import { AppConfig, repo } from '@luxms/bi-core';


export interface IEnquiry {
  id: number;
  title: string;
  dataset: string;                          // only for POST
  config: any;
  // ...
  assignee_id: number;
  attachment_type: 'access_enquiry';
  created: string;
  dashlet_id: null;
  dataset_id: number;
  expiration_time: string;
  location_id: number;
  lpe: null;
  metric_id: number;
  norm_id: null;
  period_id: null;
  period_type: null;
  start_time: string;
  updated: string;
  user_id: number;
}

export class EnquiriesRepository extends repo.BaseRepository<IEnquiry> {
  public constructor() {
    super('vcp', 'attachments');
  }

  protected _getUrl(method: 'GET' | 'POST' | 'PUT' | 'DELETE', id?: string | number): string {
    let url: string;

    if (method === 'GET'  && id === undefined) {
      url = `/api/db/vcp.attachments/.filter(attachment_type = 'access_enquiry')`;
    } else if (method === 'POST') {
      url = '/api/enquiries';
    } else {
      url = `/api/db/vcp.attachments/${id}`;
    }

    return AppConfig.fixRequestUrl(url);
  }
}
