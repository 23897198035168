import {ILction, ILocation, IMetric, IPeriod, IStoplight, ISubspace, IValue} from '../../defs/bi';

import { KoobSubspaceAsyncService } from '../../services/koob/KoobSubspaceAsyncService';
import {KoobFiltersService} from '../../services/koob/KoobFiltersService';
import {SubspacePtr} from '../../services/ds/ds-helpers';
import {Stoplights} from '../../config/Stoplights';

import {isNullVector, matrixMax} from '../../data-manip/data-utils';
import {$eid, getEntity} from '../../libs/imdas/list';
import $ from 'jquery';
import {IConfigHelper, IDatasetModel} from '../../services/ds/types';
import * as L from 'leaflet';
import { data_engine, INormsResponse } from '../../data-manip/data-manip';
import {
  BuggyClusteringRenderer,
  CameraPinMarker, DisplayChartsByLocationsRenderer, DisplayPinsByLocationsRenderer,
  DataProviderCache,
  IMapPointsRenderer, LeafletClusteringRenderer,
  LeafletDefaultPinMarker,
  PinMarker,
  TitleMarker, IMapPointsRendererResult, ClusterItem,
} from './clustering';
import toPairs from 'lodash/toPairs';
import isObject from 'lodash/isObject';
import {bi, makeColor, oneEntity} from '../../utils/utils';



interface IClusterMarkerEx extends L.Marker {
  id: string;                       // custom property
  metric: IMetric;                  // custom property
  location: ILocation;
  setval: (v: IValue) => void;
  getval: () => IValue;
}

interface IMapFactory {
  createPolylineGroup(): L.FeatureGroup<L.Layer>;

  createPinGroup(): L.FeatureGroup<L.Layer>;

  createTitleGroup(): L.FeatureGroup<L.Layer>;

  createChartGroup(): L.FeatureGroup<L.Layer>;

  createMapPoint(location: ILocation): MapPoint;

  createPinMarker(location: ILocation): PinMarker;

  createRenderer(map: L.Map): IMapPointsRenderer;

  createClusterMarker(location: ILocation): ClusterMarker;
}

export class DisplayChartsRendererKoob implements IMapPointsRenderer {
  protected _dataset: IDatasetModel;
  protected map: L.Map;

  public constructor(dataset: IDatasetModel, map: L.Map, public displayLocationsOnlyAtLevels?: number) {
    this._dataset = dataset;
    this.map = map;
  }

  public evaluate(mapPoints: MapPoint[], subspace: ISubspace): IMapPointsRendererResult {
    const ids: { [lid: string]: boolean } = {};
    // let points: MapPoint[] = mapPoints.filter((mp: MapPoint) => ids[mp.id]);
    let points: MapPoint[] = mapPoints;
    /*    if (this.displayLocationsOnlyAtLevels != -1) {
          // points = points.filter((mp) => mp.tree_level == this.displayLocationsOnlyAtLevels);
          // points = points.filter((mp) => mp.zoom == this.displayLocationsOnlyAtLevels);
          points = points.filter((mp) => mp);
        }*/
    return {charts: [], pins: points};
  }

  /**
   * evaluates map position to make all locations visible
   */
  public evaluateMapPosition(mapPoints: MapPoint[]): void {
    if (mapPoints.length === 0) {
      return;
    }
    if (mapPoints.length === 1) {
      this.map.setView(mapPoints[0].latlng, this.map.getZoom());
      return;
    }

    let lats: number[] = mapPoints.map(l => l.latlng.lat);
    let lngs: number[] = mapPoints.map(l => l.latlng.lng);

    const minLat: number = Math.min.apply(Math, lats), maxLat: number = Math.max.apply(Math, lats);
    const minLng: number = Math.min.apply(Math, lngs), maxLng: number = Math.max.apply(Math, lngs);

    const dLat: number = maxLat - minLat;
    const dLng: number = maxLng - minLng;

    // too many map moves
    window.setTimeout(() => {
      if (!this.map) return;
      /*      this.map.fitBounds(new L.LatLngBounds(
              new L.LatLng(minLat - dLat * 0.2, minLng - dLng * 0.2),
              new L.LatLng(maxLat + dLat * 0.2, maxLng + dLng * 0.2),
            ));*/
    }, 0);
  }
}

/**/
export class BaseClusterMarker {
  public id: string;
  public title: string;
  public latlng: L.LatLng;
  public lmarker: any;            // L.Marker;
  public $container = null;
  public visible: boolean = true;
  public color: string = null;
  private _location: ILocation;
  private location: ILocation;

  public constructor(location: ILocation, dataset: IDatasetModel) {
    this._location = location;
    const altTitleType: string = dataset.getConfigHelper().getStringValue('map.locations.titleType', '');
    this.title = location.getAltTitle(altTitleType);
    this.latlng = new L.LatLng(location.latitude, location.longitude);
  }

  public initLMarker(icon: L.DivIcon, z: number = 0) {
    const options: any = {
      icon: icon,
      title: this.title,
      riseOnHover: true,
    };
    this.lmarker = new L.Marker(this.latlng, options) as any;
    // FIXME: pleasepleaseplease
    this.lmarker.setval = function (v) {
      this['val'] = v;
    };
    this.lmarker['getval'] = function () {
      return this['val'];
    };
    this.lmarker.setval(0);
    this.lmarker.location = this._location ? this._location : this.location;

    this.lmarker.setZIndexOffset(z);
    this.lmarker.addEventListener('add', (e) => {
      this.$container = $('#' + this.id);
      if (this.color) this.applyColorInternal();
    });
  }

  public initClusterMarker(icon: L.DivIcon, z: number = 0) {
    const options: any = {
      icon: icon,
      title: this.title,
      riseOnHover: true,
    };
    this.lmarker = new L.Marker(this.latlng, options) as IMarkerEx;
    // FIXME: pleasepleaseplease
    this.lmarker.setval = function (v) {
      this['val'] = v;
    };
    this.lmarker['getval'] = function () {
      return this['val'];
    };
    this.lmarker.setval(0);
    this.lmarker.location = this._location;

    this.lmarker.setZIndexOffset(z);
    this.lmarker.addEventListener('add', (e) => {
      let location = this._location ? this._location : this.location;

      this.$container = $('#' + location.id);
      this.$container.id = location.id;

      if (this.color) this.applyColorInternal();
    });
  }

  public setColor(value: string): void {
    this.color = value;

    // FIXME: pleasepleaseplease
    if (this.color == '#ff0000') {
      this.lmarker.setval(1);
    } else {
      this.lmarker.setval(0);
    }

    if (this.$container && this.color != null) this.applyColorInternal();
  }

  public setMetric(m: IMetric): void {
    this.lmarker.metric = m;
    this.lmarker.id = m ? m.id : null;
  }

  public setValue(val: IValue): void {
    this.lmarker.setval(val);
  }

  public getValue(): IValue {
    return this.lmarker.getval();
  }

  public redraw(visible: boolean): void {
    if (null == this.$container) return;
    if (visible) {
      this.$container.parent().removeClass('hidden-marker');
    } else {
      this.$container.parent().addClass('hidden-marker');
    }
  }

  // abstract protected
  public applyColorInternal() {
    //
  }
}

export class ClusterMarker extends BaseClusterMarker {

  public constructor(element: ILocation, dataset: IDatasetModel) {
    super(element, dataset);
    this.id = 'map-pin-' + element.id;
    const svg: string =
      `<svg width="41" height="41" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle stroke="#cccccc" fill="#769bc7" stroke-width="3" clip-rule="evenodd" fill-rule="evenodd" cx="20.5" cy="20.5" r="19"/>
        </g>
       </svg>`;

    const onClickAction: string = 'openObjectCard';   // dataset.config.getStringValue('map.pin.onClick'); TODO: get real config value
    let cursor: string = 'pointer';
    if (onClickAction === 'openObjectCard' && element.card == null) {     // issue #2617: no pointer cursor when pin has no location-card
      cursor = 'default';
    }

    const icon: L.DivIcon = L.divIcon({
      html: `<div class="ClusterMarker" id="${this.id}" title="${element.title}" style="cursor: ${cursor}">${svg}<div class="ClusterMarker__Title">${element.cnt}</div></div>`,
      iconSize: L.point(41, 41),
    });
    this.initClusterMarker(icon);
  }

  public applyColorInternal() {
    let container = document.getElementById(`map-pin-${this.$container.id}`) || null;
    if (container) {
      let body = container.querySelector('circle');
      if (body) body.setAttribute('fill', this.color);
    }
  }
}

export class DefaultClusterMapFactory implements IMapFactory {
  private _dataset: IDatasetModel;

  constructor(dataset: IDatasetModel) {
    this._dataset = dataset;
  }

  public createPolylineGroup(): L.FeatureGroup<L.Layer> {
    // return L.multiPolyline([], {color: '#5FB404', weight: 2} as any);
    return new L.Polyline([], {color: '#5FB404', weight: 2} as any) as any;
  }

  private iconFunction1(): (cluster: any) => L.DivIcon {
    return (cluster: any): L.DivIcon => {
      const markers: IMarkerEx[] = cluster.getAllChildMarkers();
      const mCount: {[mid: string]: number} = {};
      let total: number = 0;
      let color: string = null;
      const childCount: number = cluster.getChildCount();

      markers.forEach((marker: IMarkerEx) => {
        const m: IMetric = marker.metric;
        if (!m) {
          // THIS MIGHT BE A ERROR: see issue #1765
          // probably the situation occurs when data-request is not finished while other is started
          return;
        }
        mCount[m.id] = (mCount[m.id] || 0) + 1;
        total++;
      });

      // total ?== childCount
      type ICountPair = [IMetric, number];
      const pairs: ICountPair[] = toPairs(mCount).map<ICountPair>(([mid, count]) => [getEntity(markers, mid).metric, count]);
      pairs.sort(([metric1, count1], [metric2, count2]) => metric1.srt - metric2.srt);

      //
      // STAGE 1. Get cluster color based on thresholds
      //
      for (let pair of pairs) {
        const m: IMetric = pair[0];
        const count: number = pair[1];
        const relativeCount: number = count / total;
        let threshold: [number, number]
          | { min?: number, max?: number }
          | number = (m.config && ('cluster' in m.config)) ? m.config.cluster.threshold : null;

        if (threshold == null) {
          // skip this check and if others also fail, we will try to select color by voting
          continue;
        }

        if (isObject(threshold) && !Array.isArray(threshold)) {
          const thObject: { min?: number, max?: number } = threshold as  { min?: number, max?: number };
          threshold = [thObject.min, thObject.max];
        }

        const thArray: [number, number] = threshold as [number, number];

        console.assert((Array.isArray(thArray) && thArray.length === 2));

        if (thArray && thArray[0] == null)
          thArray[0] = -Infinity;

        if (thArray && thArray[1] == null)
          thArray[1] = +Infinity;

        if (thArray[0] <= relativeCount && relativeCount <= thArray[1]) {
          color = makeColor(m.color);
          // console.log('Evaluated cluster color ', color, ' by threshold');
          break;
        }
      }

      if (color == null) {
        //
        // STAGE 2. Get cluster color based on stoplight zones
        //
        for (let pair of pairs) {
          let m: IMetric = pair[0];
          let count: number = pair[1];
          let relativeCount: number = count / total;

          if (!m.config || !m.config.cluster) {
            continue;
          }
          let clusterCfg: any = m.config.cluster;
          let stoplights: Stoplights = Stoplights.create(clusterCfg.stoplight);
          let light: IStoplight = stoplights.getStoplight(relativeCount);
          if (light) {
            color = light.colorPair.color;
            // console.log('Evaluated cluster color ', color, ' by stoplights');
            break;
          }
        }
      }

      if (color == null) {
        //
        // STAGE 3. Vote?
        //
        let best: number = -1;
        for (let pair of pairs) {
          let m: IMetric = pair[0];
          let count: number = pair[1];
          if (count > best) {
            best = count;
            color = m.color;
          }
        }
      }

      const hint: string = pairs.map((pair: [IMetric, number]) => pair[0].title + ': ' + String(pair[1])).join('\n');

      // bagen
      let lTitle: string = '';
      if (skin.mapDisplayPinTitle) {
        const locations: ILocation[] = markers.map(m => m.location).filter(l => l != null);
        locations.sort((l1, l2) => l1.tree_level - l2.tree_level);
        lTitle = locations.length ? locations[0].title : '';
      }
      const bgColor = Color(color).fade(0.4).toString();

      const html =
        `<div class="IconFunction1" title="${hint}" style="background: ${bgColor};">
          <div class="IconFunction1__Shadow" style="background: ${bgColor};"></div>
          <span class="IconFunction1__Value">${childCount}</span>
          <div class="IconFunction1__Title">${lTitle}</divclas>
        </div>`;

      return new L.DivIcon({
        html,
        // className: 'marker-cluster' + c,
        className: 'marker-cluster marker-cluster-custom',
        iconSize: new L.Point(40, 40),
        // bgColor: color,
      } as any);
    };
  }

  private iconFunction2(): (cluster: any) => L.DivIcon {
    return (cluster) => new L.DivIcon({
      html: '<div><span>' + cluster.getChildCount() + '</span></div>',
      className: 'marker-cluster marker-cluster-small',
      iconSize: new L.Point(40, 40),
    });
  }

  public createPinGroup(): L.FeatureGroup<L.Layer> {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    if (configHelper.getStringValue('map.clusteringMode') === 'native') {
      const opts: any = {
        // polygonOptions: {weight: 2, color: '#00ff33'},
        spiderfyOnMaxZoom: true,
        spiderfyDistanceMultiplier: 1,
      };
      const iconFunctionName = configHelper.getValue('map.iconFunctionName', 'iconFunction1');
      opts.iconCreateFunction = this[iconFunctionName]();
      if (configHelper.getStringValue('map.pin.onClick') === 'showChart') {
        opts.spiderfyDistanceMultiplier = 5;
      }

      // return L.markerClusterGroup(opts);
      return new MarkerClusterGroup(opts);
    } else {
      return L.featureGroup();
    }
  }

  public createClusterGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createTitleGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createChartGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createMapPoint(location: ILocation): MapPoint {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    return new MapPoint(location, this._dataset, this, configHelper.getBoolValue('map.displayPinsOnly'));
  }

  public createMapPointKoob(data: any): MapPoint {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    return new MapPoint(data, this._dataset, this, configHelper.getBoolValue('map.displayPinsOnly'));
  }

  public createMapCluster(data: any): ClusterElement {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    return new ClusterElement(data, this._dataset, this, configHelper.getBoolValue('map.displayPinsOnly'));
  }

  public createPinMarker(location: ILocation): PinMarker {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const className = configHelper.getValue('map.pinMarkerClass', 'PinMarker');
    switch (className) {
      case 'DefaultPinMarker':
        return new LeafletDefaultPinMarker(location, this._dataset);
      case 'PinMarker':
        return new PinMarker(location, this._dataset);
      case 'CameraPinMarker':
        return new CameraPinMarker(location, this._dataset);
      default:
        throw new Error('Undefined classname to create pin');
    }
  }

  public createClusterMarker(location: ILocation): any { // TODO types
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const className = configHelper.getValue('map.clusterMarkerClass', 'ClusterMarker'); // TODO CLASS
    switch (className) {
      case 'ClusterMarker':
        return new ClusterMarker(location, this._dataset);
      case 'DefaultClusterMarker':
        return new LeafletDefaultClusterMarker(location, this._dataset);
      default:
        throw new Error('Undefined classname to create pin');
    }
  }

  public createRenderer(map: L.Map, subspace?: ISubspace): IMapPointsRenderer {

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const displayLevel: number = configHelper.getIntValue('map.displayLocationsOnlyAtLevels', -1);
    const clusteringMode: string = configHelper.getStringValue('map.clusteringMode');
    const displayPinsOnly: boolean = configHelper.getBoolValue('map.displayPinsOnly');

    if (clusteringMode === 'native') {
      return new LeafletClusteringRenderer(this._dataset, map, displayLevel);
    } else if (displayPinsOnly) {

      if (subspace && subspace.koob) {
        return new DisplayChartsRendererKoob(this._dataset, map, displayLevel);
      }
      return new DisplayPinsByLocationsRenderer(this._dataset, map, displayLevel);
    } else if (!configHelper.getBoolValue('map.automaticClusteringDisabled')) {
      // needs old buggy clustering
      if (subspace && subspace.koob) {
        return new DisplayChartsRendererKoob(this._dataset, map, displayLevel);
      }
      return new BuggyClusteringRenderer(this._dataset, map);

      return new DisplayChartsByLocationsRenderer(this._dataset, map);
    }
  }
}

export class LeafletDefaultClusterMarker extends ClusterMarker {
  constructor(location: ILocation, dataset: IDatasetModel) {
    super(location, dataset);
    this.id = 'map-pin-' + location.id;
    this.lmarker = new L.Marker(this.latlng, {title: this.title, riseOnHover: true}) as IClusterMarkerEx;
    this.lmarker.id = null;
    this.lmarker.metric = null;
  }
}

class ClusterElement {
  public id: number | string;
  public title: string;
  public nodata: boolean = true;
  public location: ILocation;

  public latlng: L.LatLng;
  public titleMarker: TitleMarker;
  public clusterMarker: ClusterMarker; // TODO!!!

  public factory: IMapFactory;

  public visible: boolean = false;
  public visibleChart: boolean = false;

  private _dataset: IDatasetModel;
  public zoom?: number;

  public constructor(location: ILocation, dataset: IDatasetModel, factory: IMapFactory, private displayPinsOnly: boolean = false) {
    this.location = location;
    this._dataset = dataset;

    this.id = location.id;
    this.title = location.title;

    this.factory = factory;
    if (location.zoom) this.zoom = location.zoom;
    this.latlng = new L.LatLng(this.location.latitude, this.location.longitude);
    this.initMarkers();
  }

  public initMarkers() {
    this.clusterMarker = this.factory.createClusterMarker(this.location);
    // if (this.displayPinsOnly) return;
  }

  public setData(parameters: IMetric[], vector: IValue[], domain: number[]) {
    // nodata if all values in the vector are NULL
    this.nodata = isNullVector(vector);
    if (this.nodata) {
      if (this.clusterMarker) this.clusterMarker.redraw(false);
      if (this.titleMarker) this.titleMarker.redraw(true);
    } else {
      if (this.clusterMarker) this.clusterMarker.setData(parameters, vector, domain);
      this.redraw();
    }
  }

  public setClusterColor(color: string): void {
    this.clusterMarker.setColor(color);
  }

  public hide() {
    if (!this.visible) return;
    this.visible = false;
    this.redraw();
  }

  public show() {
    if (this.visible) return;
    this.visible = true;
    this.redraw();
  }

  public onAdd(titleGroup: L.FeatureGroup<L.Layer>,
               clusterGroup: L.FeatureGroup<L.Layer>,
               chartGroup: L.FeatureGroup<L.Layer>) {
    if (this.clusterMarker) clusterGroup.addLayer(this.clusterMarker.lmarker);
    if (this.titleMarker) titleGroup.addLayer(this.titleMarker.lmarker);

    this.redraw();
  }

  public onRemove() {
    //
  }

  private redraw() {
    if (this.clusterMarker) this.clusterMarker.redraw(this.visible && !this.visibleChart);
    if (this.titleMarker) this.titleMarker.redraw(this.visible && this.visibleChart);
  }

}

export class KoobClusterLayer extends L.Layer<any, any> {
  protected _map: L.Map = null;
  private _mapPoints: ClusterElement[] = [];

  private _polylineGroup: L.FeatureGroup<L.Layer> = null;
  private _clusterGroup: L.FeatureGroup<L.Layer> = null;
  private _pinGroup: L.FeatureGroup<L.Layer> = null;
  private _titleGroup: L.FeatureGroup<L.Layer> = null;
  private _chartGroup: L.FeatureGroup<L.Layer> = null;

  private _popup: L.Popup = L.popup({minWidth: 400, maxWidth: 600});

  private _displayPinsOnly: boolean;
  private _factory: DefaultClusterMapFactory; // IMapFactory;

  private _dataset: IDatasetModel;
  private _subspace: ISubspace;

  private _dataProvider: data_engine.IDataProvider;
  private _renderer: IMapPointsRenderer = null;

  private currentClusteredIds: (number | string)[] = [];

  private _cfg = null;
  private _setCallback = () => {
    let latLng = this._map.getBounds();

    let southEast = latLng.getSouthEast();
    let northWest = latLng.getNorthWest();

    let xy0 = [northWest.lng, northWest.lat];
    let xy1 = [northWest.lng, southEast.lat];
    let xy2 = [southEast.lng, southEast.lat];
    let xy3 = [southEast.lng, northWest.lat];

    this._getDataFromSubspacePtr([xy0, xy1, xy2, xy3], this._map.getZoom());
  }


  public constructor(data: any) {
    super(data);

    this._dataset = data.dataset;
    this._subspace = data.subspace;

    this._dataProvider = this._dataset.getDataProvider();

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    this._factory = new DefaultClusterMapFactory(this._dataset);
    this._displayPinsOnly = configHelper.getBoolValue('map.displayPinsOnly');

    this._createLayers();

    this._cfg = data.cfg;
    this._map = data.map;


    if (this._subspace && data.map) {
      this._setCallback();

      data.map.on('zoomend', () => {
        this._setCallback();
      });

      data.map.on('moveend', () => {
        this._setCallback();
      });
    }

  }

  private async _getDataFromSubspacePtr(coords_array, zoom) {
    this.onRemove();
    this._createLayers();

    const h3Dimension = this._getZoomType();

    let rawDataSource: any = {...this._cfg.getRaw().dataSource};
    // Мы заменим в конфиге ключ на новый, но тут надо быть осторожным, вдруг там другие значения будут
    rawDataSource.dimensions = [h3Dimension];
    rawDataSource.xAxis = h3Dimension;

    let dataSourceFilters = this._cfg.getRaw().dataSource.filters || {};
    let filters: any = Array.isArray(dataSourceFilters) ? Object.fromEntries(dataSourceFilters.map((d) => [d, true])) : {...dataSourceFilters};

    const koobFiltersModel = KoobFiltersService.getInstance().getModel();
    Object.keys(filters).forEach(key => {
      if (filters[key] === true) filters[key] = koobFiltersModel.filters[key];
      if (filters[key] === undefined) delete filters[key];
    });

    let emptyFilter = filters[''];
    let requestFilter =  ['pointInPolygon', ['tuple', 'lat', 'lng'],
      [
        '[',
        ['tuple', coords_array[0][1], coords_array[0][0]],
        ['tuple', coords_array[1][1], coords_array[1][0]],
        ['tuple', coords_array[2][1], coords_array[2][0]],
        ['tuple', coords_array[3][1], coords_array[3][0]],
      ],
    ];

    filters[''] = emptyFilter ? ['and', emptyFilter, requestFilter] : requestFilter;

    rawDataSource.filters = filters;

    const subpacePtr = new SubspacePtr(rawDataSource);
    const subspaceService = new KoobSubspaceAsyncService(this._cfg.getDataset().schemaName, subpacePtr);
    await subspaceService.whenReady();
    const { subspace } = subspaceService.getModel();

    const data = await this._dataProvider.getMatrixYX(subspace);
    if (data && data[0]) {
      let pointsData = this._tranformMatrixToPoints(data, subspace);
      this.setKoobDataToPoints(pointsData);
    }
  }

  private _tranformMatrixToPoints = (matrix: any, subspace: any) => {
    const ys = subspace.ys;
    const xs = subspace.xs;

    let pointsData: any = [];
    if (matrix.length && matrix[0].length) {
      if (matrix[0].length && matrix[0][0]) {
        for (let z = 0; z < matrix[0].length; ++z) {
          let el = {};
          for (let i = 0; i < matrix.length; ++i) {
            if (matrix[i] && matrix[i][z]) {
              let value = matrix[i][z];
              let key = (ys[i]) ? ys[i].id : null;
              el[key] = value;
            }
          }

          if (Object.keys(el).length) el['h3'] = (xs[z]) ? xs[z].id : null;
          pointsData.push(el);
        }
      }
    }

    return pointsData;
  }

  private async getDataFromSubspace(subspace): Promise<any> {
    if (subspace) {
      const matrix = await this._dataProvider.getMatrixYX(subspace);
      const ys = subspace.ys;


      let pointsData: any = [];
      if (matrix.length && matrix[0].length) {
        for (let z = 0; z < matrix[0].length; ++z) {
          let el = {};
          for (let i = 0; i < matrix.length; ++i) {
            if (matrix[i] && matrix[i][z]) {
              let value = matrix[i][z];
              let key = ys[i].id;
              el[key] = value;
            }
          }
          pointsData.push(el);
        }
      }

      this.setKoobDataToPoints(pointsData);
    }
  }

  public setData(locations: ILocation[], metrics: IMetric[], matrix: IValue[][]) {
    const max: number = matrixMax(matrix);
    const domain = [0, max];
    locations.forEach((location, i) => {
      $eid(this._mapPoints, location.id).setData(metrics, matrix[i], domain);
    });
  }

  public setKoobDataToPoints(data: any) {

    this._mapPoints = [];

    this._mapPoints = data.map((d, ind) => {
      let item = {};
      item = {...d};

      item['longitude'] = d.ln;
      item['latitude'] = d.la;
      item['title'] = 'title' + ind + ':' + item['cnt'];
      item['getAltTitle'] = () => item['title'] ;
      item['id'] = 'id' + ind; // TODO убрать лишнее добавить нужное
      item['cnt'] = item['cnt'];

      const cl: ClusterElement = this._factory.createMapCluster(item);
      return cl;
    });

    if (this._clusterGroup) {
      // reset current for redraw
      this._map.removeLayer(this._clusterGroup);
      this._clusterGroup = null;
      this._clusterGroup = this._factory.createClusterGroup();
    }

    this.onAdd(this._map);

  }

  public setChartType(value: string): void {
    this._mapPoints.forEach((e) => e.setChartType(value));
  }

  private _createLayers = () => {
    this._pinGroup = this._factory.createPinGroup();
    this._titleGroup = this._factory.createTitleGroup();
    this._chartGroup = this._factory.createChartGroup();
    this._clusterGroup = this._factory.createClusterGroup();
  }

  public onAdd(map: L.Map): this {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const cfgMapPinColor: string = configHelper.getStringValue('map.pinColor', '#769bc7');        // color or 'FirstNonZeroParameter'
    const cfgMapClusteringMode: string = configHelper.getValue('map.clusteringMode');             // native

    this._map = map;

    map.addLayer(this._titleGroup);
    map.addLayer(this._chartGroup);
    map.addLayer(this._clusterGroup);

    this._chartGroup.on('click', this._handleChartClick);
    this._titleGroup.on('click', this._handleChartClick);
    this._pinGroup.on('click', this._handlePinClick);
    this._clusterGroup.on('click', this._handleClusterClick);

    if (this._subspace.koob) {

      this._mapPoints.forEach((mp: any) => {
        mp.onAdd(this._titleGroup, this._clusterGroup, this._chartGroup);
        mp.setClusterColor(cfgMapPinColor);
      });
    } else {
      this._mapPoints.forEach((mp: any) => {
        mp.onAdd(this._pinGroup, this._titleGroup, this._chartGroup, this._clusterGroup);
        mp.setClusterColor(cfgMapPinColor);
      });
    }

    this.redraw();
    return this;
  }

  public onRemove(): this {
    this._mapPoints.forEach((e) => e.onRemove());
    this._mapPoints = [];
    if (this._titleGroup) {
      this._map.removeLayer(this._titleGroup);
      this._titleGroup = null;
    }
    if (this._chartGroup) {
      this._map.removeLayer(this._chartGroup);
      this._chartGroup = null;
    }
    if (this._pinGroup) {
      this._map.removeLayer(this._pinGroup);
      this._pinGroup = null;
    }

    if (this._clusterGroup) {
      this._map.removeLayer(this._clusterGroup);
      this._clusterGroup = null;
    }
    return this;
  }

  public redraw(doMoveMap: boolean = true): void {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    if (!this._renderer) {
      this._renderer = this._factory.createRenderer(this._map, this._subspace);
    }
    const result: any = this._renderer.evaluate(this._mapPoints, this._subspace);

/*  if (doMoveMap && this._renderer.evaluateMapPosition) {

    }

    this._mapPoints.forEach((mp: ClusterElement) => {
      if (this._subspace.koob) {
        mp.show();
      }
    });*/
  }

  public setKoobAxes = (subspace: ISubspace) => {
    if (this._subspace !== subspace) {
      this._subspace = subspace;
      this._setCallback();
    }

    if ((this._dataProvider as DataProviderCache).invalidate) {
      (this._dataProvider as DataProviderCache).invalidate();
    }
    this.currentClusteredIds = [];

    this.redraw(!!subspace);
  }

  private async loadPinsData(mapPoints: ClusterElement[]): Promise<IValue[][]> {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const cfgMapPinColor: string = configHelper.getStringValue('map.pinColor');

    const ms: IMetric[] = this._subspace.ms;
    const ls: ILction[] = mapPoints.map(mp => mp.location);
    const ps: IPeriod[] = [oneEntity(this._subspace.ps)];
    const subspace: ISubspace = bi.createSimpleSubspace(ms, ls, ps);

    const dataMatrix: IValue[][] = await this._dataProvider.getMatrixYX(subspace);

    let polylines: L.LatLng[][] = [];

    for (let i = 0; i < dataMatrix.length; ++i) {
      const mp: MapPoint = mapPoints[i];

      if (cfgMapPinColor == 'FirstNonZeroParameter') {
        const parametersOfLocation: IValue[] = dataMatrix[i];

        const metricIndex: number = parametersOfLocation.indexOf(1);
        let metric: IMetric = null;

        if (metricIndex == -1) {
          // console.warn('No found parameter with value=1');
          // EP: will try to display as gray
          mp.setPinMetric(null);
          mp.setPinColor('transparent');
          continue;
        } else {
          mp.show();
        }
        metric = ms[metricIndex];

        if (metric !== undefined) {
          const val: number = (ms.indexOf(metric) === -1) ? 0 : 1;
          const color: string = val ? metric.color : 'none';
          mp.setPinColor(color);
          mp.setPinMetric(metric);

          // draw lines
          if (configHelper.getBoolValue('map.pin.lineToParent') && color == '#00ff00') {
            const ll: L.LatLng[] = [mp.latlng, $eid(this._mapPoints, mp.location.parent.id).latlng];
            polylines.push(ll);
          }
        } else {
          mp.setPinColor('#0066ff');
        }
      } else {
        mp.setPinColor('#0066ff');
      }
    }

    this._polylineGroup['setLatLngs'](polylines);

    return dataMatrix;
  }

  private _handleChartClick = (e) => {

  }

  private _getZoomType() {
    let type: string = '';
    if (this._map) {
      let zoom = this._map.getZoom();

      const H3_LEVELS = [0, 0, 1, 1, 1, 1, 2, 3, 4, 5, 6,  7, 8, 9, 11, 12, 13, 14, 15, 15, 15];
      const h3Level = H3_LEVELS[Number(zoom) - 1];
      const h3Dimension = 'h3_' + h3Level;

      type = h3Dimension;
    }

    return type;
  }

  private _handleClusterClick = (e) => {

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const elId: string = $(e.layer._icon).children().get(0).id.replace(/\D+/, '');

    const ce: ClusterElement = $eid(this._mapPoints, 'id' + elId);
    let h3_id = ce.location.h3 || null;

    import('../modal-container').then((modalContainerModule: any) => {
      const modalContainer = modalContainerModule.modalContainer;

      const h3Dimension = this._getZoomType();

      modalContainer.pushVizelConfig({
        view_class: 'koob-table-simple',
        dataSource: {
          koob: this._subspace.koob,
          dimensions: ['floor'],
          measures: ['price_ddu', 'room'],
          filters: {
            [h3Dimension]: ['=', h3_id],
          },
        },
      });
    });
  }

  private _handlePinClick = (e) => {

  }

  private moveMapToPoint(targetPoint: any) {
    this._map.setView(targetPoint.latlng);

    if (BuggyClusteringRenderer.algorytm == null) return;

    let latlng: L.LatLng = targetPoint.latlng;
    let z: number = this._map.getZoom();
    while (true) {
      const ci: ClusterItem = BuggyClusteringRenderer.algorytm.getCluster(latlng, z);
      if (-1 != ci.mapPoints.indexOf(targetPoint)) {
        this._map.setView(targetPoint.latlng, z);
        break;
      }
      z++;
      if (z > this._map.getMaxZoom()) {
        break;
      }
    }
  }
}
