import { BaseService, srv, repo, UrlState, IUrl } from '@luxms/bi-core';
import { IBaseModel } from '@luxms/bi-core/dist/BaseService';

import DashboardTopicsService = srv.ds.DashboardTopicsService;
import DashboardsService = srv.ds.DashboardsService;

import { $eid } from '../../libs/imdas/list';
import IRawDashboardTopic = repo.ds.IRawDashboardTopic;
import { IRawDashboard } from '@luxms/bi-core/dist/repositories/ds';

export interface IDashboardsByTopicsMode extends IBaseModel {
  topics: IRawDashboardTopicMode[];
  dashboards: IRawDashboard[];
}

export interface IRawDashboardTopicMode extends IRawDashboardTopic {
  children: IRawDashboard[];
}

export class DashboardByTopicsService extends BaseService<IDashboardsByTopicsMode> {
  private readonly _shemaName: string;
  private readonly _dashboardTopicsService: DashboardTopicsService;
  public readonly _dashboardsService: DashboardsService;

  public constructor(shemaName: string) {
    super({
      loading: true,
      error: null,
      topics: [],
      dashboards: [],
    });
    this._shemaName = shemaName;

    this._dashboardTopicsService = DashboardTopicsService.createInstance(shemaName);
    this._dashboardsService = DashboardsService.createInstance(shemaName);
    this._dashboardTopicsService.subscribeUpdatesAndNotify(this._onServiceUpdate);
    this._dashboardsService.subscribeUpdatesAndNotify(this._onServiceUpdate);
  }

  private _onServiceUpdate = (): void => {
    const modelDashboards = this._dashboardsService.getModel();
    const modelTopics = this._dashboardTopicsService.getModel();

    if (modelTopics.loading || modelDashboards.loading) return this._updateWithLoading();
    if (modelDashboards.error || modelTopics.error) return this._updateWithError(modelTopics.error || modelDashboards.error);

    const topics = modelTopics.map((topic) => ({...topic, children: []}));
    const dashboards = modelDashboards;

    modelDashboards.forEach((dashboard) => {
      const topicId = dashboard.topic_id;
      const topic = $eid(topics, topicId); // ссылка на топик
      if (topic) topic.children.push(dashboard);
    });

    this._updateWithData({topics, dashboards, loading: false});
  }

  protected _dispose() {
    this._dashboardTopicsService.unsubscribe(this._onServiceUpdate);
    this._dashboardsService.unsubscribe(this._onServiceUpdate);
    super._dispose();
  }
}