import { IEntity, ILocation, IMetric, IPeriod, ISubspacePtr, IValue } from '../../defs/bi';
import { SubspacePtr } from '../../services/ds/ds-helpers';
import { makeNullAxis } from '../../services/ds/createSubspaceGenerator';


interface IVizelPivotMLPModel {
  loading: boolean;
  error: string;
}

interface IVizelPivotMLPOptions {
  xAxis: string[];
  yAxis: string[];
  zAxis: string[];
  tags: string[][];   // [[axisId, tagName]]
}

// 🌈
interface IAggregator {
  data: { [key: string]: string | number }[];                             // набор данных mlp + XYZ + value
  xs: string[];                                                           // все xs у ячейки
  total: number;
  getTotal: () => number;

  put: (record: { [key: string]: string | number }) => IAggregator;
  getMLP: () => { m: ILocation, l: ILocation, p: IPeriod };               // Возвращает из последнего data  - mlp
  putValue: (v: IValue) => IAggregator;                                   // вставляет из последнего data value
  getValue: () => number | null;                                          // Возвращает из последнего data value
  putXs: (x: string) => IAggregator;                                 // вставляет в массив labels X, кто имеет доступ к этой ячейке
  getXs: () => string | null;                                          // Возвращает из массива labels последний X, кто имеет доступ к этой ячейке (для нормы)
}

export class VizelPivotMLP {
  private readonly _mlp: { metrics: IMetric[], locations: ILocation[], periods: IPeriod[] };
  private readonly _subspacePtr: ISubspacePtr;
  private readonly _options: IVizelPivotMLPOptions = {
    xAxis: [],
    yAxis: [],
    zAxis: [],
    tags: [],
  };

  private order: {
    x: { idx: number, sort: 'desc' | 'asc' };
    y: { idx: number, sort: 'desc' | 'asc' }
  } = {x: {idx: null, sort: 'desc'}, y: {idx: null, sort: 'desc'}};

  private readonly _xAxisKeysMap: { [idx: string]: IAggregator } = {};
  private readonly _yAxisKeysMap: { [idx: string]: IAggregator } = {};
  private readonly _zAxisKeysMap: { [idx: string]: IAggregator } = {};

  // [mId  lId  pid] = ссылка на ячейку, потому, что набор id и порядок как снежинка
  private readonly _mlpKeysMap: { [idx: string]: IAggregator } = {};

  public xAxisKeys: IEntity[] = [];
  public yAxisKeys: IEntity[] = [];
  public zAxisKeys: IEntity[] = [];

  private readonly _treeXY: { [idx: string]: { [idy: string]: IAggregator } } = {};
  private readonly _treeXYZ: { [idx: string]: { [idx: string]: { [idz: string]: IAggregator } } } = {};

  public constructor(
      mlp: { metrics: IMetric[], locations: ILocation[], periods: IPeriod[] },
      options: IVizelPivotMLPOptions,
      subspacePtr?: ISubspacePtr,
  ) {

    this._subspacePtr = subspacePtr;
    this._options = options;
    this._mlp = mlp;
    this._init();
  }

  //  для манипуляции с ячейкой
  private _aggregator: any = function (): IAggregator {
    return {
      data: [],
      xs: [],
      total: null,
      getTotal: function () {
        if (this.total) return this.total;
        const val = this.data.reduce((accum, current) => {
          if (current.value) accum += current.value;
          return accum;
        }, 0);
        this.total = val;
        return val;
      },
      put: function (record): IAggregator {
        this.data.push(record);
        return this;
      },
      getMLP: function (): any {
        const len = this.data.length - 1;
        return this.data[len];
      },
      putValue: function (value: number): IAggregator {
        const len = this.data.length - 1;
        const data = this.data[len];
        data.value = value;
        return this;
      },
      getValue: function (): number | null {
        const len = this.data.length - 1;
        return this.data?.[len]?.value;
      },
      putXs: function (xs: string): IAggregator {
        this.xs.push(xs);
        return this;
      },
      getXs: function (): string {
        const len = this.xs.length - 1;
        return this.xs?.[len] || null;
      },
    };
  };

  // формирую объект данных для записи в ячейки памяти по осям
  private _init(): void {

    let allMetrics: IMetric[] = this._mlp.metrics;
    let allLocations: ILocation[] = this._mlp.locations;
    let allPeriods: IPeriod[] = this._mlp.periods;

    allPeriods.forEach((period, i) => {
      const pId = period.id;
      const pTag = period.getTags().reduce((acc, curr) => {
        // todo как вставить cfg к тегу?
        const axisId = curr.axisId;
        acc[axisId] = {id: curr.id, entity: {title: curr.id, axisId: period.axisId, id: curr.id, config: {}}};
        return acc;
      }, {});

      allMetrics.forEach((metric, j) => {
        const mId = metric.id;

        const mTag = metric.getTags().reduce((acc, curr) => {
          // todo как вставить cfg к тегу?
          const axisId = curr.axisId;
          acc[axisId] = {id: curr.id, entity: {title: curr.id, axisId: metric.axisId, id: curr.id, config: {}}};
          return acc;
        }, {});

        allLocations.forEach((location, k) => {
          // todo как вставить cfg к тегу?
          const lId = location.id;
          const lTag = location.getTags().reduce((acc, curr) => {
            const axisId = curr.axisId;
            acc[axisId] = {id: curr.id, entity: {title: curr.id, id: curr.id, axisId: location.axisId, config: {}}};
            return acc;
          }, {});

          const key = {
            // id
            locations: {id: lId, entity: location},
            metrics: {id: mId, entity: metric},
            periods: {id: pId, entity: period},
            // tags
            ...pTag,
            ...mTag,
            ...lTag,
            // 🧊
            m: metric,
            p: period,
            l: location,
          };
          this._processRecord(key);
        });
      });
    });

    /// сортировка ключей по именам a-a-b, a-b-a, a-b-b,...n
    this.sortAxes(this.xAxisKeys);
    this.sortAxes(this.yAxisKeys);

    // принудительно делаю выборку и сортировку по списку для каждого из тегов
    // DARK MATTER #3
    if (this._subspacePtr) {
      const xAxisTag = this._subspacePtr.combineAxes.xAxis.length ? this._subspacePtr.combineAxes.xAxis[0] : null;
      const yAxisTag = this._subspacePtr.combineAxes.yAxis.length ? this._subspacePtr.combineAxes.yAxis[0] : null;
      const isXAxisSorting = xAxisTag != null && !this._subspacePtr.axesOrder.includes(xAxisTag) && this._subspacePtr.dataSource.hasOwnProperty(xAxisTag);
      const isYAxisSorting = yAxisTag != null && !this._subspacePtr.axesOrder.includes(yAxisTag) && this._subspacePtr.dataSource.hasOwnProperty(yAxisTag);
      if (isXAxisSorting) {
        this.xAxisKeys = this.xAxisKeys.filter(el => this._subspacePtr.dataSource[xAxisTag].includes(el.id)).sort((a, b) => this._subspacePtr.dataSource[xAxisTag].indexOf(a.id) - this._subspacePtr.dataSource[xAxisTag].indexOf(b.id));
      }
      if (isYAxisSorting) {
        this.yAxisKeys = this.yAxisKeys.filter(el => this._subspacePtr.dataSource[yAxisTag].includes(el.id)).sort((a, b) => this._subspacePtr.dataSource[yAxisTag].indexOf(a.id) - this._subspacePtr.dataSource[yAxisTag].indexOf(b.id));
      }
    }
    this.sortAxes(this.zAxisKeys);
  }

  // заношу в таблицу
  private _processRecord(data: { [id: string]: any }) {
    const xKey: IEntity = makeNullAxis();
    const yKey: IEntity = makeNullAxis();
    const zKey: IEntity = makeNullAxis();
    // mlp уникальный id , записываю x-y-z = ячейка с данными  &&  m+l+p = ссылка на ячейку данных
    const mlpKey: string[] = [data.metrics.id, data.locations.id, data.periods.id];

    this._options.xAxis.forEach((xAttr, i) => {
      const id = data[xAttr]?.id;
      if (!id) return;

      const entity = data[xAttr]?.entity;
      xKey.ids.push(id);
      xKey.axisIds.push(entity.axisId);
      xKey.titles.push(entity.title);
      xKey.config[id] = entity.config;
    });
    this._options.yAxis.forEach((yAttr, i) => {
      const id = data[yAttr]?.id;
      if (!id) return;

      const entity = data[yAttr]?.entity;
      yKey.ids.push(id);
      yKey.axisIds.push(entity.axisId);
      yKey.titles.push(entity.title);
      yKey.config[id] = entity.config;
    });
    this._options.zAxis.forEach((zAttr, i) => {
      const id = data[zAttr]?.id;
      if (!id) return;

      const entity = data[zAttr]?.entity;
      zKey.ids.push(id);
      zKey.axisIds.push(entity.axisId);
      zKey.titles.push(entity.title);
      zKey.config[id] = entity.config;
    });

    xKey.title = xKey.titles.join(' ');
    yKey.title = yKey.titles.join(' ');
    zKey.title = zKey.titles.join(' ');
    xKey.id = xKey.ids.join(' ');
    yKey.id = yKey.ids.join(' ');
    zKey.id = zKey.ids.join(' ');

    const mlpKeyFlat: string = mlpKey.join(' ');

    if (xKey.ids.length > 0) {
      if (!this._xAxisKeysMap[xKey.id]) {
        this._xAxisKeysMap[xKey.id] = this._aggregator();
        this.xAxisKeys.push(xKey);
      }
      // сохраняю данные + сохраняю в MLP ссылку на ячейку данных
      this._xAxisKeysMap[xKey.id].putXs(xKey.id);
      this._xAxisKeysMap[xKey.id].put(data);
      this._mlpKeysMap[mlpKeyFlat] = this._xAxisKeysMap[xKey.id];
    }
    if (yKey.ids.length > 0) {
      if (!this._yAxisKeysMap[yKey.id]) {
        this._yAxisKeysMap[yKey.id] = this._aggregator();
        this.yAxisKeys.push(yKey);
      }

      // сохраняю данные + сохраняю в MLP ссылку на ячейку данных
      this._yAxisKeysMap[yKey.id].put(data);
      this._mlpKeysMap[mlpKeyFlat] = this._yAxisKeysMap[yKey.id];
    }
    if (zKey.ids.length > 0) {
      if (!this._zAxisKeysMap[zKey.id]) {
        this._zAxisKeysMap[zKey.id] = this._aggregator();
        this.zAxisKeys.push(zKey);
      }

      // сохраняю данные + сохраняю в MLP ссылку на ячейку данных
      this._zAxisKeysMap[zKey.id].put(data);
      this._mlpKeysMap[mlpKeyFlat] = this._zAxisKeysMap[zKey.id];
    }
    if (xKey.ids.length > 0 && yKey.ids.length > 0) {
      if (!this._treeXY[xKey.id]) this._treeXY[xKey.id] = {};
      if (!this._treeXY[xKey.id][yKey.id]) this._treeXY[xKey.id][yKey.id] = this._aggregator();

      // сохраняю данные + сохраняю в MLP ссылку на ячейку данных
      this._treeXY[xKey.id][yKey.id].put(data);
      this._treeXY[xKey.id][yKey.id].putXs(xKey.id);

      this._mlpKeysMap[mlpKeyFlat] = this._treeXY[xKey.id][yKey.id];
    }

    // for z Axis
    if ((xKey.ids.length && !yKey.ids.length || !xKey.ids.length && yKey.ids.length) && zKey.ids.length) {
      const x = xKey.ids.length ? xKey.id : yKey.id;
      if (!this._treeXY[x]) this._treeXY[x] = {};
      if (!this._treeXY[x][zKey.id]) this._treeXY[x][zKey.id] = this._aggregator();

      // сохраняю данные + сохраняю в MLP ссылку на ячейку данных
      this._treeXY[x][zKey.id].put(data);
      this._treeXY[x][zKey.id].putXs(x);
      this._mlpKeysMap[mlpKeyFlat] = this._treeXY[x][zKey.id];
    }
    if (xKey.ids.length > 0 && yKey.ids.length > 0 && zKey.ids.length > 0) {
      if (!this._treeXYZ[xKey.id]) this._treeXYZ[xKey.id] = {};
      if (!this._treeXYZ[xKey.id][yKey.id]) this._treeXYZ[xKey.id][yKey.id] = {};
      if (!this._treeXYZ[xKey.id][yKey.id][zKey.id]) this._treeXYZ[xKey.id][yKey.id][zKey.id] = this._aggregator();

      // сохраняю данные + сохраняю в MLP ссылку на ячейку данных
      this._treeXYZ[xKey.id][yKey.id][zKey.id].put(data);
      this._treeXYZ[xKey.id][yKey.id][zKey.id].putXs(xKey.id);
      this._mlpKeysMap[mlpKeyFlat] = this._treeXYZ[xKey.id][yKey.id][zKey.id];
    }
  }

  // сортирую оси по а-а-b, a-a-c ...
  private sortAxes(axes: IEntity[]): void {
    axes = axes?.sort(this._compareFn);
  }

  private _compareFn = (a: IEntity, b: IEntity, index?: number): number => {
    let i = index || 0;
    const aa = a.ids;
    const bb = b.ids;
    if (i >= aa?.length || i >= bb?.length) return 1;
    else if (aa[i] > bb[i]) return 1;
    else if (aa[i] < bb[i]) return -1;
    else return this._compareFn(a, b, ++i);
  }

  // нахожу нужное дерево
  private _getBranchXYZ(flatXKey: string, flatYKey: string, flatZKey: string): IAggregator {
    if (flatXKey.length > 0 && flatYKey.length > 0 && flatZKey.length > 0) return this._treeXYZ?.[flatXKey]?.[flatYKey]?.[flatZKey];
    if (flatXKey.length > 0 && flatYKey.length > 0 && flatZKey.length === 0) return this._treeXY?.[flatXKey]?.[flatYKey];

    if (flatZKey.length > 0 && flatYKey.length > 0 && flatXKey.length === 0) return this._treeXY?.[flatZKey]?.[flatYKey];


    if ((flatXKey.length > 0 || flatYKey.length > 0) && flatZKey.length > 0) {
      const x = flatXKey.length === 0 ? flatYKey : flatXKey;
      return this._treeXY?.[x]?.[flatZKey];
    }

    if (flatXKey.length === 0 && flatZKey.length === 0) return this._yAxisKeysMap?.[flatYKey];
    if (flatYKey.length === 0 && flatZKey.length === 0) return this._xAxisKeysMap?.[flatXKey];

    return null;
  }

  public getDataXY(x: number | string[], y: number | string[], z: number | string[] = null, hasTotal: boolean = false): { name: string, value: number } {
    if (x === undefined || y === undefined || z === undefined) {
      console.error('ошибка оси xAxis yAxis zAxis, ось не может быть undefined');
      debugger;
      return {name: 'error', value: null};
    }

    const xKey = Array.isArray(x) ? x : this.xAxisKeys[x]?.ids || [];
    const yKey = Array.isArray(y) ? y : this.yAxisKeys[y]?.ids || [];
    const zKey = Array.isArray(z) ? z : this.zAxisKeys[z]?.ids || [];
    let flatXKey = xKey.join(' ');
    let flatYKey = yKey.join(' ');
    let flatZKey = zKey.join(' ');

    if (flatXKey.length === 0 && flatYKey.length === 0 && flatZKey.length === 0) {
      return {name: 'error', value: null};
    }

    // TODO сделать субтоталы
    const xAxisHasSubtotal = !!(flatXKey?.match(/Итого/));
    const yAxisHasSubtotal = !!(flatYKey?.match(/Итого/));
    const hasSubtotal = xAxisHasSubtotal || yAxisHasSubtotal;

    if (xAxisHasSubtotal && yAxisHasSubtotal) return {name: 'null', value: null};

    const branch = this._getBranchXYZ(flatXKey, flatYKey, flatZKey);

    let value = branch?.getValue() ?? null;

    if (hasTotal) value = branch?.getTotal() ?? null;

    return {
      value,
      name: 'value',
    };
  }


  // TODO Z ??

  // сортировка по одной из оси по возрастанию или убыванию
  public orderCells(x?: number | null, y?: number | null): void {

    // ссылка на массив
    let currentAxis: IEntity[] = y === null ? this.yAxisKeys : this.xAxisKeys;

    // ссылка на order
    let order = y === null ? this.order.x : this.order.y;
    order.sort = order.sort === 'asc' ? 'desc' : 'asc';

    const hashList: { [key: string]: number } = {};
    let tempGroupIdx = 0;

    currentAxis.forEach((v, i) => {
      const key: any = v.ids.length === 1 ? v.ids : v.ids.slice(0, v.ids.length - 1);
      const flatKey = key.join(' ');
      if (!hashList[flatKey]) {
        hashList[flatKey] = tempGroupIdx;
        ++tempGroupIdx;
      }
    });

    const orderNum: 1 | -1 = order.sort === 'asc' ? -1 : 1;

    currentAxis.sort((a, b) => {
      const keyA = a.ids.length === 1 ? a.ids : a.ids.slice(0, a.ids.length - 1);
      const keyB = b.ids.length === 1 ? b.ids : b.ids.slice(0, b.ids.length - 1);
      const flatKeyA = keyA.join(' ');
      const flatKeyB = keyB.join(' ');

      if (flatKeyA !== flatKeyB && a.ids.length > 1 && b.ids.length > 1) return hashList[flatKeyA] - hashList[flatKeyB];
      else {
        const valueA = this.getDataXY(x !== null ? x : a.ids, y !== null ? y : a.ids, null)?.value || null;
        const valueB = this.getDataXY(x !== null ? x : b.ids, y !== null ? y : b.ids, null)?.value || null;

        if (!valueA && !valueB) return 0;
        else if (!valueB && valueA) return -1;
        else if (!valueA && valueB) return 1;
        else return orderNum * (valueA - valueB);
      }
    });

  }

  // сортировка по алфавиту
  public orderLabels(x: number | null, y: number | null, srt: string): void {
    const hashList: { [key: string]: number } = {};
    // ссылка на нужный массив
    let currentAxis: IEntity[] = [];
    if (x === null) {
      let tempGroupIdx = 0;
      this.yAxisKeys.forEach((v, i) => {
        const xKey: any = y === 0 ? v.ids.slice(0, 1) : v.ids.slice(0, y);
        const flatXkey = xKey.join(' ');
        if (!hashList[flatXkey]) {
          hashList[flatXkey] = tempGroupIdx;
          ++tempGroupIdx;
        }
      });
      currentAxis = this.yAxisKeys;
    }
    if (y === null) {
      let tempGroupIdx = 0;
      this.xAxisKeys.forEach((v, i) => {
        const xKey: any = x === 0 ? v.ids.slice(0, 1) : v.ids.slice(0, x);
        const flatXkey = xKey.join(' ');
        if (!hashList[flatXkey]) {
          hashList[flatXkey] = tempGroupIdx;
          ++tempGroupIdx;
        }
      });
      currentAxis = this.xAxisKeys;
    }

    currentAxis.sort((a, b) => {
      let len = -1;
      if (x === null) len = y === 0 ? 1 : y;
      if (y === null) len = x === 0 ? 1 : x;

      const keyA = a.ids.length === 1 ? a.ids : a.ids.slice(0, len);
      const keyB = b.ids.length === 1 ? b.ids : b.ids.slice(0, len);
      const flatKeyA = keyA.join(' ');
      const flatKeyB = keyB.join(' ');
      if (flatKeyA !== flatKeyB && a.ids.length !== 1 && b.ids.length !== 1) {
        const keyAA = len === 1 ? a.ids.slice(0, 1) : a.ids.slice(0, len);
        const keyBB = len === 1 ? b.ids.slice(0, 1) : b.ids.slice(0, len);
        const flatKeyAA = keyAA.join(' ');
        const flatKeyBB = keyBB.join(' ');
        if (x === 0) return srt === 'desc' ? flatKeyAA.localeCompare(flatKeyBB) : flatKeyBB.localeCompare(flatKeyAA);
        return hashList[flatKeyAA] - hashList[flatKeyBB];
      } else {
        const valueA = a.ids[x !== null ? x : y];
        const valueB = b.ids[x !== null ? x : y];
        return srt === 'desc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    });
  }

  public getMLP(xs: IEntity | IEntity[], ys: IEntity | IEntity[], zs: IEntity | IEntity[]): { m: IMetric[], l: ILocation[], p: IPeriod[] } {
    const result = {m: [], l: [], p: []};
    let hash = {m: {}, l: {}, p: {}};
    const x = Array.isArray(xs) ? xs : [xs];
    const y = Array.isArray(ys) ? ys : [ys];
    const z = Array.isArray(zs) ? zs : [zs];

    z.forEach((z) => {
      y.forEach((y) => {
        x.forEach((x) => {

          const flatX = x?.id ?? '';
          const flatY = y?.id ?? '';
          const flatZ = z?.id ?? '';
          const branch = this._getBranchXYZ(flatX, flatY, flatZ);
          const mlp = branch?.getMLP();
          if (mlp?.m && mlp?.l && mlp?.p) {
            const mId = mlp.m.id;
            const lId = mlp.l.id;
            const pId = mlp.p.id;
            if (!hash.m[mId]) {
              hash.m[mId] = true;
              result.m.push(mlp.m);
            }
            if (!hash.l[lId]) {
              hash.l[lId] = true;
              result.l.push(mlp.l);
            }
            if (!hash.p[pId]) {
              hash.p[pId] = true;
              result.p.push(mlp.p);
            }
          }
        });
      });
    });
    hash = null;
    return result;
  }

  public putMLPValue(mid: string, lid: string, pid: string, value: IValue): void {
    const flatMlp = [mid, lid, pid].join(' ');
    this._mlpKeysMap[flatMlp].putValue(value);
  }

  public createMLPCube(zs: any[], ys: any[], xs: any[]): IValue[][][] {
    const cude: IValue[][][] = [];

    zs.forEach((zObj, i) => {
      if (!cude[i]) cude[i] = [];
      const zId = zObj.ids;
      ys.forEach((yObj, k) => {
        if (!cude[i][k]) cude[i][k] = [];
        const yId = yObj.ids;
        xs.forEach((xObj) => {
          const xId = xObj.ids;
          const {value} = this.getDataXY(xId, yId, zId);
          cude[i][k].push(value);
        });
      });
    });

    return cude;
  }

  // для нахождения
  public getXs(mid: string, lid: string, pid: string): string | null {
    const flatMlp = [mid, lid, pid].join(' ');
    const branch = this._mlpKeysMap[flatMlp];
    return branch?.getXs() || null;
  }
}

