import { AppConfig, BaseService, extractErrorMessage, IBaseModel } from '@luxms/bi-core';
import { DataSourceInspectorService } from '../../../services/QRPCService';
import axios from 'axios';

export interface IMitoDataResponse {
  columns: any[];
  rows: string[][];
  rowNumbers: number[];
  query: string;
  execTime: number;
  error: string;
}

interface IMitoDocumentsResponse {
  created: number;
  documents: IMitoDocumentResponse[];
  macros: any;
  sourceId: string;
}

interface IMitoDocumentResponse {
  documentId: string;
  fileName: string;
  metadata: any;
  partName: string;
  progress: number;
  size: number;
  sourceFile: string;
  tableContainers: any;
}

interface IMitoSheetResponse {
  '@class': string;
  documentId: string;
  facets: any;
  index: number;
  name: string;
  rowsCount: number;
  sourceId: string;
  title: string;
  uri: string;
}

export interface IMitoDocumentModel {
  id: string;
  title: string;
  fileName: string;
  progress: number;
}

export interface IMitoSheetModel {
  documentId: string;
  index: number;
  title: string;
  code: string;
  data: IMitoDataResponse;
}

export interface MitoDataServiceModel extends IBaseModel {
  documents: IMitoDocumentModel[];
  sourceId: string;
  sheets: IMitoSheetModel[];
}

class MitoDataService extends BaseService<MitoDataServiceModel> {
  private static MODEL: MitoDataServiceModel;
  private _timeoutId: number = null;

  public constructor() {
    super({loading: false, error: null, documents: [], sheets: [], sourceId: null});
  }

  public async dropDocument(docId: string): Promise<void> {
    const {sourceId, sheets} = this._model;
    const mitoDocumentsResponse: IMitoDocumentsResponse = await DataSourceInspectorService.dropDocument(sourceId, docId);
    const documents = (mitoDocumentsResponse?.documents ?? []).map(d => ({
      id: d.documentId,
      fileName: d.fileName,
      title: d.fileName,
      progress: d.progress
    }));
    const newSheets = sheets.filter(s => s.documentId !== docId);
    this._updateModel({documents, sheets: newSheets});
    this._maybeRestartTimer(mitoDocumentsResponse.documents);
  }

  public async addDocuments(files: File[]): Promise<void> {
    try {
      this._updateModel({error: null, loading: true});

      const {sourceId} = this._model;
      const url = AppConfig.fixRequestUrl('/srv/datagate/source/upload');
      const formData = new FormData();
      files.forEach(file => formData.append('source', file));
      if (sourceId) formData.append('sourceId', sourceId);

      const mitoDocumentsResponse: IMitoDocumentsResponse = (await axios.post<IMitoDocumentsResponse>(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;

      const documents = (mitoDocumentsResponse?.documents ?? []).map(d => ({
        id: d.documentId,
        fileName: d.fileName,
        title: d.fileName,
        progress: d.progress
      }));

      this._updateModel({documents, sourceId: mitoDocumentsResponse.sourceId});
      this._maybeRestartTimer(mitoDocumentsResponse.documents);

    } catch (err) {
      console.error(err);
      this._updateModel({error: extractErrorMessage(err), loading: false, documents: [], sheets: []});
    }
  }

  private _maybeRestartTimer = (documents: IMitoDocumentResponse[]): void => {
    if (this._timeoutId !== null) {
      window.clearTimeout(this._timeoutId);
      this._timeoutId = null;
    }

    const hasDocumentsInProgress = documents.some(doc => doc.progress !== 1);
    if (hasDocumentsInProgress) this._timeoutId = window.setTimeout(this._reloadDocuments, 3000);
  }

  private _reloadDocuments = async (): Promise<void> => {
    const {sourceId} = this._model;
    try {
      const mitoDocumentsResponse: IMitoDocumentsResponse = await DataSourceInspectorService.getDocuments(sourceId);
      const documents = (mitoDocumentsResponse?.documents ?? []).map(d => ({
        id: d.documentId,
        title: d.fileName,
        fileName: d.fileName,
        progress: d.progress
      }));
      this._updateModel({documents});
      this._loadSheetAndData();
      this._maybeRestartTimer(mitoDocumentsResponse.documents);

    } catch (err) {
      console.error(err);
      this._updateModel({error: extractErrorMessage(err), loading: false, documents: [], sheets: []});
    }
  }

  private _loadSheetAndData = async (): Promise<void> => {
    const {documents, sheets, sourceId} = this._model;
    const documentLoad = documents.filter(d => d.progress === 1);
    const sheetCopy = [...sheets];
    let sheetsResponse: IMitoSheetResponse[] = [];
    for (let i = 0; i < documentLoad.length; i++) {
      const doc = documentLoad[i];
      if (!sheets.find(s => s.documentId === doc.id)) {
        const sheetsReq: any[] = await DataSourceInspectorService.getTables(sourceId, doc.id);
        sheetsResponse = sheetsResponse.concat(sheetsReq);
      }
    }

    for (let j = 0; j < sheetsResponse.length; j++) {
      const sheetResponse: IMitoSheetResponse = sheetsResponse[j];
      const code = `openDocument("${sheetResponse.documentId}")\nselectSheet(${sheetResponse.index})`;
      const data: IMitoDataResponse = await DataSourceInspectorService.sampleFirstRows(sourceId, code, 100, 0);
      const index = sheetResponse.index;
      const sheet: IMitoSheetModel = {
        documentId: sheetResponse.documentId,
        title: sheetResponse.title,
        data,
        code,
        index
      };
      sheetCopy.push(sheet);
      this._updateModel({sheets: sheetCopy});
      this._dateAnalysis(sheet);
    }

    if (documentLoad.length === documents.length) this._updateModel({loading: false});
  }

  // нейросеть!! ищет все и преобразует все
  private _dateAnalysis = (sheet: IMitoSheetModel): any => {
    const data = sheet.data;
    return sheet;
  }

  public changeCode = async (docId: string, sheetName: string, code: string): Promise<void> => {
    const {sheets, sourceId} = this._model;
    const idx = sheets.findIndex(s => s.documentId === docId && s.title === sheetName);
    const sheet = sheets[idx];
    if (!sheet || sheet.code === code) return;
    try {
      sheets[idx].data = await DataSourceInspectorService.sampleFirstRows(sourceId, code, 100, 0);
      sheet.code = code;
      this._updateModel({sheets: [...sheets], error: null});
    } catch (error) {
      this._updateModel({sheets: [...sheets], error: extractErrorMessage(error)});
    }
  }


  protected _dispose() {
    super._dispose();
  }

}

export default MitoDataService;