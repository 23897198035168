import React from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import './ExpandableSearch.scss';
import { BIIcon } from '../BIIcon/BIIcon';

interface IExpandableSearchProps {
  onSearchTermChanged: (searchTerm: string) => any;
  onToggleExpanded?: (expanded: boolean) => any;
}

// todo удалить когда старый DatasetsListView умрет
/**
 * @deprecated
 * @deprecated Использовался в старом компоненте DatasetsListView (стартовый экран)
 */
export class ExpandableSearch extends React.Component<IExpandableSearchProps> {
  public state: {
    searchTerm: string;
    expanded: boolean;
  } = {
    searchTerm: '',
    expanded: false,
  };

  private _notifySearchTermChanged = debounce(this.props.onSearchTermChanged, 55);

  private _onSearchTermChanged = (event) => {
    this.setState({searchTerm: event.target.value});
    this._notifySearchTermChanged(event.target.value);
  }

  private _onToggle = () => {
    let {expanded, searchTerm} = this.state;
    expanded = !expanded;
    if (expanded) {
      window.setTimeout(() => $('.ExpandableSearch__SearchTerm').focus(), 55);
    } else {
      searchTerm = '';
      this._notifySearchTermChanged('');
    }
    this.setState({expanded, searchTerm});
    if (this.props.onToggleExpanded) {
      this.props.onToggleExpanded(expanded);
    }
  }

  public render() {
    const {searchTerm, expanded} = this.state;
    return (
      <div className={ cn('ExpandableSearch', {expanded}) }>
        <BIIcon icon="search"
                onPress={ this._onToggle }
                className="ExpandableSearch__Icon bi-icon light"
                //text={ lang('search') }
                />
        <input type="text"
               ref="searchElement"
               className="ExpandableSearch__SearchTerm"
               value={ searchTerm || '' }
               onChange={ this._onSearchTermChanged }/>
      </div>);
  }
}
