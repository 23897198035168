/**
 *  Root
 *  displaying logo and tabs
 */

import React from 'react';
import './Root.scss';
import {IRootVM} from '../../view-controllers/Root/RootVC';
import {RootContent} from './RootContent';
import {Strap} from '@luxms/bi-face';
import {RootMenu} from './RootMenu';
import {RootHeader} from './RootHeader';
import LoadFromResources from '../components/LoadFromResources';

export class Root extends React.Component<IRootVM> {
  public render() {
    const {activeTabIndex, activeChildIndex, tabs} = this.props;
    return (
      <LoadFromResources path="Root.js" activeTabIndex={activeTabIndex} tabs={tabs}>
        <section className="Root view roots">
          <Strap>
            <RootMenu activeTabIndex={activeTabIndex} activeChildIndex={activeChildIndex} tabs={tabs}/>
            <Strap.Body>
              <RootHeader/>
              <RootContent {...this.props}/>
            </Strap.Body>
          </Strap>
        </section>
      </LoadFromResources>);
  }
}


export default Root;

