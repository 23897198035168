const skin = require('../skins/skin.json');

export const COLOR_PALETTE = skin.colorPallete ?? [
  "#AA6FAC",
  "#E85498",
  "#4F4F9B",
  "#4AB6E8",
  "#E07921",
  "#5FB138",
  "#F05045",
  "#F2BB05",
  "#9797C4",
];

export const DATA_GREEN = "#5FB138";
export const DATA_RED = "#F05045";
export const DATA_YELLOW = "#F2BB05";

export default COLOR_PALETTE;
