/**
 *
 *
 *
 */

import React from 'react';
import './BaseVizel.scss';
import $ from 'jquery';
import { BaseVizelVC, IVizelVM } from '../../view-controllers/vizels/BaseVizelVC';
import { EntityColorResolver } from '../../config/color-resolvers';
import { parseVizelTypeString } from '../../utils/utils';
import { extractErrorMessage } from '@luxms/bi-core';
import { data_engine } from '../../data-manip/data-manip';
import { IVizelConfig, IVizelProps } from '../../services/ds/types';

import {
  IColorResolver,
  IDisposable,
  ISubspace,
  IVCPV,
  IVizel, IVizelConfigDisplay, IVizelDescription,
  IVizelListener,
  IVizelProperties,
  tables
} from '../../defs/bi';
import { cloneDeep } from 'lodash';


export abstract class BaseVizel<VM extends IVizelVM> extends React.Component<IVizelProps> implements IVizel {
  protected _vc: BaseVizelVC<VM>;
  protected _vm: VM;
  public _$container: JQuery = null;
  protected _$prevContainer: JQuery = null;
  protected _dp: data_engine.IDataProvider;
  public _cfg: IVizelConfig;
  protected _colorResolver: IColorResolver;
  public _properties: IVizelProperties = {saveAbilities: []};
  private _vcSubscription: IDisposable = null;
  private _rtSubscription: IDisposable = null;
  private _internalErrorMessage: string = null;
  private _loadingFirstTime: boolean = true;

  public _listener: IVizelListener = null;

  protected constructor(viewController: BaseVizelVC<VM>, props: IVizelProps) {
    super(props);
    this._vc = viewController;
    this._vm = this._vc.getModel();
    this._dp = props.dp;
    this._cfg = props.cfg.clone();
    this._listener = props.listener;
    this._colorResolver = this._cfg.colorResolver || new EntityColorResolver();
    window.setTimeout(() => this.setAxes(props.subspace), 0);
  }


  protected _viewModelChanged(vm: VM): void {
    try {
      const prevVM: VM = this._vm;
      this._vm = vm;

      this._syncLoadingStatus();

      if (vm.error) {
        this._onError(vm.error);
        return;
      }

      // if (this._vm.data != prevVM.data || this._$container !== this._$prevContainer) {
      //   this._viewModelDataChanged(this._vm.data, prevVM.data);
      this._viewModelDataChanged(this._vm, prevVM);
      this._$prevContainer = this._$container;
      // }
    } catch (err) {
      this._internalError(err);
    }
  }

  // deprecated
  protected _viewModelDataChanged(vm: VM, prevVM: VM): void {
    // override
  }

  protected _internalError(err: any): void {
    console.error('Vizel View: internal error');
    console.error(err);
    this._internalErrorMessage = extractErrorMessage(err);
    this._onError(this._internalErrorMessage);
  }

  public attached(container: HTMLElement): void {
    console.assert(this._$container === null);
    this._$container = $(container);
    this._$container.addClass(this._getVizelType());
    this._syncLoadingStatus();

    console.assert(this._vcSubscription === null);
    this._vcSubscription = this._vc.subscribeUpdatesAndNotify((vm: VM) => this._viewModelChanged(vm));

    console.assert(this._rtSubscription === null);
    this._rtSubscription = this._vc.subscribe('updateValue', (vcpv) => {
      try {
        this._rtValueUpdated(vcpv);
      } catch (err) {
        //
      }
    });
  }

  protected _dispose() {
    //
  }

  public dispose = (): void => {
    console.assert(this._$container !== null);
    this._dispose();
    if (this._vcSubscription) {
      this._vcSubscription.dispose();
      this._vcSubscription = null;
    }

    if (this._rtSubscription) {
      this._rtSubscription.dispose();
      this._rtSubscription = null;
    }
    this._$container = null;
  }

  protected _syncLoadingStatus(): void {
    if (!this._$container) {
      return;
    }
    const {loading, error} = this._vm;
    const hasData: boolean = this._hasData(this._vm);

    if (!loading) {
      this._loadingFirstTime = false;
    }

    // TODO: Check this._internalError (js-exceptions, ...)

    if (error) {
      this._onError(error);
      return;
    }
    this._$container.removeClass('error');        // no error

    if (loading) {
      this._$container.removeClass('no-data').addClass('loading');
      if (!hasData || this._loadingFirstTime) {
        this._$container.addClass('loadingFirstTime');
      } else {
        this._$container.removeClass('loadingFirstTime');
      }
      return;
    }
    this._$container.removeClass('loading').removeClass('loadingFirstTime');

    if (!hasData) {
      this._renderNoData();
    } else {
      this._$container.removeClass('no-data');
    }
  }

  protected _hasData(vm: VM): boolean {
    return (vm !== null) && !vm.noData;
  }

  protected _renderNoData(): void {
    this._$container.addClass('no-data');
  }

  protected _onError(err: any): void {
    console.error(err);
    if (this._$container) {
      this._$container.empty().addClass('error');
      let errorMessage: string = err.message || err.responseText;
      try {
        const json = JSON.parse(errorMessage);
        errorMessage = json.message || json.error || json;
      } catch (ex) {
        if (err.status === 404) {    // some html message: error 404
          errorMessage = '404 not found';
        }
      }
      if (errorMessage) {
        this._$container.text(errorMessage);
      }
    }
  }

  protected _rtValueUpdated(vcpv: IVCPV): void {
    // override it
  }

  // IVizel
  public async setAxes(subspace: ISubspace): Promise<any> {
    const result: any = await this._vc.setAxes(subspace);
    return result;
  }

  public resize(width?: number, height?: number): void {
    // ...
  }

  protected _setProperty(key: string, value: any): boolean {
    return false;
  }

  public setProperties(o: { [id: string]: any }): boolean {
    let shouldRedraw: boolean = false;
    for (let key in o) {
      if (o.hasOwnProperty(key)) {
        let value: any = o[key];
        // 1. save value to config
        const isOption: boolean = !!key.match(/^[A-Z]/);
        if (isOption) {

          this._cfg.setOption(key, !!value);
        } else {
          this._cfg.setProperty(key, value);
        }

        // 2. run custom handler
        if (this._setProperty(key, value)) {
          shouldRedraw = true;
        }
      }
    }
    return shouldRedraw;
  }

  public _notifyPropertiesChanged(): void {
    const {onVizelPropertiesChanged} = this.props;
    if (this._listener) {
      this._listener.onVizelPropertiesChanged(this._properties, this);
    }
    if (onVizelPropertiesChanged) onVizelPropertiesChanged(this._properties, this);
  }

  public _setSaveAbilities(saveAbilities: string[]): void {
    this._properties.saveAbilities = saveAbilities;
    this._notifyPropertiesChanged();
  }

  public getProperties(): IVizelProperties {
    return this._properties;
  }

  public addListener(listener: IVizelListener): void {
    this._listener = listener;
  }

  public removeListener(listener: IVizelListener): void {
    if (this._listener === listener) {
      this._listener = null;
    }
  }

  public save(saveAbility: string, titleContext: string[], $anchor?: JQuery): void {
    throw new Error('Not implemented');
  }

  public _getConfigDisplay(): IVizelConfigDisplay {
    return this._cfg.getDisplay(this._getVizelType());
  }

  protected _getVizelType(): string {
    const descr: IVizelDescription = parseVizelTypeString(this._cfg.getVizelType());
    return descr.type;
  }

  protected _onClickDataPoint(event: any, vcpv: IVCPV): void {
    if (!this._cfg?.controller?.handleVCPClick) return;
    const {subspace} = this.props;

    // TODO: iterate over vcpv to get x, y, z, ...
    const cloneVCPV = cloneDeep(vcpv);
    const {x, y, z} = cloneVCPV;
    const xli: tables.ILegendItem = this._cfg.getLegendItem(x);
    const yli: tables.ILegendItem = this._cfg.getLegendItem(y);
    const zli: tables.ILegendItem = this._cfg.getLegendItem(z);

    if (yli && yli.onClickDataPoint) {                                  // first check y override config
      (event as any).eventDescription = yli.onClickDataPoint;

    } else if (xli && xli.onClickDataPoint) {                           // then - x
      (event as any).eventDescription = xli.onClickDataPoint;

    } else if (zli && zli.onClickDataPoint) {                           // then - z
      (event as any).eventDescription = zli.onClickDataPoint;
    }
    let filters = {};
    if (subspace.koob) {
      const modelFilter = {...subspace.filters};
      let hasMeasures = false;
      [z, y, x].forEach((axis) => {
        axis.axisIds.forEach((axisId, i) => {
          const id = axis.ids[i];
          const formula = axis.formula[i].replace(/\:.*/g, ''); // убрать id
          if (axisId !== 'measures') modelFilter[formula] = ['=', id];
          if (axisId === 'measures') hasMeasures = true;
        });
      });
      filters = modelFilter;
      // может быть так, что на xAxis && yAxis находятся дименшены + имеется одна меша = мы убарли её с оси.
      // т.к. мы все равно уберем мешу с оси нам вcе равно куда её подмешать, наверно.
      if (!hasMeasures) {
        const measure = subspace.measures[0]; // мы уверены в этом
        const axis = vcpv.y.axisIds.length ? y : x; // беру ссылку
        axis.axisIds.push('measures');
        axis.ids.push(measure.id);
        axis.titles.push(measure.title);
        axis.unit = measure.unit;
        axis.id = axis.ids.join(' ');
        axis.title = axis.titles.join(' ');
        axis.formula.push(measure.formula);
      }

    }

    this._cfg.controller.handleVCPClick(event, {...cloneVCPV, filters});
  }

  protected _onClickChart(event): void {
    if (!this._cfg?.controller?.handleChartClick) return;
    this._cfg.controller.handleChartClick(event, this.props.subspace);
  }
}

export default BaseVizel;
