/**
 * color resolver: разные сопосбы получить значение цвета для осей и данных на графиках
 */

import { fixColorPairEx } from './ColorPair';
import { IS_L, IS_M, IS_P } from '../utils/utils';
import { IColorPair, IColorResolver, IEntity, IValue } from '../defs/bi';
import COLOR_PALETTE from '../utils/color-palette';
import { ThemeVC } from '../view-controllers/ThemeVC';

export const INVALID_COLOR_PAIR: IColorPair = {color: null, bgColor: null};


export function isInvalidColorPair(cp: IColorPair): boolean {
  return (cp == null) || (cp.color == null && cp.bgColor == null);
}


export abstract class BaseColorResolver implements IColorResolver {
  public abstract getColorPair(e: IEntity, v?: IValue, idx?: number, colorPalette?: string[]): IColorPair;

  public getColor(e: IEntity, v: IValue, idx?: number, colorPalette?: string[]): string | null {
    return this.getColorPair(e, v, idx, colorPalette).color;
  }

  public getBgColor(e: IEntity, v: IValue, idx?: number, colorPalette?: string[]): string | null {
    return this.getColorPair(e, v, idx, colorPalette).bgColor;
  }
}


export class PaletteColorResolver extends BaseColorResolver {
  private _mi = 0;
  private _li = 0;
  private _pi = 0;
  private _ai = 0;
  private _mh: { [key: string]: string } = {};
  private _lh: { [key: string]: string } = {};
  private _ph: { [key: string]: string } = {};
  private _ah: { [key: string]: string } = {};
  private _colorPalette: string[] = COLOR_PALETTE;
  private _themeVc: ThemeVC;
  constructor() {
    super();
    this._themeVc = ThemeVC.getInstance();
    this._themeVc.subscribeUpdatesAndNotify((model) => {
      if (model.currentTheme.hasOwnProperty('themeBuilder')) {
        this._colorPalette = model.currentTheme.themeBuilder;
      }
    });
  }

  public getColorPair(e: IEntity, v?: IValue, idx?: number, colorPalette?): IColorPair {
    const colorPallets = (colorPalette ?? []).filter(Boolean).length ? colorPalette : this._colorPalette;
    const id: string | number = e.id;
    let color: string | null;
    if (IS_M(e) && (id in this._mh)) color = this._mh[id];
    else if (IS_L(e) && (id in this._lh)) color = this._lh[id];
    else if (IS_P(e) && (id in this._ph)) color = this._ph[id];
    else if (IS_M(e)) color = (this._mh[id] = colorPallets[this._mi++ % colorPallets.length]);
    else if (IS_L(e)) color = (this._lh[id] = colorPallets[this._li++ % colorPallets.length]);
    else if (IS_P(e)) color = (this._ph[id] = colorPallets[this._pi++ % colorPallets.length]);
    else if (id in this._ah) color = this._ah[id];                                                          // unknown entity axis
    else color = (this._ah[id] = colorPallets[this._ai++ % colorPallets.length]);

    return fixColorPairEx({color});
  }
}


// extracts color from config.color if any
export class EntityConfigColorResolver extends BaseColorResolver {
  public getColorPair(e: IEntity, v?: IValue): IColorPair {
    let {color, bgColor} = e.config || {};
    return fixColorPairEx({color, bgColor});
  }
}


export class TextVizelColorResolver extends BaseColorResolver {
  public constructor(opt?: any) {
    super();
    // const BAD_COLOR = '#ff3333';
    // const GOOD_COLOR = '#229922';
    // const NORMAL_COLOR = '#222299';
    //
    // const _badColor = 'badColor' in opt ? makeColor(opt.badColor) : BAD_COLOR;
    // const _goodColor = 'goodColor' in opt ? makeColor(opt.goodColor) : GOOD_COLOR;
    // const _normalColor = 'normalColor' in opt ? makeColor(opt.normalColor) : NORMAL_COLOR;
  }

  public getColorPair(e: IEntity, v?: IValue): IColorPair {
    // return {
    //   color: '#222299',
    //   bgColor: '#AAAAFF',
    // };
    return null;
  }
}


//
// probably the last chance to get color
//
export class EntityColorResolver extends BaseColorResolver {
  public getColorPair(e: IEntity, v?: IValue): IColorPair {
    return fixColorPairEx(e);                                 // e has 'color' and 'bgColor'
  }
}


// chain color resolver
export class ColorResolversChain extends BaseColorResolver {
  private readonly _resolvers: IColorResolver[];

  public constructor(...resolvers: IColorResolver[]) {
    super();
    this._resolvers = resolvers || [];
  }

  public getColorPair(e: IEntity, v?: IValue, idx?: number, colorPalette?: string[]): IColorPair {
    for (let resolver of this._resolvers) {
      if (resolver && resolver.getColorPair) {
        const cp = resolver.getColorPair(e, v, idx, colorPalette);
        if (!isInvalidColorPair(cp)) {
          return cp;
        }
      } else if (resolver && (resolver.getColor || resolver.getBgColor)) {
        const cp = {
          color: resolver.getColor(e, v, idx),
          bgColor: resolver.getBgColor(e, v, idx),
        };
        if (!isInvalidColorPair(cp)) {
          return cp;
        }
      }
    }
    return INVALID_COLOR_PAIR;
  }
}
