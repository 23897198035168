import { BaseService, RtService, srv, repo } from '@luxms/bi-core';
import { IEntity, IKoobDimension, IKoobMeasure, ISubspace, ISubspacePtr, tables } from '../../defs/bi';
import { IKoobFiltersModel, KoobFiltersService } from './KoobFiltersService';
import isEqual from 'lodash/isEqual';
import { $eid } from '../../libs/imdas/list';
import { koobCountRequest3, koobDataRequest3 } from './KoobDataService';
import { nEntities, lang, fixMeasureFormula, extractMeasureId, extractMeasureTitle } from '../../utils/utils';
import { ISubspaceModel, makeNullAxis } from '../ds/createSubspaceGenerator';
import IDataSource = tables.IDataSource;
import axios, { CancelTokenSource } from 'axios';
import { DsVarsService, IDsVarsModel } from '../ds/DsVarsService';
import { KoobDimensionsMemberService } from './KoobDimensionsMemberService';
import cloneDeep from 'lodash/cloneDeep';
import createService from '../createService';
import CanIService from '../CanIService';


const DEFAULT_LIMIT = 128; // если не задан limit в dataSource

// [esix] я потом поправлю название и смысл
const HackDimensionsService = createService<typeof srv.koob.DimensionsService.MODEL>(null, ({useService, useServiceItselfWithCustomSubscription}, schema_name, source_ident, cube_name) => {
  const globalClaim = `L koob.cubes`;
  const localClaim = `L ${schema_name}.cubes`;
  const claims = schema_name ? [globalClaim, localClaim] : [globalClaim];                           // нет атласа - смотрим только глобальне

  const canI: CanIService = useServiceItselfWithCustomSubscription(CanIService, claims);

  if (claims.some(claim => canI.can(claim) === undefined)) {                                        // какой-то клэйм из нужных не загружен => дожидаемся
    canI.ensure(claims);
    return Object.assign([], {error: null, loading: true});
  }

  const globalCubes: typeof srv.koob.CubesService.MODEL = canI.can(globalClaim) ? useService(srv.koob.CubesService) : [];
  const localCubes: typeof srv.ds.CubesService.MODEL = canI.can(localClaim) ? useService(srv.ds.CubesService, schema_name) : [];

  if (globalCubes.error || localCubes.error) return Object.assign([], {error: globalCubes.error || localCubes.error, loading: false});
  if (globalCubes.loading || localCubes.loading) return Object.assign([], {error: null, loading: true});

  let cube: repo.koob.IRawCube = null;
  if (!localCubes.error) cube = localCubes.find(c => c.source_ident === source_ident && c.name === cube_name && !c.is_global);
  if (!cube) cube = globalCubes.find(c => c.source_ident === source_ident && c.name === cube_name);

  if (!cube) return Object.assign([], {error: `No cube '${cube_name}' found (with source='${source_ident}')`, loading: false});

  const isGlobal = cube.is_global !== 0;                  // undefined or === 1

  const dimensions = isGlobal ?
      useService(srv.koob.DimensionsService, source_ident, cube_name) :
      useService(srv.ds.DimensionsService, schema_name, source_ident, cube_name);
  return Object.assign(dimensions, {is_global: cube.is_global});              // сюда же дописываем is_global
});


/**
 * KoobSubspaceAsyncService
 * скачиваю данные по осям, максимум по 128 на ось
 * у subspace метод async createSubspaceAxes (x[from,to], y[from,to]) - возвращает subspace с новыми осями
 * у subspace метод async getXsLength -> count соси X
 * у subspace метод async getYsLength -> count соси Y
 * у subspace метод async sortBy(id:string) -> возвращает новый subspace отсортированный по id + || -
 */
export class KoobSubspaceAsyncService extends BaseService<ISubspaceModel> {
  private readonly _subspacePtr: ISubspacePtr;
  private readonly _schemaName: string;
  private _sortBy: string[] = [];
  private _limit: number = null;
  private _subtotals: string[] = [];

  // service
  private readonly _dimensionsService: srv.koob.DimensionsService; // typeof HackDimensionsService.MODEL; // типизация `createService` пока не работает
  private readonly _koobFiltersService: KoobFiltersService;
  private readonly _unitsService: srv.ds.UnitsService;
  private readonly _dsKoobValuesService: DsVarsService;
  private readonly _koobDimensionsMemberService: KoobDimensionsMemberService;
  private readonly _RtService: RtService;
  //
  private _cancelToken: CancelTokenSource | null = null;

  public constructor(schemaName: string, subspacePtr: ISubspacePtr) {
    super({
      error: '',
      loading: true,
      subspace: null,
    });
    this._schemaName = schemaName;
    const [source_ident, cube_name] = subspacePtr.koob.split('.');

    // init all service
    this._dimensionsService = new HackDimensionsService(schemaName, source_ident, cube_name) as any;        // типизация хворает
    this._unitsService = srv.ds.UnitsService.createInstance(schemaName);
    this._koobFiltersService = KoobFiltersService.getInstance();
    this._dsKoobValuesService = DsVarsService.createInstance(schemaName);
    this._koobDimensionsMemberService = KoobDimensionsMemberService.createInstance(source_ident, cube_name);
    this._RtService = RtService.getInstance();

    // copy
    this._sortBy = subspacePtr.dataSource?.sortBy?.split(';') || [];
    this._subtotals = (subspacePtr.dataSource?.subtotals || null)?.split(';') || [];
    this._limit = subspacePtr.dataSource?.limit ?? DEFAULT_LIMIT;
    this._subspacePtr = subspacePtr;
    this._init();
  }

  private _init(): void {
    this._dsKoobValuesService.subscribeUpdatesAndNotify(this._onVarsUpdated);
    this._koobFiltersService.subscribeUpdatesAndNotify(this._onFilterUpdated);
    this._dimensionsService.subscribeUpdatesAndNotify(this._onUpdateSubspace);
    this._RtService.subscribeDataset(this._subspacePtr.koob, this._onUpdateSubspace);
  }

  protected _dispose() {
    this._dimensionsService.unsubscribe(this._onUpdateSubspace);
    this._dimensionsService.release();
    this._koobFiltersService.unsubscribe(this._onFilterUpdated);
    this._dsKoobValuesService.unsubscribe(this._onVarsUpdated);
    this._RtService.unsubscribeDataset(this._subspacePtr.koob, this._onUpdateSubspace);
    super._dispose();
  }

  // upd subspace when koobSrv changed
  private _onUpdateSubspace = async (): Promise<void> => {
    const dimensions = this._dimensionsService.getModel();
    if (dimensions.error) return;
    if (dimensions.loading) return;

    await this._unitsService.whenReady();
    await this._dsKoobValuesService.whenReady();
    const subspace: any = await this._createSubspace([0, this._limit], [0, this._limit]);
    this._updateWithData({subspace});
  }

  // upd subspace when filters service changed
  private _onFilterUpdated = (model: IKoobFiltersModel, oldModel?: IKoobFiltersModel): void => {
    if (model.loading) return;
    const newFilter = this._makeFilters(model.filters);
    const oldFilter = this._makeFilters(oldModel?.filters);
    if (isEqual(newFilter, oldFilter)) return;
    this._onUpdateSubspace();
  }

  // upd vars dataset
  private _onVarsUpdated = (model: IDsVarsModel, oldModel?: IDsVarsModel): void => {
    if (model.loading) return;
    const uniqVars = getVars(this._subspacePtr.dataSource.measures || []);
    const newVars = uniqVars.map(m => (model?.dsVars ?? []).find(d => d.ident === m)).filter(Boolean);
    const oldVars = uniqVars.map(m => (oldModel?.dsVars ?? []).find(d => d.ident === m)).filter(Boolean);
    if (isEqual(newVars, oldVars)) return;
    this._onUpdateSubspace();
  }

  // создаю фильтры
  private _makeFilters = (globalFilters = {}): { [id: string]: string[] } => {
    const cfgFilters = this._subspacePtr.dataSource.filters;

    if (!cfgFilters) return {};
    let filters = {};

    if (Array.isArray(cfgFilters)) cfgFilters.forEach(filter => filters[filter] = true);
    else if (cfgFilters && typeof cfgFilters === 'object') filters = {...cfgFilters};

    Object.keys(filters).forEach((keyId) => {
      if (filters[keyId] === true) filters[keyId] = globalFilters[keyId];
    });

    return filters;
  }

  // создаю xAxis:'', yAxis:''
  private _makeAxes(dimensions: IKoobDimension[]): { xAxis: any[], yAxis: any[], zAxis: any[] } {
    const dataSource: any = this._subspacePtr.dataSource;
    const axes: any[] = [{id: 'measures', title: 'Метрика'}, ...dimensions];    // measures делаю будто бы dimension

    const xAxisCfg = (dataSource.xAxis || '').split(';');
    const yAxisCfg = (dataSource.yAxis || '').split(';');
    const zAxisCfg = (dataSource.zAxis || '').split(';');

    let xAxis: any[] = [];
    let yAxis: any[] = [];
    let zAxis: any[] = [];

    axes.forEach((d) => {
      const idx: number = xAxisCfg.indexOf(d.id);
      const idy: number = yAxisCfg.indexOf(d.id);
      const idz: number = zAxisCfg.indexOf(d.id);
      xAxis[idx] = d;
      yAxis[idy] = d;
      zAxis[idz] = d;
    });

    xAxis = xAxis.filter(Boolean);
    yAxis = yAxis.filter(Boolean);
    zAxis = zAxis.filter(Boolean);

    // если не выбраны оси действуем по дефолту x=dimension ,y=measures
    if (!xAxis.length && !yAxis.length) {
      xAxis = dimensions;
      yAxis = [{id: 'measures', title: 'Метрика'}];
      zAxis = [];
    }

    return ({xAxis, yAxis, zAxis});
  }

  //
  private _makeMeasures(): IKoobMeasure[] {
    const dataSource: tables.IDataSource = this._subspacePtr.dataSource;
    const {style = {}} = dataSource;

    const columns: repo.koob.IRawDimension[] = this._dimensionsService.getModel();
    const datasetUnits = (this._unitsService.getModel()).filter(e => e?.id);
    const allVars = this._dsKoobValuesService?.getModel()?.dsVars || [];

    const measures = (dataSource.measures || []).map(cfgMeasure => {
      const vars = getVars(cfgMeasure);
      let formula: string = fixMeasureFormula(cfgMeasure);

      // если у формулы есть переменные
      if (vars.length) {
        vars.forEach((v) => {
          const dsVar = allVars.find(dVar => dVar?.ident === v);
          const regex = new RegExp(`\\$${v}`, 'gi');
          formula = formula.replace(regex, dsVar?.default_value ?? '');
        });
      }

      const id: string = extractMeasureId(formula);
      const title: string = extractMeasureTitle(formula, columns, this._subspacePtr.dataSource.style);

      let type: string = 'FN';
      if (cfgMeasure.startsWith('sum_') || cfgMeasure.startsWith('sum(')) type = `SUM`;
      if (cfgMeasure.startsWith('count_') || cfgMeasure.startsWith('count(')) type = `COUNT`;
      if (cfgMeasure.startsWith('avg_') || cfgMeasure.startsWith('avg(')) type = `AVG`;

      let columnName: string = '';
      if (cfgMeasure.match(/^(\w+?)_(\w+)/)) {
        type = RegExp.$1.toUpperCase();
        columnName = RegExp.$2;
      } else if (cfgMeasure.match(/^(\w+)\((\w+)\)/)) {
        type = RegExp.$1.toUpperCase();
        columnName = RegExp.$2;
      } else columnName = id;

      let format: string = '';
      let config: any = {};
      const unitId: any = style.measures?.[id]?.unit_id || null;
      const unit = $eid(datasetUnits, unitId);

      let koobMeasure: repo.koob.IRawDimension | null = columns.find(c => c.name === columnName);
      if (koobMeasure?.config) config = koobMeasure.config;

      koobMeasure = columns.find(c => c.name === cfgMeasure);
      if (koobMeasure?.config) config = koobMeasure.config;

      return {id, name: columnName, axisId: 'measures', formula, title, format, type, sql: '', unit, config};
    });
    return measures;
  }

  // создаю xs,ys
  private _makeAxis(data: any, options: { id: string, title: string, formula: string }[], measures: IKoobMeasure[], titles: { id: number, title: string }[][]): IEntity {
    const axis: IEntity = makeNullAxis();
    const skipMeasure = options.length > 1 && measures.length === 1; // одна межа на оси + дименшен
    options.forEach((opt, i) => {
      if (opt.id === 'measures' && !skipMeasure) {
        const mIdx: number = data.measure_idx ?? 0;     // measure_idx - хардкор из range
        const measure = measures[mIdx];

        axis.ids.push(measure.id);
        axis.axisIds.push(opt.id);
        axis.titles.push(measure.title);
        axis.unit = measure.unit;
        axis.config[opt.id] = {title: opt.title};
        axis.config[measure.id] = {title: measure.title};
        axis.formula.push(measure.formula);
      }
      if (opt.id !== 'measures') {
        const name: any = data[opt.id];
        const subtotal = data[`∑${opt.id}`]; // спец символ от бэка
        const hasSubtotal = subtotal === 1 || subtotal === '1';
        let title = $eid(titles[i] ?? [], name)?.title ?? String(name); // ищу title в справочнике
        if (hasSubtotal) title = `${lang('subtotal')} "${opt.title}"`;
        axis.ids.push(hasSubtotal ? `∑${opt.id}` : name);
        axis.axisIds.push(opt.id);
        axis.titles.push(title);
        axis.config[opt.id] = {title: opt.title};
        axis.formula.push(opt.formula ?? opt.id);
      }
    });

    axis.id = axis.ids.join(' ');
    axis.title = axis.titles.join(' ');
    return axis;
  }

  // добавляю напрямую из style subtotal
  private _makeDimensions(): IKoobDimension[] {
    const dataSource: IDataSource = this._subspacePtr.dataSource;

    const columns: repo.koob.IRawDimension[] = this._dimensionsService.getModel();
    const dimensions: IKoobDimension[] = [];

    (dataSource.dimensions || []).forEach((id: string) => {
      const column = columns.find(c => c.name === id);
      if (column)
        dimensions.push({                                     // Превращаю объект из db.api в обратно-совместимый
          axisId: column.name,
          config: column.config,
          cube_name: column.cube_name,
          id: column.name,
          name: column.name,
          source_ident: column.source_ident,
          sql: column.sql_query,
          title: column.title,
          type: column.type,
          // _id: column.id
        });
      // создаю dimension
      if (id.match(/:(\w+)$/)) dimensions.push(this._makeDimension(id));
    });

    const usedDimensions: IKoobDimension[] = [];
    const xAxisCfg = (dataSource.xAxis || '').split(';');
    const yAxisCfg = (dataSource.yAxis || '').split(';');

    [...xAxisCfg, ...yAxisCfg].forEach((id) => {
      const idx: number = dimensions.findIndex(d => d.id === id);
      if (idx !== -1) usedDimensions.push(dimensions[idx]);
    });

    return usedDimensions;
  }

  private _makeDimension(formulaDimension: string): IKoobDimension {
    const columns: repo.koob.IRawDimension[] = this._dimensionsService.getModel();

    const dimension = {
      id: '',
      formula: '',
      title: '',
      type: 'STRING',
      sql: '',
    };
    if (formulaDimension.match(/:(\w+)$/)) dimension.id = RegExp.$1;
    dimension.formula = formulaDimension;
    dimension.sql = formulaDimension;

    // add title
    formulaDimension.replace(/\w+/g, (str) => {
      // const d = $eid(allDimensions, str);
      const d = columns.find(c => c.name === str);
      if (d) dimension.title = dimension.title.concat(' ', d.title, ' ').trim();
      return str;
    });
    return dimension;
  }

  private _getAxisLength = async (options: IEntity[]): Promise<number> => {
    const {koob} = this._subspacePtr;

    const measures = this._makeMeasures();
    const filters = this._makeFilters(this._koobFiltersService.getModel().filters);
    const subtotals = [];
    options.forEach((opt: any) => {
      if (this._subtotals.includes(opt.id)) subtotals.push(opt.id);
    });

    const hasMeasure = $eid(options, 'measures');
    const dimensionIds: any[] = options.filter(x => (x.id !== 'measures' && x.id !== '__idx')).map(x => x?.formula ?? x.id);
    const measuresFormulas: string[] = measures.map(m => m.formula);

    if (!dimensionIds.length && !hasMeasure) return 0;
    if (!dimensionIds.length && hasMeasure) return measures.length;

    if (hasMeasure && measures.length > 1) dimensionIds.push(`range(${measures.length})`);

    const data: { count: number }[] = await koobCountRequest3(koob, dimensionIds, measuresFormulas, filters, {subtotals});
    return data[0].count;
  }

  private _getAxis = async (options: any[], offset: number, limit: number, axisStr: string): Promise<IEntity[]> => {
    if (offset < 0 || limit < 0 || !options.length) return [];
    if (this._subspacePtr.disableLoadData) return [];
    const {koob} = this._subspacePtr;

    const measures = this._makeMeasures();
    const filters = this._makeFilters(this._koobFiltersService.getModel().filters);

    let measuresFormulas: string[] = measures.map(m => m.formula);
    let axisIds: string[] = options.map(d => d?.formula ?? d.id);  // id на оси

    let sort = options.map(x => x.id).map((dId) => {
      const findSrt = this._sortBy.find((sb) => sb.match(dId));
      if (findSrt && (findSrt[0] === '-' || findSrt[0] === '+')) return findSrt;
      else return `+${dId}`;
    });

    // нахожу в каком месте measures
    const idxMeasure = axisIds.indexOf('measures');

    // если есть, и больше 1 мэже, добавляю range
    if (idxMeasure >= 0 && measures.length > 1) {
      axisIds[idxMeasure] = `range(${measures.length}):measure_idx`;
      sort[idxMeasure] = '+measure_idx';
    }

    if (idxMeasure === -1) {                                                                        // на оси нет меж - возможно, надо сортировать по числам - значениям по межам
      const measuresIds: string[] = measures.map(m => m.id);
      const sortMeasures = this._sortBy.filter(                                                     // это список меж из конфига dataSource.sortBy
          sortColumn => measuresIds.includes(sortColumn) ||
              (sortColumn[0] === '-' && measuresIds.includes(sortColumn.slice(1))) ||
              (sortColumn[0] === '+' && measuresIds.includes(sortColumn.slice(1))));
      if (sortMeasures.length) {
        // Вставляем межи, по которым идет сортировка ПЕРЕД последним столбцом, значит
        sort.splice(-1, 0, ...sortMeasures);
      }
    }else {
      const measuresIds: string[] = measures.map(m => m.id);
      const sortMeasures = this._sortBy.filter(                                                     // это список меж из конфига dataSource.sortBy
          sortColumn => measuresIds.includes(sortColumn) ||
              (sortColumn[0] === '-' && measuresIds.includes(sortColumn.slice(1))) ||
              (sortColumn[0] === '+' && measuresIds.includes(sortColumn.slice(1))));
      if (sortMeasures.length) {
        // Вставляем межи, по которым идет сортировка ПЕРЕД последним столбцом, значит
        sort.splice(idxMeasure, 0, ...sortMeasures);
      }
    }


    axisIds = axisIds.filter(d => d !== 'measures');
    sort = sort.filter(s => s !== '+measures');

    if (!measuresFormulas.length && !axisIds.length) return [];

    const subtotals = [];
    axisIds.forEach((id) => {
      if (this._subtotals.includes(id)) subtotals.push(id);
      if (this._subtotals.includes(`+${id}`)) subtotals.push(id);
      if (this._subtotals.includes(`-${id}`)) subtotals.push(`-${id}`);
    });

    const axes: IEntity[] = [];
    this._cancelToken = axios.CancelToken.source();
    const isGlobal = (this._dimensionsService.getModel() as any).is_global !== 0;                       // [esix] тут надо будет более красиво придумать
    const data = await koobDataRequest3(koob, axisIds, measuresFormulas, filters, {
      offset,
      sort,
      limit,
      subtotals,
      cancelToken: this._cancelToken.token,
      schema_name: isGlobal ? null : this._schemaName,
    }, axisStr);
    this._cancelToken = null;
    const titles = [];
    // собираю весь справочник
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const id = option.formula ?? option.id;
      const dimension: any = option.id === 'measures' ? {} : await this._koobDimensionsMemberService.loadMember(id, true);
      titles.push(dimension?.members || []);
    }

    for (let i = 0; i < data.length; i++) {
      const record = data[i];
      const axis = this._makeAxis(record, options, measures, titles);
      axes.push(axis);
    }
    return axes;
  }

  // т.к. по умолчанию всегда сортировка идет +axisId
  private _updateSortBy = async (axisId: string): Promise<ISubspace> => {
    const idx = this._sortBy.findIndex(axis => axis.match(axisId));
    if (idx === -1) this._sortBy.push(`-${axisId}`);
    else {
      const srt = this._sortBy[idx][0] === '+' ? '-' : '+';
      this._sortBy[idx] = srt + axisId;
    }
    const subspace = await this._createSubspace([0, this._limit], [0, this._limit]);
    this._updateModel({subspace});
    return subspace;
  }

  private _createSubspace = async (x: [number, number], y: [number, number]): Promise<ISubspace> => {
    const {koob} = this._subspacePtr;

    const dimensions = this._makeDimensions();
    const measures = this._makeMeasures();
    const {xAxis, yAxis, zAxis} = this._makeAxes(dimensions);

    const filters =  cloneDeep(this._makeFilters(this._koobFiltersService.getModel().filters));
    const rawFilters = cloneDeep(this._makeFilters());

    const xsLimit = x[1] - x[0];
    const ysLimit = y[1] - y[0];

    const xs: IEntity[] = await this._getAxis(xAxis, x[0], xsLimit, 'xAxis');
    const ys: IEntity[] = await this._getAxis(yAxis, y[0], ysLimit, 'yAxis');
    const zs: IEntity[] = await this._getAxis(zAxis, 0, this._limit, 'zAxis'); // todo нужно продумать этот момент

    if (!xs.length) xs.push(makeNullAxis());
    if (!ys.length) ys.push(makeNullAxis());
    if (!zs.length) zs.push(makeNullAxis());

    const yAxisId = yAxis.map(y => y.id).join(';');
    const xAxisId = xAxis.map(x => x.id).join(';');
    const zAxisId = zAxis.map(z => z.id).join(';');

    const isGlobal = (this._dimensionsService.getModel() as any).is_global !== 0;        // [esix] тут надо будет более красиво придумать
    // todo add type ISubspace
    const subspace: ISubspace = {
      koob,
      schemaName: isGlobal ? null : this._schemaName,                                   // если куб в датасете, то выставляем и schemaName
      measures,
      dimensions,
      filters, rawFilters,
      subtotals: this._subtotals,
      xAxis: xAxisId,
      yAxis: yAxisId,
      zAxis: zAxisId,
      ms: [], ls: [], ps: [],
      xs, ys, zs,
      // максимальная кол-во объектов в оси - 128, если не задан лимит
      reduce: function (nx, ny, nz): ISubspace {
        this.xs = nEntities(xs, nx);
        this.ys = nEntities(ys, ny);
        this.zs = nEntities(zs, nz);
        return this;
      },

      sortBy: async (id: string): Promise<ISubspace> => this._updateSortBy(id),
      createSubspaceAxes: async (x, y): Promise<ISubspace> => this._createSubspace(x, y),

      getXsLength: async () => this._getAxisLength(xAxis),
      getYsLength: async () => this._getAxisLength(yAxis),

      // mlp fn
      getMLP: (...arg: any) => arg,
      getZ: (id: number) => zs[id],
      getY: (id: number) => ys[id],
      getX: (id: number) => xs[id],
      isEmpty: () => !(measures.length),
    };
    return subspace;
  }
}

function getVars(measuresDataSource: string[] | string): string[] {
  const formula = Array.isArray(measuresDataSource) ? measuresDataSource : [measuresDataSource];
  const allFormula = formula.map(m => fixMeasureFormula(m));
  const measuresVars = [].concat(...allFormula.map(ff => ff.match(/\$([a-z0-9]*)/gi))).filter(Boolean);
  const measuresUniq = Array.from(new Set(measuresVars)).map(m => m.replace('$', ''));
  return measuresUniq;
}
