import { repo } from '@luxms/bi-core';

export interface IRawLocationCards {
  readonly id: number;
  readonly loc_id: number;
  readonly metric_id: number;
  readonly title: string;
  readonly level: number;
  readonly tree_level: number;
  readonly parent_id: number;
  readonly css_file: string;
  readonly created: string;
  readonly updated: string;
}

export class LocationCardsRepository extends repo.BaseRepository<IRawLocationCards> {
  public constructor(schema_name: string) {
    super(schema_name, 'location_cards');
  }
}

