// https://github.com/eligrey/FileSaver.js/blob/master/FileSaver.js
import { lang } from './utils';
import * as echarts from 'echarts';


// IE <10 is explicitly unsupported
// if (typeof window === 'undefined' || typeof navigator !== 'undefined' && /MSIE [1-9]\./.test(navigator.userAgent)) {
//   return null;
// }

const doc = window.document;
// only get URL when necessary in case Blob.js hasn't overridden it yet
const get_URL = function () {
  return window.URL || (window as any).webkitURL || window;
};

let save_link: any = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a');
const can_use_save_link = 'download' in save_link;
const click = function (node) {
  const event = new MouseEvent('click');
  node.dispatchEvent(event);
};
const is_safari = /constructor/i.test(String(HTMLElement)) || (window as any).safari;
const is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
const throw_outside = function (ex) {
  ((window as any).setImmediate || window.setTimeout)(function () {
    throw ex;
  }, 0);
};
const force_saveable_type = 'application/octet-stream';
// the Blob API is fundamentally broken as there is no "downloadfinished" event to subscribe to
const arbitrary_revoke_timeout = 1000 * 40; // in ms
const revoke = function (file) {
  const revoker = function () {
    if (typeof file === 'string') { // file is an object URL
      get_URL().revokeObjectURL(file);
    } else { // file is a File
      file.remove();
    }
  };
  setTimeout(revoker, arbitrary_revoke_timeout);
};
const dispatch = function (filesaver, event_types, event?) {
  event_types = [].concat(event_types);
  var i = event_types.length;
  while (i--) {
    var listener = filesaver['on' + event_types[i]];
    if (typeof listener === 'function') {
      try {
        listener.call(filesaver, event || filesaver);
      } catch (ex) {
        throw_outside(ex);
      }
    }
  }
};
const auto_bom = function (blob) {
  // prepend BOM for UTF-8 XML and text/* types (including HTML)
  // note: your browser will automatically convert UTF-16 U+FEFF to EF BB BF
  if (/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(blob.type)) {
    return new Blob([String.fromCharCode(0xFEFF), blob], {type: blob.type});
  }
  return blob;
};
const FileSaver = function (blob, name, no_auto_bom) {
  if (!no_auto_bom) {
    blob = auto_bom(blob);
  }
  // First try a.download, then web filesystem, then object URLs
  const filesaver = this;
  const type = blob.type;
  const force = type === force_saveable_type;
  let object_url;
  const dispatch_all = function () {
    dispatch(filesaver, 'writestart progress write writeend'.split(' '));
  };
  // on any filesys errors revert to saving with object URLs
  const fs_error = function () {
    if ((is_chrome_ios || (force && is_safari)) && (window as any).FileReader) {
      // Safari doesn't allow downloading of blob urls
      var reader: any = new FileReader();
      reader.onloadend = function () {
        var url = is_chrome_ios ? reader.result : reader.result.replace(/^data:[^;]*;/, 'data:attachment/file;');
        var popup = window.open(url, '_blank');
        if (!popup) window.location.href = url;
        url = undefined; // release reference before dispatching
        filesaver.readyState = filesaver.DONE;
        dispatch_all();
      };
      reader.readAsDataURL(blob);
      filesaver.readyState = filesaver.INIT;
      return;
    }
    // don't create more object URLs than needed
    if (!object_url) {
      object_url = get_URL().createObjectURL(blob);
    }
    if (force) {
      window.location.href = object_url;
    } else {
      var opened = window.open(object_url, '_blank');
      if (!opened) {
        // Apple does not allow window.open, see https://developer.apple.com/library/safari/documentation/Tools/Conceptual/SafariExtensionGuide/WorkingwithWindowsandTabs/WorkingwithWindowsandTabs.html
        window.location.href = object_url;
      }
    }
    filesaver.readyState = filesaver.DONE;
    dispatch_all();
    revoke(object_url);
  };
  filesaver.readyState = filesaver.INIT;

  if (can_use_save_link) {
    object_url = get_URL().createObjectURL(blob);
    setTimeout(function () {
      save_link.href = object_url;
      save_link.download = name;
      click(save_link);
      dispatch_all();
      revoke(object_url);
      filesaver.readyState = filesaver.DONE;
    });
    return;
  }

  fs_error();
};

FileSaver.prototype.abort = function () {/* */
};
FileSaver.prototype.readyState = FileSaver.prototype.INIT = 0;
FileSaver.prototype.WRITING = 1;
FileSaver.prototype.DONE = 2;

FileSaver.prototype.error = null;
FileSaver.prototype.onwritestart = null;
FileSaver.prototype.onprogress = null;
FileSaver.prototype.onwrite = null;
FileSaver.prototype.onabort = null;
FileSaver.prototype.onerror = null;
FileSaver.prototype.onwriteend = null;


let saveAs: (blob, name?, no_auto_bom?) => any = function (blob, name?, no_auto_bom?) {
  return new FileSaver(blob, name || blob.name || 'download', no_auto_bom);
};

// IE 10+ (native saveAs)
if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
  saveAs = function (blob, name?, no_auto_bom?) {
    name = name || blob.name || 'download';

    if (!no_auto_bom) {
      blob = auto_bom(blob);
    }
    return navigator.msSaveOrOpenBlob(blob, name);
  };
}

function drawImageEchartsCanvas($anchor, chart: any, titleContext: string[]): void {
  const echarts: any = require('echarts');
  const options: any = chart.getOption();
  options?.title?.[0]?.show = true;
  options?.animation = false;
  options?.animationDuration = false;
  options?.animationEasing = false;
  options?.animationEasingUpdate = false;
  options?.animationThreshold = false;
  const {width, height} = chart._dom.getBoundingClientRect();
  const container = document.createElement('div');
  const echart = echarts.init(container, null, {renderer: 'canvas', width, height});
  echart.setOption(options);
  const dataURL = echart._api.getDataURL();
  const a = document.createElement('a');
  const headers = options.title ? options.title[0]['text'] : 'chart';
  a.href = dataURL;
  a.download = 'canvas';
  document.body.appendChild(a);
  a.click();
  a.remove();
  echart.clear();
  container.remove();
}

/**
 * Старая версия сохранения png echarts, используйте drawImageEchartsCanvas
 * @deprecated *
 */
function drawImageEcharts($anchor, chart: any, titleContext: string[]): void {
  const options = chart.getOption();
  const isTitleContextExist = titleContext && Array.isArray(titleContext) && titleContext[0] != '';
  const headers = isTitleContextExist ? titleContext : [options.title ? options.title[0]['text'] : ''];

  let container = chart._dom;
  const chartName = (isTitleContextExist ? titleContext[0].split(' ').join('_') : (options.title ? options.title[0]['text'].split(' ').join('_') : 'chart')) + '.png';
  const $chart = $(container).clone();

  // const width: number = $chart.width(), height = $chart.height();
  const width: number = container.getBoundingClientRect().width;
  const height: number = container.getBoundingClientRect().height;

  // выпиливаем дочерний элемент с символом из-за которого выпадает ошибка
  // вот этот элемент
  // <tspan x="5" y="16">​</tspan>
  const $labels = $chart.find('.highcharts-text-outline');
  const trickyTspan = $labels.find('tspan').remove();

  const svg: any = $chart.find('svg')[0];

  // const svg = container.querySelector('svg');
  let svgText: string = svg.outerHTML;

  if (!svgText) {    // may not work in safari
    svgText = chart.getSVG({
      chart: {
        width: width,
        height: height,
      },
    });
  }

  function replaceNbsps(str) {
    // багуют колоночные графики
    let reg = /filter="url\([^<>]+\)"/g;

    // remove white stroke
    let reg2 = /stroke="rgb\(255,255,255\)" stroke-width/g;
    str = str.replace(reg, '').replace(reg2, ' stroke-width');

    return str.replace(/&nbsp;/g, ' ').replace(/&quot;/g, '\'');
  }

  svgText = replaceNbsps(svgText);

  let headersLength = options.title?.[0]?.['text'].length || 0;
  const iter = 10;
  const diff = width - (headersLength * iter);
  let formatted = [];

  if (diff < 0) {
    let arrStr = options.title?.[0]?.['text'].split(' ');
    let newStr: any = [];
    arrStr.forEach((str: string) => {
      const length = newStr.length || 0;
      let current = length > 0 ? (length - 1) : 0;
      let last = newStr[current] ? newStr[current] : '';
      if (((last + ' ' + str).length * iter) > width) {
        newStr.push(str);
      } else {
        newStr[current] = (last) ? last + ' ' + str : str;
      }
    });

    formatted = newStr;
    headers.splice(0, 1, ...formatted);
  }

  const headerLineHeight: number = 26;
  const headerHeight: number = headerLineHeight + headerLineHeight * headers.length;

  const $disposableCanvas: JQuery = $('<canvas id="disposable-canvas"></canvas>').insertAfter($chart);
  const canvas: any = $disposableCanvas.get(0);
  canvas.width = width;
  canvas.height = headerHeight + height;
  var ctx = canvas.getContext('2d');
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = '#333333';
  ctx.font = 'bold 24px Arial';
  for (let i = 0; i < headers.length; ++i) {
    /*    ctx.fillText(headers[i], 10, headerLineHeight * (1 + i));
        ctx.font = '16px Arial';*/

    ctx.font = '16px Arial';
    ctx.fillText(headers[i], 10, headerLineHeight * (1 + i));
  }

  // ctx.fillText(headers[i], 10, headerLineHeight * (1 + i), width -10);

  ctx.drawSvg(svgText, 0, headerHeight, width, height);

  if ((typeof window !== 'undefined') && (window.navigator as any).standalone) {        // iOS at home screen
    const $modal: JQuery = $(`<div style="position: fixed; z-index: 1000; left:0; top: 0; right: 0; bottom:0; background: #eeeeee; text-align: center">
      <h1>${lang('save-png-instructions')}</h1>
      <a type="button" class="close bi-icon dark" style="z-index:1">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
          <polygon points="15.778,14.364 9.414,8 15.777,1.636 14.363,0.222 8,6.586 1.636,0.222 0.222,1.636 6.586,8 0.222,14.364 1.636,15.778 8,9.414 14.364,15.778 "/>
        </svg>
      </a>
      <iframe frameborder="0" style="width: ${width + 20}px; height: ${canvas.height + 20}px; "></iframe>
    </div>`).appendTo('body');
    // $modal.append($disposableCanvas);
    $modal.find('iframe').attr('src', canvas.toDataURL('image/png'));
    $modal.on('click', 'a', () => {
      $modal.remove();
    });
  } else if (navigator.msSaveBlob) {
    window.navigator.msSaveBlob(canvas.msToBlob(), chartName);
  } else if (canvas.toBlob) {
    canvas.toBlob(blob => {
      console.log(`Saving BLOB of size ${blob.size}`);
      saveAs(blob, chartName);
    });
  } else { // other browser not tested on IE 11
    $anchor.attr('download', chartName);
    $anchor.attr('href', canvas.toDataURL('image/png'));
  }
  $disposableCanvas.remove();
}

function drawImage($anchor, chart: HighchartsChartObject, titleContext: string[]): void {
  const headers = titleContext;

  let container = chart.container;

  const $chart = $(chart.container).clone();

  // const width: number = $chart.width(), height = $chart.height();
  const width: number = container.getBoundingClientRect().width;
  const height: number = container.getBoundingClientRect().height;

  // выпиливаем дочерний элемент с символом из-за которого выпадает ошибка
  // вот этот элемент
  // <tspan x="5" y="16">​</tspan>
  const $labels = $chart.find('.highcharts-text-outline');
  const trickyTspan = $labels.find('tspan').remove();

  const svg: any = $chart.find('svg')[0];

  // const svg = container.querySelector('svg');
  let svgText: string = svg.parentNode.innerHTML; // svg.outerHTML;

  if (!svgText) {    // may not work in safari
    svgText = chart.getSVG({
      chart: {
        width: width,
        height: height,
      },
    });
  }

  function replaceNbsps(str) {
    return str.replace(/&nbsp;/g, ' ').replace(/&quot;/g, '\'');
  }

  svgText = replaceNbsps(svgText);

  const headerLineHeight: number = 26;

  const headerHeight: number = headerLineHeight + headerLineHeight * headers.length;
  const $disposableCanvas: JQuery = $('<canvas id="disposable-canvas"></canvas>').insertAfter($chart);
  const canvas: any = $disposableCanvas.get(0);
  canvas.width = width;
  canvas.height = headerHeight + height;
  var ctx = canvas.getContext('2d');
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = '#333333';
  ctx.font = 'bold 24px Arial';
  debugger;
  for (let i = 0; i < headers.length; ++i) {
    ctx.fillText(headers[i], 10, headerLineHeight * (1 + i));
    ctx.font = '16px Arial';
  }

  // ctx.drawSvg(svgText, 0, headerHeight, $chart.width(), $chart.height());
  ctx.drawSvg(svgText, 0, headerHeight, width, height);

  if ((typeof window !== 'undefined') && (window.navigator as any).standalone) {        // iOS at home screen
    const $modal: JQuery = $(`<div style="position: fixed; z-index: 1000; left:0; top: 0; right: 0; bottom:0; background: #eeeeee; text-align: center">
      <h1>${lang('save-png-instructions')}</h1>
      <a type="button" class="close bi-icon dark" style="z-index:1">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
          <polygon points="15.778,14.364 9.414,8 15.777,1.636 14.363,0.222 8,6.586 1.636,0.222 0.222,1.636 6.586,8 0.222,14.364 1.636,15.778 8,9.414 14.364,15.778 "/>
        </svg>
      </a>
      <iframe frameborder="0" style="width: ${width + 20}px; height: ${canvas.height + 20}px; "></iframe>
    </div>`).appendTo('body');
    // $modal.append($disposableCanvas);
    $modal.find('iframe').attr('src', canvas.toDataURL('image/png'));
    $modal.on('click', 'a', () => {
      $modal.remove();
    });
  } else if (navigator.msSaveBlob) {
    window.navigator.msSaveBlob(canvas.msToBlob(), 'chart.png');
  } else if (canvas.toBlob) {
    canvas.toBlob(blob => {
      console.log(`Saving BLOB of size ${blob.size}`);
      saveAs(blob, 'chart.png');
    });
  } else { // other browser not tested on IE 11
    $anchor.attr('download', 'chart.png');
    $anchor.attr('href', canvas.toDataURL('image/png'));
  }
  $disposableCanvas.remove();
}


export { saveAs, drawImage, drawImageEcharts, drawImageEchartsCanvas };
