/**
 *
 *
 *
 */
import { IDsToolbarButton, IPlugin, IRootSegment } from './plugins-model';
import { disposeAll, AppConfig, BaseService, createSingleton, srv } from '@luxms/bi-core';
import { IDatasetModel } from '../services/ds/types';
import { IDisposable, IVCPV } from '../defs/bi';
import InternalComponentVC from '../view-controllers/InternalComponentVC';
import CanIService from '../services/CanIService';


interface IPluginsManagerModel extends Array<IPlugin> {
  loading: boolean;
  error: string | null;
}

function makeModel(error: string | null, loading: boolean, plugins: any[]): IPluginsManagerModel {
  (plugins as any).error = error;
  (plugins as any).loading = loading;
  return plugins as IPluginsManagerModel;
}


async function loadPlugin(pluginId: string): Promise<any> {
  let module;
  if (pluginId === 'presentations') module = await import('@luxms/bi-resources/dist/ds_res/plugins/1.presentations');
  else if (pluginId === 'tasks') module = await import('@luxms/bi-resources/dist/ds_res/plugins/2.tasks');
  // else if (pluginId === 'chats') module = await import('@luxms/bi-resources/dist/ds_res/plugins/3.chats');
  else if (pluginId === 'chats') module = await import('../views/chats/index');
  else if (pluginId === 'dictionaries') module = await import('@luxms/bi-resources/dist/ds_res/plugins/_4.dictionaries');
  // else if (pluginId === 'reports') module = await import(`../plugins/reports`);
  // else if (pluginId === `news`) module = await import(`../plugins/news`);
  else if (pluginId === `data`) module = await import(`../plugins/data`);
  else if (pluginId === `crud`) module = await import(`../plugins/crud`);
  else throw new Error('No such plugin ' + pluginId);
  return module.default || module;
}


// ВАЖНО: нет подписки на обновления
async function loadResourcePlugin(pluginId: string): Promise<any> {
  const vc = new InternalComponentVC('ds_res', `plugins/${pluginId}.js`);

  try {
    await vc.whenReady();
    const model = vc.getModel();
    if (model.error) throw new Error(model.error);
    return model.Component;
  } finally {
    // vc.release();
    // не выгружаем VC-шку потому что уничтожатся стили загруженные компонентом
  }
}


export class PluginsManager extends BaseService<IPluginsManagerModel> implements IPlugin {
  public id: string = 'PluginsManager';
  protected _subscriptions: IDisposable[] = [];
  private _toolbarButtons: IDsToolbarButton[] = [];
  private _pluginBgUrl: string[] = [];

  protected constructor() {
    super(makeModel(null, true, []));
    this._init();
  }

  private async _init() {
    // TODO: currently runs only once - add ability to reload
    let resourcePlugins: string[] = [];

    const rs = srv.ds.ResourcesService.createInstance('ds_res');
    try {
      await rs.whenReady();
      resourcePlugins = rs.getModel().map(e => e.alt_id.match(/^plugins\/([^_][\w\d-.]+).js$/) ? RegExp.$1 : '').filter(Boolean);
      // плагины могут начинаться с цифр, для сортировки, например "1.presentations.js"
      resourcePlugins.sort((p1, p2) => {
        let i1 = p1.match(/^(\d+)?\./) ? +RegExp.$1 : 100000;
        let i2 = p2.match(/^(\d+)?\./) ? +RegExp.$1 : 100000;
        return i1 - i2;
      });
      // todo поменять
      this._pluginBgUrl = rs.getModel().map(e => e.alt_id.match(/^plugins\/([^_][\w\d-.]+).png$/) ? e.alt_id : null).filter(Boolean);

    } catch (err) {
      console.error(err);
    } finally {
      rs.release();
    }
    const cfgPlugins: string[] = AppConfig.getPlugins().filter(id => !resourcePlugins.map(p => p.replace(/^(\d+)?\./, '')).includes(id));
    cfgPlugins.push('data');

    // пока что на всю админку одно правило. Надо сделать отдельные правила на чилдренов
    const canAdm = await CanIService.one('C rbac.domain_group_rbac9_group_maps');
    if (canAdm) {
      cfgPlugins.push('crud');
    }

    const pluginModules: { [id: string]: any } = {};
    const pluginLoaders = [].concat(
        cfgPlugins.map(((id: string) => loadPlugin(id).then(module => pluginModules[id] = module).catch((err) => console.warn(err)))),
        resourcePlugins.map(id => loadResourcePlugin(id).then(module => pluginModules[id] = module).catch((err) => console.warn(err))));

    await Promise.all(pluginLoaders);

    const plugins: IPlugin[] = resourcePlugins.concat(cfgPlugins).filter(id => pluginModules[id]).map(id => {
      const plugin = new pluginModules[id](this);
      plugin.id = id;
      return plugin;
    });

    this._setModel(makeModel(null, false, plugins));
  }

  public getRootSegments(): IRootSegment[] {
    let rootSegments: IRootSegment[] = [];
    this._model.forEach(plugin => {
      if (plugin.getRootSegments) {
        const segments: IRootSegment[] = plugin.getRootSegments();
        // ищу img
        segments.forEach((segment) => {
          const url = this._pluginBgUrl.find(bg => bg.match(segment.key));
          if (url) segment.bgUrl = AppConfig.fixRequestUrl(`/srv/resources/ds_res/${url}`);

          // немножко хардкода, пока не решились полностью положить все плагины в ресурсы
          if (segment.key === 'presentations') segment.bgUrl = require('@luxms/bi-resources/dist/ds_res/plugins/presentations.png');
          else if (segment.key === 'tasks-from') segment.bgUrl = require('@luxms/bi-resources/dist/ds_res/plugins/tasks-from.png');
          else if (segment.key === 'tasks-to') segment.bgUrl = require('@luxms/bi-resources/dist/ds_res/plugins/tasks-to.png');
          else if (segment.key === 'tasks-self') segment.bgUrl = require('@luxms/bi-resources/dist/ds_res/plugins/tasks-self.png');
          else if (segment.key === 'chats') segment.bgUrl = require('@luxms/bi-resources/dist/ds_res/plugins/chats.png');
          else if (segment.key === 'data') segment.bgUrl = require('../../assets/images/data.png');
        });
        rootSegments = rootSegments.concat(segments);
      }
    });
    return rootSegments;
  }

  public getDrillDownMenuItems(ddMenu: any, dataset: IDatasetModel, vcpv: IVCPV, vcpAction?: any): any[] {
    let menuItems = [];
    this._model.forEach(plugin => {
      if (plugin.getDrillDownMenuItems) {
        const pluginMenuItems: any[] = plugin.getDrillDownMenuItems(ddMenu, dataset, vcpv, vcpAction);
        if (pluginMenuItems) {
          menuItems = menuItems.concat(pluginMenuItems);
        }
      }
    });
    return menuItems;
  }


  public subscribeDsToolbarButtons(dataset: IDatasetModel, cb: (bs: IDsToolbarButton[]) => void, immediateNotify: boolean = false): IDisposable {
    let plugins: IPlugin[] = this._model.filter(plugin => !!plugin.subscribeDsToolbarButtons);        // only wioth such method

    let initialized: boolean = false;
    let subscriptions: IDisposable[];

    let bss: IDsToolbarButton[][] = [];

    const onPluginButtonsUpdated = (bs: IDsToolbarButton[], idx: number) => {
      bss[idx] = bs;
      if (initialized) {
        cb(Array.prototype.concat.apply([], bss));
      }
    };

    subscriptions = plugins.map((plugin, idx) => plugin.subscribeDsToolbarButtons(dataset, bs => onPluginButtonsUpdated(bs, idx), true));
    initialized = true;

    if (immediateNotify) {
      cb(Array.prototype.concat.apply([], bss));
    }

    return {
      dispose: () => disposeAll(subscriptions),
    };
  }

  public static getInstance = createSingleton<PluginsManager>(() => new PluginsManager(), '__pluginsManager');
}
