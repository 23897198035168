import { BaseService, createSingleton, UrlState } from '@luxms/bi-core';
import { throttle } from 'lodash';

const throttleTimeout = 3000;                                                                       // можно ставить достаточно большим
                                                                                                    // повторные фильтры будут срабатывать в течение этого времени
export interface IKoobFiltersModel {
  loading?: boolean;
  error?: string;
  query?: string;
  result: any;
  filters: any;
  pendingFilters: any;
}

export class KoobFiltersService extends BaseService<IKoobFiltersModel> {
  private constructor() {
    super({
      loading: false,
      error: null,
      query: undefined,
      result: {},
      filters: {},
      pendingFilters: {},
    });
    UrlState.subscribeAndNotify('_koobFilters f', this._onUrlStateUpdated);
  }
  protected _dispose() {
    UrlState.unsubscribe(this._onUrlStateUpdated);
    super._dispose();
  }

  private _onUrlStateUpdated = (url) => {
    this._updateWithData({filters: {...url._koobFilters, ...url.f}});
  }

  // public updateTextSearch(query: string) {
  //   this._updateModel({query, error: null, result: {}, filters: {}});
  //   if (!query) return;
  //   this._load();
  // }

  // Выпилить везде гдк используется, пока просто вызываем нормальную функцию
  public setDimensionFilter(koob: string, dimension: string, filter?: string[]) {
    this.setFilter(koob, dimension, filter);
  }

  public setFilter(koob: string, dimension: string, filter?: any[]) {
    let filters = this._model?.pendingFilters;
    if (filter) {
      let arr: string[] | undefined = filter?.slice(0);
      filters = {...filters, [dimension]: arr};
    } else {
      filters = {...filters, [dimension]: undefined};
    }
    this._updateModel({pendingFilters: filters});
    this._applyAllFilters();
  }

  public setFilters(koob: string, newFilters: any) {
    let filters = {...this._model.pendingFilters};
    for (let dimension in newFilters) {
      let filter = newFilters[dimension];
      filters[dimension] = filter?.slice(0);
    }
    this._updateModel({pendingFilters: filters});
    this._applyAllFilters();
  }

  public toggleFilters(koob: string, newFilters: any) {
    let filters = this._model.pendingFilters;
    let currentFilters = this._model.filters;

    for (let dimension in newFilters) {
      // передаваемое
      let filter = newFilters[dimension];
      // текущие фильтры
      let currentFilter = currentFilters[dimension];

      if (filter) {
        let arr: string[] | undefined = filter?.slice(0);
        if (currentFilter) {
          let obj: string[] | undefined = arr?.slice(0).filter((i) => i !== '=');
          if (currentFilter.includes(obj[0])) {
            let pendingsFilters = [];
            currentFilter.forEach((c) => {
              if (c !== obj[0]) pendingsFilters.push(c);
            });

            if (JSON.stringify(pendingsFilters) == '["="]') {
              pendingsFilters = ['!='];
            } else {
              if (pendingsFilters.includes('!=') || pendingsFilters.includes('')) {
                const filteredFilters = pendingsFilters.filter((pf: string) => pf !== '' && pf !== '!=');
                pendingsFilters = ['=', ...filteredFilters];
              }
            }

            filters = {...filters, [dimension]: pendingsFilters};
          } else {
            const second = currentFilter?.length ? arr.filter((a) => a !== '=') : arr;
            const thisFilter = (currentFilter || []).filter((cf: string) => cf !== '=' && cf !== '!=' && cf !== '');

            filters = {...filters, [dimension]: ['=', ...thisFilter, ...second]};
          }
        } else {
          filters = {...filters, [dimension]: arr};
        }
      } else {
        filters = {...filters, [dimension]: undefined};
      }
    }
    this._updateModel({pendingFilters: filters});
    this._applyAllFilters();

  }

  public applyDimensionFilter(dimension: string, value: string | number, toggleFlag: boolean, allValues: (string | number)[]) {
    const filters = this._model.pendingFilters;
    const current = filters[dimension];
    let arr: (string | number)[];

    if (toggleFlag) {
      arr = current ?
          current.concat(value) :
          ['=', value];
    } else {
      arr = current ?
          ['='].concat(current.slice(1).filter(e => e != value)) :
          ['='].concat(allValues.filter(e => e != value) as any);                                   // when not set, consider that every was selected
    }

    const _filters = {...filters, [dimension]: arr};
    this._updateModel({pendingFilters: _filters});
    this._applyAllFilters();
  }

  public applyPeriodsFilter(dimension: string, lodate: string | number, hidate: string | number) {
    const filters = this._model.pendingFilters;
    const _filters = {...filters, [dimension]: ['between', lodate, hidate]};
    this._updateModel({pendingFilters: _filters});
    this._applyAllFilters();
  }

  private _applyAllFilters = throttle(() => {
    const filters = {...this._model.filters, ...this._model.pendingFilters};
    Object.keys(filters).forEach(filter => {
      if (filters[filter] === undefined) {
        delete filters[filter];
      }
    });

    this._updateModel({pendingFilters: {}});

    const url = UrlState.getInstance().getModel();
    let publicKeys = Object.keys(url.f || {});                                                      // Раскидываем ключи фильтров на две части - публичную и скрытую
    const publicFilters = {}, privateFilters = {};                                                  // в публичную попадают ключи, которые уже есть в url
    for (let key in filters) {                                                                      // Может быть стоит добавить какое-то более остроумное условие
      if (publicKeys.includes(key)) {
        publicFilters[key] = filters[key];
      } else {
        privateFilters[key] = filters[key];
      }
    }

    UrlState.getInstance().updateModel({f: publicFilters, _koobFilters: privateFilters});
  }, throttleTimeout);

  public static getInstance = createSingleton(() => new KoobFiltersService(), '__koobFiltersService');
}

KoobFiltersService.getInstance();
