import { BaseService, AppConfig, createObjectsCache, extractErrorMessage } from '@luxms/bi-core';
import { $eid, $eidx } from '../../libs/imdas/list';
import axios from 'axios';
import { IKoobDimension, IKoobMeasure } from '../../defs/bi';


//
// INTERFACES
//

export interface IKoob {
  id: string;
  source_ident?: string;
  name?: string;
  title: string;
  sql: string;
  dimensions: IKoobDimension[];
  measures: IKoobMeasure[];
}


export interface IKoobModel {
  id: string;
  config: any;
  loading?: boolean;
  error?: string;
  dimensions: IKoobDimension[];
  measures: IKoobMeasure[];
}

/**
 * @deprecated
 */
export class KoobService extends BaseService<IKoobModel> {
  public readonly id: string;
  private _detailedEntities: { [entityId: string]: string[] } = {};
  private _isMainLoading: boolean = true;

  private constructor(koobId: string) {
    super({
      id: koobId,
      loading: true,
      error: null,
      dimensions: [],
      measures: [],
      config: {},
    });
    this.id = koobId;
    this._init();
  }

  private async _init() {
    try {
      const url = AppConfig.fixRequestUrl(`/api/v3/koob/${this.id}`);
      const result: any = (await axios.get(url)).data;
      if (!this._model) {                                                         // disposed!
        return;
      }
      result.dimensions.forEach((dimension, idx) => {
        if (dimension.id.match(/^\w+\.\w+\.(\w+)$/)) {                                        // иногда приходят в формате x.y.ID
          dimension.id = RegExp.$1;
        }
        dimension.axisId = dimension.id;
        if (this._detailedEntities[dimension.id]) {
          result.dimensions[idx] = this._detailedEntities[dimension.id];
        }
      });
      result.measures.forEach((measure, idx) => {
        if (this._detailedEntities[measure.id]) {
          result.measures[idx] = this._detailedEntities[measure.id];
        }
      });
      this._isMainLoading = false;

      this._updateModel({
        error: null,
        loading: false,
        config: result?.config ?? {},
        dimensions: result.dimensions,
        measures: result.measures,
      });
    } catch (err) {
      console.error(err);
      this._updateModel({
        error: extractErrorMessage(err),
        loading: false,
        dimensions: [],
        measures: [],
      });
    }
  }

  protected _dispose() {
    KoobService._cache.remove(this.id);
    super._dispose();
  }

  private static _cache = createObjectsCache(id => new KoobService(String(id)), '__deprecated__KoobServices');

  public static createInstance: (id: string | number) => KoobService = KoobService._cache.get;
}

