import React from 'react';
import $ from 'jquery';
import { getElementCoords } from '../view-utils';
import { PopupVC } from '../../view-controllers/dialogs/PopupVC';
import { BIIcon } from '../components/BIIcon/BIIcon';


export interface IDsShellTitleProps {
  schema_name: string;
  dsTitle: string;
  dsDescription: string;
  dsUrl: string;
}

export class DsShellTitle extends React.PureComponent<IDsShellTitleProps> {
  private popup: PopupVC;

  constructor(props) {
    super(props);
    this.popup = PopupVC.getInstance();
  }

  private _toggleDatasetDescription = (event) => {
    const $container = $(this.refs.container as any);
    const icon = $container.find('.DatasetTitle__DescriptionIcon')[0];
    const {x, y} = getElementCoords(icon, 'bottom', 'left');
    this.popup.toggleDescription(this.props.dsDescription, {left: x, top: y});
  };


  public render() {
    const {dsTitle, dsDescription, dsUrl} = this.props;
    return null;
    // return (
    //   <header ref="container" className="DsShellTitle" data-bind="visible: (koDatasetTitle() != null)">
    //     {!!dsUrl &&
    //     <a href={dsUrl} target="_blank">
    //       <h1 style={{fontWeight: 'normal'}}>{dsTitle}</h1>
    //     </a>}
    //
    //     {!dsUrl &&
    //     <h1 style={{fontWeight: 'normal'}} title={dsTitle}>{dsTitle}</h1>}
    //
    //     {!!dsDescription && dsDescription != dsTitle &&
    //     <BIIcon icon="info"
    //             onPress={this._toggleDatasetDescription}
    //             className="help-icon bi-icon light DatasetTitle__DescriptionIcon" />}
    //
    //   </header>);
  }
}
