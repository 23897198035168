/**
 * ProviderService
 *
 *
 */

import {
  BaseService,
  createSingleton,
  AppConfig,
  extractErrorMessage,
  AuthenticationService,
  IAuthentication,
  UrlState
} from '@luxms/bi-core';
import { httpGet } from '../repositories/http/data-storage';
const skin: any = require('../skins/skin.json');


export interface IRawProvider {
  contact?: any;
  id?: number;
  image?: string;
  subtitle?: any;
  title?: string;
  url?: string;
}


export interface IServerInfoResponse {
  provider: IRawProvider;
  serverTitle: string;
  version: string;
}


export interface IProviderModel extends IRawProvider {
  loading: boolean;
  error: string;
}


export class ProviderService extends BaseService<IProviderModel> {
  constructor() {
    super({
      loading: true,
      error: null,
    });
    AuthenticationService.subscribeUpdatesAndNotify(this._reload);
  }

  private _reload = async () => {
    const auth: IAuthentication = AuthenticationService.getModel();
    if (auth.error) return this._setModel(ProviderService.withError(auth.error));
    if (auth.loading) return this._setModel(ProviderService.withLoading());
    if (!auth.authenticated) return this._setModel(ProviderService.withError(AuthenticationService.NOT_AUTHENTICATED));

    try {
      let rawData: IRawProvider = null;

      // ACHTUNG

      //// Сделано для быстрого входа на нужный урл, минуя стандартную страницу списка разделов
      let entryPoint = AppConfig.getModel().features.find(el => el.hasOwnProperty("entryPoint"));
      if (entryPoint) {
        UrlState.getInstance().navigate(entryPoint['entryPoint']);
      }
      ////

      try {
        const url: string = AppConfig.fixRequestUrl(`/api/v3/server-info`);
        const response: IServerInfoResponse = await httpGet<IServerInfoResponse>(url);
        rawData = response.provider;
        // images should be local
        rawData.image = skin.datasetsLogo ? skin.datasetsLogo.slice(1, -1) : 'assets/images/datasets-logo.png';
      } catch (err) {
        console.error(err);
        // try full datasets old request
        const url = AppConfig.fixRequestUrl( `/api/datasets/`);
        const datasetsListModel: responses.IDatasetsResponse = await httpGet<responses.IDatasetsResponse>(url);
        rawData = datasetsListModel.provider;
      }

      this._setModel(ProviderService.withData(rawData));
      if (entryPoint) {
        UrlState.getInstance().navigate(entryPoint['entryPoint']);
      }
    } catch (err) {
      this._setModel(ProviderService.withError(extractErrorMessage(err)));
    }
  };

  public static withLoading(): IProviderModel {
    return {
      loading: true,
      error: null,
    };
  }

  public static withError(error: string): IProviderModel {
    return {
      loading: false,
      error: error,
    };
  }

  public static withData(raw: IRawProvider): IProviderModel {
    return {
      ...raw,
      loading: false,
      error: null,
    };
  }

  public static getInstance: () => ProviderService = createSingleton<ProviderService>(() => new ProviderService(), '__providerService');
}
