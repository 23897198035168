/**
 *  immutable data structures: list
 *
 */


export interface IIdOwner {
  id: string | number;
}


function _createIndexOnEntities<T extends IIdOwner>(es: T[]): {[id: string]: number} {
  if (!('__index' in es)) {
    // need to build
    let index: {[id: string]: number} = {};
    for (let i = 0; i < es.length; i++) {
      index[es[i].id] = i;
    }
    if (Object.defineProperty) {
      Object.defineProperty(es, '__index', {
        value: index,
        writable: false,
        enumerable: false,
        configurable: true,
      });
    } else {
      es['__index'] = index;
    }
  }
  return es['__index'];
}


/**
 * Find index of entity in array
 *
 * @param {T[]} es
 * @param {string | number | T} id - (string) - entity id; (number) - entity index, (T) - other entity to search with same id
 * @returns {number}
 */
export function getEntityIdx<T extends IIdOwner>(es: T[], id: string | number | T): number {
  if (typeof id === 'string') {                       // id
    const index: { [id: string]: number } = _createIndexOnEntities(es);
    const idx = index[id];
    return idx !== undefined ? idx : -1;
  } else if (typeof id === 'number') {                // idx
    return (id >= 0) ? id : es.length + id;
  } else if (id === null || id === undefined) {       // nothing
    return -1;
  } else if ('id' in id) {                            // entity
    const index: { [id: string]: number } = _createIndexOnEntities(es);
    const idx = index[id.id];
    return (idx !== undefined && es[idx] === id) ? idx : -1;            // return idx only if has same entity
  } else {                                            // error
    throw new Error('getEntityIdx: unknown id' + String(id));
  }
}


export function getEntity<T extends IIdOwner>(es: T[], id: string | number | T): T | null {
  const idx: number = getEntityIdx(es, id);
  return es[idx] || null;
}


if (typeof window !== 'undefined') {
  (window as any).getEntity = getEntity;
}

/**
 * Get Entity by id
 * @param {T[]} es entities array
 * @param {string | number} id entity id to search
 * @returns {T} entity or null
 */
export function $eid<T extends IIdOwner>(es: T[], id: string | number): T | null {
  const index: { [id: string]: number } = _createIndexOnEntities(es);
  return (id in index) ? es[index[id]] : null;
}

/**
 * Return those entites from list which ids are provided as second argument
 * @param {T[]} es
 * @param {(string | number)[]} ids
 * @returns {T[]}
 */
export function $esid<T extends IIdOwner>(es: T[], ids: (string | number)[]): T[] {
  return ids.map(id => $eid(es, id)).filter(e => e != null);
}

/**
 * Get entity index in array, -1 if not exists
 * @param {T[]} es
 * @param {string | number} id
 * @returns {number}
 */
export function $eidx<T extends IIdOwner>(es: T[], id: string | number): number {
  return getEntityIdx(es, String(id));
}
