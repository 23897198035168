import { BaseService, UrlState } from '@luxms/bi-core';
import { IEntity, ILocation, IMetric, IMLPSubspace, IPeriod, ISubspace, ISubspacePtr } from '../../defs/bi';
import { ILookupDataModel, LookupDataService } from './LookupDataService';
import { IDatasetModel, IDsState } from '../ds/types';
import { IS_P, joinDataLookup, markContinuousPeriodType, nEntities, oneEntity } from '../../utils/utils';
import { $eid, $esid } from '../../libs/imdas/list';
import { lpeRun } from '../../utils/lpeRun';
import { Period } from '../ds/entities';
import {
  makeNullAxis,
  makeMetrics,
  esApplyDrilldown,
  makeLocation,
} from '../ds/createSubspaceGenerator';
import { DsStateService1 } from '../ds/DsStateService1';

export interface ILookupSubspaceModel {
  error: string;
  loading: boolean;
  subspace: any;
}

const isArray = (arg) => Object.prototype.toString.call(arg) === '[object Array]';
const isHash = (arg) => (typeof arg === 'object') && (arg !== null) && !isArray(arg);

/**
 * LookupSubspaceService
 * скачиваю данные по осям, максимум по 128 на ось
 * у subspace метод async createSubspaceAxes (x[from,to], y[from,to]) - возвращает subspace с новыми осями
 * у subspace метод async getXsLength -> общее кол-во по оси x  todo НЕТ АПИ
 * у subspace метод async getYsLength -> общее кол-во по оси y  todo НЕТ АПИ
 * у subspace метод async sortBy(id:string) -> возвращает новый subspace отсортированный по id + || -
 */

export class LookupSubspaceService extends BaseService<ILookupSubspaceModel> {
  private readonly DEFAULT_LIMIT: number = 128;

  private readonly _subspacePtr: ISubspacePtr;
  private readonly _schemaName: string;
  private readonly _lookupId: string;

  private _mlp: IMLPSubspace;

  private _dsStateService: DsStateService1;
  private _lookupDataService: LookupDataService;

  public constructor(schemaName: string, subspacePtr: ISubspacePtr) {
    super({
      error: null,
      loading: true,
      subspace: null,
    });

    this._subspacePtr = subspacePtr;
    this._schemaName = schemaName;
    this._lookupId = String(subspacePtr.lookupId);

    this._initService();
  }

  private _initService(): void {
    this._dsStateService = DsStateService1.createInstance(this._schemaName);
    this._dsStateService.subscribeUpdatesAndNotify(this._onDsStateServiceUpdate);
  }

  private _onDsStateServiceUpdate = async (dsStateModel: IDsState): Promise<void> => {
    if (dsStateModel.error && dsStateModel.loading) return;
    this._createMLP();
    const subspace = await this._createSubspace([0, this.DEFAULT_LIMIT], [0, this.DEFAULT_LIMIT]);
    const loading = this._lookupDataService?.getModel()?.loading || false;
    this._updateWithData({subspace, loading});
  }

  private _loadData = async (offset: number, limit: number): Promise<ILookupDataModel> => {
    const result = {rows: [], columns: [], loading: false, error: null, noData: true};
    if (offset < 0 || limit < 0) return result;
    const {lookupId} = this._subspacePtr;

    if (this._lookupDataService) {
      this._lookupDataService.abort();
      this._lookupDataService.release();
      this._lookupDataService = null;
    }

    try {
      this._lookupDataService = new LookupDataService(lookupId, this._schemaName, this._mlp, {offset, limit});
      await this._lookupDataService.whenReady();
      return this._lookupDataService.getModel();

    } catch (error) {
      this._updateModel({error});
    }
    return result;
  }

  private _getXs = async (options: any[], offset: number, limit: number): Promise<IEntity[]> => {
    const data = await this._loadData(offset, limit);
    const {rows, columns} = data;

    const axes: IEntity[] = [];
    for (let i = 0; i < rows.length; i++) {
      const record = joinDataLookup(rows[i], columns);
      const idx = offset + i;
      const axis = makeAxisLookup(record, options, idx);
      axes.push(axis);
    }
    return axes;

    function makeAxisLookup(data: any, options: { name: string, title: string, config?: any }[], idx) {
      const axis: IEntity = {id: '', title: '', ids: [], axisIds: [], titles: [], config: {}, unit: null};
      options.forEach((attr, i) => {
        const name = data[attr?.name] ?? attr.name;
        const uniqId = idx + i;
        axis.ids.push(uniqId);
        axis.axisIds.push(attr.name);
        axis.titles.push(name);
        if (attr.config) axis.config[name] = attr.config;
        if (name === '__idx') axis.titles[i] = '';
      });
      axis.id = axis.ids.join(' ');
      axis.title = axis.titles.join(' ');

      return axis;
    }
  }

  private _getYs = (options: any[], offset: number, limit: number): IEntity[] => {
    if (offset < 0 || limit < 0) return [];
    const axes: IEntity[] = [];

    options.forEach((attr, i) => {
      const axis: IEntity = makeNullAxis();
      axis.ids.push(attr.name);
      axis.axisIds.push('measures');
      axis.titles.push(attr.title);
      if (attr.config) axis.config = attr.config;
      axis.id = axis.ids.join(' ');
      axis.title = axis.titles.join(' ');
      axes.push(axis);
    });


    return axes;
  }

  private async _makeAxes(): Promise<{ xAxis: any[], yAxis: any[] }> {
    const {dataSource} = this._subspacePtr;
    const data = await this._loadData(1, 1);

    const {columns} = data;

    const axes: any[] = [];
    const _axes: any = [];

    const xAxisCfg = (dataSource.xAxis || '').split(';');
    const yAxisCfg = (dataSource.yAxis || '').split(';');

    columns.forEach((column) => {
      const idx: number = xAxisCfg.indexOf(column.name);
      const idy: number = yAxisCfg.indexOf(column.name);
      if (idx >= 0 || idy >= 0) axes.push(column);
      else _axes.push(column);
    });

    let xAxis: any[] = [];
    let yAxis: any[] = [];

    axes.forEach((column) => {
      const idx: number = xAxisCfg.indexOf(column.name);
      const idy: number = yAxisCfg.indexOf(column.name);
      xAxis[idx] = column;
      yAxis[idy] = column;
    });

    xAxis = xAxis.filter(Boolean);
    yAxis = yAxis.filter(Boolean);

    if (!xAxis.length) xAxis = [{name: '__idx', title: ''}];
    if (!yAxis.length) yAxis = _axes;

    return {xAxis, yAxis};
  }

  private _createSubspace = async (x: [number, number], y: [number, number]): Promise<ISubspace> => {
    const {xAxis, yAxis} = await this._makeAxes();
    const {ms, ls, ps} = this._mlp;

    const xsLimit = x[1] - x[0];
    const ysLimit = y[1] - y[0];

    const ys: IEntity[] = this._getYs(yAxis, y[0], ysLimit);
    const xs: IEntity[] = await this._getXs(xAxis, x[0], xsLimit);
    const zs: IEntity[] = [];

    if (xs.length === 0) xs.push(makeNullAxis());
    if (ys.length === 0) ys.push(makeNullAxis());
    if (zs.length === 0) zs.push(makeNullAxis());

    const yAxisId = yAxis.map(y => y.name).join(';');
    const xAxisId = xAxis.map(x => x.name).join(';');
    const schemaName = this._schemaName;
    const subspace: ISubspace = {
      lookupId: this._lookupId,
      schemaName,
      xAxis: xAxisId,
      yAxis: yAxisId,
      offset: x[0],
      limit: xsLimit,

      ms, ls, ps,
      xs, ys, zs,

      reduce: function (nx, ny, nz): ISubspace {
        this.xs = nEntities(xs, nx);
        this.ys = nEntities(ys, ny);
        this.zs = nEntities(zs, nz);
        return this;
      },

      createSubspaceAxes: async (x, y) => this._createSubspace(x, y),
      getXsLength: async () => xs.length, // todo дождаться api для всей длинны
      getYsLength: async () => ys.length, // длинна неизменна

      // mlp fn
      getZ: (id: number) => zs[id],
      getY: (id: number) => ys[id],
      getX: (id: number) => xs[id],
      isEmpty: () => !(ys.length),
      getMLP: (...arg: any) => arg,

    };
    return subspace;
  }

  private _createMLP(): void {
    const ms: IMetric[] = this._getMs();
    const ls: ILocation[] = this._getLs();
    const ps: IPeriod[] = this._getPs();
    this._mlp = {ms, ls, ps};
  }

  // TODO убрал: зависимость external && onUpdate ??
  private _getMs(): IMetric[] {
    let ms: IMetric[] = [];
    const dsModel: IDatasetModel = this._dsStateService.getDataset();
    const state: IDsState = this._dsStateService.getModel();

    const mIdsExpr: any = this._subspacePtr.getMIds();

    let wantSubscribeForAllMetrics: boolean = false;
    let wantSubscribeForUrl: boolean = false;

    if (Array.isArray(mIdsExpr) && mIdsExpr.length) {
      ms = mIdsExpr.map((id: string): IMetric => {
        if (typeof id === 'object') {
          return makeMetrics(id, dsModel.units, dsModel.metrics);
        } else if (typeof id === 'string' && id[0] === '=') {
          return makeMetrics(id, dsModel.units, dsModel.metrics);
        }
        const m = $eid(dsModel.metrics, id);
        return m;
      }).filter(m => !!m);

    } else if ((typeof mIdsExpr === 'string') && (mIdsExpr === '*' || mIdsExpr === '(..)')) {
      wantSubscribeForAllMetrics = true;
      ms = dsModel.metrics;

    } else if (typeof mIdsExpr === 'string' && mIdsExpr.startsWith('lpe:')) {

      ms = lpeRun(mIdsExpr, {
        ms: dsModel.metrics,
        es: dsModel.metrics,
        state: state.metrics,
        byId: (id) => $eid(dsModel.metrics, id),
        m: (id) => $eid(dsModel.metrics, id),
        e: (id) => $eid(dsModel.metrics, id),
        e$: (...ids) => $esid(dsModel.metrics, ids.length === 1 && Array.isArray(ids[0]) ? ids[0] : ids),
        url: (key) => {
          wantSubscribeForUrl = true;                                                               // TODO subscribe only for key
          const model: any = UrlState.getInstance().getModel();
          return model[key] || '';
        },
        dataSource: this._subspacePtr.dataSource,
      });

    } else {
      ms = dsModel.defaultMetrics;
    }
    ms = esApplyDrilldown(ms, this._subspacePtr.metricsDrilldown);
    return ms;
  }

  // TODO убрал: зависимость external && onUpdate ??
  private _getLs(): ILocation[] {
    let ls: ILocation[] = [];
    const dsModel: IDatasetModel = this._dsStateService.getDataset();
    const state: IDsState = this._dsStateService.getModel();

    const lIdsExpr: any = this._subspacePtr.getLIds();

    let wantSubscribeForAllLocations: boolean = false;
    let wantSubscribeForUrl: boolean = false;

    if (Array.isArray(lIdsExpr) && lIdsExpr.length) {
      ls = lIdsExpr.map((id: string): ILocation => {
        if (typeof id === 'object') {
          return makeLocation(id);
        } else if (typeof id === 'string' && id[0] === '=') {
          return makeLocation(id);
        }
        const l = $eid(dsModel.locations, id);
        return l;
      }).filter(l => !!l);

    } else if (lIdsExpr === '*' || lIdsExpr === '(..)') {
      wantSubscribeForAllLocations = true;
      ls = dsModel.locations;

    } else if (typeof lIdsExpr === 'string' && lIdsExpr.startsWith('lpe:')) {
      wantSubscribeForAllLocations = true;
      ls = lpeRun(lIdsExpr, {
        ls: dsModel.locations,
        es: dsModel.locations,
        state: state.locations,
        STATE: (x: any) => {
          if (!x) return state;
          return state[x];
        },
        lastState: oneEntity(state.locations),
        byId: (id) => $eid(dsModel.locations, id),
        l: (id) => $eid(dsModel.locations, id),
        e: (id) => $eid(dsModel.locations, id),
        e$: (...ids) => $esid(dsModel.locations, ids.length === 1 && Array.isArray(ids[0]) ? ids[0] : ids),
        url: (key) => {
          wantSubscribeForUrl = true;                                                               // TODO subscribe only for key
          const model: any = UrlState.getInstance().getModel();
          return model[key] || '';
        },
        dataSource: this._subspacePtr.dataSource,
      });

    } else {
      ls = dsModel.defaultLocations;
    }

    ls = esApplyDrilldown(ls, this._subspacePtr.locationsDrilldown);

    return ls;
  }

  // TODO убрал: зависимость external && onUpdate ??  &&   subscriptions.push(dsModel.subscribe('periodsUpdated', onUpdate));
  private _getPs(): IPeriod[] {
    let ps: IPeriod[] = [];
    const dsModel: IDatasetModel = this._dsStateService.getDataset();
    const state: IDsState = this._dsStateService.getModel();

    const pIdsExpr: any = this._subspacePtr.getPIds();
    const periodType: string = this._subspacePtr.getPType();

    let wantSubscribeForAllPeriods: boolean = false;                            // may ba always true?
    let wantSubscribeForUrl: boolean = false;

    if (Array.isArray(pIdsExpr) && pIdsExpr.length) {                           // array (ids)
      ps = $esid(dsModel.periods, pIdsExpr);

    } else if (isArray(pIdsExpr) && pIdsExpr.length === 0) {              // []
      ps = [];

    } else if (isHash(pIdsExpr)) {                                                          // {start, end}
      ps = dsModel.getPeriodInfoByRange(pIdsExpr.start, pIdsExpr.end).periods as Period[];

    } else if (periodType != null && String(periodType).match(/^(\d+)(?:\.(\d+))?$/)) {       // period-type


      const pt: number = parseInt(RegExp.$1);
      const periods: IPeriod[] = dsModel.periodsHelper.getPeriodsByTypeId(pt);
      markContinuousPeriodType(periods, [pt, 1]);

      if (typeof pIdsExpr === 'string') {                                                     // period_type + biPath
        // надо вычислять через lpe
      } else {
        ps = periods;
      }

    } else if (typeof pIdsExpr === 'string' && pIdsExpr.startsWith('lpe:')) {
      wantSubscribeForAllPeriods = true;
      ps = lpeRun(pIdsExpr, {
        ps: dsModel.periods,
        es: dsModel.periods,
        state: state.periods,
        lastState: oneEntity(state.periods),                                                        // TODO: implement getters in lpe and subscribe on state
        // disabled due to conflict with expression "[url(period).end.e()]"
        // start: state.periods[0] || null,
        // end: oneEntity(state.periods),
        byId: (id) => $eid(dsModel.periods, id),
        p: (id) => $eid(dsModel.periods, id),
        e: (id) => $eid(dsModel.periods, id),
        idDecYear: (id) => String(id).replace(/^..../, (v) => String(+v - 1)),
        last: (ps, expr) => {
          const P = ps[ps.length - 1];
          if (!P) return [];
          if (expr == null) return P;                                                               // WARN: last() returns one entity (not array) when no expression
          let {year, quarter, month, day} = P;
          day = P.date.getDate();
          switch (expr) {
            case 'year':
              return ps.filter(p => p.year == year);
            case 'quarter':
              return ps.filter(p => p.quarter === quarter && p.year == year);
            case 'month':
              return ps.filter(p => p.month === month && p.year == year);
            case 'day':
              return ps.filter(p => p.day === day && p.month === month && p.year == year);
          }
          return [];
        },
        prev: (ps: IPeriod[], expr, count = 1) => {
          const P = ps[ps.length - 1];
          if (!P) return [];
          if (expr == null) return ps[ps.length - 2];                                               // WARN: last() returns one entity (not array) when no expression
          let {year, quarter, month, day} = P;
          day = P.date.getDate();
          switch (expr) {
            case 'year': {
              year -= count;
              return ps.filter(p => p.year === year);
            }
            case 'quarter': {
              year -= Math.floor(count / 4);
              const currentCount = count != 0 ? count - 4 * Math.floor(count / 4) : 0;
              if (quarter - currentCount < 0) {
                year--;
                quarter = 4 + (quarter - currentCount);
              } else if (quarter == currentCount) {
                year--;
                quarter = 4;
              } else {
                quarter -= currentCount;
              }
              return ps.filter(p => p.quarter === quarter && p.year == year);
            }
            case 'month': {
              let curDate = new Date(year, month - 1, 1);
              curDate.setMonth(curDate.getMonth() - count);
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              return ps.filter(p => p.year == year && p.month == month);
            }
            case 'day': {
              console.log('before:', day, month, year, quarter);
              let curDate = new Date(year, month - 1, day);
              curDate.setDate(curDate.getDate() - count);
              day = curDate.getDate();
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              console.log('after:', day, month, year, quarter);
              return ps.filter(p => p.day === day && p.month === month && p.year == year);
            }
          }
          return [];
        },
        next: (ps: IPeriod[], expr, count = 1) => {
          const P = ps[ps.length - 1];
          if (!P) return [];
          let {year, quarter, month, day} = P;
          const PNext = dsModel.periods[dsModel.periods.indexOf(P) + 1];
          if (expr == null) return PNext;
          switch (expr) {
            case 'year': {
              year += count;
              return dsModel.periods.filter(p => p.year === year);
            }
            case 'quarter': {
              year += Math.floor(count / 4);
              const currentCount = count != 0 ? count - 4 * Math.floor(count / 4) : 0;
              if (quarter - currentCount < 0) {
                year++;
                quarter = 4 + (quarter + currentCount);
              } else if (quarter == currentCount) {
                year++;
                quarter = 4;
              } else {
                quarter += currentCount;
              }
              return dsModel.periods.filter(p => p.quarter === quarter && p.year == year);
            }
            case 'month': {
              let curDate = new Date(year, month - 1, 1);
              curDate.setMonth(curDate.getMonth() + count);
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              return dsModel.periods.filter(p => p.year == year && p.month == month);
            }
            case 'day': {
              let curDate = new Date(year, month - 1, day);
              curDate.setDate(curDate.getDate() + count);
              day = curDate.getDate();
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              return dsModel.periods.filter(p => p.day === day && p.month === month && p.year == year);
            }
          }
          return [];
        },
        pt: state.periods[state.periods.length - 1].period_type,
        e$: (...ids) => $esid(dsModel.periods, ids.length === 1 && Array.isArray(ids[0]) ? ids[0] : ids),
        url: (key) => {
          wantSubscribeForUrl = true;                                                               // TODO subscribe only for key
          const model: any = UrlState.getInstance().getModel();
          return model[key] || '';
        },
        dataSource: this._subspacePtr.dataSource,
      });
      ps = ps.filter(IS_P);

    } else ps = dsModel.defaultPeriods;

    return ps;
  }


  protected _dispose() {
    this._dsStateService.release();
    this._dsStateService = null;

    this._lookupDataService.release();
    this._lookupDataService = null;

    super._dispose();
  }
}
