const utils = require('../../utils/utils');
const utilsEcharts = require('../../utils/utilsEchars');
const {$eid, $eidx, $esid} = require('../imdas/list');
const cUtils = require('../../views/vizels/utility/c-utils');
const formatNumberWithString = require('@luxms/format-number-with-string');
import {mouseWatcher} from '../../libs/MouseWatcher';

const skin = require('../../skins/skin.json');
import {parse as wktParse} from "wellknown";
import {lpeRun} from '../../utils/lpeRun';
import L from 'leaflet';

module.exports = {...utils, ...utilsEcharts, L, wktParse,  $eid, $eidx, $esid, formatNumberWithString, mouseWatcher, ...cUtils, skin, lpeRun};
