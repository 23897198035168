/// <reference path="../defs/bi.d.ts" />

import uniq from 'lodash/uniq';
import { fixColorPairEx } from '../config/ColorPair';
import { IS_LS, IS_MS, IS_PS, makeColor } from '../utils/utils';
import { createNullVector } from '../data-manip/data-utils';
import { INormsResponse, INormZone } from '../data-manip/data-manip';
import { $eidx } from '../libs/imdas/list';

enum Direction {
  SUP, INF,
}

export class NormZone implements INormZone {
  public readonly id: string;
  public readonly normTitle: string;
  public readonly title: string;
  public metric: IMetric = null;
  public color: string = null;
  public bgColor: string = null;
  public readonly hasInf: boolean;
  public readonly hasSup: boolean;
  public readonly infTitle: string;
  public readonly supTitle: string;
  public readonly infColor: string;
  public readonly supColor: string;

  private _infMetric: IMetric = null;
  private _supMetric: IMetric = null;
  private _infDataVector: number[] = [];
  private _supDataVector: number[] = [];
  private _xs: IEntity[] = [];

  public constructor(zoneMetric: IMetric) {
    console.assert(!!zoneMetric);
    this.metric = zoneMetric;
    this.id = this.metric.id;
    this.normTitle = zoneMetric.parent.title;
    this.title = this.metric.title;
    this.metric.children.forEach((m: IMetric) => {
      if (m.config && (m.config.normValue === 'infimum' || m.config.normValue === 'min')) this._infMetric = m;
      if (m.config && (m.config.normValue === 'supremum' || m.config.normValue === 'max')) this._supMetric = m;
    });
    this.color = (this.metric.config && this.metric.config.color) ? makeColor(this.metric.config.color) : null;
    this.bgColor = (this.metric.config && this.metric.config.bgColor) ? makeColor(this.metric.config.bgColor) : null;
    this.hasInf = !!this._infMetric;
    this.hasSup = !!this._supMetric;
    this.infTitle = this.hasInf ? this._infMetric.title : null;
    this.supTitle = this.hasSup ? this._supMetric.title : null;
    this.infColor = this.hasInf ? makeColor(this._infMetric.config.color) : null;
    this.supColor = this.hasSup ? makeColor(this._supMetric.config.color) : null;
  }

  public setData(subspace: ISubspace, data: tables.INormDataEntry[]): void {
    //
    // probably we will get one z and one y
    //
    const {xs, ys, zs} = subspace;

    this._xs = xs;

    this._infDataVector = [];
    this._supDataVector = [];

    if (this._infMetric) {
      this._infDataVector = createNullVector(xs.length);
      data.forEach((d: tables.INormDataEntry) => {
        if (d.norm_id === this._infMetric.id) {
          let idx: number;
          if (IS_MS(this._xs)) idx = $eidx(this._xs, d.metric_id);
          else if (IS_LS(this._xs)) idx = $eidx(this._xs, d.loc_id);
          else if (IS_PS(this._xs)) idx = $eidx(this._xs, d.period_id);

          if (idx !== -1) this._infDataVector[idx] = d.value as number;
        }
      });
    }

    if (this._supMetric) {
      this._supDataVector = createNullVector(xs.length);
      data.forEach((d: tables.INormDataEntry) => {
        if (d.norm_id === this._supMetric.id) {
          let idx: number;
          if (IS_MS(this._xs)) idx = $eidx(this._xs, d.metric_id);
          else if (IS_LS(this._xs)) idx = $eidx(this._xs, d.loc_id);
          else if (IS_PS(this._xs)) idx = $eidx(this._xs, d.period_id);

          if (idx !== -1) this._supDataVector[idx] = d.value as number;
        }
      });
    }
  }

  public getInfData(): number[] {
    return this._infDataVector;
  }

  public getSupData(): number[] {
    return this._supDataVector;
  }

  public hasValue(v: number, e: IEntity = null): boolean {
    try {
      if (v == null) return false;
      if (e == null) e = this._xs[this._xs.length - 1];
      if (!e) return false;
      const inf: number = this.getInfValueForEntity(e);    // -inf if not defined inf-metric
      const sup: number = this.getSupValueForEntity(e);

      if (inf == null || sup == null) {  // seems to be no norm for this entity
        return false;
      }
      // invalid cases might be:
      //   null .. null
      //   -inf .. null
      //   null .. +inf
      // TODOL: check (number .. null) and (null .. number)

      // if(inf == null) inf = -Infinity;      // TODO: check when it may be null (errors)
      // if(sup == null) sup = +Infinity;
      return inf <= v && v <= sup;
    } catch (err) {
      return false;
    }
  }

  public getInfValueForEntity(e: IEntity = null): number {
    if (e == null) e = this._xs[this._xs.length - 1];
    if (!e) return -Infinity;
    const idx: number = $eidx(this._xs, e.id);

    if (idx === -1) throw new Error('No entity in norms: inf');
    if (!this.hasInf) return -Infinity;
    return this._infDataVector[idx];
  }

  public getSupValueForEntity(e: IEntity = null): number {
    if (e == null) e = this._xs[this._xs.length - 1];
    if (!e) return +Infinity;
    const idx: number = $eidx(this._xs, e.id);
    if (idx === -1) throw new Error('No entity in norms: sup');
    if (!this.hasSup) return +Infinity;
    return this._supDataVector[idx];
  }
}

export class NormZone3 implements INormZone {
  public readonly id: string;
  public readonly normTitle: string;
  public readonly title: string;
  public readonly color: string;
  public readonly bgColor: string;
  public readonly hasInf: boolean;
  public readonly hasSup: boolean;
  public readonly infTitle: string;
  public readonly supTitle: string;
  public readonly infColor: string;
  public readonly supColor: string;
  private _normMetric: IMetric;
  private _zi: number;
  private _zoneConfig: any;
  private _xs: IEntity[];
  private _infDataVector: number[] = [];
  private _supDataVector: number[] = [];

  public constructor(normMetric: IMetric, zi: number) {
    this._normMetric = normMetric;
    this._zi = zi;
    const {zones, borders}: any = normMetric.config;
    this._zoneConfig = zones[zi];
    const loBorderConfig = borders[zi - 1];
    const hiBorderConfig = borders[zi];

    this.id = normMetric.id + '-' + String(zi);
    this.normTitle = normMetric.title;
    this.title = this._zoneConfig.title || '';
    this.color = makeColor(this._zoneConfig.color);
    this.bgColor = makeColor(this._zoneConfig.background || this._zoneConfig.bgColor);
    this.hasInf = !!loBorderConfig;
    this.hasSup = !!hiBorderConfig;
    this.infTitle = loBorderConfig ? loBorderConfig.title : null;
    this.supTitle = hiBorderConfig ? hiBorderConfig.title : null;
    this.infColor = loBorderConfig ? makeColor(loBorderConfig.color) : null;
    this.supColor = hiBorderConfig ? makeColor(hiBorderConfig.color) : null;
  }

  public setData(subspace: ISubspace, data: tables.INormDataEntry3[]): void {

    //
    // probably we will get one z and one y
    //
    const {xs, ys, zs} = subspace;
    this._xs = xs;
    this._infDataVector = [];
    this._supDataVector = [];

    if (this.hasInf) {
      this._infDataVector = createNullVector(xs.length);
      data.forEach((d: tables.INormDataEntry3) => {
        if (String(d.norm_id) === this._normMetric.id) {
          let idx: number;
          if (IS_MS(this._xs)) idx = $eidx(this._xs, d.metric_id);
          else if (IS_LS(this._xs)) idx = $eidx(this._xs, d.loc_id);
          else if (IS_PS(this._xs)) idx = $eidx(this._xs, d.period_id);

          if (idx !== -1) this._infDataVector[idx] = d.val[this._zi - 1];
        }
      });
    }

    if (this.hasSup) {
      this._supDataVector = createNullVector(xs.length);
      data.forEach((d: tables.INormDataEntry3) => {
        if (String(d.norm_id) === this._normMetric.id) {
          let idx: number;
          if (IS_MS(this._xs)) idx = $eidx(this._xs, d.metric_id);
          else if (IS_LS(this._xs)) idx = $eidx(this._xs, d.loc_id);
          else if (IS_PS(this._xs)) idx = $eidx(this._xs, d.period_id);
          else if (subspace.getXs) {
            const xID = subspace.getXs(d.metric_id, d.loc_id, d.period_id);
            idx = $eidx(this._xs, xID);
          }

          if (idx !== -1) this._supDataVector[idx] = d.val[this._zi];
        }
      });
    }
  }

  public hasValue(v: number, e: IEntity = null): boolean {
    try {
      if (v == null) return false;
      if (e == null) e = this._xs[this._xs.length - 1];
      if (!e) return false;
      const inf: number = this.getInfValueForEntity(e);    // -inf if not defined inf-metric
      const sup: number = this.getSupValueForEntity(e);

      if (inf == null || sup == null) {  // seems to be no norm for this entity
        return false;
      }
      // invalid cases might be:
      //   null .. null
      //   -inf .. null
      //   null .. +inf
      // TODOL: check (number .. null) and (null .. number)

      // if(inf == null) inf = -Infinity;      // TODO: check when it may be null (errors)
      // if(sup == null) sup = +Infinity;

      if (this._zoneConfig && (this._zoneConfig.contains === false)) {
        return inf < v && v < sup;
      } else if (this._zoneConfig && (this._zoneConfig.contains === 'right')) {
        return inf < v && v <= sup;
      } else if (this._zoneConfig && (this._zoneConfig.contains === 'left')) {
        return inf <= v && v < sup;
      } else {
        return inf <= v && v <= sup;
      }

    } catch (err) {
      return false;
    }
  }

  public getInfData(): number[] {
    return this._infDataVector;
  }

  public getSupData(): number[] {
    return this._supDataVector;
  }

  public getInfValueForEntity(e: IEntity = null): number {
    if (e == null) e = this._xs[this._xs.length - 1];
    if (!e) return -Infinity;
    const idx: number = $eidx(this._xs, e.id);

    if (idx === -1) throw new Error('No entity in norms: inf');
    if (!this.hasInf) return -Infinity;
    return this._infDataVector[idx];
  }

  public getSupValueForEntity(e: IEntity = null): number {
    if (e == null) e = this._xs[this._xs.length - 1];
    if (!e) return +Infinity;
    const idx: number = $eidx(this._xs, e.id);
    if (idx === -1) throw new Error('No entity in norms: sup');
    if (!this.hasSup) return +Infinity;
    return this._supDataVector[idx];
  }
}

export class FakeZone implements INormZone {
  public readonly id: string;
  public readonly normTitle: string;
  public readonly title: string;
  public readonly color: string = null;
  public readonly bgColor: string = null;
  public readonly hasInf: boolean;
  public readonly hasSup: boolean;
  public readonly infTitle: string;
  public readonly supTitle: string;
  public readonly infColor: string;
  public readonly supColor: string;
  public metric: IMetric = null;
  public fake: boolean = true;
  private _colorCfg: any;
  private _zone: NormZone;
  private _zoneType: Direction;

  public constructor(zone: NormZone, zoneType: Direction, colorCfg: any) {
    this._zone = zone;
    this._zoneType = zoneType;
    this._colorCfg = colorCfg;
    this.id = zone.id + (this._zoneType === Direction.SUP ? '-FAKE-SUP' : '-FAKE-INF');
    this.normTitle = zone.normTitle;
    this.title = ('title' in this._colorCfg) ? this._colorCfg.title : this._zone.title;
    this.color = this._colorCfg.color ? makeColor(this._colorCfg.color) : null;
    this.bgColor = (this._colorCfg.bgColor) ? makeColor(this._colorCfg.bgColor) : null;
    this.hasInf = (this._zoneType === Direction.INF) ? false : zone.hasSup;
    this.hasSup = (this._zoneType === Direction.INF) ? zone.hasInf : false;
    this.infTitle = (this._zoneType === Direction.INF) ? null : zone.supTitle;
    this.supTitle = (this._zoneType === Direction.INF) ? zone.infTitle : null;
    this.infColor = (this._zoneType === Direction.INF) ? null : zone.supColor;
    this.supColor = (this._zoneType === Direction.INF) ? zone.infColor : null;
  }

  public hasValue(v: number, e: IEntity = null): boolean {
    if (v == null) return false;
    let sup: number, inf: number;
    if (this._zoneType === Direction.INF) {    // our zone is lower then _zone
      inf = -Infinity;
      sup = this._zone.getInfValueForEntity(e);
    } else {                                     // upper
      inf = this._zone.getSupValueForEntity(e);
      sup = +Infinity;
    }
    return inf <= v && v <= sup;
  }

  public getInfData(): number[] {
    if (this._zoneType === Direction.SUP) return this._zone.getSupData();
    return [];
  }

  public getSupData(): number[] {
    if (this._zoneType === Direction.INF) return this._zone.getInfData();
    return [];
  }

}


export class NormsResponse implements INormsResponse {
  private _zones: INormZone[];

  public constructor(subspace: ISubspace, data: tables.INormDataEntry[] | tables.INormDataEntry3[]) {
    // v3
    if (data.length && data[0].normMetric.parent === null) {
      this._init3(subspace, data as tables.INormDataEntry3[]);
    } else {
      this._init2(subspace, data as tables.INormDataEntry[]);
    }
  }

  private _init3(subspace: ISubspace, data: tables.INormDataEntry3[]) {
    const normMetrics: IMetric[] = uniq((data as any).map(d => d.normMetric));

    this._zones = [];
    normMetrics.forEach(normMetric => {
      for (let zi = 0; zi < normMetric.config.zones.length; zi++) {
        const zone = new NormZone3(normMetric, zi);
        zone.setData(subspace, data);
        this._zones.push(zone);
      }
    });
  }

  private _init2(subspace: ISubspace, data: tables.INormDataEntry[]) {
    const borderMetrics: IMetric[] = uniq((data as any).map(d => d.normMetric));
    const zoneMetrics: IMetric[] = uniq(borderMetrics.map((p: IMetric) => p.parent));

    this._zones = zoneMetrics.map((zoneMetric: IMetric) => new NormZone(zoneMetric));
    (this._zones as NormZone[]).forEach((zone: NormZone) => zone.setData(subspace, data));

    zoneMetrics.forEach((m: IMetric, idx: number) => {
      if (m.config && (m.config.extraZoneInf || m.config.extraZoneBelow)) {
        this._zones.push(new FakeZone(this._zones[idx] as NormZone, Direction.INF, m.config.extraZoneInf || m.config.extraZoneBelow));
      }
      if (m.config && (m.config.extraZoneSup || m.config.extraZoneAbove)) {
        this._zones.push(new FakeZone(this._zones[idx] as NormZone, Direction.SUP, m.config.extraZoneSup || m.config.extraZoneAbove));
      }
    });
  }

  public getZones(): INormZone[] {
    return this._zones;
  }

  private _getZoneForValue(v: IValue, e: IEntity = null): INormZone {
    for (let zone of this._zones) {
      if (zone.hasValue(v, e)) {
        return zone;
      }
    }
    return null;
  }

  public getColorPair(e: IEntity, v: IValue): IColorPair {
    const zone: INormZone = this._getZoneForValue(v, e);
    return fixColorPairEx(zone);
  }

  public getColor(e: IEntity, v: IValue): string {
    return this.getColorPair(e, v).color;
  }

  public getBgColor(e: IEntity, v: IValue): string {
    return this.getColorPair(e, v).bgColor;
  }

  public getTitle(e: IEntity, v: IValue): string {
    const zone: INormZone = this._getZoneForValue(v, e);
    return zone ? zone.title : null;
  }
}
