import { coloring, makeColor } from '../utils/utils';
import { IColorPair, IRawColor, IRawColorPair } from '../defs/bi';
import Color from 'color';

/**
 * Make color and bgColor if only one is provided.
 * arguments are valid stringcolors
 *
 * @param {{color: string; bgColor: string}} raw
 * @returns {{color: string; bgColor: string}}
 * @private
 */
function _fixColorPairEx(raw: { color?: IRawColor, bgColor?: IRawColor }): { color: string | null, bgColor: string | null} {
  const color: string | null = makeColor(raw.color);
  const bgColor: string | null = makeColor(raw.bgColor);

  if (color && bgColor) {
    return {color, bgColor};
  }
  if (color && !bgColor) {
    return {color, bgColor: Color(raw.color).lighten(0.1).rgb().toString()};
  }
  if (!color && bgColor) {
    return {color: Color(raw.color).darken(0.1).rgb().toString(), bgColor};
  }
  return {color: null, bgColor: null};
}

/**
 * make color and bgColor if only one is provided
 * arguments are any of types that LuxmsBI provides (IRawColor = string/number/number array)
 * arguments will go through makeColor function
 *
 * @param {IRawColorPair} colorPair
 * @returns {IColorPair}
 */
export function fixColorPairEx(colorPair: IRawColorPair): IColorPair {
  let rawColor: IRawColor = null;
  let bgColor: IRawColor = null;
  if (!colorPair) {
    //
  } else if (typeof colorPair === 'string') {      // comma separated color, bgColor
    [rawColor, bgColor] = colorPair.split(';');
  } else if (Array.isArray(colorPair)) {
    [rawColor, bgColor] = colorPair;
  } else if (typeof colorPair === 'object') {
    rawColor = colorPair.color;
    bgColor = colorPair.bgColor;
  }

  return _fixColorPairEx({
    color: makeColor(rawColor),
    bgColor: makeColor(bgColor),
  });
}
