import $ from 'jquery';
import isObject from 'lodash/isObject';
import toPairs from 'lodash/toPairs';
import * as charts from './charts';
import * as L from 'leaflet';
import { bi, lang, makeColor, MessageHub, oneEntity } from '../../utils/utils';
import {urlState, AppConfig, IDisposable} from '@luxms/bi-core';
import { Stoplights } from '../../config/Stoplights';
import { $eid, getEntity } from '../../libs/imdas/list';
import { isNullVector, matrixMax, vectorMax, vectorMin } from '../../data-manip/data-utils';
import { data_engine, INormsResponse } from '../../data-manip/data-manip';
import { IConfigHelper, IDatasetModel, IVizelClass, IVizelConfig } from '../../services/ds/types';
import { MarkerClusterGroup } from 'leaflet.markercluster';
const Color = require('color');
const skin: any = require('../../skins/skin.json');
import './MarkerCluster.css';
import './MarkerCluster.Default.css';
import './leaflet.css';
import './clustering.scss';
import React from 'react';
import VizelLCard from '../vizels/VizelLCard';
import ReactDOM from 'react-dom';
import {ISubspace, ILocation, IMetric, IValue, tables, IStoplight, IMLPSubspace, ILction, IPeriod} from '../../defs/bi';
import {SubspacePtr} from '../../services/ds/ds-helpers';

import {KoobFiltersService} from '../../services/koob/KoobFiltersService';
import {KoobSubspaceAsyncService} from '../../services/koob/KoobSubspaceAsyncService';

export class BaseMarker {
  public id: string;
  public popupData: object;
  public title: string;
  public latlng: L.LatLng;
  // public lmarker: IMarkerEx;            // L.Marker;
  public lmarker: any;            // L.Marker;
  public $container = null;
  public visible: boolean = true;
  public color: string = null;
  private _location: ILocation;
  private location: ILocation;

  public constructor(location: ILocation, dataset: IDatasetModel) {
    this._location = location;
    const altTitleType: string = dataset.getConfigHelper().getStringValue('map.locations.titleType', '');
    this.title = location.getAltTitle(altTitleType);
    this.latlng = new L.LatLng(location.latitude, location.longitude);
  }

  public initLMarker(icon: L.DivIcon, z: number = 0) {
    const options: any = {
      icon: icon,
      title: this.title,
      riseOnHover: true,
    };
    // this.lmarker = new L.Marker(this.latlng, options) as IMarkerEx;
    this.lmarker = new L.Marker(this.latlng, options) as any;
    // FIXME: pleasepleaseplease
    this.lmarker.setval = function (v) {
      this['val'] = v;
    };
    this.lmarker['getval'] = function () {
      return this['val'];
    };
    this.lmarker.setval(0);
    this.lmarker.location = this._location ? this._location : this.location;

    this.lmarker.setZIndexOffset(z);
    this.lmarker.addEventListener('add', (e) => {
      this.$container = $('#' + this.id);
      if (this.color) this.applyColorInternal();
    });
  }

  public initClusterMarker(icon: L.DivIcon, z: number = 0) {
    const options: any = {
      icon: icon,
      title: this.title,
      riseOnHover: true,
    };
    this.lmarker = new L.Marker(this.latlng, options) as IMarkerEx;
    // FIXME: pleasepleaseplease
    this.lmarker.setval = function (v) {
      this['val'] = v;
    };
    this.lmarker['getval'] = function () {
      return this['val'];
    };
    this.lmarker.setval(0);
    this.lmarker.location = this._location;

    this.lmarker.setZIndexOffset(z);
    this.lmarker.addEventListener('add', (e) => {
      this.$container = $('#' + this._location.id);
      if (this.color) this.applyColorInternal();
    });
  }

  public setColor(value: string): void {
    this.color = value;

    // FIXME: pleasepleaseplease
    if (this.color == '#ff0000') {
      this.lmarker.setval(1);
    } else {
      this.lmarker.setval(0);
    }

    if (this.$container && this.color != null) this.applyColorInternal();
  }

  public setMetric(m: IMetric): void {
    this.lmarker.metric = m;
    this.lmarker.id = m ? m.id : null;
  }

  public setValue(val: IValue): void {
    this.lmarker.setval(val);
  }

  public getValue(): IValue {
    return this.lmarker.getval();
  }

  public redraw(visible: boolean): void {
    if (null == this.$container) return;
    if (visible) {
      this.$container.parent().removeClass('hidden-marker');
    } else {
      this.$container.parent().addClass('hidden-marker');
    }
  }

  // abstract protected
  public applyColorInternal() {
    //
  }

  // popup for mark
  public clickInfo(idDOM, data){
    if (!Object.keys(data).length) return '';

    const title = Object.keys(data)[0].substr(0, 6) + ' ' + Object.keys(data)[0].substr(6, 3);

    const key = Object.keys(data)[0];

    const obj: any = data[key];

    const values = Object.values(obj).find((d: any) => {
     if (d && Array.isArray(d)) return d;
     return null;
    }) || [];

    const content = values.reduce((array, obj) => {
      array.push(`
        <div class="mark-popup__content">
         <p class="mark-popup__p">${obj['ГосКонтракт']}</p>
         <p class="mark-popup__p">Получатель: ${obj['Получатель']}</p>
         <p class="mark-popup__p">${obj['Наименование']} / Кол-во:${obj['Количество']}</p>
        </div>
      `);
      return array;
    }, []).join('');

    return (`
        <div class="mark-popup">
            <p class="mark-popup__title">ТС:${title}
              <span class="mark-popup__close-button">
                <i class="mark-popup__i-close"></i>
                <i class="mark-popup__i-close"></i>
              </span>
            </p>
            <p class="mark-popup__tel"><a class="mark-popup__link-tel" href="tel:${data[Object.keys(data)[1]]}">Тел: ${data[Object.keys(data)[1]]}</a></p>
            <div class="mark-popup__wrapper-content">${content}</div>
        </div>
    `);
  }
}

export class TitleMarker extends BaseMarker {
  constructor(location: ILocation, dataset: IDatasetModel) {
    super(location, dataset);
    this.popupData = dataset.locations.find(obj => obj.id === location.id).config;
    this.id = 'map-title-' + location.id;
    const icon: L.DivIcon = L.divIcon({
      html: `<div id="${this.id}" class="title chart-title">${this.title}</div>${this.clickInfo(this.id, this.popupData) ? this.clickInfo(this.id, this.popupData) : ''}`,
      iconSize: L.point(-1, -1),
    });
    this.initLMarker(icon, 1000);
  }
}

export class ChartMarker extends BaseMarker {
  public chart: charts.Chart;

  constructor(location: ILocation, dataset: IDatasetModel, chartSize: number) {
    super(location, dataset);
    charts.setSize(chartSize);
    this.chart = new charts.Chart();
    this.popupData = dataset.locations.find(obj => obj.id === location.id).config;
    this.id = 'map-chart-' + location.id;
    const icon: L.DivIcon = L.divIcon({
      html: '<div id="' + this.id + '"></div>' + `${this.clickInfo(this.id, this.popupData)}`,
      iconSize: L.point(chartSize, chartSize),
      iconAnchor: L.point(chartSize >> 1, chartSize, true),
    });
    this.initLMarker(icon, 2000);
  }

  public init() {
    const $el = $('#' + this.id);
    $el.append(this.chart.getCanvasElement());
    this.chart.setContainer($el);
    this.chart.setType(charts.Chart.TYPE_PIE);
  }

  public setData(parameters: IMetric[], vector: IValue[], domain: number[]) {
    this.chart.setData(parameters, vector, domain);
  }

  public setType(value: string) {
    this.chart.setType(value);
  }
}


export class PinMarker extends BaseMarker {

  public constructor(location: ILocation, dataset: IDatasetModel) {
    super(location, dataset);
    this.popupData = dataset.locations.find(obj => obj.id === location.id).config;
    this.id = 'map-pin-' + location.id;
    const svg: string =
      `<svg width="25" height="41" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path clip-rule="evenodd" fill="none" fill-rule="evenodd" stroke="#666666" stroke-miterlimit="10" d="m12.68848,38.33806c-0.79993,-4.23423 -2.21023,-7.75806 -3.91847,-11.0237c-1.26709,-2.42241 -2.73492,-4.65832 -4.09306,-7.00755c-0.45335,-0.78418 -0.84463,-1.6126 -1.28027,-2.42643c-0.87107,-1.62737 -1.57732,-3.51415 -1.53248,-5.96157c0.04387,-2.39133 0.68522,-4.30954 1.61007,-5.87797c1.52116,-2.57959 4.06905,-4.69455 7.48777,-5.25037c2.79518,-0.45444 5.41592,0.31333 7.27435,1.48516c1.51862,0.95762 2.69476,2.23675 3.58862,3.74428c0.93305,1.57347 1.57561,3.43238 1.62943,5.85703c0.02767,1.24224 -0.16092,2.39263 -0.4267,3.34682c-0.26886,0.96587 -0.70137,1.77323 -1.08616,2.63569c-0.75128,1.6834 -1.69301,3.22592 -2.63826,4.7692c-2.81523,4.59712 -5.45764,9.28527 -6.61484,15.70941z"/>
          <circle clip-rule="evenodd" fill="#cccccc" fill-rule="evenodd" cx="12.72183" cy="11.82957" r="3.18299"/>
        </g>
       </svg>`;

    const onClickAction: string = 'openObjectCard';   // dataset.config.getStringValue('map.pin.onClick'); TODO: get real config value
    let cursor: string = 'pointer';
    if (onClickAction === 'openObjectCard' && location.card == null) {     // issue #2617: no pointer cursor when pin has no location-card
      cursor = 'default';
    }

    const icon: L.DivIcon = L.divIcon({
      html: `<div class="PinMarker" id="${this.id}" title="${location.title}" style="cursor: ${cursor}">${svg}<div class="PinMarker__Title">${location.title}</div></div>${this.clickInfo(this.id, this.popupData) ? this.clickInfo(this.id, this.popupData) : ''}`,
      // html:'<div id="'+this.id+'"><img src="libs/leaflet/images/marker-icon.png" class="leaflet-marker-icon leaflet-zoom-animated leaflet-clickable"></div>',
      iconSize: L.point(25, 41),
      iconAnchor: L.point(12, 40, true),
    });
    this.initLMarker(icon);
  }

  public applyColorInternal() {
    this.$container.find('path').attr('fill', this.color);
  }
}

export class LeafletDefaultPinMarker extends PinMarker {
  constructor(location: ILocation, dataset: IDatasetModel) {
    super(location, dataset);
    this.id = 'map-pin-' + location.id;
    this.lmarker = new L.Marker(this.latlng, {title: this.title, riseOnHover: true}) as IMarkerEx;
    this.lmarker.id = null;
    this.lmarker.metric = null;
  }
}

export class CameraPinMarker extends BaseMarker {

  constructor(location: ILocation, dataset: IDatasetModel) {
    super(location, dataset);

    this.popupData = dataset.locations.find(obj => obj.id === location.id).config;
    this.id = 'map-pin-' + location.id;
    const icon: L.DivIcon = L.divIcon({
      html: '<div id="' + this.id + '" class="map-camera-marker"></div>' + `${this.clickInfo(this.id, this.popupData) ? this.clickInfo(this.id, this.popupData) : ''}`,
      iconSize: L.point(24, 24),
      iconAnchor: L.point(12, 12, true),
    });

    this.initLMarker(icon);
  }

  public applyColorInternal() {
    this.$container.css('background-color', this.color);
  }
}

/**/

export class ClusterMarker extends BaseMarker {

  public constructor(element: ILocation, dataset: IDatasetModel) {
    super(element, dataset);

    this.popupData = dataset.locations.find(obj => obj.id === element.id).config;
    this.id = 'map-pin-' + element.id;
    const svg: string =
      `<svg width="41" height="41" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle stroke="#cccccc" fill="#ff0000" stroke-width="3" clip-rule="evenodd" fill-rule="evenodd" cx="20.5" cy="20.5" r="19"/>
        </g>
       </svg>`;

    const onClickAction: string = 'openObjectCard';   // dataset.config.getStringValue('map.pin.onClick'); TODO: get real config value
    let cursor: string = 'pointer';
    if (onClickAction === 'openObjectCard' && element.card == null) {     // issue #2617: no pointer cursor when pin has no location-card
      cursor = 'default';
    }

    const icon: L.DivIcon = L.divIcon({
      html: `<div class="ClusterMarker" id="${this.id}" title="${element.title}" style="cursor: ${cursor}">${svg}<div class="ClusterMarker__Title">${element.cnt}</div></div>${this.clickInfo(this.id, this.popupData) ? this.clickInfo(this.id, this.popupData) : ''}`,
      iconSize: L.point(41, 41),
      // iconAnchor: L.point(12, 40, true),
    });
    this.initClusterMarker(icon);
  }

  public applyColorInternal() {
    this.$container.find('circle').attr('fill', this.color);
  }
}

export class LeafletDefaultClusterMarker extends ClusterMarker {
  constructor(location: ILocation, dataset: IDatasetModel) {
    super(location, dataset);

    this.popupData = dataset.locations.find(obj => obj.id === location.id).config;
    this.id = 'map-pin-' + location.id;
    this.lmarker = new L.Marker(this.latlng, {title: this.title, riseOnHover: true}) as IMarkerEx;
    this.lmarker.id = null;
    this.lmarker.metric = null;
  }
}

class ClusterElement {
  public id: number | string;
  public title: string;
  public nodata: boolean = true;
  public location: ILocation;

  public latlng: L.LatLng;
  public titleMarker: TitleMarker;
  public clusterMarker: ClusterMarker; // TODO!!!

  public factory: IMapFactory;

  public visible: boolean = false;
  public visibleChart: boolean = false;

  private _dataset: IDatasetModel;
  public zoom?: number;

  public constructor(location: ILocation, dataset: IDatasetModel, factory: IMapFactory, private displayPinsOnly: boolean = false) {
    this.location = location;
    this._dataset = dataset;

    this.id = location.id;
    this.title = location.title;

    this.factory = factory;
    if (location.zoom) this.zoom = location.zoom;
    this.latlng = new L.LatLng(this.location.latitude, this.location.longitude);
    this.initMarkers();
  }

  public initMarkers() {
    this.clusterMarker = this.factory.createClusterMarker(this.location);
    // if (this.displayPinsOnly) return;
  }

  public setData(parameters: IMetric[], vector: IValue[], domain: number[]) {
    // nodata if all values in the vector are NULL
    this.nodata = isNullVector(vector);
    if (this.nodata) {
      if (this.clusterMarker) this.clusterMarker.redraw(false);
      if (this.titleMarker) this.titleMarker.redraw(true);
    } else {
      if (this.clusterMarker) this.clusterMarker.setData(parameters, vector, domain);
      this.redraw();
    }
  }

  public setClusterColor(color: string): void {
    this.clusterMarker.setColor(color);
  }

  public hide() {
    if (!this.visible) return;
    this.visible = false;
    this.redraw();
  }

  public show() {
    if (this.visible) return;
    this.visible = true;
    this.redraw();
  }

  public onAdd(titleGroup: L.FeatureGroup<L.Layer>,
               clusterGroup: L.FeatureGroup<L.Layer>,
               chartGroup: L.FeatureGroup<L.Layer>) {
    if (this.clusterMarker) clusterGroup.addLayer(this.clusterMarker.lmarker);
    if (this.titleMarker) titleGroup.addLayer(this.titleMarker.lmarker);
   // if (this.chartMarker) chartGroup.addLayer(this.chartMarker.lmarker);
   // if (this.chartMarker) this.chartMarker.init();
    this.redraw();
  }

  public onRemove() {
    //
  }

  private redraw() {
    if (this.clusterMarker) this.clusterMarker.redraw(this.visible && !this.visibleChart);
    if (this.titleMarker) this.titleMarker.redraw(this.visible && this.visibleChart);
    // if (this.chartMarker) this.chartMarker.redraw(this.visible && this.visibleChart);
  }

}

/**/

class MapPoint {
  public id: number | string;
  public title: string;
  public nodata: boolean = true;
  public location: ILocation;
  public tree_level: number;
  // closest location at the same level
  public closest: MapPoint = null;
  public latlng: L.LatLng;
  public titleMarker: TitleMarker;
  public pinMarker: PinMarker;
  public chartMarker: ChartMarker;
  public visible: boolean = false;
  public visibleChart: boolean = false;
  public factory: IMapFactory;
  private _dataset: IDatasetModel;
  public zoom?: number;

  public constructor(location: ILocation, dataset: IDatasetModel, factory: IMapFactory, private displayPinsOnly: boolean = false) {
    this.location = location;
    this._dataset = dataset;

    this.id = location.id;
    this.title = location.title;

    this.factory = factory;
    this.tree_level = location.tree_level;
    if (location.zoom) this.zoom = location.zoom;
    this.latlng = new L.LatLng(this.location.latitude, this.location.longitude);
    this.initMarkers();
  }

  public initMarkers() {
    this.pinMarker = this.factory.createPinMarker(this.location);
    if (this.displayPinsOnly) return;
    this.titleMarker = new TitleMarker(this.location, this._dataset);
    this.chartMarker = new ChartMarker(this.location, this._dataset, AppConfig.getModel().map.mapCircleRadius * 2);
  }

  public setData(parameters: IMetric[], vector: IValue[], domain: number[]) {
    // nodata if all values in the vector are NULL
    this.nodata = isNullVector(vector);
    if (this.nodata) {
      if (this.pinMarker) this.pinMarker.redraw(false);
      if (this.titleMarker) this.titleMarker.redraw(true);
      if (this.chartMarker) this.chartMarker.redraw(false);
    } else {
      if (this.chartMarker) this.chartMarker.setData(parameters, vector, domain);
      this.redraw();
    }
  }

  //
  // method used to save metric which is attached to pin; will be used to and analyze cluster color
  //
  public setPinMetric(m: IMetric): void {
    this.pinMarker.setMetric(m);
  }

  public setPinColor(color: string): void {
    this.pinMarker.setColor(color);
  }

  public setChartType(value: string) {
    if (this.chartMarker) this.chartMarker.setType(value);
  }

  public showAsPin() {
    this.visibleChart = false;
    this.redraw();
  }

  public showAsChart() {
    this.visibleChart = true;
    this.redraw();
  }

  public hide() {
    if (!this.visible) return;
    this.visible = false;
    this.redraw();
  }

  public show() {
    if (this.visible) return;
    this.visible = true;
    this.redraw();
  }

  public onAdd(pinGroup: L.FeatureGroup<L.Layer>,
               titleGroup: L.FeatureGroup<L.Layer>,
               chartGroup: L.FeatureGroup<L.Layer>) {
    if (this.pinMarker) pinGroup.addLayer(this.pinMarker.lmarker);
    if (this.titleMarker) titleGroup.addLayer(this.titleMarker.lmarker);
    if (this.chartMarker) chartGroup.addLayer(this.chartMarker.lmarker);
    if (this.chartMarker) this.chartMarker.init();
    this.redraw();
  }

  public onRemove() {
    //
  }

  private redraw() {
    if (this.pinMarker) this.pinMarker.redraw(this.visible && !this.visibleChart);
    if (this.titleMarker) this.titleMarker.redraw(this.visible && this.visibleChart);
    if (this.chartMarker) this.chartMarker.redraw(this.visible && this.visibleChart);
  }
}

export interface IMapFactory {
  createPolylineGroup(): L.FeatureGroup<L.Layer>;

  createPinGroup(): L.FeatureGroup<L.Layer>;

  createTitleGroup(): L.FeatureGroup<L.Layer>;

  createChartGroup(): L.FeatureGroup<L.Layer>;

  createMapPoint(location: ILocation): MapPoint;

  createPinMarker(location: ILocation): PinMarker;

  createRenderer(map: L.Map): IMapPointsRenderer;

  createClusterMarker(location: ILocation): ClusterMarker;
}


interface IMarkerEx extends L.Marker {
  id: string;                       // custom property
  metric: IMetric;                  // custom property
  location: ILocation;
  setval: (v: IValue) => void;
  getval: () => IValue;
}


export class DefaultMapFactory implements IMapFactory {
  private _dataset: IDatasetModel;

  constructor(dataset: IDatasetModel) {
    this._dataset = dataset;
  }

  public createPolylineGroup(): L.FeatureGroup<L.Layer> {
    // return L.multiPolyline([], {color: '#5FB404', weight: 2} as any);
    return new L.Polyline([], {color: '#5FB404', weight: 2} as any) as any;
  }

  private iconFunction1(): (cluster: any) => L.DivIcon {
    return (cluster: any): L.DivIcon => {
      const markers: IMarkerEx[] = cluster.getAllChildMarkers();
      const mCount: {[mid: string]: number} = {};
      let total: number = 0;
      let color: string = null;
      const childCount: number = cluster.getChildCount();

      markers.forEach((marker: IMarkerEx) => {
        const m: IMetric = marker.metric;
        if (!m) {
          // THIS MIGHT BE A ERROR: see issue #1765
          // probably the situation occurs when data-request is not finished while other is started
          return;
        }
        mCount[m.id] = (mCount[m.id] || 0) + 1;
        total++;
      });

      // total ?== childCount
      type ICountPair = [IMetric, number];
      const pairs: ICountPair[] = toPairs(mCount).map<ICountPair>(([mid, count]) => [getEntity(markers, mid).metric, count]);
      pairs.sort(([metric1, count1], [metric2, count2]) => metric1.srt - metric2.srt);

      //
      // STAGE 1. Get cluster color based on thresholds
      //
      for (let pair of pairs) {
        const m: IMetric = pair[0];
        const count: number = pair[1];
        const relativeCount: number = count / total;
        let threshold: [number, number]
          | { min?: number, max?: number }
          | number = (m.config && ('cluster' in m.config)) ? m.config.cluster.threshold : null;

        if (threshold == null) {
          // skip this check and if others also fail, we will try to select color by voting
          continue;
        }

        if (isObject(threshold) && !Array.isArray(threshold)) {
          const thObject: { min?: number, max?: number } = threshold as  { min?: number, max?: number };
          threshold = [thObject.min, thObject.max];
        }

        const thArray: [number, number] = threshold as [number, number];

        console.assert((Array.isArray(thArray) && thArray.length === 2));

        if (thArray && thArray[0] == null)
          thArray[0] = -Infinity;

        if (thArray && thArray[1] == null)
          thArray[1] = +Infinity;

        if (thArray[0] <= relativeCount && relativeCount <= thArray[1]) {
          color = makeColor(m.color);
          // console.log('Evaluated cluster color ', color, ' by threshold');
          break;
        }
      }

      if (color == null) {
        //
        // STAGE 2. Get cluster color based on stoplight zones
        //
        for (let pair of pairs) {
          let m: IMetric = pair[0];
          let count: number = pair[1];
          let relativeCount: number = count / total;

          if (!m.config || !m.config.cluster) {
            continue;
          }
          let clusterCfg: any = m.config.cluster;
          let stoplights: Stoplights = Stoplights.create(clusterCfg.stoplight);
          let light: IStoplight = stoplights.getStoplight(relativeCount);
          if (light) {
            color = light.colorPair.color;
            // console.log('Evaluated cluster color ', color, ' by stoplights');
            break;
          }
        }
      }

      if (color == null) {
        //
        // STAGE 3. Vote?
        //
        let best: number = -1;
        for (let pair of pairs) {
          let m: IMetric = pair[0];
          let count: number = pair[1];
          if (count > best) {
            best = count;
            color = m.color;
          }
        }
      }

      const hint: string = pairs.map((pair: [IMetric, number]) => pair[0].title + ': ' + String(pair[1])).join('\n');

      // bagen
      let lTitle: string = '';
      if (skin.mapDisplayPinTitle) {
        const locations: ILocation[] = markers.map(m => m.location).filter(l => l != null);
        locations.sort((l1, l2) => l1.tree_level - l2.tree_level);
        lTitle = locations.length ? locations[0].title : '';
      }
      const bgColor = Color(color).fade(0.4).toString();

      const html =
        `<div class="IconFunction1" title="${hint}" style="background: ${bgColor};">
          <div class="IconFunction1__Shadow" style="background: ${bgColor};"></div>
          <span class="IconFunction1__Value">${childCount}</span>
          <div class="IconFunction1__Title">${lTitle}</divclas>
        </div>`;

      return new L.DivIcon({
        html,
        // className: 'marker-cluster' + c,
        className: 'marker-cluster marker-cluster-custom',
        iconSize: new L.Point(40, 40),
        // bgColor: color,
      } as any);
    };
  }

  private iconFunction2(): (cluster: any) => L.DivIcon {
    return (cluster) => new L.DivIcon({
      html: '<div><span>' + cluster.getChildCount() + '</span></div>',
      className: 'marker-cluster marker-cluster-small',
      iconSize: new L.Point(40, 40),
    });
  }

  public createPinGroup(): L.FeatureGroup<L.Layer> {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    if (configHelper.getStringValue('map.clusteringMode') === 'native') {
      const opts: any = {
        // polygonOptions: {weight: 2, color: '#00ff33'},
        spiderfyOnMaxZoom: true,
        spiderfyDistanceMultiplier: 1,
      };
      const iconFunctionName = configHelper.getValue('map.iconFunctionName', 'iconFunction1');
      opts.iconCreateFunction = this[iconFunctionName]();
      if (configHelper.getStringValue('map.pin.onClick') === 'showChart') {
        opts.spiderfyDistanceMultiplier = 5;
      }

      // return L.markerClusterGroup(opts);
      return new MarkerClusterGroup(opts);
    } else {
      return L.featureGroup();
    }
  }

  public createClusterGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createTitleGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createChartGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createMapPoint(location: ILocation): MapPoint {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    return new MapPoint(location, this._dataset, this, configHelper.getBoolValue('map.displayPinsOnly'));
  }

  public createMapPointKoob(data: any): MapPoint {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    return new MapPoint(data, this._dataset, this, configHelper.getBoolValue('map.displayPinsOnly'));
  }

  public createMapCluster(data: any): ClusterElement {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    return new ClusterElement(data, this._dataset, this, configHelper.getBoolValue('map.displayPinsOnly'));
  }

  public createPinMarker(location: ILocation): PinMarker {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const className = configHelper.getValue('map.pinMarkerClass', 'PinMarker');
    switch (className) {
      case 'DefaultPinMarker':
        return new LeafletDefaultPinMarker(location, this._dataset);
      case 'PinMarker':
        return new PinMarker(location, this._dataset);
      case 'CameraPinMarker':
        return new CameraPinMarker(location, this._dataset);
      default:
        throw new Error('Undefined classname to create pin');
    }
  }

  public createClusterMarker(location: ILocation): any { // TODO types
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const className = configHelper.getValue('map.clusterMarkerClass', 'ClusterMarker'); // TODO CLASS
    switch (className) {
      case 'ClusterMarker':
        return new ClusterMarker(location, this._dataset);
      case 'DefaultClusterMarker':
        return new LeafletDefaultClusterMarker(location, this._dataset);
      default:
        throw new Error('Undefined classname to create pin');
    }
  }

  public createRenderer(map: L.Map, subspace?: ISubspace): IMapPointsRenderer {

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const displayLevel: number = configHelper.getIntValue('map.displayLocationsOnlyAtLevels', -1);
    const clusteringMode: string = configHelper.getStringValue('map.clusteringMode');
    const displayPinsOnly: boolean = configHelper.getBoolValue('map.displayPinsOnly');

    if (clusteringMode === 'native') {
      return new LeafletClusteringRenderer(this._dataset, map, displayLevel);
    } else if (displayPinsOnly) {

      if (subspace && subspace.koob) {
        return new DisplayChartsRendererKoob(this._dataset, map, displayLevel);
      }
      return new DisplayPinsByLocationsRenderer(this._dataset, map, displayLevel);
    } else if (!configHelper.getBoolValue('map.automaticClusteringDisabled')) {
      // needs old buggy clustering
      if (subspace && subspace.koob) {
        return new DisplayChartsRendererKoob(this._dataset, map, displayLevel);
      }
      return new BuggyClusteringRenderer(this._dataset, map);

      return new DisplayChartsByLocationsRenderer(this._dataset, map);
    }
    else {
      if (subspace && subspace.koob) {
        return new DisplayChartsRendererKoob(this._dataset, map, displayLevel);
      }
      return new BuggyClusteringRenderer(this._dataset, map);

      return new DisplayChartsByLocationsRenderer(this._dataset, map);
    }
  }
}


export class DataProviderCache implements data_engine.IDataProvider {

  private _request: Promise<IValue[][]> = null;
  private _decoratee: data_engine.IDataProvider = null;

  public constructor(private decoratee: data_engine.IDataProvider) {
    this._decoratee = decoratee;
  }

  public invalidate(): void {
    this._request = null;
  }

  // IRawDataProvider
  public getRawData(mlpSubspace: IMLPSubspace, closest?: boolean): Promise<tables.IDataEntry[]> {
    throw new Error('Not implemented here');
  }

  public getRawNorms(mlpSubspace: IMLPSubspace): Promise<tables.INormDataEntry[]> {
    throw new Error('Not implemented here');
  }

  public getAggregate(mlpSubspace: IMLPSubspace): Promise<any> {
    throw new Error('Not implemented here');
  }

  public load(request: data_engine.IRawRequest, mlpSubspace: IMLPSubspace, closest?: boolean): Promise<data_engine.IRawResponse> {
    throw new Error('Not implemented here');
  }

  public rawSubscribe(mlpSubspace: IMLPSubspace, callback: data_engine.IMLPSubscribeCallback): IDisposable {
    throw new Error('Not implemented here');
  }

  // ICubeProvider
  public getCube(subspace: ISubspace, closest?: boolean): Promise<IValue[][][]> {
    throw new Error('Not implemented here');
  }

  // IMatrixProvider
  public getMatrixYX(subspace: ISubspace, closest: boolean = false): Promise<IValue[][]> {   // period, locations, parameters
    // get pin card - skip cached
    if (subspace.ys.length == 1) {
      return this.decoratee.getMatrixYX(subspace);
    }
    // get all locations = preload map pins
    if (this._request == null) {
      this._request = this.decoratee.getMatrixYX(subspace);
    }
    return this._request;
  }

  // IVectorProvider
  public getVectorX(subspace: ISubspace, closest?: boolean): Promise<number[]> {
    throw new Error('Not implemented');
  }

  public getVectorY(subspace: ISubspace, closest?: boolean): Promise<number[]> {
    throw new Error('Not implemented');
  }

  // IValueProvider
  public getValue(subspace: ISubspace, closest?: boolean): Promise<number> {
    throw new Error('Not implemented');
  }

  // INormsProvider
  public getNorms(subspace: ISubspace): Promise<INormsResponse> {
    throw new Error('Not implemented');
  }


  // IDataProvider
  public subscribe(subspace: ISubspace, callback: data_engine.ISubscribeCallback): IDisposable {
    throw new Error('Not implemented');
  }

  public getRawColors(subspace: ISubspace): Promise<any> {
    throw new Error('Not implemented');
  }
}

export class KoobClusterLayer extends L.Layer<any, any> {
  protected _map: L.Map = null;
  private _mapPoints: ClusterElement[] = [];

  private _polylineGroup: L.FeatureGroup<L.Layer> = null;
  private _clusterGroup: L.FeatureGroup<L.Layer> = null;
  private _pinGroup: L.FeatureGroup<L.Layer> = null;
  private _titleGroup: L.FeatureGroup<L.Layer> = null;
  private _chartGroup: L.FeatureGroup<L.Layer> = null;

  private _popup: L.Popup = L.popup({minWidth: 400, maxWidth: 600});

  private _mergeLevel0And1: boolean;
  // private _displayLocationsOnlyAtLevels: number;
  private _displayPinsOnly: boolean;
  private _factory: DefaultMapFactory; // IMapFactory;

  private _dataset: IDatasetModel;
  private _subspace: ISubspace;

  private _dataProvider: data_engine.IDataProvider;
  private _renderer: IMapPointsRenderer = null;

  private currentClusteredIds: (number | string)[] = [];

  private _cfg = null;
  private _setCallback = () => {
    let latLng = this._map.getBounds();

    let southEast = latLng.getSouthEast();
    let northWest = latLng.getNorthWest();

    let xy0 = [northWest.lng, northWest.lat];
    let xy1 = [northWest.lng, southEast.lat];
    let xy2 = [southEast.lng, southEast.lat];
    let xy3 = [southEast.lng, northWest.lat];

    this._getDataFromSubspacePtr([xy0, xy1, xy2, xy3], this._map.getZoom());
  }


  public constructor(data: any) {
    super(data);

    this._dataset = data.dataset;
    this._subspace = data.subspace;

    this._dataProvider = this._dataset.getDataProvider();

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    this._factory = new DefaultMapFactory(this._dataset);
    this._displayPinsOnly = configHelper.getBoolValue('map.displayPinsOnly');

    this._createLayers();

    this._cfg = data.cfg;
    this._map = data.map;


    if (this._subspace && data.map) {
      this._setCallback();

      data.map.on('zoomend', () => {
        this._setCallback();
      });

      data.map.on('moveend', () => {
        this._setCallback();
      });
    }

  }

  private async _getDataFromSubspacePtr(coords_array, zoom) {
    this.onRemove();
    this._createLayers();

    const h3Dimension = this._getZoomType();

    let rawDataSource: any = {...this._cfg.getRaw().dataSource};
    // Мы заменим в конфиге ключ на новый, но тут надо быть осторожным, вдруг там другие значения будут
    rawDataSource.dimensions = [h3Dimension];
    rawDataSource.xAxis = h3Dimension;

    let dataSourceFilters = this._cfg.getRaw().dataSource.filters || {};
    let filters: any = Array.isArray(dataSourceFilters) ? Object.fromEntries(dataSourceFilters.map((d) => [d, true])) : {...dataSourceFilters};

    const koobFiltersModel = KoobFiltersService.getInstance().getModel();
    Object.keys(filters).forEach(key => {
      if (filters[key] === true) filters[key] = koobFiltersModel.filters[key];
      if (filters[key] === undefined) delete filters[key];
    });

    let emptyFilter = filters[''];
    let requestFilter =  ['=', ['pointInPolygon', ['tuple', 'lat', 'lng'],
      [
        '[',
        ['tuple', coords_array[0][1], coords_array[0][0]],
        ['tuple', coords_array[1][1], coords_array[1][0]],
        ['tuple', coords_array[2][1], coords_array[2][0]],
        ['tuple', coords_array[3][1], coords_array[3][0]],
      ],
    ], 1];

    filters[''] = emptyFilter ? ['and', emptyFilter, requestFilter] : requestFilter;

    rawDataSource.filters = filters;

    const subpacePtr = new SubspacePtr(rawDataSource);
    const subspaceService = new KoobSubspaceAsyncService(this._cfg.getDataset().schemaName, subpacePtr);
    await subspaceService.whenReady();
    const { subspace } = subspaceService.getModel();

    const data = await this._dataProvider.getMatrixYX(subspace);
    if (data && data[0]) {
      let pointsData = this._tranformMatrixToPoints(data, subspace);
      this.setKoobDataToPoints(pointsData);
    }
  }

  private _tranformMatrixToPoints = (matrix: any, subspace: any) => {
    const ys = subspace.ys;
    const xs = subspace.xs;

    let pointsData: any = [];
    if (matrix.length && matrix[0].length) {
      if (matrix[0].length && matrix[0][0]) {
        for (let z = 0; z < matrix[0].length; ++z) {
          let el = {};
          for (let i = 0; i < matrix.length; ++i) {
            if (matrix[i] && matrix[i][z]) {
              let value = matrix[i][z];
              let key = (ys[i]) ? ys[i].id : null;
              el[key] = value;
            }
          }

          if (Object.keys(el).length) el['h3'] = (xs[z]) ? xs[z].id : null;
          pointsData.push(el);
        }
      }
    }

    return pointsData;
  }

  private async getDataFromSubspace(subspace): Promise<any> {
    if (subspace) {
      const matrix = await this._dataProvider.getMatrixYX(subspace);
      const ys = subspace.ys;


      let pointsData: any = [];
      if (matrix.length && matrix[0].length) {
        for (let z = 0; z < matrix[0].length; ++z) {
          let el = {};
          for (let i = 0; i < matrix.length; ++i) {
            if (matrix[i] && matrix[i][z]) {
              let value = matrix[i][z];
              let key = ys[i].id;
              el[key] = value;
            }
          }
          pointsData.push(el);
        }
      }

      this.setKoobDataToPoints(pointsData);
    }
  }

  public setData(locations: ILocation[], metrics: IMetric[], matrix: IValue[][]) {
    const max: number = matrixMax(matrix);
    const domain = [0, max];
    locations.forEach((location, i) => {
      $eid(this._mapPoints, location.id).setData(metrics, matrix[i], domain);
    });
  }

  public setKoobDataToPoints(data: any) {
    this._mapPoints = data.map((d, ind) => {
      let item = {};
      item = {...d};

      item['longitude'] = d.ln;
      item['latitude'] = d.la;
      item['title'] = 'title' + ind + ':' + item['cnt'];
      item['getAltTitle'] = () => item['title'] ;
      item['id'] = 'id' + ind; // TODO убрать лишнее добавить нужное
      item['cnt'] = item['cnt'];

      const cl: ClusterElement = this._factory.createMapCluster(item);
      return cl;
    });

    this.onAdd(this._map);
  }

  public setChartType(value: string): void {
    this._mapPoints.forEach((e) => e.setChartType(value));
  }

  private _createLayers = () => {
    this._polylineGroup = this._factory.createPolylineGroup();
    this._pinGroup = this._factory.createPinGroup();
    this._titleGroup = this._factory.createTitleGroup();
    this._chartGroup = this._factory.createChartGroup();
    this._clusterGroup = this._factory.createClusterGroup();
  }

  public onAdd(map: L.Map): this {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const cfgMapPinColor: string = configHelper.getStringValue('map.pinColor', '#5bff42');        // color or 'FirstNonZeroParameter'

    const cfgMapClusteringMode: string = configHelper.getValue('map.clusteringMode');             // native

    this._map = map;

    map.addLayer(this._polylineGroup);
    // map.addLayer(this._pinGroup);
    map.addLayer(this._titleGroup);
    map.addLayer(this._chartGroup);
    map.addLayer(this._clusterGroup);

    this._chartGroup.on('click', this._handleChartClick);
    this._titleGroup.on('click', this._handleChartClick);
    this._pinGroup.on('click', this._handlePinClick);
    this._clusterGroup.on('click', this._handleClusterClick);

    if (this._subspace.koob) {
      this._mapPoints.forEach((mp: any) => {
        mp.onAdd(this._titleGroup, this._clusterGroup, this._chartGroup);
        mp.setClusterColor(cfgMapPinColor);
      });
    } else {
      this._mapPoints.forEach((mp: any) => {
        mp.onAdd(this._pinGroup, this._titleGroup, this._chartGroup, this._clusterGroup);
        mp.setClusterColor(cfgMapPinColor);
      });
    }

    this.redraw();


    return this;
  }

  public onRemove(): this {
    this._mapPoints.forEach((e) => e.onRemove());
    this._mapPoints = [];
    if (this._titleGroup) {
      this._map.removeLayer(this._titleGroup);
      this._titleGroup = null;
    }
    if (this._chartGroup) {
      this._map.removeLayer(this._chartGroup);
      this._chartGroup = null;
    }
    if (this._pinGroup) {
      this._map.removeLayer(this._pinGroup);
      this._pinGroup = null;
    }

    if (this._clusterGroup) {
      this._map.removeLayer(this._clusterGroup);
      this._clusterGroup = null;
    }
    return this;
  }

  public redraw(doMoveMap: boolean = true): void {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    if (!this._renderer) {
      this._renderer = this._factory.createRenderer(this._map, this._subspace);
    }


    const result: any = this._renderer.evaluate(this._mapPoints, this._subspace);

    if (doMoveMap && this._renderer.evaluateMapPosition) {

    }

    this._mapPoints.forEach((mp: ClusterElement) => {
      if (this._subspace.koob) {
        mp.show();
      }
    });
  }

  public setKoobAxes = (subspace: ISubspace) => {
    if (this._subspace !== subspace) {
      this._subspace = subspace;
      this._setCallback();
    }

    if ((this._dataProvider as DataProviderCache).invalidate) {
      (this._dataProvider as DataProviderCache).invalidate();
    }
    this.currentClusteredIds = [];

    this.redraw(!!subspace);
  }

  private async loadPinsData(mapPoints: MapPoint[]): Promise<IValue[][]> {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const cfgMapPinColor: string = configHelper.getStringValue('map.pinColor');

    const ms: IMetric[] = this._subspace.ms;
    const ls: ILction[] = mapPoints.map(mp => mp.location);
    const ps: IPeriod[] = [oneEntity(this._subspace.ps)];
    const subspace: ISubspace = bi.createSimpleSubspace(ms, ls, ps);

    const dataMatrix: IValue[][] = await this._dataProvider.getMatrixYX(subspace);

    let polylines: L.LatLng[][] = [];

    for (let i = 0; i < dataMatrix.length; ++i) {
      const mp: MapPoint = mapPoints[i];

      if (cfgMapPinColor == 'FirstNonZeroParameter') {
        const parametersOfLocation: IValue[] = dataMatrix[i];

        const metricIndex: number = parametersOfLocation.indexOf(1);
        let metric: IMetric = null;

        if (metricIndex == -1) {
          // console.warn('No found parameter with value=1');
          // EP: will try to display as gray
          mp.setPinMetric(null);
          mp.setPinColor('transparent');
          continue;
        } else {
          mp.show();
        }
        metric = ms[metricIndex];

        if (metric !== undefined) {
          const val: number = (ms.indexOf(metric) === -1) ? 0 : 1;
          const color: string = val ? metric.color : 'none';
          mp.setPinColor(color);
          mp.setPinMetric(metric);

          // draw lines
          if (configHelper.getBoolValue('map.pin.lineToParent') && color == '#00ff00') {
            const ll: L.LatLng[] = [mp.latlng, $eid(this._mapPoints, mp.location.parent.id).latlng];
            polylines.push(ll);
          }
        } else {
          mp.setPinColor('#0066ff');
        }
      } else {
        mp.setPinColor('#0066ff');
      }
    }

    this._polylineGroup['setLatLngs'](polylines);

    return dataMatrix;
  }

  private _handleChartClick = (e) => {

  }


  private _getZoomType() {
    let type: string = '';
    if (this._map) {
      let zoom = this._map.getZoom();

      const H3_LEVELS = [0, 0, 1, 1, 1, 1, 2, 3, 4, 5, 6,  7, 8, 9, 11, 12, 13, 14, 15, 15, 15];
      const h3Level = H3_LEVELS[Number(zoom) - 1];
      const h3Dimension = 'h3_' + h3Level;

      type = h3Dimension;
    }

    return type;
  }

  private _handleClusterClick = (e) => {

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const elId: string = $(e.layer._icon).children().get(0).id.replace(/\D+/, '');

    const ce: ClusterElement = $eid(this._mapPoints, 'id' + elId);
    let h3_id = ce.location.h3 || null;

    import('../modal-container').then((modalContainerModule: any) => {
      const modalContainer = modalContainerModule.modalContainer;

      const h3Dimension = this._getZoomType();

      modalContainer.pushVizelConfig({
        view_class: 'koob-table-simple',
        dataSource: {
          koob: this._subspace.koob,
          dimensions: ['floor'],
          measures: ['price_ddu', 'room'],
          filters: {
            [h3Dimension]: ['=', h3_id],
          },
        },
      });
    });
  }

  private _handlePinClick = (e) => {

  }

  private moveMapToPoint(targetPoint: any) {
    this._map.setView(targetPoint.latlng);

    if (BuggyClusteringRenderer.algorytm == null) return;

    let latlng: L.LatLng = targetPoint.latlng;
    let z: number = this._map.getZoom();
    while (true) {
      const ci: ClusterItem = BuggyClusteringRenderer.algorytm.getCluster(latlng, z);
      if (-1 != ci.mapPoints.indexOf(targetPoint)) {
        this._map.setView(targetPoint.latlng, z);
        break;
      }
      z++;
      if (z > this._map.getMaxZoom()) {
        break;
      }
    }
  }
}

export class ClusterLayer extends L.Layer {
  protected _map: L.Map = null;
  private _mapPoints: MapPoint[] = [];
  private _polylineGroup: L.FeatureGroup<L.Layer> = null;
  private _pinGroup: L.FeatureGroup<L.Layer> = null;
  private _titleGroup: L.FeatureGroup<L.Layer> = null;
  private _chartGroup: L.FeatureGroup<L.Layer> = null;
  private _mergeLevel0And1: boolean;
  private _displayLocationsOnlyAtLevels: number;
  private _displayPinsOnly: boolean;
  private _popup: L.Popup = L.popup({minWidth: 400, maxWidth: 600});
  private _factory: DefaultMapFactory; // IMapFactory;

  private _dataset: IDatasetModel;
  private _subspace: ISubspace;

  private _dataProvider: data_engine.IDataProvider;
  private _renderer: IMapPointsRenderer = null;

  public constructor(dataset: IDatasetModel, subspace: ISubspace) {
    super();
    this._dataset = dataset;
    this._subspace = subspace;

    this._dataProvider = this._dataset.getDataProvider();

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    this._factory = new DefaultMapFactory(this._dataset);
    this._mergeLevel0And1 = configHelper.getBoolValue('branches.mergeLevel0And1');
    this._displayPinsOnly = configHelper.getBoolValue('map.displayPinsOnly');
    this._displayLocationsOnlyAtLevels = configHelper.getIntValue('map.displayLocationsOnlyAtLevels', -1);

    this._polylineGroup = this._factory.createPolylineGroup();
    this._pinGroup = this._factory.createPinGroup();
    this._titleGroup = this._factory.createTitleGroup();
    this._chartGroup = this._factory.createChartGroup();

    const allLocations: ILocation[] = this._dataset.locations;
    this._mapPoints = allLocations.map((location) => {
      const cl: MapPoint = this._factory.createMapPoint(location);
      return cl;
    });
  }

  public setData(locations: ILocation[], metrics: IMetric[], matrix: IValue[][]) {
    const max: number = matrixMax(matrix);
    const domain = [0, max];
    locations.forEach((location, i) => {
      $eid(this._mapPoints, location.id).setData(metrics, matrix[i], domain);
    });
  }

  public setChartType(value: string): void {
    this._mapPoints.forEach((e) => e.setChartType(value));
  }

  public onAdd(map: L.Map): this {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const cfgMapPinColor: string = configHelper.getStringValue('map.pinColor', '#0066ff');        // color or 'FirstNonZeroParameter'
    const cfgMapClusteringMode: string = configHelper.getValue('map.clusteringMode');             // native

    this._map = map;
    map.addLayer(this._polylineGroup);
    map.addLayer(this._pinGroup);
    map.addLayer(this._titleGroup);
    map.addLayer(this._chartGroup);

    this._chartGroup.on('click', this._handleChartClick);
    this._titleGroup.on('click', this._handleChartClick);
    this._pinGroup.on('click', this._handlePinClick);

    if (cfgMapPinColor === 'FirstNonZeroParameter') {
      if (cfgMapClusteringMode === 'native') {
        this._dataProvider = new DataProviderCache(this._dataProvider);
        this.redraw();
      } else {
        this.loadPinsData(this._mapPoints.filter((mp: MapPoint) => mp.location.card != null)).then(() => {
          this._mapPoints.forEach((mp) => {
            mp.onAdd(this._pinGroup, this._titleGroup, this._chartGroup);
          });
          this.redraw();
        });
      }
    } else {
      this._mapPoints.forEach((mp) => {
        mp.onAdd(this._pinGroup, this._titleGroup, this._chartGroup);
        mp.setPinColor(cfgMapPinColor);
      });
      this.redraw();
    }
    return this;
  }

  public onRemove(): this {
    this._mapPoints.forEach((e) => e.onRemove());
    this._mapPoints = [];
    if (this._titleGroup) {
      this._map.removeLayer(this._titleGroup);
      this._titleGroup = null;
    }
    if (this._chartGroup) {
      this._map.removeLayer(this._chartGroup);
      this._chartGroup = null;
    }
    if (this._pinGroup) {
      this._map.removeLayer(this._pinGroup);
      this._pinGroup = null;
    }
    return this;
  }

  private currentClusteredIds: number[] = [];

  private redrawClusteredPins(mapPoints: MapPoint[]) {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    if (!mapPoints.length) {
      (this._pinGroup as any).clearLayers();
      this.currentClusteredIds = [];
      return;
    }

    const sortedIds: number[] = mapPoints.map((mp: MapPoint) => +mp.location.id).sort();
    let needsRedraw: boolean = (sortedIds.length != this.currentClusteredIds.length);
    if (!needsRedraw) {
      // no redraw if all elements in sorted arrays are equal
      for (let i = 0; i < sortedIds.length; ++i) {
        if (sortedIds[i] != this.currentClusteredIds[i]) {
          needsRedraw = true;
          break;
        }
      }
    }

    if (!needsRedraw) return;

    this.currentClusteredIds = sortedIds;

    (this._pinGroup as any).clearLayers();

    this.loadPinsData(mapPoints).then(() => {
      let markers: L.Marker[];
      if ('FirstNonZeroParameter' == configHelper.getValue('map.pinColor')) {
        // filter all points and leave only those that have valid linked metric
        markers = mapPoints.map((mp: MapPoint) => mp.pinMarker.lmarker).filter((marker: any) => marker.metric);
      } else {
        markers = mapPoints.map((mp: MapPoint) => mp.pinMarker.lmarker);
      }
      if (markers.length) {
        (this._pinGroup as any).addLayers(markers);
      }
      try {
        this._map.fitBounds(this._pinGroup.getBounds());
      } catch (err) {
        console.warn('No points for bounds');
      }
    });
  }

  public redraw(doMoveMap: boolean = true): void {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    if (!this._renderer) {
      this._renderer = this._factory.createRenderer(this._map);
    }

    const result: IMapPointsRendererResult = this._renderer.evaluate(this._mapPoints, this._subspace);

    // TODO: must NOT use here state: autopositioning should be an option
    // if (!geo) {
    if (doMoveMap && this._renderer.evaluateMapPosition) {
      this._renderer.evaluateMapPosition(result.charts.length ? result.charts : result.pins);
    }

    const chartsHash: { [lid: string]: boolean } = {}, pinsHash: { [lid: string]: boolean } = {};
    result.pins.forEach((mp: MapPoint) => pinsHash[mp.location.id] = true);
    result.charts.forEach((mp: MapPoint) => chartsHash[mp.location.id] = true);

    this._mapPoints.forEach((mp: MapPoint) => {
      if (chartsHash[mp.location.id]) {
        mp.showAsChart();
        mp.show();
      } else if (pinsHash[mp.location.id]) {
        mp.showAsPin();
        mp.show();
      } else {
        mp.hide();
      }
    });

    if (result.pins.length) {
      if ('FirstNonZeroParameter' == configHelper.getValue('map.pinColor')) {
        this.loadPinsData(result.pins);
      }

      if ('native' == configHelper.getValue('map.clusteringMode')) {
        this.redrawClusteredPins(result.pins);
      }
    } else {
      // https://redmine.luxms.com/issues/964 - strange case no locations selected
      if ('native' == configHelper.getValue('map.clusteringMode')) {
        // (this._pinGroup as L.MarkerClusterGroup).clearLayers();
        this.redrawClusteredPins([]);
      }
    }

    if (result.charts.length) {
      this.loadChartsData(result.charts);
    }
  }

  public setAxes(subspace: ISubspace) {
    this._subspace = subspace;

    if ((this._dataProvider as DataProviderCache).invalidate) {
      (this._dataProvider as DataProviderCache).invalidate();
    }
    this.currentClusteredIds = [];

    this.redraw(subspace.ls.length > 0);
  }

  private async loadPinsData(mapPoints: MapPoint[]): Promise<IValue[][]> {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const cfgMapPinColor: string = configHelper.getStringValue('map.pinColor');

    const ms: IMetric[] = this._subspace.ms;
    const ls: ILction[] = mapPoints.map(mp => mp.location);
    const ps: IPeriod[] = [oneEntity(this._subspace.ps)];
    const subspace: ISubspace = bi.createSimpleSubspace(ms, ls, ps);

    const dataMatrix: IValue[][] = await this._dataProvider.getMatrixYX(subspace);

    let polylines: L.LatLng[][] = [];

    for (let i = 0; i < dataMatrix.length; ++i) {
      const mp: MapPoint = mapPoints[i];

      if (cfgMapPinColor == 'FirstNonZeroParameter') {
        const parametersOfLocation: IValue[] = dataMatrix[i];

        const metricIndex: number = parametersOfLocation.indexOf(1);
        let metric: IMetric = null;

        if (metricIndex == -1) {
          // console.warn('No found parameter with value=1');
          // EP: will try to display as gray
          mp.setPinMetric(null);
          mp.setPinColor('transparent');
          continue;
        } else {
          mp.show();
        }
        metric = ms[metricIndex];

        if (metric !== undefined) {
          const val: number = (ms.indexOf(metric) === -1) ? 0 : 1;
          const color: string = val ? metric.color : 'none';
          mp.setPinColor(color);
          mp.setPinMetric(metric);

          // draw lines
          if (configHelper.getBoolValue('map.pin.lineToParent') && color == '#0cff0c') {
            const ll: L.LatLng[] = [mp.latlng, $eid(this._mapPoints, mp.location.parent.id).latlng];
            polylines.push(ll);
          }
        } else {
          mp.setPinColor('#ee00ff');
        }
      } else {
        mp.setPinColor('#ff2f00');
      }
    }

    this._polylineGroup['setLatLngs'](polylines);

    return dataMatrix;
  }

  private async loadChartsData(mapPoints: MapPoint[]): Promise<any> {
    const ms: IMetric[] = this._subspace.ms;
    const ls: ILocation[] = mapPoints.map((mp: MapPoint) => mp.location);       // from MapPoints
    const ps: IPeriod[] = [oneEntity(this._subspace.ps)];
    const subspace: ISubspace = bi.createSimpleSubspace(ms, ls, ps);
    const dataMatrix: IValue[][] = await this._dataProvider.getMatrixYX(subspace);
    this.setData(ls, ms, dataMatrix);
  }

  private _handleChartClick = (e) => {
    const locationId: number = parseInt($(e.layer._icon).children().get(0).id.replace(/\D+/, ''));
    const mp: MapPoint = $eid(this._mapPoints, locationId);
    if (mp.nodata) return;
    this.moveMapToPoint(mp);
    MessageHub.send('showChartDetailWnd', this, {location: mp.location});
  }

  private _handlePinClick = (e) => {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const locationId: string = $(e.layer._icon).children().get(0).id.replace(/\D+/, '');
    const mp: MapPoint = $eid(this._mapPoints, locationId);
    mp.pinMarker.redraw(true);

    const onClickAction: string = configHelper.getStringValue('map.pin.onClick');

    const ms: IMetric[] = this._subspace.ms;
    const ps: IPeriod[] = [oneEntity(this._subspace.ps)];

    if (onClickAction === 'showChart') {
      this._mapPoints.forEach((mp) => mp.showAsPin());
      this._dataProvider
          .getMatrixYX(bi.createSimpleSubspace(ms, [mp.location], ps))
          .then((mtx) => {
            this.setData([mp.location], ms, mtx);
            mp.showAsChart();
          });
    } else if (onClickAction === 'openObjectCard') {
      if (mp.location.card == null) {
        console.warn(`Action: openObjectCard. Location ${locationId} nas no location card`);
        this.moveMapToPoint(mp);
        return;
      }

      this._popup = L.popup({
        minWidth: 323,
      });
      this._popup.setContent(lang('loading'));
      e.layer.bindPopup(this._popup).openPopup();

      const dp: data_engine.IDataProvider = this._dataset.getDataProvider();
      const cfg: IVizelConfig = this._dataset.createVizelConfig({});
      const subspace: ISubspace = bi.createSimpleSubspace(ps, ms, [mp.location]);


      const div = document.createElement('div');
      div.style.width = '100%';
      div.style.height = '100%';
      div.classList.add('leaflet-popup-content');
      div.style.minHeight = '30px';
      let data = {dp, cfg, subspace};
      ReactDOM.render(<VizelLCard {...data} />, div);
      const vzlContainer: HTMLElement = div;
      this._popup.setContent(vzlContainer);


    } else if (onClickAction === 'navigate') {
      try {
        const o: any = {
          ...mp.location.config.onclick,
          locations: [mp.location.id],
        };
        urlState.navigate(o);
      } catch (e) {
        console.warn('Unable to navigate using location', mp.location);
        throw e;
      }
    } else {
      this.moveMapToPoint(mp);
    }
  }

  private moveMapToPoint(targetPoint: MapPoint) {
    this._map.setView(targetPoint.latlng);

    if (BuggyClusteringRenderer.algorytm == null) return;

    let latlng: L.LatLng = targetPoint.latlng;
    let z: number = this._map.getZoom();
    while (true) {
      const ci: ClusterItem = BuggyClusteringRenderer.algorytm.getCluster(latlng, z);
      if (-1 != ci.mapPoints.indexOf(targetPoint)) {
        this._map.setView(targetPoint.latlng, z);
        break;
      }
      z++;
      if (z > this._map.getMaxZoom()) {
        break;
      }
    }
  }
}

export interface IMapPointsRendererResult {
  charts: MapPoint[];
  pins: MapPoint[];
}

export interface IMapPointsRenderer {
  evaluate(mapPoints: MapPoint[], subspace: ISubspace): IMapPointsRendererResult;
  evaluateMapPosition?(mapPoints: MapPoint[]): void;
}


export class BuggyClusteringRenderer implements IMapPointsRenderer {
  private _dataset: IDatasetModel;
  private _mergeLevel0And1: boolean;
  private _map: L.Map;
  private _subspace: ISubspace | null = null;

  public static algorytm: ClusterAlgorytm = null;

  public constructor(dataset: IDatasetModel, map: L.Map) {
    this._dataset = dataset;
    this._map = map;

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    this._mergeLevel0And1 = configHelper.getBoolValue('branches.mergeLevel0And1');

    // algorytm should be non-static
    // it saves L.Map instance and does not work any more on other instances
    // ACHTUNG: we read to private field of ClusterAlgorytm
    if (BuggyClusteringRenderer.algorytm && (BuggyClusteringRenderer.algorytm as any).map !== this._map) {
      BuggyClusteringRenderer.algorytm = null;
    }
  }

  public evaluate(mapPoints: MapPoint[], subspace: ISubspace): IMapPointsRendererResult {
    this._subspace = subspace;

    if (!BuggyClusteringRenderer.algorytm) {
      BuggyClusteringRenderer.algorytm = new ClusterAlgorytm(this._dataset, mapPoints, this._map, AppConfig.getModel().map.mapCircleRadius * 2);
      BuggyClusteringRenderer.algorytm.calculate();
    }

    const ls: ILocation[] = subspace.ls;
/*    if (ls && ls.length) {
        const renderer: IMapPointsRenderer = new DisplayChartsByLocationsRenderer(this._dataset, this._map);
        const result = renderer.evaluate(mapPoints, subspace);
        return result;
    } else {
      if (subspace.koob) {
        const renderer: IMapPointsRenderer = new DisplayChartsByLocationsRenderer(this._dataset, this._map);
        const result = renderer.evaluate(mapPoints, subspace);
        return result;
      }
    }*/

    if (subspace.koob) {
      const renderer: IMapPointsRenderer = new DisplayChartsByLocationsRenderer(this._dataset, this._map);
      const result = renderer.evaluate(mapPoints, subspace);
      return result;
    } else {
      if (ls.length) {
        const renderer: IMapPointsRenderer = new DisplayChartsByLocationsRenderer(this._dataset, this._map);
        const result = renderer.evaluate(mapPoints, subspace);
        return result;
      }
    }


    const center: L.LatLng = this._map.getCenter();
    let zoom: number = this._map.getZoom();

    const minZoom: number = this._dataset.getConfigHelper().getIntValue('startup.map.zoom.min', AppConfig.getModel().map.minZoom);
    const maxZoom: number = this._dataset.getConfigHelper().getIntValue('startup.map.zoom.max', AppConfig.getModel().map.maxZoom);
    if (zoom < minZoom) zoom = minZoom;
    if (zoom > maxZoom) zoom = maxZoom;

    const visibleCluster: ClusterItem = BuggyClusteringRenderer.algorytm.getCluster(center, zoom);
    const level: number = visibleCluster.tree_level;
    let charts: MapPoint[] = visibleCluster.mapPoints;
    if (this._mergeLevel0And1 && level == 1) {
      // join with locations at level 0
      charts = charts.concat(mapPoints.filter((mp) => mp.tree_level === 0));
    }

    const pins: MapPoint[] = mapPoints.filter((mp: MapPoint) => mp.tree_level === level + 1);

    return {charts, pins};
  }

  public evaluateMapPosition(mapPoints: MapPoint[]): void {
    if (this._subspace.koob) {
        const renderer: IMapPointsRenderer = new DisplayChartsRendererKoob(this._dataset, this._map);
        return renderer.evaluateMapPosition(mapPoints);
    } else {
      const ls: ILocation[] = this._subspace.ls;
      if (ls.length) {
        const renderer: IMapPointsRenderer = new DisplayChartsByLocationsRenderer(this._dataset, this._map);
        return renderer.evaluateMapPosition(mapPoints);
      }
    }
/*    const ls: ILocation[] = this._subspace.ls;
    if (ls.length) {
      const renderer: IMapPointsRenderer = new DisplayChartsByLocationsRenderer(this._dataset, this._map);
      return renderer.evaluateMapPosition(mapPoints);
    }*/
  }
}


export class DisplayChartsByLocationsRenderer implements IMapPointsRenderer {
  protected _dataset: IDatasetModel;
  protected map: L.Map;

  public constructor(dataset: IDatasetModel, map: L.Map) {
    this._dataset = dataset;
    this.map = map;
  }

  public evaluate(mapPoints: MapPoint[], subspace: ISubspace): IMapPointsRendererResult {
    const ls: ILocation[] = subspace.ls;
    const points: MapPoint[] = mapPoints.filter((mp: MapPoint) => !!$eid(ls, mp.location.id));
    return {charts: points, pins: []};
  }

  /**
   * evaluates map position to make all locations visible
   */
  public evaluateMapPosition(mapPoints: MapPoint[]): void {
    if (mapPoints.length === 0) {
      return;
    }
    if (mapPoints.length === 1) {
      this.map.setView(mapPoints[0].latlng, this.map.getZoom());
      return;
    }

    let lats: number[] = mapPoints.map(l => l.latlng.lat);
    let lngs: number[] = mapPoints.map(l => l.latlng.lng);

    const minLat: number = Math.min.apply(Math, lats), maxLat: number = Math.max.apply(Math, lats);
    const minLng: number = Math.min.apply(Math, lngs), maxLng: number = Math.max.apply(Math, lngs);

    const dLat: number = maxLat - minLat;
    const dLng: number = maxLng - minLng;

    // too many map moves
    window.setTimeout(() => {
      if (!this.map) return;
/*      this.map.fitBounds(new L.LatLngBounds(
        new L.LatLng(minLat - dLat * 0.2, minLng - dLng * 0.2),
        new L.LatLng(maxLat + dLat * 0.2, maxLng + dLng * 0.2),
      ));*/
    }, 0);
  }
}

export class DisplayChartsRendererKoob implements IMapPointsRenderer {
  protected _dataset: IDatasetModel;
  protected map: L.Map;

  public constructor(dataset: IDatasetModel, map: L.Map, public displayLocationsOnlyAtLevels?: number) {
    this._dataset = dataset;
    this.map = map;
  }

  public evaluate(mapPoints: MapPoint[], subspace: ISubspace): IMapPointsRendererResult {
    const ids: { [lid: string]: boolean } = {};
    // let points: MapPoint[] = mapPoints.filter((mp: MapPoint) => ids[mp.id]);
    let points: MapPoint[] = mapPoints;
/*    if (this.displayLocationsOnlyAtLevels != -1) {
      // points = points.filter((mp) => mp.tree_level == this.displayLocationsOnlyAtLevels);
      // points = points.filter((mp) => mp.zoom == this.displayLocationsOnlyAtLevels);
      points = points.filter((mp) => mp);
    }*/
    return {charts: [], pins: points};
  }

  /**
   * evaluates map position to make all locations visible
   */
  public evaluateMapPosition(mapPoints: MapPoint[]): void {
    if (mapPoints.length === 0) {
      return;
    }
    if (mapPoints.length === 1) {
      this.map.setView(mapPoints[0].latlng, this.map.getZoom());
      return;
    }

    let lats: number[] = mapPoints.map(l => l.latlng.lat);
    let lngs: number[] = mapPoints.map(l => l.latlng.lng);

    const minLat: number = Math.min.apply(Math, lats), maxLat: number = Math.max.apply(Math, lats);
    const minLng: number = Math.min.apply(Math, lngs), maxLng: number = Math.max.apply(Math, lngs);

    const dLat: number = maxLat - minLat;
    const dLng: number = maxLng - minLng;

    // too many map moves
    window.setTimeout(() => {
      if (!this.map) return;
/*      this.map.fitBounds(new L.LatLngBounds(
        new L.LatLng(minLat - dLat * 0.2, minLng - dLng * 0.2),
        new L.LatLng(maxLat + dLat * 0.2, maxLng + dLng * 0.2),
      ));*/
    }, 0);
  }
}

export class DisplayPinsByLocationsRenderer extends DisplayChartsByLocationsRenderer {

  constructor(dataset: IDatasetModel, map: L.Map, public displayLocationsOnlyAtLevels: number) {
    super(dataset, map);
  }

  public evaluate(mapPoints: MapPoint[], subspace: ISubspace): IMapPointsRendererResult {
    const ls: ILocation[] = subspace.ls;
    const ids: { [lid: string]: boolean } = {};
    ls.forEach((l: ILocation) => ids[l.id] = true);
    let points: MapPoint[] = mapPoints.filter((mp: MapPoint) => ids[mp.location.id]);
    if (this.displayLocationsOnlyAtLevels != -1) {
      points = points.filter((mp) => mp.tree_level == this.displayLocationsOnlyAtLevels);
    }
    return {charts: [], pins: points};
  }
}

export class LeafletClusteringRenderer extends DisplayPinsByLocationsRenderer {

  public evaluate(mapPoints: MapPoint[], subspace: ISubspace): IMapPointsRendererResult {
    const ls: ILocation[] = subspace.ls;

    // https://redmine.luxms.com/issues/964 - strange case - should be removed IMHO
    if (ls.length === 0) {
      return {charts: [], pins: []};
    }

    let points: MapPoint[] = mapPoints;

    if (this.displayLocationsOnlyAtLevels != -1) {
      points = mapPoints.filter((mp: MapPoint) => mp.tree_level == this.displayLocationsOnlyAtLevels);
    }

    if (points.length && ls.length) {
      let ids: { [lid: string]: boolean } = {};

      if (this.displayLocationsOnlyAtLevels != -1) {
        // fill ids with state locations only at specified level±
        const collectDescendantIds = (location: ILocation, level: number) => {
          if (location.tree_level === level) {
            ids[location.id] = true;
          } else if (location.tree_level < level) {
            location.children.forEach((l: ILocation) => collectDescendantIds(l, level));
          }
        };

        ls.forEach((l: ILocation) => collectDescendantIds(l, this.displayLocationsOnlyAtLevels));

      } else {
        // fill ids with state locations
        ls.forEach((l: ILocation) => ids[l.id] = true);
      }

      points = points.filter((p: MapPoint) => ids[p.location.id]);
    }

    return {charts: [], pins: points};
  }
}

/**
 * Helper class to group locations with same level
 */
export class ClusterItem {
  public mapPoints: MapPoint[] = null;
  public tree_level: number = -1;

  constructor(mapPoints: MapPoint[], tree_level: number) {
    this.mapPoints = mapPoints;
    this.tree_level = tree_level;
  }
}


export class ClusterAlgorytm {
  private _dataset: IDatasetModel;
  private $map: JQuery;
  private maxLevel: number;
  private minLevel: number;

  public constructor(dataset: IDatasetModel, private allLocations: MapPoint[], private map: L.Map, private minDistance: number) {
    this._dataset = dataset;
    this.$map = $(map.getContainer());
  }

  public calculate() {
    // find closest location for each location, which has the same level
    this.allLocations.forEach((l1: MapPoint) => {
      const a = l1.latlng;
      let minDist = Infinity;
      this.allLocations.forEach((l2: MapPoint) => {
        // location is not the closest one to itself
        if (l1 === l2) return;
        // and we take only from the same level
        if (l2.tree_level !== l1.tree_level) return;
        const b = l2.latlng;
        // some of locations have the same geo position - ignore it!
        if (a.equals(b)) return;
        const dist = a.distanceTo(b);
        if (0 < dist && dist < minDist) {
          // the candidate to closest
          minDist = dist;
          l1.closest = l2;
        }
      });
    });

    this.maxLevel = Math.max.apply(Math, this.allLocations.map(l => l.tree_level));
    this.minLevel = Math.min.apply(Math, this.allLocations.map(l => l.tree_level));
  }

  /**
   * For specified geo point and for each zoom level
   *  find all locations that can be displayed without collisions
   */
  public getCluster(center: L.LatLng, dstZoom: number): ClusterItem {
    let result: ClusterItem[] = [];

    const locationsByZoom: MapPoint[][] = this.clusterLocationsByZooms(center);
    const minZoom: number = this._dataset.getConfigHelper().getIntValue('startup.map.zoom.min', AppConfig.getModel().map.minZoom);
    const maxZoom: number = this._dataset.getConfigHelper().getIntValue('startup.map.zoom.max', AppConfig.getModel().map.maxZoom);

    let level: number = this.maxLevel;

    for (let zoom: number = maxZoom; zoom >= minZoom; zoom--) {
      // locations of current level visible at current zoom
      const locations: MapPoint[] = this.filterMapPointsByLevel(locationsByZoom[zoom], level);
      const hasCollision: boolean = this.detectCollision1(locations, zoom);
      if (!hasCollision) {
        result[zoom] = new ClusterItem(locations, level);
      } else {
        level--;
        // when has a collision we just decrease level
        if (level < this.minLevel) level = this.minLevel;
        result[zoom] = new ClusterItem(this.filterMapPointsByLevel(locationsByZoom[zoom], level), level);
      }
    }
    return result[dstZoom];
  }

  /**
   * return distance between two on-screen points in pixels
   * @param {{x: number, y: number}} p1 - first point
   * @param {{x: number, y: number}} p2 - second point
   * @returns {number}
   */
  private distance(p1, p2): number {
    const dx = p2.x - p1.x, dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  /**
   * Filters the list of locations and return only those that are visible for specified geo position and zoom
   */
  private filterVisibleLocations(locations: MapPoint[], center: L.LatLng, zoom: number): MapPoint[] {
    const size = {
      width: this.$map.width(),
      height: this.$map.height(),
    };
    const centerPoint = this.map.project(center, zoom);

    return locations.filter((l: MapPoint) => {
      const locationPoint = this.map.project(l.latlng, zoom);
      const dx: number = Math.abs(locationPoint.x - centerPoint.x);
      const dy: number = Math.abs(locationPoint.y - centerPoint.y);
      return (dx < size.width / 2 && dy < size.height / 2);
    });
  }

  /**
   *  Cluster all locations by visibility at every zoom level
   */
  private clusterLocationsByZooms(center: L.LatLng): MapPoint[][] {
    let result: MapPoint[][] = [];
    let locations: MapPoint[] = this.allLocations;
    const minZoom: number = this._dataset.getConfigHelper().getIntValue('startup.map.zoom.min', AppConfig.getModel().map.minZoom);
    const maxZoom: number = this._dataset.getConfigHelper().getIntValue('startup.map.zoom.max', AppConfig.getModel().map.maxZoom);

    for (let zoom = minZoom; zoom <= maxZoom; zoom++) {
      // at the next zoom we will see only some of locations from current zoom level
      locations = this.filterVisibleLocations(locations, center, zoom);
      result[zoom] = locations;
    }
    return result;
  }

  /**
   * Returns location with specified level
   */
  private filterMapPointsByLevel(locations: MapPoint[], level: number): MapPoint[] {
    return locations.filter((l) => l.tree_level === level);
  }


  /**
   * Detect whether some of locations in list have some other location so close
   * that prevents to display them as a charts
   * Note: Caller should send only visible locations
   */
  private detectCollision1(mapPoints: MapPoint[], zoom: number): boolean {
    for (let mapPoint of mapPoints) {
      const closest = mapPoint.closest;
      // we will check collision only for closest location on the same level
      if (closest) {
        const p1: L.Point = this.map.project(mapPoint.latlng, zoom);
        const p2: L.Point = this.map.project(closest.latlng, zoom);
        if (this.distance(p1, p2) < this.minDistance)
          return true;
      }
    }
    return false;
  }
}
