import React, { PropsWithChildren } from 'react';
import CanIService from '../../services/CanIService';

type IfICanProps = Readonly<PropsWithChildren<{eachOf?: string[], oneOf?: string[]}>>;

class IfICan extends React.Component<IfICanProps> {
  public componentDidMount() {
    const {eachOf, oneOf} = this.props;
    CanIService.getInstance().ensure([...eachOf ?? [], ...oneOf ?? []]);
    CanIService.getInstance().subscribeUpdatesAndNotify(this._onCanIServiceUpdated);
  }

  public componentWillUnmount() {
    CanIService.getInstance().unsubscribe(this._onCanIServiceUpdated);
  }

  public componentDidUpdate(prevProps: Readonly<IfICanProps>, prevState: Readonly<{}>, snapshot?: any) {
    const {eachOf, oneOf} = this.props;

    if (String(eachOf) !== String(prevProps.eachOf) || String(oneOf) !== String(prevProps.oneOf)) {
      CanIService.getInstance().ensure([...eachOf ?? [], ...oneOf ?? []]);
    }
  }

  private _onCanIServiceUpdated = (allClaims: typeof CanIService.MODEL) => {    // TODO: оптимизировать перерисовки - писать в state только необходимые клеймы и проверять
    this.forceUpdate();   // вдруг нас не уведомят о клеймах, если они поменяются в пропсах, если они все загружены уже
  }

  public render() {
    const {eachOf, oneOf, children} = this.props;
    const canI = CanIService.getInstance().getModel();

    if (Array.isArray(eachOf) && eachOf.map(c => canI[c]).every(v => v === true)) {
      return null;                           // какой-то из требуемых клеймов либо не загружен (undefined), либо запрещен (false)
    }

    if (Array.isArray(oneOf) && oneOf.length && !oneOf.map(c => canI[c]).some(v => v === true)) {
      return null;                            // нет хоть одного клейма, который был бы true
    }

    return children as any;
  }
}

export default IfICan;
