import React from 'react';

// import cn from 'classnames';

interface CenterMapIconProps {

}

const CenterMapIcon = (props: CenterMapIconProps) => {
  //const {  } = props;

  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
     <path d="M0 0h48v48h-48z" fill="none"/>
     <path d="M10 30h-4v8c0 2.21 1.79 4 4 4h8v-4h-8v-8zm0-20h8v-4h-8c-2.21 0-4 1.79-4 4v8h4v-8zm28-4h-8v4h8v8h4v-8c0-2.21-1.79-4-4-4zm0 32h-8v4h8c2.21 0 4-1.79 4-4v-8h-4v8zm-14-20c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"/>
   </svg>
  )
}

export default CenterMapIcon;