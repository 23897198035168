/// <reference path="../defs/bi.d.ts" />

import { NormsResponse } from './norm';
import { httpPost } from '../repositories/http/data-storage';
import { createNullCube, matrixTranspose } from '../data-manip/data-utils';
import { data_engine, INormsResponse } from '../data-manip/data-manip';
import { IDatasetModel } from './ds/types';
import { $eid } from '../libs/imdas/list';
import { AppConfig } from '@luxms/bi-core';
import { eval_lpe } from '@luxms/lpe';
import {
  IDisposable, IEntity, IKoobDimension, IKoobMeasure,
  IMetric,
  IMLPPtrCube,
  IMLPSubspace,
  IPeriod,
  ISubspace,
  IValue,
  responses,
  tables,
} from '../defs/bi';
import { koobDataRequest3 } from './koob/KoobDataService';


import { lookupRequestData, ILookupResponse } from './lookup/LookupDataService';
import { _aggregate, getDataInAxis, getMeasureId, joinDataLookup } from '../utils/utils';
import { makeNullAxis } from './ds/createSubspaceGenerator';

type IBiQueryRequestHash = responses.IBiQueryRequestHash;


//
// some macro
//  P - parameter
//  L - location
//  T - period
//

function makeRequestHashPeriods(ps: IPeriod[], closest: boolean = false): any {
  const periodType: [number, number] = ps ? (ps as any).__continuousPeriodType : undefined;

  if (!closest && periodType && ps.length > 1) {                              // for closest values the algorythm is more complicated
    return {
      start: ps[0].id,
      end: ps[ps.length - 1].id,
      type: periodType[0],
      qty: periodType[1],
    };
  } else {
    return ps.map(p => p.id);
  }
}

// add dependencies for formula
// children for `is_formula` entity is the list of dependencies
function pushDependencyIds(resultArray: (string | number)[], es: any[]) {
  es.filter(e => e.is_formula || e.is_aggregator).forEach(formulaE => {
    (formulaE.children || []).forEach(formulaEChild => {                                          // formula metric children is dependency metric
      if (!resultArray.includes(formulaEChild.id)) {
        resultArray.push(formulaEChild.id);
      }
    });
  });
}


export function makeRequestHash(mlpSubspace: IMLPSubspace, closest: boolean = false): IBiQueryRequestHash {
  const {ms, ls, ps} = mlpSubspace;

  const result: IBiQueryRequestHash = {};
  if (closest) {
    result.closest = true;
  }

  if (ms && ms.length) {
    result.metrics = ms.filter(m => !m.is_formula).map(m => m.id);
    pushDependencyIds(result.metrics, ms);
  }
  if (ls && ls.length) {
    result.locations = ls.filter(l => !l.is_formula && !l.is_aggregator).map(l => l.id);
    pushDependencyIds(result.locations, ls);
  }
  if (ps && ps.length) {
    result.periods = makeRequestHashPeriods(ps, closest);
  }
  return result;
}


class MLPCubeWithRawData implements IMLPPtrCube {
  public constructor(private __rawData: tables.IDataEntry[]) {
    //
  }

  public forEach(fn: (mid: string, lid: string, pid: string, v: IValue) => void): void {
    this.__rawData.forEach((dataEntry: tables.IDataEntry) => {
      fn(String(dataEntry.metric_id),
          String(dataEntry.loc_id),
          dataEntry.period_id,
          ('val' in dataEntry) ? dataEntry.val : dataEntry.value);
    });
  }
}


function makeCube(subspace: ISubspace, rawData: tables.IDataEntry[]): IValue[][][] {
  const mlpCube: MLPCubeWithRawData = new MLPCubeWithRawData(rawData);
  const result: IValue[][][] = subspace.projectData(mlpCube);
  return result;
}

export function makeCubeKoob(subspace: ISubspace, rawData: { [id: string]: string | number }[]): IValue[][][] {
  const xAxes: string[] = subspace.xAxis.split(';');
  const yAxes: string[] = subspace.yAxis.split(';');
  const zAxes: string[] = subspace.zAxis.split(';');
  const idxMeasures = xAxes.indexOf('measures');
  const idyMeasures = yAxes.indexOf('measures');
  const idzMeasures = zAxes.indexOf('measures');

  const _cachedData = {};

  // create cached xId+yId = data
  for (let i = 0; i < rawData.length; i++) {
    const record = rawData[i];
    const xAxis = createIds(record, xAxes, subspace.measures, idxMeasures);
    const yAxis = createIds(record, yAxes, subspace.measures, idyMeasures);
    const zAxis = createIds(record, zAxes, subspace.measures, idzMeasures);
    xAxis.forEach((xx) => {
      yAxis.forEach((yy) => {
        zAxis.forEach((zz) => {
          const xId: string = xx.id;
          const yId: string = yy.id;
          const zId: string = zz.id;
          if (!_cachedData[xId]) _cachedData[xId] = {};
          if (!_cachedData[xId][yId]) _cachedData[xId][yId] = {};
          if (!_cachedData[xId][yId][zId]) _cachedData[xId][yId][zId] = _aggregate();
          _cachedData[xId][yId][zId].put(record);
        });
      });
    });
  }

  const measureLast: IKoobMeasure = subspace.measures[subspace.measures.length - 1];
  const matrix: IValue[][][] = [];
  for (let zi = 0; zi < subspace.zs.length; zi++) {
    if (!matrix[zi]) matrix[zi] = [];
    for (let yi = 0; yi < subspace.ys.length; yi++) {
      if (!matrix[zi][yi]) matrix[zi][yi] = [];
      for (let xi = 0; xi < subspace.xs.length; xi++) {
        const xAxis = subspace.xs[xi];
        const yAxis = subspace.ys[yi];
        const zAxis = subspace.zs[zi];
        const measure = getMeasureId(xAxis, yAxis, zAxis) ?? measureLast?.id;
        const value = _cachedData?.[xAxis.id]?.[yAxis.id]?.[zAxis.id]?.get(measure) ?? null;
        matrix[zi][yi].push(value);
      }
    }
  }
  return matrix;

  function createIds(data: any, option: string[], measures: IKoobMeasure[], idx): { id: string, ids: string[] }[] {
    const axis = {ids: [], id: ''};
    const axisMeasures = [];

    const replaceMeasure = (measures.length > 1 && idx !== -1) || (option.length === 1 && idx !== -1);

    option.forEach((attr, i) => {
      const name: string = data[attr];
      if (attr === 'measures' && replaceMeasure) axis.ids.push(name);
      else if (attr !== 'measures') axis.ids.push(name);
    });

    axis.id = axis.ids.join(' ');

    if (replaceMeasure) {
      measures.forEach((m) => {
        const mId = m.id;
        const idCopy = axis.ids.slice(0);
        idCopy[idx] = mId;
        const copyAxis = {ids: idCopy, id: ''};
        copyAxis.id = copyAxis.ids.join(' ');
        axisMeasures.push(copyAxis);
      });
    }


    return replaceMeasure ? axisMeasures : [axis];
  }
}

export function makeCubeLookup(subspace: ISubspace, rawData: ILookupResponse): IValue[][] {
  const {xAxis, yAxis, offset} = subspace;
  const rows = rawData.rows;
  const columns = rawData.columns;

  const _cachedData = {};

  const xAxisId = (xAxis || '').split(';');
  const yAxisId = (yAxis || '').split(';');

  for (let i = 0; i < rows.length; i++) {
    const idx = offset + i;
    const record = joinDataLookup(rows[i], columns);
    const xKey = xAxisId.map((x, i) => i + idx).join(' ');
    if (!_cachedData[xKey]) _cachedData[xKey] = {};
    yAxisId.forEach((y) => {
      if (!_cachedData[xKey][y]) _cachedData[xKey][y] = _aggregate();
      _cachedData[xKey][y].put(record);
    });
  }

  const matrix: IValue[][] = [];

  for (let y = 0; y < subspace.ys.length; y++) {
    if (!matrix[y]) matrix[y] = [];
    for (let x = 0; x < subspace.xs.length; x++) {
      const xKey = subspace.xs[x].id;
      const yKey = subspace.ys[y].id;
      const measure = getMeasureId(subspace.xs[x], subspace.ys[y]);
      const value = _cachedData?.[xKey]?.[yKey]?.get(measure) ?? null;
      matrix[y].push(value);
    }
  }

  return matrix;
}


function emulateSlowness(): Promise<any> {
  if (typeof window !== 'undefined' && window['DEBUG_SLOW']) {
    return new Promise((resolve) => window.setTimeout(() => resolve(), 1000 * window['DEBUG_SLOW']));
  } else {
    return Promise.resolve();
  }
}


function isEmptyMLPSubspace(mlpSubspace: IMLPSubspace): boolean {
  const {ms = [], ls = [], ps = []} = mlpSubspace;
  return ms.length === 0 || ls.length === 0 || ps.length === 0;
}


//
//
//  BiQuery
//
//
export class NetStrategy implements data_engine.IRawDataProvider {
  protected _datasetKey: string;
  protected _datasetModel: IDatasetModel;

  public constructor(datasetModel: IDatasetModel) {
    this._datasetModel = datasetModel;
    this._datasetKey = datasetModel.schema_name;
  }

  protected getRequestUrl(): string {
    return AppConfig.fixRequestUrl(`/api/data/${this._datasetKey}/cube`);
  }

  private _processMKeysNorms(data: tables.INormDataEntry[]): tables.INormDataEntry[] {
    const result: tables.INormDataEntry[] = data.map((d: tables.INormDataEntry) => {
      const ms: IMetric[] = this._datasetModel.metrics;
      const normMetric: IMetric = $eid(ms, d.norm_id);
      if (!normMetric) {                                                                            // when metrics are formula
        return null;
      }

      return {
        metric_id: String(d.metric_id),
        loc_id: String(d.loc_id),
        period_id: String(d.period_id),
        value: ('val' in d) ? d.val : d.value,
        val: ('val' in d) ? d.val : d.value,
        // INormDataEntry
        norm_id: normMetric.id,

        // hacky metric
        normMetric,
      };
    }).filter(v => v !== null);
    return result;
  }

  public async _getBiQueryRawData<T extends tables.IDataEntry>(url: string, mlpSubspace: IMLPSubspace, closest: boolean = false): Promise<T[]> {
    if (isEmptyMLPSubspace(mlpSubspace)) {
      console.warn('Empty subspace');
      return Promise.resolve([]);
    }
    const cube: IBiQueryRequestHash = makeRequestHash(mlpSubspace, closest);

    const result: T[] = await httpPost<T[]>(url, {
      version: '2.0',
      cube: cube,
      type: cube.closest ? 'data+closest' : 'data',
      responseFormat: 'dataRecordsJSON',
      accept: 'application/json; format=data-records',
    });

    const getVal = (mId, lId, pId): number | null => {
      const entry = result.find(e => e.metric_id == mId && e.loc_id == lId && e.period_id == pId);
      return entry ? (entry as any).val : null;
    };

    let {ms, ls, ps} = mlpSubspace;

    // count aggregates binded to ls
    ls.forEach((l, li) => {
      if (l.is_aggregator) {
        ms.forEach((m, mi) => {
          if (m.config.aggregate) {
            ps.forEach((p, pi) => {
              let val = NaN;
              let deps = l.children.map(child => getVal(m.id, child.id, p.id)).filter(v => v !== null);
              if (deps.length) {
                switch (m.config.aggregate) {
                  case 'SUM':
                    val = deps.reduce((a, b) => a + b, 0);
                    break;
                  case 'MAX':
                    val = Math.max.apply(Math, deps);
                    break;
                  case 'MIN':
                    val = Math.min.apply(Math, deps);
                    break;
                  case 'AVG':
                    val = val = deps.reduce((a, b) => a + b, 0) / deps.length;
                    break;
                  case 'COUNT':
                    val = deps.length;
                    break;
                }
                result.push({metric_id: m.id, period_id: +p.id, loc_id: l.id, val} as any);
              }
            });
          }
        });
      }
    });

    // count formulas binded to ms
    ms.forEach((m, mi) => {
      if (m.is_formula && m.id.startsWith('=')) {
        const [_, formula] = m.id.slice(1).match(/^(.*?)(?:\?(.*))?$/);
        // TODO: optimize
        ls.forEach((l, li) => {
          ps.forEach((p, pi) => {
            let val = eval_lpe(formula, (name, value) => {
              if (name.startsWith('#') && name.match(/^#(\d+)$/)) {
                const mId = RegExp.$1;
                return getVal(mId, l.id, p.id);
              }
              return undefined;
            });
            if (!isNaN(val)) {
              result.push({metric_id: m.id, period_id: +p.id, loc_id: +l.id, val} as any);
            }
          });
        });
      }
    });

    await emulateSlowness();
    return result;
  }

  public async getRawData(mlpSubspace: IMLPSubspace, closest?: boolean): Promise<tables.IDataEntry[]> {
    const hasExternalMetrics: boolean = !!mlpSubspace.ms.find(m => m.config.dataset);

    if (hasExternalMetrics) {
      const msByDs = {};
      mlpSubspace.ms.forEach(m => (msByDs[m.config.dataset] || (msByDs[m.config.dataset] = [])).push(m));
      let lIds = mlpSubspace.ls && mlpSubspace.ls.length ? mlpSubspace.ls.filter(l => !l.is_formula).map(l => l.id) : null;
      let pIds = mlpSubspace.ps && mlpSubspace.ps.length ? makeRequestHashPeriods(mlpSubspace.ps, closest) : null;
      let result = [];
      for (let dsId in msByDs) {
        if (dsId === 'undefined') {
          // TODO: mixed content
          debugger;
        }
        const ms = msByDs[dsId];
        const url: string = AppConfig.fixRequestUrl(`/api/data/${dsId === 'undefined' ? this._datasetKey : dsId}/cube`);
        let mIds = ms.map(m => m.config.metric_id);
        const request: IBiQueryRequestHash = {};
        request.metrics = mIds;
        if (lIds) request.locations = lIds;
        if (pIds) request.periods = pIds;

        // getBiQuery
        const entries: any[] = await httpPost(url, {
          version: '2.0',
          cube: request,
          type: 'data',
          responseFormat: 'dataRecordsJSON',
          accept: 'application/json; format=data-records',
        });

        entries.forEach(entry => {                                                    // change metric ids to emulate
          let m = ms.find(mm => mm.config.metric_id == entry.metric_id);
          entry.metric_id = m.id;
        });
        result = result.concat(entries);
      }
      return result;
    }

    const url: string = AppConfig.fixRequestUrl(`/api/data/${this._datasetKey}/cube`);
    const dataEntries: tables.IDataEntry[] = await this._getBiQueryRawData<tables.IDataEntry>(url, mlpSubspace, closest);
    return dataEntries;
  }

  private static _hasV3Norms: boolean = true;

  public async getRawNorms3(mlpSubspace: IMLPSubspace): Promise<tables.INormDataEntry[]> {
    const url: string = AppConfig.fixRequestUrl(`/api/v3/data/${this._datasetKey}/norms`);
    const ndes: tables.INormDataEntry[] = await this._getBiQueryRawData<tables.INormDataEntry>(url, mlpSubspace);
    return ndes;
  }

  public async getRawNorms(mlpSubspace: IMLPSubspace): Promise<tables.INormDataEntry[]> {
    if (NetStrategy._hasV3Norms) {
      try {
        const ndes = await this.getRawNorms3(mlpSubspace);
        const result: tables.INormDataEntry[] = this._processMKeysNorms(ndes);
        return result;
      } catch (err) {
        // might be no V3
        NetStrategy._hasV3Norms = false;
      }
    }

    try {
      const url: string = AppConfig.fixRequestUrl(`/api/data/${this._datasetKey}/norms`);
      const ndes: tables.INormDataEntry[] = await this._getBiQueryRawData<tables.INormDataEntry>(url, mlpSubspace);
      const result: tables.INormDataEntry[] = this._processMKeysNorms(ndes);
      return (result && result.length) ? result : null;
    } catch (err) {
      console.warn('Error in norms:', err.message);
      console.error(err);
      return null;                 // norms are not critical, so recover with null
    }
  }

  public async getAggregate(mlpSubspace: IMLPSubspace): Promise<any> {
    const cube: IBiQueryRequestHash = makeRequestHash(mlpSubspace);
    const url = AppConfig.fixRequestUrl(`/api/data/${this._datasetKey}/aggregate`);
    const result: any = await httpPost(url, {
      version: '2.0',
      cube: cube,
      type: cube.closest ? 'data+closest' : 'data',
      responseFormat: 'dataRecordsJSON',
      accept: 'application/json; format=data-records',
    });
    return result;
  }

  public async load(request: data_engine.IRawRequest, mlpSubspace: IMLPSubspace, closest?: boolean): Promise<data_engine.IRawResponse> {
    const [data, norms, aggregate] = await Promise.all([
      request.data ? this.getRawData(mlpSubspace, closest) : null,
      request.norms ? this.getRawNorms(mlpSubspace) : null,
      request.aggregate ? this.getAggregate(mlpSubspace) : null,
    ]);
    return {
      data: request.data ? data : null,
      norms: request.norms ? norms : null,
      aggregate: request.aggregate ? aggregate : null,
    };
  }

  public async getRawColors(mlpSubspace: IMLPSubspace): Promise<{                 // table: data
    loc_id: string | number;
    metric_id: string | number;
    period_id: string;
    color: string;
  }[]> {

    if (isEmptyMLPSubspace(mlpSubspace)) {
      console.warn('Empty subspace');
      return Promise.resolve([]);
    }
    const url = AppConfig.fixRequestUrl(`/api/data/${this._datasetKey}/colors`);

    const requestHash = makeRequestHash(mlpSubspace, false);
    requestHash.periods = mlpSubspace.ps.map(p => p.id);
    const body: any = {
      version: '2.0',
      cube: requestHash,
    };
    const rawColors = await httpPost<any>(url, body);
    return rawColors;
  }

  public rawSubscribe(mlpSubspace: IMLPSubspace, callback: data_engine.IMLPSubscribeCallback): IDisposable {
    throw new Error('not implemented');
  }

  public async getKoobData(subspace: ISubspace): Promise<any[]> {
    const {xs, ys, zs, schemaName} = subspace;
    let allDimensionId = [];
    let allSubtotalsIds = [];
    const innerFilters: { [id: string]: IValue[] } = {}; //
    const allMeasureIds = subspace.measures.map(m => m.formula);

    [xs, ys, zs].forEach((axes) => {
      axes.forEach((axis) => {
        // получаю все filters и columns
        const {columns, filters, subtotals} = getDataInAxis(axis);
        allDimensionId.push(...columns);
        allSubtotalsIds.push(...subtotals);
        Object.keys(filters).forEach((key) => {
          if (!innerFilters[key]) innerFilters[key] = ['='];
          const ids = filters[key];
          innerFilters[key].push(...ids);
        });
      });
    });
    // каждый дименшен должен быть уникальный, как снежинка или 💩
    allDimensionId = Array.from(new Set(allDimensionId));
    allSubtotalsIds = Array.from(new Set(allSubtotalsIds));
    Object.keys(innerFilters).forEach((key) => innerFilters[key] = Array.from(new Set(innerFilters[key])));

    const reqFilter = {...subspace.filters, ...innerFilters}; // мержу фильтры

    if (!allDimensionId.length && !allMeasureIds.length) return [];

    const data = await koobDataRequest3(subspace.koob, allDimensionId, allMeasureIds, reqFilter, {schema_name: schemaName});

    // делаю отдельный запрос на подытоги
    const reqSubtotals = allSubtotalsIds.filter(s => subspace.subtotals.includes(s)); // чтобы общий итог не смог запросить тоже ненужный подытог
    for (let i = 0; i < reqSubtotals.length; i++) {
      const subtotalId = allSubtotalsIds[i];
      const subtotalsFilter = {...reqFilter, [subtotalId]: undefined};
      const subtotalsDimension = allDimensionId.filter(d => d !== subtotalId);
      const subtotalData = await koobDataRequest3(subspace.koob, subtotalsDimension, allMeasureIds, subtotalsFilter, {schema_name: schemaName}, 'subtotal');
      subtotalData.forEach((req) => data.push({...req, [subtotalId]: `∑${subtotalId}`}));
    }
    return data;
  }

  public async getKoobAggregate(subspace: ISubspace): Promise<any> {
    const result = {
      minval: null,
      maxval: null,
      // avgval: null,  // todo есть ли среднее ?
    };
    if (!subspace.measures.length) return result;

    const measure: IKoobMeasure = subspace.measures[subspace.measures.length - 1];
    const {name, formula, type, id} = measure;
    const filters = subspace.rawFilters;

    if (type === 'SUM') {
      const filterMax = {[name]: ['>', '0'], ...filters};
      const filterMin = {[name]: ['<', '0'], ...filters};
      const min = await koobDataRequest3(subspace.koob, [], [formula], filterMin);
      const max = await koobDataRequest3(subspace.koob, [], [formula], filterMax);
      result.minval = min[0][id] ?? 0; // Женя сказал что сумма равна 0 если ничего не выбрано
      result.maxval = max[0][id] ?? 0; // Женя сказал что сумма равна 0 если ничего не выбрано
    } else {
      const formulaMin: any = `min(${name}):min`;
      const formulaMax: any = `max(${name}):max`;
      const min = await koobDataRequest3(subspace.koob, [], [formulaMin], {...filters});
      const max = await koobDataRequest3(subspace.koob, [], [formulaMax], {...filters});
      result.minval = min[0]?.min;
      result.maxval = max[0]?.max;
    }

    return result;
  }

  public async getLookupData(subspace: ISubspace): Promise<ILookupResponse> {
    const {ms, ls, ps, lookupId, offset, limit} = subspace;
    const data = await lookupRequestData(this._datasetKey, lookupId, {ms, ls, ps}, {offset, limit});
    return data;
  }
}


export class DataProvider implements data_engine.IDataProvider {
  private __decoratee: data_engine.IRawDataProvider;
  public subscribeFn: any;

  public constructor(rawDataProvider: data_engine.IRawDataProvider) {
    this.__decoratee = rawDataProvider;
  }

  // IRawDataProvider
  public getRawData(subspace: ISubspace, closest: boolean = false): Promise<tables.IDataEntry[]> {
    if (subspace.koob) return this.__decoratee.getKoobData(subspace);
    if (subspace.lookupId) return this.__decoratee.getLookupData(subspace) as any;
    else return this.__decoratee.getRawData(subspace, closest);
  }

  public getRawColors(mlpSubspace: IMLPSubspace): Promise<tables.IDataEntry[]> {
    return this.__decoratee.getRawColors(mlpSubspace);
  }

  public getRawNorms(mlpSubspace: IMLPSubspace): Promise<tables.INormDataEntry[]> {
    return this.__decoratee.getRawNorms(mlpSubspace);
  }

  public getAggregate(subspace: ISubspace): Promise<any> {
    if (subspace.koob) return this.getKoobAggregate(subspace);
    return this.__decoratee.getAggregate(subspace);
  }

  // todo заменить в кода на getAggregate
  public getKoobAggregate(subspace: ISubspace): Promise<any> {
    return this.__decoratee.getKoobAggregate(subspace);
  }

  public getKoobData(subspace: ISubspace): Promise<any[]> {
    return this.__decoratee.getKoobData(subspace);
  }

  public getLookupData(subspace: ISubspace): Promise<ILookupResponse> {
    return this.__decoratee.getLookupData(subspace);
  }

  public load(request: data_engine.IRawRequest, mlpSubspace: IMLPSubspace, closest?: boolean): Promise<data_engine.IRawResponse> {
    return this.__decoratee.load(request, mlpSubspace, closest);
  }

  public rawSubscribe(mlpSubspace: IMLPSubspace, callback: data_engine.IMLPSubscribeCallback): IDisposable {
    return this.__decoratee.rawSubscribe(mlpSubspace, callback);
  }

  // ICubeProvider
  public async getCube(subspace: ISubspace, closest: boolean = false): Promise<IValue[][][]> {
    if (subspace.zs.length === 0 || subspace.ys.length === 0 || subspace.xs.length === 0) {
      return createNullCube(
          subspace.zs.length,
          subspace.ys.length,
          subspace.xs.length);
    }

    const response: data_engine.IRawResponse = await this.load({data: true}, subspace, closest);
    const cube: IValue[][][] = makeCube(subspace, response.data);
    return cube;
  }

  private async getLookupMatrixYX(subspace: ISubspace): Promise<IValue[][]> {
    const data: any = await this.getRawData(subspace);
    const cube = makeCubeLookup(subspace, data);
    return cube;
  }

  public async getKoobMatrixYX(subspace: ISubspace): Promise<IValue[][][]> {
    const data = await this.getRawData(subspace);
    const cube: IValue[][][] = makeCubeKoob(subspace, data);
    return cube;
  }

  // IMatrixProvider
  public async getMatrixYX(subspace: ISubspace, closest: boolean = false): Promise<IValue[][]> {
    let cube: IValue[][][] = [];

    if (subspace.lookupId) cube = [await this.getLookupMatrixYX(subspace)];
    else if (subspace.koob) cube = await this.getKoobMatrixYX(subspace);
    else cube = await this.getCube(subspace, closest);
    if (cube.length === 0) return null;
    const matrix: IValue[][] = cube[cube.length - 1];
    return matrix;
  }

  // IVectorProvider
  public async getVectorX(subspace: ISubspace, closest: boolean = false): Promise<IValue[]> {
    let cube: IValue[][][] = [];

    if (subspace.lookupId) cube = [await this.getLookupMatrixYX(subspace)];
    else if (subspace.koob) cube = await this.getKoobMatrixYX(subspace);
    else cube = await this.getCube(subspace, closest);

    if (cube.length === 0) return null;
    const matrix: IValue[][] = cube[cube.length - 1];
    if (matrix.length === 0) return null;
    const vector: IValue[] = matrix[matrix.length - 1];
    return vector;
  }

  public async getVectorY(subspace: ISubspace, closest: boolean = false): Promise<IValue[]> {
    let cube: IValue[][][] = [];
    if (subspace.lookupId) cube = [await this.getLookupMatrixYX(subspace)];
    else if (subspace.koob) cube = await this.getKoobMatrixYX(subspace);
    else cube = await this.getCube(subspace, closest);

    if (cube.length === 0) return null;
    const matrix: IValue[][] = cube[cube.length - 1];
    const tMatrix = matrixTranspose(matrix);
    if (tMatrix.length === 0) return null;
    const vector: IValue[] = tMatrix[tMatrix.length - 1];
    return vector;
  }

  public async getTotalVector(subspace: ISubspace): Promise<IValue[]> {
    if (!subspace.koob) {
      console.warn('Not implemented');
      return [];
    }
    let xsTotal = [makeNullAxis()];
    let ysTotal = [makeNullAxis()];
    let zsTotal = [makeNullAxis()];

    if (subspace.xAxis.match(/measures/)) xsTotal = subspace.xs;
    if (subspace.yAxis.match(/measures/)) ysTotal = subspace.ys;
    if (subspace.zAxis.match(/measures/)) zsTotal = subspace.zs;
    const fakeSubspace: any = {
      xs: xsTotal,
      ys: ysTotal,
      zs: zsTotal,
      koob: subspace.koob,
      filters: subspace.filters,
      measures: subspace.measures,
      xAxis: subspace.xAxis.match(/measures/) ? subspace.xAxis : '',
      yAxis: subspace.yAxis.match(/measures/) ? subspace.yAxis : '',
      zAxis: subspace.zAxis.match(/measures/) ? subspace.zAxis : '',
      subtotals: [],
    };
    if (subspace.xAxis.match(/measures/)) return this.getVectorX(fakeSubspace);
    if (subspace.yAxis.match(/measures/)) return this.getVectorY(fakeSubspace);
    return [];
  }

  // IValueProvider
  public async getValue(subspace: ISubspace, closest: boolean = false): Promise<IValue> {
    let cube: IValue[][][] = [];
    if (subspace.lookupId) cube = [await this.getLookupMatrixYX(subspace)];
    else if (subspace.koob) cube = await this.getKoobMatrixYX(subspace);
    else cube = await this.getCube(subspace, closest);

    if (cube.length === 0) return null;
    const matrix: IValue[][] = cube[cube.length - 1];
    if (matrix.length === 0) return null;
    const vector: IValue[] = matrix[matrix.length - 1];
    if (vector.length === 0) return null;
    const value: IValue = vector[vector.length - 1];
    return value;
  }

  // IDataProvider
  public subscribe(subspace: ISubspace, callback: data_engine.ISubscribeCallback): IDisposable {
    if (this.subscribeFn) {
      return this.subscribeFn(subspace, callback);
    }
    return null;
  }

  // INormsProvider
  public getNorms(subspace: ISubspace): Promise<INormsResponse | any> {
    if (subspace.koob || subspace.lookupId) console.error('Нет нормы у koob');
    return this.__decoratee
        .getRawNorms(subspace)
        .then((data: tables.INormDataEntry[]) => data ? new NormsResponse(subspace, data) : null);
  }

}


type RTEntryPair = [ISubspace, (z: IEntity, y: IEntity, x: IEntity, v: number) => void];

export class BiQuery {
  private __dataProvider: DataProvider;
  private __subscribePairs: RTEntryPair[] = [];

  public constructor(data: tables.IDataEntry[], datasetModel: IDatasetModel) {
    //
    //  if we have data then use crossfilter with data
    //  otherwise use network requests
    //
    // const rawDataProvider: data_engine.IRawDataProvider = (typeof data !== 'undefined') ? new CrossfilterStrategy(data) : new NetStrategy(datasetKey);
    const rawDataProvider: data_engine.IRawDataProvider = new NetStrategy(datasetModel);
    this.__dataProvider = new DataProvider(rawDataProvider);

    this.__dataProvider.subscribeFn = (subspace: ISubspace, callback: (rtUpdateEntry: any) => void): IDisposable => {
      let entry: RTEntryPair = [subspace, callback];
      this.__subscribePairs.push(entry);

      return {
        dispose: () => {
          let idx = this.__subscribePairs.indexOf(entry);
          if (idx !== -1) {
            this.__subscribePairs.splice(idx, 1);
          }
        },
      };
    };
  }

  public getDataProvider(): DataProvider {
    return this.__dataProvider;
  }

  public notifyDataChange(mid: string, lid: string, pid: string, v: number) {
    this.__subscribePairs.forEach((e: RTEntryPair) => {
      let [subspace, callback] = e;
      let [zi, yi, xi] = subspace.getZYXIndexesByMLPIds(mid, lid, pid);
      if (zi === -1 || yi === -1 || xi === -1) {
        return;
      }
      try {
        callback(subspace.getZ(zi), subspace.getY(yi), subspace.getX(xi), v);
      } catch (err) {
        // skip???
      }
    });
  }
}
