/// <reference path="../../defs/bi.d.ts" />
/// <reference path="../../services/norm.ts" />

/**
 *
 *  lcard: location card vizel
 *
 */

import React from 'react';
import './VizelLCard.scss';

import {bi, makeValue, oneEntity, parseVizelTypeString} from '../../utils/utils';
import { $eid } from '../../libs/imdas/list';
import { IDatasetModel, IVizelConfig, IVizelProps } from '../../services/ds/types';
import { INormsResponse } from '../../data-manip/data-manip';
import {urlState, UrlState} from '@luxms/bi-core';
import { replaceNavigateWithSubstitutions } from '../../utils/url-utils';
import {
  ILocation,
  ILocationCard, ILocationCardField,
  IMapFill,
  IMetric,
  IPeriod,
  ISubspace,
  IValue, IVizel,
  IVizelDescription,
} from '../../defs/bi';
import {lpeRun} from '../../utils/lpeRun';


/*
class VizelLCard extends BaseVizel {
  private _location: ILocation = null;
  private _period: IPeriod = null;
  private _periods: IPeriod[] = [];
  private _card: ILocationCard = null;

  public constructor(props: IVizelProps) {
    super(props);
  }

  public async setAxes(subspace: ISubspace): Promise<any> {
    await super.setAxes(subspace);
    const {ms, ls, ps} = subspace;

    this._location = ls ? oneEntity(ls) : null;
    this._period = ps ? oneEntity(ps) : null;
    this._periods = ps;
    if (this._$container) {
      this._applyData();
    }
  }

  public attached(container: HTMLElement): void {
    super.attached(container);
    this._$container.addClass('leaflet-popup-content');
    this._$container.css('min-height', '30px');
    if (this._location && this._period) {
      window.setTimeout(() => this._applyData());
    }
  }

  protected _noData(): void {
    super._noData();
  }

  private _onItemClick(e: JQueryEventObject, field: ILocationCardField, m: IMetric): void {
    let command = field.config.onClick;

    if (String(command).toLowerCase().startsWith('navigate')) {
      const params = replaceNavigateWithSubstitutions(command, {                                     // replace %m %l %p
        m: m ? m.id : '',
        l: this._location ? this._location.id : '',
        // p: p ? p.id : '',
        // x: x ? x.id : '',
        // y: x ? y.id : '',
        // z: x ? z.id : '',
      });
      UrlState.navigate(params);
      return;
    }

    if (command === 'maximize') {
      //
      // THIS IS UGLY COPYPASTE FROM dash.js
      // PLEASE CONSIDER CREATING A CLASS FOR VARIOUS onClick ACTIONS
      //
      const dataset: IDatasetModel = this._cfg.getDataset();
      const m: IMetric = field.metric;

      import('./plot').then((PlotModule) => {
        const vizelConfig: IVizelConfig = dataset.createVizelConfig({});
        vizelConfig.colorResolver = {
          getColor: (e, v) => '#0000cc',
          getBgColor: (e, v) => '#0000cc',
          getColorPair: (e, v) => ({color: '#0000cc', bgColor: '#0000cc'}),
        };

        // axis order might change in case of tree-based text vizel
        // currently no vizel accepts many zs, so simplify as:
        const subspace: ISubspace = bi.createSimpleSubspace(this._periods, [m], [this._location]);
        const vzl: IVizel = new PlotModule.default({dp: dataset.getDataProvider(), cfg: vizelConfig, subspace});

        import('../modal-container').then((modalContainerModule: any) => {
          modalContainerModule.modalContainer.push(vzl, this._location.title + ' / ' + m.title);
        });
      });
    }
  }

  private _drawData(vec: IValue[], ms: IMetric[], title: string, doCleanup: boolean = true): void {
    if (!this._$container) {
      return;
    }
    if (!vec || vec.length === 0) {
      this._noData();
      return;
    }

    const items: JQuery[] = ms.map((m: IMetric, i: number) => {
      const sval: string = makeValue(vec[i], m.unit) || '-';        // TODO: must not be '-' on empty strings
      const item: JQuery = $('<tr></tr>')
          .append($('<td class="name"></td>').text(m.title + ':'))
          .append($('<td class="value"></td>').text(sval));
      // .append('<br>');

      if (this._card.fields[i].config && this._card.fields[i].config.onClick) {
        item.find('.value')
            .css('text-decoration', 'underline')        // TODO: add real <a> to be able to open in new tab
            .css('cursor', 'pointer')
            .on('click', (event: JQueryEventObject) => {
                this._onItemClick(event, this._card.fields[i], m);
            });
      }
      return item;
    });

    const $inner = $('<div></div>');
    const isShowTitle = true;                 // this._cfg.getOption('ShowTitle');

    if (isShowTitle) {
      $inner.append($('<h2 class="VizelLCard__Header"></h2>').text(title));
    } else {
      $inner.css('margin', '20px');
    }

    $inner.append($('<table style="width:100%"></table>').append(items));

    if (doCleanup) {
      this._$container.empty();
    }
    this._$container.append($inner);

    // TODO: check option
    this._dp
        .getNorms(bi.createSimpleSubspace(ms, [this._location], [this._period]))
        .then((normsResponse: INormsResponse) => {
          ms.forEach((m: IMetric, i: number) => {
            const color: string | null = normsResponse ? normsResponse.getColor(m, vec[i]) : null;
            if (color != null) {
              items[i].find('.value').css('color', color);
            }
          });
        });
  }

  private async _applyData(): Promise<any> {
    if (!this._$container) {
      return 'No container';
    }

    try {
      const rawCfg: any = this._cfg.getRaw();

      if (!this._period || !this._location || !(('cardId' in rawCfg) || this._location.card)) {
        this._noData();
        return Promise.resolve();
      }

      const locationCards = this._cfg.getDataset().locationCards;

      if ('cardId' in rawCfg) {
        this._card = $eid(locationCards, String(rawCfg.cardId));
      } else {
        this._card = this._location.card;
      }
      console.assert(!!this._card);

      const ms: IMetric[] = this._card.fields.map((f: ILocationCardField) => f.metric).filter(m => m != null);

      this._loading(true);
      let vec: IValue[] = await this._dp.getVectorX(bi.createSimpleSubspace(ms, [this._location], [this._period]));
      this._loading(false);
      this._drawData(vec, ms, this._card.title);

      if (this._card.config && this._card.config.childrenCard) {
        const childrenCard = $eid(locationCards, String(this._card.config.childrenCard));
        if (childrenCard) {
          const ms2: IMetric[] = childrenCard.fields.map((f: ILocationCardField) => f.metric).filter(m => m != null);
          const mtx: IValue[][] = await this._dp.getMatrixYX(bi.createSimpleSubspace(ms2, this._location.children, [this._period]));
          this._location.children.forEach((l, i) => {
            this._drawData(mtx[i], ms2, childrenCard.title + ' ' + l.title, false);
          });
        }
      }

    } catch (err) {
      // this._error(...);
    }
  }

  public getView(): HTMLElement {
    const div = document.createElement('div');
    div.className = 'VizelLCard Vizel view vizel plot';
    div.style.width = '100%';
    div.style.height = '100%';
    return div;
  }
}
*/

enum LoadingStatus {
  UNDEFINED,
  LOADING_FIRST_TIME,
  LOADING,
  NO_DATA,
  HAS_DATA,
}

interface IVizelLCardRState {
  readonly _location: ILocation;
  readonly _period: IPeriod;
  readonly _periods: IPeriod[];
  readonly _card: ILocationCard;
  readonly className: string [];
  readonly _loadingStatus: LoadingStatus;
  readonly body: JSX.Element;
}

class VizelLCard extends React.Component<IVizelProps, IVizelLCardRState> {
  private _mounted: boolean = false;
  public readonly state: IVizelLCardRState;
  public constructor(props: IVizelProps) {
    super(props);
    const {subspace} = this.props;
    const { ls, ps} = subspace;
    let className = [];
    let _loadingStatus = LoadingStatus.UNDEFINED;
    className.push(this._getVizelType());
    className = this._syncLoadingStatus(className, _loadingStatus);

    this.state = {
      _location: ls ? oneEntity(ls) : null,
      _period: ps ? oneEntity(ps) : null,
      _periods: ps,
      _card: null,
      className,
      _loadingStatus,
      body: null,
    };

    this._applyData();
    UrlState.subscribeUpdatesAndNotify(this._disableVizel);
  }

  public componentDidMount() {
    this._mounted = true;
  }

  public componentWillUnmount() {
    UrlState.unsubscribe(this._disableVizel);
  }

  public async setAxes(subspace: ISubspace): Promise<any> {
    const {ms, ls, ps} = subspace;
    this.setState({
      _location: ls ? oneEntity(ls) : null,
      _period: ps ? oneEntity(ps) : null,
      _periods: ps,
    }, () => this._applyData());
  }

  protected _getVizelType(): string {
    const descr: IVizelDescription = parseVizelTypeString(this.props.cfg.getVizelType());
    return descr.type;
  }

  protected _syncLoadingStatus(arr: string [], loadingStatus: LoadingStatus): string [] {
    switch (loadingStatus) {
      case LoadingStatus.UNDEFINED:
        arr = arr.filter(item => !['no-data', 'loading', 'loadingFirstTime'].includes(item));
        break;
      case LoadingStatus.LOADING_FIRST_TIME:
        arr = arr.filter(item => !['no-data'].includes(item));
        arr.push('loading', 'loadingFirstTime');
        break;
      case LoadingStatus.LOADING:
        arr = arr.filter(item => !['no-data', 'loadingFirstTime'].includes(item));
        arr.push('loading');
        break;
      case LoadingStatus.NO_DATA:
        arr = arr.filter(item => !['loading', 'loadingFirstTime'].includes(item));
        arr.push('no-data');
        break;
      case LoadingStatus.HAS_DATA:
        arr = arr.filter(item => !['no-data', 'loading', 'loadingFirstTime'].includes(item));
        break;
    }
    return arr;
  }

  protected removeItem(array: any [], item: any): void {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  protected _disableVizel = () => {
    const {cfg } = this.props;
    let {className} = this.state;
    // feature "disabled vizel"
    const rawConfig: any = cfg.getRaw();
    if ('disabled' in rawConfig) {
      let disabled: any = rawConfig.disabled;

      const applyDisabled = (disabled: any) => {
        if (disabled) className.push('disabled');
        else className.push('disabled');
      };
      if (typeof disabled === 'string') {
        const lpeExpression: string = disabled;
        let wantSubscribeForUrl = false;
        const lpeResult: any = lpeRun(lpeExpression, {
          url: (key) => {
            wantSubscribeForUrl = true;                                                               // TODO subscribe only for key
            const model: any = urlState.getModel();
            return model[key] || '';
          },
        });
        applyDisabled(lpeResult);
      } else {                                                        // cfg.disabled not a string
        applyDisabled(disabled);
      }
    }
    if (this._mounted) this.setState({className});
    // todo  ????
    else this.state = {...this.state, className};
  }

  protected _noData(newNoDataStatus: boolean = true): void {
    let {className, _loadingStatus} = this.state;
    _loadingStatus = newNoDataStatus ? LoadingStatus.NO_DATA : LoadingStatus.HAS_DATA;
    className = this._syncLoadingStatus(className, _loadingStatus);
    this.setState({className, _loadingStatus});
  }

  private _onItemClick = (e: React.SyntheticEvent, field: ILocationCardField, m: IMetric): void => {
    const {cfg} = this.props;
    let {_location, _periods} = this.state;
    let command = field.config.onClick;

    if (String(command).toLowerCase().startsWith('navigate')) {
      const params = replaceNavigateWithSubstitutions(command, {                                     // replace %m %l %p
        m: m ? m.id : '',
        l: _location ? _location.id : '',
        // p: p ? p.id : '',
        // x: x ? x.id : '',
        // y: x ? y.id : '',
        // z: x ? z.id : '',
      });
      UrlState.navigate(params);
      return;
    }

    if (command === 'maximize') {
      //
      // THIS IS UGLY COPYPASTE FROM dash.js
      // PLEASE CONSIDER CREATING A CLASS FOR VARIOUS onClick ACTIONS
      //
      const dataset: IDatasetModel = cfg.getDataset();
      const m: IMetric = field.metric;

      import('./plot').then((PlotModule) => {
        const vizelConfig: IVizelConfig = dataset.createVizelConfig({}, 'compare-sort');
        // Странный хардкод цветов, убрал и добавил класс compare-sort
        // vizelConfig.colorResolver = {
        //   getColor: (e, v) => '#0000cc',
        //   getBgColor: (e, v) => '#0000cc',
        //   getColorPair: (e, v) => ({color: '#0000cc', bgColor: '#0000cc'}),
        // };

        // axis order might change in case of tree-based text vizel
        // currently no vizel accepts many zs, so simplify as:
        const subspace: ISubspace = bi.createSimpleSubspace(_periods, [m], [_location]);
        const vzl: IVizel = new PlotModule.default({dp: dataset.getDataProvider(), cfg: vizelConfig, subspace});

        import('../modal-container').then((modalContainerModule: any) => {
          modalContainerModule.modalContainer.push(vzl, _location.title + ' / ' + m.title);
        });
      });
    }
  }

  protected _loading(newLoadingStatus: boolean): void {
    let {className, _loadingStatus} = this.state;
    if (newLoadingStatus && _loadingStatus === LoadingStatus.UNDEFINED) {
      _loadingStatus = LoadingStatus.LOADING_FIRST_TIME;
      className = this._syncLoadingStatus(className, _loadingStatus);
      if (this._mounted) this.setState({className, _loadingStatus});
      // todo  ????
      else this.state = {...this.state, className, _loadingStatus };
      return;
    }

    if (newLoadingStatus && _loadingStatus === LoadingStatus.LOADING_FIRST_TIME) {
      // skip: state is loading
      if (this._mounted) this.setState({className, _loadingStatus});
      else this.state = {...this.state, className, _loadingStatus };
      return;
    }

    if (newLoadingStatus) {
      _loadingStatus = LoadingStatus.LOADING;
    } else {  // finished loading
      _loadingStatus = LoadingStatus.HAS_DATA;
    }
    className = this._syncLoadingStatus(className, _loadingStatus);
    if (this._mounted) this.setState({className, _loadingStatus});
    // todo  ????
    else this.state = {...this.state, className, _loadingStatus };
  }

  private _drawData = (): void => {
    this._loading(true);
    let {dp} = this.props;
    let {_location, _period } = this.state;
    let {_card} = this.state;
    const title = _card.title;
    let vec: IValue[];

    const ms: IMetric[] = _card.fields.map(f => f.metric).filter(m => m != null);

    dp.getVectorX(bi.createSimpleSubspace(ms, [_location], [_period]))
      .then((v: IValue[]) => {
        if (!v || v.length === 0) {
          this._noData();
          return null;
        }
        vec = v;
        return dp.getNorms(bi.createSimpleSubspace(ms, [_location], [_period]));
      })
      .then((normsResponse: INormsResponse) => {
        const items: JSX.Element[] = ms.map((m: IMetric, i: number) => {
          const sval: string = makeValue(vec[i], m.unit) || '-';        // TODO: must not be '-' on empty strings
          const isConfig = _card.fields[i].config && _card.fields[i].config.onClick;
          const color: string | null = normsResponse ? normsResponse.getColor(m, vec[i]) : null;

          return (
            <tr key={m.id}>
              <td className="name">{m.title + ':'}</td>
              <td className="value"
                  style={isConfig ? {textDecoration: 'underline', cursor: 'pointer', color} : null}
                  onClick={(event) => this._onItemClick(event, _card.fields[i], m)}
              >{sval}</td>
            </tr>
          );
        });

        const body = <div>
            <h2 className="VizelLCard__Header">{title}</h2>
            <table style={{width: '100%'}}>
              <tbody>
              {items}
              </tbody>
            </table>
          </div>;
        this.setState({body});
        this._loading(false);
      })
      .catch((e) => this._loading(false));
  }

  private async _applyData(): Promise<any> {
    const {cfg } = this.props;
    let {_period, _location, _card } = this.state;
    try {
      const rawCfg: any = cfg.getRaw();

      if (!_period || !_location || !(('cardId' in rawCfg) || _location.card)) {
        this._noData();
        return Promise.resolve();
      }

      const locationCards = cfg.getDataset().locationCards;

      if ('cardId' in rawCfg) {
        _card = $eid(locationCards, String(rawCfg.cardId));
      } else {
        _card = _location.card;
      }
      console.assert(!!_card);

      if (this._mounted) this.setState({_card}, () => this._drawData());
      else {
        // todo  ????
        this.state = {...this.state, _card};
        this._drawData();
      }

    } catch (err) {
      // this._error(...);
    }
  }

  public render() {
    const {className, body} = this.state;
    return (
      <div className={'VizelLCard Vizel view vizel plot leaflet-popup-content' + className.join(' ')}
        style={{width: '100%', height: '100%', minHeight: '30px'}}>
        {body}
      </div>
    );
  }
}


export default VizelLCard;
