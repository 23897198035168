import React, {createRef} from 'react';
import {ISearchVM, SearchVC} from '../../view-controllers/SearchVC';
import {BIIcon} from '../components/BIIcon/BIIcon';
import {lang} from '../../utils/utils';
import cn from 'classnames';
import {UrlState} from '@luxms/bi-core';
import {IUrl} from '@luxms/bi-core/dist/UrlState/UrlState';

interface IRootSearch {
  search: string;
  visible: boolean;
}

export class RootSearch extends React.Component<any, IRootSearch> {
  private readonly _searchVC: SearchVC;
  private readonly _urlState: UrlState;
  private readonly _inputRef: React.RefObject<HTMLInputElement>;

  public state: IRootSearch;

  public constructor(props) {
    super(props);
    this._searchVC = SearchVC.getInstance();
    this._urlState = UrlState.getInstance();
    this._inputRef = createRef();

    this.state = {search: null, visible: false};
  }

  public componentDidMount() {
    this._searchVC.subscribeUpdatesAndNotify(this._onUpdateModel);
    this._urlState.subscribeUpdatesAndNotify(this._onUrlUpdate);
  }

  public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IRootSearch>) {
    if (prevState.visible !== this.state.visible && this.state.visible === false) this._searchVC.setSearch(null);
    if (prevState.visible !== this.state.visible && this.state.visible === true) this._inputRef.current.focus();
  }

  public componentWillUnmount() {
    this._searchVC.unsubscribe(this._onUpdateModel);
    this._urlState.unsubscribe(this._onUrlUpdate);
  }

  private _onUpdateModel = (model: ISearchVM): void => {
    if (model.loading || model.error) return;
    this.setState({search: model.search});
  }
  private _onUrlUpdate = (model: IUrl): void => {
    if (model.loading || model.error) return;
    this.setState({visible: false});
  }

  public render() {
    const {search, visible} = this.state;
    return (
      <div className="Root__Search">
        <div className={cn('Root__Input', {visible: visible})}>
          <input ref={this._inputRef} placeholder={lang('lookup_filter_apply')} type="text" value={search ?? ''}
                 onChange={e => this._searchVC.setSearch(e.target.value)}/>
        </div>
        <BIIcon className="icon_search" icon="toolbar/search" onPress={() => this.setState({visible: !visible})}
                hint={lang('lookup_filter_apply')}/>
      </div>
    );
  }
}