
import React from 'react';
import cn from 'classnames';
import clone from 'lodash/clone';
import { UrlState, IUrl, IDisposable } from '@luxms/bi-core';
import { ModalContainer, modalContainer } from './modal-container';
import { DrilldownMenu } from './dd-menu';
import { IShellVM } from '../view-controllers/ShellVC';
import { mouseWatcher } from '../libs/MouseWatcher';
import { data_engine } from '../data-manip/data-manip';
import { IDatasetModel, IVizelConfig, IVizelProps } from '../services/ds/types';
import './Shell.scss';
import { ISubspace, ISubspacePtr, tables } from '../defs/bi';
import Vizel from './components/Vizel/Vizel';
import LoadFromResources from './components/LoadFromResources';
import CustomStyleLoader from './CustomStyleLoader';
import SpriteComponent from './components/SpriteComponent/SpriteComponent';
import IconSpriteLoader from './components/IconSpriteLoader/IconSpriteLoader';

const DlgAuth = React.lazy(() => import('./components/AuthDlg/AuthDlg'));
const Popup = React.lazy(() => import('./dialogs/Popup'));

const DsShell = React.lazy(() => import('./DsShell/DsShell'));
const AdmShell = React.lazy(() => import('./adm'));
const RootShell = React.lazy(() => import('./Root/Root'));


interface ILayerProps {
  zIndex: number;
}

class Layer extends React.Component<ILayerProps> {
  public state: {
    dialog: any;
  } = {
    dialog: null,
  };
  private _wrapperContainer: React.RefObject<HTMLDivElement> = React.createRef();

  public setDialog(dlg: any) {
    this.setState({ dialog: dlg });
  }

  private _close = () => {
    this.setState({ dialog: null });
  }

  private _onClickWrapper = (event) => {
    if (event.target === this._wrapperContainer.current) {
      this.setState({ dialog: null });
    }
  }

  public render() {
    const {zIndex} = this.props;
    const {dialog} = this.state;

    if (!dialog) return null;

    return (
      <>
        <div className="ShellLayer__Shadow"
             style={{zIndex: zIndex}}
             onClick={this._close}></div>
        <div className="ShellLayer shell-layer-wrapper"
             ref={this._wrapperContainer}
             style={{zIndex: zIndex + 1}}
             onClick={this._onClickWrapper}>
          {dialog}
        </div>
      </>);
  }
}


interface IFlyout {
  vizel: IVizelProps;
  x: number;
  y: number;
}


interface IShellState {
  flyout: IFlyout | null;
  currentBookmark: any;
  SouthPanel: any;
  spriteVisible: boolean;
}


export class Shell extends React.Component<IShellVM, IShellState> {
  public state: IShellState = {
    flyout: null,
    currentBookmark: null,
    SouthPanel: null,
    spriteVisible: false,
  };
  public modalContainer: ModalContainer = null;
  public layer50: Layer = null;
  public drilldownMenu: DrilldownMenu | null = null;
  private _mounted: boolean = false;

  public constructor(props: IShellVM) {
    super(props);
    shell = this;                                                                                   // HACK
    (window as any).shell = shell;                                                                  // NOT HACK, debug

    this.drilldownMenu = new DrilldownMenu();
  }

  private _setupModalContainerRef = (ref: ModalContainer | null) => this.modalContainer = ref;
  private _setupLayer50Ref = (ref: Layer | null) => this.layer50 = ref;
  private _showSprite = () => this.setState({spriteVisible: true});

  private _activeModule: React.RefObject<any> = React.createRef();

  public componentDidMount(): void {
    this._mounted = true;


    window.onresize = () => {
      const activeModule = this._activeModule.current;
      if (activeModule && activeModule.resize) {
        try {
          activeModule.resize();
        } catch (err) {
          console.log('activeItem = ', activeModule);
          console.error(err);
          console.log(err.stack);
        }
      }
      if (this.modalContainer) {
        this.modalContainer.resize();
      }
    };
  }

  public componentWillUnmount() {
    this._mounted = false;
  }

  private _getModalVizels(): IVizelProps[] {
    if (!this.modalContainer) {
      return [];
    }
    return this.modalContainer.state.stack.map((ve) => ve.vizel).concat(this.modalContainer.state.vizel || []).filter(v => v != null);
  }

  public getCurrentContext(): any {
    const context: any = clone(UrlState.getModel());
    if (context.metrics == null || context.metrics.length == 0) {
      context.metrics = null;
    }

    const vzls: IVizelProps[] = this._getModalVizels();
    if (vzls.length) {
      context.modals = [];
      for (let vzl of vzls) {
        try {
          // ACHTUNG
          const { cfg, subspace} = vzl;

          const rawCfg: any = cfg.getRaw();
          if (subspace) {
            rawCfg.dataSource = clone(subspace.getRawConfig());
            const yAxisOrigin: string = rawCfg.dataSource.yAxis;        // 'metrics', 'locations', 'periods'

            const styleHash: any = {};
            for (let y of subspace.ys) {
              let styleHashEntry = {
                color: cfg.getColor(y) || y.color,
              };
              if (y.title != cfg.getTitle(y)) {
                styleHashEntry['title'] = cfg.getTitle(y);
              }
              styleHash[y.id] = styleHashEntry;
            }

            rawCfg.dataSource.style = {};
            rawCfg.dataSource.style[yAxisOrigin] = styleHash;
          }
          context.modals.push(rawCfg);
        } catch (err) {
          console.error(err);
        }
      }
    }

    return context;
  }

  public setFlyoutVizel(flyoutVizel: IVizelProps): void {
    if (flyoutVizel) {
      const mouseX = mouseWatcher.getMouseX();
      const mouseY = mouseWatcher.getMouseY();
      this.setState({ flyout: { vizel: flyoutVizel, x: mouseX, y: mouseY } });
    } else {
      this.setState({ flyout: null });
    }
  }

  public activateBookmark(slideModel: ISlideModel, isSilent: boolean = false, isReplaceUrl: boolean = false, additionalModel: IUrl = null): void {
    const ctx = slideModel.context;
    if (!ctx) {
      return;
    }

    const url: IUrl = {
      ...slideModel.context,
      ...additionalModel,
      segment: 'ds',
      segmentId: slideModel.context.segmentId || slideModel.context.dataset,
      slide: String(slideModel.id),
    };
    if (isSilent) {
      delete url.slide;
      url.displayMode = 'slide';
    }
    delete url.dataset;
    delete url.path;
    UrlState.getInstance().setModel(url, isReplaceUrl);

    const datasetId: string = ctx.segmentId || ctx.dataset;

    // modals
    // remove all modals
    if (this.modalContainer) {
      this.modalContainer.hide();
    }

    if (Array.isArray(ctx.modals) && ctx.modals.length) {
      // add modals
      let p: Promise<any> = Promise.resolve(null);
      let vizelItems: any[] = [];
      ctx.modals.forEach((modal) => {
        p = p.then((vizelItem: any) => {
          if (vizelItem) {
            vizelItems.push(vizelItem);
          }
          // TODO: populate datasetId to modal.dataSource if none is set
          return this._activateBookmarkModal(modal, datasetId);
        });
      });
      p.then((we: any) => {
        if (we) {
          vizelItems.push(we);
        }
        if (vizelItems.length) {
          const stack: any[] = vizelItems.slice(0, vizelItems.length - 1);
          const last: any = vizelItems[vizelItems.length - 1];
          modalContainer.setState({
            stack,
            vizel: last.vizel,
            title: last.title,
          });
        }
      });
    }
  }

  private async _activateBookmarkModal(modal: tables.IRawVizelConfig, defaultDatasetId: string): Promise<{vizel: IVizelProps, title: string}> {
    const service = await import('../services/service');
    const vizelConfig: IVizelConfig = await service.createVizelConfig(modal, defaultDatasetId);
    const dataset: IDatasetModel = vizelConfig.getDataset();
    const subspacePtr: ISubspacePtr = vizelConfig.getSubspacePtr();
    const dataProvider: data_engine.IDataProvider = dataset.getDataProvider();
    const { createSubspaceGenerator } = await import('../services/ds/createSubspaceGenerator');

    return new Promise<{ vizel: IVizelProps, title: string }>((resolve, reject) => {
      let subscription: IDisposable = createSubspaceGenerator(
        dataset.schema_name,
        subspacePtr,
        false,
        (subspace: ISubspace) => {
          subscription.dispose();
          subscription = null;
          resolve({
            vizel: {
              dp: dataProvider,
              cfg: vizelConfig,
              subspace,
            },
            title: vizelConfig.title || '',
          });
        });
    });
  }

  public setSouthPanel(SouthPanel: any) {
    this.setState({SouthPanel});
  }

  public render() {
    const { authenticated, segment, popup, error, loading } = this.props;
    const { flyout, SouthPanel } = this.state;

    let SegmentModule: any = null;

    // find out segment module
    if (!error && !loading && authenticated && segment) {
      if (segment.viewClassId === 'DsShell') SegmentModule = DsShell;
      else if (segment && segment.viewClassId === 'AdmShell') SegmentModule = AdmShell;
      else if (segment && segment.viewClassId === 'Root/Root') SegmentModule = RootShell;
    }

    return (
        <section className="Shell view shell">
          <IconSpriteLoader />
          <SpriteComponent visible={this.state.spriteVisible} onHide={() => this.setState({spriteVisible: false})} />

          {!!error &&
          <div className="Shell__Error error-background">
            <img className="Shell__ErrorIcon" src="assets/icons/bug.svg" />
            <br/>
            <span>{error}</span>
          </div>}

          <CustomStyleLoader/>

          {/* ☰ menu */}

          {!error && !loading && !authenticated &&
          <React.Suspense fallback={null}>
            <LoadFromResources path="DlgAuth.js">
              <DlgAuth />
            </LoadFromResources>
          </React.Suspense>}

          {!!popup &&
          <React.Suspense fallback={null}>
            <Popup {...popup}/>
          </React.Suspense>}

          {/* modal container */}
          <ModalContainer ref={this._setupModalContainerRef}/>

          <Layer zIndex={50} ref={this._setupLayer50Ref}/>

          {/* flyout vizel */}
          {!!flyout &&
          <div className="FlyoutVizel flyout-vizel popover fade bottom in" role="tooltip"
               style={{left: flyout.x, top: flyout.y}}>
            <div className="arrow" style={{zIndex: 2}}/>
            <div className="popover-content contextMenu">
              <Vizel {...flyout.vizel}/>
            </div>
          </div>}

          <main id="content"
                className={SouthPanel ? 'f_slideshow-pane-active' : ''} >

            {!!loading &&
            <img className="main-loading-image" src="assets/logo/logo-animated.svg"/>}

            {!!SegmentModule &&
            <>
              <React.Suspense fallback={null}>
                <SegmentModule ref={this._activeModule} {...segment}/>
              </React.Suspense>

              {SouthPanel}
            </>}

          </main>
          <footer></footer>
        </section>);
  }
}


// HACK
export let shell: Shell = null;
export function getShell(): Shell {
  return shell;
}

export default shell;
