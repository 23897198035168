exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.VizelLCard{overflow-y:auto !important;padding:1rem;max-height:60vh;color:var(--color1)}.VizelLCard__Header{margin-top:10px;margin-bottom:5px}.leaflet-popup-close-button{z-index:1}.leaflet-popup-content{margin:0}.leaflet-popup-content-wrapper{border-radius:.5rem !important;color:#333}.leaflet-popup-content-wrapper .leaflet-popup-content{margin:10px !important}.leaflet-popup-content-wrapper h2{font-size:18px;line-height:18px;margin:0 10px;text-align:center}.leaflet-popup-content-wrapper hr{margin:3px}.leaflet-popup-content-wrapper .name{text-align:right;font-weight:bold;font-size:11px;line-height:13px;padding-right:5px;vertical-align:middle;padding-bottom:3px}.leaflet-popup-content-wrapper .value{font-size:11px;line-height:12px;text-align:left;vertical-align:bottom;padding-bottom:3px}", ""]);

// exports
exports.locals = {
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};