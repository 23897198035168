import React, {useEffect, useState} from 'react';
import memoize from 'lodash/memoize';

const loadIcon = memoize(async (path: string) => {
  const response = await fetch(path);
  return response.text();
});

// popa-popa-popa = popaPopaPopa
function camelCaseStyle(text: string): string {
  const a = text.toLowerCase()
    .replace(/[-\s]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
  return a.substring(0, 1).toLowerCase() + a.substring(1);
}

const SVGIcon = (props: { path: string, className?: string, [id: string]: any }) => {
  const {path} = props;
  const [content, setContent] = useState<any>(null);
  const [_path, setPath] = useState<any>(path);

  const makeSVG = (content) => {
    const el = document.createElement('div');
    el.innerHTML = content;
    const svg = Array.from(el.children).find(e => e.tagName === 'svg');
    if (!svg) return;
    let svgProps: any = {...props};
    Array.from(svg.attributes).forEach(({name, value}) => {
      if (name !== 'style') svgProps[name] = value;
    });
    svgProps.dangerouslySetInnerHTML = {__html: svg.innerHTML};
    // setContent(React.createElement('svg', svgProps));
    setContent(svgProps);
  };

  useEffect(() => {
    if (path.indexOf('<') != -1) {
      makeSVG(path);
    } else if (path.endsWith('.svg')) {
      loadIcon(path).then(content => {
        makeSVG(content);
      });
    } else {
      setPath(path.split('#').join(''));
      setContent('spriteIcon');
    }
  }, [path]);

  return content ?
            content === 'spriteIcon' ?
               <svg {...props}><use xlinkHref={'#' + path}></use></svg> :
               <svg data-path={path.replace(/^.*\/\.\.\//, '')} {...content} {...props}/> :
            null;
};

export default SVGIcon;
