import { BaseEntitiesService, IBaseEntities } from '@luxms/bi-core';
import { IRawLocationCards, LocationCardsRepository } from '../../repositories/LocationCardsRepository';


export class LocationCardsService extends BaseEntitiesService<IRawLocationCards> {
  public static readonly MODEL: IBaseEntities<IRawLocationCards>;

  public constructor(schema_name: string) {
    super(new LocationCardsRepository(schema_name));
  }
}