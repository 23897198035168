/**
 *
 *
 *
 *
 */

import React from 'react';
import { ProviderService, IProviderModel } from '../../services/ProviderService';
import { WpLoadingIcon } from '../components';
import { UrlState, IUrl, AuthenticationService, srv, AppConfig } from '@luxms/bi-core';
import { lang } from '../../utils/utils';
import { BIIcon } from '../components/BIIcon/BIIcon';
import {IRootSegment} from '../../plugins/plugins-model';
import './Provider.scss';
import useService from '../useService';

interface IProviderElementProps {
  activeTab: IRootSegment;
  tabs: IRootSegment[];
}


function SegmentHeader({activeTab, tabs, backHref}) {
  if (!activeTab) {
    return null;
  }
  return (
    <div className="Provider__SegmentHeader segment-header">
      {tabs.length > 1 &&
      <BIIcon href={backHref}
              className="black icon-shake effect-scale"
              icon="back"
              style={{
                width: 48,
                height: 48,
                border: '2px solid black',
                borderRadius: 48,
                marginRight: 48,
              }}/>}
      <span style={{fontSize: 22}}>{activeTab.title}</span>
    </div>);
}


const LogoutButton = ({onSignOut}) => (
  <section className="btn-logout"
           onClick={onSignOut}
           style={{cursor: 'pointer', display: 'flex', alignItems: 'center', paddingRight: '5px'}}>
    <BIIcon className="black" icon="logout" style={{width: 32, height: 32}}/>
    <span className="text">{lang('log_out')}</span>
  </section>);


const DocsButton = () => (
  <section onClick={() => window.location.href = 'docs/'}
           style={{cursor: 'pointer', display: 'flex', alignItems: 'center', paddingRight: '5px'}}>
    <BIIcon className="black" icon="docs" style={{width: 32, height: 32}}/>
    <span className="text">{lang('docs')}</span>
  </section>
);


const Logo = () => {
  const provider = useService<ProviderService>(ProviderService);
  const dsRes = useService<srv.ds.ResourcesService>(srv.ds.ResourcesService, 'ds_res');
  if (provider.error || provider.loading || dsRes.loading) return null;
  let image = provider.image;
  if (dsRes.find(r => r.alt_id === 'thumbnail.svg')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.svg');
  else if (dsRes.find(r => r.alt_id === 'thumbnail.png')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.png');

  return <img className="Provider__Logo provider-logo img-thumbnail" src={image}/>;
};


interface IProviderState extends IProviderModel {
  backHref: string;
  showDocsButton: boolean;
}

export default class Provider extends React.Component<IProviderElementProps, IProviderState> {
  public props: IProviderElementProps;
  public state: IProviderState;

  public constructor(props: IProviderElementProps) {
    super(props);
    this.state = {
      ...ProviderService.withLoading(),
      backHref: this._buildBackHref(),
      showDocsButton: false,
    };
    this._checkDocsButton();
  }

  public componentDidMount(): void {
    UrlState.subscribe('segment segmentId', this._onUrlUpdated);
    ProviderService.getInstance().subscribeUpdatesAndNotify(this._onProviderServiceUpdated);
  }

  public componentWillUnmount(): void {
    ProviderService.getInstance().unsubscribe(this._onProviderServiceUpdated);
    UrlState.unsubscribe(this._onUrlUpdated);
  }

  private _onUrlUpdated = () => {
    const newBackHref = this._buildBackHref();
    if (this.state.backHref !== newBackHref) {
      this.setState({backHref: newBackHref});
    }
  };

  private _onProviderServiceUpdated = (provider: IProviderModel) => {
    this.setState(provider);
  };

  private _checkDocsButton = () => {
    fetch(`docs/index.xml`)
      // .then((response) => this.setState({showDocsButton: response.status === 200}));
      .then(response => response.text())
      .then(str => {
        const parser = new DOMParser();
        const parsererrorNS = parser.parseFromString('INVALID', 'application/xml').getElementsByTagName('parsererror')[0].namespaceURI;
        const dom = (new DOMParser()).parseFromString(str, 'application/xml');
        this.setState({showDocsButton: dom.getElementsByTagNameNS(parsererrorNS, 'parsererror').length === 0});
      });
  }

  private _buildBackHref(): string {
    const appModel: IUrl = UrlState.getModel();
    if (appModel.segment === 'ds' && appModel.segmentId && appModel.segmentId[0] === '@') {
      // TODO: should find parent of current topic and navigate to parent, not to the ds list
      return '#/ds/';
    } else {
      return '#/';
    }
  }

  private handleSignOut = async () => {
    AuthenticationService.signOut();
    window.location.hash = '#';
  };

  private _renderLoading(): JSX.Element {
    return (
      <aside className="provider" style={{display: 'flex'}}>
        <div>
           <span className="magic-center"
                 style={{width: '200px'}}>
            <WpLoadingIcon/>
          </span>
        </div>
        <LogoutButton onSignOut={this.handleSignOut}/>
      </aside>);
  }

  public render(): JSX.Element {
    const {activeTab, tabs} = this.props;
    const {loading, error, title, backHref, showDocsButton} = this.state;

    if (error) {
      return null;    // TODO: handle errors
    }

    if (loading) {
      return this._renderLoading();
    }

    const isShowActiveTabTitle: boolean = !!activeTab && tabs.length > 2;

    return (
      <aside className="Provider provider" style={{display: 'flex'}}>
        <Logo/>

        <div className="Provider__Body">
          {!isShowActiveTabTitle && <h3 className="Provider__Title Provider_Title" style={{flexGrow: 1}}>{title}</h3>}
          {isShowActiveTabTitle  && <SegmentHeader activeTab={activeTab} tabs={tabs} backHref={backHref}/>}
        </div>
        <div className="Provider__Navigation navigation">
          <LogoutButton onSignOut={this.handleSignOut}/>
          {showDocsButton &&
            <DocsButton></DocsButton>
          }
        </div>
      </aside>);
  }
}
