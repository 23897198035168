import React from 'react';
import './InputTooltip.scss';
import {Dropdown} from '@luxms/bi-face';

interface IInputTest {
  type: string;
  value: string | number;
  onChange: (val: string) => void;
  className?: string;
  onKeyDown?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  regexp?: RegExp;
  warningText?: string;
  autoFocus?: boolean;
}

export class InputTooltip extends React.Component<IInputTest, { error: boolean }> {
  public state = {error: false};

  public constructor(props) {
    super(props);
  }

  private _onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const {regexp} = this.props;
    const value = e.target.value;
    if (!regexp || !value) {
      this.props.onChange(value);
      return;
    }

    const myTest = regexp.test(value);
    if (myTest) {
      this.props.onChange(value);
      this.setState({error: false});
    } else this.setState({error: true});

  }

  public render() {
    const {type, value, onBlur, onKeyDown, warningText, className, autoFocus} = this.props;
    return (
      <Dropdown.Trigger onClickOutside={() => null} visible={this.state.error} placement="top-start"
                        menu={warningText && <div className="inputTooltip">{warningText}</div>}>
        <input className={className}
               type={type}
               value={value ?? ''}
               onChange={this._onChange}
               autoFocus={autoFocus}
               onBlur={onBlur}
               onKeyDown={onKeyDown}
        />
      </Dropdown.Trigger>
    );
  }
}

export default InputTooltip;

