import React from 'react';
import { lang } from '../../utils/utils';
import './DsShellError.scss';

interface IDsShellError {
  error: string;
}

export class DsShellError extends React.Component<IDsShellError> {
  public render() {
    const {error} = this.props;
    return (
      <section className="DsShellError error">
        <span className="DsShell__ErrorMessage">{error}</span>
        <br/>
        <a href="#/ds/">{lang('datasets')}</a>
      </section>);
  }
}
