import { repo } from '@luxms/bi-core';

export interface IRawLocationCardFields {
  readonly id: number;
  readonly card_id: number;
  readonly metric_id: number;
  readonly text_id: string;
  readonly title: string;
  readonly srt: number;
  readonly config: any;
  readonly created: string;
  readonly updated: string;
}

export class LocationCardFieldsRepository extends repo.BaseRepository<IRawLocationCardFields> {
  public constructor(schema_name: string) {
    super(schema_name, 'location_cards');
  }
}