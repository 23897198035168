

import axios from 'axios';
import { AppConfig, UrlState } from '@luxms/bi-core';
import { DatasetService } from './ds/DatasetService';
import { IDatasetModel, IVizelConfig } from './ds/types';
import { tables } from '../defs/bi';

// DEBUG
(window as any).axios = axios;


// deprecated
export async function getBookmarks(): Promise<tables.IBookmark[]> {
  const url = AppConfig.fixRequestUrl('/api/bookmarks');
  return (await axios.get(url)).data;
}

// deprecated
export async function deleteBookmark(bookmark): Promise<any> {
  const url = AppConfig.fixRequestUrl(`/api/db/bm.bookmarks/${bookmark.id}`);
  return (await axios.delete(url)).data;
}


export async function createVizelConfig(rawVizelConfig: tables.IRawVizelConfig, defaultSchemaName?: string, view_class?: string): Promise<IVizelConfig> {
  const url = UrlState.getModel();
  if (!defaultSchemaName && url.segment === 'ds') {
    defaultSchemaName = url.segmentId;
  }
  const dataSource: tables.IDataSource = rawVizelConfig.dataSource || {};
  const schema_name: string = dataSource.dataset || defaultSchemaName || 'ds_default';              // ??? ds_default

  let dsService: DatasetService = null;

  try {
    dsService = DatasetService.createInstance(schema_name);
    await dsService.whenReady();
    const model = dsService.getModel();

    const dataset: IDatasetModel = model.dataset;
    return dataset.createVizelConfig(rawVizelConfig, view_class);

  } finally {
    if (dsService) {
      dsService.release();
      dsService = null;
    }
  }
}
