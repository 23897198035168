//
// ViewController
//
import isEqual from 'lodash/isEqual';
import { lang } from '../../utils/utils';
import { BaseService, urlState, IUrl, AuthenticationService, IAuthentication, AppConfig } from '@luxms/bi-core';
import { PluginsManager } from '../../plugins/plugins-manager';
import { IDatasetModel, IDsStateService, IDsState } from '../../services/ds/types';
import { IDsToolbarButton } from '../../plugins/plugins-model';
import { DsStateService1 } from '../../services/ds/DsStateService1';

function getNextChartType(currentChartType: string): string {
  switch (currentChartType) {
    case 'pies':
      return 'columns';
    case 'columns':
      return 'bars';
    case 'bars':
      return 'pies';
  }
  return '';
}


export interface IHrefButtonVM {
  viewClassId: 'MainToolbar/HrefButton';
  active: boolean;
  href: string;
  icon: string;
  title: string;
  visible: boolean;
  target?: string;
}


export interface IMainToolbarVM {
  viewClassId: 'MainToolbar/MainToolbar';
  loading: boolean;
  error: string;
  schemaName: string | null;
  trendsButton?: IHrefButtonVM;
  mapButton?: IHrefButtonVM;
  dashboardsButton?: IHrefButtonVM;
  skipMap?: boolean;
  skipTrends?: boolean;
  pluginButtons?: IDsToolbarButton[];
  userHomeEnabled?: boolean;
  toUserHomeUrl?: string;
  isAtHome?: boolean;
  route?: string;
  currentChartType?: string;
  nextChartType?: string;
  autoScale?: boolean;
  mapMetricsPanelVisible?: boolean;
  hasMapHomeIcon?: boolean;
  onToggleAutoscale?: () => void;
  onPressShowFullMap?: () => void;
  onToggleMapMetricsPanelVisible?: () => void;
  onToggleChartType?: () => void;
  hasTextSearch?: boolean;
  textSearch?: string;
}


interface IDepsModels {
  dsState: IDsState;
  url: IUrl;
  auth: IAuthentication;
}


export class MainToolbarVC extends BaseService<IMainToolbarVM> {
  private _dsStateService: IDsStateService;

  public constructor(schema_name: string) {
    super({
      viewClassId: 'MainToolbar/MainToolbar',
      loading: true,
      error: null,
      schemaName: null,
      trendsButton: null,
      mapButton: null,
      dashboardsButton: null,
      skipMap: true,
      skipTrends: true,
      pluginButtons: [],
      userHomeEnabled: false,
      toUserHomeUrl: '',
      isAtHome: false,
      route: '',
      currentChartType: '',
      nextChartType: '',
      autoScale: false,
      mapMetricsPanelVisible: false,
      hasMapHomeIcon: false,
      onToggleAutoscale: () => this._onToggleAutoscale(),
      onPressShowFullMap: () => this._onPressShowFullMap(),
      onToggleMapMetricsPanelVisible: () => this._onToggleMapMetricsPanelVisible(),
      onToggleChartType: () => this._onToggleChartType(),
      hasTextSearch: false,
    });

    this._addDependencies({
      dsState: (this._dsStateService = DsStateService1.createInstance(schema_name)),
      url: urlState.retain(),
      auth: AuthenticationService.getInstance().retain(),
    });

    // this._subscriptions.push(this._dsStateService.subscribe('route chartType autoscale mapMetricsPanelVisible', this._onXDepsUpdated));
  }

  private async _initPlugins(ds: IDatasetModel) {
    const pluginsManager: PluginsManager = PluginsManager.getInstance();
    await pluginsManager.whenReady();
    this._subscriptions.push(
      pluginsManager.subscribeDsToolbarButtons(
        ds,
        (pluginButtons: IDsToolbarButton[]) => { this._updateModel({pluginButtons}); },
        true));
  }

  private _pluginsInitialized: boolean = false;

  protected _onDepsReadyAndUpdated(depsModels: IDepsModels, prevDepsModels: IDepsModels) {
    const {auth, url, dsState} = depsModels;

    const ds: IDatasetModel = dsState.dataset;

    if (ds && !this._pluginsInitialized) {
      this._pluginsInitialized = true;
      this._initPlugins(ds);
    }

    const ch = ds.getConfigHelper();
    const dh = ds.dashletsHelper;
    const skipDashboards: boolean = !dh.dashboards.length;
    const skipMap: boolean = AppConfig.hasFeature('DisableMLP') || ch.getBoolValue('startup.hack.skipMap');
    const skipTrends: boolean = AppConfig.hasFeature('DisableMLP') || ch.getBoolValue('startup.hack.skipCharts');
    const hasTextSearch: boolean = ch.getBoolValue('panel.hasTextSearch');

    let dashboardsButton: IHrefButtonVM = null;
    if (!skipDashboards) {
      dashboardsButton = {
        viewClassId: 'MainToolbar/HrefButton',
        active: url.route == '#dashboards',
        href: urlState.buildUrl({route: '#dashboards', locations: url.locations.slice(-1)}),
        icon: 'toolbar/dashboards',
        title: lang('go_to_dashboards'),
        visible: true,
      };
      // оптимизация - сервис реагирует на любое urlState изменение
      if (JSON.stringify(dashboardsButton) === JSON.stringify(this._model.dashboardsButton)) dashboardsButton = this._model.dashboardsButton;
    }

    let mapButton: IHrefButtonVM = null;
    if (!skipMap) {
      mapButton = {
        viewClassId: 'MainToolbar/HrefButton',
        active: url.route === '#map',
        href: urlState.buildUrl({route: '#map'}),
        icon: 'toolbar/map',
        title: lang('go_to_map'),
        visible: true,
      };
      // оптимизация - сервис реагирует на любое urlState изменение
      if (JSON.stringify(mapButton) === JSON.stringify(this._model.mapButton)) mapButton = this._model.mapButton;
    }

    let trendsButton: IHrefButtonVM = null;
    if (!skipTrends) {
      trendsButton = {
        viewClassId: 'MainToolbar/HrefButton',
        active: url.route === '#trends',
        href: urlState.buildUrl({route: '#trends'}),
        icon: 'toolbar/trends',
        title: lang('go_to_charts'),
        visible: true,
      };
      // оптимизация - сервис реагирует на любое urlState изменение
      if (JSON.stringify(trendsButton) === JSON.stringify(this._model.trendsButton)) trendsButton = this._model.trendsButton;
    }

    let userHomeEnabled: boolean = false;
    let toUserHomeUrl: string = '';
    let isAtHome: boolean = false;
    if (auth.userConfig && !!auth.userConfig['home.location.uri']) {        // has user config for home
      userHomeEnabled = true;
      toUserHomeUrl = auth.userConfig['home.location.uri'];
      if (typeof toUserHomeUrl === 'object') {
        toUserHomeUrl = urlState.buildUrl(toUserHomeUrl);
      }
      const homeUrlObj = urlState.deserializeUrl(toUserHomeUrl);
      isAtHome = isEqual(homeUrlObj, url);
    }

    const hasMapHomeIcon: boolean = ch.hasValue('startup.map.center.latitude') && ch.hasValue('startup.map.center.longitude');

    const route = dsState ? dsState.route : '';
    const currentChartType = dsState ? dsState.chartType : 'columns';     // 'columns', 'bars', 'pies'
    const nextChartType = getNextChartType(currentChartType);
    const mapMetricsPanelVisible = dsState ? dsState.mapMetricsPanelVisible : false;
    const autoScale = dsState ? dsState.autoscale : false;

    this._updateModel({
      loading: false,
      error: null,
      schemaName: ds.schemaName,
      trendsButton,
      mapButton,
      dashboardsButton,
      skipMap,
      skipTrends,
      userHomeEnabled,
      toUserHomeUrl,
      isAtHome,
      route,
      currentChartType,
      nextChartType,
      autoScale,
      mapMetricsPanelVisible,
      hasMapHomeIcon,
      hasTextSearch,
    });
  }

  private _onToggleAutoscale() {
    const autoscale = this._dsStateService.getModel().autoscale;
    this._dsStateService.setAutoscale(!autoscale);
  }

  private _onPressShowFullMap() {
    const dataset = this._dsStateService.getModel().dataset;
    const ch = dataset.getConfigHelper();
    const lat: any = ch.getFloatValue('startup.map.center.latitude').toFixed(5);
    const lng: any = ch.getFloatValue('startup.map.center.longitude').toFixed(5);
    const zoom = ch.getIntValue('startup.map.zoomLevel', 11);
    this._dsStateService.setGeo({lat: lat, lng: lng, zoom: zoom});
    this._dsStateService.setCustomConfig({isButtonClicked: true});
  }

  private _onToggleMapMetricsPanelVisible() {
    const mapMetricsPanelVisible: boolean = this._dsStateService.getModel().mapMetricsPanelVisible;
    this._dsStateService.setMapMetricsPanelVisible(!mapMetricsPanelVisible);
  }

  private _onToggleChartType(): void {
    const currentChartType: string = this._dsStateService.getModel().chartType;
    const nextChartType: string = getNextChartType(currentChartType);
    this._dsStateService.setChartType(nextChartType);
  }
}

