/**
 *
 *
 *
 */
import { BaseService, disposeAll } from '@luxms/bi-core';
import { PanelLocationsVC } from './panels/PanelLocations/PanelLocationsVC';
import { PanelMetricsVC } from './panels/PanelMetrics/PanelMetricsVC';
import { PanelPeriodsVC } from './panels/PanelPeriods/PanelPeriodsVC';
import { IDatasetModel, IDsStateService, IDsState} from '../services/ds/types';
import { DsStateService1 } from '../services/ds/DsStateService1';


export interface IDsShellVM {
  viewClassId: 'DsShell';
  loading?: boolean;
  error?: string;
  id: string;
  schema_name: string;
  schemaName: string;
  eastPanel: any;
  westPanel: any;
  northPanel: any;
  eastPanelEnabled: boolean;
  westPanelEnabled: boolean;
  northPanelEnabled: boolean;
  datasetTitle: string;
  datasetDescriptionHTML: string;
  datasetUrl: string;
  route: string;                        // TODO: remove
  // children
  toggleEastPanel?: () => void;
  toggleWestPanel?: () => void;
  toggleNorthPanel?: () => void;
  dataset: IDatasetModel;
  state: IDsStateService;
}


interface IDepsModels {
  dsState: IDsState;
}


export class DsShellVC extends BaseService<IDsShellVM> {
  public id: string;
  private _dsStateService: DsStateService1;
  private _panelLocationsVC: PanelLocationsVC;
  private _panelMetricsVC: PanelMetricsVC;
  private _dataset: IDatasetModel;

  public constructor(dsId: string) {
    super({
      viewClassId: 'DsShell',
      loading: true,
      error: null,
      id: dsId,
      schemaName: dsId,
      schema_name: dsId,
      eastPanel: null,
      westPanel: null,
      northPanel: null,
      eastPanelEnabled: false,
      westPanelEnabled: false,
      northPanelEnabled: false,
      datasetTitle: '',
      datasetDescriptionHTML: '',
      datasetUrl: '',
      route: '',
      toggleEastPanel: () => this._onToggleEastPanel(),
      toggleWestPanel: () => this._onToggleWestPanel(),
      toggleNorthPanel: () => this._onToggleNorthPanel(),
      dataset: null,
      state: null,
      key: '',
    });
    this.id = dsId;

    this._addDependencies({
      dsState: (this._dsStateService = DsStateService1.createInstance(dsId)),
    });
  }

  protected _onDepsReadyAndUpdated(depsModels: IDepsModels, prevDepsModels: IDepsModels) {
    const {dsState} = depsModels;
    const prevDsState = prevDepsModels.dsState;
    const dataset: IDatasetModel = dsState.dataset;

    if (this._dataset && this._dataset !== dataset) {
      // Тут раньше стоят debugger, но мы решили, что это нормально
      // debugger;
    }

    this._dataset = dataset;

    if (typeof window !== 'undefined') {                      // DEBUG
      (window as any).dataset = dataset;
      (window as any).state = dsState;
    }

    const ch = dataset ? dataset.getConfigHelper() : null;
    // if (ch.getBoolValue('panel.metrics.hide') || ch.getBoolValue('panel.parameters.hide')) this._panelMetricsEnabled = false;
    // if (ch.getBoolValue('panel.locations.hide')) this._panelLocationsEnabled = false;
    // if (ch.getBoolValue('panel.periods.hide')) this._panelPeriodsEnabled = false;

    const datasetTitle: string = dsState.datasetTitle;
    const datasetDescriptionHTML: string = dsState.datasetDescriptionHTML;
    const datasetUrl: string = ch ? ch.getStringValue('webSiteUrl', null) : '';

    this._updateModel({
      error: null,
      loading: false,
      datasetTitle,
      datasetDescriptionHTML,
      datasetUrl,
      schemaName: dataset.schema_name,
      schema_name: dataset.schema_name,
      route: dsState.route,
      dataset: this._dataset,
      state: this._dsStateService,
      key: dataset.schema_name,
    });
  }

  private _onToggleEastPanel(): void {
    debugger;
  }

  private _onToggleWestPanel(): void {
    debugger;
  }

  private _onToggleNorthPanel(): void {
    debugger;
  }

  protected _dispose() {
    disposeAll(this._subscriptions);
    if (this._panelMetricsVC) {
      this._panelMetricsVC.release();
      this._panelMetricsVC = null;
    }
    if (this._panelLocationsVC) {
      this._panelLocationsVC.release();
      this._panelLocationsVC = null;
    }
    super._dispose();
  }
}
