/**
 *
 *
 */

import { BaseService, urlState, IUrl, AuthenticationService, IAuthentication } from '@luxms/bi-core';
import { IDsShellVM, DsShellVC } from './DsShellVC';
import { IRootVM, RootVC } from './Root/RootVC';
import { PopupVC, IPopupVM } from './dialogs/PopupVC';
import { AdmShellVC, IAdmShellVM } from './AdmShellVC';


export interface IShellVM {
  loading: boolean;
  error: string;
  authenticated: boolean;
  authError: string | null;
  isAuthenticationBlocked: boolean;
  popup?: IPopupVM;
  segment: IDsShellVM | IRootVM | IAdmShellVM;
}


interface IDepsModels {
  auth: IAuthentication;
  popup: IPopupVM;
  url: IUrl;
  segment: any;
}


export class ShellVC extends BaseService<IShellVM> {
  private _segmentVC: DsShellVC | RootVC | AdmShellVC = null;          // TODO: remove variable and use method getDep()

  public constructor() {
    super({
      loading: true,
      error: null,
      authenticated: false,
      authError: '',
      isAuthenticationBlocked: false,
      segment: null,
    });

    this._addDependencies({
      auth: AuthenticationService.getInstance().retain(),
      popup: PopupVC.getInstance().retain(),
      url: urlState.retain(),
    });
  }

  protected _onDepsUpdated(newDepsModels: IDepsModels, prevDepsModels: IDepsModels): boolean {
    const {auth, url, popup} = newDepsModels;
    const prevUrl = prevDepsModels.url;

    if (auth.loading) {
      this._updateWithLoading();
      return false;
    }

    if (!auth.authenticated) {
      this._removeDependency('segment', false);
      this._segmentVC = null;
      this._updateModel({
        authenticated: false,
        error: null,
        loading: false,
        segment: null,
      });
      return false;
    }

    if (url.segment === 'ds' && url.segmentId && url.segmentId[0] !== '@') {                  // dataset : dsShell
      if ((this._segmentVC instanceof DsShellVC) && (this._segmentVC.id === url.segmentId)) {
        // skip
      } else {
        this._segmentVC = new DsShellVC(url.segmentId);
        this._addDependency('segment', this._segmentVC, false);
      }
    // } else if (url.segment === 'adm') {
    //   if (this._segmentVC instanceof AdmShellVC) {
    //     // skip
    //   } else {
    //     this._segmentVC = new AdmShellVC();
    //     this._addDependency('segment', this._segmentVC, false);
    //   }
    } else {                                                            // any other segment: pass to Root module
      if (this._segmentVC instanceof RootVC) {
        // skip
      } else {
        this._segmentVC = new RootVC();
        this._addDependency('segment', this._segmentVC, false);
      }
    }

    const vmSegment: IDsShellVM | IRootVM | IAdmShellVM = this._segmentVC ? this._segmentVC.getModel() : null;

    this._updateModel({
      authenticated: true,
      error: null,
      loading: false,
      popup,
      segment: vmSegment,
    });
    return true;
  }
}


export default ShellVC;
