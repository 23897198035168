import React, { useEffect, useState } from 'react';
import './DlgAbout.scss';
import Logo from '../../../../srx/views/app/Menu/Logo';
import { Button } from '@luxms/bi-face';
import { lang } from '../../../utils/utils';
import axios from 'axios';
import { AppConfig } from '@luxms/bi-core';

interface IDlgAboutProp{
  readonly onModalResult?: (...arg: any) => void;
  readonly onModalCancel?: (...args: any) => any;
}

const DlgAbout: React.FC<IDlgAboutProp> = (props): JSX.Element => {

  const webVersion = process.env.VERSION;
  const [pgVersion, setPgVersion] = useState(null);

  useEffect(() => {
    axios.get(AppConfig.fixRequestUrl('/api/healthcheck')).then(response => {
      setPgVersion(response.data.core_version);
    });
  }, []);

  return (
    <dialog open className="DlgAbout">
      <header className="DlgAbout__Header">
        <Logo/>
      </header>
      <section className="DlgAbout__Body">
        {lang('components')}:
        <ul className="DlgAbout__Components">
          <li className="DlgAbout__Component">
            <span>luxmsbi-web</span>
            <span className="DlgAbout__ComponentVersion">{webVersion}</span>
          </li>
          <li className="DlgAbout__Component">
            <span>luxmsbi-pg</span>
            <span className="DlgAbout__ComponentVersion">{pgVersion}</span>
          </li>
        </ul>
      </section>
      <footer className="DlgAbout__Footer">
        <span className="DlgAbout__Powered">
          {lang('poweredBy')}
        </span>
        <Button onClick={props.onModalResult} size="lg" variant="primary">OK</Button>
      </footer>

    </dialog>);
};

export default DlgAbout;
