/// <reference path="../defs/bi.d.ts"/>

/**
 *
 *   ViewModel for drilldown menu (singleton)
 *
 *   public methods:
 *     show(dataset, event, z, y, x)
 *         event is mouse clickevent
 *
 *     hide()
 *
 */


'use strict';

import $ from 'jquery';
import axios from 'axios';
import {tracking} from '../services/tracking';
import {bi, IS_L, IS_M, IS_P, IS_PS, lang} from '../utils/utils';
import {httpPost} from '../repositories/http/data-storage';
import {PluginsManager} from '../plugins/plugins-manager';
import {getTextWidth} from './vizels/utility/c-utils';
import {fixColorPairEx} from '../config/ColorPair';
import {$eid, $esid} from '../libs/imdas/list';
import {data_engine} from '../data-manip/data-manip';
import {IDatasetModel, IVizelConfig, IVizelProps} from '../services/ds/types';
import {ContextMenu, IMenuItem} from './components/ContextMenu/ContextMenu';
import {AppConfig, RtService, UrlState} from '@luxms/bi-core';
import uniq from 'lodash/uniq';
import {OptionsProvider} from '../config/OptionsProvider';
import {
  IEntity,
  ILocation,
  IMetric,
  IMLP,
  IMLPV,
  IPeriod,
  IPreset,
  ISubspace,
  ISubspacePtr,
  ITag,
  IValue,
  IVCPV, tables,
} from '../defs/bi';
import {BIIcon} from './components/BIIcon/BIIcon';
import {cloneDeep} from 'lodash';
import IDataSource = tables.IDataSource;
import {KoobService} from '../services/koob/KoobService';
import { KoobSubspaceAsyncService } from '../services/koob/KoobSubspaceAsyncService';

const createSimpleSubspace = bi.createSimpleSubspace;
type IDataProvider = data_engine.IDataProvider;


enum State {
  HIDDEN = 0,
  PREPARING_TO_SHOW = 1,
  VISIBLE = 2,
}

let _state: State = State.HIDDEN;


function buildMenuTitle(langId: string, title1: string, title2: string): string {
  const longTitle: string = lang(langId + '_ex').replace('{0}', title1).replace('{1}', title2);
  const screenWidth: number = $('body').width();
  if (getTextWidth(longTitle) + 40 < screenWidth - 10) {
    return longTitle;
  }
  return lang(langId).replace('{0}', title1).replace('{1}', title2);                      // short title
}


// check mlp on different mixin
function checkDrillDownMixinIf(cfgMixinIf, m, l, p) {
  if (!cfgMixinIf) return true;                    // no option means any

  if (Array.isArray(cfgMixinIf.metrics) && cfgMixinIf.metrics.length && cfgMixinIf.metrics.map(String).indexOf(String(m.id)) === -1) return false;
  if (Array.isArray(cfgMixinIf.locations) && cfgMixinIf.locations.length && cfgMixinIf.locations.map(String).indexOf(l.id) === -1) return false;
  if (Array.isArray(cfgMixinIf.periods) && cfgMixinIf.periods.length && cfgMixinIf.periods.map(String).indexOf(p.id) === -1) return false;

  return true;
}

function sliceHierarchyKoob(datasourceHierarchy: string[], title: string): string[] {
  const result: string[] = [];
  datasourceHierarchy.forEach((h) => {
    const arrTitles = String(h).replace(/\s/g, '').split('=>');
    const idx = arrTitles.indexOf(title);
    if (idx !== -1) arrTitles.splice(idx, 1);
    const stringTitles = arrTitles.join('=>');
    if (stringTitles) result.push(stringTitles);
  });
  return result;
}

function makeNewAxis(dataSource: IDataSource, vcpv: IVCPV, dimensionId: string): IDataSource {
  const cloneDataSource = cloneDeep(dataSource);
  if (!cloneDataSource.filters) cloneDataSource.filters = {};
  if (Array.isArray(cloneDataSource.filters)) {
    const filters = {};
    cloneDataSource.filters.forEach((filter) => filters[filter] = true);
    cloneDataSource.filters = filters;
  }

  const {x, y, z} = vcpv;

  cloneDataSource.xAxis = dimensionId;
  cloneDataSource.yAxis = 'measures';
  cloneDataSource.dimensions = [dimensionId];

  const measures = [];
  [x, y, z].forEach((axis, i) => {
    axis.axisIds.forEach((axisId, k) => {
      const id = axis.ids[k];
      const dimFormula = axis.formula[k].replace(/\:.*/g, ''); // убрать id
      if (axisId === 'measures') {
        const formula = axis.formula[k];
        if (formula) measures.push(formula);
      } else cloneDataSource.filters[dimFormula] = ['=', id];
    });
  });

  cloneDataSource.measures = measures;


  return cloneDataSource;
}


export class MenuItem {
  protected _dataset: IDatasetModel;
  public title: string;
  public action: any;

  public constructor(dataset: IDatasetModel, title: string, action: any) {
    this._dataset = dataset;
    this.title = title;
    this.action = action;
  }

  // public render() {
  //   return (
  //     <li tabindex="1" data-bind="event: {mousedown: action, touchstart: action}">
  //       <a data-property="parameter" tabindex="-1" href="javascript:void(0)" data-bind="text: title"></a>
  //     </li>);
  // }
}


export class EditMenuItem extends MenuItem {
  private _val: IValue;
  private _m: IMetric;
  public isText: boolean;

  public constructor(dataset: IDatasetModel, mlpv: IMLPV, action: any) {
    super(dataset, mlpv.v != null ? String(mlpv.v) : '', action);
    const {m, l, p, v} = mlpv;
    this._m = m;
    this._val = v;
    this.isText = !!this._m.is_text_val;

    if (this._val === undefined) {
      // not passed from vizel: should load value
      const dp: data_engine.IDataProvider = dataset.getDataProvider();
      dp.getValue(createSimpleSubspace([p], [l], [m])).then((v: IValue) => {
        this._val = v;
        this.title = (v != null ? String(this._val) : '');                            // TODO!
      });
    }
  }

  public runAction(): void {
    const newVal: IValue = this.isText ? this.title : parseFloat(this.title);
    this.action(newVal);
  }

  public render = () => {
    return (
      <li style={{display: 'flex', alignItems: 'stretch', padding: '2px 20px', minWidth: 150}}>
        <input type="text" style={{flexGrow: 1}} data-bind="value:title"/>
        <BIIcon icon="yes" onPress={this.runAction}/>
      </li>);
  }
}


export class DrilldownMenu {
  public static instance: DrilldownMenu = null;

  public constructor() {
    console.assert(DrilldownMenu.instance === null);
    DrilldownMenu.instance = this;
  }

  public hide(): void {
    ContextMenu.hide();
    _state = State.HIDDEN;
  }

  public static hide() {
    ContextMenu.hide();
    _state = State.HIDDEN;
  }

  public static getModalContainer() {
    return DrilldownMenu._getModalContainer();
  }

  private static async _getModalContainer() {
    const modalContainerModule = await import('./modal-container');
    return modalContainerModule.modalContainer;
  }

  // create menu item for `table` feature: show lookup table
  private _createVCPActionLookupTableMenuItems(vcpAction, dataset: IDatasetModel, vcpv: IVCPV, isDeprecatedUrl: boolean = false): MenuItem[] {
    console.assert(vcpAction.src_type === 'table' || vcpAction.src_type === 'vcp-lookup-table' || vcpAction.src_type === 'lookup-table');

    const dp: IDataProvider = dataset.getDataProvider();
    const {x, y, z, v, m, l, p} = vcpv;

    return [new MenuItem(dataset, vcpAction.title, () => {
      DrilldownMenu.hide();
      DrilldownMenu._getModalContainer().then(modalContainer => {
        let options: string[] = ['LookupExternalFilter'];
        if (isDeprecatedUrl) {
          options.push('X-DeprecatedLookupUrl');
        }
        const cfg: IVizelConfig = dataset.createVizelConfig({
          view_class: 'lookup-table',
          lookupId: vcpAction.id,
          options,
        } as any);
        const subspace: ISubspace = bi.createSimpleSubspace([l], [m], [p]);
        const lookupTable: IVizelProps = {dp, cfg, subspace};
        modalContainer.push(lookupTable);
      });
    })];
  }

  // create menu item for `card` feature: show location card
  private _createVCPActionCardMenuItems(vcpAction, dataset: IDatasetModel, vcpv: IVCPV): MenuItem[] {
    console.assert(vcpAction.src_type === 'card');

    const dp: IDataProvider = dataset.getDataProvider();
    const {x, y, z, v, m, l, p} = vcpv;

    return [new MenuItem(dataset, vcpAction.title, (vm, evt) => {
      const cfg: IVizelConfig = dataset.createVizelConfig({
        view_class: 'lcard',
        title: l.title,
        cardId: vcpAction.id,
      });
      cfg.controller = {
        handleVCPClick: (event, vcpv: IVCPV) => this.show(dataset, event, vcpv),
      };
      DrilldownMenu._getModalContainer().then(modalContainer => {
        this.hide();
        const ps: IPeriod[] = dataset.getPeriodInfoByRange(null, p.id).periods;       // TODO: use Subspace object
        const subspace: ISubspace = bi.createSimpleSubspace([l], [m], ps);
        const cs: IVizelProps = {dp, cfg, subspace};
        modalContainer.push(cs, [l.title, p.title].join(' / '));
      });
    })];
  }

  // create menu item for `valueEdit` feature: edit value inplace
  private _createVCPActionValueEditMenuItems(vcpAction, dataset: IDatasetModel, vcpv: IVCPV): MenuItem[] {
    console.assert(vcpAction.src_type === 'valueEdit');

    const {x, y, z, v, m, l, p} = vcpv;

    return [new EditMenuItem(dataset, vcpv, (val: IValue) => {
      const payload: any[] = [{
        metric_id: m.id,
        loc_id: l.id,
        period_id: p.id,
        val,
      }];

      // const batchId: string = vcpAction.batch_id;
      // const url = AppConfig.fixRequestUrl(`/import/mlpv/${batchId}`);
      const url = AppConfig.fixRequestUrl('/' + vcpAction.path).replace(/\/\//g, '/');
      axios.post(url, payload).then(() => {
        this.hide();
        RtService.getInstance().onInternalDataUpdated(dataset.schema_name, payload);
      });
    })];
  }

  private _createVCPActionDrillDownMenuItems(vcpAction: any, dataset: IDatasetModel, vcpv: IVCPV): MenuItem[] {
    console.assert(vcpAction.src_type === 'drilldown');
    const payload: any = vcpAction.payload;

    if (!payload || payload.by !== 'periods') {
      console.warn(`vcp_actions: drilldown other then periods are not implemented`, vcpAction);
      return [];
    }

    const pids: (string | number)[] = payload.periods;
    let ps: IPeriod[] = $esid(dataset.periods, pids);

    const dataProvider = dataset.getDataProvider();
    const ch = dataset.getConfigHelper();
    const {x, y, z, v, m, l, p} = vcpv;

    const menuItemTitle = buildMenuTitle('drilldown_period', vcpAction.title, p.title);
    const vizelTitle = [l.title, m.title, p.title].join(' / ');
    const vTitle: string = m.unit && m.unit.axis_title || null;

    return [new MenuItem(dataset, menuItemTitle, async (vm, evt) => {
      const options: string[] = ['DisplayAllBadges', 'DisplayDataZoom'];
      const cfg: IVizelConfig = dataset.createVizelConfig({
        view_class: 'compare-sort',
        title: vizelTitle,
        display: {
          vAxis: {
            title: vTitle,
          },
        },
        options,
      });
      cfg.controller = {
        handleVCPClick: (event, vcpv: IVCPV) => this.show(dataset, event, vcpv),
      };

      const modalContainer = await DrilldownMenu._getModalContainer();

      cfg.getColor = () => null;
      cfg.getBgColor = () => null;

      this.hide();

      // cfg.getDisplay(cfg.getVizelType()).setSort('desc');     // TODO: add when implemented not only periods

      const subspace: ISubspace = bi.createSimpleSubspace(ps, [m], [l]);
      const cs = {dp: dataProvider, cfg, subspace};
      modalContainer.push(cs, vizelTitle);
      tracking.onDrilldown(dataset.id);
    })];
  }

  // create menu items for one vcpAction
  private _createVCPActionMenuItems(vcpAction, dataset: IDatasetModel, vcpv: IVCPV): MenuItem[] {
    const deprecatedDrillDownByPeriods = AppConfig.hasFeature('DeprecatedClientDrillDownByPeriods');

    switch (vcpAction.src_type) {
      case 'table':
      case 'lookup-table':
      case 'vcp-lookup-table':
        return this._createVCPActionLookupTableMenuItems(vcpAction, dataset, vcpv, vcpAction.src_type === 'table');

      case 'card':
        return this._createVCPActionCardMenuItems(vcpAction, dataset, vcpv);

      case 'valueEdit':
      case 'value-edit':
        return this._createVCPActionValueEditMenuItems(vcpAction, dataset, vcpv);

      case 'drilldown':
        if (!deprecatedDrillDownByPeriods) {
          return this._createVCPActionDrillDownMenuItems(vcpAction, dataset, vcpv);
        }
        break;
    }
    return [];
  }

  private async _createVCPActionKoob(dataset: IDatasetModel, event: any, vcpv: IVCPV, subspacePtr: ISubspacePtr, config?: IVizelConfig, ...customItems: IMenuItem[]): Promise<any> {
    const hierarchy = subspacePtr.dataSource?.hierarchy || [];

    if ((!Array.isArray(hierarchy) || hierarchy.length === 0) && !(config?.onClickDataPoint && Array.isArray(config.onClickDataPoint))) {
      this.hide();
      return;
    }
    const dp: IDataProvider = dataset.getDataProvider();

    const koob = subspacePtr.dataSource.koob;
    const koobService = KoobService.createInstance(koob);
    await koobService.whenReady();
    const dimensions = koobService.getModel().dimensions || [];

    let menuItems: IMenuItem[] = [];
    let vTitle: any = config.getTitle(vcpv.x);
    const addDrillDownMenuItem = (id: string, modalTitle: string, drillVCP: IVCPV, menuItemClickConfig?) => {
      const dimension = dimensions.find(d => d.id === id);
      const menuTitle = dimension?.title || id;
      menuItems.push(new MenuItem(dataset, menuTitle, async () => {
        if (menuItemClickConfig) {
          const urlInstance = UrlState.getInstance();
          let modelToUpdate = {};
          if (menuItemClickConfig.hasOwnProperty('filters')) {
            let filters = urlInstance.getModel()['_koobFilters'] ?? {};
            console.log(drillVCP);
            Object.keys(menuItemClickConfig['filters']).map(dimension => {
              let arr: Array<string | number> | undefined = Array.isArray(menuItemClickConfig['filters'][dimension]) ? menuItemClickConfig['filters'][dimension] : [];
              if (drillVCP?.filters && drillVCP.filters[dimension]) {
                filters = {...filters, [dimension]: drillVCP.filters[dimension]};
              } else {
                if (!Array.isArray(menuItemClickConfig['filters'][dimension])) {
                  let dimIndex = -1;
                  if (drillVCP.x.axisIds.indexOf(dimension) != -1) {
                    dimIndex = drillVCP.x.axisIds.indexOf(dimension);
                    arr.push(drillVCP.x.ids[dimIndex]);
                  } else if (drillVCP.y.axisIds.indexOf(dimension) != -1) {
                    dimIndex = drillVCP.y.axisIds.indexOf(dimension);
                    arr.push(drillVCP.y.ids[dimIndex]);
                  }
                }
                if (arr && !['=', '!=', 'BETWEEN', 'between'].includes(arr[0])) arr.unshift('=');
                if (arr.length > 1) {
                  filters = {...filters, [dimension]: arr};
                }
              }
            });
            modelToUpdate['_koobFilters'] = filters;
          }
          if (menuItemClickConfig.hasOwnProperty('navigate')) {
            Object.keys(menuItemClickConfig['navigate']).map(key => {
              modelToUpdate[key] = menuItemClickConfig['navigate'][key];
            });
          }
          urlInstance.updateModel(modelToUpdate);
        } else {
          const view_class = 'compare-sort'; // TODO только compare-sort ??
          const options: string[] = ['DisplayAllBadges', 'DisplayDataZoom'];

          // обрезаю первую иерархию
          const hierarchy = sliceHierarchyKoob(subspacePtr.dataSource.hierarchy, id);
          const newDataSource = makeNewAxis(subspacePtr.dataSource, drillVCP, id);
          const onClickDataPoint = config?.getRaw()?.onClickDataPoint;

          const cfg: IVizelConfig = dataset.createVizelConfig({
            view_class,
            title: modalTitle,
            display: {
              vAxis: {
                title: vTitle,
              },
            },
            options,
            dataSource: {...newDataSource, hierarchy},
            // onClickDataPoint, // т.к. вынесли в отдельное меню  onClickDataPoint && hierarchy
          });

          const schemaName = cfg.dataset.schema_name;

          const modalContainer = await DrilldownMenu._getModalContainer();
          const originalGetTitle = cfg.getTitle;

          cfg.getColor = () => null;
          cfg.getBgColor = () => null;

          this.hide();

          const subspaceSrv = new KoobSubspaceAsyncService(schemaName, cfg.getSubspacePtr());
          await subspaceSrv.whenReady();
          const subspace = subspaceSrv.getModel().subspace;

          const cs = {dp: dp, cfg, subspace};
          modalContainer.push(cs, modalTitle);
        }
      }));
    };    // addDrillDownMenuItem function

    for (let i = 0; i < hierarchy.length; i++) {
      // убираю пробелы и обрезаю всё до "=>"
      const id = String(hierarchy[i]).replace(/\s/g, '').split('=>')[0];
      const titleId = $eid(dimensions, id)?.title;
      const title = vTitle + `/${titleId || id}`;
      addDrillDownMenuItem(id, title, vcpv);
    }
    if (config?.onClickDataPoint && Array.isArray(config.onClickDataPoint)) {
      // [EP] Я удалил этот код, тут надо концепцию пересмотреть
      // Массив в onClickDataPoint должен означать lpe выражение
      // которое соберет уже меню итемы
      // ["showDrilldownMenu", ["navigate", "В даль" {...}],

      //
      // config.onClickDataPoint.map(action => {
      //   if (typeof action !== 'string') {
      //     const actionConfig = {};
      //     if (action.navigate) {
      //       actionConfig['navigate'] = action.navigate;
      //     }
      //     if (action.filters) {
      //       actionConfig['filters'] = action.filters;
      //     }
      //     addDrillDownMenuItem(action.title || '', '', vcpv, actionConfig);
      //   }
      // });
    }
    if (menuItems.length) ContextMenu.show(event, menuItems, {arrow: true, placement: 'bottom'});

    if (customItems.length) {
      Promise.all(customItems).then(customItems => {
        customItems = customItems.filter(Boolean);
        if (customItems.length) {
          menuItems = menuItems.concat(customItems);
          ContextMenu.show(event, menuItems, {arrow: true, placement: 'bottom'});
          _state = State.VISIBLE;
        }
      });
    }
  }


  private async _show(dataset: IDatasetModel, event: any, vcpv: IVCPV, subspacePtr?: ISubspacePtr, config?: IVizelConfig, ...customItems: IMenuItem[]): Promise<any> {
    const {x, y, z, v, m, l, p} = vcpv;
    let menuitems: IMenuItem[] = [];

    if (event === null) {
      this.hide();
      return;
    }
    // кубовые иерархии
    if (subspacePtr?.dataSource?.koob) {
      await this._createVCPActionKoob(dataset, event, vcpv, subspacePtr, config, ...customItems);
      return;
    } else if (!z || !y || !x || !m || !l || !p) {
      this.hide();
      return;
    }

    const dp: IDataProvider = dataset.getDataProvider();
    const ch = dataset.getConfigHelper();
    const vTitle: string = m.unit && m.unit.axis_title || null;


    const addDrillDownMenuItem = (title: string, modalTitle: string, z: IEntity, y: IEntity, x: IEntity, xs: IEntity[], menuItemClickConfig?) => {
      menuitems.push(new MenuItem(dataset, title, async (vm, evt) => {
        if (menuItemClickConfig) {
          const urlInstance = UrlState.getInstance();
          let modelToUpdate = {};
          if (menuItemClickConfig.hasOwnProperty('filters')) {
            let filters = urlInstance.getModel()['_koobFilters'] ?? {};
            Object.keys(menuItemClickConfig['filters']).map(dimension => {
              let arr: string[] | undefined = Array.isArray(menuItemClickConfig['filters'][dimension]) ? menuItemClickConfig['filters'][dimension] : [];
              if (arr && !['=', '!=', 'BETWEEN', 'between'].includes(arr[0])) arr.unshift('=');
              filters = {...filters, [dimension]: arr};
            });
            modelToUpdate['_koobFilters'] = filters;
          }
          if (menuItemClickConfig.hasOwnProperty('navigate')) {
            Object.keys(menuItemClickConfig['navigate']).map(key => {
              modelToUpdate[key] = menuItemClickConfig['navigate'][key];
            });
          }
          urlInstance.updateModel(modelToUpdate);
        } else {
          const view_class = (x && x.config && x.config.drillDownVizelType) ? x.config.drillDownVizelType : 'compare-sort';
          const options: string[] = ['DisplayAllBadges', 'DisplayDataZoom'];
          if (!IS_P(x) &&
            (ch.getBoolValue('trends.config.options.LegendFullTitle') || ch.getBoolValue('charts.config.options.LegendFullTitle'))) {
            options.push('LegendFullTitle');
          }
          const cfg: IVizelConfig = dataset.createVizelConfig({
            view_class,
            title: modalTitle,
            display: {
              vAxis: {
                title: vTitle,
              },
            },
            options,
          });
          cfg.controller = {
            handleVCPClick: (event, vcpv: IVCPV) => this.show(dataset, event, vcpv),
          };

          const modalContainer = await DrilldownMenu._getModalContainer();
          const originalGetTitle = cfg.getTitle;

          cfg.getColor = () => null;
          cfg.getBgColor = () => null;

          if (x && x.config && x.config.drillDown && x.config.drillDown.mixin) {
            const cfgMixin = x.config.drillDown.mixin;
            if (checkDrillDownMixinIf(cfgMixin.if, m, l, p) && Array.isArray(cfgMixin.include)) {
              const additionalXs = [], additionalCfgs = {};

              cfgMixin.include.forEach((cfgMixinInclude) => {
                let e: IEntity;
                if (cfgMixinInclude.id) {
                  if (IS_M(x)) e = $eid(dataset.metrics, cfgMixinInclude.id);
                  if (IS_L(x)) e = $eid(dataset.locations, cfgMixinInclude.id);
                  if (IS_P(x)) e = $eid(dataset.periods, cfgMixinInclude.id);
                } else {
                  e = x;                 // if id not specified use parent
                }
                if (!e) return;    // bad case. throw???

                additionalXs.push(e);
                additionalCfgs[e.id] = cfgMixinInclude;
              });

              xs = xs.slice(0).concat(additionalXs);

              const getColorPair = (e: IEntity, v?: IValue) => (e.id in additionalCfgs) ? fixColorPairEx(additionalCfgs[e.id]) : {
                color: null,
                bgColor: null,
              };
              cfg.colorResolver = {
                getColorPair,
                getColor: (e: IEntity, v?: IValue) => getColorPair(e, v).color,
                getBgColor: (e: IEntity, v?: IValue) => getColorPair(e, v).bgColor,
              };
              cfg.titleResolver = {
                getTitle: (e, v) => {
                  if (e.id in additionalCfgs && additionalCfgs[e.id].title) {
                    return additionalCfgs[e.id].title;
                  }
                  return originalGetTitle ? originalGetTitle.apply(cfg, [e, v]) : null;
                },
              };
            }
          }

          this.hide();

          if (!IS_PS(xs)) {                                     // when periods: do not sort
            cfg.getDisplay(cfg.getVizelType()).setSort('desc');
          }
          const subspace: ISubspace = bi.createSimpleSubspace(xs, [y], [z]);
          const cs = {dp: dp, cfg, subspace};
          modalContainer.push(cs, modalTitle);
        }
      }));
    };    // addDrillDownMenuItem function

    if (m.children.length) {
      const options: any = new OptionsProvider(m.config?.options);
      let showDrilldown = (options.hasOption('!Drilldown'));
      if (!showDrilldown) {
        addDrillDownMenuItem(
          buildMenuTitle('drilldown_metric', dataset.getConfigParameter('parameters.title'), m.title),
          [p.title, l.title, m.title].join(' / '),
          p, l, m, m.children);
      }
    }

    if (l.children.length) {
      const options: any = new OptionsProvider(l.config.options);
      let showDrilldown = (options.hasOption('!Drilldown'));
      if (!showDrilldown) {
        addDrillDownMenuItem(
          buildMenuTitle('drilldown_location', dataset.getConfigParameter('branches.l' + (l.tree_level + 1) + '.title'), l.title),
          [p.title, m.title, l.title].join(' / '),
          p, m, l, l.children);
      }
    }

    // drilldown by tags
    if (l.id.startsWith('AGGREGATE') && l.src_id) {
      for (let tagGroup of l.src_id.split(';')) {
        let tags = uniq(l.children.map(l => l.getTagByGroupId(tagGroup)));
        let tagLocations = tags.map(tag => {
          const title = tag.title;
          const children = l.children.filter(c => c.getTagByGroupId(tagGroup) === tag);                     // children only with specific tag
          const tagLocation = {
            axisId: 'locations',
            loc_id: -1,
            id: 'AGGREGATE_' + Math.random(),                                                               // TODO: compare AGGREGATE id by special code comparing its children
            parent_id: null,
            tree_level: 0,
            title,
            config: {},
            parent: null,
            children,
            spatials: [],
            card: null,
            is_point: 0,
            latitude: 0,
            longitude: 0,
            is_hidden: 0,
            srt: 0,
            src_id: l.src_id.split(';').filter(t => t !== tagGroup).join(';'),
            rawTags: [],
            addTag: (tag: ITag) => null,
            getTags: (): ITag[] => [],
            getTag: (id: string | number): ITag => null,
            getTagByGroupId: (tagGroupId: string): ITag => null,
            is_aggregator: true,
            getAltTitle: (titleType: string) => title,
          };
          return tagLocation;
        });
        addDrillDownMenuItem(
          'По тегу ' + tagGroup,
          [p.title, m.title, l.title].join(' / '),
          p, m, l, tagLocations);
      }
    }

    const deprecatedDrillDownByPeriods: boolean = AppConfig.hasFeature('DeprecatedClientDrillDownByPeriods');
    if (deprecatedDrillDownByPeriods && p.children.length) {
      addDrillDownMenuItem(
        buildMenuTitle('drilldown_period', dataset.getConfigParameter('datepicker_period' + (p.children.length ? p.children[0].period_type : 0)), p.title),
        [l.title, m.title, p.title].join(' / '),
        l, m, p, p.children);
    }

    if (m.config.drillJump) {
      m.config.drillJump.forEach((drillJumpItem) => {
        let ms: IMetric[] = [];
        if (drillJumpItem.presets) {
          const presetIds: string[] = drillJumpItem.presets;
          const presets: IPreset[] = $esid(dataset.presets, presetIds);
          const presetMetrics: IMetric[][] = presets.map((p: IPreset) => p.metrics);
          ms = Array.prototype.concat.apply([], presetMetrics);
        } else if (drillJumpItem.children) {
          ms = $esid(dataset.metrics, drillJumpItem.children);
        }
        addDrillDownMenuItem(
          drillJumpItem.title,
          [m.title, l.title, p.title].join(' / '),
          p, l, null, ms);
      });
    }

    if (l.config.drillJump) {
      l.config.drillJump.forEach((drillJumpItem) => {
        let ls: ILocation[] = $esid(dataset.locations, drillJumpItem.children);
        addDrillDownMenuItem(
          drillJumpItem.title,
          [l.title, m.title, p.title].join(' / '),
          p, m, null, ls);
      });
    }

    if (p.config.drillJump) {
      p.config.drillJump.forEach((drillJumpItem) => {
        let ps: IPeriod[] = $esid(dataset.periods, drillJumpItem.children);
        addDrillDownMenuItem(
          drillJumpItem.title,
          [p.title, l.title, m.title].join(' / '),
          l, m, null, ps);
      });
    }

    if (l.card) {
      menuitems.push(new MenuItem(dataset, lang('location-card'), (vm, evt) => {
        const cfg: IVizelConfig = dataset.createVizelConfig({
          view_class: 'lcard',
          title: l.title,
        });
        cfg.controller = {
          handleVCPClick: (event, vcpv: IVCPV) => this.show(dataset, event, vcpv),
        };
        DrilldownMenu._getModalContainer().then(modalContainer => {
          this.hide();
          const ps: IPeriod[] = dataset.getPeriodInfoByRange(null, p.id).periods;       // TODO: use Subspace object
          const subspace: ISubspace = bi.createSimpleSubspace(ps, [m], [l]);
          const lcardCfg = cfg.clone();
          lcardCfg.setVizelType('lcard');

          modalContainer.push(
            {dp, cfg: lcardCfg, subspace},
            [l.title, p.title].join(' / '));
        });
        evt.stopPropagation();
      }));
    }


    if (config?.onClickDataPoint && Array.isArray(config.onClickDataPoint)) {

      // [EP] Я удалил этот код, тут надо концепцию пересмотреть
      // Массив в onClickDataPoint должен означать lpe выражение
      // которое соберет уже меню итемы
      // ["showDrilldownMenu", ["navigate", "В даль" {...}],

      // config.onClickDataPoint.map(action => {
      //   if (typeof action !== 'string') {
      //     const actionConfig = {};
      //     if (action.navigate) {
      //       actionConfig['navigate'] = action.navigate;
      //     }
      //     if (action.filters) {
      //       actionConfig['filters'] = action.filters;
      //     }
      //     addDrillDownMenuItem(action.title || '', '', null, null, null, [], actionConfig);
      //   }
      // });
    }

    const pluginsManager: PluginsManager = PluginsManager.getInstance();
    await pluginsManager.whenReady();

    menuitems = menuitems.concat(pluginsManager.getDrillDownMenuItems(this, dataset, vcpv));

    if (menuitems.length) {
      ContextMenu.show(event, menuitems, {arrow: true, placement: 'bottom'});

    } else {
      console.log('No drilldown menu for the point (will check lookup)');
      ContextMenu.hide();
      _state = State.HIDDEN;
    }

    const reqSubspaces: any = {
      version: '1.0',
      metrics: [m.id],
      locations: [l.id],
      periods: [p.id],
    };

    // load vcp action

    const url: string = AppConfig.fixRequestUrl(`/api/vcp/${dataset.id}/actions/`);
    const data: any = await httpPost<any>(url, reqSubspaces);
    const vcpActions: any[] = Array.isArray(data) ? data : data.sources;
    let additionalMenuItems: IMenuItem[] = [];
    for (let vcpAction of vcpActions) {
      additionalMenuItems = additionalMenuItems.concat(this._createVCPActionMenuItems(vcpAction, dataset, vcpv));
      additionalMenuItems = additionalMenuItems.concat(pluginsManager.getDrillDownMenuItems(this, dataset, vcpv, vcpAction));
    }
    if (additionalMenuItems.length) {
      menuitems = menuitems.concat(additionalMenuItems);
      ContextMenu.show(event, menuitems, {arrow: true, placement: 'bottom'});
      _state = State.VISIBLE;
    }
  }

  public show(datasetModel: IDatasetModel, event, vcpv: IVCPV, subspacePtr?: ISubspacePtr, cfg?: IVizelConfig, ...customItems: IMenuItem[]): void {
    if (_state === State.PREPARING_TO_SHOW) {
      // ignore clicks when showing
      return;
    }
    _state = State.PREPARING_TO_SHOW;

    window.setTimeout(() => {
      _state = State.VISIBLE;
      this._show(datasetModel, event, vcpv, subspacePtr, cfg, ...customItems);
    }, 55);
  }
}


export default DrilldownMenu;
