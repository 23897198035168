/**
 *
 * Vizel to display map
 *
 *
 * next: use react-leaflet
 *
 */

import * as React from 'react';
import {ClusterLayer} from '../map/clustering';
import {KoobAreaLayer} from '../map/KoobMF';
import {KoobClusterLayer} from '../map/koobclustering';
import { IDatasetModel, IVizelProps } from '../../services/ds/types';
import {AppConfig, repo} from '@luxms/bi-core';
import {
  IMapFill,
  IMapLayer,
  ISubspace,
} from '../../defs/bi';
import './VizelMap.scss';
import 'mapbox-gl-leaflet';
import {IMapFillLayerProps} from '../map/layers/mf';
import {ThemeVC} from '../../view-controllers/ThemeVC';
import VizelFromCfg from '../components/Vizel/VizelFromCfg';
import {DASHLET_MIME_TYPE} from '../../utils/utils';
import SVGIcon from '../components/SVGIcon';


let _leafletPromise: Promise<any> | null = null;
declare var L: any;

function initLeaflet(): Promise<any> {
  if (!_leafletPromise) {
    _leafletPromise = new Promise((resolve, reject) => {
      import('mapbox-gl-leaflet').then(
        (leafletCtor: any) => {
          L = (window as any).L;
          resolve(L);
        },
        reject);

    });
  }
  return _leafletPromise;
}

interface IVizelMapProps extends IVizelProps {
  onMapReady(map: L.Map);
  geo?: any;
  mf?: IMapFill;
  chartType?: string;
  editMode?: string;
  onEditDashlet?: (dashId: string) => void;
  dashId: number;
  dashlet?: any;
  onChangeDashlets?: (updated: repo.ds.IRawDashlet[], deleted?: number[]) => any;
}

interface IVizelMapRState {
  _ds: IDatasetModel;
  _cluster: any;
  mapLayers: any[];
  lastActiveMapLayer: IMapFillLayerProps;
  file: any;
  map: any;
  L: any;
  minZoom: number;
  maxZoom: number;
  lat: number;
  lng: number;
  zoom: number;
  mainLayer: L.Layer;
  settings: any;
  subspace: ISubspace;
  chartType?: string;
  pointsData: any[];
  theme?: any;
  activeLayers?: any;
  activeMenu?: any;
  customStyleForTiles?: string;
}

const BASIC_TILE_RASTER_URL: string = 'https://tiles.luxmsbi.com/mapstyle/{z}/{x}/{y}.png';

function readTextFile(file, callback) {
  const rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType('application/json');
  rawFile.open('GET', file, true);
  rawFile.onreadystatechange = () => {
    if (rawFile.readyState === 4 && String(rawFile.status) === '200') {
      callback(rawFile.responseText);
    }
  };
  rawFile.send(null);
}

export const LoadingIcon = (props: {color?: string;
} ) => {
  const fillColor = props.color || 'grey';
  return(
    <div className={'rotating'}><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 613.705 613.705" >
      <g>
        <path
          fill={fillColor}
          d="M594.389,274.302c-12.418-12.462-27.193-18.836-44.311-18.836s-31.555,6.087-43.981,18.478
			c-12.418,12.39-18.463,27.215-18.463,44.332c0,17.117,5.959,31.656,18.127,43.975c12.332,12.462,27.193,18.478,44.317,18.478
			s31.893-6.088,44.311-18.478c12.419-12.462,18.464-26.857,18.464-43.975C612.853,301.159,606.808,286.692,594.389,274.302z"/>
        <path
          fill={fillColor}
          d="M474.542,438.453c-16.114,0-29.543,5.371-40.623,16.472c-11.079,11.029-16.45,24.136-16.45,39.893
			c0,15.827,5.285,29.292,16.45,40.68c10.994,11.101,24.509,16.4,40.623,16.4c15.777,0,28.869-5.371,39.949-16.4
			c11.416-11.459,17.124-24.853,17.124-40.68c0-15.757-5.787-28.791-17.124-39.893C503.325,443.896,490.319,438.453,474.542,438.453
			z"/>
        <path
          fill={fillColor}
          d="M482.936,213.855c18.463,0,34.577-6.732,48.006-20.125c13.429-13.464,20.14-29.579,20.14-48.343
			c0-19.123-6.718-35.237-20.14-48.701c-13.092-13.106-29.206-19.767-48.006-19.767c-19.137,0-35.251,6.661-48.343,19.767
			c-13.429,13.464-20.14,29.579-20.14,48.701c0,18.764,6.718,34.878,20.14,48.343C448.021,207.123,464.136,213.855,482.936,213.855z
			"/>
        <path
          fill={fillColor}
          d="M297.284,511.289c-14.102,0-26.27,4.942-36.253,15.112c-9.819,9.955-14.768,22.13-14.768,36.597
			c0,14.109,4.956,26.284,14.768,35.882c9.991,9.883,22.159,14.825,36.253,14.825c14.438,0,26.435-5.157,36.254-14.825
			c9.99-9.812,15.104-21.772,15.104-35.882c0-14.467-5.035-26.856-15.104-36.955C323.805,516.303,311.723,511.289,297.284,511.289z"
        />
        <path
          fill={fillColor}
          d="M297.284,0c-20.476,0-37.937,7.09-52.368,21.486c-14.438,14.467-21.486,31.584-21.486,52.067
			c0,20.483,7.047,38.245,21.486,52.711c14.438,14.396,31.892,21.486,52.368,21.486c20.476,0,37.937-7.09,52.368-21.486
			c14.438-14.467,21.822-32.229,21.822-52.711s-7.384-37.6-21.822-52.067C335.221,7.09,317.76,0,297.284,0z"/>
        <path
          fill={fillColor}
          d="M120.363,179.263c9.067,0,17.447-3.008,24.171-9.74c13.436-13.393,13.436-35.237,0-48.629
			c-6.725-6.732-15.104-9.74-24.171-9.74c-8.73,0-17.002,3.08-23.835,9.74c-13.987,13.536-13.987,35.093,0,48.629
			C103.361,176.184,111.64,179.263,120.363,179.263z"/>
        <path
          fill={fillColor}
          d="M120.363,445.472c-12.418,0-22.997,4.87-32.229,13.822c-8.895,8.522-13.429,19.122-13.429,31.513
			c0,12.461,4.362,23.204,13.429,32.229c9.067,9.096,19.81,13.751,32.229,13.751c12.419,0,23.162-4.655,32.229-13.751
			c9.067-9.024,13.429-19.768,13.429-32.229c0-12.391-4.533-22.99-13.429-31.513C143.36,450.342,132.79,445.472,120.363,445.472z"/>
        <path
          fill={fillColor}
          d="M68.669,290.058c-7.721-7.735-16.788-11.745-27.867-11.745s-20.812,4.01-28.533,11.745S0.853,307.175,0.853,318.275
			c0,11.029,3.695,20.77,11.416,28.505c7.721,7.734,17.454,11.459,28.533,11.459s20.139-3.725,27.867-11.459
			c7.72-7.735,11.752-17.476,11.752-28.505C80.414,307.175,76.389,297.792,68.669,290.058z"/>
      </g>
    </svg></div>);
};


const LayerMenuButton = (props: any) => {
  const {active, rawCfg, index, activeCurrentMenu, title, editMode, deleteLayer, setLayerEdit, _toggleLayer, _toggleMenu, layer} = props;

  return(<div className={`LayerConfig__Button CustomButton ${editMode === 'builder' ? 'Button__EditMode' : ''}`} ref={layer?.button} style={{background: `${active === false ? 'lightgrey' : ''}`}}>
    <div className={'layerButtonIcon'}></div>
    <div className={`LayerConfig__Title `}  onClick={() => (editMode === 'builder') ? setLayerEdit(layer?.button, index) : _toggleLayer(index)}>{title}</div>
    {(editMode === 'builder') ? (<div className={'LayerConfig__Controls btn-context-menu closeButton'}>
      <SVGIcon onClick={(e) => deleteLayer(e, (rawCfg.id || index))} path={'../../../assets/icons/x.svg'} />
    </div>) : (<div className={'LayerConfig__Controls btn-context-menu'} onClick={(e) => _toggleMenu(index)}>
      <svg viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z" />
        <path d="M4 10C4 11.1046 3.10457 12 2 12C0.89543 12 0 11.1046 0 10C0 8.89543 0.89543 8 2 8C3.10457 8 4 8.89543 4 10Z" />
      </svg>
    </div>)}
    <div className={`LayerConfig__Dropdown ${activeCurrentMenu ? 'ActiveDropdown' : ''}`}></div>
  </div>);
};

/*
* config
* osmVectorEnable: false / true,
* osmUrlTemplate: 'http://vectortiles.spb.luxms.com/styles/basic-preview/style.json',
* colorLayers Object with layer colors.
* */

class VizelMap extends React.Component<IVizelMapProps, IVizelMapRState> {
  private L: any = null;
  private _leafletLayer = L.layerGroup([]);
  private mapContainer: HTMLElement = null;
  private _mounted: boolean = false;

  private _ref: RefObject<any> = React.createRef();
  private _refLayersWr: RefObject<any> = React.createRef();
  private parent: RefObject<any> = React.createRef();
  private _menuRef: RefObject<any> = React.createRef();
  private _bottomRight: RefObject<any> = React.createRef();
  private _byConfig = false;

  constructor(props: IVizelMapProps) {
    super(props);
    const {cfg, subspace} = props;
    const _ds = cfg.getDataset();

    const display: any = cfg.getDisplay();

    const cfgRaw = cfg.getRaw();

    const minZoom: number = ('minZoom' in display) ? display.minZoom : AppConfig.getModel().map.minZoom;
    const maxZoom: number = ('maxZoom' in display) ? display.maxZoom : AppConfig.getModel().map.maxZoom;

    const lat: number = (display?.geo?.lat) ? display?.geo?.lat : ('lat' in display) ? display.lat : (cfgRaw.geo && cfgRaw.geo.lat) ? cfgRaw.geo.lat : 0;
    const lng: number = (display?.geo?.lng) ? display?.geo?.lng : ('lng' in display) ? display.lng : (cfgRaw.geo && cfgRaw.geo.lng) ? cfgRaw.geo.lng : 0;
    const zoom: number = (display?.geo?.zoom) ? display?.geo?.zoom : ('zoom' in display) ? display.zoom : (cfgRaw.geo && cfgRaw.geo.zoom) ? cfgRaw.geo.zoom : 0;

    this.state = {
      _ds,
      _cluster: null,
      mapLayers: [],
      lastActiveMapLayer: null,
      file: null,
      map: null,
      L: null,
      minZoom: minZoom,
      maxZoom: maxZoom,
      lat: lat,
      lng: lng,
      zoom: zoom,
      mainLayer: null,
      settings: AppConfig.getModel().map,
      subspace: null,
      chartType: props.chartType,
      pointsData: [],
      theme: null,
      activeLayers: {},
      activeMenu: {},
      customStyleForTiles: null,
    };

    this.initMap = this.initMap.bind(this);

    if (subspace?.koob) this._byConfig = true;
  }

  public componentDidMount() {
    this._mounted = true;
    this._setL().then(() => {
      this.initMap();
    });

    ThemeVC.getInstance().subscribeUpdatesAndNotify(this._onThemeVCUpdated);
  }

  public componentWillUnmount() {
    ThemeVC.getInstance().unsubscribe(this._onThemeVCUpdated);
  }

  private _setL = async () => {
    this.L = await initLeaflet();
  }

  private _onThemeVCUpdated = () => {
    this.setState(() => ({
      theme: ThemeVC.getInstance().getModel().currentTheme
    }));

  }

  private async initMap(): Promise<any> {
    const {map, zoom, minZoom, maxZoom, lng, lat} = this.state;
    if (!map) {

      this.mapContainer = this._ref.current ? this._ref.current : null;

      if (this.L) {
        const mapObj = this.L.map(this.mapContainer, {
          minZoom,
          maxZoom,
          center: [lat, lng],
          zoom,
          zoomAnimation: false,
        });

        this.setState({map: mapObj});
      }
    }
  }

  private mergeColorsInLayers = (layersArray, configHash) => {
    let newLayers = [...layersArray];

    if (configHash && Object.keys(configHash).length) {
      Object.keys(configHash).forEach((key: string) => {
        let item = newLayers.find((l) => l.id == key);

        if (item) {
          let paint = item.paint;

          if (item.type == 'background') {
            paint['background-color'] = configHash[key];
          }

          if (item.type == 'fill') {
            paint['fill-color'] = configHash[key];
          }

          if (item.type == 'line') {
            paint['line-color'] = configHash[key];
          }

          if (item.type == 'symbol') {
            paint['text-color'] = configHash[key];
          }
        }

      });
    }

    return newLayers;
  }
  private async setMainLayer(): Promise<any> {
    const {map, file, minZoom, maxZoom, theme} = this.state;
    const rawCfg = this.props.cfg?.getRaw();
    const geoCfg = rawCfg?.geo || {};

    if (map) {
      let mainMapLayer;
      let settings = AppConfig.getModel().map;

      if (settings.type == 'osm') {
        let vectorEnable = 'osmVectorEnable' in settings && !!settings.osmVectorEnable;
        if ('osmVectorEnable' in geoCfg) {
          vectorEnable = geoCfg?.osmVectorEnable;
        }

        let osmUrlTemplate = settings.osmUrlTemplate;

        if ('osmUrlTemplate' in geoCfg) {
          if (rawCfg && !!geoCfg?.osmUrlTemplate)
            osmUrlTemplate = geoCfg?.osmUrlTemplate;
          if (!osmUrlTemplate.startsWith('http')) {
            const origin = window.location.origin;

            osmUrlTemplate = `${origin}/${osmUrlTemplate}`;
          }
        }

        if (!!vectorEnable && !!osmUrlTemplate) {
          if (this.state.mainLayer) {
            map.removeLayer(this.state.mainLayer);
          }

          if (file) {
            let new_style = file;

            if (typeof new_style === 'string') new_style = JSON.parse(new_style);

            // file source
            let sources = new_style.source;
            let layers = new_style.layers;


            /*customize layers >>>>>>*/
            // layer types for config
            /*          "background"
                      "landuse-residential"
                      "landcover_grass"
                      "landcover_wood"
                      "water"
                      "water_intermittent"
                      "landcover-ice-shelf"
                      "landcover-glacier"
                      "landcover_sand"
                      "landuse"
                      "landuse_overlay_national_park"
                      "waterway-tunnel"
                      "waterway"
                      "waterway_intermittent"
                      "tunnel_railway_transit"
                      "building"
                      "housenumber"
                      "road_area_pier"
                      "road_pier"
                      "road_bridge_area"
                      "road_path"
                      "road_minor"
                      "tunnel_minor"
                      "tunnel_major"
                      "aeroway-area"
                      "aeroway-taxiway"
                      "aeroway-runway"
                      "road_trunk_primary"
                      "road_secondary_tertiary"
                      "road_major_motorway"
                      "railway-transit"
                      "railway"
                      "waterway-bridge-case"
                      "waterway-bridge"
                      "bridge_minor case"
                      "bridge_major case"
                      "bridge_minor"
                      "bridge_major"
                      "admin_sub"
                      "admin_country"
                      "poi_label"
                      "airport-label"
                      "road_major_label"
                      "place_label_other"
                      "place_label_city"
                      "country_label-other"
                      "country_label"*/

            let configColors = (settings) ?  settings.colorLayers : null;
            let themeColors = (theme && theme?.map) ? theme.map : null;

            if (themeColors && Object.keys(themeColors).length) {
              layers = this.mergeColorsInLayers(layers, themeColors);
            }

            if (configColors && Object.keys(configColors).length) {
              layers = this.mergeColorsInLayers(layers, configColors);
            }

            /*customize layers >>*/

            mainMapLayer = this.L.mapboxGL({
              style: new_style,
              updateInterval: 500,
              updateWhenIdle: false,
              padding: 0.5,
              tileSize: 500,
              interactive: false,
              keepBuffer: 100,
            });
          }
          else {
            // если есть ссылка на тайлы и выключен вектор
            if (osmUrlTemplate && !vectorEnable) {
              // берем из общего settings или дефолтный растровый
              osmUrlTemplate = AppConfig.getModel().map?.osmUrlTemplate || BASIC_TILE_RASTER_URL;
            } else if (!osmUrlTemplate) {
              // дефолтный растровый
              osmUrlTemplate = BASIC_TILE_RASTER_URL;
            }

            mainMapLayer = new L.tileLayer(osmUrlTemplate, {
              attribution: AppConfig.getModel().map.osmAttribution,
              subdomains: AppConfig.getModel().map.osmSubdomains,
              minZoom: minZoom,
              maxZoom: maxZoom,
            });
          }

        } else {
          // osmUrlTemplate = BASIC_TILE_RASTER_URL;

/*          if (!osmUrlTemplate) {
            osmUrlTemplate = BASIC_TILE_RASTER_URL;
          }*/

          if (osmUrlTemplate && !vectorEnable) {
            osmUrlTemplate = AppConfig.getModel().map?.osmUrlTemplate || BASIC_TILE_RASTER_URL;
          } else if (!osmUrlTemplate) {
            osmUrlTemplate = BASIC_TILE_RASTER_URL;
          }

          mainMapLayer = new L.tileLayer(osmUrlTemplate, {
            attribution: AppConfig.getModel().map.osmAttribution,
            subdomains: AppConfig.getModel().map.osmSubdomains,
            minZoom: minZoom,
            maxZoom: maxZoom,
          });
        }
      }

      if (mainMapLayer) mainMapLayer.addTo(map);

      this.setState((prev) => ({
        ...this.state,
        mainLayer: mainMapLayer,
      }));
    }
  }

  private setLayersToMap = async () => {
    if (this.state.map && this.state.mainLayer) {
      const {dp, cfg, subspace} = this.props;
      let { mapLayers, _cluster} = this.state;

      // add layer with charts
      if (cfg.hasOption('DisplayLayerCharts')) {
        _cluster = this._initLayerCharts();
      }

      let newMapLayers: IMapLayer[] = await this._getCustomMapLayers();

      mapLayers = newMapLayers.map((mapLayer, index) => {

        let active = true;
        if (this.state.activeLayers[`mf${index}`] === false) {
          active = false;
        }

        const zIndex = mapLayer.rawCfg?.display?.zIndex || 1;

        active = false;

        if (String(mapLayer?.rawCfg?.display?.defaultActive) === 'false') {
          this.setState(() => ({
            activeLayers: {
              ...this.state.activeLayers,
              [`mf${index}`]: false,
            }
          }));
        }

        return ({
          ds: cfg.getDataset(),
          dp,
          cfg,
          subspace,
          container: this.mapContainer,
          style: {zIndex: zIndex},
          map: this.state.map,
          leafletLayer: this._leafletLayer,
          cfgMapFill: {enabled: false},
          component: mapLayer,
          editMode: this.props.editMode,
          dashId: this.props.dashId,
          active: active,
        });     // wrap all

      });

      mapLayers.sort((a, b) => b?.style.zIndex - a?.style?.zIndex);

      if (this.props.onMapReady) {
        this.props.onMapReady(this.state.map);
      }
      if (this._mounted) this.setState({mapLayers, _cluster, subspace});
      else this.state = {...this.state, mapLayers, _cluster};

    }
  }

  private setFile = async () => {
    let settings = AppConfig.getModel().map;
    let vectorEnable = 'osmVectorEnable' in settings && !!settings.osmVectorEnable;
    let osmUrlTemplate = settings.osmUrlTemplate;

    const rawCfg = this.props.cfg.getRaw();
    const geoCfg = rawCfg?.geo || {};
    const options = rawCfg?.options || [];


    if ('osmVectorEnable' in geoCfg) {
      vectorEnable = geoCfg?.osmVectorEnable;
    }

    if ('osmUrlTemplate' in geoCfg) {
      if (geoCfg?.osmUrlTemplate)
        osmUrlTemplate = geoCfg?.osmUrlTemplate;

      if (!osmUrlTemplate.startsWith('http')) {
        const origin = window.location.origin;
        osmUrlTemplate = `${origin}${osmUrlTemplate}`;
      }
    }

    if (vectorEnable && geoCfg?.osmUrlTemplate) {
      await readTextFile(osmUrlTemplate, (json) => {
        const disabledCustomTiles = !options.includes('OSMCustomStyle');

        if (vectorEnable && (geoCfg?.osmUrlTemplate || settings?.osmUrlTemplate) && (!settings?.osmCustomStyle && disabledCustomTiles)) {
          const parsed: any = JSON.parse(json);

          if (parsed && parsed.layers) parsed.layers = this.state.theme.mapScheme.layers;
          if (parsed) this.addFileToState(parsed);

          this.setState(() => ({
            customStyleForTiles: 'basic',
          }));
        } else {
          this.addFileToState(json);

          this.setState(() => ({
            customStyleForTiles: 'custom',
          }));
        }
      });
    } else if (this.state.theme && this.state.theme.mapScheme) {
      if (vectorEnable && osmUrlTemplate) {
        this.addFileToState(this.state.theme.mapScheme);
        this.setState(() => ({
          customStyleForTiles: 'basic',
        }));
      }
    } else {
      if (vectorEnable && osmUrlTemplate) {
        await readTextFile(osmUrlTemplate, (json) => this.addFileToState(json));
        this.setState(() => ({
          customStyleForTiles: 'basic',
        }));
      }
    }
  }

  private addFileToState = (json) => {
    this.setState((prev) => ({
      ...this.state,
      file: json,
    }));
  }

  public componentDidUpdate(prevProps: Readonly<IVizelMapProps>, prevState: Readonly<IVizelMapRState>, snapshot?: any) {

    if (this.state.settings !== prevState.settings || (this.state.settings && !this.state.settings.osmVectorEnable && !this.state.map) ) {
      this.initMap();
    }

    if (prevState.map !== this.state.map) {
      this.initMap();
    }

    if (prevState.file !== this.state.file) {
      this.setMainLayer();
    }

    if (prevState.map !== this.state.map) {
      this.setMainLayer();
    }

    if (prevState.theme !== this.state.theme) {
      this.setFile();
    }

    if (prevProps.subspace !== this.props.subspace) {
      this.setAxes(this.props.subspace);
    }

    if (prevState.mainLayer !== this.state.mainLayer) {
      this.setLayersToMap();
    }

    if (prevProps.mf !== this.props.mf) {
      this.setMapFill(this.props.mf);
    }

    if (prevProps.chartType !== this.props.chartType) {
      this.setLayerChartsType(this.props.chartType);
    }

    if (prevState.subspace !== this.state.subspace) {
      this.refreshLayers();
    }

    if (prevState.pointsData !== this.state.pointsData) {
      this.setClusters();
    }

  }

  private setClusters = (): any => {

    const {cfg, subspace} = this.props;
    let {_cluster} = this.state;

    try {
      if (subspace.koob) {
        const data = {dataset: cfg.getDataset(), subspace: subspace};
        if (cfg.hasOption('FillAreasByData' || 'fillAreasByData')) {
          _cluster = new KoobAreaLayer(data);
        } else {
          _cluster = new KoobClusterLayer(data);
        }
      } else {
        _cluster = new ClusterLayer(cfg.getDataset(), subspace);
      }

      if (_cluster) this.state.map.addLayer(_cluster);

      const display: any = cfg.getDisplay();
      const chartType: string = (display && display.layerCharts && display.layerCharts.vizelType) ? display.layerCharts.vizelType : 'bars';
      _cluster.setChartType(chartType);
    } catch (err) {
      console.error(err);
      // ignore error
    }
    return _cluster;
  }

  private refreshLayers = async () => {
    if (this.state.map && this.state.mainLayer) {
      const {cfg} = this.props;
      let { mapLayers, _cluster} = this.state;

      if (cfg.hasOption('DisplayLayerCharts')) {
        if (this.props.onMapReady) {
          this.props.onMapReady(this.state.map);
        }

        this.redrawLayerCharts();
        if (this._mounted) this.setState({mapLayers, _cluster});
        else this.state = {...this.state, mapLayers, _cluster};
      } else {

      }
    }
  }

  private async _getCustomMapLayers(): Promise<IMapLayer[]> {
    const { cfg } = this.props;
    const ids: string[] = [];

    const hasMapfill: boolean = cfg.hasOption('DisplayLayerMap') || cfg.hasOption('DisplayLayerMapFill+');
    if (hasMapfill) {
      ids.push('mf');
    }

    const hasCrazyBus: boolean = cfg.hasOption('DisplayLayerCrazyBus') || cfg.hasOption('DisplayLayerCrazyBus+');

    if (hasCrazyBus) {
      ids.push('dbg-crazy-bus');          // the test layer
    }

    const hasHeatMap: boolean = cfg.hasOption('DisplayLayerHeatMap') || cfg.hasOption('DisplayLayerHeatMap+');
    if (hasHeatMap) {
      ids.push('heat-map');  // heat map layer
    }

    const hasSelectionControlls: boolean = cfg.hasOption('DisplaySelectionControlls') || cfg.hasOption('DisplaySelectionControlls+');
    if (hasSelectionControlls) {
      ids.push('selection-controll');  // heat map layer
    }

    let rawCfg = this.props.cfg.getRaw() || null;

    const children = rawCfg?.children && Array.isArray(rawCfg!.children) ? rawCfg.children : [];

    let ids2: any = [];

    children.forEach((ch: any) => {
      if (ch) {
        let view_class = ch.view_class || null;
        if (view_class) {
          // get filename
          // ids.push('MHeatMap');

          ids2.push({
            config: ch,
            view_class: ch.view_class,
            schema_name: this.state._ds.schema_name,
          });
        }
      }
    });

    ids.push('MHeatMap');

    let layers: IMapLayer[] = ids2.map(cf => {
      return {
        view_class: cf.view_class,
        schema_name: cf.schema_name,
        rawCfg: cf.config
      };
    });

    return layers;
  }

  public setLayerChartsType(chartType: string): void {
    if (this.state._cluster) this.state._cluster.setChartType(chartType);
  }

  public redrawLayerCharts(doMoveMap: boolean = true): void {
    if (this.state._cluster) this.state._cluster.redraw(doMoveMap);
  }

  // from MapView
  public setMapFill(mf: IMapFill): void {
    // hacky
    const {mapLayers} = this.state;
    mapLayers.forEach((ml: IMapFillLayerProps) => ml.cfgMapFill = mf);
    this.setState({mapLayers: [...mapLayers]});
  }

  private _initLayerCharts(): any {
    const {cfg, subspace} = this.props;
    let {_cluster} = this.state;
    try {
      if (subspace.koob) {
        const data = {dataset: cfg.getDataset(), subspace: subspace, cfg, map: this.state.map};
        // TODO убрать ту что с маленькой буквы
        if (cfg.hasOption('FillAreasByData') || cfg.hasOption('fillAreasByData')) {
          _cluster = new KoobAreaLayer(data);
        } else {
          _cluster = new KoobClusterLayer(data);
        }

      } else {
        _cluster = new ClusterLayer(cfg.getDataset(), subspace);
      }

      this.state.map.addLayer(_cluster);

      const display: any = cfg.getDisplay();
      const chartType: string = (display && display.layerCharts && display.layerCharts.vizelType) ? display.layerCharts.vizelType : 'bars';
      _cluster.setChartType(chartType);
    } catch (err) {
      console.error(err);
      // ignore error
    }
    return _cluster;
  }

  public async setAxes(subspace: ISubspace): Promise<any> {
    const {_cluster, mapLayers} = this.state;
    if (subspace) {
      this.initMap(); // no wait
    }
    if (_cluster) {
      if (this.state.subspace.koob) {
        _cluster.setKoobAxes(subspace);
      } else {
        _cluster.setAxes(subspace);
      }

    }
    mapLayers.forEach(ml => ml.subspace = subspace);
    if (this._mounted && this.state.map) this.setState({mapLayers: [...mapLayers], subspace});
    else {
      this.setState((prev) => ({...this.state, mapLayers: [...mapLayers]}));
    }

    return null;
  }

  public dispose(): void {
    let {_cluster} = this.state;
    let mapLayers = [];

    if (_cluster) {
      this.state.map.removeLayer(_cluster);
      _cluster = null;
    }
    if (this.state.map) {
      this.state.map.remove();

      this.setState((prev) => ({
        ...this.state,
        map: null,
      }));
    }
    this.setState({mapLayers, _cluster});
  }

  public resize = (width: number, height: number): void => {
    if (this.parent && this._ref) {
      this.parent.current.width = width;
      this.parent.current.height = height;

      if (this.state.map) setTimeout(() => this.state.map.invalidateSize(), 400);
    }
  }

  private _toggleLayer = (index) => {
    const active = (this.state.activeLayers[`mf${index}`]);

    this.setState(() => ({
      activeLayers: {
        ...this.state.activeLayers,
        [`mf${index}`]: (active === undefined || active === true) ? false : true,
      }
    }));
  }

  private _toggleMenu = (index) => {
    const active = (this.state.activeMenu[`mf${index}`]);
    this.setState(() => ({
      activeMenu: {
        ...this.state.activeMenu,
        [`mf${index}`]: (!active),
      }
    }));
  }

  private setLayerEdit = (buttonRef: refObject, index: number) => {
    let buttons = document.querySelectorAll('.Button__EditMode');
    if (buttons.length) {
      buttons.forEach((b) => b.classList.remove('ActiveEditButton'));
    }

    if (buttonRef.current) {
      buttonRef.current.classList.add('ActiveEditButton');
    }

    let id = String(this.props.dashId);
    if (id.indexOf(':') === -1) id = `${this.props.dashId}:${index}`;

    this.props.onEditDashlet(null);
    setTimeout(() => this.props.onEditDashlet(id), 10);
  }

  private _editContainer = null;

  private onDragEnter = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    const dashlet = e.currentTarget.closest('.Dashlet');
    this._editContainer = dashlet?.querySelector('.Dashlet__Foreground');

    if (this._editContainer) {
      this._editContainer.classList.add('EditContainer');
    }

  }

  private onDragOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (this._editContainer && !this._editContainer.classList.contains('EditContainer')) {
      this._editContainer.classList.add('EditContainer');
    }
  }

  private onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (this._editContainer && this._editContainer.classList.contains('EditContainer')) {
      this._editContainer.classList.remove('EditContainer');
      this._editContainer = null;
    }

    const payload = JSON.parse(e.dataTransfer.getData(DASHLET_MIME_TYPE));

    let cfg = {...payload};

    if (!payload) debugger;

    delete cfg.dashboard_id;

    const oldDashlet = this.props.dashlet || null;
    if (oldDashlet) {
      const edtDashletId = oldDashlet.id || null;

      const parentConfig = oldDashlet.config;

      if (parentConfig) {
        const oldChildren: any[] = Array.isArray(parentConfig?.children) ? [...parentConfig.children] : [];

        let childDashlet = {
          ...cfg,
          id: `${edtDashletId}:${oldChildren.length}`,
          title: 'Новый слой',
          view_class: payload.view_class,
        };

        oldChildren.push(childDashlet);

        const dashlet: any = {
          ...oldDashlet,
          config: {
            ...parentConfig,
            children: [...oldChildren],
          }
        };

        if (this.props.onChangeDashlets) this.props.onChangeDashlets([dashlet]);

      }
    }
  }

  private onDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (this._editContainer && this._editContainer.classList.contains('EditContainer')) {
      this._editContainer.classList.remove('EditContainer');
      this._editContainer = null;
    }
  }

  private deleteLayer = (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    if (this._editContainer && this._editContainer.classList.contains('EditContainer')) {
      this._editContainer.classList.remove('EditContainer');
      this._editContainer = null;
    }

    const oldDashlet = this.props.dashlet || null;
    const parentConfig = oldDashlet.config;

    if (parentConfig) {
      const oldChildren: any[] = Array.isArray(parentConfig?.children) ? [...parentConfig.children] : [];
      const newChildren: any[] = oldChildren.filter((l) => String(l.id) !== String(id));

      const dashlet: any = {
        ...oldDashlet,
        config: {
          ...parentConfig,
          children: [...newChildren],
        }
      };

      if (this.props.onChangeDashlets) this.props.onChangeDashlets([dashlet]);
    }
  }

  private _toggleCustomTilesStyles = async (e?: MouseEvent): Promise<void> => {
    let settings = AppConfig.getModel().map;
    let vectorEnable = 'osmVectorEnable' in settings && !!settings.osmVectorEnable;
    let osmUrlTemplate = settings.osmUrlTemplate;

    const rawCfg = this.props.cfg.getRaw();
    const geoCfg = rawCfg?.geo || {};

    if ('osmVectorEnable' in geoCfg) {
      vectorEnable = geoCfg?.osmVectorEnable;
    }

    if (vectorEnable && osmUrlTemplate) {
      if (vectorEnable && (geoCfg?.osmUrlTemplate || settings?.osmUrlTemplate)) {
        await readTextFile(osmUrlTemplate, (json) => {
            const parsed: any = JSON.parse(json);
            if (this.state.customStyleForTiles === 'custom') {
              if (parsed && parsed.layers) parsed.layers = this.state.theme.mapScheme.layers;
              if (parsed) this.addFileToState(parsed);

              this.setState((prev) => ({
                customStyleForTiles: 'basic',
              }));
            } else {
              this.addFileToState(json);
              this.setState((prev) => ({
                customStyleForTiles: 'custom',
              }));
            }
        });
      }
    }
  }

  protected _setProperty(key: string, value: any): boolean {

  }
  private setProperties(key: string, value: any): boolean {
    if (key && 'customStyleForTiles' in key) {
      this._toggleCustomTilesStyles();
      return true;
    }
    return false;
  }

  public render() {
    const {mapLayers, activeMenu, activeLayers, map} = this.state;

    const elements: any = [];
    const menuElements = [];

    mapLayers.map((layer: L.Layer, index) => {

      const {rawCfg} = layer.component;

      const active = activeLayers[`mf${index}`];
      const activeCurrentMenu = activeMenu[`mf${index}`];

      const buttonRef = React.createRef();
      layer.button = buttonRef;

      const title = rawCfg.title || '';

      const layerData = {
        active,
        rawCfg,
        index,
        activeCurrentMenu,
        title,
        layer,
        editMode: this.props.editMode,
        deleteLayer: this.deleteLayer,
        setLayerEdit: this.setLayerEdit,
        _toggleLayer: this._toggleLayer,
        _toggleMenu: this._toggleMenu,
      };

      menuElements.push(<li key={`menuMapLi-${index}`}>
        <LayerMenuButton
          key={`menu_${index}`}
          {...layerData}
        />
      </li>);
    });

    mapLayers.map((layer: L.Layer, index) => {
      const {view_class, schema_name, rawCfg} = layer.component;
      const active = activeLayers[`mf${index}`];
      const activeCurrentMenu = activeMenu[`mf${index}`];

      const dataForLayer = {
        leafleatLayer: layer.leafletLayer,
        map: map,
        rawCfg: rawCfg,
        active: (active === true || active === undefined) ,
        activeMenu: activeCurrentMenu,
        button: layer?.button,
      };

      const Component = <VizelFromCfg
        key={'mf-' + index}
        view_class={view_class}
        schema_name={schema_name}
        rawCfg={rawCfg}
        {...dataForLayer}
        editMode={this.props.editMode}
      />;

      elements.push(Component);
    });

    return (<div ref={this.parent} className="VizelMap Vizel view vizel" style={{width: '100%', height: '100%'}}>
      <div className="VizelMap__MapContainer" ref={this._ref}
           key={'map-container'}
           onDragEnter={this.onDragEnter}
           onDragOver={this.onDragOver}
           onDrop={this.onDrop}
           onDragLeave={this.onDragLeave}
      > </div>
      <div className="VizelMap__MapContainerLayers" ref={this._refLayersWr}
      >
        {elements.map(e => e)}
      </div>
      <ul ref={this._menuRef} className={`VizelMap__Toolbar view toolbar map-layers ${this.props.editMode === 'builder' ? 'List__EditMode' : ''}`}>
        {menuElements.map(me => me)}
      </ul>
    </div>);
  }
}

export default VizelMap;
