/**
 *
 *
 *
 */


import uniq from 'lodash/uniq';
import { getEntity } from '../../libs/imdas/list';
import { makeColor } from '../../utils/utils';
import COLOR_PALETTE from '../../utils/color-palette';
const skin: any = require('../../skins/skin.json');


export class TaggedEntity implements ITaggedEntity {
  public rawTags: string[] = [];
  protected tags: ITag[] = [];
  private dtags: string;

  public constructor() {
    this.dtags = this.tags.map(t => (t.parent ? t.parent.id + '=' : '') + t.id).join('; ');
  }

  public getTagByGroupId(tagGroupName: string): ITag {
    for (let tag of this.tags) {
      if (tag.root.title === tagGroupName) {
        return tag;
      }
    }
    return null;
  }

  public getTagIdByGroupId(tagGroupName: string): number | string {
    const tag = this.getTagByGroupId(tagGroupName);
    return tag ? tag.id : '';
  }

  public getTags(): ITag[] {
    return this.tags;
  }

  public getTag(id: string | number): ITag {
    return getEntity(this.tags, id);
  }

  public addTag(tag: ITag): this {
    this.tags = this.tags.concat(tag);
    this.dtags = this.tags.map(t => (t.parent ? t.parent.id + '=' : '') + t.id).join('; ');
    let path = [];
    for (; !!tag; tag = tag.parent) path.unshift(tag.id);
    this.rawTags.push(path.join(':'));
    return this;
  }
}


export enum PeriodType {
  Seconds = 1,
  Minutes = 2,
  Hours = 3,
  Days = 4,
  Weeks = 5,
  Months = 6,
  Quarters = 7,
  Years = 8,
}


//
// utility function
//

function parseDate(str: string): Date {
  if (!str.match(/^(\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}:\d{2})([+-]\d{2}(:\d{2})?|Z)?$/)) {
    console.error('Unable to parse date "%s" as ISO8601', str);
    throw new Error('parseDate: unsupported date format ' + str);
  }

  // ACHTUNG!!!
  // Skip timezone offset from server
  const date: Date = new Date(RegExp.$1 + 'Z');

  if (isNaN(date.valueOf())) {
    console.error('parseDate: failed to create Date object from string "%s"', str);
    throw new Error('parseDate: unsupported date format ' + String(str));
  }

  return date;
}

function fixPeriodStartTime(str: string, periodType: PeriodType): Date {
  const date: Date = parseDate(str);

  switch (periodType) {
    case PeriodType.Years:
      if (date.getUTCMonth() !== 0) {
        console.warn(`[Period: Years]: "${str}" invalid month`);
        date.setUTCMonth(0);
      }
    // continue
    case PeriodType.Quarters:
      date.setUTCMonth(3 * Math.floor(date.getUTCMonth() / 3));
    // continue
    case PeriodType.Months:
      if (date.getUTCDate() !== 1) {
        console.warn(`[Period >=Months]: "${str}" invalid date`);
        date.setUTCDate(1);
      }
    // continue
    case PeriodType.Weeks:
    // need special case to align to Mondays ot Sundays
    // continue
    case PeriodType.Days:
      if (date.getUTCHours() !== 0) {
        console.warn(`[Period >=Days]: "${str}" invalid Hours`);
        date.setUTCHours(0);
      }
    // continue
    case PeriodType.Hours:
      if (date.getUTCMinutes() !== 0) {
        console.warn(`[Period >=Hours]: "${str}" invalid Minutes`);
        date.setUTCMinutes(0);
      }
    // continue
    case PeriodType.Minutes:
      if (date.getUTCSeconds() !== 0) {
        console.warn(`[Period >=Minutes]: "${str}" invalid Seconds`);
        date.setUTCSeconds(0);
      }
    // continue
    case PeriodType.Seconds:
    // continue
  }
  return date;
}


const bi2moment: { [periodTypeId: number]: string } = {
  [PeriodType.Seconds]: 'seconds',
  [PeriodType.Minutes]: 'minutes',
  [PeriodType.Hours]: 'hours',
  [PeriodType.Days]: 'days',
  [PeriodType.Weeks]: 'weeks',
  [PeriodType.Months]: 'months',
  [PeriodType.Quarters]: 'quarters',
  [PeriodType.Years]: 'years',
  0: null,
};


export class Unit implements IUnit {
  public axisId: string = 'units';
  public axis_title: string;
  public id: number;
  public config: any;
  public unit_id: number;
  public tiny_title: string;
  public title: string;
  public value_prefix: string;
  public value_suffix: string;
  public color: string;

  public constructor(d: tables.IUnitsItem) {
    this.unit_id = d.id;
    this.id = this.unit_id;
    this.update(d);
  }

  public update(d: tables.IUnitsItem): void {
    this.config = d.config;
    this.axis_title = d.axis_title;
    this.tiny_title = d.tiny_title;
    this.title = d.title;
    this.value_prefix = d.value_prefix;
    this.value_suffix = d.value_suffix;
  }

  public toString(): string {
    return '[Unit id=' + this.id + ']';
  }

  public isInteger(): boolean {
    return (this.config && Array.isArray(this.config.options) && this.config.options.indexOf('Integer') != -1);
  }
}


export class Metric extends TaggedEntity implements IMetric {
  public axisId: string = 'metrics';
  public id: string;
  public parent_id: string;
  public title: string;

  public key: string;
  public tree_level: number;
  public unit_id: number;
  public is_text_val: number;                                                   // 0 | 1
  public config: any;
  public is_hidden: number;
  public srt: number;
  public description: string;
  public parentId: string;

  public children: Metric[] = [];
  public parent: Metric = null;
  public root: Metric;

  public unit: Unit = null;
  public color: string;

  public constructor(raw: tables.IMetricsItem) {
    super();
    this.id = raw.id.toString();
    this.update(raw);
  }

  public update(raw: tables.IMetricsItem): void {
    const rawConfig: any = raw.config || {};
    this.parent_id = String(raw.parent_id);
    const rawUnitId: number = ('dim_id' in raw) ? raw.dim_id : raw.unit_id;
    this.unit_id = (rawUnitId != null) ? rawUnitId : null;
    this.tree_level = raw.tree_level;
    this.title = rawConfig.title || raw.title;
    this.is_text_val = raw.is_text_val;
    this.config = rawConfig;
    const colorPallete: string[] = skin.colorPallete ?? COLOR_PALETTE;
    this.color = (rawConfig.color ? makeColor(rawConfig.color) : colorPallete[Math.abs(+raw.id) % colorPallete.length]);
    this.is_hidden = raw.is_hidden;
    this.description = ('description' in rawConfig) ? rawConfig.description : null;
    this.parentId = (raw.parent_id != null) ? String(raw.parent_id) : null;
  }

  public setParent(parent: Metric): void {
    this.parent = parent;
    if (parent) {
      parent.children.push(this);
    }
  }

  public getParent(): IMetric {
    return this.parent;
  }

  public getTitlePath(): string {
    let result: string = this.title;
    for (let e: IMetric = this; !!e; e = e.parent) {
      result = e.title + ' / ' + result;
    }
    return result;
  }

  public getAltTitle(titleType: string): string {
    if (titleType === 'fullPath') {
      return this.getTitlePath();
    }
    if (titleType in this.config) {
      return this.config[titleType];
    }
    return this.title;
  }
}

export class Location extends TaggedEntity implements ILocation {
  public axisId: string = 'locations';
  public config: any;
  public is_hidden: number;
  public is_point: number;
  public latitude: number;
  public longitude: number;
  public tree_level: number;
  public loc_id: number;                                                        // deprecated
  public parent_id: string;
  public title: string;
  public spatials: ILocationArea[] = [];
  public src_id: string;

  // additional
  public color: string;
  public children: ILocation[] = [];
  public parent: ILocation = null;
  public card: ILocationCard = null;
  public id: string;
  public description: string;

  public constructor(l: tables.ILocationsItem) {
    super();
    this.id = l.id.toString();
    this.update(l);
  }

  public update(l: tables.ILocationsItem) {
    const rawConfig: any = l.config || {};
    this.config = rawConfig;
    this.is_hidden = l.is_hidden;
    this.is_point = l.is_point;
    this.latitude = l.latitude || 0;
    this.longitude = l.longitude || 0;
    this.tree_level = l.tree_level;
    this.loc_id = +this.id;
    this.parent_id = l.parent_id ? l.parent_id.toString() : null;
    this.title = rawConfig.title || l.title;
    this.src_id = l.src_id;

    const colorPallete: string[] = skin.colorPallete ?? COLOR_PALETTE;
    this.color = colorPallete[+l.id % colorPallete.length];
    this.description = ('description' in rawConfig) ? rawConfig.description : null;

    if (this.id === this.parent_id) {
      console.warn(`Location ${this.id} has parent_id same as id: should be null`);
      this.parent_id = null;
    }
  }

  public getParent(): ILocation {
    return this.parent;
  }

  public setParent(parent: Location): void {
    this.parent = parent;
    if (parent) {
      parent.children.push(this);
    }
  }

  public getTitlePath(): string {
    let result: string = this.title;
    for (let e: ILocation = this; !!e; e = e.parent) {
      result = e.title + ' / ' + result;
    }
    return result;
  }

  public getAltTitle(titleType: string): string {
    if (titleType === 'fullPath') {
      return this.getTitlePath();
    }
    if (titleType in this.config) {
      return this.config[titleType];
    }
    return this.title;
  }
}


export class Period extends TaggedEntity implements IPeriod {
  public axisId: string = 'periods';
  public id: string = '';
  public title: string;

  public children: IPeriod[] = [];
  public parent: Period = null;
  public root: Period;

  public period_id: string;
  public period_type: number;
  public qty: number;
  public start: string;
  public config: any;

  public startDate: moment.Moment;   // moment.js
  public middleDate: moment.Moment;  // moment.js
  public endDate: moment.Moment;     // moment.js
  public orderBy: Date;
  public date: Date;
  public color: string = '';
  public tree_level: number = 0;

  public year: number;
  public quarter: number;                                                                           // 1..4
  public month: number;                                                                             // 1..12
  public week: number;                                                                              // 1..
  public day: number;                                                                               // 1..

  public constructor(p: tables.IPeriodsItem) {
    super();
    this.id = ('period_id' in p) ? p.period_id.toString() : p.id.toString();
    this.update(p);
  }

  public update(p: tables.IPeriodsItem) {
    this.period_id = this.id;                                                                       // duplicate for IS_P function
    this.period_type = +p.period_type;
    this.qty = p.qty ? +p.qty : 1;
    this.start = ('start_time' in p) ? p.start_time : p.start;
    this.title = p.title;
    this.config = p.config || {};

    // this.date = this.orderBy = parseDate(this.start);
    this.date = this.orderBy = fixPeriodStartTime(this.start, this.period_type);

    const colorPallete: string[] = skin.colorPallete ?? COLOR_PALETTE;
    this.color = colorPallete[parseInt(p.period_id) % colorPallete.length];  // Save color

    if (!bi2moment[this.period_type]) {
      throw new Error('Undefined period type');
    }
    this.startDate = moment.utc(this.date);
    this.endDate = this.startDate.clone().add(this.qty, bi2moment[this.period_type]);
    this.middleDate = moment.utc((this.startDate.valueOf() + this.endDate.valueOf()) / 2);

    this.year = this.middleDate.year();
    this.quarter = this.middleDate.quarter();
    this.month = this.middleDate.month() + 1;
    this.week = this.middleDate.week();
    this.day = this.middleDate.date();

    if (!this.startDate.isValid()) {
      console.error(`Failed to convert date ${this.start} to moment`);
      throw new Error('Failed to convert start="' + this.start + '" to object in period(period_id=' + this.period_id + ')');
    }
  }

  public valueOf(): number {
    return this.orderBy.valueOf();
  }

  public getParent(): IPeriod {
    return this.parent;
  }
}


export class Preset implements IPreset, IEntity {
  public axisId: string = 'presets';
  public id: string;
  public title: string;
  public color: string;
  public parameters: IMetric[] = [];
  public metrics: IMetric[] = [];
  // IPreset
  public preset_id: number;            // todo: remove
  public can_be_pie: number;

  public constructor(p: tables.IPresetsItem) {
    this.preset_id = ('preset_id' in p) ? p.preset_id : p.id;
    this.id = this.preset_id.toString();
    this.update(p);
  }

  public update(p: tables.IPresetsItem) {
    this.title = p.title;
    this.color = '#000000';
    this.can_be_pie = p.can_be_pie;
  }

  public setMetrics(ms: IMetric[]): void {
    this.metrics = this.parameters = ms;
  }

  public getDimensions(): IUnit[] {
    return uniq(this.metrics.map((m: IMetric) => m.unit));
  }
}
