import {Root} from '../../views/Root/Root';
import {RootContent} from '../../views/Root/RootContent';
import {RootMenu} from '../../views/Root/RootMenu';
import {RootHeader} from '../../views/Root/RootHeader';
import {RootSearch} from '../../views/Root/RootSearch';
import Provider from '../../views/Root/Provider';
import {BreadcrumbControl} from '../../views/Root/BreadcrumbControl';
import {DatasetsListView} from '../../views/Root/DatasetsListView';
import {DatasetsListView1} from '../../views/Root/DatasetsListView1';

export {Root, RootContent, RootMenu, RootHeader, RootSearch, Provider, BreadcrumbControl, DatasetsListView, DatasetsListView1};
