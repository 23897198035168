import React, {useState} from 'react';
import './ActionList.scss';
import {Dropdown, Confirm} from '@luxms/bi-face';
import cn from 'classnames';
import {lang} from '../../../src/utils/utils';

interface IActionProps {
  children: any;
  clickFn?: () => void;
  confirm?: string;
  className?: string;
  confirmClassName?: string;
}

interface IActionListProps {
  children: any;
  onClick: (key: string) => void;
  className?: string;
}

interface IActionList extends React.FC<IActionListProps> {
  Action: React.FC<IActionProps>;
}

const ActionList: IActionList = ({children, className, onClick}) => {
  const childs: (JSX.Element | Element)[] = [];

  React.Children.forEach(children, (child) => {
    // TODO: Check child type (React.Element)
    if (child) {
      const clickFn = () => onClick(child.key);
      childs.push(React.cloneElement(child, {clickFn}));
    }
  });

  return <ul className={cn(className)}>{childs}</ul>;
};

const Action: React.FC<IActionProps> = (props) => {
  const {children, clickFn, confirm, className, confirmClassName} = props;
  const [visible, setVisible] = useState(false);

  const handleHide = (): void => setVisible(false);
  const handleShow = (): void => setVisible(true);
  const onCancelConfirm = (): void => setVisible(false);
  const onConfirm = (): void => {
    clickFn();
    setVisible(false);
  };

  return confirm?.length
    ? (
      <Dropdown.Trigger
        trigger="click"
        placement="right"
        visible={visible}
        onClickOutside={handleHide}
        onClick={handleShow}
        menu={(
          <Confirm
            boxShadow="0px 0px 10px 2px rgba(79, 79, 155, 0.15)"
            className={cn(confirmClassName)}
            title={confirm}
            cancelText={lang('cancel')}
            acceptText={lang('ok')}
            onConfirm={onConfirm}
            onCancel={onCancelConfirm}
          />
        )}
      >
        <li className={cn(className)}>{children}</li>
      </Dropdown.Trigger>
    )

    : <li className={cn(className)} onClick={clickFn}>{children}</li>;
};

ActionList.Action = Action;

export default ActionList;
