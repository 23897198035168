import React from 'react';
import InternalComponentVC from '../../view-controllers/InternalComponentVC';
import useService from '../useService';
import ResourceLocatorService from '../../services/ResourceLocatorService';
import createService from '../../services/createService';
import { UrlState } from '@luxms/bi-core';

interface ILoadFromResourcesProps {
  path: string;
  children?: any;
  [key: string]: any;
}

interface ILFRModel {
  error: string;
  loading: boolean;
  Component: any;
}


const LFRService = createService<ILFRModel>('LFRService', ({useService, useServiceWithCustomSubscription}, path) => {
  const url = useServiceWithCustomSubscription(UrlState, 'displayMode');
  if (url.displayMode === 'luxmsbi') return {error: null, loading: false, Component: null};

  const dsResource = useService(ResourceLocatorService, null, path);
  const dsResResource = useService(ResourceLocatorService, 'ds_res', path);

  if (dsResource.error || dsResResource.error) return {error: dsResource.error || dsResResource.error, loading: false};
  if (dsResource.loading || dsResResource.loading) return {error: null, loading: true};

  let schema_name: string = null;

  if (dsResource.resource) {
    schema_name = dsResource.schema_name;
  } else if (dsResResource.resource) {
    schema_name = dsResResource.schema_name;
  }

  if (!schema_name) {
    return {error: null, loading: false, Component: null};
  }

  const internalComponent = useService(InternalComponentVC, schema_name, path);

  if (internalComponent.error) return {error: internalComponent.error, loading: false, Component: null};
  if (internalComponent.loading) return {error: null, loading: true, Component: null};

  return {
    error: null,
    loading: false,
    Component: internalComponent.Component,
  };
});


const LoadFromResources = React.memo((props: ILoadFromResourcesProps) => {
  const lfr = useService(LFRService, props.path);

  if (lfr.error) { // ошибки игнорим же?
    return props.children;
  }

  if (lfr.loading) {
    return null;
  }

  return lfr.Component ? React.createElement(lfr.Component, props) : props.children;
});

export default LoadFromResources;
