/**
 *
 *
 *
 */

import { vectorSum } from '../data-manip/data-utils';
import { IOptionsProvider } from '../defs/bi';


function matchOptionIdCount(o1: string, o2: string): number {
  if (o1[0] === '!') return -matchOptionIdCount(o1.slice(1), o2);
  if (o2[0] === '!') return -matchOptionIdCount(o1, o2.slice(1));
  return o1 === o2 ? 1 : 0;
}


export class OptionsProvider implements IOptionsProvider {
  private readonly _options: string[];

  public constructor(options: string[], defaultOptions?: string[]) {
    this._options = Array.isArray(options) ? options : [];
    if (defaultOptions) {
      defaultOptions.forEach((defaultOption: string) => {
        if (this.getOption(defaultOption) !== false) {        // invert is not set
          this.addOption(defaultOption);
        }
      });
    }
  }

  public hasOption(optionId: string): boolean {
    return this._options.indexOf(optionId) !== -1;
  }

  public hasAnyOf(...optionIds: string[]): boolean {
    return optionIds.some(optionId => this.hasOption(optionId));
  }

  public addOption(optionId: string): boolean {
    if (this.hasOption(optionId)) {
      return false;
    }
    this._options.push(optionId);
    return true;
  }

  public removeOption(optionId: string): boolean {
    const idx: number = this._options.indexOf(optionId);
    if (idx === -1) {
      return false;
    }
    this._options.splice(idx, 1);
    return true;
  }

  public getOption(optionId: string, defaultValue?: boolean): boolean | undefined {               // true, false or undefined
    const hasOption: boolean = this._options.indexOf(optionId) !== -1;
    if (hasOption) return true;
    const hasInvert = this._options.indexOf('!' + optionId) !== -1;
    if (hasInvert) return false;
    return defaultValue;                                     // in most cases will be interpreted as false
  }

  public getOptions(): string[] {
    return this._options;
  }

  public getOptionCount(optionId: string): number {
    return vectorSum(this._options.map((o: string) => matchOptionIdCount(optionId, o)));
  }

  public setOption(optionId: string, value: boolean): void {   // deprecated
    if (value) {
      this.addOption(optionId);
    } else {
      this.removeOption(optionId);
    }
  }
}
