import React from 'react';
import useService from './useService';
import ResourceLocatorService from '../services/ResourceLocatorService';
import {Helmet} from 'react-helmet';
import { AppConfig } from '@luxms/bi-core';
import { CurrentDsStateService } from '../services/ds/CurrentDsStateService';


// TODO: использовать React Helmet

const CustomStyleLoader = () => {
  const dsResStyles = useService<ResourceLocatorService>(ResourceLocatorService, 'ds_res', 'styles.css');
  const dsStyles = useService<ResourceLocatorService>(ResourceLocatorService, null, 'styles.css');
  const currentDsState = useService<CurrentDsStateService>(CurrentDsStateService);

  const dboard = (currentDsState.error || currentDsState.loading) ? null : currentDsState.dboard?.id;
  const dsDboardStyles = useService<ResourceLocatorService>(ResourceLocatorService, null, `styles.dboard=${dboard}.css`);

  return (
    <Helmet>
      {!!dsResStyles.resource &&
        <link key="ds_res-custom-style"
              data-purpose="ds_res-custom-style"
              rel="stylesheet"
              href={AppConfig.fixRequestUrl(`/srv/resources/${dsResStyles.schema_name}/styles.css?ts=${dsResStyles.resource.updated}`)}/>}

      {!!dsStyles.resource &&
        <link key="ds-custom-style"
              data-purpose="ds-custom-style"
              rel="stylesheet"
              href={AppConfig.fixRequestUrl(`/srv/resources/${dsStyles.schema_name}/${dsStyles.resource.alt_id}?ts=${dsStyles.resource.updated}`)}/>}

      {!!dsDboardStyles.resource &&
        <link key="ds-dboard-custom-style"
              data-purpose="ds-dboard-custom-style"
              rel="stylesheet"
              href={AppConfig.fixRequestUrl(`/srv/resources/${dsDboardStyles.schema_name}/${dsDboardStyles.resource.alt_id}?ts=${dsDboardStyles.resource.updated}`)}/>}
    </Helmet>);
};

export default CustomStyleLoader;
