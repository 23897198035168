/// <reference path="../../defs/classnames.d.ts" />


import * as React from 'react';
import cn from 'classnames';
import {
  DatasetsListService,
  IDatasetsListModel,
  IDatasetsListItem,
  IDatasetsListTile
} from '../../services/DatasetsListService';
import {WpLoadingIcon} from '../components';
import {OptionsProvider} from '../../config/OptionsProvider';
import {$eid} from '../../libs/imdas/list';
import {AppConfig, AuthenticationService, UrlState} from '@luxms/bi-core';
import {lang, search} from '../../utils/utils';
import {ExpandableSearch} from '../components/ExpandableSearch/ExpandableSearch';
import './DatasetsListView.scss';
import {BIIcon} from '../components/BIIcon/BIIcon';
import debounce from 'lodash/debounce';
import {IOptionsProvider} from '../../defs/bi';
import axios, {CancelTokenSource} from 'axios';
import {IDatasetModel} from '../../services/ds/types';
import {EnquiriesService} from '../../services/EnquiriesService';
import {IEnquiry} from '../../repositories/EnquiriesRepository';
import useService, {useServiceItself} from '../useService';

class DsThumbnail extends React.PureComponent<{ ds: IDatasetsListItem }> {
  public state: {
    imgUrl: string;
    lazyLoaded: boolean;
  };

  public constructor(props: { ds: IDatasetsListItem }) {
    super(props);
    const {ds} = props;
    const imgUrl: string = AppConfig.fixRequestUrl(`/srv/resources/${ds.schema_name}/thumbnail.png`);
    this.state = {imgUrl, lazyLoaded: false};
    //  ds.image
  }

  private _isVisible(): boolean {
    if (!this.refs.container) {
      return false;
    }
    const container: HTMLDivElement = this.refs.container as HTMLDivElement;
    const bounding = container.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight));
  }

  private _timerId: number = null;

  private _checkVisibility = () => {
    if (!this.state.lazyLoaded && this._isVisible()) {
      this.setState({lazyLoaded: true});
      window.clearInterval(this._timerId);
      this._timerId = null;
    }
  }

  public componentDidMount() {
    if (!this.state.lazyLoaded) {
      this._timerId = window.setInterval(this._checkVisibility, 150);
    }
  }

  public componentWillUnmount() {
    if (this._timerId !== null) {
      window.clearInterval(this._timerId);
      this._timerId = null;
    }
  }

  public componentWillReceiveProps(props) {
    const {ds} = props;
    const imgUrl: string = AppConfig.fixRequestUrl(`/srv/resources/${ds.schema_name}/thumbnail.png`);
    this.setState({imgUrl});
  }

  private _onImgError = () => {
    const {ds} = this.props;
    let {imgUrl} = this.state;
    if (imgUrl !== ds.image) {
      this.setState({imgUrl: ds.image});
    }
  }

  public render() {
    const {lazyLoaded, imgUrl} = this.state;
    return (
      <div ref="container" className="DsThumbnail">
        {!!lazyLoaded && !!imgUrl &&
        <img
          className="logo"
          onError={this._onImgError}
          src={imgUrl}/>}
      </div>);
  }
}


const DatasetItemWithSchemaName = ({ds, bgColor}: { ds: IDatasetsListItem, bgColor: string }) => {
  return (
    <div className="DatasetItemWithSchemaName DatasetItem dataset DatasetItemNew" data-color={ds.color}
         style={{backgroundColor: bgColor}}>

      <DsThumbnail ds={ds}/>
      <div className="DsContent">
        <div className="DsContent__wrapper">
          <h3>{ds.title}</h3>
          <h4>{ds.description != 'none' && ds.description != '' ? ds.description : ''}</h4>
          <div className="data-available">
            <span>{lang('available_data_on')}</span>
            <span>{ds.lastPeriodTitle}</span>
          </div>
        </div>
      </div>
      <a className="DatasetItemWithSchemaName__Open btn btn-primary" href={ds.href}>{lang('open')}</a>
    </div>);
};


const DatasetItemTile = ({ds, bgColor}: { ds: IDatasetsListItem, bgColor: string }) => {
  const options: IOptionsProvider = new OptionsProvider(ds.config.options);
  const isGroup: boolean = ds.id[0] === '_';
  const isOpenable: boolean = options.getOption('Openable');
  const groupUrl: string = '#/ds/@' + ds.id;
  const dsUrl: string = ds.href;
  const url: string = (isGroup || isOpenable) ? groupUrl : dsUrl;
  return (
    <div className="DatasetItemTile DatasetItem dataset">
      <a className="material"
         href={url}
         style={{backgroundColor: bgColor}}>
        <span className="title">{ds.title}</span>
        {(ds.children.length > 0) &&
        <span className="description">{lang('available') + ': ' + ds.children.length}</span>}
      </a>
    </div>);
};


function DatasetViewBookmarks({ds}: { ds: IDatasetsListItem }) {
  return <ul className="bookmarks">
    {ds.bookmarks.map(bookmark =>
      <li key={bookmark.id.toString()}
          style={{lineHeight: '20px', verticalAlign: 'middle'}}>
        <a className="deleteBookmark" style={{cursor: 'pointer'}}
           href="javascript:void(0)"
           onClick={() => ds.deleteBookmark(bookmark)}>
          <svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16">
            <polygon
              points="15.778,14.364 9.414,8 15.777,1.636 14.363,0.222 8,6.586 1.636,0.222 0.222,1.636 6.586,8 0.222,14.364 1.636,15.778 8,9.414 14.364,15.778 "/>
          </svg>
        </a>
        <a href={bookmark.url}>{bookmark.title}</a>
      </li>)
    }
  </ul>;
}


function DatasetViewChildrenGrid({ds, searchTerm}: { ds: IDatasetsListItem, searchTerm: string }) {
  return (
    <ul className="children grid"
        style={{position: 'relative', listStyle: 'none', margin: '0', display: 'block'}}
        data-bind="style: {height: $data.gridPxHeight() + 'px'}, attr: {'data-dataset-guid':$data.guid}">
      {ds.tiles.map((tile: IDatasetsListTile) =>
        <li key={tile.dataset.id.toString()}
            style={{
              position: 'absolute',
              listStyle: 'none',
              display: 'block',
              margin: '0',
              padding: '0',
              color: 'white',
              border: '1px solid #fdfdfd',
              overflow: 'hidden',
              left: tile.percentage.x + '%',
              top: tile.percentage.y + '%',
              width: tile.percentage.w + '%',
              height: tile.percentage.h + '%',
            }}>

          <a data-bind="attr: {href: $tile.dataset.href}" style={{position: 'absolute', width: '100%', height: '100%'}}>
            <img
              data-bind="attr: {src: $tile.dataset.image}, style: {visibility: ('' != $tile.dataset.image ? 'visible' : 'hidden')}"
              className="logo"
              style={{
                width: 'auto',
                height: 'auto',
                maxHeight: '90%',
                maxWidth: '90%',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              src=""/>
          </a>
        </li>)}
    </ul>);
}


const DatasetViewChildrenNoGrid = ({ds, searchTerm}: { ds: IDatasetsListItem, searchTerm: string }) => {
  return (
    <ul className="DatasetsListView__DatasetsList children"
        data-dataset-guid={ds.guid}>
      {ds.children.map(ds => (
        <DatasetView key={ds.id.toString()} ds={ds} searchTerm={searchTerm}/>))}
    </ul>);
};


const DatasetViewChildren = ({ds, searchTerm}: { ds: IDatasetsListItem, searchTerm: string }) => {
  // return (
  //   ds.layout === 'grid' ?
  //     <DatasetViewChildrenGrid ds={ds} searchTerm={searchTerm}/> :
  //     <DatasetViewChildrenNoGrid ds={ds} searchTerm={searchTerm}/>);

  // temporarily tiles are disabled
  // must implement correct layout strategy for any window size here
  return (<DatasetViewChildrenNoGrid ds={ds} searchTerm={searchTerm}/>);
};


function DatasetView({ds, searchTerm}: { ds: IDatasetsListItem, searchTerm: string }) {
  const searchVisible: boolean = searchTerm ? search(ds.title, searchTerm) : true;
  const isGroup: boolean = ds.id[0] === '_';
  const options: IOptionsProvider = new OptionsProvider(ds.config.options);
  const isTile: boolean = options.getOption('Tile');
  const isOpenable: boolean = options.getOption('Openable');
  let bgColor: string = null;
  let tilesPerRow: number = ds.config.tilesPerRow || 3;

  if (isGroup && isTile) {                // will bgcolor only for groups that are tiled
    bgColor = ds.color || '#ffffff';
  }
  if (!isGroup) {
    bgColor = ds.color || null;
  }

  if (!searchVisible) {                       // when no in search: try to display children
    if (ds.children.length) {                                               // if check !isOpenable will not display children of openables
      return <DatasetViewChildren ds={ds} searchTerm={searchTerm}/>;
    } else {
      return null;
    }
  }

  let displayType = '';
  if (isTile) {
    displayType = 'Tile';
  } else if (ds.schema_name) {
    displayType = 'SchemaName';
  } else {
    displayType = 'Title';
  }

  const className = cn(
    'DatasetView',
    'DatasetView--' + displayType,
    'view',
    displayType === 'Tile' ? ('DatasetView--Tile' + tilesPerRow) : null,
    'datasetlink',
    {
      'has-children': !!ds.children.length,
    });

  return (
    <li className={className}
        data-color={ds.color}>

      {displayType === 'Tile' &&
      <DatasetItemTile ds={ds} bgColor={bgColor}/>}

      {displayType === 'SchemaName' &&
      <DatasetItemWithSchemaName ds={ds} bgColor={bgColor}/>}

      {displayType === 'Title' &&
      <h3 data-color={ds.color}>{ds.title}</h3>}

      {!!ds.bookmarks.length ?
        <DatasetViewBookmarks ds={ds}/> : null}

      {!isOpenable && !!ds.children.length &&
      <DatasetViewChildren ds={ds} searchTerm={searchTerm}/>}
    </li>);
}

const DatasetWithEnquiry = ({ds}: { ds: IDatasetsListItem }) => {
  const auth = useService<AuthenticationService>(AuthenticationService);
  const enquiriesService = useServiceItself<EnquiriesService>(EnquiriesService);
  const enquiries = enquiriesService.getModel();

  if (auth.error || auth.loading || enquiries.error || enquiries.loading) return null;

  const enquiry: IEnquiry = enquiries.find(e => e.user_id === auth.userId && e.attachment_type === 'access_enquiry' && String(e.dataset_id) === String(ds.id));

  const toggleEnquiry = (event: React.MouseEvent) => {
    if (enquiry) {
      enquiriesService.remove(enquiry.id);
    } else {
      enquiriesService.create({title: '', dataset: ds.schema_name});
    }
    event.preventDefault();
  };

  return (
    <div className="DatasetItemWithSchemaName DatasetItem dataset fts">
      <div className="DsThumbnail"><img className="logo" src=""/></div>
      <h3>{ds.title}</h3>
      <h4>{ds.description}</h4>

      <a className="DatasetItemWithSchemaName__Open btn btn-primary DatasetItemWithSchemaName__AccessBtn"
         onClick={toggleEnquiry}
         href="#">
        {!!enquiry ? lang('cancelRequest') : lang('requestAccess')}
      </a>
      {
        !!enquiry && (<span className="DatasetItemWithSchemaName__AccessStatus">
            <BIIcon icon={'yes'} onPress={e => e.preventDefault()} className="DatasetItemWithSchemaName__AccessIcon"/>
            <span>{lang('accessRequested')}</span>
          </span>)
      }
    </div>);
};


interface IDatasetsListState extends IDatasetsListModel {
  searchTerm: string;
  openedId: string;
  searchExpanded: boolean;
  ftsDatasets: IDatasetModel[];
}


export class DatasetsListView extends React.Component<any, IDatasetsListState> {

  public constructor(props: any) {
    super(props);
    const {segment, segmentId} = UrlState.getModel();
    let openedId: string | null = null;
    if (segment === 'ds' && segmentId && segmentId[0] === '@') {
      openedId = segmentId.slice(1);
    }
    this.state = {
      ...DatasetsListService.getModel(),
      searchTerm: null,
      searchExpanded: false,
      openedId,
      ftsDatasets: [],
    };
  }

  public componentDidMount() {
    UrlState.subscribe('segment segmentId', this._onUrlStateUpdated);
    DatasetsListService.subscribeUpdatesAndNotify(this._onDatasetsListServiceUpdated);
  }

  public componentWillUnmount() {
    DatasetsListService.unsubscribe(this._onDatasetsListServiceUpdated);
    UrlState.unsubscribe(this._onUrlStateUpdated);
  }

  private _onDatasetsListServiceUpdated = (datasetsListModel: IDatasetsListModel) => {
    this.setState(datasetsListModel);
  }

  private _onUrlStateUpdated = ({segment, segmentId}) => {
    if (segment === 'ds' && (!segmentId || segmentId[0] === '@')) {
      const newOpenedId = segmentId ? segmentId.slice(1) : null;
      if (this.state.openedId !== newOpenedId) {
        this.setState({
          openedId: newOpenedId,
          searchTerm: null,                 // reset search when change opened
        });
      }
    }
  }

  public resize(): void {
    //
  }

  private _handleToggleSearch = () => {
    let {searchTerm} = this.state;

    if (searchTerm === null) {      // turn on
      searchTerm = '';

      window.setTimeout(() => {    // focus input box
        (this.refs.searchElement as HTMLInputElement).focus();
      }, 55);
    } else {
      searchTerm = null;
    }

    this.setState({searchTerm});
  }

  private _ftsCancel: CancelTokenSource = null;

  private _fts = debounce(async (searchTerm: string) => {
    try {
      this._ftsCancel = axios.CancelToken.source();
      const req = await axios.get(AppConfig.fixRequestUrl('/api/search/fts_datasets'), {
        params: {s: searchTerm},
        cancelToken: this._ftsCancel.token,
      });

      let ftsDatasets: IDatasetModel[] = (req.data || []).filter(ftsDataset => !this.state.datasets.find(ds => ds.schema_name === ftsDataset.schema_name));
      this.setState({ftsDatasets});

    } catch (err) {
      if (err instanceof axios.Cancel) {
        // cancelled
      } else if (err.response?.status === 404) {                                                    // no such functionality
        this._fts = null;                                                                           // disable it
      }
    } finally {
      this._ftsCancel = null;
    }
  }, 1000);

  private _onSearchTermChanged = (searchTerm: string) => {
    this.setState({searchTerm});
    this._fts?.cancel();
    if (this._ftsCancel) {
      this._ftsCancel.cancel('Operation canceled by the user.');
      this._ftsCancel = null;
    }
    this.setState({ftsDatasets: []});

    if (searchTerm.length >= 3 && this._fts) {
      this._fts(searchTerm);
    }
  }

  private _onSearchToggleExpanded = (searchExpanded: boolean) => this.setState({searchExpanded});

  private _renderError(error: string) {
    return (
      <article className="DatasetsListView error error-background" style={{minHeight: '300px', position: 'relative'}}>
        <div className="magic-center" style={{width: '95%', maxWidth: '600px'}}>
          <BIIcon icon="bug" className="black"
                  style={{display: 'inline-block', width: '64px', height: '64px', float: 'left', margin: '20px'}}/>
          <h2
            style={{fontWeight: 'normal', fontSize: '24px', lineHeight: '64px'}}>{lang('unable_to_load_data_sets')}</h2>
          <div style={{clear: 'both'}}></div>
          <span style={{width: '100%', display: 'inline-block', textAlign: 'center'}}>{error}</span>
        </div>
      </article>);
  }

  public render() {
    const {loading, error, datasets, roots, searchTerm, searchExpanded, openedId, ftsDatasets} = this.state;
    if (error) return this._renderError(error);
    if (loading) return <section className="DatasetsListView loading loadingFirstTime"/>;

    let visibleRootDatasets: IDatasetsListItem[] = roots;
    if (openedId) {
      const parentDataset: IDatasetsListItem = $eid(datasets, openedId);
      visibleRootDatasets = parentDataset ? parentDataset.children : [];
    }

    // const isSearchAvailable: boolean = !!visibleRootDatasets.find(ds => {
    //   const options: IOptionsProvider = new OptionsProvider(ds.config.options);
    //   const isTile: boolean = options.getOption('Tile');
    //   const isOpenable: boolean = options.getOption('Openable');
    //   return !isOpenable;                                                       // openables are not available for search
    //   return true;
    // });
    const isSearchAvailable: boolean = true;

    return (
      <section className={cn('DatasetsListView', {searchExpanded})}>
        {isSearchAvailable &&
        <ExpandableSearch onSearchTermChanged={this._onSearchTermChanged}
                          onToggleExpanded={this._onSearchToggleExpanded}/>}

        <ul className="DatasetsListView__DatasetsList datasets">
          {visibleRootDatasets.map(ds => (
            <DatasetView key={ds.id.toString()} searchTerm={searchTerm} ds={ds}/>))}
        </ul>

        {!!ftsDatasets.length &&
        <ul className="DatasetsListView__DatasetsList fts">
          {ftsDatasets.map(ds =>
            <li key={ds.schema_name} className="DatasetView">
              <DatasetWithEnquiry ds={ds}/>
            </li>)}
        </ul>}
      </section>);
  }
}

export default DatasetsListView;


export const DatasetsListIcon = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5455 0C15.8327 0 13.0909 0.96 13.0909 2.78545V3.94182C13.578 3.96426 14.0636 4.01282 14.5455 4.08727V2.78545C14.5455 2.31273 16.0655 1.44727 18.5455 1.44727C21.0255 1.44727 22.5455 2.32 22.5455 2.78545V10.2982C22.5455 10.6909 21.4836 11.3455 19.7091 11.5564V13.0109C21.9855 12.7782 24 11.8473 24 10.2836V2.78545C24 0.96 21.2582 0 18.5455 0Z"
        fill="white"/>
      <path
        d="M1.45455 10.3055V2.79273C1.45455 2.32 2.97455 1.45455 5.45455 1.45455C7.93455 1.45455 9.45455 2.32727 9.45455 2.79273V4.09454C9.93645 4.02009 10.422 3.97153 10.9091 3.94909V2.79273C10.9091 0.96 8.16727 0 5.45455 0C2.74182 0 0 0.967273 0 2.79273V10.3055C0 11.8764 2.01455 12.8 4.29091 13.04V11.5855C2.51636 11.3527 1.45455 10.6982 1.45455 10.3055Z"
        fill="white"/>
      <path
        d="M11.7455 4.36364C8.16 4.36364 5.45455 5.73091 5.45455 7.54909V16.5018C5.45455 18.32 8.16 19.6873 11.7455 19.6873C15.3309 19.6873 18.0364 18.32 18.0364 16.5018V7.54909C18.0364 5.73091 15.3309 4.36364 11.7455 4.36364ZM16.5818 9.94182C15.9636 10.6691 14.0945 11.3964 11.7455 11.3964C10.7629 11.4066 9.78369 11.2793 8.83636 11.0182V12.08C9.78824 12.3135 10.7654 12.4284 11.7455 12.4218C13.4307 12.4902 15.1028 12.0979 16.5818 11.2873V13.5564C15.9636 14.2836 14.0945 15.0109 11.7455 15.0109C10.7625 15.0187 9.78334 14.889 8.83636 14.6255V15.6873C9.78824 15.9208 10.7654 16.0356 11.7455 16.0291C13.4307 16.0974 15.1028 15.7052 16.5818 14.8945V16.56C16.5818 17.2509 14.6545 18.2909 11.7455 18.2909C8.83636 18.2909 6.90909 17.2509 6.90909 16.56V7.54909C6.90909 6.85818 8.83636 5.81818 11.7455 5.81818C14.6545 5.81818 16.5818 6.85818 16.5818 7.54909V9.94182Z"
        fill="white"/>
    </svg>
  );
};

