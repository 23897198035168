import $ from 'jquery';

export class MouseWatcher {
  private _mouseX: number = 0;
  private _mouseY: number = 0;

  public constructor() {
    $('body').on('mousemove mousedown touchstart', (e: any) => {
      e = e.originalEvent;

      this._mouseX = (e.touches && e.touches[0] && (e.touches[0].clientX || e.touches[0].pageX)) || e.clientX || e.pageX;
      this._mouseY = (e.touches && e.touches[0] && (e.touches[0].clientY || e.touches[0].pageY)) || e.clientY || e.pageY;
    });
  }

  public getMouseX(): number {
    return this._mouseX;
  }

  public getMouseY(): number {
    return this._mouseY;
  }
}


export const mouseWatcher: MouseWatcher = new MouseWatcher()
