import React from 'react';
import {Idcard} from '../svg/Idcard';
import {Team} from '../svg/Team';
import {Setting} from '../svg/Setting';
import {Search} from '../svg/Search';
import {CheckBox} from '../svg/CheckBox';
import {ArrowLeft} from '../svg/ArrowLeft';
import ArrowDown from '../svg/ArrowDown';
import {Plus} from '../svg/Plus';
import {Loupe} from '../svg/Loupe';
import AddUser from '../svg/AddUser';
import DelUser from '../svg/DelUser';
import {InArchive} from '../svg/InArchive';
import {FromArchive} from '../svg/FromArchive';
import {Block} from '../svg/Block';
import {UnBlock} from '../svg/UnBlock';
import {Edit} from '../svg/Edit';
import {Phone} from '../svg/Phone';
import {Clip} from '../svg/Clip';
import {TableSvg} from '../svg/TableSvg';
import {X} from '../svg/X';
import {FrownO} from '../svg/FrownO';
import {FileText} from '../svg/FileText';
import {Information} from '../svg/Information';
import {Toggle} from '../svg/Toggle';
import {Geo} from '../svg/Geo';
import {Check} from '../svg/Check';
import {Dash} from "../svg/Dash";
import {Home} from "../svg/Home";
import {Bars} from "../svg/Bars";
import {Hourglass} from "../svg/Hourglass";
import {Period} from "../svg/Period";


/**
 * в рамках выпиливания antd
 * такая же крутилка как и в анте
 * если antd не используется нигде - удаляйте папку компонента
 */

interface IIconProp {
  type: string;
  styles?: any;
  className?: string;
  onClick?: () => void;
}

export class Icon extends React.Component<IIconProp> {

  private _getIcon(icon: string): JSX.Element {
    switch (icon) {
      case 'idcard':
        return <Idcard/>;
      case 'team':
        return <Team/>;
      case 'setting':
        return <Setting/>;
      case 'search':
        return <Search/>;
      case 'checkBox':
        return <CheckBox/>;
      case 'arrowDown':
        return <ArrowDown/>;
      case 'plus':
        return <Plus/>;
      case 'arrowLeft':
        return <ArrowLeft/>;
      case 'loupe':
        return <Loupe/>;
      case 'addUser':
        return <AddUser/>;
      case 'delUser':
        return <DelUser/>;
      case 'inArchive':
        return <InArchive/>;
      case 'fromArchive':
        return <FromArchive/>;
      case 'block':
        return <Block/>;
      case 'unblock':
        return <UnBlock/>;
      case 'edit':
        return <Edit/>;
      case 'phone':
        return <Phone/>;
      case 'clip':
        return <Clip/>;
      case 'table':
        return <TableSvg/>;
      case 'x':
        return <X/>;
      case 'frownO':
        return <FrownO/>;
      case 'fileText':
        return <FileText/>;
      case 'information':
        return <Information/>;
      case 'toggle':
        return <Toggle/>;
      case 'geo':
        return <Geo/>;
      case 'check':
        return <Check/>;
      case 'dash':
        return <Dash/>;
      case 'home':
        return <Home/>;
      case 'bars':
        return <Bars/>;
      case 'hourglass':
        return <Hourglass/>;
      case 'period':
        return <Period/>;
      default:
        return null;
    }
  }

  private _onClick = (): void => {
    if (!this.props.onClick) return;
    this.props.onClick();
  }

  public render() {
    const {type, styles, className} = this.props;
    if (!type) return null;
    return (<i className={className} onClick={this._onClick}
               style={{
                 display: 'inline-flex',
                 alignItems: 'center',
                 position: 'relative',
                 ...styles
               }}>{this._getIcon(type)}</i>);
  }
}

export default Icon;
