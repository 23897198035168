/**
 *
 */


import { bi, markContinuousPeriodType } from '../utils/utils';
import { data_engine, INormsResponse } from './data-manip';
import { $eid, $eidx } from '../libs/imdas/list';
import { IEntity, ILocation, IMetric, IMLPPtrCube, IPeriod, ISubspace, IValue } from '../defs/bi';
import { koobDataRequest3 } from '../services/koob/KoobDataService';


function getCPT(es: IEntity[]): [number, number] | null {
  return (es as any).__continuousPeriodType || null;
}

function isEqualsContinuous(es1: IEntity[], es2: IEntity[]): boolean {
  if (!getCPT(es1) || !getCPT(es2)) {
    return false;
  }
  const c1: [number, number] = (es1 as any).__continuousPeriodType;
  const c2: [number, number] = (es2 as any).__continuousPeriodType;
  return c1[0] === c2[0] && c1[1] === c2[1];
}


const RELATIVE_POSITION_aa__bb = 0;
const RELATIVE_POSITION_XXXXXX = 1;
const RELATIVE_POSITION_aaXXaa = 2;
const RELATIVE_POSITION_bbXXbb = 3;
const RELATIVE_POSITION_aaXXbb = 4;
const RELATIVE_POSITION_bbXXaa = 5;
const RELATIVE_POSITION_XXXbbb = 6;
const RELATIVE_POSITION_bbbXXX = 7;
const RELATIVE_POSITION_XXXaaa = 8;
const RELATIVE_POSITION_aaaXXX = 9;


// prerequires
// es1.length > 0
// es2.length > 0
// es1.continuous === es2.continuous
function getRelativePosition<E extends IEntity>(a: E[], b: E[]): number {
  const a0: E = a[0], a1: E = a[a.length - 1];
  const a0InB: boolean = $eidx(b, a0.id) !== -1;
  const a1InB: boolean = $eidx(b, a1.id) !== -1;

  const b0: E = b[0], b1: E = b[b.length - 1];
  const b0InA: boolean = $eidx(a, b0.id) !== -1;
  const b1InA: boolean = $eidx(a, b1.id) !== -1;

  if (!a0InB && !a1InB && !b0InA && !b1InA) return RELATIVE_POSITION_aa__bb;

  if (!a0InB && !a1InB && b0InA && b1InA) return RELATIVE_POSITION_aaXXaa;
  if (a0InB && a1InB && !b0InA && !b1InA) return RELATIVE_POSITION_bbXXbb;
  if (!a0InB && a1InB && b0InA && !b1InA) return RELATIVE_POSITION_aaXXbb;
  if (a0InB && !a1InB && !b0InA && b1InA) return RELATIVE_POSITION_bbXXaa;

  if (a0InB && a1InB && b0InA && !b1InA) return RELATIVE_POSITION_XXXbbb;
  if (a0InB && a1InB && !b0InA && b1InA) return RELATIVE_POSITION_bbbXXX;
  if (!a0InB && a1InB && b0InA && b1InA) return RELATIVE_POSITION_aaaXXX;
  if (a0InB && !a1InB && b0InA && b1InA) return RELATIVE_POSITION_XXXaaa;

  if (a0InB && a1InB && b0InA && b1InA) return RELATIVE_POSITION_XXXXXX;

  console.error('Undefined relative position', a, b, a0InB, a1InB, b0InA, b1InA);
  throw new Error('Undefined relative position');
}


// prerequires
// es1.length > 0
// es2.length > 0
// es1.continuous === es2.continuous
function intersectContinuous<E extends IEntity>(a: E[], b: E[]): E[] {
  const cpt = getCPT(a);
  const relativePosition = getRelativePosition(a, b);

  switch (relativePosition) {
    case RELATIVE_POSITION_aa__bb:
      return [];
    case RELATIVE_POSITION_XXXXXX:
      return a;
    case RELATIVE_POSITION_aaXXaa:
      return b;
    case RELATIVE_POSITION_bbXXbb:
      return a;
    case RELATIVE_POSITION_aaXXbb:
      return markContinuousPeriodType(b.filter(e => !!$eid(a, e.id)), cpt);       // TODO: optimize with indices/slices
    case RELATIVE_POSITION_bbXXaa:
      return markContinuousPeriodType(a.filter(e => !!$eid(b, e.id)), cpt);
    case RELATIVE_POSITION_XXXbbb:
      return a;
    case RELATIVE_POSITION_bbbXXX:
      return a;
    case RELATIVE_POSITION_XXXaaa:
      return b;
    case RELATIVE_POSITION_aaaXXX:
      return b;
  }

  throw new Error('Undefined relative position');
}


// prerequires
// es1.length > 0
// es2.length > 0
// es1.continuous === es2.continuous
function unionContinuous<E extends IEntity>(a: E[], b: E[]): E[] {
  const cpt = getCPT(a);
  const relativePosition = getRelativePosition(a, b);

  switch (relativePosition) {
    case RELATIVE_POSITION_aa__bb:
      return a.concat(b);       // not continuous range
    case RELATIVE_POSITION_XXXXXX:
      return a;
    case RELATIVE_POSITION_aaXXaa:
      return a;
    case RELATIVE_POSITION_bbXXbb:
      return b;
    case RELATIVE_POSITION_aaXXbb:
      return markContinuousPeriodType(a.concat(b.filter(e => !$eid(a, e.id))), cpt);       // TODO: optimize with indices/slices
    case RELATIVE_POSITION_bbXXaa:
      return markContinuousPeriodType(b.concat(a.filter(e => !$eid(b, e.id))), cpt);
    case RELATIVE_POSITION_XXXbbb:
      return b;
    case RELATIVE_POSITION_bbbXXX:
      return b;
    case RELATIVE_POSITION_XXXaaa:
      return a;
    case RELATIVE_POSITION_aaaXXX:
      return a;
  }

  throw new Error('Undefined relative position');
}


// prerequires
// es1.length > 0
// es2.length > 0
// es1.continuous === es2.continuous
function diffContinuous<E extends IEntity>(a: E[], b: E[]): E[] {
  const cpt: [number, number] = getCPT(a);
  const relativePosition = getRelativePosition(a, b);

  switch (relativePosition) {
    case RELATIVE_POSITION_aa__bb:
      return a;
    case RELATIVE_POSITION_XXXXXX:
      return [];
    case RELATIVE_POSITION_aaXXaa:
      return a.filter(e => !$eid(b, e.id));              // not continuous
    case RELATIVE_POSITION_bbXXbb:
      return [];
    case RELATIVE_POSITION_aaXXbb:
      return markContinuousPeriodType(a.filter(e => !$eid(b, e.id)), cpt);       // TODO: optimize with indices/slices
    case RELATIVE_POSITION_bbXXaa:
      return markContinuousPeriodType(a.filter(e => !$eid(b, e.id)), cpt);
    case RELATIVE_POSITION_XXXbbb:
      return [];
    case RELATIVE_POSITION_bbbXXX:
      return [];
    case RELATIVE_POSITION_XXXaaa:
      return markContinuousPeriodType(a.filter(e => !$eid(b, e.id)), cpt);       // TODO: optimize with indices/slices
    case RELATIVE_POSITION_aaaXXX:
      return markContinuousPeriodType(a.filter(e => !$eid(b, e.id)), cpt);       // TODO: optimize with indices/slices
  }

  throw new Error('Undefined relative position');
}


function intersection<E extends IEntity>(es1: E[], es2: E[]): E[] {
  if (es1 === es2) return es1;
  if (es1.length === 0) return [];
  if (es2.length === 0) return [];

  if (getCPT(es1) && getCPT(es2)) {           // both continuous
    if (isEqualsContinuous(es1, es2)) {         // and same periodType
      return intersectContinuous(es1, es2);
    } else {
      return [];                                // intersection is empty as different period types
    }
  }

  return es1.filter(e => !!$eid(es2, e.id));
}

function union<E extends IEntity>(es1: E[], es2: E[]): E[] {
  if (es1 === es2) return es1;
  if (es1.length === 0) return es2;
  if (es2.length === 0) return es1;

  if (getCPT(es1) && getCPT(es2) && isEqualsContinuous(es1, es2)) {         // both continuous and same periodType
    return unionContinuous(es1, es2);
  }

  let result: E[] = es1.concat(es2.filter(e => !$eid(es1, e.id)));
  return result;
}


function diff<E extends IEntity>(es1: E[], es2: E[]): E[] {
  if (es1 === es2) return [];
  if (es1.length === 0) return [];
  if (es2.length === 0) return es1;

  if (getCPT(es1) && getCPT(es2)) {           // both continuous
    if (isEqualsContinuous(es1, es2)) {         // and same periodType
      return diffContinuous(es1, es2);
    } else {
      return es1;                             // different period types
    }
  }

  return es1.filter(e => !$eid(es2, e.id));
}


export class CachedMLPCube implements IMLPPtrCube {
  private _dp: data_engine.IDataProvider;
  private _ms: IMetric[] = [];
  private _ls: ILocation[] = [];
  private _ps: IPeriod[] = [];
  protected _mlpData: { [mid: string]: { [lid: string]: { [pid: string]: number } } } = {};

  public constructor(dp: data_engine.IDataProvider, private isNoCache = 0) {
    this._dp = dp;
  }

  public rtValueUpdated(m: IMetric, l: ILocation, p: IPeriod, v: number): void {
    debugger;
    let mid: string = m.id;
    let lid: string = l.id;
    let pid: string = p.id;
    if ($eid(this._ms, mid) && $eid(this._ls, lid) && $eid(this._ps, pid)) {
      let h: any = this._mlpData;

      h = (mid in h) ? h[mid] : (h[mid] = {});
      h = (lid in h) ? h[lid] : (h[lid] = {});
      h[pid] = v;
    }
  }

  // IMLPPtrCube
  public forEach(fn: (mid: string, lid: string, pid: string, v: number) => void): void {
    for (let m of this._ms) {
      const lpHash: { [lid: string]: { [pid: string]: number } } = this._mlpData[m.id] || {};
      for (let l of this._ls) {
        const pHash: { [pid: string]: number } = lpHash[l.id] || {};
        for (let p of this._ps) {
          let value: number = p.id in pHash ? pHash[p.id] : null;
          fn(m.id, l.id, p.id, value);
        }
      }
    }
  }

  private __removeExtras(ms: IMetric[], ls: ILocation[], ps: IPeriod[]): Promise<any> {
    this._ms = intersection(this._ms, ms);
    this._ls = intersection(this._ls, ls);
    this._ps = intersection(this._ps, ps);

    // TODO: remove from mlpData...
    // this.__mlpData = {};

    return Promise.resolve();
  }

  private async __loadCube(ms: IMetric[], ls: ILocation[], ps: IPeriod[], closest?: boolean): Promise<any> {
    const subspace: ISubspace = bi.createSimpleSubspace(ps, ls, ms);
    const cube: IValue[][][] = await this._dp.getCube(subspace, closest);
    ms.forEach((m: IMetric, midx: number) => {
      let lpHash: { [lid: string]: { [pid: string]: IValue } } = (m.id in this._mlpData) ? this._mlpData[m.id] : (this._mlpData[m.id] = {});
      ls.forEach((l: ILocation, lidx: number) => {
        let pHash: { [pid: string]: IValue } = (l.id in lpHash) ? lpHash[l.id] : (lpHash[l.id] = {});
        ps.forEach((p: IPeriod, pidx: number) => {
          const value: IValue = cube[midx][lidx][pidx];
          pHash[p.id] = value;
        });
      });
    });
  }

  private __loadMetrics(ms: IMetric[], closest?: boolean): Promise<any> {
    const newMs: IMetric[] = diff(ms, this._ms);   // only new
    if (newMs.length === 0) {
      return Promise.resolve();
    }

    this._ms = union(this._ms, ms);

    if (this._ls.length === 0 || this._ps.length === 0) {
      return Promise.resolve();
    }

    return this.__loadCube(newMs, this._ls, this._ps, closest);
  }

  private __loadLocations(ls: ILocation[], closest?: boolean): Promise<any> {
    const newLs: ILocation[] = diff(ls, this._ls);   // only new
    if (newLs.length === 0) {
      return Promise.resolve();
    }

    this._ls = union(this._ls, ls);

    if (this._ms.length === 0 || this._ps.length === 0) {
      return Promise.resolve();
    }

    return this.__loadCube(this._ms, newLs, this._ps, closest);
  }

  private __loadPeriods(ps: IPeriod[], closest?: boolean): Promise<any> {
    const newPs: IPeriod[] = diff(ps, this._ps);   // only new
    if (newPs.length === 0) {
      return Promise.resolve();
    }

    this._ps = union(this._ps, ps);

    if (this._ms.length === 0 || this._ls.length === 0) {
      return Promise.resolve();
    }

    return this.__loadCube(this._ms, this._ls, newPs, closest);
  }

  public async getMatrixYX(subspace: ISubspace, closest?: boolean): Promise<IValue[][]> {
    const {ms, ls, ps} = subspace;

    if (subspace.lookupId || subspace.koob) return this._dp.getMatrixYX(subspace);

    if (this.isNoCache) {
      this._mlpData = {};
      this._ms = [];
      this._ls = [];
      this._ps = [];
    }
    await this.__removeExtras(ms, ls, ps);
    await this.__loadMetrics(ms, closest);
    await this.__loadLocations(ls, closest);
    await this.__loadPeriods(ps, closest);
    const zyxData = subspace.projectData(this);
    return zyxData[0];
  }

  public getNorms(subspace: ISubspace): Promise<INormsResponse> {
    return this._dp.getNorms(subspace);
  }
}

