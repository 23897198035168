import { BaseService, createSingleton } from '@luxms/bi-core';
import React from 'react';

export interface IOpenModalVMOpt {
  readonly cancelWrapper?: boolean;   // default = true,  закрытие модального окна по клику на wrapper
  readonly cancelEsc?: boolean;       // default = true,  кнопка esc не работает на закрытие
  readonly hiddenWrapper?: boolean;   // default = false, наличие wrapper'a
  readonly style?: { [id: string]: string | number };
  readonly className?: string;
  // ... todo дальнейшее расширение
}

export interface IOpenModalVM {
  readonly loading: boolean;
  readonly error: string;
  readonly reactEl: React.ReactElement;
  readonly args: any;
  readonly options: any;
}

/**
 * @param {React.ReactElement} el - JSX element
 * @param {IOpenModalVMOpt}  options - доп опции
 * @description Функция открывает окно, в котором встиавлен реакт эл-т, у которого в props добавляются функции:
 *  onModalCancel: (args: any) => void
 *  onModalResult: (args: any) => void
 * , Функции срабатываают на  openModal.then((args)=> аргументы из onModalResult ).catch((args)=> аргументы из onModalCancel)
 */
export async function openModal(el: React.ReactElement, options?: IOpenModalVMOpt): Promise<any> {
  const service: OpenModalVC = OpenModalVC.getInstance();
  service.setVizel(el, options);
  await service.whenReady();
  return service.getModel().args;
}

export class OpenModalVC extends BaseService<IOpenModalVM> {

  public static getInstance = createSingleton<OpenModalVC>(() => new OpenModalVC(), '__openModalVC');

  private constructor() {
    super({
      loading: false,
      error: null,
      reactEl: null,
      args: null,
      options: null,
    });
  }

  protected _dispose() {
    super._dispose();
  }

  public setVizel = (reactEl: React.ReactElement, options: IOpenModalVMOpt = null): void => {
    this._updateModel({reactEl, loading: true, error: null, options: options, args: null});
  }

  // чтобы в catch перехватить 'ошибку'
  public onModalCancel = (args: any): void => {
    this._updateModel({reactEl: null, args: null, loading: false, error: args ?? 'Cancelled'});
  }

  public onModalResult = (args: any): void => {
    this._updateModel({reactEl: null, args: args, loading: false, error: null});
  }

}