import {tables} from '../defs/bi';
import {ConfigHelper} from '../services/ds/ds-helpers';
import {IUrl, urlState} from '@luxms/bi-core';


// Helper function: build url to dataset using its defaults
export default function buildUrl(uiCfg: { [id: string]: string }, schemaName: string): string {
  const configItems: tables.IConfigItem[] = [];
  for (let key in uiCfg) {
    if (uiCfg.hasOwnProperty(key)) {
      configItems.push({cfg_key: key, cfg_val: uiCfg[key]});
    }
  }
  const ch: ConfigHelper = new ConfigHelper(configItems, null);
  const url: IUrl = ch.getEnterUrl(schemaName);
  const sUrl: string = urlState.buildUrl(url);
  return sUrl;
}