import { IVizelClass } from '../services/ds/types';
import { IVizelDescription } from '../defs/bi';
import { parseVizelTypeString } from './utils';
import { srv } from '@luxms/bi-core';
import ResourcesService = srv.ds.ResourcesService;

async function getResource(fileName: string, schema_name: string): Promise<IVizelClass> {
  const resourcesService = ResourcesService.createInstance(schema_name);
  const fileN = fileName + '.js';
  const fileH = fileName + '.html';
  const fileNameMap = fileN + '.map';
  try {
    await resourcesService.whenReady();
    const resources = resourcesService.getModel();

    for (let res of resources) {
      if ((res.alt_id === fileN || res.alt_id?.endsWith('/' + fileN)) && resources.find(r => r.alt_id.includes(fileNameMap))) {
        const VzlClazz: any = await import(/* webpackChunkName: "vizels" */ `../views/vizels/internal`);
        return VzlClazz.default || VzlClazz;
      }
    }
    for (let res of resources) {
      if (res.alt_id === fileH || res.alt_id.includes('/' + fileH)) {
        const VzlClazz: any = await import(/* webpackChunkName: "vizels" */ `../views/vizels/external`);
        return VzlClazz.default || VzlClazz;
      }
    }
  } catch (err) {
    // ресурсы не загрузились, но это не страшно: игнорируем ошибку
  } finally {
    resourcesService.release();
  }
  return null;
}

export async function loadVizel(vizelType: string, schema_name?: string): Promise<IVizelClass> {
  const descr: IVizelDescription = parseVizelTypeString(vizelType);
  const file: string = descr.file;

  let VzlClazz: any;
  try {
    if (schema_name && schema_name !== 'ds_res') {
      VzlClazz = await getResource(file, schema_name);
      if (VzlClazz) return VzlClazz;
    }
    VzlClazz = await getResource(file, 'ds_res');
    if (VzlClazz) return VzlClazz;
    VzlClazz = await import(/* webpackChunkName: "vizels" */ `../views/vizels/${file}`);
    return VzlClazz.default || VzlClazz;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
