// TODO: move to bi-core

//
// Attachments
//

import { BaseEntitiesService, createObjectsCache, repo } from '@luxms/bi-core';
import * as lpe from '@luxms/lpe';

export class AttachmentsService extends BaseEntitiesService<repo.ds.IRawAttachment> {
  // protected _repo: repo.ds.AttachmentsRepository;

  protected constructor(schemaName: string) {
    super(new repo.ds.AttachmentsRepository(schemaName));
  }

  public findById(id: string | number): repo.ds.IRawAttachment {
    return this._model.entities.find(e => String(e.id) === String(id));
  }

  public static unpackPeriodTypeWhere(expr): number[] {
    const idsMap: Partial<any> = this.unpackWhere(expr);
    if (!('id' in idsMap)) return [];
    return idsMap.id;
  }

  public static packPeriodTypeWhere(value: number[]): string {
    return this.packWhere({ id : value});
  }

  public static unpackWhere(expr) {
    const matches = ['id', 'parent_id', 'tree_level'];

    const callback = (arr) => {
      if (arr[0] !== '=') return;
      if (matches.indexOf(arr[1]) == -1) return;
      if (arr[2][0] !== '[') return;
      r[arr[1]] = arr[2].slice(1);
    };

    const sexprWalk = (arr: any) => {
      callback(arr);
      for (let i = 1; i < arr.length; i++) {
        const operand = arr[i];
        if (Array.isArray(operand)) {
          sexprWalk(operand);
        }
      }
    };

    const r = {};
    if (!expr) return r;
    const sexpr = lpe.parse(expr);
    sexprWalk(sexpr);
    return r;
  }

  public static packWhere(idsMap) {
    const keys = Object.keys(idsMap);
    if (keys.length == 0) return null;
    const sexpr = keys.filter(k => Array.isArray(idsMap[k]) && idsMap[k].length != 0).map(k => {
      return ['=', k, ['[', ...idsMap[k]]];
    });
    if (sexpr.length == 0) return null;
    return lpe.deparse(['where', ['or', ...sexpr]]);
  }

  public static unpackDuring(during) {
    return during.match(/([\d\-: ]{10,19})/g);
  }

  public static packDuring(values) {
    const pack = (value) => {
      if (value == '') return value;
      return `"${value} 00:00:00"`;
    };
    return `[${pack(values[0])},${pack(values[1])})`;
  }

  protected _dispose() {
    AttachmentsService._cache.remove(this._schemaName);
    super._dispose();
  }

  private static _cache = createObjectsCache(id => new AttachmentsService(String(id)), '__attachmentsServices');

  public static createInstance: (schema_name: number | string) => AttachmentsService = AttachmentsService._cache.get;

  public static readonly INITIAL_ENTITY: repo.ds.IRawAttachment = {
    id : null,
    title : null,
    attachment_type: 'vcp-lookup-table',
    data_source : null,
    config : {},
    payload : null,
    alt_payload : null,
    m_where : null,
    l_where : null,
    pt_where : null,
    during : '(,)',
  };
}
