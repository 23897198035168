exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[3].use[1]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../assets/fonts/GolosUI/GolosUI_Regular.css"), "");
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[3].use[1]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../assets/fonts/GolosUI/GolosUI_Medium.css"), "");
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[3].use[1]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../assets/fonts/GolosUI/GolosUI_Bold.css"), "");

// module
exports.push([module.id, "", ""]);

// exports
