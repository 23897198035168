import React from 'react';
import SVGIcon from '../SVGIcon';
import useService from '../../useService';
import ResourceLocatorService from '../../../services/ResourceLocatorService';
import {AppConfig} from '@luxms/bi-core';

const IconSpriteLoader: React.FC<{}> = () => {
  const resSprite = useService<ResourceLocatorService>(ResourceLocatorService, 'ds_res', 'sprite.svg');
  const dsSprite = useService<ResourceLocatorService>(ResourceLocatorService, null, 'sprite.svg');

  return (
    <React.Fragment>
      {!!dsSprite.resource &&
        <SVGIcon key="ds-svg-sprite" className="SVGSprite" path={AppConfig.fixRequestUrl(`/srv/resources/${dsSprite.schema_name}/sprite.svg?ts=${dsSprite.resource.updated}`)}/>}

      {!!resSprite.resource &&
        <SVGIcon key="ds_res-svg-sprite" className="SVGSprite" path={AppConfig.fixRequestUrl(`/srv/resources/${resSprite.schema_name}/sprite.svg?ts=${resSprite.resource.updated}`)}/>}

      <SVGIcon key="default-svg-sprite" path={require('../../../../assets/icons/sprite.svg')} className="SVGSprite"/>
    </React.Fragment>
  );
};

export default IconSpriteLoader;