/// <reference path="../defs/bi.d.ts" />

/**
 *  Modal container
 *   Define viewmodel for modal window that shows one vizel
 *
 * Usage:
 *     import {modalContainer} from './modal-container';
 *   or
 *     const  modalContainer = (await import('./modal-container')).modalContainer;
 *     ...
 *
 *     var plot = new Plot();   // any vizel: plot, grid, pie
 *     modalContainer.vizel(plot);
 *
 *
 * if plot has save function, save button is visible
 *
 */

import * as React from 'react';
import cn from 'classnames';
import $ from 'jquery';
import { UrlState } from '@luxms/bi-core';
import { lang } from '../utils/utils';
import { BIIcon } from './components/BIIcon/BIIcon';
import { tables } from '../defs/bi';
import './ModalContainer.scss';
import Vizel from './components/Vizel';
import { IVizelProps } from '../services/ds/types';


const IconBack = ({className, onClick}) => (
  <a href="javascript:void(0)"
     type="button"
     className={className}
     onClick={onClick}>
    <svg version="1.1" x="0px" y="0px" width="24px" height="22px" viewBox="0 0 24 22" enableBackground="new 0 0 24 22">
      <rect x="3.732" y="9.334" width="20.268" height="4"/>
      <rect x="-0.844" y="4.99" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.9321 6.9018)" width="15.418" height="4"/>
      <rect x="-0.845" y="13.055" transform="matrix(0.7071 0.7071 -0.7071 0.7071 12.6556 -0.444)" width="15.418" height="4"/>
    </svg>
  </a>);


interface  IToolbarButtonProps {
  id: number | string;
  title: string;
  icon: string;
  active: boolean;
  onClick: () => any;
}

const ToolbarButton = ({title, icon, active, onClick}: IToolbarButtonProps) => (
  <span className="ModalContainer__ToolbarButton"
        onClick={onClick}>
    <BIIcon className={cn('dark', {active})}
            hint={title}
            icon={icon}/>
  </span>);


// define data for modal container
interface IVizelItem {
  vizel: IVizelProps;
  title: string;
}

interface IModalContainerRProps {}

interface IModalContainerState {
  title: string;
  vizel: IVizelProps | null;
  stack: IVizelItem[];
  toolbar: any[];
  saveAbility: string;
}

export class ModalContainer extends React.Component<IModalContainerRProps, IModalContainerState> {
  public state: IModalContainerState = {
    title: '',
    vizel: null,
    stack: [],
    toolbar: [],
    saveAbility: '',
  };
  private _vizelRef: Vizel | null = null;

  public constructor(props: IModalContainerRProps) {
    super(props);
    modalContainer = this;                                                                          // HACK

    //
    // close modal container when navigate out
    //
    UrlState.subscribe('segment segmentId route', () => {
      this.setState({vizel: null, title: '', stack: [], toolbar: [],  saveAbility: '' });
    });
  }

  public resize() {
    if (this._vizelRef) {
      this._vizelRef.resize();
    }
  }

  public onVizelPropertiesChanged = (properties: any, vizel: any) => {
    // TODO: handle height/width

    if (properties?.saveAbilities?.length) {
      this.setState({saveAbility: properties.saveAbilities[0]});
    }

    if (properties?.toolbarButtons?.length) {
      this.setState({toolbar: properties.toolbarButtons});
    }
  }

  // TODO: вызывать также когда vizel изменился
  private _setupVizelRef = (vizel: Vizel | null) => {
    this._vizelRef = vizel;
    if (vizel) {
      if (vizel.getProperties) this.onVizelPropertiesChanged(vizel.getProperties(), vizel);
      vizel.addListener(this);
    }
  }

  public push(pVizel: IVizelProps, pTitle?: string | string[]): void {

    if ((pVizel as any).props || (pVizel as any)._props) {
      console.warn('modalContainer::push - объект визеля!');
      debugger;
    }

    let {vizel, title, stack} = this.state;
    if (vizel) {
      stack = [...stack, {
        vizel: vizel,
        title: title,
      }];
    }
    vizel = pVizel;
    title = Array.isArray(pTitle) ? pTitle.join(' / ') : pTitle;
    this.setState({vizel, title, stack, toolbar: [], saveAbility: ''});
  }

  public async pushVizelConfig(rawVizelConfig: tables.IRawVizelConfig, title?: string | string[]): Promise<any> {
    const {CurrentDsStateService} = await import('../services/ds/CurrentDsStateService');
    const currentDsStateService = CurrentDsStateService.getInstance();
    await currentDsStateService.whenReady();
    const {dataset} = currentDsStateService.getModel();
    const cfg = dataset.createVizelConfig(rawVizelConfig);
    const createSubspaceGenerator = (await import('../services/ds/createSubspaceGenerator')).createSubspaceGenerator;
    const subspacePtr = cfg.getSubspacePtr();
    let subspaceSubscription = createSubspaceGenerator(dataset.schema_name, subspacePtr, false, (subspace) => {
      this.push({
        dp: dataset.getBiQuery().getDataProvider(),
        cfg: cfg,
        subspace,
      }, title);
      subspaceSubscription.dispose();
      subspaceSubscription = null;
    });
  }

  public pop = () => {
    let { vizel, title, stack } = this.state;
    if (vizel) {
      this._runVizelCloseCallback(vizel);
    }

    if (stack.length) {
      stack = [...stack];
      const stackItem = stack.pop();
      vizel = stackItem.vizel;
      title = stackItem.title;
    } else {
      vizel = null;
      title = '';
    }
    this.setState({vizel, title, stack, toolbar: [], saveAbility: ''});
  }

  public hide = () => {
    let { vizel, title, stack } = this.state;

    if (vizel) {
      this._runVizelCloseCallback(vizel);
    }
    let stackVizels = stack.map(vi => vi.vizel);
    stackVizels.forEach(this._runVizelCloseCallback);

    stack = [];
    vizel = null;
    title = '';

    this.setState({vizel, title, stack, toolbar: [], saveAbility: ''});
  };

  // TODO: remove onModalContainerClose HACK
  private _runVizelCloseCallback(vizel: any): void {
    // TODO: add real event listeners!
    if (vizel.onModalContainerClose) {
      try {
        vizel.onModalContainerClose(vizel);
      } catch (err) {
        console.error(err.stack);
      }
    }
  }

  private _onSave = (event) => {
    const {vizel, title, saveAbility} = this.state;
    if (!vizel || !saveAbility || !this._vizelRef) {
      return;
    }

    // [esix] - теперь это не работает, требуется убрать saveHandler(event) из plotContainer
    // special case: plot container has ith own saveHandler(event)
    if (vizel['saveHandler']) {
      return vizel['saveHandler'](event);
    }

    let $anchor: JQuery = (event.target.tagName === 'A') ? $(event.target) : $(event.target).parents('a');

    const titleContext = [title];
    try {                                                                                         // HACK: add state.datasetTitle to context
      const dsTitle = this._vizelRef.props.cfg.dataset.title;
      titleContext.unshift(dsTitle);
    } catch (err) {
      //
    }

    this._vizelRef.save(saveAbility, titleContext, $anchor);
    return true;
  };

  public render() {
    const { title, vizel, stack, toolbar, saveAbility } = this.state;
    if (!vizel) return null;

    let cfg = null, subspace = null, dp = null;
    if (vizel.cfg && vizel.subspace && vizel.dp) {
      cfg = vizel.cfg;
      subspace = vizel.subspace;
      dp = vizel.dp;
    } else if (vizel && (vizel as any).props) {
      console.warn('В modalContainer приходит объект визеля!');
      cfg = (vizel as any).props.cfg;
      subspace = (vizel as any).props.subspace;
      dp = (vizel as any).props.dp;
    } else if (vizel && (vizel as any)._props) {
      console.warn('В modalContainer приходит объект визеля! (2)');
      cfg = (vizel as any)._props.cfg;
      subspace = (vizel as any)._props.subspace;
      dp = (vizel as any)._props.dp;
    } else {
      debugger;
    }

    if (!cfg || !subspace || !dp) {
      return null;
    }

    return (
      <>
        <div className="ModalContainer__Shadow" onClick={this.hide}/>
        <dialog className="ModalContainer view components modal-container bi-modal"
                data-bind="hideOnEscape: $data.visible()">
          <header className="ModalContainer__Header modal-header" style={{position: 'absolute', left: 0, right: 0, padding: 0, overflow: 'hidden'}}>

            {!!stack.length &&
            <IconBack className="ModalContainer__ButtonBack btn btn-back bi-icon dark"
                      onClick={this.pop}/>}

            {/* toolbar */}
            <span className="ModalContainer__Toolbar toolbar">
              {toolbar.map((btn, idx) =>
              <ToolbarButton {...btn} key={idx}/>)}
            </span>

            <h4 className="ModalContainer__HeaderTitle modal-title"
                dangerouslySetInnerHTML={{__html: title}}/>

            <BIIcon className="ModalContainer__ButtonClose dark"
                    icon="x"
                    onPress={this.hide} />
          </header>

          <article className="ModalContainer__Body modal-body">
            <Vizel ref={this._setupVizelRef}
                   dp={dp}
                   cfg={cfg}
                   subspace={subspace}
                   onVizelPropertiesChanged={this.onVizelPropertiesChanged} />
          </article>

          <footer className="ModalContainer__Footer">

            {/* [esix]: TODO - найти что такое filterVM и имплементировать на react
            <!-- ko if: vizel() && vizel().filterVM && vizel().filterVM() -->
            <!-- ko compose: {model: vizel().filterVM()} -->
            <!-- /ko -->
            <!-- /ko -->
            */}

            {!!saveAbility &&
            <a className="btn btn-primary buttonContain buttonContain--wide" onClick={this._onSave}>
              <span className="buttonContain__text"
                    title={lang('save-' + saveAbility)}>{lang('save-' + saveAbility)}</span>
            </a>}

            <span className="buttonWrapper buttonContain--wide">
              <button type="button" className="btn btn-default" onClick={this.hide}>{lang('close')}</button>
            </span>
          </footer>
        </dialog>
      </>);
  }
}


export var modalContainer: ModalContainer = null;

// import('./Shell').then((shell_module) => {
//   shell_module.shell.modalContainer(modalContainer);
// });
//

export default modalContainer;

