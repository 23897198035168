/**
 *
 *
 */
import { createNullVector, isNullMatrix, matrixHasNumericData } from './data-utils';
import { getEntity } from '../libs/imdas/list';
import { IDataMatrix, INormsResponse, INormZone } from './data-manip';


export interface ICreateDataMatrix {
  xs: IEntity[];
  ys: IEntity[];
  z: IEntity;
  matrix: IValue[][];
  normsResponses: INormsResponse[];
}


export function createDataMatrix({xs, ys, z, matrix, normsResponses}: ICreateDataMatrix): IDataMatrix {
  console.assert(z ? matrix != null : matrix == null);
  console.assert(z ? (matrix.length === ys.length) : true);

  const getYIndex = (y: number | IEntity): number => {
    let idx: number;
    if (typeof y === 'number') {
      idx = y;
      if (idx < 0) {
        idx = ys.length - idx;
      }
    } else {
      idx = ys.indexOf(y);
    }
    console.assert(0 <= idx && idx < ys.length);
    return idx;
  };

  const getXIndex = (x: number | IEntity): number => {
    let idx: number;
    if (typeof x === 'number') {
      idx = x;
      if (idx < 0) {
        idx = xs.length - idx;
      }
    } else {
      idx = xs.indexOf(x);
    }
    console.assert(0 <= idx && idx < xs.length);
    return idx;
  };

  let hasData: boolean = null;
  let hasNumericData: boolean = null;

  return {
    xs,
    ys,
    z,
    normsResponses,
    matrix,
    getX: (idx: number | string): IEntity => getEntity(xs, idx),
    getY: (idx: number | string): IEntity => getEntity(ys, idx),
    getVectorY: (x: number | IEntity): IValue[] => {
      if (!matrix) {  // z is null?
        return createNullVector(ys.length);
      }
      const idx: number = getXIndex(x);
      return matrix.map((vec: IValue[]) => vec[idx]);
    },
    getVectorX: (y: number | IEntity): IValue[] => {
      if (!matrix) {  // z is null?
        return createNullVector(xs.length);
      }
      const idx: number = getYIndex(y);
      return matrix[idx] || null;
    },
    getNormsResponse: (y: number | IEntity): INormsResponse => {
      const idx: number = getYIndex(y);
      return normsResponses ? normsResponses[idx] : null;
    },
    hasData: (): boolean => {
      if (hasData === null) {
        hasData = matrix ? !isNullMatrix(matrix) : false;
      }
      return hasData;
    },
    hasNumericData: (): boolean => {
      if (hasNumericData === null) {
        hasNumericData = matrix ? matrixHasNumericData(matrix) : false;
      }
      return hasNumericData;
    },
  };
}


export interface ICreateDataMatrixWithOrder {
  xs: IEntity[];
  ys: IEntity[];
  z: IEntity;
  matrix: IValue[][];
  normsResponses: INormsResponse[];
  xOrder: number[];
  yOrder: number[];
}


export function createDataMatrixWithOrder({xs, ys, z, matrix, normsResponses, xOrder, yOrder}: ICreateDataMatrixWithOrder): IDataMatrix {

  // fix order in norms responses
  if (normsResponses) {
    normsResponses = normsResponses.map((nr: INormsResponse) => {
      if (!nr) {
        return null;
      }
      const zones: INormZone[] = nr.getZones().map((z: INormZone) => ({
        id: z.id,
        normTitle: z.normTitle,
        title: z.title,
        // metric: z.metric,
        color: z.color,
        bgColor: z.bgColor,
        hasInf: z.hasInf,
        hasSup: z.hasSup,
        infTitle: z.infTitle,
        supTitle: z.supTitle,
        infColor: z.infColor,
        supColor: z.supColor,
        hasValue: (v: number, e?: IEntity): boolean => z.hasValue(v, e),
        getInfData: (): number[] => {
          const vector: number[] = z.getInfData();
          return xOrder.map((idx: number) => vector[idx]);
        },
        getSupData: (): number[] => {
          const data: number[] = z.getSupData();
          return xOrder.map((idx: number) => data[idx]);
        },
        fake: z.fake,
      }));
      return {
        getZones: (): INormZone[] => zones,
        getColor: (e: IEntity, v: number): string => nr.getColor(e, v),
        getBgColor: (e: IEntity, v: number): string => nr.getBgColor(e, v),
        getColorPair: (e: IEntity, v?: IValue): IColorPair => nr.getColorPair(e, v),
        getTitle: (e: IEntity, v: number): string => nr.getTitle(e, v),
      };
    });
  }
  xs = xOrder.map((xi: number) => xs[xi]);
  ys = yOrder.map((yi: number) => ys[yi]);

  if (matrix) {
    matrix = yOrder.map((yi: number) => xOrder.map((xi: number) => matrix[yi][xi]));
  }

  return createDataMatrix({
    xs,
    ys,
    z,
    matrix,
    normsResponses,
  });
}
