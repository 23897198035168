import React, {useEffect, useRef, useState} from 'react';
import { RoughSVG } from 'roughjs/bin/svg';
import './DbRelations.scss';
import ReactResizeDetector from 'react-resize-detector';
import db_relations from './services';
import {dfs} from './DbRelationsUtils';
import cn from 'classnames';
import ContextMenu from '../ContextMenu/ContextMenu';

const getTableId = (columnId): string => columnId.split('.').slice(0, 3).join('.');
const abs = Math.abs, min = Math.min, max = Math.max;


export interface IDbLink {
  readonly from: string;
  readonly to: string;
  readonly width?: number;
  readonly x?: number;
  readonly y?: number;
}

interface IDbRelationsProps {
  readonly className?: string;
  readonly onChange: (tables: any, links: IDbLink[]) => any;
  readonly tables?:  {sourceId: string, schema: string, name: string}[];
  readonly renderTables?: IRenderTable[];
  readonly setRenderTables?: (renderTables: IRenderTable[]) => any;
  readonly ident?: string;
}


abstract class Element<P> extends React.PureComponent<P> {
  public state = {
    content: null,
  };
  private _rc: any = null;

  public constructor(props: P) {
    super(props);
    this._rc = new RoughSVG({} as any, {});
  }

  protected abstract _create(rc: any): any;

  public render() {
    const { className, tabIndex } = this.props as any;
    const node = this._create(this._rc);
    if (className) {
      Array.from(node.children).forEach((child: any) => child.classList.add(className));
    }
    if (tabIndex !== undefined) {
      node.childNodes[0]?.setAttribute('tabIndex', tabIndex);
    }
    return (
      <g dangerouslySetInnerHTML={{__html: node.innerHTML}}/>);
  }
}

class Rectangle extends Element<{ x: number; y: number; width: number; height: number; options?: any }> {
  protected _create(rc): any {
    return rc.rectangle(this.props.x, this.props.y, this.props.width, this.props.height, this.props.options);
  }
}

class Circle extends Element<{ x: number; y: number; diameter: number; options?: any; className?: string}> {
  protected _create(rc): any {
    return rc.circle(this.props.x, this.props.y, this.props.diameter, this.props.options, this.props.className);
  }
}

class Path extends Element<{ d: string; className?: string; options?: any; tabIndex?: number }> {
  protected _create(rc): any {
    return rc.path(this.props.d, this.props.options);
  }
}


interface IRenderTable {
  readonly x: number;
  readonly y: number;
  readonly id: string;
  readonly tableInfo: db_relations.ITableInfo;
}

const TABLE_WIDTH = 160;


interface ITableProps {
  readonly x: number;
  readonly y: number;
  readonly table: db_relations.ITableInfo;
  readonly onStartDrag: (event, table: string) => any;
  readonly onStartDragColumn: (event, columnId: string) => any;
  readonly onMouseEnterColumn: (event, columnId) => any;
  readonly onMouseLeaveColumn: (event, columnId) => any;
  readonly hiliteColumnDraggingFrom: string | undefined;
  readonly hiliteColumnDraggingTo: string | undefined;
  readonly onCloseTable: (event, id: string) => any;
  readonly focusLinkFrom: string[];
  readonly focusLinkTo: string[];
}

const Table = React.memo(({x, y, table, onStartDrag, onStartDragColumn, onMouseEnterColumn, onMouseLeaveColumn, hiliteColumnDraggingFrom, hiliteColumnDraggingTo, onCloseTable, focusLinkFrom, focusLinkTo}: ITableProps) => {
  const {error, loading, id, name, columns, primaryKeys} = table;
  if (error) return <TableLoading x={x} y={y} name={error}/>;
  if (loading) return <TableLoading x={x} y={y} name={table.name}/>;

  const height = 30 + columns.length * 20;
  const onHeaderPointerDown = (event): void => {
    onStartDrag(event, id);
    event.stopPropagation();
    event.preventDefault();
  };

  let primaryKeysNoRepeat = [];
  primaryKeys.map(key => {
    if (primaryKeysNoRepeat.indexOf(key.name) === -1) {
      primaryKeysNoRepeat.push(key.name);
    }
  });

  let ongoingLinksNoRepeat = [];
  table.ongoingLinks.map(key => {
    if (ongoingLinksNoRepeat.indexOf(key.from) === -1) {
      ongoingLinksNoRepeat.push(key.from);
    }
  });

  return (
    <g className="DbRelations__Table" transform={`translate(${x}, ${y})`}>
      <rect className="DbRelations__TableBackground" x={0} y={0} width={TABLE_WIDTH} height={height} fill="#ffffff"/>
      <Rectangle x={0} y={0} width={TABLE_WIDTH} height={height} options={{stroke: '#646464'}}/>
      <Rectangle x={0} y={0} width={TABLE_WIDTH} height={30}
                 options={{fill: '#646464', fillWeight: 0.2, stroke: 'none'}}/>
      <text x={5} y={20} fontSize={16}
            clipPath={`polygon(0px 0px,0px 26px,${TABLE_WIDTH - 20} 26px,${TABLE_WIDTH - 20} 0px,0px 0px)`}>{name}</text>
      {columns.map((column, i) => {
        const columnId = [column.schema, column.table, column.name].join('.');
        const isActiveColumnFrom = columnId === hiliteColumnDraggingFrom || focusLinkFrom.includes(columnId);
        const isActiveColumnTo = columnId === hiliteColumnDraggingTo || focusLinkTo.includes(columnId);
        return <>
          {(isActiveColumnFrom || isActiveColumnTo) &&
            <Rectangle x={0} y={30 + i * 20}
                       width={TABLE_WIDTH}
                       height={20}
                       options={{fill: '#3BB8D0', fillWeight: 0.2, stroke: 'none'}}/>}
          <text key={column.name}
                x={5} y={45 + i * 20} fontSize={12}
                fontWeight={column.primaryKey ? 'bold' : 'normal'}
                clipPath={`polygon(0px 0px,0px 20px,${TABLE_WIDTH - 20} 20px,${TABLE_WIDTH - 20} 0px,0px 0px)`}>
            {column.name}
          </text>
          {ongoingLinksNoRepeat.map(tl => tl.indexOf(`.${column.name}`) !== -1 &&
            <Circle key={i + column.name} x={TABLE_WIDTH - 15} y={40 + i * 20} diameter={10}
                    options={{fill: 'rgba(119, 221, 119, 0.7)', fillWeight: 3}}/>)}
          {primaryKeysNoRepeat.map(key => column.primaryKey == key.name && column.primaryKey !== columns[i - 1]?.primaryKey &&
            <Rectangle x={0} y={50 + (i - 1) * 20}
                       width={TABLE_WIDTH}
                       height={1}
                       options={{fill: '#3BB8D0', fillWeight: 0.2, stroke: 'none'}}/>)}
          <rect className="DbRelations__Column"
                x={0} y={30 + i * 20}
                width={TABLE_WIDTH}
                height={20}
                fill="rgba(0, 0, 0, 0)"
                style={{cursor: 'crosshair'}}
                onPointerEnter={(event) => onMouseEnterColumn(event, id + '.' + column.name)}
                onPointerLeave={(event) => onMouseLeaveColumn(event, id + '.' + column.name)}
                onPointerDown={(event) => onStartDragColumn(event, id + '.' + column.name)}/>
        </>;
      })}

      <rect className="DbRelations__TableHeader"
            x={0} y={0}
            width={TABLE_WIDTH}
            height={30}
            fill="rgba(0, 0, 0, 0)"
            onPointerDown={onHeaderPointerDown}/>
      <g className="DbRelations__Table__Buttons close"  onClick={(e) => onCloseTable(e, id)} >
        <Circle x={TABLE_WIDTH} y={0} diameter={18} options={{fill: 'rgba(202, 122, 98, 0.7)', fillWeight: 3}}/>
        <circle cx={TABLE_WIDTH} y={0} r={12} fill={'rgba(202, 122, 98, 0.8)'} opacity={0}/>
      </g>
    </g>);
});


const TableLoading = ({x, y, name}: {x: number; y: number; name: string}) => {
  return (
    <g className="DbRelations__Table loading" transform={`translate(${x}, ${y})`}>
      <rect className="DbRelations__TableBackground" x={0} y={0} width={TABLE_WIDTH} height={TABLE_WIDTH}
            fill="#ffffff"/>
      <Rectangle x={0} y={0} width={TABLE_WIDTH} height={TABLE_WIDTH} options={{stroke: '#646464'}}/>
      <Rectangle x={0} y={0} width={TABLE_WIDTH} height={30}
                 options={{fill: '#646464', fillWeight: 0.2, stroke: 'none'}}/>
      <text x={5} y={20} fontSize={16}
            clipPath={`polygon(0px 0px,0px 20px,${TABLE_WIDTH - 20} 20px,${TABLE_WIDTH - 20} 0px,0px 0px)`}>{name}</text>

      <g id="Preloader" transform="translate(30 40) scale(0.3)">
        <g className="Preloader__Col">
          <Path className="Preloader__Col1"
                options={{fill: '#E07921', stroke: '#646464'}}
                d="M22 180l-0 0c-12,0 -22,10 -22,22l0 56c0,12 10,22 22,22l0 0c12,0 22,-10 22,-22l0 -56c0,-12 -10,-22 -22,-22z"/>
          <Path className="Preloader__Col2"
                options={{fill: '#4AB6E8', stroke: '#646464'}}
                d="M97 100l-0 0c-12,0 -22,10 -22,22l0 132c0,12 10,22 22,22l0 0c12,0 22,-10 22,-22l0 -132c0,-12 -10,-22 -22,-22z"/>
          <Path className="Preloader__Col3"
                options={{fill: '#4F4F9B', stroke: '#646464'}}
                d="M172 39l0 0c-12,0 -22,10 -22,22 0,41 0,83 0,124 -0,1 -0,8 0,8 1,0 4,-5 5,-5 1,-2 3,-3 4,-5 10,-10 20,-17 32,-22 3,-1 3,-2 3,-5 -0,-15 -0,-30 -0,-46 0,-17 0,-33 0,-50 0,-12 -10,-22 -22,-22z"/>
          <Path className="Preloader__Col4"
                options={{fill: '#AA6FAC', stroke: '#646464'}}
                d="M248 0l0 0c-12,0 -22,10 -22,22 0,41 0,81 -0,122 -0,1 -0,6 0,7 1,1 6,0 8,0 11,-0 22,1 33,5 4,1 4,1 4,-3 -0,-9 -0,-19 -0,-28 0,-34 0,-68 0,-102 0,-12 -10,-22 -22,-22z"/>
        </g>

        <g className="Preloader__Sector">
          <Path className="Preloader__Sector2"
                options={{fill: '#5FB138'}}
                d="M318 242c1,-0 6,-0 7,-1 1,-2 -4,-16 -5,-19 -5,-12 -13,-23 -23,-32 -14,-12 -29,-19 -46,-22 -2,-0 -6,-2 -7,0 -1,1 -0,7 -0,9 0,21 0,43 -0,64 -0,1 -0,7 1,7 1,1 6,-0 8,-0 22,-2 44,-5 66,-7z"/>
          <Path className="Preloader__Sector3"
                options={{fill: '#F05045'}}
                d="M222 284c2,-8 4,-16 5,-24 1,-4 0,-7 0,-11 -0,-24 -0,-49 0,-73 0,-2 1,-7 -1,-8 -1,-1 -7,0 -9,1 -18,4 -34,12 -47,25 -17,17 -27,39 -27,65 0,22 8,42 20,58 11,13 25,23 41,29 4,2 4,-0 5,-4 1,-7 3,-14 4,-21 2,-10 4,-21 7,-31 0,-2 1,-3 1,-5l0 -1z"/>
          <Path className="Preloader__Sector4"
                options={{fill: '#E85498'}}
                d="M246 266c-4,0 -4,2 -5,6 -1,7 -3,14 -4,20 -2,7 -3,14 -4,21 -1,4 -2,7 -2,10 -2,8 -3,16 -5,23 -1,3 -0,3 3,4 3,0 5,0 8,0 25,0 48,-10 65,-27 17,-17 27,-40 27,-64 0,-2 -0,-2 -3,-2 -7,1 -14,2 -21,2 -19,2 -39,4 -58,6z"/>
        </g>
      </g>
    </g>);
};


const CLink = React.memo(({x1, y1, d1, x2, y2, d2, width, onDeleteLink, onFocusLink, focusLink, idx } : {x1: number, y1: number, d1: number, x2: number, y2: number, d2: number, width: number, onDeleteLink: (e: any, direction?: string) => void, onFocusLink?: (e: any, active: boolean, type: string) => void, focusLink?: boolean, idx?: string}) => {

  const ref = useRef<SVGPathElement>(null);
  const [direction, setDirection] = useState('');

  useEffect(() => {
    const onClick = (e: any) => {
      const active = ref.current.contains(e.target);
      const cn = e.target.className.baseVal;
      const type = cn?.includes(idx) ? 'main' : cn?.includes('pathLinkTransparent') ? 'others' : '';
      onFocusLink(e, active, type);
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [onFocusLink, ref]);

  const ra = min(width, abs(y2 - y1) / 2), xa = x1 + d1 * (width - 10 + ra), ya = y1;
  const rb = min(width, abs(y2 - y1) / 2), xb = x2 + d2 * (width - 10 + rb), yb = y2;
  let className = 'pathLinkTransparent';

  const onKeyDown = (event): void => {
    if (event.key === 'Delete') {
      onDeleteLink(event);
    }
  };

  const onContextMenu = (event): void => {
    event.stopPropagation();
    event.preventDefault();
    ContextMenu.show(event, [{
      title: 'Удалить',
      action: () => onDeleteLink(event),
    }], {arrow: false, placement: 'bottom-end'});
  };

  const onDownLink = (e: any, direction: string): void => {
    e.stopPropagation();
    e.preventDefault();
    setDirection(direction);
    onDeleteLink(e, direction);
  };

  const onMoveLink = (e: any): void => {
    if (!direction) return;
    // e.stopPropagation();
    // e.preventDefault();
    // onDeleteLink(e, direction);
  };

  const d = `
     M ${x1} ${y1}
     L ${xa - d1 * ra} ${y1}
     C ${xa} ${ya}, ${xa} ${ya}, ${xa * 0.8 + xb * 0.2} ${ya * 0.8 + yb * 0.2}
     L ${xa * 0.2 + xb * 0.8} ${ya * 0.2 + yb * 0.8}
     C ${xb} ${yb}, ${xb} ${yb}, ${xb - d2 * rb} ${y2}
     L ${x2} ${y2}
     L ${x2 + d2 * 10} ${y2 + d1 * 5}
     M ${x2 + d2 * 10} ${y2 - d1 * 5}
     L ${x2} ${y2}`;

  const link = <Path d={d} options={{stroke: '#646464'}}/>;
  const pathLinkTransparent = <path className={cn(className, {focusLink: focusLink}, idx)}
                                    d={d}
                                    stroke="rgb(0, 0, 0, .0)"
                                    strokeWidth={20}
                                    tabIndex={10}
                                    fill="none"
                                    onKeyDown={onKeyDown}
                                    onContextMenu={focusLink ? onContextMenu : null}
                                    ref={ref}/>;

  return (
    <g>
      {pathLinkTransparent}
      {link}
      <circle className={'pathLinkMove from'} r={10} cx={x1 - d2 * 10} cy={y1} onPointerMove={onMoveLink}
              onPointerDown={(e) => onDownLink(e, 'from')}/>
      <circle className={'pathLinkMove to'} r={10} cx={x2 + d2 * 10} cy={y2} onPointerMove={onMoveLink}
              onPointerDown={(e) => onDownLink(e, 'to')}/>
    </g>);
});

const Link = React.memo(({link, renderTables, onDeleteLink, focusLink, onFocusLink}: {link: IDbLink; renderTables: IRenderTable[]; onDeleteLink: (e: any, link: IDbLink, direction?: string) => void, onFocusLink: (e: any, active: boolean, link: IDbLink, type: string) => void, focusLink?: boolean}) => {
  let [sourceIdFrom, schemaFrom, tableFromName, columnFromName] = link.from.split('.');
  let [sourceIdTo, schemaTo, tableToName, columnToName] = link.to.split('.');
  let tableFrom = renderTables.find(t => t.id === `${sourceIdFrom}.${schemaFrom}.${tableFromName}`);
  let tableTo = renderTables.find(t => t.id === `${sourceIdTo}.${schemaTo}.${tableToName}`);
  if (!tableFrom || !tableTo) return null;

  const dx1 = abs(tableFrom.x - tableTo.x), dx2 = abs(tableFrom.x - tableTo.x - TABLE_WIDTH),
    dx3 = abs(tableFrom.x + TABLE_WIDTH - tableTo.x);
  let d1 = dx2 > dx1 ? 1 : -1;
  let x1 = tableFrom.x + (d1 === 1 ? TABLE_WIDTH : 0);
  let y1 = tableFrom.y + 40 + 20 * tableFrom.tableInfo.columns.findIndex(col => col.name === columnFromName);

  let d2 = dx3 > dx1 ? 1 : -1;
  let x2 = tableTo.x + (d2 === 1 ? TABLE_WIDTH : 0);
  let y2 = tableTo.y + 40 + 20 * tableTo.tableInfo.columns.findIndex(col => col.name === columnToName);

  return <CLink idx={link.from + link.to} x1={x1} y1={y1} d1={d1} x2={x2} y2={y2} d2={d2} width={link.width} focusLink={focusLink}
                onDeleteLink={(event, active?) => {onDeleteLink(event, link, active); }}
                onFocusLink={(event, active, type) => onFocusLink(event, active, link, type)}/>;

});


const DraggingLink = React.memo(({draggingLink, direction, renderTables, onDeleteLink}: {draggingLink: any; direction: string, renderTables: IRenderTable[]; onDeleteLink: any} ) => {
  if (!draggingLink || !direction) return null;
  // const direction = !!draggingLink.from ? 'from' : 'to';

  let [sourceId, scheme, tableName, columnName] = draggingLink[direction]?.split('.') ?? [];
  const tableId = `${sourceId}.${scheme}.${tableName}`;
  let table = renderTables.find(t => t.id === tableId);

  if (!table) return null;

  const isFrom = direction === 'from' && !!draggingLink.from;

  const startD = isFrom && draggingLink.x > table.x + TABLE_WIDTH / 2 ? 1 : -1;
  const startX = table.x + (startD === 1 ? TABLE_WIDTH : 0);
  const startY = table.y + 40 + 20 * table.tableInfo.columns.findIndex(col => col.name === columnName);

  const endD = isFrom ? draggingLink.x < startX ? 1 : -1 : draggingLink.x < table.x + TABLE_WIDTH / 2 ? 1 : -1;
  const endX = draggingLink.x + endD * 10;
  const endY = draggingLink.y;

  const start = [startD, startX, startY], end = [endD, endX, endY];

  let d1, x1, y1, d2, x2, y2;

  if (isFrom) [d1, x1, y1, d2, x2, y2] = [...start, ...end];
  else [d1, x1, y1, d2, x2, y2] = [...end, ...start];


  return <CLink x1={x1} y1={y1} d1={d1} x2={x2} y2={y2} d2={d2} width={40} onDeleteLink={onDeleteLink} />;

});


class DbRelations extends React.Component<IDbRelationsProps> {
  public state: {
    width: number;
    height: number;
    x: number;
    y: number;
    zoom: number;
    renderTables: IRenderTable[];
    links: IDbLink[];
    draggingTable: string;
    draggingCanvas: any;
    draggingLink: { from: string, to: string, x?: number, y?: number } | null;
    focusLinks: IDbLink[];
    maxXY: { px: number; mx: number; py: number, my: number };
    buttonNext: boolean;
    direction: string;
  } = {
    width: 300,
    height: 150,
    x: 0,
    y: 0,
    zoom: 2,
    renderTables: [],
    links: [],
    draggingTable: null,
    draggingCanvas: null,
    draggingLink: null,
    focusLinks: [],
    maxXY: {px: 10, mx: -300, py: 10, my: -300},
    buttonNext: false,
    direction: 'to',
  };

  private _containerRef: HTMLElement;
  private _tableInfoServices: { [id: string]: db_relations.TableInfoService } = {};

  public componentDidMount() {
    // ??? надо будет подписаться на все из state._renderTables
  }

  public componentWillUnmount() {
    Object.keys(this._tableInfoServices).forEach(id => {
      this._tableInfoServices[id].unsubscribe(this._onTableInfoServiceUpdated);
      this._tableInfoServices[id].release();
    });
    this._tableInfoServices = {};
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevProps.ident !== this.props.ident) this._clearenCanvas();

    if (prevProps.tables !== this.props.tables) {
      const {tables} = this.props;
      const index = tables.length;
      const table = tables[index - 1];
      if (!!table) {
        const {sourceId, schema, name} = table;
        let i = index * 8;
        const pxX = i, pxY = i;
        this._tableInit(sourceId, schema, name, pxX, pxY);
      }
    }

    if (prevProps.renderTables !== this.props.renderTables && this.props.renderTables.length > 0) {
      let {x, y, zoom} = this.state;

      let renderTable = this.props.renderTables[0];
      const id = renderTable.id;

      if (this._tableInfoServices[id]) {
        return;
      }
      renderTable.x = (x + 10) * zoom;
      renderTable.y = (y + 10) * zoom;
      this.state.renderTables.forEach(rt => {
        if (renderTable.x === rt.x && renderTable.y === rt.y) {
          renderTable.x += 20 * zoom;
          renderTable.y += 20 * zoom;
        }
      });
      const renderTables: IRenderTable[] = [...this.state.renderTables, renderTable];
      const [sourceId, schema, name] = id.split('.');
      this._tableInfoServices[id] = new db_relations.TableInfoService(sourceId, schema, name);
      this.props.setRenderTables(renderTables);
      this.setState({renderTables}, () => {
        this._tableInfoServices[id].subscribeUpdatesAndNotify(this._onTableInfoServiceUpdated);
        if (this.props.onChange) {
          this.props.onChange(this.state.renderTables.map(t => t.tableInfo), this.state.links);
        }
      });
    }
  }

  private _setupContainerRef = (ref): void => this._containerRef = ref;

  private _resized = (width: number, height: number): void => this.setState({width, height});

  private _tableInit = (sourceId: string, schema: string, table: string, pxX: number, pxY: number) => {
    const tableInfoService = new db_relations.TableInfoService(sourceId, schema, table);
    tableInfoService.whenReady().then(tableInfo => {
      const id = tableInfo.id;

      if (id in this._tableInfoServices) {
        tableInfoService.release();
        return;
      }

      this._tableInfoServices[id] = tableInfoService;

      const {x, y, zoom} = this.state;
      let renderTables = [
        ...this.state.renderTables,
        {
          id,
          x: (pxX + x) * zoom ?? 0,
          y: (pxY + y) * zoom ?? 0,
          tableInfo: {sourceId, schema, ...tableInfo},
        }];
      this._onResizeCanvas();
      this.props.setRenderTables(renderTables);
      this.setState({renderTables}, () => {
        tableInfoService.subscribeUpdatesAndNotify(this._onTableInfoServiceUpdated);
        if (this.props.onChange) {
          this.props.onChange(this.state.renderTables.map(t => t.tableInfo), this.state.links);
        }
      });
    });
  }

  private _onStartDrag = (event: any, draggingTable: string) => {
    let renderTables = this.state.renderTables.slice(0);
    const idx = renderTables.findIndex(rt => rt.id === draggingTable);
    if (idx !== -1) {
      const renderTable = renderTables.splice(idx, 1)[0];
      renderTables.push(renderTable);
    }
    this.setState({draggingTable, renderTables});
  }

  private _onStartDragColumn = (event, column) => {
    event.stopPropagation();
    event.preventDefault();

    const bounds = this._containerRef.getBoundingClientRect();
    const pxX = event.clientX - bounds.left, pxY = event.clientY - bounds.top;
    this.setState({draggingTable: null, draggingCanvas: null, draggingLink: {from: column, x: pxX, y: pxY}});
  }

  private _onPointerMove = (event) => {
    const {draggingTable, draggingCanvas, draggingLink} = this.state;
    let {x, y, zoom, maxXY} = this.state;

    if (draggingCanvas) {
      x -= event.movementX;
      y -= event.movementY;

      if (x < 0) x = max(x, maxXY.mx);
      else x = min(x, maxXY.px);

      if (y < 0) y = max(y, maxXY.my);
      else y = min(y, maxXY.py);


      this.setState({x, y});
      this._onResizeCanvas();

    } else if (draggingTable) {
      let renderTables = this.state.renderTables.slice(0);
      const idx = renderTables.findIndex(rt => rt.id === draggingTable);
      if (idx === -1) return;

      let renderTable = {...renderTables[idx]};
      renderTable.x += event.movementX * zoom;
      renderTable.y += event.movementY * zoom;
      renderTables[idx] = renderTable;

      this._onResizeCanvas();

      this.setState({renderTables});
      this._onResizeCanvas();

    } else if (draggingLink) {
      const bounds = this._containerRef.getBoundingClientRect();
      const pxX = event.clientX - bounds.left, pxY = event.clientY - bounds.top;
      this.setState({draggingLink: {...draggingLink, x: pxX * zoom + x * zoom, y: pxY * zoom + y * zoom}});
      this._onResizeCanvas();
    }
  }

  private _onCanvasPointerDown = () => {
    this.setState({draggingCanvas: true});
  }

  private _onCanvasPointerUp = (event) => {
    const {draggingLink} = this.state;

    if (draggingLink && draggingLink.to && draggingLink.from) {
      let links = [...this.state.links, {...draggingLink, width: (Math.random() * 50) + 10}];
      this.setState({links}, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.renderTables.map(t => t.tableInfo), links);
        }
      });
    }

    this.setState({draggingTable: null, draggingCanvas: null, draggingLink: null, direction: 'to'});
  }

  private _onMouseEnterColumn = (event, columnId) => {
    const {draggingLink, links} = this.state;

    if (!draggingLink) return;

    let {buttonNext} = this.state;
    const isFrom = !!draggingLink.from;
    const from = !!draggingLink.to ? !isFrom && !dfs(getTableId(columnId), getTableId(draggingLink.to ?? ''), links) : false;
    const to = !!draggingLink.from ? isFrom && !dfs(getTableId(columnId), getTableId(draggingLink.from ?? ''), links) : false;

    if (draggingLink && (from || to)) {
      this.setState({draggingLink: {...draggingLink, [isFrom ? 'to' : 'from']: columnId}, buttonNext});
    }
  }

  private _onMouseLeaveColumn = (event, columnId) => {
    const {draggingLink, direction} = this.state;
    if (draggingLink && draggingLink[direction] === columnId) {
      this.setState({draggingLink: {...draggingLink, [direction]: undefined}, direction});
    }
  }

  private _onWheel = (event) => {
    event.stopPropagation();
    event.preventDefault();

    let {x, y, zoom} = this.state;

    const bounds = this._containerRef.getBoundingClientRect();
    const pxX = event.clientX - bounds.left, pxY = event.clientY - bounds.top;

    x = (x + pxX) * zoom;
    y = (y + pxY) * zoom;

    if (event.deltaY > 0) zoom += 0.1;
    if (event.deltaY < 0) zoom -= 0.1;
    zoom = min(max(zoom, 1), 6);

    x = x / zoom - pxX;
    y = y / zoom - pxY;

    this._onResizeCanvas();
    this.setState({zoom, x, y});
  }

  private _onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  private _notifyOnChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.renderTables.map(t => t.tableInfo), this.state.links);
    }
  }

  private _onDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const data = JSON.parse(event.dataTransfer.getData('application/vnd.luxmsbi.table+json'));
    const bounds = this._containerRef.getBoundingClientRect();
    const pxX = event.clientX - bounds.left, pxY = event.clientY - bounds.top;

    const sourceId = data.facets.sourceId || data.sourceId;
    const schema = data.facets.schema || data.schema;
    const table = data.facets.elementName || data.name;

    // table может поменять идентификатор - на таблицу из БД, поэтому тут очень рисковый момент - что мы придем сюда еще разок, пока она грузится
    // const id = `${sourceId}.${schema}.${table}`;
    // if (id in this._tableInfoServices) {
    //   return;
    // }
    this._tableInit(sourceId, schema, table, pxX, pxY);
  }

  private _onResizeCanvas = () => {
    const {height, width, zoom, renderTables} = this.state;
    let {maxXY} = this.state;
    for (let key in maxXY) {
      if (maxXY.hasOwnProperty(key)) {
        maxXY[key] = 0;
      }
    }

    renderTables.map((rt) => {
      let TABLE_HEIGHT = rt.tableInfo.columns.length;
      if (rt.x / zoom > maxXY.px) {
        maxXY.px = (rt.x + TABLE_WIDTH / 2) / zoom;
      }
      if ((rt.x - TABLE_WIDTH - (width * zoom)) / zoom < maxXY.mx) {
        maxXY.mx = (rt.x + TABLE_WIDTH / 2) / zoom - width;
      }
      if (rt.y / zoom > maxXY.py) {
        maxXY.py = (rt.y + 30 + TABLE_HEIGHT * 20 / 2) / zoom;
      }
      if ((rt.y - (TABLE_HEIGHT * 20) - (height * zoom)) / zoom < maxXY.my) {
        maxXY.my = (rt.y + (30 + TABLE_HEIGHT * 20 / 2)) / zoom - height;
      }
    });
    this.setState(maxXY);
  }

  private _onTableInfoServiceUpdated = (tableInfo: db_relations.ITableInfo) => {
    const renderTables = [...this.state.renderTables];

    let idx = renderTables.findIndex(rt => rt.id === tableInfo.id);
    if (idx === -1) return;
    renderTables[idx] = {
      ...renderTables[idx],
      tableInfo: {...renderTables[idx].tableInfo, ...tableInfo},
    };
    let links = this.state.links
      .filter(({from, to}) => to.startsWith(tableInfo.id) + '.')
      .concat(tableInfo.ongoingLinks.map(l => ({...l, width: (Math.random() * 50) + 10})));
    this.setState({renderTables, links}, () => this._notifyOnChange());
  }
  // delete this._tableInfoServices[id];

  private _onCloseTable = (event, tableId) => {
    event.stopPropagation();
    event.preventDefault();
    let {renderTables, links} = this.state;

    let idt = renderTables.findIndex(table => table.id === tableId);

    if (idt !== -1) {
      const table = renderTables[idt].tableInfo.id;
      links = links.filter(l => !l.from.includes(table));

      renderTables = [...renderTables];
      renderTables.splice(idt, 1);
    }

    this._onResizeCanvas();

    this.props.setRenderTables(renderTables);
    this.setState({renderTables, links, focusLinks: []}, () => {
      if (this.props.onChange) {
        this._tableInfoServices[tableId].unsubscribe(this._onTableInfoServiceUpdated);
        this._tableInfoServices[tableId].release();
        delete this._tableInfoServices[tableId];
        this.props.onChange(renderTables.map(t => t.tableInfo), links);
      }
    });
  }

  private _onDeleteLink = (event: any, selectedlink: IDbLink, direction?: string) => {
    event.stopPropagation();
    event.preventDefault();

    let {links, renderTables, buttonNext, focusLinks} = this.state;
    let draggingLink = null;

    if (!!direction) {                                                                          // перемещаю конец стрелки direction (from или to)
      let idx = links.findIndex(l => l.from + l.to === selectedlink.from + selectedlink.to);
      links.splice(idx, 1);
      draggingLink = {...selectedlink, [direction]: null};
    } else {                                                                                  // удаляю все активные
      for (let i = 0; i < focusLinks.length; i++) {
        let idx = links.findIndex(l => l.from + l.to === focusLinks[i].from + focusLinks[i].to);
        if (idx !== -1 ) links.splice(idx, 1); // удаляю и перерисовыю линки которые перемещаю
      }
    }

    this.setState({links, buttonNext, focusLinks: [], draggingLink, direction}, () => {
      if (this.props.onChange) {
        this.props.onChange(renderTables.map(t => t.tableInfo), this.state.links);
      }
    });
  }

  private _onFocusLink = (e: any, active: boolean, selectedlink: IDbLink, type: string) => {
    e.stopPropagation();
    e.preventDefault();

    if (type === 'others') return; // не реагирую на другие links

    let {focusLinks} = this.state;
    const iFocus = focusLinks.findIndex(fl => fl.from + fl.to === selectedlink.from + selectedlink.to);

    if (type !== 'main') {
      this.setState({focusLinks: []});   // клик не на link, все должны стать не активными
    } else {
      if (iFocus !== -1) {
        focusLinks.splice(iFocus, 1);     // если кликнули на уже активную
        this.setState({focusLinks});
      } else this.setState({focusLinks: [...focusLinks, selectedlink]});
    }

  }

  private _clearenCanvas = () => {
    let idt = this.state.renderTables.map(rt => rt.id);
    let maxXY = this.state.maxXY;

    this.props.setRenderTables([]);
    this._onResizeCanvas();

    this.setState({renderTables: [], maxXY, links: []}, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.renderTables.map(t => t.tableInfo), []);
        idt.map(id => {
          this._tableInfoServices[id].unsubscribe(this._onTableInfoServiceUpdated);
          this._tableInfoServices[id].release();
        });
      }
      this._tableInfoServices = {};
    });
  }

  public render() {
    const {x, y, width, height, zoom, renderTables, links, draggingCanvas, draggingLink, focusLinks, direction} = this.state;
    const focusLinkFrom = focusLinks.map(fl => fl.from.split('.').slice(1).join('.')),
      focusLinkTo = focusLinks.map(fl => fl.to.split('.').slice(1).join('.'));
    let cursor = 'grab';
    if (draggingCanvas) cursor = 'grabbing';
    else if (draggingLink) cursor = 'crosshair';
    return (
      <div className={`DbRelations zoom${zoom}`}
           onWheel={this._onWheel}
           onDragOver={this._onDragOver}
           ref={this._setupContainerRef}>
        <ReactResizeDetector handleWidth handleHeight onResize={this._resized}/>
        <svg className="DbRelations__Canvas"
             viewBox={`${x * zoom} ${y * zoom} ${width * zoom} ${height * zoom}`}
             style={{
               width,
               height,
               backgroundSize: `${20 / zoom}px ${20 / zoom}px`,
               backgroundPosition: `${-x}px ${-y}px`,
               cursor,
             }}
             onPointerMove={this._onPointerMove}
             onPointerUp={this._onCanvasPointerUp}
             onPointerDown={this._onCanvasPointerDown}
             onDragOver={this._onDragOver}
             onDrop={this._onDrop}>

          {links.map((link, i) => {
            const focusLink = focusLinks.find(fl => fl.from + fl.to === link.from + link.to);
            return <Link key={link.from + link.to} link={link} renderTables={renderTables} onDeleteLink={this._onDeleteLink}
                         focusLink={!!focusLink} onFocusLink={this._onFocusLink}/>;
          })}

          { renderTables.map(renderTable => {
            const hiliteColumnDraggingFrom = draggingLink?.from?.startsWith(renderTable.id + '.') ? draggingLink.from.split('.').slice(1).join('.') : undefined;
            const hiliteColumnDraggingTo = draggingLink?.to?.startsWith(renderTable.id + '.') ? draggingLink.to.split('.').slice(1).join('.') : undefined;
            return <Table key={renderTable.id + renderTable.tableInfo}
                   table={renderTable.tableInfo}
                   x={renderTable.x}
                   y={renderTable.y}
                   onMouseEnterColumn={this._onMouseEnterColumn}
                   onMouseLeaveColumn={this._onMouseLeaveColumn}
                   hiliteColumnDraggingFrom={hiliteColumnDraggingFrom}
                   hiliteColumnDraggingTo={hiliteColumnDraggingTo}
                   focusLinkFrom={focusLinkFrom}
                   focusLinkTo={focusLinkTo}
                   onStartDrag={this._onStartDrag}
                   onStartDragColumn={this._onStartDragColumn}
                   onCloseTable={this._onCloseTable}/>;
          })}

          <DraggingLink draggingLink={draggingLink} direction={direction === 'from' ? 'to' : 'from'} renderTables={renderTables} onDeleteLink={this._onDeleteLink}/>

          {/*<Circle x={0} y={0} diameter={80} options={{fill: 'rgba(202, 122, 98, 0.7)', fillWeight: 3}} />*/}
        </svg>
      </div>);
  }
}

export default DbRelations;
