/**
 *
 *  M - some data required for vizel to draw anything
 *
 */

import { BaseService, extractErrorMessage, IDisposable } from '@luxms/bi-core';
import { data_engine } from '../../data-manip/data-manip';
import { IVizelConfig } from '../../services/ds/types';
import { IEntity, ISubspace } from '../../defs/bi';
import {ThemeVC, IThemeVM} from '../ThemeVC';

export interface IVizelVM {
  loading?: boolean;
  error?: string;
  noData?: boolean;
  theme?: any;
  // data: M;
  // ctrl?: BaseService<IVizelVM<M>>;
}


export class BaseVizelVC<VM extends IVizelVM> extends BaseService<VM> {
  protected _dp: data_engine.IDataProvider;
  protected _cfg: IVizelConfig;
  protected _subspace: ISubspace = null;
  private _nextSubspace: ISubspace = null;
  private __rtSubscription: IDisposable = null;
  private _themeSrv: ThemeVC;

  public constructor(dp: data_engine.IDataProvider, cfg: IVizelConfig, initialModel: Partial<VM>) {
    super({
      ...initialModel as any,
      loading: true,
      error: null,
      // data: null,
      // ctrl: this,
    });
    this._dp = dp;
    this._cfg = cfg;
    this._themeSrv = ThemeVC.getInstance();
    this._themeSrv.subscribeUpdatesAndNotify((model) => {
      if (model.loading || model.error)
      this._updateModel({theme: model.currentTheme})
    })
  }

  // given raw model prepare valid model
  protected _createViewModelData(rawModel: any, prevVM: VM): VM {
    return null;
  }

  // TODO: make common method for any options
  // override
  public setUserFilterBy(filterBy: string | null) {
    //
  }


  // TODO: make abstract
  // TODO: declare RAW type
  protected _loadRawModel(subspace: ISubspace): Promise<any> {
    return Promise.resolve(null);
  }

  protected _subscribeSubspace(subspace: ISubspace | null): void {
    if (this.__rtSubscription) {
      this.__rtSubscription.dispose();
      this.__rtSubscription = null;
    }
    if (subspace) {
      this.__rtSubscription = this._dp.subscribe(subspace, this.__rtValueUpdated);
    }
  }

  protected _updateOneValueInViewModelData(vm: VM, z: IEntity, y: IEntity, x: IEntity, v: number): VM {
    return vm;
  }

  private __rtValueUpdated = (z: IEntity, y: IEntity, x: IEntity, v: number): void => {
    this._updateModel(this._updateOneValueInViewModelData(this._model, z, y, x, v));
    this._notify('updateValue', {z, y, x, v});
  }

  // guard
  private _isRawModelLoading: boolean = false;

  // guards queue
  public async setAxes(subspace: ISubspace): Promise<void> {
    if (this._isRawModelLoading) {
      // loading in process
      this._nextSubspace = subspace;
      await this.happens('dataLoadFinished');                // TODO: check errors
      if (this._nextSubspace === subspace) { // Im winner
        return this.setAxes(subspace);
      } else {
        throw new Error('interrupted');
      }
    }

    try {
      this._isRawModelLoading = true;
      this._notify('dataLoadStarted');

      this._updateWithLoading();

      this._subspace = subspace;
      const rawModel: any = await this._loadRawModel(this._subspace);
      this._subscribeSubspace(this._subspace);

      this._updateWithData(this._createViewModelData(rawModel, this._model));

    } catch (err) {
      console.error(err);
      this._updateWithError(extractErrorMessage(err));
    }

    this._isRawModelLoading = false;
    window.setTimeout(() => {
      this._notify('dataLoadFinished');
    }, 0);
  }

  protected _dispose() {
    this._subscribeSubspace(null);
    super._dispose();
  }
}


export default BaseVizelVC;
