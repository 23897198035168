import * as React from 'react';
import {IDatasetModel} from '../../../services/ds/types';
import {ILocation, IMapFill, IMetric} from '../../../defs/bi';
import {lang} from '../../../utils/utils';
import {$eid} from '../../../libs/imdas/list';
import './VMMapFillControlsRDropdown.scss';

function isFirefox() {
  return navigator.userAgent.indexOf('Firefox') != -1;
}

interface IVMMapFillControlsProps {
  ds: IDatasetModel;
  cfgMapFill: IMapFill;
  onChange: (mf: IMapFill) => void;
  selectedMetric: IMetric;
  selectedLocation: ILocation;
}

interface IVMMapFillControlsState {
  metrics: IMetric[];
  locations: ILocation[];
  expandMenu: boolean;
}

class VMMapFillControlsR extends React.Component<IVMMapFillControlsProps, IVMMapFillControlsState>{
  private metrics = [];
  private locations = [];

  constructor(props) {
    super(props);
    const {ds} = props;
    const {rootMetrics, rootLocations} = ds;

    for (let rootMetric of rootMetrics) {
      this._createMetricsTree(rootMetric);
    }

    for (let rootLocation of rootLocations) {
      this._createLocationsTree(rootLocation);
    }
    this.state = {
      metrics: this.metrics,
      locations: this.locations,
      expandMenu: false,
    }
  }

  private _createMetricsTree(metric: IMetric): void {
    if (metric.is_hidden == 1) return;
    this.metrics.push(metric);
    for (let child of metric.children) {
      this._createMetricsTree(child);
    }
  }

  private static _isLocationSpatiable(location: ILocation): boolean {
    return !!location.children.find((l: ILocation) => !!(l.spatials && l.spatials.length));
  }

  private _createLocationsTree(node: ILocation): void {
    if (VMMapFillControlsR._isLocationSpatiable(node)) {
      this.locations.push(node);
    }
    for (let child of node.children) {
      this._createLocationsTree(child);
    }
  }

  public itemsPostRender(item: IMetric | ILocation): string {
    // on single selected option.style.padding don't work
    const p: string = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
    let padding: string = '';
    if (!item) {     // not setected
      // $(option)
      //   .prop('disabled', true)              // should user be able to unselect?
      //   .text(lang('not-selected'));
      return;
    }
    for (let i = 0; i < item.tree_level; ++i) {
      padding += p;
    }
    return padding + item.title;
  }

  private _apply (newMetric, newLocation): void {
    const {onChange, cfgMapFill} = this.props;
    let {locations} = this.state;
    const { enabled, metric, } = cfgMapFill;
    const newM: IMetric = newMetric || null;
    let newLs: ILocation[] = newLocation ? [newLocation] : [];

    if (locations.length === 1) {     // if only one location, it is selected
      newLs = locations;
    }

    // if (newM === metric && isEqual(newLs, cfgMapFill.locations)) {
    //   return;
    // }

    if (!enabled && !metric && !cfgMapFill.locations.length) {     // turned off
      return;
    }

    onChange({
      enabled: true,
      metric: newM,
      locations: newLs,
    });
  }

  private _onSelectMetric = (event) => {
    const {selectedMetric, selectedLocation} = this.props;
    const {metrics, locations} = this.state;
    this._apply($eid(metrics, event.target.value) , selectedLocation);
    this.toggleMetricDropdown();
  }

  private _onSelectLocation = (event) => {
    const {selectedMetric, selectedLocation} = this.props;
    const {metrics, locations} = this.state;
    this._apply(selectedMetric, $eid(locations, event.target.value));
  }

  private toggleMetricDropdown = () => this.setState({expandMenu: !this.state.expandMenu});

  public render () {
    const {locations,  metrics, expandMenu} = this.state;
    const {selectedMetric, selectedLocation} = this.props;

    return (
      <article style={{padding: '0px 11px 16px 12px'}}>
        {locations.length == 0 &&
        <div>{lang('mapfill_dialog_no_chance_warning')}</div>
        }

        {locations.length != 0 &&
          <div>
            <h5 dangerouslySetInnerHTML={{__html:lang('mapfill_dialog_select_parameter_tip')}}></h5>
            <div onClick={this.toggleMetricDropdown}  id="selected" className={`customFilter form-control ${expandMenu ? 'expandedMenu' : 'closedMenu'}`}
                 onChange={this._onSelectMetric}>
              {selectedMetric ? selectedMetric.title : ' '}
              <span  className="bi-icon dark" style={{position: 'absolute', right: '12px', width: '22px', height: '22px', background: '#fff8f8'}}>
                    <svg enableBackground="new 0 0 32 32" version="1.1" className="expand-icon" viewBox="0 0 32 32" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                     <path d="M24.285,11.284L16,19.571l-8.285-8.288c-0.395-0.395-1.034-0.395-1.429,0  c-0.394,0.395-0.394,1.035,0,1.43l8.999,9.002l0,0l0,0c0.394,0.395,1.034,0.395,1.428,0l8.999-9.002  c0.394-0.395,0.394-1.036,0-1.431C25.319,10.889,24.679,10.889,24.285,11.284z" />
                    </svg>
              </span>
            </div>
            {expandMenu && (<div id={'metric_dropdown'}>
              <ul>
                {metrics.map((m) => {
                  let padding = `${(Number(m.tree_level)) ? (Number(m.tree_level) > 1) ? (Number(m.tree_level) * 20) : 20 : 0}px`;
                  let styles = {
                    paddingLeft: padding,
                  };
                  let classLi = '';
                  if (selectedMetric && selectedMetric.id == m.id) classLi = 'active';
                  return(<li
                      onClick={this._onSelectMetric}
                      key={m.id}
                      value={m.id}
                      style={styles}
                      className={classLi}
                  >{m.title}</li>);
                })}
              </ul>
            </div>) || null}
          </div>
        }

        {locations.length == 1 &&
          <div>
            <h5>
              <span>{lang('mapfill_dialog_one_location_tip')}</span>: <span>{locations[0].title}</span>
            </h5>
          </div>
        }

        {locations.length > 1 &&
          <div>
            <h5 dangerouslySetInnerHTML={{__html: lang('mapfill_dialog_select_location_tip')}}></h5>
            <select className="form-control" value={selectedLocation?.id ? selectedLocation.id : ' '} onChange={this._onSelectLocation}>
              {locations.map((l) => (
                <option key={l.id} value={l.id} dangerouslySetInnerHTML={{__html: this.itemsPostRender(l)}} onClick={isFirefox ? this._onSelectLocation : void 0}/>
              ))}
            </select>
          </div>
        }
      </article>
    )}
}

export default VMMapFillControlsR;