/**
 *
 * Datasets List Service
 *
 * provides DatasetsListModel object
 *  updates it if necassary
 *
 * subscribe on event 'update' to receive Datasets List changes
 *
 */


import isObject from 'lodash/isObject';
import { ConfigHelper } from './ds/ds-helpers';
import { BaseService, urlState, IDisposable, IUrl, createSingleton, extractErrorMessage, AppConfig, AuthenticationService, IAuthentication } from '@luxms/bi-core';
import { httpGet } from '../repositories/http/data-storage';
import { $eid } from '../libs/imdas/list';
import { makeColor, makePlainConfig,  } from '../utils/utils';
import buildUrl from '../utils/buildUrl';
import { createNullVector, isNullVector } from '../data-manip/data-utils';
import { IEntity, responses, tables } from '../defs/bi';


export interface IDatasetsListTile {
  dataset: IDatasetsListItem;
  percentage: {
    x: number;
    y: number;
    w: number;
    h: number;
  };
}


export interface IDatasetsListItem extends IEntity {
  id: string;
  guid: string;
  schema_name: string;
  title: string;
  description: string;
  layout: string;
  image: string;
  lastPeriodTitle: string;
  href: string;
  color: string;
  tiles: IDatasetsListTile[];
  bookmarks: any[];             // tables.IBookmark[]
  searchVisible: boolean;       // TODO: remove
  children: IDatasetsListItem[];
  resourcesRoot: string;
  parents: IDatasetsListItem[];
  uiCfg: any;
  deleteBookmark(bookmark: tables.IBookmark);
}


//
//
// TODO:
//  /api/v3/dataset-groups
//  /api/v3/datasets
//
//

export class Tile implements IDatasetsListTile {
  public dataset: Dataset;
  public x: number;
  public y: number;
  public w: number;
  public h: number;
  public percentage: {
    x: number;
    y: number;
    w: number;
    h: number;
  };

  public constructor(dataset: Dataset) {
    this.dataset = dataset;
    const frame: any = this.dataset.config.frame || {};
    this.x = ('x' in frame) ? frame.x : 0;
    this.y = ('y' in frame) ? frame.y : 0;
    this.w = ('w' in frame) ? frame.w : 1;
    this.h = ('h' in frame) ? frame.h : 1;
    this.percentage = {
      x: 0,
      y: 0,
      w: 100,
      h: 100,
    };
  }

  public hasConfigXY(): boolean {
    const frame: any = this.dataset.getFrame();
    return ('x' in frame) && ('y' in frame);
  }

  public getConfigX(): number {
    return this.hasConfigXY() ? this.dataset.getFrame().x : null;
  }

  public getConfigY(): number {
    return this.hasConfigXY() ? this.dataset.getFrame().y : null;
  }

  public evaluatePosition(layoutHeight: number, layoutWidth: number): void {
    //
    // TODO: we need paddings between tiles
    //  Either
    //  - pass pxWidth here and evaluate
    //  - use internal padding and start from (-margin, -margin)
    //
    this.percentage.x = 100 * this.x / layoutWidth;
    this.percentage.y = 100 * this.y / layoutHeight;
    this.percentage.w = 100 * this.w / layoutWidth;
    this.percentage.h = 100 * this.h / layoutHeight;
  }
}


export class Dataset implements IEntity, IDatasetsListItem {
  public id: string;
  public config: any = {};
  public title: string;
  public lastPeriodTitle: string;
  public description: string;
  public image: string;
  public icon: string;
  public uiCfg: { [id: string]: string };
  public color: string;
  public parentIds: string[] = [];
  public childrenIds: string[] = [];    // schema_name or guid
  public schema_name: string;
  public srt: number;

  public guid: string;

  public layout: string = '';
  public tiles: Tile[] = [];
  public tilePxSize: number = 0;
  public gridPxHeight: number = 0;

  public parents: Dataset[] = [];
  public children: Dataset[] = [];
  public href: string = '#';
  public bookmarks: tables.IBookmark[] = [];
  public searchVisible: boolean = true;
  public resourcesRoot: string;

  private _serial: moment.Moment;
  private _$container: JQuery = null;

  public deleteBookmark = async (bookmark: tables.IBookmark) => {
    const idx = this.bookmarks.indexOf(bookmark);
    if (idx !== -1) {
      this.bookmarks.splice(idx, 1);
    }
    const service = await import('./service');
    await service.deleteBookmark(bookmark);
  }

  public constructor(d: responses.IDatasetDescription) {
    this.id = String(d.id);
    this.update(d);
  }

  public getFrame(): tables.IConfigFrame {
    return this.config.frame || {};
  }

  public update(d: responses.IDatasetDescription): void {
    console.assert(this.id === String(d.id));
    this.guid = ('guid' in d) ? d.guid : String(d.id);    // v3.0
    this.config = isObject(d.config) ? d.config : {};
    this.title = d.title;
    this.lastPeriodTitle = d.lastPeriod[0] ? d.lastPeriod[0].title : '';
    this.description = d.description || 'none';
    this.image = d.image || '';
    this.icon = d.icon || '';

    this.uiCfg = {};
    if (d.ui_cfg) this.uiCfg = d.ui_cfg;
    else if (d.config) this.uiCfg = makePlainConfig(d.config || {});

    this.srt = d.srt || 0;
    this._serial = moment(d.serial);
    this.color = null;
    if ('dataset.stateColor' in this.uiCfg) {
      this.color = makeColor(this.uiCfg['dataset.stateColor']);
    } else if ('stateColor' in this.config) {
      this.color = makeColor(this.config.stateColor);
    }
    this.parentIds = d.parent_id ? [d.parent_id] : [];
    this.schema_name = d.schema_name;

    // this.href = buildUrl(this.uiCfg, this.id);
    // this.href = buildUrl(this.uiCfg, this.guid);
    this.href = buildUrl(this.uiCfg, this.schema_name);

    this.layout = this.config['layout'] || '';

    this.resourcesRoot = AppConfig.fixRequestUrl(`/srv/resources/${this.schema_name}/`);
  }

  private _rebuildTiles(): void {
    if (this.layout !== 'grid' || !this._$container) {
      return;
    }
    const hasDataset = (d: Dataset): boolean => this.children.indexOf(d) !== -1;
    const hasTile = (d: Dataset): boolean => this.tiles.map((t: Tile) => t.dataset).indexOf(d) !== -1;

    let tiles: Tile[] = this.tiles.slice();
    tiles = tiles.filter((t: Tile) => hasDataset(t.dataset));    // remove extra tiles

    this.children.forEach((d: Dataset) => {          // add tiles for children without tile
      if (!hasTile(d)) {
        tiles.push(new Tile(d));
      }
    });

    tiles.sort((t1: Tile, t2: Tile) => this.children.indexOf(t1.dataset) - this.children.indexOf(t2.dataset));

    let layoutWidth: number = 3;    // TODO: add to config
    let tilePxSize: number = this._$container.width() / layoutWidth;

    tiles.forEach((t: Tile) => t.w = Math.min(t.w, layoutWidth));    // max tile width is layout width

    let tileLayout: (Tile | boolean)[][] = [];
    let isFree = (y: number, x: number) => (x < layoutWidth) && (tileLayout.length <= y || !tileLayout[y][x]);
    let addRow = () => tileLayout.push(createNullVector(layoutWidth) as any[]);
    let ensureHasNRows = (n) => {
      while (tileLayout.length <= n) addRow();
    };

    let canPlaceTile = (tile: Tile, y: number, x: number) => {
      for (let yy = y; yy < y + tile.h; yy++) {
        for (let xx = x; xx < x + tile.w; xx++) {
          if (!isFree(yy, xx)) {
            return false;
          }
        }
      }
      return true;
    };

    let placeTile = (tile: Tile, y: number, x: number) => {
      for (let yy = y; yy < y + tile.h; yy++) {
        ensureHasNRows(yy);
        for (let xx = x; xx < x + tile.w; xx++) {
          console.assert(isFree(yy, xx));
          tileLayout[yy][xx] = true;     // all squares marked as true
        }
        tileLayout[y][x] = tile;   // left top is marked as tile
      }
    };

    let reservePlace = (tile: Tile, y: number, x: number) => {
      let yy: number = y;
      let xx: number = x;
      while (true) {
        if (canPlaceTile(tile, yy, xx)) {
          placeTile(tile, yy, xx);
          return;
        }
        xx++;
        if (xx >= layoutWidth) {
          xx = 0;
          yy++;
        }
      }
    };

    tiles.filter(t => t.hasConfigXY()).forEach((t: Tile) => reservePlace(t, t.getConfigY(), t.getConfigX()));   // first place those that have xy set in config
    tiles.filter(t => !t.hasConfigXY()).forEach((t: Tile) => reservePlace(t, 0, 0));                            // then place others from left top corner

    let tileRowNotEmpty: any = (row: Tile[]): boolean => !isNullVector(row as any[]);
    tileLayout = tileLayout.filter(tileRowNotEmpty);                             // remove empty rows

    // set x/y
    tileLayout.forEach((tileRow: (boolean | Tile)[], y: number) => {
      tileRow.forEach((tile: (boolean | Tile), x: number) => {
        if (tile && (tile !== true)) {
          (tile as Tile).x = x;
          (tile as Tile).y = y;
        }
      });
    });

    const layoutHeight: number = tileLayout.length;

    tiles.forEach((t: Tile) => t.evaluatePosition(layoutHeight, layoutWidth));

    this.tilePxSize = tilePxSize;
    this.gridPxHeight = layoutHeight * tilePxSize;
    this.tiles = tiles;
  }

  public resize(): void {
    this.children.forEach((d: Dataset) => d.resize());

    if (this.layout === 'grid' && this._$container) {
      let layoutWidth: number = 3;    // TODO: add to config
      let tilePxSize: number = this._$container.width() / layoutWidth;
      this.tilePxSize = tilePxSize;

      let layoutHeight: number = 0;
      this.tiles.forEach((t: Tile) => layoutHeight = Math.max(layoutHeight, t.y + t.h));
      this.gridPxHeight = layoutHeight * tilePxSize;
    }
  }

  public setChildren(d: Dataset[]): void {
    this.children = d.slice(0);
    this.children.sort((c1: Dataset, c2: Dataset) => c1.srt - c2.srt);
    this._rebuildTiles();
  }

  public getChildren(): Dataset[] {
    return this.children.slice(0);
  }

  public getNextUpdate(): moment.Moment {
    if (!('dataset.updateEvery' in this.uiCfg)) {
      return null;
    }

    const ueval: string = this.uiCfg['dataset.updateEvery'] || '';
    if (ueval.match(/^(\d+)(.+)$/)) {
      const updateEvery: moment.Duration = moment.duration(parseInt(RegExp.$1), RegExp.$2);
      const nextUpdate: moment.Moment = this._serial.clone().add(updateEvery);
      console.log(`Dataset '${this.id}': ${this._serial.format()} + ${ueval} = ${nextUpdate.format()}`);
      return nextUpdate;
    } else {
      console.warn('Unknown value %s in dataset[%s].ui_cfg_updateEvery', ueval, this.id);
      return null;
    }
  }

  public setBookmarks(bookmarks: tables.IBookmark[]): void {
    this.bookmarks = bookmarks;
  }

  public getTitle(): string {
    return this.title;
  }

  private attached(container: HTMLElement): void {
    this._$container = $(container);
  }

  public compositionComplete(container): void {
    window.setTimeout(() => this._rebuildTiles(), 0);
  }

  public getAxisId(): string {
    return 'datasets';
  }
}


export interface IDatasetsListModel {
  loading: boolean;
  error: string;
  datasets: IDatasetsListItem[];
  roots: IDatasetsListItem[];
}


class DatasetsListModel implements IDatasetsListModel {
  public loading: boolean = false;
  public error: string = null;
  public datasets: IDatasetsListItem[] = null;
  public roots: IDatasetsListItem[] = null;

  public static withLoading(datasets?: IDatasetsListItem[], roots?: IDatasetsListItem[]): IDatasetsListModel {
    const model: DatasetsListModel = (datasets && roots) ? this.withData(datasets, roots) : new DatasetsListModel();
    model.loading = true;
    return model;
  }

  public static withError(error: string): IDatasetsListModel {
    const model: DatasetsListModel = new DatasetsListModel();
    model.error = error || 'Unknown error';
    return model;
  }

  public static withData(datasets: IDatasetsListItem[], roots: IDatasetsListItem[]): IDatasetsListModel {
    const model: DatasetsListModel = new DatasetsListModel();
    model.datasets = datasets;
    model.roots = roots;
    return model;
  }
}


export class DatasetsListService extends BaseService<IDatasetsListModel> {
  @BaseService.dependency
  private _authenticationService: AuthenticationService = AuthenticationService.getInstance();

  private _updateTimer: number = null;

  // cached data
  private _datasetsBySchemaName: { [schema_name: string]: Dataset };
  private _datasets: Dataset[];
  private _roots: Dataset[];

  public constructor() {
    super(DatasetsListModel.withLoading());
    this._datasets = [];
    this._datasetsBySchemaName = {};
    this._roots = [];
  }

  protected _onDepsReadyAndUpdated() {
    const auth: IAuthentication = this._authenticationService.getModel();
    if (!auth.authenticated) {
      this._datasets = [];
      this._datasetsBySchemaName = {};
      this._roots = [];
      return this._updateWithError(AuthenticationService.NOT_AUTHENTICATED);
    }
    this._reload();
  }

  private async _loadDatasetsListModel(): Promise<responses.IDatasetsResponse> {
    const url = AppConfig.fixRequestUrl(`/api/datasets/`);
    return httpGet<responses.IDatasetsResponse>(url);
  }

  private _reload = async () => {
    try {
      // this._setModel(DatasetsListModel.withLoading(this._datasets, this._roots));
      const model: responses.IDatasetsResponse = await this._loadDatasetsListModel();
      this._onModelLoaded(model);

      // load dataset bookmarks: deprecated
      try {
        const service = await import('./service');
        const bookmarks: tables.IBookmark[] = await service.getBookmarks();
        const bmByDsId: { [id: string]: tables.IBookmark[] } = {};
        bookmarks.forEach((b: tables.IBookmark) => {
          if (!(b.dataset_id in bmByDsId)) bmByDsId[b.dataset_id] = [];
          bmByDsId[b.dataset_id].push(b);
        });

        for (let datasetId in bmByDsId) {
          if (bmByDsId.hasOwnProperty(datasetId)) {
            const ds: Dataset = $eid(this._datasets, datasetId);
            if (ds) {
              ds.setBookmarks(bmByDsId[datasetId]);
            }
          }
        }
      } catch (err) {
        console.error(err);
        // ignore this error: but will be no bookmarks
      }

      this._setModel(DatasetsListModel.withData(this._datasets, this._roots));

    } catch (err) {
      console.error(err);
      this._setModel(DatasetsListModel.withError(extractErrorMessage(err)));
    }
  }

  private _planUpdate(): void {
    window.clearTimeout(this._updateTimer);

    const diff: number = this._getNextUpdateSeconds();
    if (diff === null) {
      return;
    }
    console.log(`Datasets list will update in ${diff} seconds`);

    this._updateTimer = window.setTimeout(() => {
      this._reload();
    }, diff * 1000);
  }

  private _getDataset(id: string): Dataset {
    return $eid(this._datasets, id) || this._datasetsBySchemaName[id] || null;
  }

  private _postUpdateDatasets(): void {
    // 1. set children for those, which have list of children ids
    this._datasets.forEach((d: Dataset) => {
      const children: Dataset[] = d.childrenIds.map((id: string) => this._getDataset(id)).filter(d => d != null);
      d.setChildren(children);
    });

    // 2. add to children those, which have parent_id_list
    this._datasets.forEach((d: Dataset) => {
      const parents: Dataset[] = d.parentIds.map((pid: string): Dataset => {
        const ds: Dataset = $eid(this._datasets, pid);
        if (ds === null) {
          console.warn('Dataset', d.id, '(', d.title, ')', ' has parent_id=', pid, ' but it is not found');
        }
        return ds;
      }).filter(d => d != null);
      parents.forEach((p: Dataset) => p.children.push(d));
    });

    // 3. cleanup parents list
    this._datasets.forEach((d: Dataset) => d.parents = []);

    // 4. fill parents list
    this._datasets.forEach((d: Dataset) => d.children.forEach((c: Dataset) => c.parents.push(d)));

    this._roots = this._datasets.filter((d: Dataset) => !d.parents.length && !!(d.schema_name || d.children.length));
  }

  private _getNextUpdateSeconds(): number {
    const nextUpdates: moment.Moment[] = this._datasets.map((d: Dataset) => d.getNextUpdate()).filter(d => d != null);
    if (nextUpdates.length === 0)
      return null;
    nextUpdates.sort();
    const nextUpdate: moment.Moment = nextUpdates[0];
    const now: moment.Moment = moment();

    let diff: number = nextUpdate.diff(now, 'seconds');

    if (diff < 60) {
      console.warn(`Dataset list update: Next is scheduled less then minute to future (${diff})`);
      diff = 60;
    }

    return diff;
  }

  private _addGroup(g: responses.IGroup): void {
    const id: string = String(g.id);
    const d: responses.IDatasetDescription = {
      config: g.config || {},
      datafile: '',
      description: '',
      groups: [],
      icon: '',
      id: '_' + id,
      srt: -10000 + g.id,
      image: '',
      lastPeriod: [],
      parent_id: null,
      schema_name: '',
      serial: '',
      title: g.title,
      ui_cfg: {},
    };

    const dataset: Dataset = this._addDataset(d);
    dataset.childrenIds = (g.datasets || []).map(String);
  }

  private _addDataset(d: responses.IDatasetDescription): Dataset {
    const id: string = String(d.id);
    let dataset: Dataset = $eid(this._datasets, id);
    if (dataset) {
      dataset.update(d);
    } else {
      dataset = new Dataset(d);
      this._datasets = this._datasets.slice(0);
      this._datasets.push(dataset);
      if (dataset.schema_name) {
        this._datasetsBySchemaName[dataset.schema_name] = dataset;
      }
    }
    return dataset;
  }

  private _onModelLoaded(model: responses.IDatasetsResponse): void {
    model.groups.forEach((g: responses.IGroup) => this._addGroup(g));
    model.datasets.forEach((d: responses.IDatasetDescription) => this._addDataset(d));

    this._postUpdateDatasets();
    this._planUpdate();
  }

  public static getInstance = createSingleton<DatasetsListService>(() => new DatasetsListService(), '__datasetsListService');

  public static getModel(): IDatasetsListModel {
    return this.getInstance().getModel();
  }

  public static subscribeUpdatesAndNotify(listener: (model: IDatasetsListModel) => void): IDisposable {
    return this.getInstance().subscribeUpdatesAndNotify(listener);
  }

  public static unsubscribe(listener: (...args: any[]) => any): boolean {
    return this.getInstance().unsubscribe(listener);
  }
}
