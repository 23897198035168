import { AppConfig, BaseService, repo } from '@luxms/bi-core';
import ResourceLocatorService from './ResourceLocatorService';
import axios from 'axios';

interface IResourceByNameModel {
  error: string;
  loading: boolean;
  resource: repo.ds.IRawResource;
  schema_name: string;
  content: string;
}

export default class ResourceByNameService extends BaseService<IResourceByNameModel> {
  public readonly MODEL: IResourceByNameModel;
  private _resourceLocatorService: ResourceLocatorService = null;
  private _dsResResourceLocatorService: ResourceLocatorService = null;

  public constructor(private readonly alt_id: string) {
    super({
      error: null,
      loading: true,
      resource: null,
      schema_name: null,
      content: null,
    });

    this._resourceLocatorService = new ResourceLocatorService(null, this.alt_id);
    this._resourceLocatorService.subscribeUpdates(this._onResourceUpdated);

    this._dsResResourceLocatorService = new ResourceLocatorService('ds_res', this.alt_id);
    this._dsResResourceLocatorService.subscribeUpdates(this._onResourceUpdated);
  }

  protected _dispose() {
    this._resourceLocatorService.unsubscribe(this._onResourceUpdated);
    this._resourceLocatorService.release();
    this._resourceLocatorService = null;

    this._dsResResourceLocatorService.unsubscribe(this._onResourceUpdated);
    this._dsResResourceLocatorService.release();
    this._dsResResourceLocatorService = null;

    super._dispose();
  }

  private _onResourceUpdated = async () => {
    const dsResResourceLocator = this._dsResResourceLocatorService.getModel();
    const resourceLocator = this._resourceLocatorService.getModel();

    if (dsResResourceLocator.error || resourceLocator.error) return this._updateWithError(dsResResourceLocator.error || resourceLocator.error);
    if (dsResResourceLocator.loading || resourceLocator.loading) return this._updateWithLoading();

    let schema_name: string, resource: repo.ds.IRawResource;

    if (resourceLocator.resource) {
      schema_name = resourceLocator.schema_name;
      resource = resourceLocator.resource;
    } else if (dsResResourceLocator.resource) {
      schema_name = dsResResourceLocator.schema_name;
      resource = dsResResourceLocator.resource;
    } else {
      schema_name = null;
      resource = null;
    }

    // Тут бы отменять загрузку файла если сюда пришли еще раз
    if (resource) {
      const url = AppConfig.fixRequestUrl(`/srv/resources/${schema_name}/${this.alt_id}`);
      const response = await axios.get(url);
      this._updateWithData({resource, schema_name, content: response.data});
    } else {
      this._updateWithData({resource: null, schema_name: null, content: null});
    }
  }
}
