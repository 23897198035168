exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.DlgAbout{position:relative;border-radius:.5rem;border:none;box-shadow:var(--shadow_panel);max-width:80vh;width:30rem;padding:1rem;display:flex;flex-direction:column;background:var(--color2);color:var(--color1)}@media screen and (max-width: 1024px){.DlgAbout{width:auto}}.DlgAbout__Header{display:flex;flex-direction:row}.DlgAbout__Body{margin-top:1rem}.DlgAbout__Components{min-height:5rem;padding:1rem;box-shadow:var(--panel_push-down);list-style:none;margin:1rem 0 0 0;border-radius:.5rem}.DlgAbout__Component{display:flex;flex-direction:row;align-items:center;min-height:3rem;justify-content:space-between}.DlgAbout__ComponentVersion{justify-self:flex-end;font-weight:bold}.DlgAbout__Footer{margin-top:1rem;width:100%;flex-grow:0;flex-shrink:0;height:3rem;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.DlgAbout__Powered{flex-grow:1;font-size:80%;color:var(--color16);margin-right:1rem}", ""]);

// exports
exports.locals = {
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};