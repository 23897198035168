import React from 'react';
import { loadVizel } from '../utils/loadVizel';
import { extractErrorMessage } from '@luxms/bi-core';
import { data_engine } from '../data-manip/data-manip';
import { IVizelConfig } from '../services/ds/types';
import debounce from 'lodash/debounce';
import ReactResizeDetector from 'react-resize-detector';


interface IVizelElementProps {
  model?: any;
  dp?: data_engine.IDataProvider;
  cfg: IVizelConfig;
  style?: any;
}


export class VizelElement extends React.Component<IVizelElementProps> {
  public props: IVizelElementProps;
  public state: {
    VizelClass: any,
    width: number,
    height: number,
  };
  private _container: HTMLElement | null = null;

  public constructor(props: IVizelElementProps) {
    super(props);
    this.state = {
      VizelClass: null,
      width: 300,
      height: 200,
    };
  }

  public componentDidMount(): void {
    loadVizel(this.props.cfg.getVizelType()).then((VizelClass) => {
      this.setState({
        ...this.state,
        VizelClass,
      });
    });
  }

  public componentWillUnmount() {
    this._resized.cancel();
  }

  private _applySize = () => {
    if (!this.refs.vizel) return;
    const divElement: HTMLElement | null = this._container;
    const width: number = Math.floor(divElement ? divElement.offsetWidth : 300);
    const height: number = Math.floor(divElement ? divElement.offsetHeight : 200);
    console.log('Sending resize to vizel: ', this.props.cfg.getVizelType(), width, height);
    (this.refs.vizel as any).resize(width, height);
  };

  private _resized = debounce(this._applySize, 333);

  private _onSetupContainerRef = (container) => {
    this._container = container;
    this._applySize();
  }

  public render() {
    const {VizelClass} = this.state;

    if (!VizelClass) {
      return (<div className="Vizel vizel loading" style={{position: 'relative', width: '100%', height: '100%', overflow: 'hidden', ...this.props.style}}/>);
    }

    const divElement: HTMLElement | null = this._container;
    const width: number = Math.floor(divElement ? divElement.offsetWidth : 300);
    const height: number = Math.floor(divElement ? divElement.offsetHeight : 200);

    return (
      <div ref={this._onSetupContainerRef}
           className="Vizel vizel"
           style={{position: 'relative', width: '100%', height: '100%', overflow: 'hidden', ...this.props.style}}>
        <ReactResizeDetector handleWidth handleHeight onResize={this._resized} />
        {React.createElement(VizelClass, {...this.props, width, height, ref: 'vizel'})}
      </div>);
  }
}


export const WpLoadingIcon = () => (
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 400 10"
       preserveAspectRatio="xMidYMin meet"
       className="WpLoadingIcon loading-wp-icon">
    <circle className="WpLoadingIcon__Ball bi-wp-loading" cx="5" cy="5" r="5"/>
    <circle className="WpLoadingIcon__Ball bi-wp-loading" cx="5" cy="5" r="5"/>
    <circle className="WpLoadingIcon__Ball bi-wp-loading" cx="5" cy="5" r="5"/>
    <circle className="WpLoadingIcon__Ball bi-wp-loading" cx="5" cy="5" r="5"/>
  </svg>);


function getComponentUrl(viewClassId: string): string {
  let prefix = '.';
  if (viewClassId.match(/@plugins\/(.+?)\/(.*)$/)) {
    prefix = `../plugins/${RegExp.$1}/views`;
    viewClassId = '/' + RegExp.$2;
  }

  let suffix: string = '';

  return prefix + viewClassId + suffix;
}


async function loadComponent(viewClassId: string): Promise<any> {
  const url: string = getComponentUrl(viewClassId);

  if (viewClassId[0] === '@') {
    const pluginId = viewClassId.slice(1);
    const PluginsManager = (await import('../plugins/plugins-manager')).PluginsManager;
    const pluginsManager = PluginsManager.getInstance();
    await pluginsManager.whenReady();
    const component = pluginsManager.getModel().find(p => p.id === pluginId);
    return component;

  } else {
    throw new Error('loadComponent not supported');
    // module = await import(url);
  }
}


export class BIView<T extends {viewClassId: string, viewClass?: any, children?: React.ReactNode}> extends React.PureComponent<T> {
  public props: T;
  public state: {
    viewClassId: string;
    componentClass: any;
    loading: boolean;
    error: string;
  };
  private _isMounted: boolean = false;
  private _viewClassId: string = '';

  private static _components: {[viewClassId: string]: any} = {};
  private static _loadingComponents: {[viewClassId: string]: Promise<any>} = {};

  public constructor(props: T) {
    super(props);
    this.state = {
      viewClassId: '',
      componentClass: props.viewClass || null,
      loading: !props.viewClass,
      error: null,
    };
    if (!props.viewClass) {
      this._loadComponent(props.viewClassId);
    }
  }

  public componentDidMount() {
    this._isMounted = true;
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public UNSAFE_componentWillReceiveProps(props: T) {
    if (!props.viewClass) {
      this._loadComponent(props.viewClassId);             // start loading new class (if it is new)
    } else {
      this.setState({componentClass: props.viewClass});
    }
  }

  private _updateState(s) {
    if (this._isMounted) {
      this.setState(s);
    } else {
      this.state = s;
    }
    this._viewClassId = s.viewClassId;
  }

  private async _loadComponent(viewClassId: string) {
    if (this._viewClassId === viewClassId) {
      return;
    }

    if (viewClassId in BIView._components) {
      this._updateState({
        viewClassId,
        componentClass: BIView._components[viewClassId],
        loading: false,
        error: null,
      });
      return;
    }

    if (!(viewClassId in BIView._loadingComponents)) {        // need to start loading
      BIView._loadingComponents[viewClassId] = loadComponent(viewClassId);
    }

    this._updateState({
      viewClassId,
      componentClass: null,
      loading: true,
      error: null,
    });

    try {
      const componentClass = await BIView._loadingComponents[viewClassId];

      if (this._viewClassId === viewClassId) {        // maybe interrupted
        this._updateState({
          viewClassId,
          componentClass,
          loading: false,
          error: null,
        });
      }

    } catch (err) {
      console.error('Error while loading component', err);

      if (this._viewClassId === viewClassId) {        // maybe interrupted
        this._updateState({
          viewClassId,
          componentClass: null,
          loading: false,
          error: extractErrorMessage(err),
        });
      }
    }
  }

  public render() {
    const {componentClass, loading, error} = this.state;

    if (loading) {
      return null;
    }

    if (error) {
      return null;
    }

    return React.createElement(componentClass, this.props);
  }
}
