import { AppConfig } from '@luxms/bi-core';
import axios from 'axios';

class Tracking {
  private _enabled: boolean;

  constructor() {
    this._enabled = true;
  }

  private _getRequestUrl(): string {
    return AppConfig.fixRequestUrl('/api/track-user/');
  }

  private _isEnabled(): boolean {
    return this._enabled;
  }

  private _sendInfo(action: string, dsId?: string | number, id?: string): Promise<any> {
    if (!this._isEnabled())
      return null;

    const res: any = {
      action: action,
      ui: 'WEB',
    };

    if (id != null) {
      res.id = id;
    }

    if (dsId != null) {
      res.dataset = dsId;
    }

    return axios.post(this._getRequestUrl(), res);
  }

  public onRouteChange(route: string, dsId?: string | number, id?: string): void {
    switch (route) {
      case '#ds':
        this._sendInfo('NAV_DATASETS');
        break;
      case '#dashboards':
        this._sendInfo('NAV_DASHBOARD', dsId, id);
        break;
      case '#map':
        this._sendInfo('NAV_MAP', dsId);
        break;
      case '#trends':
      case '#plots':
        this._sendInfo('NAV_TRENDS', dsId);
        break;
    }
  }

  public onDrilldown(dsId?: number): void {
    this._sendInfo('FUNC_DRILLDOWN', dsId);
  }

  public onShowTable(dsId?: number): void {
    this._sendInfo('FUNC_TABLE', dsId);
  }

  public onMapfill(dsId?: number): void {
    this._sendInfo('FUNC_MAP_COLOR_FILL', dsId);
  }

}

export const tracking: Tracking = new Tracking();
export default tracking;
