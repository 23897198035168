/// <reference path="../../services/norm.ts"/>
/**
 *  Echarts Plot vizel
 */

import React from 'react';
import ReactDOM from 'react-dom';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import {FIND_M, formatNum, makeValue, stringSubstitute} from '../../utils/utils';
import {coloring} from '../../utils/utilsEchars';
import {VizelConfigDisplay} from '../../services/ds/ds-helpers';
import {VizelXYVC, ISerie, IVAxis, IVizelXYVM} from '../../view-controllers/vizels/VizelXYVC';
import BaseVizelEcharts from './BaseVizelEcharts';
import {OptionsProvider} from '../../config/OptionsProvider';
import {IVizelConfig, IVizelProps} from '../../services/ds/types';
import {$eid, $eidx} from '../../libs/imdas/list';

import {
  IEntity,
  IMetric, IOptionsProvider,
  IPeriod,
  IRange, ISubspace,
  IUnit, IValue, IVCPV,
  IVizelConfigDisplay,
  IVizelPropertiesDataRange,
  tables,
} from '../../defs/bi';
import formatNumberWithString from '@luxms/format-number-with-string';

import {getAxisGap, getTextWidth} from './utility/c-utils';
import {Stoplights} from '../../config/Stoplights';
import {lpeRun} from '../../utils/lpeRun';

import 'katex/dist/katex.min.css';
import VizelFromCfg from '../components/Vizel/VizelFromCfg';
import {ThemeVC} from '../../view-controllers/ThemeVC';
const Latex = React.lazy(() => import('react-latex-next'));

const skin: any = require('../../skins/skin.json');
const fontFamily = skin.hasOwnProperty('main-font-family') ? skin['main-font-family'] : 'HeliosCondC';

function getHighchartsDashStyle(idx: number): string {
  const dashStyles = [
    'Solid',
    'ShortDot',
    'ShortDash',
    'ShortDashDot',
    'ShortDashDotDot',
    'Dot',
    'Dash',
    'LongDash',
    'DashDot',
    'LongDashDot',
    'LongDashDotDot',
  ];
  return dashStyles[Math.abs(idx) % dashStyles.length];
}

function getEchartsDashStyle(strokeStyle: string): any {
  let lineStyle = {};
  switch (strokeStyle) {
    case 'ShortDot':
      lineStyle = {
        type: [2, 10],
        dashOffset: 0
      };
      break;
    case 'ShortDash':
      lineStyle = {
        type: [5, 10],
        dashOffset: 0
      };
      break;
    case 'ShortDashDot':
      lineStyle = {
        type: [2, 5, 5, 5],
        dashOffset: 0
      };
      break;
    case 'ShortDashDotDot':
      lineStyle = {
        type: [10, 5, 2, 5, 2, 10],
        dashOffset: 0
      };
      break;
    case 'Dot':
      lineStyle = {
        type: 'dotted'
      };
      break;
    case 'Dash':
      lineStyle = {
        type: 'dashed'
      };
      break;
    case 'LongDash':
      lineStyle = {
        type: [30, 5],
        dashOffset: 0
      };
      break;
    case 'DashDot':
      lineStyle = {
        type: [2, 5, 10, 5],
        dashOffset: 0
      };
      break;
    case 'LongDashDot':
      lineStyle = {
        type: [2, 5, 30, 5],
        dashOffset: 0
      };
      break;
    case 'LongDashDotDot':
      lineStyle = {
        type: [30, 5, 2, 5, 2, 10],
        dashOffset: 0
      };
      break;
    case 'Solid':
    default:
      lineStyle = {
        type: 'solid'
      };
      break;
  }
  return lineStyle;
}

export class EPlot extends BaseVizelEcharts<IVizelXYVM> {
  protected _MAX_YS: number = 15;
  protected _vm: IVizelXYVM;
  protected _xAxisTickTitleRotationAngle: number | null;             // TODO: move to cfg.xAxis.labelRotation
  protected _vc: VizelXYVC;
  protected _chartConfig: any = null;

  protected _range: IVizelPropertiesDataRange = {};
  protected _units: IUnit[] = [];

  public constructor(props: IVizelProps) {
    super(new VizelXYVC(props.dp, props.cfg), props);
    if (this._cfg.showLegend == null) {
      this._cfg.showLegend = this._cfg.getOption('DisplayLegend', true);
    }
    ThemeVC.getInstance().subscribeUpdatesAndNotify((model) => {
      if (model.loading || model.error)
      this.resize();
    });
  }

  // deprecated, override
  protected _viewModelDataChanged(vm: IVizelXYVM, prevVM: IVizelXYVM): void {
    if (vm.series && vm.categories) {
      super._viewModelDataChanged(vm, prevVM);
    }
  }

  protected _getRangeForUnit(unit: IUnit): IRange {
    const display: IVizelConfigDisplay = this._getConfigDisplay();
    const isYLogAxis: boolean = !!this._cfg.getOption('YLogAxis');

    if (display.hasRange()) return display.getRange();
    if (Array.isArray(this._cfg['autoscaleRange'])) return this._cfg['autoscaleRange'] as any;

    const unitId: number = unit ? unit.id : null;
    let r: IRange;
    if (this._range.hasOwnProperty(unitId)) r = clone(this._range[unitId]);
    else if (unit?.config?.range) r = [unit?.config?.range?.[0] ?? null, unit?.config?.range?.[1] ?? null];
    else {
      r = [null, null];         // TODO: might be an error: r[0] == 0 and is >= 0

      let zeroBased = this._cfg.getOption('VAxisZeroBased');

      if (zeroBased === true) {
        if (this._isVAxisZeroBased(unit) && r[0] >= 0) {
          r[0] = 0;

          if (this._chart) {
            try {
              const vAxis: any = this._getVAxisByUnit(unit);
              if (vAxis && (vAxis as any).dataMin < 0) {                             // bars can be negative
                r[0] = null;
              }
              if (isYLogAxis) {
                r = this._cfg.getRange(); // null ломает logAxis
              }
            } catch (err) {
              // skip: might be when no-data and no initialization
            }
          }
        }
      } else {
        try {
          const vAxis: any = this._getVAxisByUnit(unit);
          const isLine = this._cfg.getVizelType() === 'line' || this._cfg.getVizelType() === 'scatter';
          // т.к. line && scatter не должны быть от 0. ЭТО ВИДИТИ ЛИ не красиво
          r[0] = isLine || isYLogAxis ? vAxis.dataMin : null;
          r[1] = isYLogAxis ? vAxis.dataMax : null; // раньше здесь было dataMax

        } catch (err) {
          // skip: might be when no-data and no initialization
        }
      }
    }

    return r;
  }

  protected _getYVizelType(y: IEntity): string {
    const legendItem: tables.ILegendItem = this._cfg.getLegendItem(y);
    return (legendItem && (legendItem.vizelType || legendItem.widgetType)) || this._getVizelType();
  }

  protected _getEchartsChartType(y: IEntity): string {
    const vizelType: string = this._getYVizelType(y);
    switch (vizelType) {
      case 'area':
      case 'area-v2':
      case 'stacked-area':
      case 'spline':
      case 'line':
        return 'line';
      case 'bar':
      case 'fixed-bar':
      case 'stacked-bar':
      case 'column':
      case 'fixed-column':
      case 'stacked-column':
      case 'waterfall':
      case 'thermometer':
      case 'column1d':
        return 'bar';
      case 'scatter':
        return 'scatter';
      case 'radar':
      case 'radar1d':
        return 'radar';
      case 'semicircle':
      case 'gauge':
      case 'circle':
        return 'gauge';
      case 'classified-bar'  :
      case 'classified-column':
      case 'compare-sort'     :
        return 'bar';
    }
    return 'line';
  }

  /**
   * @param y     entity from y-axis
   * @returns     serie is stacked
   */
  protected _isStacked(y: IEntity): boolean {
    const vizelType: string = this._getYVizelType(y);
    switch (vizelType) {
      case 'stacked-column':
      case 'stacked-bar':
      case 'stacked-area':
        return true;
      default:
        return false;
    }
  }

  protected _isFixed(y: IEntity | null): boolean {
    const vizelType: string = this._getYVizelType(y);
    switch (vizelType) {
      case 'fixed-column':
      case 'fixed-bar':
        return true;
      default:
        return false;
    }
  }

  /**
   * @param u     entity from unit (v-axis)
   * @returns     is x-axis should be zero-based (should it start from zero if values are greater)
   */
  protected _isVAxisZeroBased(u: IUnit): boolean {
    if (this._cfg.hasOption('VAxisZeroBased')) {
      return true;
    }

    return false;
  }

  protected _getLegendTitle(e: IEntity): string {
    let label: string = e ? this._cfg.getTitle(e) : '';
    if (e && this._cfg.getOption('LegendFullTitle')) {
      let p: IEntity = e;
      while ((p = p.parent)) {
        label = this._cfg.getTitle(p) + ' / ' + label;
      }
    }
    return label;
  }

  protected _onChartClick = (params): void => {
    const {seriesIndex, dataIndex, value} = params;
    const vm = this._vm;
    const chart: any = this._chart;
    if (!chart) return;

    const z: IEntity = vm.subspace.getZ(0);
    const y: IEntity = vm.subspace.getY(seriesIndex);
    const x: IEntity = vm.categories[dataIndex];

    if (!z || !y || !x) return;

    const {m, l, p} = vm.subspace.getMLP(z, y, x);
    const v: IValue = value;

    this._onClickDataPoint(params, {x, y, z, m, l, p, v});
  }

  protected _tooltipFormatter(vm: any, params: any): string | HTMLElement {
    const {seriesIndex, value, marker, dataIndex} = params;
    const seriesEntity = vm.series[seriesIndex]?.e;

    const cfg = this.props.cfg, rawCfg = cfg.getRaw();
    const color = this.theme.color1;
    const title = cfg.getTitle(seriesEntity);
    const li = cfg.getLegendItem(seriesEntity);

    const enableAxisTitle = cfg.getOption('TooltipXAxisTitle', false);
    const isVAxisInteger = cfg.getOption('VAxisInteger', false);

                                       // tooltip менять в зависимости от y
    const categoriesEntity = vm.categories[dataIndex];
    const unit = seriesEntity?.unit ?? categoriesEntity?.unit;

    const formatPercent = li?.formatPercent || '#.0%';
    const format = isVAxisInteger ? '# ###' : (cfg.getFormat(seriesEntity) || cfg.getFormat(categoriesEntity) || null);

    const sumSeries = vm.series.map(s => s.numValues[dataIndex]).filter(Boolean).reduce((acc, curr) => acc += Number(Math.abs(curr)), 0);
    const percent = (Math.abs(value) / sumSeries) * 100;

    let fValue = format ? formatNumberWithString(value, format) : makeValue(value, unit);
    if (Array.isArray(value)) fValue = format ? formatNumberWithString(value[1], format) : makeValue(value[1], unit);

    let container: any = null;
    const tooltip = li?.tooltip ?? cfg.display?.tooltip;

    if (String(tooltip).startsWith('latex:')) {                                                     // LaTeX: простой вариант - строка, начинающаяся с latex:
      container = document.createElement('section');
      ReactDOM.render(
        <React.Suspense fallback={null}>
          <Latex>{stringSubstitute(tooltip.slice(6), {v: fValue, y: title, x: params.name})}</Latex>
        </React.Suspense>, container);
    } else if (tooltip?.view_class) {                                                               // вставляем визель в тултипы
      container = document.createElement('section');
      container.style.width = tooltip.width ?? '7rem';
      container.style.height = tooltip.height ?? '7rem';
      container.style.position = 'relative';
      container.style.overflow = 'hidden';

      const newCfg = cloneDeep(tooltip);
      const filters = newCfg?.dataSource?.filters ?? {};
      const vcpFilters = {};                                                          // Обогащаем фильтры от точки, на которую навели
      categoriesEntity.axisIds?.forEach?.((id, idx) => (id !== 'measures' && (vcpFilters[id] = ['=', categoriesEntity.ids[idx]])));
      seriesEntity.axisIds?.forEach?.((id, idx) => (id !== 'measures' && (vcpFilters[id] = ['=', seriesEntity.ids[idx]])));
      newCfg.dataSource = {...rawCfg.dataSource, ...newCfg.dataSource, filters: {...rawCfg.dataSource?.filters, ...vcpFilters, ...filters}};
      ReactDOM.render(
        <React.Suspense fallback={null}>
          <VizelFromCfg schema_name={cfg.dataset.schema_name} rawCfg={newCfg}/>
        </React.Suspense>, container);
    } else if (String(tooltip).startsWith('html:')) {
      return (
        `<section class="tooltipContainer" style="color: ${color}; white-space: pre-wrap; text-align: start">${stringSubstitute(tooltip.slice(5), {
          v: (pattern, format?) => format ? formatNumberWithString(value, format) : makeValue(value, unit),
          y: title,
          x: params.name,
          percent,
          'v%': percent,
          marker,
        }, {percent: formatPercent, 'v%': formatPercent})}
        </section>`);

    } else {
      return (
        `<section class="tooltipContainer" style="max-width:300px; color: ${color};">
          <span style="white-space: normal">${title}${(enableAxisTitle) ? `<br/> ${params.name}` : ''}</span>
          <br/> ${marker}
          <span style="font-size: 14px;line-height: 1">
            <span style="font-weight: 900;">${fValue}</span>
          </span>
       </section>`);
    }

    // Размаунчиваю элемент если размаунтился тултип.
    // На момент написания данного кода в библиотеке echarts нет колбэка (хендлера)
    // о размаунте самого тултипа
    const intervalId = setInterval(() => {
      if (!container) return clearInterval(intervalId);
      if (!container.closest('div')) {
        ReactDOM.unmountComponentAtNode(container);
        return clearInterval(intervalId);
      }
    }, 8000);

    return container;
  }

  protected _handlePointClick(event, serie, category, v: IValue): void {
    const vm = this._vm;
    const z: IEntity = vm.subspace.getZ(0);
    const y: IEntity = $eid(vm.subspace.ys, serie.options.entityId) || vm.subspace.getY(serie.index);
    let x = category;

    if (typeof category === 'number' && vm.dateTimeXAxisPeriodType) {
      let ps: IPeriod[] = vm.categories as IPeriod[];
      x = ps.find(p => p.startDate.toDate().valueOf() === category);
    }

    const {m, l, p} = vm.subspace.getMLP(z, y, x);
    this._onClickDataPoint(event, {z, y, x, m, l, p, v});
  }

  protected _getEchartsDataLabelsConfig(vm: IVizelXYVM): any {
    const self: EPlot = this;
    const cfg = this._cfg;
    const display: any = this._getConfigDisplay() || {};

    const isDisplayAllBadges: boolean = cfg.getOption('DisplayAllBadges');
    const isDisplayLastValueBadge: boolean = !isDisplayAllBadges && cfg.getOption('DisplayLastValueBadge');
    const isDataLabelsOnTop: boolean = cfg.getOption('DataLabelsOnTop');
    const isFontWeightNormal: boolean = cfg.getOption('FontWeightNormal');
    const isHideUnits: boolean = cfg.getOption('HideUnits');
    const isVAxisInteger = cfg.getOption('VAxisInteger') || false;
    const isDisplayAllBadgesForSerie = (e: IEntity, i: number) => {
      const li = cfg.getLegendItem(e, i);
      return li && li.options && (new OptionsProvider(li.options).getOption('DisplayAllBadges') === true);
    };

    const isAnySerieHasDisplayAllBadges: boolean = !!vm.series.find(s => isDisplayAllBadgesForSerie(s.e, s.index));
    let dataLabels: any = {
      show: isDisplayAllBadges || isDisplayLastValueBadge || isAnySerieHasDisplayAllBadges,
      borderWidth: isDisplayAllBadges || isAnySerieHasDisplayAllBadges ? 0 : 1,
      // allowOverlap: isDisplayAllVeryBadges || isDisplayAllDataLabels,
      textShadowColor: 'transparent',
      distance: 7,
      // offset: 0,
      position: this._getVizelType().indexOf('line') != -1 ? 'top' : 'inside',
      verticalAlign: 'middle',
      fontSize: 11,
      overflow: 'none',

      formatter: function (params) {
        const {seriesIndex, dataIndex, value} = params;
        const category: IEntity = vm.categories[dataIndex];
        const serie: IEntity = vm.series[seriesIndex]?.e;
        const z: IEntity = vm.subspace.getZ(0);
        const m: IMetric = FIND_M(category, serie, z);

        const liX = cfg.getLegendItem(serie);
        const liY = cfg.getLegendItem(category);

        const unit = isHideUnits ? null : (m?.unit ?? serie?.unit ?? category?.unit ?? null);
        let format = liX?.format ?? liY?.format ?? null;
        if (isVAxisInteger) format = '# ###';

        const configStyle = cfg?.getRaw()?.dataSource?.style?.measures || {};

        if (isDisplayAllBadges || isDisplayAllBadgesForSerie(serie, seriesIndex)) {
          const serieId = serie.id;
          const serieStyleConf = configStyle ? configStyle[serieId] : null;
          const badgeTitle = serieStyleConf ? serieStyleConf['badgeTitle'] : null;
          if (badgeTitle) {
            const ctx = {};
            vm.series.forEach((v) => {
              const key = v.e.id;
              const value = v.values[dataIndex];
              ctx[key] = value;
            });

            const titleResult = lpeRun(badgeTitle, ctx);
            const numberValue = (typeof titleResult === 'number') ? formatNumberWithString(titleResult, format) : titleResult;
            return numberValue;
          }
          return format  ? formatNumberWithString(value,  format) : makeValue(value, unit);
        }

        return format  ? formatNumberWithString(value,  format) : makeValue(value, unit);
      },
    };   // dataLabels

    if (isFontWeightNormal) {
      dataLabels = {
        ...dataLabels,
        fontWeight: 'normal',
      };
    }

    dataLabels = {       // populate with values from config
      ...dataLabels, ...display.badges,
      distance: 7,
      color: 'black', // изменен цвет шрифта
      // fontWeight: 'bold',
      backgroundColor: 'transparent' // убрана подложка
    };
    if (isDataLabelsOnTop) {
      dataLabels = {       // populate with values from config
        ...dataLabels, ...display.badges,
        position: 'top',
        distance: 5,
        verticalAlign: null,
      };
    }
    if (isDisplayLastValueBadge) {    // for last value: some special settings
      dataLabels = {
        ...dataLabels,
        align: 'left',
        verticalAlign: 'middle',
        backgroundColor: 'white',
      };
    }

    return dataLabels;
  }

  protected _getEchartsConfig(vm: IVizelXYVM) {
    const cfg = this._cfg;
    const self = this;
    const showLegend: boolean = this._cfg['showLegend'];
    const isDisplayAllBadges: boolean = cfg.getOption('DisplayAllAxisLabels');
    const isDisplayDataZoom: boolean = !!cfg.getOption('DisplayDataZoom');
    const isDisplayAxis: boolean = this._cfg.getOption('DisplayAxis', true);
    const isXAxisValue: boolean = cfg.getOption('XAxisValue', false);

    const additional: any = (this._cfg.getRaw() as any).echart || {};
    const chartType: string = this._getEchartsChartType(null);
    const legendSymbol: string = chartType == 'line' ? null : 'circle';
    const rotateXLabel: number = this._cfg.getDisplay()?.rotateXLabel ?? null;

    const isDisplayAxisXMarks: boolean = cfg.getOption('DisplayAxisXMarks', true);

    const stoplights = cfg.getStoplights();

    let selected = {};
    let legendData = [];
    let gapInfo: any = {left: 0, right: 0};
    let longestLeftValue = '';
    let longestRightValue = '';

    vm.series.map((s) => {
      const li: any = this._cfg.getLegendItem(s.e, s.index) || {};
      const liOptions: IOptionsProvider = new OptionsProvider(li.options);
      const title = this._getLegendTitle(s.e);
      legendData.push({name: s.title, icon: legendSymbol});
      selected[s.title] = !!!liOptions.hasOption('DisableLegend');
      s.strValues.forEach((str) => {
        const value = str.split('.')[0];
        if (vm.vAxes[s.vAxisIndex].opposite) {
          if (value.length > longestRightValue.length) longestRightValue = value;
        } else {
          if (value.length > longestLeftValue.length) longestLeftValue = value;
        }
      });
    });

    let leftDash = 0;
    let rightDash = 0;
    let leftAxes = vm.vAxes.filter(el => !el.opposite).map(el => el.id);
    let rightAxes = vm.vAxes.filter(el => el.opposite).map(el => el.id);
    let maxNameGap = 0;

    let yAxisConfig = vm.vAxes.map((vAxis, index) => {
      const {
        gridGap,
        nameGap
      } = getAxisGap(vAxis?.unit?.axis_title, vAxis.opposite ? longestRightValue : longestLeftValue);
      let offset = 0;
      maxNameGap = nameGap > maxNameGap ? nameGap : maxNameGap;
      if (vAxis.opposite) {
        gapInfo.right = gridGap > gapInfo.right ? gridGap : gapInfo.right;
        rightDash = rightAxes.indexOf(vAxis.id) == 0 ? 0 : gridGap + nameGap;
        offset = rightAxes.indexOf(vAxis.id) == 0 ? 0 : rightDash + 20;
        gapInfo.right += rightDash;
      } else {
        gapInfo.left = gridGap > gapInfo.left ? gridGap : gapInfo.left;
        leftDash = leftAxes.indexOf(vAxis.id) == 0 ? 0 : gridGap + nameGap;
        offset = leftAxes.indexOf(vAxis.id) == 0 ? 0 : leftDash + 20;
        gapInfo.left += leftDash;
      }

      if (gapInfo.right < 40) gapInfo.right = 40;
      if (gapInfo.left < 30) gapInfo.left = 30;
      return this._getChartConfigForVAxis(vAxis, vAxis.opposite ? nameGap + 10 : (leftAxes.indexOf(vAxis.id) == 0 ? nameGap + 10 : nameGap), offset);
    });

    if (maxNameGap > gapInfo.left) gapInfo.left = maxNameGap;
    if (vm.vAxes.length == 1) gapInfo.right = '4%';

    const labelLimit = this._cfg.getDisplay()?.xAxisLabelLimit ?? null;

    let axisLabel = {
      interval: 0,
      rotate: (rotateXLabel) ? rotateXLabel : 45,
      fontSize: 9,
      fontFamily: fontFamily,
      hideOverlap: !isDisplayAllBadges,
      width: labelLimit ? Number(labelLimit) * 9 : null,
      overflow: labelLimit ? 'truncate' : 'none',
      show: isDisplayAxisXMarks,
    };

    let totalWidthOfLabels = 0;
    let longest = 0;
    vm.categories.map((cat, i) => {
      const textWidth = Number(getTextWidth(cat.title, {
        fontSize: 9,
        fontFamily: fontFamily
      }));

      if (longest < textWidth) longest = textWidth;

      totalWidthOfLabels = Number(totalWidthOfLabels) + Number(getTextWidth(cat.title, {
        fontSize: 9,
        fontFamily: fontFamily
      }));
    });

    if ((this._$container && this._$container[0]?.clientWidth < totalWidthOfLabels) || (longest > (totalWidthOfLabels / 2)) ) {
      const containerWidth = this._$container[0]?.clientWidth;

      const maxWidth = vm.categories.length ? Math.round(containerWidth / vm.categories.length) * 0.8 : 0;
      axisLabel = {
        ...axisLabel,
/*        rotate: Number(maxWidth) < 50 ? 45 : 0,
        width: Number(maxWidth) < 50 ? 200 : Number(maxWidth),*/
        rotate: (rotateXLabel) ? rotateXLabel : (labelLimit > 0 || Number(maxWidth)) < 50 ? 45 : 0,
        width: (labelLimit) ? Number(labelLimit) * 9 : Number(maxWidth) < 50 ? 200 : Number(maxWidth),
        overflow: labelLimit ? 'truncate' : 'break',
      };
    }

    // смена расположения зшкалы зума для бар и стакед бар
    let dataZoom: any = [
      {
        type: 'slider',
        xAxisIndex: 0,
        // bottom: (!!showLegend) ? 45 : (this._cfg.getOption('DisplayAxisXMarks', true)) ? 65 : 5,
        bottom: !!showLegend ? '15%' : (isDisplayAxisXMarks ? 35 : '5%'),
        ...(additional.dataZoom || {})
      },
      {
        xAxisIndex: 0,
        type: 'inside',
      },
    ];

    const typeV = this._cfg?.getVizelType();

    const YTypes = [
      'bar',
      'stacked-bar',
      '1II.stacked-bar',
      '1II.bar'
    ];

    if (!!YTypes.find((type) => typeV.endsWith(type))) {
      dataZoom = [
        {
          type: 'slider',
          yAxisIndex: 0,
          // bottom: (!!this._cfg['showLegend']) ? 78 : 5,
          // bottom: showLegend ? '15%' : (!!isDisplayAxisXMarks) ? '20%' : '5%', // todo что-то нужно сделать
          top: '4%',
          ...additional.dataZoom
        },
        {
          yAxisIndex: 0,
          type: 'inside',
        },
      ];

    }

    let visualMap = null;
    const raw = this._cfg.getRaw();

    if (raw?.display?.stoplight && !raw?.echart?.visualMap) {
      let orientation: any = {
        top: 30,
        right: 5,
        orient: 'vertical'
      };

      if (YTypes.includes(typeV)) {
        orientation = {
          bottom: 10,
          left: 10,
          orient: 'vertical',
          dimension: 0,
        };
      }

      const s: any = Stoplights.createWithMatrix(raw.display.stoplight, vm.series.map(s => s.numValues));
      const stopPoints = s?.getPoints(), stopZones = s?.getLights();

      if (stopPoints?.length) {
        const start = stopPoints[0].value;
        const end = stopPoints[stopPoints.length - 1].value;
        const colors = stopPoints.map(sp => sp.color);                                              // неправильно (стопопинты неравномерны), но требует дальнейшего расследования

        visualMap = {
          ...orientation,
          min: start,
          max: end,
          calculable: true,
          realtime: false,
          inRange: {
            color: colors
          }
        };
      } else if (stopZones?.length) {
        let pieces = stopZones.map(sz => {
          let start = sz.limit?.[0] || null;
          let end = sz.limit?.[1] || null;

          // поправка  что бы не отваливался echart если все по нулям
          if (start === 0) start = 0.0000001;
          if (end === 0) end = 0.0000001;
          if (start === end) {
            start = start - 0.0000001;
            end = end + 0.0000001;
          }
          return {                                                                                  // Странная бага для line: https://github.com/apache/echarts/issues/5801
            gt: Number.isFinite(start) ? start + 1e-10 : null,
            lte: Number.isFinite(end) ? end - 1e-10 : null,
            color: sz.color,
          };
        }).filter(v => !(v.gt === null && v.lte === null));

        let e = vm.series?.[0]?.e;
        let color = this._cfg.getColor(e, null, vm.series?.[0].index) || this._colorResolver.getColor(e) || this.theme.color1;

        visualMap = {
          ...orientation,
          pieces: pieces,
          outOfRange: {
            color: color
          },
        };
      }

    }

    if (raw?.echart?.visualMap) {
      visualMap = raw?.echart?.visualMap;
    }

    const theme = this.theme;
    const backgroundColor = theme.color2;

    const chartConfig: any = {
      ...additional,
      title: {
        show: false,
        text: this._cfg.title
      },
      tooltip: {
        show: true,
        textStyle: {
          fontFamily: fontFamily
        },
        appendToBody: true,
        formatter: (params) => this._tooltipFormatter(vm, params),
        axisPointer: {
          type: 'shadow'
        },
        ...(additional.tooltip || {}),
      },
      grid: {
        top: '4%',
        id: 's-grid',
        containLabel: true,
        show: true,
        left: gapInfo.left,
        right: !!visualMap ? '12%' : gapInfo.right,
        bottom: !!showLegend ?  '20%' : '5%',
        ...(additional.grid || {}),
      },
      visualMap: visualMap,
      legend: {
        show: showLegend,
        orient: 'horizontal',
        x: 'center',
        y: 'bottom',
        formatter: function (name){
          const serie = vm.series.find(s => s.title === name);
          const title = self._getLegendTitle(serie.e);
          return title;
        },
        padding: 15,
        textStyle: {
          lineHeight: 11,
          fontFamily: fontFamily
        },
        selected,
        data: legendData,
        ...(additional.legend || {}),
      },
      dataZoom: isDisplayDataZoom ? dataZoom : null,
      xAxis: {
        axisLine: {
          show: isDisplayAxis,
          onZero: null,
          lineStyle: {}
        },
        type: isXAxisValue ? 'value' : 'category',
        data: vm.categories.map(x => this._cfg.getTitle(x)),
        axisLabel,
        axisTick: {show: false, alignWithLabel: true, interval: 0},
        ...(additional.xAxis || {}),
      },
      yAxis: yAxisConfig,
      series: vm.series.map(serie => this._getChartConfigForSerie(serie, vm.categories, vm.dateTimeXAxisPeriodType)),
      animation: false,
      animationDelay: 0,
    };
    this._chartConfig = chartConfig;
    return chartConfig;
  }

  protected _onChartCreated(chart: HighchartsChartObject): void {
    this._notifyDataRangeChanged(); // это функция нужна, чтобы при инициализации в трендах работал ОБЩИЙ МАСШТАБ
    this.recalculateChart(chart);
  }

  protected _getChartConfigForVAxis(vAxis: IVAxis, nameGap?: number, offset?: number): any {
    const additional: any = (this._cfg.getRaw() as any).echart || {};
    const self: EPlot = this;
    const cfg = this._cfg;

    const rotateYLabel: number = this._cfg.getDisplay()?.rotateYLabel ?? null;

    const u: IUnit = vAxis.unit;
    const range: IRange = this._getRangeForUnit(u);
    // const range: IRange = this._cfg.getRange();
    const isYLogAxis: boolean = !!cfg.getOption('YLogAxis');
    const isDisplayAxisYMarks = cfg.getOption('DisplayAxisYMarks', true);
    const isDisplayAxis: boolean = this._cfg.getOption('DisplayAxis', true);
    const isDisplayGrid: boolean = this._cfg.getOption('DisplayGrid', true);
    const isDisplayTicks: boolean = this._cfg.getOption('DisplayTicks', true);
    const isDisplaySplitLines = this._cfg.getOption('DisplaySplitLines', true); // показать/скрыть разделительные линии осей (оставив лейблы и тики осей)

    return {
      show: isDisplayAxis,
      name: vAxis.unit?.axis_title,
      unitId: vAxis.unit?.id,
      nameLocation: 'center',
      nameGap,
      offset,
      nameTextStyle: {
        color: '#7F8B9C',
        fontSize: 16,
        fontFamily: fontFamily,
        fontWeight: 'normal'
      },
      minorTick: {
        show: false
      },
      axisLine: {
        show: true,
        onZero: null,
        lineStyle: {}
      },
      axisTick: {show: isDisplayTicks},
      axisLabel: {
        show: isDisplayAxisYMarks,
        hideOverlap: false,
        showMinLabel: true,
        showMaxLabel: true,
        rotate: (rotateYLabel) ? rotateYLabel : 0,
        fontFamily: fontFamily,
        formatter: function (value, index) {
          const serie: ISerie = self._vm.series[index];
          let li: any = cfg.getLegendItem(serie, index);
          return li?.format ? formatNumberWithString(value, li['format']) : formatNum(value, 1);
        },
      },
      type: isYLogAxis ? 'log' : 'value',
      splitLine: {
        show: isDisplayGrid && isDisplaySplitLines,
      },
      position: vAxis.opposite ? 'right' : 'left',
      min: range[0],
      max: range[1],
      ...additional.yAxis
    };
  }

  public resize(width?: number, height?: number): void {
    super.resize();
    if (!this._chart || !this._$container) return;
    width = width || this._$container.width();
    height = height || this._$container.height();
    // const hideXLabels: boolean = this._cfg.getOption('HideXAxisTickMarks') || this._shouldHideXLabels(width, height);
    //
    // const xAxis: any = this._chart.xAxis[0];
    // xAxis.setOption({labels: {show: !hideXLabels}}, false);

    this._chart.resize(width, height);
    this.recalculateChart(this._chart);

  }

  protected _onLegendClick = (event): void => {
    const {selected, name} = event;
    let isSortByOnlyLegendItem = !!this._cfg.hasOption('SortByOnlyLegendItem');
    let displayRaw = this._cfg.getRaw().display;
    let sortOrderDefault = displayRaw['sort'] ? displayRaw['sort'].toLowerCase() : null;
    let sortByDefault = displayRaw['sortBy'] ? displayRaw['sortBy'] : null;
    let type = this._getEchartsChartType(null);
    if (isSortByOnlyLegendItem && type != 'line') {
      const seriesVisibleleft = Object.keys(selected).filter(name => selected[name]);
      if (seriesVisibleleft.length == 1) {
        this._vc.setUserSort(seriesVisibleleft[0], sortOrderDefault);
      } else {
        this._vc.setUserSort(sortByDefault, sortOrderDefault);
      }
    }

    let options = this._chart.options;

    if (!!options?.legend) {
      this._chart.setOption({
        legend: {
          ...options?.legend,
          selected: selected,
        }
      }, {replaceMerge: 'legend'});

      this._chart.resize();
      this.recalculateChart(this._chart);
    }

  }
  protected _getChartConfigForSerie(vmSerie: ISerie, categories: IEntity[], dateTimeXAxisPeriodType: number): any {
    const cfg = this._cfg;
    const themeBuilder = this.theme.themeBuilder || null;
    const vm = this._vm;
    const e: IEntity = vmSerie.e;

    const additional: any = (this._cfg.getRaw() as any).echart || {};
    const serieType: string = this._getEchartsChartType(e);
    const li: any = cfg.getLegendItem(e) || {};
    const liOptions: IOptionsProvider = new OptionsProvider(li.options);
    const type = li.widgetType != undefined ? li.widgetType : serieType;
    const rawType = this._getYVizelType(e);
    const borderRadius: number = li.borderRadius != undefined ? li.borderRadius : 0;
    let linewidth: number = li.lineWidth !== undefined ? li.lineWidth : 2;
    if (type === 'scatter') linewidth = 0;
    const serieMarker: any = li.marker != undefined ? li.marker : null;
    const isStacking: boolean = this._isStacked(e);
    const isFixed: boolean = this._isFixed(null);
    const isShowPills: boolean = cfg.getOption('ShowPills');
    const isNotNull: boolean = cfg.getOption('NotNull');
    const isDisplayDelta: boolean = !!cfg.getOption('DisplayDelta');
    const serieIndex = vm.series.indexOf(vm.series.find(el => el.id == vmSerie.id));
    const isMain = !!li.options?.includes('Main');
    const isShowBackground: boolean = !!!this._cfg.getOption('!ShowBackground');
    const isXAxisValue: boolean = cfg.getOption('XAxisValue', false);

    // чтобы показать area, выставляю свойству areaStyle
    // значение пустого объекта когда viewClass === 'stacked-area'
    const viewClass = this._cfg.getVizelType();
    let areaStyle = (isDisplayDelta ? (isMain ? null : {}) : null);
    areaStyle = viewClass.indexOf('area') !== -1 ? {} : areaStyle;

    let color: any = this._cfg.getColor(e, null, vmSerie.index) || this._colorResolver.getColor(e);
    let bgColor: any = this._cfg.getBgColor(e, null, vmSerie.index) || this._colorResolver.getBgColor(e);
    const gradientType: string = this._getGradientType(e);
    if (gradientType && String(gradientType).toLowerCase() != '3d') {
      [color, bgColor] = [coloring.makeEchartsGradient(gradientType, color), coloring.makeEchartsGradient(gradientType, bgColor)];
    }

    const lineStyle: any = this._getYStrokeStyle(vmSerie);
    const markerSize: number = serieType == 'scatter' ? 7 : this._getMarkerSize(e, vm);
    const isMarkerEnabled: boolean = (markerSize !== 0);

    const opt: OptionsProvider = new OptionsProvider(li.options || []);
    const isDisplayAllBadges: boolean = opt.getOption('DisplayAllBadges', this._cfg.hasOption('DisplayAllBadges'));
    const isDisplayAllVeryBadges: boolean = opt.getOption('DisplayAllVeryBadges', this._cfg.hasOption('DisplayAllVeryBadges'));
    const isDisplayLastValueBadge: boolean = opt.getOption('DisplayLastValueBadge', this._cfg.hasOption('DisplayLastValueBadge'));
    const isDisplayLastValueBadgeOnTop: boolean = opt.getOption('DisplayLastValueBadgeOnTop', this._cfg.hasOption('DisplayLastValueBadgeOnTop'));
    const customDisplay: any = new VizelConfigDisplay(cfg.getDataset(), li.display || {});
    const marker = li.marker != undefined ? li.marker : {};
    const stack = li.stack != undefined ? li.stack : null;
    let dataLabels = this._getEchartsDataLabelsConfig(vm);
    dataLabels = {
      ...dataLabels,
      show: isDisplayLastValueBadge || isDisplayAllVeryBadges || isDisplayAllBadges,
      distance: 7,
      rotate: 0, // -90:90
      offset: 0,
      // allowOverlap: !!isDisplayAllVeryBadges,
      overflow: 'none', // 'truncate' | 'break' | 'breakAll'
      textBorderColor: '#eee',
      textBorderWidth: 2,
      fontFamily: fontFamily,
      // ...customDisplay.badges,
    };
    if (isDisplayDelta) {
      if (!isMain) {
        dataLabels = {
          ...dataLabels,
          show: false
        };
      }
    }
    let endLabel: any = {
      show: false
    };
    if (serieType == 'scatter') {
      dataLabels = {
        ...dataLabels,
        position: 'top',
        offset: [0, -5],
      };
    }
    if (opt.getOption('DisplayLastValueBadge')) {
      dataLabels = {...dataLabels, show: false};
      // выбивает ошибку, если весь массив numValues === null, негде отрисовывать
      endLabel = {
        ...dataLabels,
        show: (vmSerie.numValues || []).some(v => v !== null),
        position: 'top',
        offset: [-20, (isDisplayLastValueBadgeOnTop ? -10 : 10)],
        textShadow: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
        textBorderColor: '#eee',
        textBorderWidth: 2,
      };
    }
    const isColoredByPoint = liOptions.hasOption('ColorX');

    let data: any[] = vmSerie.numValues;

    if (dateTimeXAxisPeriodType) {
      data = categories.map((x, xi) => [(x as IPeriod).startDate.toDate().valueOf(), vmSerie.numValues[xi]]);
    }
    if (isXAxisValue) {
      data = categories.map((x, xi) => [+x.id, data[xi]]);
    }

    if (isColoredByPoint) {
      //    data = categories.map((x, xi) => ({y: vmSerie.numValues[xi], color: vmSerie.bgColors[xi]}));

      data = categories.map((x, xi) => ({
        value: vmSerie.numValues[xi],
        itemStyle: {
          color: vmSerie.bgColors[xi]
        }
      }));
    }

    if (isNotNull) {
      data = data.filter(elem => elem !== null && elem);
    }
    if (dateTimeXAxisPeriodType && isColoredByPoint) {
      // TODO!
    }
    if (isDisplayLastValueBadge) {
      data = data.map((el, i) => {
        const element = {
          value: null,
          symbol: isMarkerEnabled || i == data.length - 1 ? 'circle' : 'none',
          symbolSize: markerSize,
        };
        element.value = el;
        return element;
      });
    }

    const self = this;

    let serieConfig: any = {
      id: vmSerie.id + '-' + vmSerie.index,
      type: serieType,
      smooth: rawType.indexOf('spline') != -1 || type.indexOf('spline') != -1,
      index: vmSerie.index,
      name: this._cfg.getTitle(e),
      description: e.description || undefined,
      step: this._cfg.getOption('SharpConnection') ? 'left' : null, // start middle end
      color: color,
      areaStyle,
      itemStyle: {
        borderRadius: borderRadius,
      },
      lineStyle: {
        ...lineStyle,
        color: bgColor,
        width: linewidth
      },
      labelLayout: {
        hideOverlap: !isDisplayAllVeryBadges,
      },
      emphasis: {
        focus: 'series'
      },
      stack: isStacking ? (e.unit ? e.unit.id : true) : (isDisplayDelta ? 'stacked' : false),
      yAxisIndex: vmSerie.vAxisIndex,
      data,
      symbol: isDisplayDelta ? (isMain ? (isMarkerEnabled ? 'circle' : 'none') : 'none') : (isMarkerEnabled ? 'circle' : 'none'),
      symbolSize: markerSize,
      entityId: e.id,
      unit: e.unit ?? null,
      ...additional.series,
      label: {...dataLabels, ...li?.label, ...additional?.series?.label},
      endLabel,
    };

    if (serieType == 'bar') {
      serieConfig = {
        ...serieConfig,
        showBackground: isShowBackground
      };
    }

    serieConfig = {
      ...serieConfig,
      lineStyle: {
        color: null,
        ...serieConfig.lineStyle,
      },
    };

    if (isShowPills) {
      serieConfig.symbol = 'circle';
    }
    if (this._cfg.hasOption('ConnectPoints')) {
      serieConfig.connectNulls = true;
    }
    if (isFixed) {
      // serieConfig.pointPadding = interpolateValue(0, vmSerie.index / vm.series.length, 0.5);
    }
    return serieConfig;
  }

  public _getVAxisConfig(vAxis: IVAxis, vAxisNumber: number): any {
    const cfg: IVizelConfig = this._cfg;
    const display: any = cfg.getDisplay();

    const u: IUnit = vAxis.unit;
    const range: IRange = this._getRangeForUnit(u);
    const isDisplayAllBadges: boolean = cfg.hasOption('DisplayAllBadges');
    let ticks: number[] = display.vAxisTicks;
    // TODO: get ticks from legend config: {style.units.[id].ticks}

    const vAxisConfig: any = {
      reversed: cfg.hasOption('VAxisInverted'),
      id: vAxis.id,
      index: vAxis.index,
      title: {
        text: !this._cfg.hasOption('HideVAxisTitle') ? (u ? u.axis_title : null) : null,
        style: {fontWeight: 'bold', fontSize: 18, color: '#444444'},
      },
      tickPositioner: function (min, max) {
        if (ticks) {
          return ticks.filter((v: number) => min <= v && v <= max);
        }
        if (min != null && max != null) {
          let ticks = this.getLinearTickPositions(this.tickInterval, min, max);
          ticks = ticks.filter((v: number) => min <= v && v <= max);
          if (ticks.length === 1) {
            return [min, max];
          }
        }
        return undefined;  // highcharts will use its algorythm
      },
      allowDecimals: !((u && u.isInteger()) || this._cfg.getOption('VAxisInteger')),
      reversedStacks: false,
      alignTick: true,
      startOnTick: vAxisNumber > 1,
      endOnTick: vAxisNumber > 1,
      minPadding: isDisplayAllBadges ? 0.1 : 0,
      maxPadding: isDisplayAllBadges ? 0.11 : 0.01,
      opposite: vAxis.opposite,
      labels: {
        enabled: !this._cfg.getOption('HideVAxisTickMarks'),
        formatter: function () {
          if (u && u.config && u.config.valueMap) {
            return (this.value in u.config.valueMap) ? u.config.valueMap[this.value] : null;
          }
          return formatNum(this.value);
        },
      },
      showRects: vAxisNumber > 1,
      lineWidth: 1,
      min: range[0],
      max: range[1],
    } as any;

    if (this._cfg.hasOption('!VAxisGrid')) {
      vAxisConfig.minorGridLineWidth = 0;
      vAxisConfig.gridLineWidth = 0;
    }

    return vAxisConfig;
  }

  /**
   * @param y     entity from y-axis
   * @returns     unit for selected y
   */
  protected _getUnit(y: IEntity): IUnit {
    const m: IMetric = y as IMetric;
    const u: IUnit = m.unit || null;

    if (this._vm) {
      const vAxis: IVAxis = this._vm.vAxes.find((vAxis: IVAxis) => vAxis.unit === u);
      if (vAxis) {
        return u;
      }
    }

    // count unitIndex based on tagged chart
    // TODO: get MLP by ZYX

    return null;
  }

  // it is possible to override gradient type for
  protected _getGradientType(e?: IEntity): string {
    const vizelType: string = this._getYVizelType(e);
    if (vizelType === 'line') {                        // no gradient for line vizel
      return '';
    }
    return this._getConfigDisplay().getGradient();
  }

  protected _getYStrokeStyle(vmSerie: ISerie): string {
    let strokeStyle: string = getHighchartsDashStyle(vmSerie.vAxisIndex);
    const legendItem: tables.ILegendItem = this._cfg.getLegendItem(vmSerie.e, vmSerie.index);
    if (legendItem && ('strokeStyle' in legendItem)) {
      strokeStyle = legendItem.strokeStyle;
    }
    const lineStyle = getEchartsDashStyle(strokeStyle);
    return lineStyle;
  }

  protected _rtValueUpdated({x, y, z, v}: IVCPV): void {
    const subspace = this._vm.subspace;
    if (z !== subspace.getZ(0)) {
      return;
    }
    const yi: number = $eidx(subspace.ys, y.id);
    const xi: number = $eidx(subspace.xs, x.id);
    if (yi === -1 || xi == -1) {
      return;
    }

    const serie: any = this._chart.series[yi];
    const point: any = serie.points[xi];
    point.update(v);
  }

  public attached(container: HTMLElement): void {
    super.attached(container);
    this._$container.on('click tap', this._onChartClick);
  }

  protected _dispose(): void {
    try {
      this._$container.off('click tap', this._onChartClick);
      this._units = [];
    } catch (err) {
      console.error(err);
    }
    super._dispose();
  }

  protected _getVAxisByUnit(unit: IUnit): HighchartsAxisObject {
    if (!this._chart) {
      throw Error('_getVAxisByUnit: no _chart');
    }
    const vAxis: IVAxis = this._vm.vAxes.find((vAxis: IVAxis) => vAxis.unit === unit);

    if (vAxis) {
      // let values = this._vm.series.find((s) => s.index === vAxis.index)?.values || [];

      let values: number[][] = this._vm.series.filter(serie => serie?.e?.unit == vAxis.unit).map((s) => {
        return s.numValues;
      });

      // @ts-ignore
      let allValues = (values && Array.isArray(values)) ? values.flat() : [];

      const min = allValues.length ? Math.min.apply(null, allValues) : null;
      const max = allValues.length ? Math.max.apply(null, allValues) : null;

      vAxis.dataMin = min;
      vAxis.dataMax = max;
    }

    if (!vAxis) {
      console.warn('Unknown unit in Eplot::scale: ', unit, ' known:', this._units);
      throw Error('Unknown unit in Eplot::scale: ' + unit);
    }
    // return this._chart.get(vAxis.id) as any;
    return vAxis as any;
  }


  // will return recommended size for plot markers
  // 0: do not display marker
  protected _getMarkerSize(y: IEntity, vm: IVizelXYVM): number {
    const li: tables.ILegendItem = this._cfg.getLegendItem(y);

    if (li && ('markerSize' in li)) {
      return li.markerSize;
    }

    const chartType: string = this._getEchartsChartType(y);

    if (chartType === 'area') {
      return 0;
    }

    if (this._cfg.hasOption('LinesWithoutDots') && chartType !== 'scatter') {
      return 0;
    }

    // count according to length opf categories
    const xsSize: number = vm.subspace?.xs?.length;
    let markerSize: number;

    if (0 <= xsSize && xsSize < 100) {
      markerSize = 6;
    } else if (100 <= xsSize && xsSize < 250) {
      markerSize = 5;
    } else if (250 <= xsSize && xsSize < 400) {
      markerSize = 4;
    } else {
      markerSize = 3;
    }
    return markerSize;
  }

  protected _updateChart(vm: IVizelXYVM, prevVM: IVizelXYVM): void {
    console.assert(vm !== null);
    console.assert(prevVM !== null);
    console.assert(this._chart !== null);
    this._vm = vm;

    let needRefresh: boolean = false;
    let options = this._getEchartsConfig(vm);
    this._chartConfig = options;
    this._chart.clear();
    if (vm.noData) {
      this._chart.setOption({
        ...options,
        xAxis: {
          ...options.xAxis,
          show: false
        },
        yAxis: options.yAxis.map(axis => {
          return {
            ...axis,
            show: false,
          };
        }),
      }, {replaceMerge: ['xAxis', 'yAxis']});
    } else {
      this._chart.setOption(options);
    }
    if (this._chart) {
      if (needRefresh) {
        this._chart.resize();
      }
     // this._notifyDataRangeChanged();
    }
    if (!vm.noData) {
      // in some cases redraw is not enough due to some highcharts async issues
      this.resize();
    }
  }

  public setAxes = (subspace: ISubspace): Promise<any> => {
    return super.setAxes(subspace.reduce(Infinity, this._MAX_YS, 1));
  }

  public _setVizelType(value: string, doRedraw: boolean): void {
    this._cfg.setVizelType(value);
    if (!this._chart) return;

    const vm = this._vm;

    if (!vm) return;


    const options = this._chart.getOption();

    const series = options?.series || [];

    const serieType: string = this._getEchartsChartType(null);
    const stacking: boolean = this._isStacked(null);
    const vizelType: string = this._getYVizelType(null);

    const newSeries = [];

    vm.subspace?.ys?.forEach((y: IEntity, i: number) => {
      let legendItem: tables.ILegendItem = this._cfg.getLegendItem(y, i);
      if (legendItem && (legendItem.vizelType || legendItem.widgetType)) {
        // ignore series which have vizelType configured in legendItem
        return;
      }
      if (this._chart && series.length) {
        const markerSize: number = this._getMarkerSize(y, vm);
        const isMarkerEnabled: boolean = (markerSize !== 0);
        const serie: any = series[i];

        let color: any = this._cfg.getColor(y) || this._colorResolver.getColor(y);
        let bgColor: any = this._cfg.getBgColor(y) || this._colorResolver.getBgColor(y);
        const gradientType: string = this._getGradientType(y);
        if (gradientType) {
          [color, bgColor] = [coloring.makeEchartsGradient(gradientType, color), coloring.makeEchartsGradient(gradientType, color)];
        }

        const configSerie: any = {
          type: serieType,
          symbol: isMarkerEnabled ? 'circle' : 'none',
          symbolSize: markerSize,
          stack: stacking ? 'normal' : null,
          color,
          lineStyle: {
            color: bgColor,
          },
        };

        const li: tables.ILegendItem = this._cfg.getLegendItem(y, i);
        if (serieType === 'scatter') configSerie.lineStyle.width = 0;
        else configSerie.lineStyle.width = li.lineWidth !== undefined ? li.lineWidth : 2;
        newSeries.push(configSerie);
      }
    });
    options.series = newSeries;

    this._chart.setOption(options);
    this._chart.resize();
    // this._applyVAxisRange();

    if (doRedraw && this._chart) this._chart.resize();
    this._updateChart(this._vm, this._vm);
    this.recalculateChart(this._chart);
    window.setTimeout(() => this._notifyDataRangeChanged(), 0);    // may be stacked changed and bottom border is above zero
  }


  protected _notifyDataRangeChanged(): void {
    if (!this._chart || !this._vm.subspace) return;
    const dataRange: IVizelPropertiesDataRange = {};
    this._vm.vAxes.forEach((vAxis: IVAxis) => {
      const unit: IUnit = vAxis.unit;
      const unitId: number = unit ? unit.id : null;
      this._cfg.getDataset().getDataProvider().getRawData(this._vm.subspace).then(data => {
        let dataMin = Math.min.apply(null, data.map(el => el.val));
        let dataMax = Math.max.apply(null, data.map(el => el.val));
        if (this._getConfigDisplay().hasRange()) [dataMin, dataMax] = this._getConfigDisplay().getRange();
        else if (this._isVAxisZeroBased(unit) && dataMin >= 0) dataMin = 0;

        dataRange[unitId] = [dataMin, dataMax];
        // эти три строчки бесполезны, но без
        // них отображения графика не меняется
        // если переключать с Точки на Линии
        let options = this._chartConfig;
        this._chart?.setOption(options);
        this._chart?.resize();
        this._properties.dataRange = dataRange;
        this._notifyPropertiesChanged();
        this.recalculateChart(this._chart);
      });
    });
  }

  // функция выставляет min and max для оси Y когда нажимаем
  // на кнопку "Общий масштаб"
  protected _applyVAxisRange(redraw: boolean = false): void {
    if (!this._chart) return;
    this._vm.vAxes.forEach((vAxis: IVAxis) => {
      const r: IRange = this._getRangeForUnit(vAxis.unit);
      const options = this._chart.getOption();
      if (options.yAxis.length) {
        options.yAxis[0].min = r[0];
        options.yAxis[0].max = r[1];
        this._chart.setOption(options, {replaceMerge: 'yAxis'});
      }
    });
    if (redraw && this._chart) {
      this._chart.resize();
      this._updateChart(this._vm, this._vm);
    }
  }


  protected _setVAxisRange(range: IVizelPropertiesDataRange): void {
    if (this._getConfigDisplay().hasRange()) return;
    this._range = range;
    this._applyVAxisRange();
  }


  public _setProperty(key: string, value: any): boolean {
    this._cfg[key] = value;        // TODO: migrate to methods and remove
    if (!this._chart) return false;

    switch (key) {
      case 'showLegend':
        const options = this._chart.getOption();
        const val = this._cfg['showLegend'];
        this._cfg['showLegend'] = value;

        options.legend.forEach(legend => {
          legend.show = value;
        });

        if (!value) {
          options.grid.forEach(grid => {
            grid.bottom = '8%';
            grid.height = '92%';
          });
        }

        const dataZoom = options['dataZoom'];
        if (dataZoom) {

          dataZoom.map((dz: any) => {
            if (dz.type === 'slider' && dz.xAxisIndex !== undefined) {
              dz['bottom'] = (!this._cfg['showLegend']) ? 15 : (this._cfg.getOption('DisplayAxisXMarks', true)) ? 65 : 15;
            } else if (dz.type === 'slider' && dz.yAxisIndex !== undefined) {
              dz['bottom'] = (!!this._cfg['showLegend']) ? 78 : 5;
            }
            return dz;
          });

          options['dataZoom'] = dataZoom;
        }

        this._chart.setOption(options, {replaceMerge: ['grid', 'legend', 'dataZoom']});
        this._chart.resize();

        this.recalculateChart(this._chart);
        return true;

      case 'vizelType':
        let redraw = (value == 'column' || value == 'stacked-column');
        this._setVizelType(value, redraw);
        return true;

      case 'range':    // shoud be autoscaleRange
        this._setVAxisRange(value);
        return true;

      case 'VAxisZeroBased':
        this._applyVAxisRange(true);      // redraw
        return false;

      case 'filterBy':
        this._vc.setUserFilterBy(value);
        return false;
    }
    return false;
  }

  // @override
  protected _hasData(vm: IVizelXYVM): boolean {
    return (vm !== null) && !vm.noNumericData;
  }

  public calculateDimensionsForLegend = (graph, side = 'bottom') => {
    const chartWidth = graph.getWidth();
    const chartHeight = graph.getHeight();
    const options = graph.getOption();

    const legend = options.legend[0] || {};
    const itemGap = legend.itemGap;

    const itemWidth = legend.itemWidth;
    const itemHeight = legend.itemHeight;
    const padding = legend.padding;
    let totalRowHeight = itemHeight;

    switch (side) {
      case 'bottom':
        if (legend?.data?.length) {
          let rowCount = 0;
          let currentWidth = 0;
          legend.data.map(el => {
            let worthyWidths = [getTextWidth(el.name, {
              fontFamily: legend.itemStyle.fontFamily,
              fontSize: legend.itemStyle.fontSize
            }), itemGap, itemWidth];
            let totalWidthNeeded = worthyWidths.reduce((item, cur) => item + cur, 0);
            worthyWidths.map(width => {
              currentWidth += width;
            });
            if (currentWidth >= chartWidth || (chartWidth - currentWidth < totalWidthNeeded)) {
              currentWidth = 0;
              rowCount++;
            }
          });
          totalRowHeight = rowCount * itemHeight + (rowCount - 1) * itemGap + padding * 4;
        }
        break;
    }

    return totalRowHeight;
  }

  protected recalculateChart(chart: any): void {
    const legendTotalHeight = this.calculateDimensionsForLegend(chart, 'bottom');
    const additional: any = (this._cfg.getRaw() as any).echart || {};
    const height = chart.getHeight();

    const isShowLegend = this._cfg['showLegend'];
    const isDisplayDataZoom = this._cfg.getOption('DisplayDataZoom', false);
    const isScroll = height / legendTotalHeight < 4.5;

    let config = this._chartConfig;
    let bottomOffset = isScroll ? 25 : legendTotalHeight;

    if (!isShowLegend) bottomOffset = 5;

    const dataZoom = [
      {
        type: 'slider',
        xAxisIndex: 0,
        bottom: bottomOffset + 15,
        ...(additional.dataZoom || {})
      },
      {
        xAxisIndex: 0,
        type: 'inside',
      },
    ];

    if (!!config) {
      config = {
        ...config,
        grid: {
          ...config.grid,
          id: 's-grid',
          bottom: bottomOffset + 15,
          top: '4%',
        },
        legend: {
            ...config.legend,
            show: isShowLegend,
            type: isScroll ? 'scroll' : 'plain',
          },
        dataZoom: isDisplayDataZoom ? dataZoom : undefined,
      };

      this._chartConfig = config;

      this._chart.setOption({
          grid: config.grid,
          dataZoom: config.dataZoom,
          legend: config.legend,
        },
        {replaceMerge: ['grid', 'dataZoom', 'legend']});
      this._chart.resize();
    }
  }

  public render() {
    return (
      <div ref="container"
           onClick={e => this._onClickChart(e)}
           className="VizelEPlot Vizel view vizel eplot"
           style={{width: '100%', height: '100%'}}
      />);
  }
}

export default EPlot;
