exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.AccountList{list-style:none;padding:.5rem;border-radius:.5rem;margin:0;box-shadow:var(--shadow_dash);background:var(--color2);color:var(--color1)}.AccountList__Item{margin:0;cursor:pointer;padding:.5rem;font-size:.75rem}.AccountList__Item:hover{box-shadow:var(--shadow_button)}.AccountList .Active{font-weight:bold}", ""]);

// exports
exports.locals = {
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};