import { BaseService, extractErrorMessage, IBaseModel } from '@luxms/bi-core';

interface IDep {
  ServiceClass: any;
  subscription: string | string[];
  args: any[];
  instance: any;
  onUpdate: any;
}

function createDep(ServiceClass: any, subscription: string | string[], args: any[], onUpdate: any): IDep {
  let instance: any;
  if (ServiceClass.getInstance && !args.length) {
    instance = ServiceClass.getInstance();
  } else if (ServiceClass.createInstance) {
    instance = ServiceClass.createInstance(...args);
  } else {
    instance = new ServiceClass(...args);
  }
  if (subscription) {
    instance.subscribe(subscription, onUpdate);
  } else {
    instance.subscribeUpdates(onUpdate);
  }
  return {ServiceClass, subscription, args, instance, onUpdate};
}

function destroyDep(dep: IDep) {
  dep.instance.unsubscribe(dep.onUpdate);
  dep.onUpdate = null;

  if (dep.ServiceClass.getInstance && !dep.args.length) {
    // синглтоны не надо релизить
  } else {
    dep.instance.release();
  }
  dep.instance = null;
}


function createService<MODEL extends IBaseModel>(name: string, callback: (dep: any, ...args: any[]) => any): typeof BaseService {

  class Service extends BaseService<MODEL> {
    public static MODEL: MODEL;
    private _args: any[];
    private _deps: IDep[] = [];

    public constructor(...args) {
      super({error: null, loading: true} as MODEL);
      this._args = args.slice(0);
      try {
        const model = this._loadModel();
        this._setModel(model);
      } catch (err) {
        this._updateWithError(extractErrorMessage(err));
      }
    }

    private _findDep = (ServiceClass: any, subscription: string | string[], args: any[]): IDep =>
        this._deps.find(d =>
            d.ServiceClass === ServiceClass &&
            String(d.subscription) === String(subscription) &&
            JSON.stringify(args) === JSON.stringify(d.args))

    protected _loadModel(): MODEL {
      const usedDeps: IDep[] = [];

      const useServiceItselfWithCustomSubscription = (serviceClass: any, subscription: string | string[], ...args: any) => {
        const dep = this._findDep(serviceClass, subscription, args) || createDep(serviceClass, subscription, args, this._onDepUpdated);

        if (!this._deps.includes(dep)) this._deps.push(dep);
        if (!usedDeps.includes(dep)) usedDeps.push(dep);

        return dep.instance;
      };
      const useServiceWithCustomSubscription = (serviceClass: any, subscription: string, ...args: any) => useServiceItselfWithCustomSubscription(serviceClass, subscription, ...args).getModel();
      const useServiceItself = (serviceClass: any, ...args: any) => useServiceItselfWithCustomSubscription(serviceClass, '', ...args);
      const useService = (serviceClass: any, ...args: any) => useServiceItself(serviceClass, ...args).getModel();
      useService.useService = useService;
      useService.useServiceItself = useServiceItself;
      useService.useServiceWithCustomSubscription = useServiceWithCustomSubscription;
      useService.useServiceItselfWithCustomSubscription = useServiceItselfWithCustomSubscription;

      let model;

      model = callback.call(this, useService, ...this._args);

      const unusedDeps = this._deps.filter(d => !usedDeps.includes(d));
      this._deps = usedDeps;
      unusedDeps.forEach(destroyDep);

      return model;
    }

    private _onDepUpdated = () => {
      // Тут бы собрать все обновления и один раз вызваться
      try {
        const model = this._loadModel();
        if (model === null || model === undefined) {
          // Ничего не делаем
        } else if (Array.isArray(model)) {
          this._setModel(model);
        } else {                                              //  по-хорошему тут надо проверить, если наша model массив, то аккуратно его скопировать
          this._updateModel(model);                           //  и обновить пришедшие свойства
        }
      } catch (err) {
        this._updateWithError(extractErrorMessage(err));
      }
    }

    protected _dispose() {
      this._deps.forEach(destroyDep);
      this._deps = [];
      super._dispose();
    }
  }

  if (name) {
    Object.defineProperty(Service, 'name', {value: name, writable: false});
    // Добавить статические getInstance / createInstance
  }

  return Service;
}

export default createService;
