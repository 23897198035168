import * as React from 'react';
import {IColorResolver, ILocation, IMetric, IRange} from "../../../defs/bi";
import Tick from "./Tick";
import {__buildTicks, _buildLegendSubtitle, TOTAL_LR_MARGIN, TOTAL_WIDTH} from "./MapFillLayerDialogR";

export interface IContinuousLegendRProps {
  ls: ILocation[];
  vec: number[];
  colorResolver: IColorResolver;
  title: string;
  metric: IMetric;
}

class ContinuousLegendR extends React.Component<IContinuousLegendRProps> {

  public state: {
    startColor: string;
    endColor: string;
    ticks: Tick[];
    title: string;
    subtitle: string;
    arrow: Tick | null;
    range: IRange,
  } = {
    startColor: 'transparent',
    endColor: 'transparent',
    ticks: [],
    title: '',
    subtitle: '',
    arrow: null,
    range: [-Infinity, Infinity],
  };

  private _ls: ILocation[];
  private _vec: number[];
  private _mounted: boolean = false;

  public constructor(props: IContinuousLegendRProps) {
    super(props);
    this._init(props);
  }

  componentDidMount() {
    this._mounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<IContinuousLegendRProps>, nextContext: any) {
    if (this.props !== nextProps) {
      this._init(nextProps);
    }
  }

  private _init(props: IContinuousLegendRProps): any {
    const {ls, vec, colorResolver, title, metric} = props;
    this._ls = ls;
    this._vec = vec;
    // const width = TOTAL_WIDTH;
    const subtitle = _buildLegendSubtitle(metric);

    let pairs = ls
      .map((l: ILocation, idx: number): [ILocation, number] => [l, vec[idx]])
      .filter((x: [ILocation, number]) => x[1] != null)
      .sort((a: [ILocation, number], b: [ILocation, number]) => a[1] - b[1]);

    this._ls = pairs.map((x: [ILocation, number]) => x[0]);
    this._vec = pairs.map((x: [ILocation, number]) => x[1]);

    if (ls.length === 0) {
      // this.hide();
      return;
    }

    const min: number = this._vec[0];
    const max: number = this._vec[this._vec.length - 1];
    const range: IRange = [min, max];

    const ticks: Tick[] = __buildTicks(
      range,
      TOTAL_WIDTH - 2 * TOTAL_LR_MARGIN,    // width
      -TOTAL_LR_MARGIN,                     // minx
      TOTAL_WIDTH - TOTAL_LR_MARGIN);       // maxx

    const startColor = colorResolver.getColor(metric, min);
    const endColor = colorResolver.getColor(metric, max);

    // let l = [];
    // let a: string[] = (min !== max) ? s(min, max, 5) : [String(min), String(max)];
    // for (let i = 0; i < a.length - 1; i++) {
    //   let start: string = a[i];
    //   let end: string = a[i + 1];
    //   let middle = (parseFloat(start) + parseFloat(end)) / 2;
    //   l.push({start: start, end: end, color: colorResolver.getColor(metric, middle)});
    // }
    //
    // this.items(l);

    if (this._mounted) this.setState({ticks, title, subtitle, range, startColor, endColor, arrow: null});
    else this.state = {ticks, title, subtitle, range, startColor, endColor, arrow: null};
  }

  public hiliteLocation(l: ILocation): void {
    const idx: number = this._ls.indexOf(l);
    if (idx !== -1) {
      const v: number = this._vec[idx];
      this.setState({arrow: new Tick(v, this._range)});
    } else {
      const currentTick = this.state.arrow;
      window.setTimeout(() => {
        if (this.state.arrow === currentTick) {
          this.setState({arrow: null});
        }
      }, 333);
    }
  }

  public render() {
    const {ticks, title, startColor, endColor, arrow} = this.state;

    return (
      <article className="ContinuousLegend legend">
        <div className="ContinuousLegend__Title">{title}</div>

        <div className="ContinuousLegend__Body"
             style={{background: `linear-gradient(to right, ${startColor}, ${endColor})`}}>

          {ticks.map(tick =>
            <React.Fragment key={tick.text}>
              <div className="ContinuousLegend__Tick"
                   style={{ left: `${tick.tickLeft}%`, background: tick.tickColor }}>
              </div>
              { tick.isVisible &&
              <div className="ContinuousLegend__TickTitle"
                   style={{left: tick.textLeft, color: tick.textColor }}
                   data-text-width={tick.textWidth}>
                {tick.text}
              </div>}
            </React.Fragment>)}

          {!!arrow &&
          <div className="ContinuousLegend__Arrow"
               style={{left: arrow.tickLeft + '%'}}>
            <svg width="100%" height="100%" enableBackground="new 0 0 100 100" version="1.1" viewBox="0 0 100 100">
              <polygon stroke="black" fill="black" points="73.4,50.4 75,51.9 48.8,78 22.6,51.9 24.2,50.4 47.7,73.9 47.7,20.2 49.9,20.2 49.9,73.9 "/>
            </svg>
          </div>}
        </div>
      </article>);
  }
}

export default ContinuousLegendR;