import { CachedMLPCube } from './CachedMLPCube';
import { createNullMatrix, createNullVector, isNullMatrix, matrixHasNumericData } from './data-utils';
import { data_engine, IDataMatrix, IDataMatrixProvider, INormsResponse } from './data-manip';
import { IEntity, ILocation, IMetric, IPeriod, ISubspace, IValue } from '../defs/bi';


export class CachedDataMatrixProvider implements IDataMatrix, IDataMatrixProvider {
  public z: IEntity = null;
  public ys: IEntity[] = [];
  public xs: IEntity[] = [];
  public normsResponses: INormsResponse[] = null;
  public matrix: IValue[][] = null;
  private _dp: CachedMLPCube;

  public constructor(dp: data_engine.IDataProvider, isNoCache = 0) {
    this._dp = new CachedMLPCube(dp, isNoCache);
  }

  public rtValueUpdated(m: IMetric, l: ILocation, p: IPeriod, v: number): void {
    this._dp.rtValueUpdated(m, l, p, v);
  }

  // dataMatrixProvider
  public async setAxes(subspace: ISubspace, closest?: boolean): Promise<IDataMatrix> {
    console.assert(subspace.zs.length <= 1);
    const z: IEntity = subspace.getZ(0) || null;
    const ys: IEntity[] = subspace.ys;
    const xs: IEntity[] = subspace.xs;

    // TODO: load only necassary data
    this.z = z;
    this.ys = ys;
    this.xs = xs;
    this.matrix = this.z ? createNullMatrix(this.ys.length, this.xs.length) : null;
    this.normsResponses = null;

    if (subspace.isEmpty()) return this;

    // TODO: search formulaes
    this.matrix = await this._dp.getMatrixYX(subspace, closest);

    if (subspace.splitByY) {
      this.normsResponses = await Promise.all(subspace.splitByY().map((ySubspace: ISubspace) => this._dp.getNorms(ySubspace)));
    }

    return this;
  }

  private _getYIndex(y: number | IEntity): number {
    const idx: number = (typeof y === 'number') ? y : this.ys.indexOf(y);
    console.assert(0 <= idx && idx < this.ys.length);
    return idx;
  }

  private _getXIndex(x: number | IEntity): number {
    const idx: number = (typeof x === 'number') ? x : this.xs.indexOf(x);
    console.assert(0 <= idx && idx < this.xs.length);
    return idx;
  }

  public getVectorX(y: number | IEntity): IValue[] {
    if (!this.matrix) {  // z is null?
      return createNullVector(this.xs.length);
    }
    const idx: number = this._getYIndex(y);
    return this.matrix[idx];
  }

  public getVectorY(x: number | IEntity): IValue[] {
    if (!this.matrix) {  // z is null?
      return createNullVector(this.ys.length);
    }
    const idx: number = this._getXIndex(x);
    return this.matrix.map((vec: IValue[]) => vec[idx]);
  }

  public getZ(): IEntity {
    return this.z;
  }

  public getY(idx: number): IEntity {
    return this.ys[idx];
  }

  public getX(idx: number): IEntity {
    return this.xs[idx];
  }

  public getNormsResponse(y: number | IEntity): any {
    const idx: number = this._getYIndex(y);
    return this.normsResponses ? this.normsResponses[idx] : null;
  }

  public hasData(): boolean {
    // TODO: add option to check at least text-data exists
    return this.matrix !== null && !isNullMatrix(this.matrix);
  }

  public hasNumericData(): boolean {
    // TODO: add option to check at least text-data exists
    return this.matrix !== null && matrixHasNumericData(this.matrix);
  }
}

