import { BaseEntitiesService, IBaseEntities, createSingleton } from '@luxms/bi-core';
import { EnquiriesRepository, IEnquiry } from '../repositories/EnquiriesRepository';


export class EnquiriesService extends BaseEntitiesService<IEnquiry> {
  public static readonly MODEL: IBaseEntities<IEnquiry>;                                            // used to reference type of model

  protected constructor() {
    super(new EnquiriesRepository());
  }

  public static getInstance: () => EnquiriesService = createSingleton<EnquiriesService>(() => new EnquiriesService(), '__enquiriesService');
}
