import React from 'react';
import './ExcelCatch.scss';

import {extractErrorMessage, srv} from '@luxms/bi-core';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import MitoDataService, {IMitoDocumentModel, IMitoSheetModel} from '../Mito/MitoDataService';
import {MitoExcel} from '../Mito/MitoExcel';
import TextEditor from '../TextEditor';
import {IMitoAction, MitoPanel} from '../data_sources/CreateExcelDataSource';
import {idifyMany, quotifySql, XLSX_MIME_TYPE} from '../../../utils/utils';
import {DataSourceInspectorService} from '../../../services/QRPCService';
import {AlertsVC} from '../../../view-controllers/AlertsVC';
import db_relations from '../DbRelations/services';
import {TablesService} from './CreateKoobStep1';
import {formSqlExpression} from '../DbRelations/DbRelationsUtils';


interface IExcelCatchProps {
  readonly onChange: () => void;
  readonly schemaName: string;
}

interface IExcelCatchState {
  readonly loading: boolean;
  readonly error: string;
  readonly loadingCreate: boolean;
  //
  readonly document: IMitoDocumentModel;
  readonly sheets: IMitoSheetModel[];
  readonly sourceId: string;
  //
  readonly activeSheet: string;
  readonly selectedCols: string[] | null;
  readonly selectedRows: number[] | null;
  //
  readonly inputName: string;
  readonly enterExcel: boolean;
  readonly saveRenameColumns: string[]; // сохраняю title
}

class ExcelCatch extends React.Component<IExcelCatchProps, IExcelCatchState> {
  private readonly _mitoDataService: MitoDataService;
  private readonly _dataSourcesService: srv.adm.DataSourcesService;

  public readonly state: IExcelCatchState;

  public constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingCreate: false,
      error: null,
      //
      document: null,
      sheets: [],
      sourceId: null,
      //
      selectedCols: null,
      selectedRows: null,
      activeSheet: null,
      inputName: '',
      enterExcel: false,
      saveRenameColumns: [],
    };
    this._dataSourcesService = srv.adm.DataSourcesService.getInstance();
    this._mitoDataService = new MitoDataService();
  }

  public componentDidMount() {
    this._mitoDataService.subscribeUpdatesAndNotify(this._onServiceUpdate);
    this._dataSourcesService.subscribeUpdatesAndNotify(this._onServiceUpdate);
  }

  public componentWillUnmount() {
    this._mitoDataService.unsubscribe(this._onServiceUpdate);
    this._dataSourcesService.unsubscribe(this._onServiceUpdate);
  }

  private _onServiceUpdate = (): void => {
    const mitoModel = this._mitoDataService.getModel();
    const sourceModel = this._dataSourcesService.getModel();
    if (mitoModel.loading || sourceModel.loading) return this.setState({loading: true});
    if (sourceModel.error || sourceModel.error) return this.setState({error: null});

    const document = mitoModel.documents[0];
    const sheets = mitoModel.sheets.filter(sheet => (document.id === sheet.documentId));
    const activeSheet = sheets?.[0]?.title || null;
    this.setState({
      loading: false,
      sourceId: mitoModel.sourceId,
      document,
      sheets,
      activeSheet,
      inputName: document?.fileName ?? '',
    });
  }

  private _onAction = (action: IMitoAction): void => {
    const {activeSheet, sheets} = this.state;
    const sheet = sheets.find(s => (s.title === activeSheet));
    const code = sheet.code;
    if (!sheet && !code) return;

    const type = action.type;
    switch (type) {
      case 'undo':
        const prg = code.split('\n');
        if (prg.length > 2) this._updateCurrentCode(prg.slice(0, -1).join('\n'));
        break;
      case 'dropColumns':
        this._updateCurrentCode(null, `dropColumns(${action.columns.map(s => `"${s}"`).join(', ')})`);
        break;
      case 'dropRows':
        this._updateCurrentCode(null, `dropRows(${action.rows.join(', ')})`);
        break;
      case 'renameColumn':
        this._updateCurrentCode(null, `renameColumn("${action.columnName}", "${action.newColumnName}")`);
        break;
      case 'cast':
        if (action.columnType === 'DATE') {
          this._updateCurrentCode(null, `castWithExpr("${action.columnName}", "${action.columnType}", to_date("${action.exp}"))`);
        } else if (action.columnType === 'DATETIME') {
          this._updateCurrentCode(null, `castWithExpr("${action.columnName}", "${action.columnType}", to_datetime("${action.exp}"))`);
        } else this._updateCurrentCode(null, `cast("${action.columnName}", "${action.columnType}")`);

        break;
      case 'rowToHeader':
        const idxRow = sheet.data.rowNumbers.indexOf(action.rows[0]);
        const rowsToDrop = sheet.data.rowNumbers.slice(0, idxRow + 1);
        const oldNames: string[] = sheet.data.columns.map(col => col.name);
        const saveNames = sheet.data.columns.map((col, idx) => sheet.data.rows[action.rows[0]]?.[idx]);
        const newNames = idifyMany(saveNames);
        const dimension = this._getDimensionTitles();
        const cod = [
          `dropRows(${rowsToDrop.map(String).join(', ')})`,
          ...oldNames.map((colName, idx) => `renameColumn("${colName}", "${newNames[idx]}")`),
        ];
        this._updateCurrentCode(null, 'begin(' + cod.join(', ') + ')');
        this.setState({saveRenameColumns: saveNames});
        break;
    }

    this.setState({selectedCols: null, selectedRows: null});
  }

  private _updateCurrentCode(replace: string = null, append: string = null): void {
    const {document, activeSheet, sheets} = this.state;
    const sheet = sheets.find(s => (s.title === activeSheet));
    let code = sheet?.code;
    if (!code) return;

    if (replace !== null) code = replace;
    if (append !== null) code = [...code.split('\n'), append].join('\n');

    this._mitoDataService.changeCode(document.id, activeSheet, code);
  }

  private _activeSheet = (sheetName: string): void => this.setState({activeSheet: sheetName});
  private _setSelectCells = (selectedCols: string[] | null, selectedRows: number[] | null): void => {
    this.setState({selectedCols, selectedRows});
  }

  private _onTextEditorCodeChange = debounce((v) => this._updateCurrentCode(v, null), 1000);
  private _onActiveSheetCodeChange = (s): void => {
    this._onTextEditorCodeChange.cancel();
    this._onTextEditorCodeChange(s);
  }

  // create datasource & cube & dimensions
  private _createAll = async (): Promise<void> => {
    const newSourceId = await this._createDataSource();
    const sql = await this._getSqlDataSource(newSourceId);
    await this._createKoobAndDimension(newSourceId, sql);
  }
  private _createDataSource = async (): Promise<string> => {
    const {document, activeSheet, sheets, sourceId, inputName} = this.state;
    const selectSheet = sheets.find(s => s?.title === activeSheet);

    try {
      const lines = selectSheet.code.split('/n');
      const query = [].concat(lines);
      const newSourceId = idifyMany([inputName])[0];

      const isUniqId = this._dataSourcesService.getModel().find(s => s.ident === sourceId);
      if (isUniqId) {
        AlertsVC.getInstance().pushDangerAlert(`ID [${inputName}] exists. Choose another one`);
        return null;
      }

      await DataSourceInspectorService.createDataSource(sourceId, query.join('\n'), newSourceId, document.title);
      AlertsVC.getInstance().pushSuccessAlert(`Источник ${newSourceId} создан`);
      return newSourceId;
    } catch (error) {
      AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
      return null;
    }
  }
  private _getSqlDataSource = async (sourceId): Promise<string> => {
    if (!sourceId) return null;
    const {document, activeSheet} = this.state;
    try {
      const tablesSrv = new TablesService(sourceId, document.id);
      const tableModel = await tablesSrv.whenReady();
      const table = (tableModel?.tables || []).find(t => t.name === activeSheet);
      const id = table.facets.sourceId;
      const schema = table.facets.schema;
      const name = table.facets.elementName;
      const tableInfoService = new db_relations.TableInfoService(id, schema, name);
      await tableInfoService.whenReady();
      return formSqlExpression([tableInfoService.getModel()], []);
    } catch (error) {
      AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
      return null;
    }
  }
  private _createKoobAndDimension = async (sourceId: string, sql: string): Promise<void> => {
    if (!sourceId || !sql) return null;
    const {inputName, sheets, activeSheet} = this.state;
    const selectSheet = sheets.find(s => s?.title === activeSheet);
    try {
      const cubeService = srv.koob.CubesService.getInstance();
      await cubeService.whenReady();

      const isUniqId = cubeService.getModel().find(s => s.name === sourceId);
      if (isUniqId) {
        AlertsVC.getInstance().pushDangerAlert(`ID [${inputName}] exists. Choose another one`);
        return null;
      }

      const cube = await cubeService.create({
        name: sourceId,
        title: inputName,
        source_ident: sourceId,
        sql_query: sql
      });

      const dimensionsService = srv.koob.DimensionsService.createInstance(sourceId, cube.name);
      await dimensionsService.whenReady();
      const titles = this._getDimensionTitles();
      const dimension = (selectSheet.data.columns || []).map((c, idx) => ({
        source_ident: sourceId,
        cube_name: cube.name,
        name: c.name,
        type: getSimpleType(c.explicitType),
        title: titles?.[idx] ?? c.title,
        sql_query: quotifySql(c.name),
        config: {
          possible_aggregations: getSimplePossibleAggregations(getSimpleType(c.explicitType))
        }
      }));
      dimension.forEach((d) => dimensionsService.create(d));
      await dimensionsService.whenReady();
      AlertsVC.getInstance().pushSuccessAlert(`Cube ${inputName} создан`);
      this.props.onChange();
    } catch (error) {
      AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
    }
  }
  private _getDimensionTitles = () => {
    const {sheets, activeSheet, saveRenameColumns} = this.state;
    const selectSheet = sheets.find(s => s?.title === activeSheet);
    const codArray = selectSheet.code.split('\n');
    if (codArray.some(s => s.startsWith('renameColumn'))) return [];
    return saveRenameColumns;
  }

  // D&D
  private _onDrop = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    const {loading, document} = this.state;
    if (loading || document) return;
    this._mitoDataService.addDocuments(Array.from(event.dataTransfer.files));
  }
  private _onDragOver = (e): void => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.items[0].type !== XLSX_MIME_TYPE) return;
    this.setState({enterExcel: true});
  }
  private _onDragLeave = (e): void => {
    e.preventDefault();
    if (e.currentTarget.contains(e.relatedTarget)) return;
    this.setState({enterExcel: false});
  }


  public render() {
    const {document, selectedRows, selectedCols, sheets, activeSheet, inputName, enterExcel} = this.state;
    const {loading, error} = this.state;
    const selectSheet = sheets.find(s => s?.title === activeSheet);
    return (
      <div className="ExcelCatch__Wrapper">
        <div className={cn('ExcelCatch', {create: !!document, enter: enterExcel})}
             onDrop={this._onDrop}
             onDragOver={this._onDragOver}
             onDragLeave={this._onDragLeave}>
          {loading && <p>Идет загрузка!</p>}
          {!loading && !document && <p>Пожалуйста, наложите в меня excel</p>}
          {!!document &&
          <div className="ExcelCatch__CreateKoob">
            <input type="text" value={inputName} onChange={e => this.setState({inputName: e.target.value})}/>
            <button disabled={!!!inputName} onClick={this._createAll}> создать</button>
          </div>
          }

          {!!document &&
          <div className="MitoExcelViews">
            <div className="MitoExcelViews__Wrapper">
              <MitoPanel selectedCols={selectedCols} selectedRows={selectedRows} onAction={this._onAction}
                         code={selectSheet?.code ?? ''}/>
              <MitoExcel error={error}
                         loading={false}
                         sheets={sheets}
                         sheetActive={activeSheet}
                         activeSheet={this._activeSheet}
                         selectedRows={selectedRows}
                         selectedCols={selectedCols}
                         onSelectCells={this._setSelectCells}
                         onAction={this._onAction}
              />

              <div className="MitoExcelViews__CodeEdit">
                <TextEditor content={selectSheet?.code ?? ''} contentType="text"
                            onChange={this._onActiveSheetCodeChange}/>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default ExcelCatch;


function getSimpleType(type: string): 'PERIOD' | 'SUM' | 'STRING' {
  switch (type) {
    case 'DATETIME' :
      return 'PERIOD';
    case 'TIME':
      return 'PERIOD';
    case 'DATE':
      return 'PERIOD';
    case 'TIMESTAMPTZ':
      return 'PERIOD';
    case 'INT':
      return 'SUM';
    case  'DOUBLE':
      return 'SUM';
    default :
      return 'STRING';
  }
}

function getSimplePossibleAggregations(type: string): string[] {
  switch (type) {
    case 'SUM':
      return ['count', 'avg', 'sum', 'min', 'max'];
    default:
      return [];
  }
}