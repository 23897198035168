/// <reference path="../../hc.d.ts" />

/**
 *
 * VizelXYVC
 *
 * ViewController for vizel displays 2 axes
 */

import range from 'lodash/range';
import uniq from 'lodash/uniq';
import { BaseVizelVC } from './BaseVizelVC';
import { CachedDataMatrixProvider } from '../../data-manip/CachedDataMatrixProvider';
import { createDataMatrixWithOrder } from '../../data-manip/DataMatrix';
import { OptionsProvider } from '../../config/OptionsProvider';
import { Stoplights } from '../../config/Stoplights';
import formatNumberWithString from '@luxms/format-number-with-string';
import { $eid, $eidx, getEntity, getEntityIdx } from '../../libs/imdas/list';
import { FIND_M, formatNum, IS_M, IS_PS, lang, makeColor } from '../../utils/utils';
import { createConstVector, createNullVector, vectorSum, vectorSumNull } from '../../data-manip/data-utils';
import { data_engine, IDataMatrix, INormsResponse, INormZone } from '../../data-manip/data-manip';
import { IDatasetModel, IVizelConfig } from '../../services/ds/types';
import { lpeRun } from '../../utils/lpeRun';
import {
  IEntity,
  IMetric,
  IOptionsProvider, IPeriod, IStoplight,
  IStoplights, IStopPoint,
  ISubspace,
  IUnit,
  IValue,
  IVizelConfigDisplay,
  tables
} from '../../defs/bi';
import { DsStateService1 } from '../../services/ds/DsStateService1';

const ORDER_ASCENDING: string = 'asc';
const ORDER_DESCENDING: string = 'desc';

export interface IVAxis {
  index: number;
  id: string;
  unit: IUnit;
  opposite: boolean;
  dataMin?: number;
  dataMax?: number;
}

interface IAggr {
  sum: number;
  qty: number;
  avg: number;
  strSum: string;         // formatted
  strQty: string;
  strAvg: string;
}

export interface ISerie {
  id: string;
  index: number;
  e: IEntity;
  title: string;
  vAxisIndex: number;
  stackGroup: string;
  values: IValue[];
  strValues: string[];                                                          // string or numerics - formatted
  numValues: number[];                                                          // numerics or nulls
  imgValues?: string[];
  bgColors: string[];
  aggr: IAggr;
  normsResponse?: INormsResponse;
  isColorX?: boolean;
}

export interface INormSerie extends ISerie {
  zone: INormZone;
  showInLegend: boolean;
}

export interface IVizelXYVM {
  loading?: boolean;
  error?: string;

  schema_name: string;
  vAxes: IVAxis[];
  series: ISerie[];
  normSeries: INormSerie[];
  categories: IEntity[];
  plotLines: HighchartsPlotLines[];
  plotBands: HighchartsPlotBands[];
  noData: boolean;
  noNumericData: boolean;
  dateTimeXAxisPeriodType: number;                                              // 0 if unavailable, otherwise PeriodType
  mixedSeries: (ISerie | INormSerie)[];
  userSortOrder: string;
  userSortBy: string;
  subspace: ISubspace;                                                          // TODO: remove

  // events
  onToggleSort: (userSortBy: string) => void;
}

//
// utility
//
function formatNum2(v: IValue, x: IEntity, y: IEntity, z: IEntity, cfg: IVizelConfig): string {
  const display: IVizelConfigDisplay = cfg.getDisplay();    // TODO: add vizelType

  if (typeof v === 'string') {
    return v as string;
  }
  if (v === null) {
    return '';
  }

  const lix: tables.ILegendItem = x ? cfg.getLegendItem(x) : null;
  const liy: tables.ILegendItem = y ? cfg.getLegendItem(y) : null;
  const liz: tables.ILegendItem = z ? cfg.getLegendItem(z) : null;

  if (lix && lix.format) return formatNumberWithString(v, lix.format).toString();
  if (liy && liy.format) return formatNumberWithString(v, liy.format).toString();
  if (liz && liz.format) return formatNumberWithString(v, liz.format).toString();

  const m: IMetric = FIND_M(z, y, x);
  const u: IUnit = m ? m.unit : (x?.unit || y?.unit || z.unit || null);

  if (u && u.config && u.config.valueMap && (v in u.config.valueMap)) {
    return u.config.valueMap[v];
  }

  // add lix/liy/liz option [VAxisInteger]

  if (u && u.config && u.config.format) return formatNumberWithString(v, u.config.format);
  if (m && m.config && m.config.format) return formatNumberWithString(v, m.config.format);

  if (display && display.format) return formatNumberWithString(v, display.format);

  // no format specified
  return formatNum(v, cfg.getOption('VAxisInteger') ? 0 : 2);
}


function calculateAggr(values: IValue[]): IAggr {
  const aggr: IAggr = {sum: null, qty: 0, avg: null, strSum: '0', strQty: '', strAvg: ''};

  values.forEach((val) => {
    if (typeof val === 'number') {
      aggr.qty = aggr.qty + 1;
      aggr.sum = aggr.sum + val;
    } else if (typeof val === 'string') {
      aggr.qty = aggr.qty + 1;
    }
  });

  if (aggr.sum != null && aggr.qty != null && aggr.qty > 0) {
    aggr.avg = aggr.sum / aggr.qty;
  }

  return aggr;
}


function createComparator(cfg: IVizelConfig, xs: IEntity[], ys: IEntity[], matrix: IValue[][], sortOrder: string, sortBy: IEntity) {
  const sortByIdx: number = getEntityIdx(ys, sortBy);
  const vector: IValue[] = matrix?.[sortByIdx];
  const mul: number = (String(sortOrder).toUpperCase() === 'DESC') ? -1 : 1;

  return (i1: number, i2: number) => {
    const x1: IEntity = xs[i1];
    const x2: IEntity = xs[i2];
    const li1: tables.ILegendItem = cfg.getLegendItem(x1);
    const li2: tables.ILegendItem = cfg.getLegendItem(x2);
    const srt1: number = li1 ? li1.srt : null;
    const srt2: number = li2 ? li2.srt : null;
    if (srt1 != null || srt2 != null) {
      return (srt1 || 0) - (srt2 || 0);
    }

    if (sortByIdx === -1) {       // sortY not exists: sort by entity
      if (('startDate' in x1) && ('startDate' in x2)) {
        return mul * ((x1 as IPeriod).startDate.valueOf() - (x2 as IPeriod).startDate.valueOf());
      } else {
        return mul * (x1.title.localeCompare(x2.title));
      }
    }

    const v1: IValue = vector[i1];
    const v2: IValue = vector[i2];
    if (v1 == null) return +1;          // nulls are always at the end, no matter of order
    if (v2 == null) return -1;

    if (typeof v1 === 'string' || typeof v2 === 'string') {
      return mul * String(v1).localeCompare(String(v2));
    } else {
      return mul * (v1 - v2);
    }
  };
}

function getColumnColors(dataMatrix: IDataMatrix, cfg: IVizelConfig, y: IEntity, values: IValue[], nr: INormsResponse): string[] | null {
  const {ys, xs} = dataMatrix;
  const li: tables.ILegendItem = cfg.getLegendItem(y) || {};
  const liOptions: IOptionsProvider = new OptionsProvider(li.options);
  const liStoplights: IStoplights = li.stoplight ? Stoplights.createWithVector(li.stoplight, values) : null;

  const setItemData = (ctx: any, arr: any[], ind: number): void => {
    (arr || []).map((c, ynum: number) => {
      ctx[c.id] = dataMatrix.matrix?.[ynum]?.[ind];
    });
  };

  if (liOptions.hasOption('ColorX')) {
    const ds = cfg.dataset;
    const dsStateService = DsStateService1.createInstance(ds.schema_name);
    const dsState = dsStateService.getModel();
    dsStateService.release();

    let context = {
      xs,
      y,
      v: (x, y) => {
        if (y === undefined) {
          y = x;
          // Сюда нужно добавить код для вытаскиванию значения
          return 0;
        }
        const xId = (typeof x === 'object') ? x.id : x, yId = (typeof y === 'object') ? y.id : y;
        const xIdx = $eidx(xs, xId), yIdx = $eidx(ys, yId);
        return dataMatrix.matrix[yIdx][xIdx];
      },
      xById: (id) => $eid(xs, id),
      state: dsState,
    };

    setItemData(context, ys, 0);
    setItemData(context, xs, 0);

    const colors = lpeRun((li?.colorFormula || li.color), context);

    if (typeof colors === 'string' && li?.colorFormula.startsWith('lpe:')) {
      const newColors = [];

      (xs || []).map((x2, xnum) => {
        let innerContext = {
          ...context,
        };

        if (x2 && x2.axisIds) {
          (x2?.axisIds || []).forEach((id, id_index) => {
            innerContext[id] = x2.titles[id_index];
          });
        }

        setItemData(innerContext, ys, xnum);

        const newColor = lpeRun(li?.colorFormula, innerContext);

        newColors.push(newColor || '');
      });

      return newColors;
    }

    return colors;
  }

  if (!liOptions.hasOption('FillBackground') && !cfg.hasOption('FillBackground')) {
    return null;
  }

  if (liStoplights) {
    return values.map((v: IValue, i: number) => liStoplights.getColorPair(xs[i], v).bgColor);
  }

  if (nr) {
    return xs.map((x: IEntity, i: number) => nr.getColorPair(x, values[i]).bgColor);
  }

  return null;
}


function colorifySeries(dataMatrix: IDataMatrix, series: ISerie[], cfg: IVizelConfig): void {
  const {xs} = dataMatrix;
  const nullBgColors = createNullVector<string>(xs.length);

  // specific by-serie config may override
  series = series.filter(serie => {
    const bgColors: string[] = getColumnColors(dataMatrix, cfg, serie.e, serie.values, serie.normsResponse);
    if (bgColors) {
      serie.bgColors = bgColors;
      return false;
    } else {
      serie.bgColors = nullBgColors as string[];
      return true;
    }
  });

  if (series.length === 0) {
    return;
  }

  if (!cfg.hasOption('FillBackground')) {
    return;
  }

  if (cfg.getRaw().display && cfg.getRaw().display.stoplight) {
    const rawStoplight = cfg.getRaw().display.stoplight;
    const matrix: IValue[][] = series.map(serie => serie.values);
    const stoplights: Stoplights = Stoplights.createWithMatrix(rawStoplight, matrix);
    series.forEach(serie => {
      serie.bgColors = xs.map((x: IEntity, i: number) => stoplights.getColorPair(x, serie.values[i]).bgColor);
    });
  }
}


function applyFilterBy(dataMatrix: IDataMatrix, cfgFilterBy: string | any[], xOrder: number[]): number[] {
  const {xs, ys} = dataMatrix;
  const filterByEntity: IEntity = getEntity(ys, String(cfgFilterBy));
  if (filterByEntity) {
    const values: IValue[] = dataMatrix.getVectorX(filterByEntity);
    xOrder = xOrder.filter((idx: number) => values[idx] !== null);
    return xOrder;
  }

  if (Array.isArray(cfgFilterBy)) {
    const filterYIdxs = cfgFilterBy.map(id => $eidx(ys, id)).filter(idx => idx !== -1);

    if (filterYIdxs.length) {
      xOrder = xOrder.filter((xIdx: number) => {
        // any of is not null
        return !!filterYIdxs.find(yIdx => dataMatrix.matrix[yIdx][xIdx] !== null);
      });
    }
    return xOrder;
  }

  if (cfgFilterBy) {
    xOrder = xOrder.filter((xIdx: number) => {
      const ctx = (varName: string, value?: any): any => {
        let yIdx: number = -1;

        if (varName[0] === '#') {
          const yId = varName.slice(1);
          yIdx = $eidx(ys, yId);

        } else if (varName[0] === '$') {
          yIdx = parseInt(varName.slice(1), 10) - 1;

        } else if (varName === 'row') {
          return xs[xIdx];
        }

        if (0 <= yIdx && yIdx < ys.length) {
          const val: IValue = dataMatrix.getVectorX(yIdx)[xIdx];
          return val;
        }
      };
      const res = lpeRun(cfgFilterBy, ctx);
      return !!res;
    });

    return xOrder;
  }

  return xOrder;
}


export class VizelXYVC extends BaseVizelVC<IVizelXYVM> {
  protected _dataMatrixProvider: CachedDataMatrixProvider;
  public _userSortOrder: string = null;
  public _userSortBy: string = null;
  private _dataMatrix: IDataMatrix;
  private _userFilterBy: string | null = null;

  public constructor(dp: data_engine.IDataProvider, cfg: IVizelConfig) {
    super(dp, cfg,
        {
          onToggleSort: (userSortBy: string) => this.onToggleSort(userSortBy),
        });
    const isNoCache: number = cfg.getOption('noCache') ? 1 : 0;
    this._dataMatrixProvider = new CachedDataMatrixProvider(dp, isNoCache);
  }

  public onToggleSort = (userSortBy: string) => {
    if (this._userSortBy === userSortBy) {
      this._userSortOrder = (this._userSortOrder === ORDER_ASCENDING) ? ORDER_DESCENDING : ORDER_ASCENDING;
    } else {
      this._userSortBy = userSortBy;
      this._userSortOrder = ORDER_ASCENDING;
    }

    if (this._dataMatrix) {
      const preprocessedDataMatrix: IDataMatrix = this.preprocess(this._dataMatrix);
      const vm: Partial<IVizelXYVM> = this.__createPlotModel(preprocessedDataMatrix, this._subspace);
      this._updateWithData(vm);
    }
  }
  public setUserSort = (userSortBy: string, userSortDir: string) => {
    this._userSortBy = userSortBy;
    this._userSortOrder = userSortDir;

    if (this._dataMatrix) {
      const preprocessedDataMatrix: IDataMatrix = this.preprocess(this._dataMatrix);
      const vm: Partial<IVizelXYVM> = this.__createPlotModel(preprocessedDataMatrix, this._subspace);
      this._updateWithData(vm);
    }
  }

  public setUserFilterBy(filterBy: string | null) {
    this._userFilterBy = filterBy;
    if (this._dataMatrix) {
      const preprocessedDataMatrix: IDataMatrix = this.preprocess(this._dataMatrix);
      const vm: Partial<IVizelXYVM> = this.__createPlotModel(preprocessedDataMatrix, this._subspace);
      this._updateWithData(vm);
    }
  }

  // override
  protected _updateOneValueInViewModelData(vm: IVizelXYVM, z: IEntity, y: IEntity, x: IEntity, v: number): IVizelXYVM {
    // TODO: remove this._subspace dependency, use dataMatrix
    const {m, l, p} = this._subspace.getMLP(z, y, x);
    if (m && l && p) {
      this._dataMatrixProvider.rtValueUpdated(m, l, p, v);
    }
    return vm;
  }

  public preprocess(dataMatrix: IDataMatrix): IDataMatrix {
    const cfg: IVizelConfig = this._cfg;
    const display: IVizelConfigDisplay = cfg.getDisplay();                                          // TODO: add vizelType
    const cfgFilterBy: string | any[] = this._userFilterBy || display.filterBy;
    const cfgSortOrder: string = display.getSort();
    const cfgSortBy: string = display.getSortBy();
    const cfgLimit: number = display.getLimit();
    const cfgExcludeX: string[] = display.excludeX;
    const cfgExcludeY: string[] = display.excludeY;
    const cfgGroup: any = display.group || null;
    const cfgDisplayLimitRest: boolean = cfg.hasOption('DisplayLimitRest');

    let {xs, ys, z, matrix, normsResponses} = dataMatrix;

    ys.forEach((y: IEntity, yi: number) => {
      const li = cfg.getLegendItem(y);
      let formula: any = li ? li.formula : null;
      if (typeof formula === 'string') {
        formula = lpeRun(formula, {
          xs,
          ys,
          v: (x, y) => {
            let xId = (typeof x === 'object') ? x.id : x, yId = (typeof y === 'object') ? y.id : y;
            return dataMatrix.matrix[$eidx(ys, yId)][$eidx(xs, xId)];
          },
          xById: (id) => $eid(xs, id),
        });
      }

      if (Array.isArray(formula)) dataMatrix.matrix[yi] = formula;              // TODO: slice/duplicate
      else if (typeof formula === 'number') dataMatrix.matrix[yi] = createConstVector(formula, xs.length);
      else if (typeof formula === 'string') dataMatrix.matrix[yi] = createConstVector(formula, xs.length);
      else if (typeof formula === 'function') dataMatrix.matrix[yi] = xs.map((x, idx) => formula(x, idx));
    });

    // setup initial state for user sort
    if (this._userSortOrder === null) {
      this._userSortOrder = cfgSortOrder != null ? cfgSortOrder : null;
    }
    if (this._userSortBy === null) {
      this._userSortBy = cfgSortBy != null ? cfgSortBy : null;
    }

    const userSortBy: IEntity = getEntity(ys, this._userSortBy);

    if (!cfgFilterBy && !cfgSortOrder && !this._userSortOrder && !cfgSortBy && !userSortBy && !cfgLimit && !cfgExcludeY && !cfgExcludeX && !cfgGroup && !cfgDisplayLimitRest) {
      return dataMatrix;
    }

    //
    let xOrder: number[] = range(xs.length);
    let yOrder: number[] = range(ys.length);

    if (cfgExcludeY) {
      const excludeY: string[] = cfgExcludeY.map(yId => yId.toString());
      yOrder = yOrder.filter(yi => excludeY.indexOf(String(ys[yi].id)) === -1);
    }

    if (cfgExcludeX) {
      const excludeX: string[] = cfgExcludeX.map(xId => xId.toString());
      xOrder = xOrder.filter(xi => excludeX.indexOf(String(xs[xi].id)) === -1);
    }

    if (cfgGroup && cfgGroup.by === 'parent') {        // take only parents and perform sort/limit; apply children later
      xOrder = [];
      const idsHash: { [id: string]: boolean } = {};
      xs.forEach(x => idsHash[x.id] = true);
      xs.forEach((x, i) => {
        if (!x.parent || !idsHash[x.parent.id]) {
          xOrder.push(i);
        }
      });
    }

    if (cfgFilterBy) {
      xOrder = applyFilterBy(dataMatrix, cfgFilterBy, xOrder);
    }

    // sort by
    const sortBy: IEntity = getEntity(ys, cfgSortBy);
    const mul: number = (String(cfgSortOrder).toUpperCase() === 'DESC') ? -1 : 1;

    if (cfgSortBy === 'Σ') {
      xOrder.sort((i1: number, i2: number) => {
        const row1 = dataMatrix.matrix.map(v => v[i1]);
        const row2 = dataMatrix.matrix.map(v => v[i2]);
        const v1: number = vectorSumNull(row1 as any);
        const v2: number = vectorSumNull(row2 as any);
        if (v1 != null && v2 != null) return mul * (v1 - v2);
        if (v1 == null) return +1;          // nulls are always at the end, no matter of order
        if (v2 == null) return -1;
        return 0;
      });
    } else if (String(cfgSortBy).startsWith('lpe:')) {
      const getCtx = (i: number) => {
        const result = {idx: i};
        const x = xs[i];
        const row = dataMatrix.matrix.map(v => v[i]);
        x.axisIds?.forEach?.((id, idx) => (id !== 'measures' && (result[id] = ['=', x.ids[idx]])));
        ys.forEach((y, idx) => result[y.id] = row[idx]);
        return result;
      };

      xOrder.sort((i1: number, i2: number) => {
        const ctx1 = getCtx(i1), ctx2 = getCtx(i2);
        const v1 = lpeRun(cfgSortBy, ctx1);
        const v2 = lpeRun(cfgSortBy, ctx2);
        if (v1 != null && v2 != null) return mul * (v1 - v2);
        if (v1 == null) return +1;          // nulls are always at the end, no matter of order
        if (v2 == null) return -1;
        return 0;
      });

    } else if (cfgSortBy && !sortBy) {                                      // старый способ - функция сортировки в LPE - надо выпилить
      xOrder.sort((i1: number, i2: number) => {
        const row1 = dataMatrix.matrix.map(v => v[i1]);
        const row2 = dataMatrix.matrix.map(v => v[i2]);
        const eResult = lpeRun(cfgSortBy, {
          i1, i2,
          x1: xs[i1],
          x2: xs[i2],
          row1, row2,
        });
        return eResult;
      });

    } else if (cfgSortOrder || cfgSortBy) {
      const valueComparator = createComparator(cfg, xs, ys, matrix, cfgSortOrder, sortBy);
      xOrder.sort(valueComparator);
    }

    let restIdxs: number[];
    let restParentIdx: number = null;
    let restX: IEntity;

    if (typeof cfgLimit === 'number') {
      if (cfgLimit >= 0) {
        restIdxs = xOrder.splice(cfgLimit);
      } else {  // < 0
        restIdxs = xOrder.splice(0, xOrder.length + cfgLimit);
      }

      if (cfgDisplayLimitRest) {
        restX = {
          axisId: null,
          children: [],
          color: null,
          config: {},
          id: '...',
          title: cfg.getTitle({id: '...', title: null}) || lang('rest'),
          parent: null,
          description: null,
        };

        xs = xs.slice(0);
        xs.push(restX);
        restParentIdx = xs.length - 1;
        xOrder.push(restParentIdx);

        if (matrix) {
          let restAggrs: IValue[] = matrix.map((vector: IValue[], yi: number) => {
            const restValues: IValue[] = restIdxs.map(idx => vector[idx]);
            return calculateAggr(restValues).sum;
          });

          // agraphena
          restAggrs = ys.map((y: IEntity, yi: number) => {
            let v: IValue = restAggrs[yi];
            const li: tables.ILegendItem = cfg.getLegendItem(y);
            let formula = (li && li.formula) ? li.formula : null;

            if (formula) {
              formula = String(formula).replace(/\#[a-zA-Z0-9_]+/g, (refId) => {
                const idx: number = getEntityIdx(ys, refId.slice(1));
                return (idx !== -1) ? String(restAggrs[idx]) : refId;
              });

              try {
                const newValue = parseFloat((window as any).eval(formula));
                v = isFinite(newValue) ? newValue : null;
              } catch (err) {
                console.warn('Error in formula', err);
              }
            }
            return v;
          });
          // push row in matrix
          matrix = matrix.map((vector: IValue[], yi: number) => {
            vector = vector.slice(0);
            vector.push(restAggrs[yi]);
            return vector;
          });
        }
      }
    }

    // apply user sort
    if (matrix && (this._userSortOrder !== null || userSortBy !== null) && (this._userSortOrder !== cfgSortOrder || userSortBy !== sortBy)) {
      const valueComparator = createComparator(cfg, xs, ys, matrix, this._userSortOrder, userSortBy);
      xOrder.sort(valueComparator);
    }


    if (cfgGroup && cfgGroup.by === 'parent') {                         // add children after each element
      let resIdxs: number[] = [];                        // the result
      xOrder.forEach((parentIdx: number) => {
        resIdxs.push(parentIdx);                         // first push parent

        let childrenIdxs: number[] = [];                 // then collect children

        if (parentIdx !== restParentIdx) {
          const p: IEntity = xs[parentIdx];
          xs.forEach((x: IEntity, xidx: number) => {
            if (x.parent === p) {
              childrenIdxs.push(xidx);
            }
          });
        } else {                           // rest
          for (parentIdx of restIdxs) {
            const p: IEntity = xs[parentIdx];
            xs.forEach((x: IEntity, xidx: number) => {
              if (x.parent === p) {
                childrenIdxs.push(xidx);
              }
            });
          }
        }

        let groupSortOrder: string = cfgGroup.sort || this._userSortOrder;
        let groupSortBy: IEntity = getEntity(ys, cfgGroup.sortBy || sortBy);      // if not set, sort by user or global
        let groupLimit: number = cfgGroup.limit;

        // filter inside group
        if (cfgFilterBy) {
          // TODO: check filterBy for string | number
          const filterBy: IEntity = getEntity(ys, String(cfgFilterBy));
          const values: IValue[] = dataMatrix.getVectorX(filterBy);
          childrenIdxs = childrenIdxs.filter((idx: number) => values[idx] !== null);
        }

        if (groupSortOrder || groupSortBy) {
          const childComparator = createComparator(cfg, xs, ys, matrix, groupSortOrder, groupSortBy);
          childrenIdxs.sort(childComparator);
        }

        if (typeof groupLimit === 'number') childrenIdxs.splice(groupLimit);
        resIdxs = resIdxs.concat(childrenIdxs);
      });
      xOrder = resIdxs;
    }

    return createDataMatrixWithOrder({
      xs,
      ys,
      z,
      normsResponses,
      matrix,
      xOrder,
      yOrder,
    });
  }

  // koob =  return
  protected __getUnitForY(y: IEntity, dataMatrix: IDataMatrix): IUnit {
    // might be tagged
    const {xs, ys, z} = dataMatrix;
    const x: IEntity = dataMatrix.getX(0);

    if (IS_M(y)) {
      return (y as IMetric).unit;
    }
    if (IS_M(z)) {
      return (z as IMetric).unit;
    }
    if (IS_M(x)) {
      return (x as IMetric).unit;
    }
    if (y.unit) {
      return y.unit;
    }

    let m: IMetric;

    if (xs.length && y && z && !this._subspace.koob && !this._subspace.lookupId) {
      // TODO: remove this._subspace dependency, use dataMatric
      m = this._subspace.getMLP(z, y, xs[0]).m;
    }
    if (m) {
      if (Array.isArray(m)) return m[0].unit;
      return m.unit;
    }
    // ?????

    return null;
  }

  private __createPlotModel(dataMatrix: IDataMatrix, subspace: ISubspace): Partial<IVizelXYVM> {
    const cfg: IVizelConfig = this._cfg;
    const display: IVizelConfigDisplay = cfg.getDisplay();
    const dataset: IDatasetModel = cfg.getDataset();

    const {xs, ys, z} = dataMatrix;

    let units: IUnit[] = uniq(ys.map((y: IEntity) => this.__getUnitForY(y, dataMatrix)));
    if (units.length === 0) {
      units = [null];
    }

    // TODO: check VizelConfig options
    let dateTimeXAxisPeriodType: number = 0;
    if (dataset && dataset.getConfigHelper().getBoolValue('vizels.*.options.XAxisDateTime') &&
        (subspace.getArity() === 3) && IS_PS(dataMatrix.xs)) {
      dateTimeXAxisPeriodType = (dataMatrix.xs[0] as IPeriod).period_type;
    }

    const vAxes: IVAxis[] = units.map((u: IUnit, index: number): IVAxis => ({
      id: 'v-' + (u ? u.id : ''),
      unit: u,
      index: index,
      opposite: index >= units.length / 2,
    }));

    const noData: boolean = !dataMatrix.hasData();
    const noNumericData: boolean = !dataMatrix.hasNumericData();

    const series: ISerie[] = ys.map((y: IEntity, index: number): ISerie => {
      const li: tables.ILegendItem = cfg.getLegendItem(y) || {};
      const liOptions: IOptionsProvider = new OptionsProvider(li.options);

      const unit: IUnit = this.__getUnitForY(y, dataMatrix);
      const unitIndex: number = units.indexOf(unit);

      const stackGroupIndex = display.getStackGroupIndex(y);     // one may configure display.stackGroups as [[id, id...], [...], ...] - we get the index
      const stackGroup: string = (stackGroupIndex != null) ? ('stack-group-' + String(stackGroupIndex)) : String(unitIndex);

      const values: IValue[] = dataMatrix.getVectorX(y);
      const numValues: number[] = values.map(v => typeof v === 'number' ? v : null);
      const strValues: string[] = values.map((v: IValue, xi: number) => formatNum2(v, xs[xi], y, z, cfg));

      const m = FIND_M(null, y, z);                                   // TODO: tagged metric
      const u = m ? m.unit : null;                                    // TODO: metrics by x
      let imgValues: string[] = null;
      if (u && u.config && u.config.imageMap) {
        imgValues = values.map(v => (typeof v === 'number') && (v in u.config.imageMap) ? u.config.imageMap[v] : null);
      }

      const normsResponse: INormsResponse = dataMatrix.getNormsResponse(y);

      let aggr: IAggr = null;
      if (cfg.getOption('DisplayOverall') !== false) {
        aggr = calculateAggr(values);
        aggr.strAvg = formatNum2(aggr.avg, null, y, z, cfg);
        aggr.strQty = formatNum2(aggr.qty, null, y, z, cfg);
        aggr.strSum = formatNum2(aggr.sum, null, y, z, cfg);
      }

      const serie: ISerie = {
        id: 's-' + y.id,
        e: y,
        title: cfg.getTitle(y),
        vAxisIndex: unitIndex,
        values,
        strValues,
        numValues,
        imgValues,
        bgColors: null,
        index: index,
        stackGroup: stackGroup,
        aggr,
        normsResponse,
        isColorX: liOptions.getOption('ColorX'),
      };

      return serie;
    });

    // colorify background
    colorifySeries(dataMatrix, series, cfg);

    let plotBands: HighchartsPlotBands[] = [];
    let plotLines: HighchartsPlotLines[] = [];
    let normSeries: INormSerie[] = [];
    let mixedSeries: (ISerie | INormSerie)[] = series;

    const stoplights: IStoplights = this._cfg.getStoplights();

    if (stoplights) {    // even empty array is valid!
      let pbId: number = 0;
      stoplights.forEach((s: IStoplight) => {
        if (s.limit[0] !== s.limit[1]) {
          plotBands.push({
            from: s.limit[0] == null ? -Infinity : s.limit[0],
            to: s.limit[1] == null ? Infinity : s.limit[1],
            color: makeColor(s.bgColor || s.color),
            id: String(pbId++),
          });
        } else {
          plotLines.push({
            value: s.limit[0],
            color: makeColor(s.color || s.bgColor),
            width: 2,
            id: String(pbId++),
          });
        }
      });
      stoplights.forEachPoint((p: IStopPoint) => {
        plotLines.push({
          value: p.value,
          color: makeColor(p.color || p.bgColor),
          width: p.width,
          dashStyle: p.style,
          id: String(pbId++),
          zIndex: p.zIndex,
        });
      });

    } else {  // norms
      //   const normsNeeded = cfg.hasOption('FillBackground') || cfg.getOption('DisplayNorms', true);

      ys.forEach((y: IEntity, i: number) => {
        const normsResponse: INormsResponse = dataMatrix.getNormsResponse(y);
        if (!normsResponse) {
          return;
        }
        const isArea: boolean = this._cfg.normStrategy === 'fill';
        const unit: IUnit = this.__getUnitForY(y, dataMatrix);
        const unitIndex: number = units.indexOf(unit);

        const zones: INormZone[] = normsResponse.getZones();
        zones.forEach((zone: INormZone) => {
          if (isArea) {
            if (zone.hasInf && zone.hasSup) {
              const infData = zone.getInfData();
              const supData = zone.getSupData();
              const values: any = dataMatrix.xs.map((x: IEntity, i: number) => [i, infData[i], supData[i]]);
              const numValues: number[] = supData.map(v => typeof v === 'number' ? v : null);
              const strValues: string[] = supData.map((v: IValue, xi: number) => formatNum2(v, xs[xi], y, z, cfg));
              let aggr: IAggr = null;
              if (cfg.getOption('DisplayOverall') !== false) {
                aggr = calculateAggr(supData);
                aggr.strAvg = formatNum2(aggr.avg, null, y, z, cfg);
                aggr.strQty = formatNum2(aggr.qty, null, y, z, cfg);
                aggr.strSum = formatNum2(aggr.sum, null, y, z, cfg);
              }
              normSeries.push({
                id: 's-norm-' + y.id + '-' + zone.id,
                e: y,
                title: cfg.getTitle(y),
                vAxisIndex: unitIndex,
                values,
                strValues,
                numValues,
                bgColors: [],
                index: series.length + i,
                zone,
                showInLegend: false,
                stackGroup: String(unitIndex) + '-norm',
                aggr,
              });
            }
          } else {   // lines
            if (zone.fake) return;

            if (zone.hasInf) {
              const values: IValue[] = zone.getInfData();
              const numValues: number[] = values.map(v => typeof v === 'number' ? v : null);
              const strValues: string[] = values.map((v: IValue, xi: number) => formatNum2(v, xs[xi], y, z, cfg));
              let aggr: IAggr = null;
              if (cfg.getOption('DisplayOverall') !== false) {
                aggr = calculateAggr(values);
                aggr.strAvg = formatNum2(aggr.avg, null, y, z, cfg);
                aggr.strQty = formatNum2(aggr.qty, null, y, z, cfg);
                aggr.strSum = formatNum2(aggr.sum, null, y, z, cfg);
              }
              normSeries.push({
                id: 's-norm-' + y.id + '-' + zone.id + '-inf',
                e: y,
                // title: cfg.getTitle(zone.infMetric),
                title: zone.infTitle,
                vAxisIndex: unitIndex,
                values,
                numValues,
                strValues,
                bgColors: [],
                index: series.length + i,
                zone: zone,
                showInLegend: false,
                stackGroup: String(unitIndex) + '-norm',
                aggr,
              });
            }

            if (zone.hasSup) {
              const values: number[] = zone.getSupData();
              const numValues: number[] = values.map(v => typeof v === 'number' ? v : null);
              const strValues: string[] = values.map((v: IValue, xi: number) => formatNum2(v, xs[xi], y, z, cfg));
              let aggr: IAggr = null;
              if (cfg.getOption('DisplayOverall') !== false) {
                aggr = calculateAggr(values);
                aggr.strAvg = formatNum2(aggr.avg, null, y, z, cfg);
                aggr.strQty = formatNum2(aggr.qty, null, y, z, cfg);
                aggr.strSum = formatNum2(aggr.sum, null, y, z, cfg);
              }
              normSeries.push({
                id: 's-norm-' + y.id + '-' + zone.id + '-sup',
                e: y,
                // title: cfg.getTitle(zone.supMetric),
                title: zone.supTitle,
                vAxisIndex: unitIndex,
                values,
                numValues,
                strValues,
                bgColors: [],
                index: series.length + i,
                zone: zone,
                showInLegend: false,
                stackGroup: String(unitIndex) + '-norm',
                aggr,
              });
            }
          }
        });
      });
      if (normSeries.length) {
        normSeries[0].showInLegend = true;
      }

      mixedSeries = [];
      series.forEach((serie: ISerie) => {
        mixedSeries.push(serie);
        if (this._cfg.getOption('DisplayNorms') === true) {
          const linkedNormSeries: INormSerie[] = normSeries.filter(ns => ns.e === serie.e);
          mixedSeries = mixedSeries.concat(linkedNormSeries);
        }
      });
    }

    const result: Partial<IVizelXYVM> = {
      schema_name: dataset.schema_name,
      vAxes,
      series,
      normSeries,
      mixedSeries,
      categories: xs,
      plotLines,
      plotBands,
      noData,
      noNumericData,
      dateTimeXAxisPeriodType,
      subspace,
      userSortOrder: this._userSortOrder,
      userSortBy: this._userSortBy,
    };

    return result;
  }

  // @override
  protected async _loadRawModel(subspace: ISubspace): Promise<any> {
    this._dataMatrix = await this._dataMatrixProvider.setAxes(subspace, this._cfg.getOption('ClosestValue'));
    const preprocessedDataMatrix: IDataMatrix = this.preprocess(this._dataMatrix);
    const vm: Partial<IVizelXYVM> = this.__createPlotModel(preprocessedDataMatrix, subspace);
    return vm;
  }

  // @override
  protected _createViewModelData(rawModel: any, prevViewModelData: IVizelXYVM): IVizelXYVM {
    return rawModel;
  }

  public setAxes(subspace: ISubspace): Promise<void> {
    return super.setAxes(subspace.reduce(Infinity, Infinity, 1));
  }

  protected _dispose() {
    super._dispose();
  }
}

