import { IRange } from '../../../defs/bi';
import { formatNum } from '../../../utils/utils';
import { getTextWidth } from '../../vizels/utility/c-utils';
import { __intersects, TEXT_MARGIN } from './MapFillLayerDialogR';

class Tick {
  public tickLeft: number;
  public tickColor: string = '#000000';

  public text: string;
  public textColor: string = '#666666';
  public isVisible: boolean = true;

  public textLeft: number;     // uninitialized!
  public textWidth: number;

  public constructor(v: number, range: IRange) {
    this.text = formatNum(v, 2);
    this.textWidth = getTextWidth(this.text);
    if (range[1] !== range[0]) {
      this.tickLeft = 100 * (v - range[0]) / (range[1] - range[0]);      // TODO: ranges equals!
    } else {
      this.tickLeft = 50;
    }
  }

  public setHighlite(isHighlited: boolean): void {
    this.textColor = isHighlited ? '#000000' : '#666666';
  }

  public intersects(other: Tick): boolean {
    const meMin: number = this.textLeft - TEXT_MARGIN / 2;
    const meMax: number = this.textLeft + this.textWidth + TEXT_MARGIN / 2;

    const min: number = other.textLeft - TEXT_MARGIN / 2;
    const max: number = other.textLeft + other.textWidth + TEXT_MARGIN / 2;

    return __intersects(meMin, meMax, min, max);
  }

  public setVisible(isVisible: boolean): void {
    this.isVisible = isVisible;
  }
}

export default Tick;
