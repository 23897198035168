import * as React from 'react';
import {IDatasetModel} from "../../../services/ds/types";
import {ILocation, IMapFill, IMetric, IRange} from "../../../defs/bi";
import cn from 'classnames';
import {formatNum, lang} from "../../../utils/utils";
import CenterMapIcon from "./CenterMapIcon";
import ExpandIcon from "./ExpandIcon";
import CloseIcon from "./CloseIcon";
import VMMapFillControlsR from "./VMMapFillControlsR";
import {IDisposable} from "@luxms/bi-core";
import DiscreteLegendR from "./DiscreteLegendR";
import Tick from "./Tick";
import {log10} from "../../../utils/math";
import {getTextWidth} from "../../vizels/utility/c-utils";
import ContinuousLegendR from "./ContinuousLegendR";
import NullLegendR from "./NullLegendR";

export const TOTAL_WIDTH: number = 360;
export const TOTAL_LR_MARGIN: number = 15;
export const TEXT_MARGIN: number = 10;

export function __intersects(amin: number, amax: number, bmin: number, bmax: number): boolean {
  if (amin >= bmax) return false;
  if (amax <= bmin) return false;
  return true;
}

function isNumber(n) {
  return typeof n === 'number' && !isNaN(n);
}

function correctFloat(num, prec?) {
  return parseFloat(num.toPrecision(prec || 14));
}


function getLinearTickPositions(tickInterval, min, max): number[] {
  var pos,
    lastPos,
    roundedMin = correctFloat(Math.floor(min / tickInterval) * tickInterval),
    roundedMax = correctFloat(Math.ceil(max / tickInterval) * tickInterval),
    tickPositions = [];

  // For single points, add a tick regardless of the relative position (#2662)
  if (min === max && isNumber(min)) {
    return [min];
  }

  // Populate the intermediate values
  pos = roundedMin;
  while (pos <= roundedMax) {

    // Place the tick on the rounded value
    tickPositions.push(pos);

    // Always add the raw tickInterval, not the corrected one.
    pos = correctFloat(pos + tickInterval);

    // If the interval is not big enough in the current min - max range to actually increase
    // the loop variable, we need to break out to prevent endless loop. Issue #619
    if (pos === lastPos) {
      break;
    }

    // Record the last value
    lastPos = pos;
  }
  return tickPositions;
}

function s(min: number, MAX: number, N: number): string[] {
  let result: string[] = [min.toString()];

  let f = -Math.floor(log10((MAX - min) / N));
  if (f < 0) {
    f = 0;
  }

  for (let i = 1; i <= N - 1; i++) {
    result.push((min + i * (MAX - min) / N).toFixed(f));
  }
  result.push(MAX.toString());

  return result;
}


export function _buildLegendSubtitle(metric: IMetric): string {
  let parent: IMetric = metric.parent;
  if (parent)
    return _buildLegendSubtitle(parent) + ' / ' + metric.title;
  else
    return metric.title;
}


function __isEnoughPlaceForTickLabels(ticks: number[], pxWidth: number): boolean {
  let totalWidth: number = 0;     // -2 * TEXT_MARGIN
  ticks.forEach((v: number) => {
    const text: string = formatNum(v, 2);
    let tickTextWidth: number = getTextWidth(text);
    totalWidth += tickTextWidth + 2 * TEXT_MARGIN;
  });
  return totalWidth <= pxWidth;
}


function __buildTicksOnTickValues(range: IRange, width: number, minx: number, maxx: number, tickValues: number[]): Tick[] {
  const ticks: Tick[] = tickValues.map((v: number) => {
    const tick: Tick = new Tick(v, range);
    let tickPxPosition: number = width * tick.tickLeft / 100;
    let textPxPosition: number = tickPxPosition - tick.textWidth / 2;
    textPxPosition = Math.min(Math.max(textPxPosition, minx + 2), maxx - tick.textWidth - 2);
    tick.textLeft = Math.floor(textPxPosition);
    return tick;
  });
  return ticks;
}


function __ticksHasIntersection(ticks: Tick[]): boolean {
  for (let i = 0; i < ticks.length - 2; i++) {
    if (ticks[i].intersects(ticks[i + 1])) {
      return true;
    }
  }
  return false;
}


export function __buildTicks(range: IRange, width: number, minx: number, maxx: number): Tick[] {
  const [min, max] = range;
  const domainLength: number = max - min;
  const digits: number = Math.floor(log10(domainLength));
  // if (!isFinite(digits)) digits = 0;

  const interval = Math.pow(10, digits);

  const multipliers = [0.1, 0.2, 0.25, 0.5, 1, 1.2, 1.25, 1.5, 2.0];

  for (let m of multipliers) {
    let tickValues: number[] = getLinearTickPositions(interval * m, min, max);

    // ok, min shall be real min and max shall be real max
    // so leave only those which are in our interval
    tickValues = tickValues.filter((v: number) => min <= v && v <= max);

    if (tickValues.length === 1 || __isEnoughPlaceForTickLabels(tickValues, maxx - minx)) {
      // tickValues is a good candidate to win

      const ticks: Tick[] = __buildTicksOnTickValues(range, width, minx, maxx, tickValues);
      if (tickValues.length === 1) {    // the last chance
        return ticks;
      }

      if (!__ticksHasIntersection(ticks)) {    // good candidate has no intersections => take it
        return ticks;
      }
    }
  }
  return  __buildTicksOnTickValues(range, width, minx, maxx, [min, max]);
}


interface IVMLegend extends IDisposable {
  hiliteLocation?: ILocation;
  nameComponent: string;
  props?: any;
}

interface ILegend {
  hiliteLocation?: ILocation;
  nameComponent: string;
  props?: any;
}

interface IItem {
  title: string;
  color: string;
}

interface IVMLegendState {
  width: number;
  title: string;
  subtitle: string;
  items: IItem[];
}

export interface IMapFillLayerDialogProps {
  ds: IDatasetModel;
  onChange: (mf: IMapFill) => void;
  centerMap: () => void;
  handleToggleExpand: () => void;
  handleClose: () => void;
  legend: ILegend;
  isExpanded: boolean;
  cfgMapFill: IMapFill;
  selectedMetric: IMetric;
  selectedLocation: ILocation;
}

const MapFillLayerDialogR = (props: IMapFillLayerDialogProps) => {
  const { centerMap, handleToggleExpand, handleClose, isExpanded, legend} = props;

  const renderLegend = () => {
    switch (legend.nameComponent){
      case 'VMDiscreteLegend': return <DiscreteLegendR {...legend.props}/>;
      case 'VMContinuousLegend': return <ContinuousLegendR {...legend.props}/>;
      default: return <NullLegendR {...legend.props}/>;
    }
  }

  return (
    <section className={cn("MapfillMapLayerDialog view dialog map-layer mf",{'is-expanded': isExpanded})}>
        <header className="MapfillMapLayerDialog__Header">
          <h2>{lang('mapfill_dialog_title')}</h2>
          <span className="bi-icon dark" style={{position: 'relative', width: '40px', height: '40px'}} onClick={centerMap}>
            <CenterMapIcon/>
          </span>
          <span className="bi-icon dark" style={{position: 'relative', width: '40px', height: '40px'}} onClick={handleToggleExpand}>
            <ExpandIcon/>
          </span>
          <span className="bi-icon dark close" style={{position: 'relative', width: '40px', height: '40px'}} onClick={handleClose}>
            <CloseIcon/>
          </span>
        </header>

        {renderLegend()}

        {!isExpanded &&
        <span style={{display: 'block', color: 'blue', padding: '0 0 10px 12px', cursor: 'pointer', textDecoration: 'underline'}}
              onClick={handleToggleExpand}>{lang('configure')}</span>
        }

        {isExpanded &&
          <VMMapFillControlsR {...props}/>
        }
      </section>
  )
}

export default MapFillLayerDialogR;