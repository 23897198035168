import React from 'react';

// import cn from 'classnames';

interface CloseIconProps {

}

const CloseIcon = (props: CloseIconProps) => {
  //const {  } = props;

  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" xmlSpace="preserve">
      <polygon points="15.778,14.364 9.414,8 15.777,1.636 14.363,0.222 8,6.586 1.636,0.222 0.222,1.636 6.586,8 0.222,14.364 1.636,15.778 8,9.414 14.364,15.778 "></polygon>
    </svg>
  )
}

export default CloseIcon;