import React from 'react';
import CanIService from '../../services/CanIService';
import uniqueId from 'lodash/uniqueId';
import pick from 'lodash/pick';
import EditModeVC from '../../view-controllers/EditModeVC';

interface IEditModeEnablerProps {
  claims: string[];
}

class EditModeEnabler extends React.Component<IEditModeEnablerProps> {
  private _componentId: string;

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    if (!this._componentId) {
      const parentComponentId = (this as any)._reactInternalFiber?._debugOwner?.stateNode?.constructor?.name ?? '';
      this._componentId = uniqueId(parentComponentId + '__EditMode__');
    }

    CanIService.getInstance().ensure(this.props.claims);
    CanIService.getInstance().subscribeUpdatesAndNotify(this._onCanIServiceUpdated);
  }

  public componentWillUnmount() {
    CanIService.getInstance().unsubscribe(this._onCanIServiceUpdated);
    EditModeVC.getInstance().iDontNeedEditMode(this._componentId);
  }

  public componentDidUpdate(prevProps: Readonly<IEditModeEnablerProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.claims.toString() !== prevProps.claims.toString()) {
      CanIService.getInstance().ensure(this.props.claims);
    }
  }

  private _onCanIServiceUpdated = (allClaims: typeof CanIService.MODEL) => {
    const myClaims = pick(allClaims, this.props.claims);
    if (Object.values(myClaims).includes(true)) {                                                   // Есть хоть один компонент, которому нужна кнопка
      EditModeVC.getInstance().iNeedEditMode(this._componentId);
    } else {
      EditModeVC.getInstance().iDontNeedEditMode(this._componentId);
    }
  }

  public render() {
    return null;
  }
}

export default EditModeEnabler;
