import { BaseService, extractErrorMessage } from '@luxms/bi-core';
import { DataSourceInspectorService } from '../../../services/QRPCService';


namespace db_relations {

  function createELArray(error: string, loading: boolean, value: []): any {
    (value as any).error = error;
    (value as any).loading = loading;
    return value;
  }

  interface ISchema {
    name: string;
    facets: {
      elementType: 'schema';
      elementName: string;
    };
    uri: string;
  }

  interface ITable {
    name: string;
    facets: {
      elementType: 'table';
      elementName: string;
      schema: string;
    };
    schema: string;
    tableType: 'TABLE';
    remarks: null;
    uri: string;
  }

  export interface IColumn {
    name: string;
    facets: {
      elementType: 'column';
      elementName: string;
      schema: string;
      table: string;
    };
    schema: string;
    table: string;
    columnTypeInt: number;
    size: number;
    nullable: boolean;
    autoincrement: boolean;
    definition: string;
    remarks: null;
    columnType: 'integer' | 'varchar' | 'timestamp' | 'other';
    uri: string;
  }

  export interface IAnchor {
    anchorType: 'importedKey' | 'exportedKey';
    columns: string[];
    facets: {
      anchorType: 'importedKey' | 'exportedKey';
      elementType: 'anchor';
      elementName: string;
      schema: string;
      table: string;
    };
    name: string;
    relColumns: string[];
    relName: string;
    relSchema: string;
    relTable: string;
    relUris: string[];
    schema: string;
    table: string;
    uri: string;
  }

  interface IRawTableInfo extends Array<ISchema | ITable | IColumn | IAnchor> {
    error: string | null;
    loading: boolean;
  }

  export function isTable(e: IColumn | ITable | ISchema | IAnchor): e is ITable {
    return e.facets.elementType === 'table';
  }

  export function isColumn(e: IColumn | ITable | ISchema | IAnchor): e is IColumn {
    return e.facets.elementType === 'column';
  }

  export function isAnchor(e: IColumn | ITable | ISchema | IAnchor): e is IAnchor {
    return e.facets.elementType === 'anchor';
  }


  // Возвращает инфу о column для таблицы
  class RawTableInfoService extends BaseService<IRawTableInfo> {
    public static MODEL: IRawTableInfo;
    private _params: {sourceId: string; schema: string; table: string};

    public constructor(sourceId: string, schema: string, table: string) {
      super(createELArray('', true, []));
      this._params = {sourceId,  schema, table};
      this._reload();
    }

    private async _reload() {
      try {
        const result = await DataSourceInspectorService.getTableDetails(this._params.sourceId, this._params.schema, this._params.table);
        this._setModel(createELArray(null, false, result));
      } catch (err) {
        this._setModel(createELArray(extractErrorMessage(err), false, []));
      }
    }
  }

  interface IColumnEx extends IColumn {
    primaryKey: string;
  }

  interface IPrimaryKey {
    name: string;
    columns: IColumn[];
  }

  export interface ITableInfo {
    error: string;
    loading: boolean;
    id: string;
    name: string;
    columns: IColumnEx[];
    primaryKeys: IPrimaryKey[];
    ongoingLinks: {from: string, to: string}[];
  }

  export class TableInfoService extends BaseService<ITableInfo> {
    public static MODEL: ITableInfo;
    private _rawTableInfoService: RawTableInfoService;

    public constructor(private sourceId: string, private schema: string, private table: string) {
      super({
        error: null,
        loading: true,
        id: `${sourceId}.${schema}.${table}`,
        name: table,
        columns: [],
        primaryKeys: [],
        ongoingLinks: [],
      });
      this.schema = schema;
      this.table = table;
      this._rawTableInfoService = new RawTableInfoService(sourceId, schema, table);
      this._rawTableInfoService.subscribeUpdatesAndNotify(this._onRawServiceUpdated);
    }

    protected _dispose() {
      this._rawTableInfoService.unsubscribe(this._onRawServiceUpdated);
      this._rawTableInfoService.release();
      this._rawTableInfoService = null;
      super._dispose();
    }

    private _onRawServiceUpdated = (rawTableInfo: IRawTableInfo) => {
      if (rawTableInfo.error) return this._updateModel({error: rawTableInfo.error, loading: false});
      if (rawTableInfo.loading) return this._updateModel({error: null, loading: true});

      const tables: ITable[] = rawTableInfo.filter(isTable);
      if (tables.length !== 1) {
        debugger;
        throw new Error('Expected one entry for table');
      }

      const table = tables[0];

      // здесь я меняю schema и table, и это может привести к драматическим последствиям
      this.schema = table.facets.schema;                                                           // датабазовая схема
      this.table = table.facets.elementName;                                                        // и table name

      const columns = rawTableInfo.filter(isColumn);
      const anchors = rawTableInfo.filter(isAnchor);
      const anchorsPrimaryKeys = anchors.filter(a => a.anchorType === 'exportedKey');

      const primaryKeys: IPrimaryKey[] = anchorsPrimaryKeys.map(apk => ({
        name: apk.name,
        columns: apk.columns.map(columnName => columns.find(c => c.name === columnName)),
      }));

      const columnsEx = columns.map(c => ({
        ...c,
        primaryKey: primaryKeys.find(pk => pk.columns.includes(c))?.name,
      }));

      columnsEx.sort((c1, c2) => {
        if (c1.primaryKey === c2.primaryKey) return c1.name.localeCompare(c2.name);
        return (c1.primaryKey || 'zzzzz').localeCompare(c2.primaryKey || 'zzzzz');
      });

      const anchorsForeignKeys = anchors.filter(a => a.anchorType === 'exportedKey');
      const ongoingLinks: {from: string, to: string}[] =
          anchorsForeignKeys.map(afk =>
              ({
                from: `${this.sourceId}.${this.schema}.${this.table}.${afk.columns[0]}`,
                to: `${this.sourceId}.${afk.relSchema}.${afk.relTable}.${afk.relColumns[0]}`,
              }));

      // Строим id датабазового типа
      const id = `${this.sourceId}.${this.schema}.${this.table}`;

      this._updateModel({
        error: null,
        loading: false,
        id,
        columns: columnsEx,
        primaryKeys,
        ongoingLinks,
      });
    }
  }
}


export default db_relations;

