import { BaseEntitiesService, IBaseEntities } from '@luxms/bi-core';
import { IRawLocationCardFields, LocationCardFieldsRepository } from '../../repositories/LocationCardFieldsRepository';

export class LocationCardFieldsService extends BaseEntitiesService<IRawLocationCardFields> {
  public static readonly MODEL: IBaseEntities<IRawLocationCardFields>;

  public constructor(schema_name: string) {
    super(new LocationCardFieldsRepository(schema_name));
  }
}
