exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.SpriteComponent{z-index:-1;width:60vw;height:70vh;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);padding:3rem 2rem 2rem 2rem;background:#fff;box-shadow:var(--shadow_button);transition:all .2s ease-in-out;opacity:0;pointer-events:none;border-radius:.5rem}.SpriteComponent.visible{z-index:99999;pointer-events:all;opacity:1}.SpriteComponent svg{max-height:100%;max-width:100%}.SpriteComponent__close{width:2rem;height:2rem;position:absolute;right:1rem;top:1rem;cursor:pointer}.SpriteComponent__close svg{color:#3e4c5e}.SpriteComponent__close:hover svg{color:#6e99d0}.SpriteComponent__container{width:100%;max-height:100%;overflow-x:hidden;overflow-y:auto;display:flex;align-items:flex-start;flex-wrap:wrap}.SpriteComponent__box{display:flex;flex-direction:column;align-items:center;justify-content:center;position:relative;margin-bottom:1rem;margin-right:1rem;transition:all .2s ease-in;box-shadow:none;padding:1rem;border-radius:.5rem}.SpriteComponent__box:hover{box-shadow:var(--shadow_button)}.SpriteComponent__iconBox{width:1.5rem;height:1.5rem;display:flex;flex-shrink:0;justify-content:center;align-items:center;margin-bottom:.5rem}.SpriteComponent__iconText{font-size:.65rem;width:4rem;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;user-select:none}.SpriteComponent__tooltip{padding:.25rem 1rem;border:1px solid #0c7df3;border-radius:.25rem;color:gray;font-size:.5rem;position:absolute;top:0;right:0;opacity:0;transform:scale(0);transition:all .2s ease-in-out;background:#fff}.SpriteComponent__tooltip.visible{opacity:1;transform:scale(1)}", ""]);

// exports
exports.locals = {
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};