/**
 *
 *
 *
 *
 */

import { BaseService, urlState, extractErrorMessage, srv, UrlState } from '@luxms/bi-core';
import { PluginsManager } from '../../plugins/plugins-manager';
import { ISummaryModel, SummaryService } from '../../services/SummaryService';
import { IRootSegment } from '../../plugins/plugins-model';
import { lang } from '../../utils/utils';
import { ConfigHelper } from '../../services/ds/ds-helpers';
import { DatasetsListIcon } from '../../views/Root/DatasetsListView';
import { DatasetsListView1 } from '../../views/Root/DatasetsListView1';


const skin: any = require('../../skins/skin.json');


export interface IRootVM {
  viewClassId: 'Root/Root';
  error: string;
  loading: boolean;
  tabs: IRootSegment[];
  summary: ISummaryModel;
  activeTabIndex: number;
  activeChildIndex: number;
  activeTab: IRootSegment;
  showHeader: boolean;
}


export class RootVC extends BaseService<IRootVM> {
  public constructor() {
    super({
      viewClassId: 'Root/Root',
      loading: true,
      error: null,
      tabs: [],
      activeTabIndex: null,
      activeChildIndex: null,
      activeTab: null,
      showHeader: false,
      summary: {loading: true, error: null, data: null},
    });

    this._init();
  }

  private async _init() {
    try {
      const pluginsManager: PluginsManager = PluginsManager.getInstance();
      await pluginsManager.whenReady();
      const DS_TAB: IRootSegment = {
        key: 'ds',
        title: lang('datasets'),
        bgColor: '#cfd8dc',
        bgUrl: require('../../../assets/images/ds_image.png'),
        getIcon: () => DatasetsListIcon,
        getTabElementClass: () => {
          debugger;
        },
        getBodyElementClass: async () => {
          const {DatasetsListView1} = await import('../../views/Root/DatasetsListView1');
          return DatasetsListView1;
        },
        url: '#/ds/',
      };

      const tabs: IRootSegment[] = [DS_TAB, ...pluginsManager.getRootSegments()];
      this._setModel({
        ...this._model,
        tabs,
      });

      this._subscriptions.push(urlState.subscribe('segment segmentId', this._updated));
      this._subscriptions.push(SummaryService.subscribe('update', this._updated));
      this._updated();

    } catch (err) {
      this._setModel({
        ...this._model,
        error: extractErrorMessage(err),
      });
    }
  }

  private _updated = async () => {
    const summary: ISummaryModel = SummaryService.getModel();
    const {segment, segmentId} = urlState.getModel();

    // Если открывается стартовая, и указан entryDataset - будем открывать его (считаем конфиг для формирования URL)
    if (!segment && skin.entryDataset) {                                                            // TODO: вызывать один раз (может быть вызван много раз - меняется сегмент)
      try {
        const configService = srv.ds.ConfigsService.createInstance(skin.entryDataset);
        await configService.whenReady();
        const ch: ConfigHelper = new ConfigHelper(configService.getModel(), null);
        const url = ch.getEnterUrl(skin.entryDataset);
        UrlState.navigate(url, {replace: true, trigger: true});
        return;
      } catch (err) {
        console.warn(err);
      }
    }

    // special case: bm navigates to bookmark
    // TODO: move to plugins
    if (segment === 'bm' && segmentId) {
      return;
    }

    // special case: opened dataset
    if (segment === 'ds' && segmentId && segmentId[0] !== '@') {
      return;
    }

    const {tabs} = this._model;

    let activeTab: IRootSegment = tabs.find(tab => tab.key === segment) || null;
    let activeTabIndex: number = segment ? tabs.indexOf(activeTab) : null;           // mark for no segment selected
    let activeChildIndex: number =  null;

    if (activeTabIndex === -1) {        // segment is set, but none is found
      activeTabIndex = null;                   // TODO: show error `404 not found` instead
      activeTab = null;
    }

    // when there is only one tab, it is always active
    if (this._model.tabs.length === 1) {
      activeTab = tabs[0];
      activeTabIndex = 0;
    }

    // when there are two tabs, don't show start screen
    if (tabs.length === 2 && activeTab !== tabs[1]) {
      activeTab = tabs[0];
      activeTabIndex = 0;
    }

    if (activeTab?.children) {
      activeChildIndex = activeTab!.children.findIndex(c => c.key === segmentId);
      if (activeChildIndex == -1) activeChildIndex = 0;                           // атоматицки выбираем первого
      activeTab = activeTab!.children[activeChildIndex];
    }

    const tabContent = await this._loadTabBody(activeTab);

    this._setModel({
      ...this._model,
      activeTabIndex,
      activeChildIndex,
      activeTab,
      showHeader: true,
      loading: false,
      summary,
    });
  }

  private async _loadTabBody(tab: IRootSegment): Promise<any> {
    if (!tab) {
      return null;
    }
    if (!tab.bodyElementClassCached) {
      const body = tab.getBodyElementClass ? tab.getBodyElementClass() : tab.children[0].getBodyElementClass();
      console.assert(body);
      if (body.then) {       // returned promise
        tab.bodyElementClassCached = await body;
      } else {
        tab.bodyElementClassCached = body;
      }
    }

    document.title = tab.title;

    return tab.bodyElementClassCached;
  }
}


export default RootVC;
