
export function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value));
}


/**
 *
 * @param {number} min -
 * @param {number} k - float number [0..1]
 * @param {number} max -
 * @returns {number}
 */
export function interpolateValue(min: number, k: number, max: number): number {
  k = clamp(k, 0, 1);
  return (1 - k) * min + k * max;
}

// no log10 in safari
export const log10 = Math.log10 = Math.log10 || ((x: number) => Math.log(x) / Math.LN10);

export const sign: (n: number) => number = 'sign' in Math ? Math['sign'] : (x) => {
  x = +x;
  if (x === 0 || isNaN(x)) {
    return x;
  }
  return x > 0 ? 1 : -1;
};

