import React, {useState} from 'react';
import cn from 'classnames';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';
import './MitoExcel.scss';
import './MitoDropdown.scss';
import ContextMenu from '../ContextMenu/ContextMenu';
import {idifyMany, lang} from '../../../utils/utils';
import {IMitoAction, IMitoColumnType} from './types';
import Dropdown from '@luxms/bi-face/Dropdown';
import InputTooltip from '../../../../srx/components/form/InputTooltip';
import {IMitoDataResponse, IMitoSheetModel} from './MitoDataService';

const STYLE = {
  // border: '1px solid #ddd',
  // fontSize: '0.75rem',
};

const STYLE_BOTTOM_LEFT_GRID = {
  // borderRight: '2px solid #aaa',
  // backgroundColor: '#f7f7f7',
};

const STYLE_TOP_LEFT_GRID = {
  // borderBottom: '2px solid #aaa',
  // borderRight: '2px solid #aaa',
  // fontWeight: 'bold',
  // backgroundColor: '#f7f7f7',
};

const STYLE_TOP_RIGHT_GRID = {
  // borderBottom: '2px solid #aaa',
  // fontWeight: 'bold',
};


interface ISheet {
  // "@class":"com.luxms.bi.mdm.excel.elements.ExcelSheet";
  sourceId: string;
  name: string;
  facets: {
    sourceId: string;
    elementType: 'table';
    elementName: string;
    table: string;
  };
  index: number;
  rowsCount: number;
  uri: string;
  //
  // // additional
  // code: string;
}


interface IMitoDataColumn {
  // @class: "com.luxms.bi.mdm.jdbc.elements.JdbcColumn"
  autoincrement: boolean;
  definition: null;
  explicitType: IMitoColumnType;
  // facets: {sourceId: "16m2z3111hhhg", elementType: "column", elementName: "a", schema: "",…}
  name: string;
  nullable: boolean;
  remarks: null;
  schema: string;
  size: number;
  sourceId: string;
  sourceType: number;
  sourceTypeString: 'VARCHAR';
  table: string;
  uri: string;
}

const ColumnTypeSelector = ({
                              type,
                              onChange
                            }: { type: IMitoColumnType, onChange: (type: IMitoColumnType, exp?: string) => any }) => {
  const [visible, setVisible] = useState(false);
  const [sublistVisible, setSublistVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [dateError, setDateError] = useState('');

  const toggleShow = () => setVisible(!visible);
  const hideMenu = () => setVisible(false);
  const handleVisibilitySublist = () => setSublistVisible(!sublistVisible);

  const handleClickItem = (type, exp?: string) => {
    if (exp) {
      onChange(type, exp);
    } else {
      onChange(type);
    }

    setVisible(false);
  };

  const handleChange = e => {
    const title = e.target.value;
    setInputValue(title);

    const template = getValidatedDateTemplate(title);
    template ? setDateError('') : setDateError(lang('wrong_format'));
  };

  const clearInput = () => {
    setDateError('');
    setInputValue('');
  };

  const handleBlur = e => clearInput();

  const getValidatedDateTemplate = (str: string) => {
    if (!str) return null;
    let valid = false;
    const dateOrTime = str.trim().split(' ');

    const dateRegexs = [/(^|[\.\-\\\/])(DD|ДД)($|[\.\-\\\/])/i, /(^|[\.\-\\\/])(MM|ММ)($|[\.\-\\\/])/i, /(^|[\.\-\\\/])(YY|YYYY|ГГ|ГГГГ)($|[\.\-\\\/])/i];
    const timeRegex = /^(HH|ЧЧ):(MI|МИ)(:(SS|СС))?$/i;

    let date = dateOrTime.filter((item) => dateRegexs.every(regex => regex.test(item)))[0];
    let time = dateOrTime[1] ? dateOrTime.filter((item) => timeRegex.test(item))[0] : null;
    const isDateValid = !!date && (date.length === 8 || date.length === 10);
    valid = isDateValid && (dateOrTime[1] ? !!time : true);
    let dateFormat = valid && !!time ? 'DATETIME' : 'DATE';
    let dateIndex = 0;
    let timeIndex = 0;
    const result = [];

    if (valid) {
      dateIndex = dateOrTime.indexOf(date);
      date = date.toUpperCase();

      date = date.replace(/(ДД)/i, 'DD')
        .replace(/(ММ|MM)/i, 'mm')
        .replace(/(ГГ)/ig, 'YY');

      const firstOfDivider = date.match(/([\.\-\\\/])/i)[0];
      date = date.replace(/([\.\-\\\/])/ig, firstOfDivider);

      result[dateIndex] = date;
    }

    if (valid && !!time) {
      timeIndex = dateOrTime.indexOf(time);
      time = time.toUpperCase();

      time = time.replace(/(ЧЧ)/i, 'HH')
        .replace(/(МИ)/i, 'MI')
        .replace(/(СС)/i, 'SS');

      result[timeIndex] = time;
    }

    if (!valid) return null;

    return {
      type: dateFormat,
      result: result.join(' ')
    };
  };

  const onKeyUpEditableItem = (e) => {
    const title = e.target.value;

    if (e.key === 'Enter') {
      if (title) {
        try {

          const template = getValidatedDateTemplate(title);
          if (!template) return;

          if (template.type === 'DATETIME') {
            onChange('DATETIME', template.result);
          } else {
            onChange('DATE', template.result);
          }
          setVisible(false);

        } catch (err) {
          console.error(err);
        }
      }
    } else if (e.key === 'Escape') {
      clearInput();
    }
  };

  return (
    <Dropdown.Trigger
      placement={'bottom-end'}
      visible={visible}
      onClick={toggleShow}
      onClickOutside={hideMenu}
      menu={
        <div className="MitoDropdown">
          <ul className="MitoDropdown__List">
            <li className="MitoDropdown__Item" onClick={() => handleClickItem('STRING')}>STRING</li>
            <li className="MitoDropdown__Item" onClick={() => handleClickItem('INT')}>INT</li>
            <li className="MitoDropdown__Item" onClick={() => handleClickItem('DOUBLE')}>DOUBLE</li>
            <li className={cn('MitoDropdown__Item MitoDropdown__SubListWrapper', {open: sublistVisible})}>
              <div className="MitoDropdown__SubListHeading" onClick={handleVisibilitySublist}>
                <span>DATETIME</span>
                <i>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </i>
              </div>
              {
                sublistVisible &&
                <ul className={cn('MitoDropdown__SubList')}>
                  <li className={cn('MitoDropdown__ItemWithInput', {error: !!dateError})}>
                    {!!dateError && <span className="MitoDropdown__ItemInputError">{dateError}</span>}
                    <input
                      onBlur={handleBlur}
                      value={inputValue}
                      onKeyUp={onKeyUpEditableItem}
                      onChange={handleChange}
                      style={{width: '100%'}}
                      placeholder={lang('enter_date_format')}/>
                  </li>
                  <li className="MitoDropdown__Item MitoDropdown__SubItem"
                      onClick={() => handleClickItem('DATE', 'DD/mm/YY')}>{lang('dd_mm_yyyy')}</li>
                </ul>
              }
            </li>
          </ul>
        </div>}>
      <div className="MitoExcel__ColumnTypeSelector">
        {type}
        <span>
          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L-2.38419e-07 -2.38419e-07H8L4 6Z" fill="#9797C4"/>
          </svg>
        </span>
      </div>
    </Dropdown.Trigger>
  );
};


interface IMitoExcelSheetProps {
  selectedCols: string[] | null;
  selectedRows: number[] | null;
  data: IMitoDataResponse;
  setSelected: (selectedCols: string[] | null, selectedRows: number[] | null) => any;
  onAction: (action: IMitoAction) => any;
}

interface IMitoExcelSheetState {
  editingColumnName: string;
  newColumnName: string;
}


class MitoExcelSheet extends React.Component<IMitoExcelSheetProps, IMitoExcelSheetState> {

  public state: IMitoExcelSheetState = {
    editingColumnName: null,
    newColumnName: '',
  };

  private _clickRow = (e, row): void => {
    e.preventDefault();
    e.stopPropagation();
    ContextMenu.show(e, [
      {title: 'В заголовок', action: () => this.props.onAction({type: 'rowToHeader', rows: [row]})},
      {title: lang('delete'), action: () => this.props.onAction({type: 'dropRows', rows: [row]})},
    ], {arrow: false, placement: 'bottom-start'});
  }
  private _clickColumn = (e, column) => {

    e.preventDefault();
    e.stopPropagation();
    ContextMenu.show(e, [
      {
        title: 'Переименовать',
        action: () => this.setState({editingColumnName: column.name, newColumnName: column.name})
      },
      {title: lang('delete'), action: () => this.props.onAction({type: 'dropColumns', columns: [column.name]})},
    ], {arrow: false, placement: 'bottom-end'});

  }
  private _inputKeyDown = (e) => {
    const {editingColumnName, newColumnName} = this.state;
    if (e.key === 'Enter') this.props.onAction({
      type: 'renameColumn',
      columnName: editingColumnName,
      newColumnName: newColumnName
    });
    if (e.key === 'Enter' || e.key === 'Escape') this.setState({editingColumnName: null, newColumnName: ''});
  }


  private _cache = new CellMeasurerCache({
    // defaultWidth: 100,
    defaultWidth: 20,
    defaultHeight: 40,
    // fixedHeight: true,
  });

  private _cellRendererPure = ({
                                 columnIndex: colIndex,
                                 key,
                                 parent,
                                 rowIndex,
                                 style
                               }: { columnIndex: number, key: string, parent: any, rowIndex: number, style: any }) => {
    const {selectedCols, selectedRows, data, setSelected} = this.props;

    const {editingColumnName, newColumnName} = this.state;
    const column = colIndex > 0 ? data.columns[colIndex - 1] : null;
    const row = rowIndex > 0 ? data.rowNumbers[rowIndex - 1] : null;

    let content;

    if (colIndex === 0 && rowIndex === 0) content = <div className="MitoExcel__Cell rowTitle">&nbsp;</div>;
    else if (colIndex === 0) {


      content = (
        <div className={cn('MitoExcel__Cell', 'rowTitle', {selectedRow: selectedRows?.includes(row)})}
             style={style}
             onContextMenu={e => this._clickRow(e, row, rowIndex)}
             onClick={() => setSelected(null, [row])}
        >
          {row + 1}
        </div>);
    } else if (rowIndex === 0) {

      content = (
        <div className={cn('MitoExcel__Cell', 'colTitle', {selectedCol: selectedCols?.includes(column.name)})}
             style={style}
             onContextMenu={e => this._clickColumn(e, column)}
             onDoubleClick={() => this.setState({editingColumnName: column.name, newColumnName: column.name})}
             onClick={() => setSelected([column.name], null)}
        >

          {editingColumnName === column.name ?
            <InputTooltip type="text"
                          value={newColumnName}
                          onChange={v => this.setState({newColumnName: v})}
                          autoFocus={true}
                          regexp={new RegExp(/^[a-z][a-z0-9_]*$/gi)}
                          onKeyDown={this._inputKeyDown}
                          warningText="Только латинские буквы без пробела"
                          onBlur={() => this.setState({editingColumnName: null, newColumnName: ''})}
            />
            :
            <>
              {column.name}
              <ColumnTypeSelector type={column.explicitType} onChange={(type, exp) => this.props.onAction({
                type: 'cast',
                columnName: column.name,
                columnType: type,
                exp: exp
              })}/>
            </>}
        </div>);
    } else {
      const v = data.rows[rowIndex - 1]?.[colIndex - 1];
      content = (
        <div className={cn('MitoExcel__Cell',
          {
            string: typeof v === 'string',
            number: typeof v === 'number',
            selected: selectedCols?.includes(column.name) && selectedRows?.includes(row),
            selectedRow: selectedCols === null && selectedRows?.includes(row),
            selectedCol: selectedCols?.includes(column.name) && selectedRows === null,
          })}
             style={style}
             onClick={() => setSelected([column.name], [row])}
        >
          {v}
        </div>);
    }

    return (
      <CellMeasurer cache={this._cache}
                    columnIndex={colIndex}
                    key={key}
                    parent={parent}
                    rowIndex={rowIndex}>
        {content}
      </CellMeasurer>);
  }

  public render() {
    const {selectedRows, selectedCols, data} = this.props;
    const {newColumnName} = this.state;

    // if (error) return <div className="MitoExcelSheet error">{error}</div>;

    if (!data) return null;
    const nCols = data.columns.length + 1;
    const nRows = data.rows.length + 1;

    return (
      <div className={cn('MitoExcelSheet', {})}>
        <AutoSizer>
          {({width, height}) => (
            <MultiGrid fixedColumnCount={1}
                       fixedRowCount={1}
                       scrollToColumn={0}
                       scrollToRow={0}
                       cellRenderer={this._cellRendererPure}
                       columnWidth={this._cache.columnWidth}
                       columnCount={nCols}
                       enableFixedColumnScroll
                       enableFixedRowScroll
                       rowHeight={this._cache.rowHeight}
                       rowCount={nRows}
                       style={STYLE}
                       styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                       styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                       styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
                       width={width}
                       height={height}
                       hideTopRightGridScrollbar
                       hideBottomLeftGridScrollbar
              // Для перерисовки
                       rawData={data}
                       selectedRows={selectedRows}
                       selectedCols={selectedCols}
                       newColumnName={newColumnName}/>
          )}
        </AutoSizer>
      </div>);
  }
}


interface IMitoExcelProps {
  error: string;
  loading: boolean;
  sheets: IMitoSheetModel[];
  sheetActive: string;

  activeSheet: (name: string) => void;

  selectedCols: string[] | null;
  selectedRows: number[] | null;
  onSelectCells: (selectedCols: string[] | null, selectedRows: number[] | null) => any;
  onAction: (action: IMitoAction) => any;
}


export const MitoExcel: React.FC<IMitoExcelProps> = ({
                                                       error,
                                                       loading,
                                                       sheets,
                                                       sheetActive,
                                                       activeSheet,
                                                       selectedCols,
                                                       selectedRows,
                                                       onSelectCells,
                                                       onAction,
                                                     }): JSX.Element => {
  // return null;
  if (error) return <section className="MitoExcel error">{error}</section>;
  const empty = sheets.length === 0;
  const sheet = sheets.find(s => s.title === sheetActive);

  return (
    // todo empty class
    <article className={cn('MitoExcel', {loading, empty})}>
      <section className="MitoExcel__Content">
        {!!activeSheet &&
        <MitoExcelSheet
          data={sheet?.data ?? null}
          selectedCols={selectedCols}
          selectedRows={selectedRows}
          setSelected={onSelectCells}
          onAction={onAction}
        />}
      </section>
      <footer className="MitoExcel__Footer">
        <div className="MitoExcel__FooterSheets">
          <ul className="MitoExcel__SheetsList">
            {sheets.map(sheet =>
              <li key={sheet.documentId + sheet.title} onClick={() => activeSheet(sheet.title)} title={sheet.title}
                  className={cn('MitoExcel__SheetsListSheet', {active: sheetActive === sheet.title})}>
                <span className="MitoExcel__SheetsListSheetTitle">{sheet.title}</span>
              </li>)}
          </ul>
        </div>
      </footer>
    </article>);
};
