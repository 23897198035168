/**
 *
 *
 *  base echarts vizel
 *
 *
 */

import React from 'react';
import './BaseVizelEcharts.scss';
import * as echarts from 'echarts';
import {BaseVizel} from './BaseVizel';
import {BaseVizelVC, IVizelVM} from '../../view-controllers/vizels/BaseVizelVC';
import {IVizelProps} from '../../services/ds/types';
import {extractErrorMessage} from '@luxms/bi-core';
import {drawImageEchartsCanvas} from '../../utils/saveAs';
import {ThemeVC} from '../../view-controllers/ThemeVC';
import {COLOR_PALETTE} from '../../utils/color-palette'

export abstract class BaseVizelEcharts<VM extends IVizelVM> extends BaseVizel<VM> {
  public _chart: any = null;
  public theme = null;

  protected constructor(vc: BaseVizelVC<VM>, props: IVizelProps) {          // TODO: pass ViewModel only
    super(vc, props);
  }

  protected abstract _getEchartsConfig(vmd: VM): any;

  protected abstract _updateChart(vmd: VM, prevVmd: VM): void;

  protected __createChart(vm: VM): any {
    if (!this._$container) throw new Error('No container');

    const themeTitle = this._getCurrentTheme();
    this._chart = echarts.init(this._$container[0], themeTitle, {renderer: 'svg'});        // create Echarts object
    const config: any = this._getEchartsConfig(vm);
    if (vm.noData) {
      this._chart.setOption({
        ...config,
        xAxis: {
          ...config.xAxis,
          show: false
        },
        yAxis: (config.yAxis || []).map(axis => {
          return {
            ...axis,
            show: false,
          };
        }),
      }, {replaceMerge: ['xAxis', 'yAxis']});
    } else {
      this._chart.setOption(config);
    }
    this._onChartCreated(this._chart);
    this._chart.on('click', this._onChartClick);
    this._chart.on('tap', this._onChartClick);
    this._chart.on('mouseover', this._onChartMouseOver);
    this._chart.on('legendselectchanged', this._onLegendClick.bind(this));
    this._chart.on('rendered', this._onChartDidMount.bind(this));
    this._chart.on('datazoom', this._onDataZoom.bind(this));
    this._setSaveAbilities(['png']);
    return this._chart;
  }
  protected _onChartDidMount(): any {

  }

  protected _getCurrentTheme = () => {
    const thisTheme = this.theme || null;
    const current = (thisTheme) ? this._createThemeConfig(thisTheme) : null;
    let themeTitle = null;
    if (current) {
      themeTitle = 'current-theme';
      echarts.registerTheme(themeTitle, current);
    }

    return themeTitle;
  }

  protected _createThemeConfig = (theme) => {
    let vizelsConfigSet = {};

    if (theme) {
      vizelsConfigSet = {
        gauge: {
          firstCircleColor: theme?.color4,
          firstCircleShadowColor: theme?.color18,
          firstCircleBorderColor: theme?.color11,
          secondCircleColor: theme?.color12,
          secondCircleShadowColor: theme?.color18,
          thirdCircleColor: theme?.color4,
          tickColor: theme?.color6,
          tickLabelColor: theme?.color1,
          mainFontColor: theme?.color1,
          mainValueFontColor: theme?.color1,
          descriptionFontColor: theme?.color1,
          // pointerShadowColor: theme?.shadow_button,
          pointerShadowColor: theme?.color18,
        },
        halfgauge: {
          firstCircleColor: theme?.color4,
          firstCircleShadowColor: theme?.color18,
          firstCircleBorderColor: theme?.color11,
          secondCircleColor: theme?.color12,
          secondCircleShadowColor: theme?.color18,
          thirdCircleColor: theme?.color4,
          tickColor: theme?.color6,
          tickLabelColor: theme?.color1,
          mainFontColor: theme?.color1,
          mainValueFontColor: theme?.color1,
          descriptionFontColor: theme?.color1,
          // pointerShadowColor: theme?.shadow_button,
          pointerShadowColor: theme?.color18,
        },
        backgroundColor: theme.color2,
        grid: {
          borderColor: 'transparent',
          borderWidth: 0,
        },
        tooltip: {
          backgroundColor: theme.color2
        },
        legend: {
          borderColor: 'rgba(178,34,34,0.8)',
          borderWidth: 0,
          pageIconInactiveColor: theme.color11,
          pageIconColor: theme.color1,
          pageTextStyle: {
            color: theme.color1,
          },
          textStyle: {
            color: theme.color1,
          }
        },
        valueAxis: {
          axisLine: {
            lineStyle: {
              color: theme?.color16,
            }
          },
          axisTick: {
            lineStyle: {
              color: theme?.color16,
            }
          },
          splitLine: {
            lineStyle: {
              color: [
                theme?.color16,
              ]
            },
          },
          axisLabel: {
            textStyle: {
              color: theme?.color16,
            },
          }
        },
        categoryAxis: {
          axisLine: {
            lineStyle: {
              color: theme?.color16,
            }
          },
          axisTick: {
            lineStyle: {
              color: theme?.color16,
            }
          },
          splitLine: {
            lineStyle: {
              color: [
                theme?.color12,
              ]
            },
          },
          axisLabel: {
            textStyle: {
              color: theme?.color16,
            },
          }
        },
        logAxis: {},
        timeAxis: {
          /*          axisLine: {
                      lineStyle: {
                        color: 'lime'
                      }
                    },
                    axisTick: {
                      lineStyle: {
                        color: 'lime'
                      }
                    },
                    axisLabel: {
                      color: 'lime'
                    },
                    splitLine: {
                      lineStyle: {
                        color: [
                          'lime'
                        ]
                      }
                    },
                    splitArea: {
                      areaStyle: {
                        color: [
                          'rgba(47,255,0,0.2)',
                          'rgba(47,255,0,0.2)',
                        ]
                      }
                    }*/
        },
       // series: [],
        bar: {
          backgroundStyle: {
            color: theme?.color11,
          },
          itemStyle: {
            borderColor: theme?.color2,
          },
          label: {
            color: theme?.color1,
            backgroundColor: theme?.color2,
            shadowColor: theme?.color18,
          },
          markPoint: {
            itemStyle: {
              color: theme?.color2,
              shadowColor: theme?.color18,
            },
            label: {
              color: theme?.color1,
              backgroundColor: theme?.color2,
              shadowColor: theme?.color18,
            },
          }

        },
        pie: {
          backgroundColor: theme?.color2,
        },
        visualMap: {
          textStyle: {
            color: theme.color1
          }
        },
        ...theme.themeBuilder,
      };
    }
    return vizelsConfigSet;
  }

  protected _onChartClick = (event): void => {
    // override
  }
  protected _onChartMouseOver = (params): void => {
    // override
  }
  protected _onLegendClick = (event): void => {
    // override
  }
  protected _onDataZoom(params, vm: any): any {
    // override
  }
  protected _onChartCreated(chart: any): void {
    // override
  }

  protected _viewModelChanged(vm: VM): void {
    if (!echarts) {
      return;                       // skip any changes while highcharts is not ready
    }
    super._viewModelChanged(vm);
  }

  public attached(container: HTMLElement): void {
    super.attached(container);
    const vm: VM = this._vc.getModel();
    this._viewModelChanged({
      ...(vm as any),                                 // need to copy as there is a check
    });
  }

  public componentDidMount(): void {
    ThemeVC.getInstance().subscribeUpdatesAndNotify(this._onThemeVCUpdated);
    this.attached(this.refs.container as any);
  }

  public componentWillUnmount() {
    // ReactDOM.unmountComponentAtNode();
    ThemeVC.getInstance().unsubscribe(this._onThemeVCUpdated);
    this._dispose();
  }

  public componentDidUpdate(prevProps: Readonly<IVizelProps>, prevState: Readonly<{}>) {
    if (prevProps.subspace !== this.props.subspace) {
      this.setAxes(this.props.subspace);
    }
  }

  protected _dispose(): void {
    if (this._chart) {
      this._chart.clear();
      this._chart = null;
    }
    super._dispose();
  }

  protected _onError(err: any): void {
    try {
      if (this._chart) {
        this._chart.clear();
        this._chart = null;
      }
    } catch (err) {
      //
    }

    this._$container
      .removeClass('no-data')
      .removeClass('loading')
      .removeClass('loadingFirstTime')
      .empty()
      .addClass('error')
      .text(extractErrorMessage(err));
  }

  // override
  protected _viewModelDataChanged(vm: VM, prevVM: VM): void {
    super._viewModelDataChanged(vm, prevVM);

    if (!echarts) {          // not loaded yet => cannot update
      return;
    }

    if (vm == prevVM) {
      return;
    }
    if (vm == null) {     // destroy
      this._chart?.clear();
      this._chart = null;
      return;
    } else if (vm.noData) {
      this._chart?.clear();
    }
    // vmd != null
    if (this._chart) {
      this._updateChart(vm, prevVM);
    } else {
      this.__createChart(vm);
    }
  }

  private _onThemeVCUpdated = () => {
    this.theme = ThemeVC.getInstance().getModel().currentTheme;
    this._reInitChart();
  }

  protected _reInitChart = () => {
    if (!this._chart || !this._$container) {
      return;
    }
    this._chart.dispose();
    const vm: VM = this._vc.getModel();

    this.__createChart(vm);
    this._updateChart(vm, vm);
  }

  public resize(width?: number, height?: number): void {
    if (!this._chart || !this._$container) {
      return;
    }
    this._chart.resize();
  }

  public setProperties(o: { [id: string]: any }): boolean {
    let result: boolean = super.setProperties(o);
    if (result && this._chart) {
      this._chart.resize();
    }
    return result;
  }

  // TODO: getProperties: saveability

  public save(saveAbility: string, titleContext: string[], $anchor?: JQuery): void {
    drawImageEchartsCanvas(null, this._chart, titleContext);
  }

  public render() {
    return (
      <div ref="container" onClick={e => this._onClickChart(e)} className="VizelEcharts Vizel view vizel plot"/>
    );
  }
}

export default BaseVizelEcharts;
