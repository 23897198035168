import { BaseEntitiesService, IBaseEntities } from '@luxms/bi-core';
import { IRawLocationAreas, LocationAreasRepository } from '../../repositories/LocationAreasRepository';


export class LocationAreasService extends BaseEntitiesService<IRawLocationAreas> {
  public static readonly MODEL: IBaseEntities<IRawLocationAreas>;

  public constructor(schema_name: string) {
    super(new LocationAreasRepository(schema_name));
  }
}