import { BaseService, createSingleton, UrlState, IUrl } from '@luxms/bi-core';


export interface ISearchVM {
  readonly loading: boolean;
  readonly error: string;
  readonly search: string;
}

/**
 * @class
 * @instance
 * @description Сервис хранит ввод пользователя в "поиск", используется в Наборе данных. Использует внутри подписку на UrlState (При смене url, search:null) *
 */
export class SearchVC extends BaseService<ISearchVM> {
  private readonly _urlState: UrlState;

  public static getInstance = createSingleton<SearchVC>(() => new SearchVC(), '__searchVC');

  protected constructor() {
    super({
      loading: false,
      error: null,
      search: null,
    });
    this._urlState = UrlState.getInstance();
    this._urlState.subscribeUpdatesAndNotify(this._updateUrl);
  }

  private _updateUrl = (model: IUrl): void => {
    if (model?.loading || model?.error) return;
    this._updateModel({loading: false, search: null});
  }

  public setSearch(search: string): void {
    // todo debounce
    this._updateModel({search});
  }

  protected _dispose() {
    super._dispose();
  }

}