import * as React from 'react';
import {ILocation} from "../../../defs/bi";

class NullLegendR extends React.Component<any> implements IVMLegend {
  public hiliteLocation(l: ILocation): void {
    //
  }

  public dispose() {
    ////
  }

  public render() {
    return(<article className="legend" style={{padding: '11px 11px 16px 12px', minHeight: '50px'}}>
      <div>&nbsp;</div>
      <div
        style={{
          position: 'relative',
          height: '20px',
          margin: '10px auto 20px auto',
          width: '330px',
          background: 'linear-gradient(to right, #ccc 0, #ddd 20%, #ccc 40%, #ddd 60%, #ccc 80%, #ddd 100%)',
        }}>
      </div>
    </article>);
  }
}

export default NullLegendR;