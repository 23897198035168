
/**
 * Extract and parse url params of string line "...?key1=value1&key1=value2"
 *
 * @param url
 */
export function urlExtractParams(url: string): any {
  const [_, pathname, query] = url.match(/^(.*?)(?:\?(.*))?$/);
  const params = {};
  (query || '').split('&').forEach(pair => {
    if (pair.match(/^([^=]+)(?:=(.*))?$/)) {
      let key = RegExp.$1, value =  RegExp.$2 || '';
      try { key = decodeURIComponent(key); } catch (err) { /**/ }
      try { value = decodeURIComponent(value); } catch (err) { /**/ }
      params[key] = value;
    }
  });
  return params;
}

export function replaceNavigateWithSubstitutions(eventDescription: string, repl): any {
  const replacedEventDescription = eventDescription.replace(/%(\d*)([a-zA-Z]+)/g, (fld, number, entityName) => {
    if (!(entityName in repl)) {                                              // does not have such letter
      return `%${number}${entityName}`;
    }

    if (number) {
      const n = parseInt(number);
      let s = encodeURIComponent(repl[entityName]);
      if (s.length > number) s = s.substr(0, n - 3) + '...';
      return s;
    } else {
      return encodeURIComponent(repl[entityName]);
    }
  });
  const params = urlExtractParams(replacedEventDescription);
  return params;
}
