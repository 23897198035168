import { repo } from '@luxms/bi-core';

export interface IRawLocationAreas {
  readonly id: number;
  readonly loc_id: number;
  readonly title: string;
  readonly wkt: string;
  readonly config: any;
  readonly created: string;
  readonly updated: string;
}

export class LocationAreasRepository extends repo.BaseRepository<IRawLocationAreas> {
  public constructor(schema_name: string) {
    super(schema_name, 'location_areas');
  }
}

