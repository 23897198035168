import { BaseEntitiesService, createSingleton, IBaseEntities, repo } from '@luxms/bi-core';

interface IUsersDatasetsMapRepositoryVM {
  id: number;
  user_id: number;
  dataset_id: number;
  effect: string;
  op: string;
}

class UsersDatasetsMapRepository extends repo.BaseRepository<IUsersDatasetsMapRepositoryVM> {
  public constructor(filter?: any) {
    super('adm', 'user_dataset_maps', filter);
  }
}

export class UsersDatasetsMapService extends BaseEntitiesService<IUsersDatasetsMapRepositoryVM> {
  public static readonly MODEL: IBaseEntities<IUsersDatasetsMapRepositoryVM>

  public constructor(filter?: any) {
    super(new UsersDatasetsMapRepository(filter));
  }

  public static getInstance: () => UsersDatasetsMapService = createSingleton<UsersDatasetsMapService>(() => new UsersDatasetsMapService(), '__usersDatasetMapService');
}
