import React from 'react';
import { IVizelProps } from '../../../services/ds/types';
import { fixViewClass, lang } from '../../../utils/utils';
import { loadVizel } from '../../../utils/loadVizel';
import { ISubspace, IVizel, IVizelListener, IVizelProperties } from '../../../defs/bi';
import './Vizel.scss';
import { extractErrorMessage } from '@luxms/bi-core';
import isEqual from 'lodash/isEqual';


export class Vizel extends React.Component<IVizelProps> implements IVizel {
  public state: {
    error: string;
    loading: boolean;
    VizelClass: any;
    autoincrement: number;                                                                          // будем использовать эту переменную как key и менять кааждый раз, когда cfg меняется
  } = {
    error: null,
    loading: true,
    VizelClass: null,
    autoincrement: 1,
  };
  private _listener: IVizelListener = null;
  private _properties: IVizelProperties = {saveAbilities: []};
  private _vizelInstance: any = null;

  public save(saveAbility: string, titleContext: string[], $anchor?: JQuery): void {
    if (this._vizelInstance && this._vizelInstance.save) {
      this._vizelInstance.save(saveAbility, titleContext, $anchor);
    }
  }

  public resize(width?: number, height?: number): void {
    if (this._vizelInstance && this._vizelInstance.resize) {
      this._vizelInstance.resize(width, height);
    }
  }

  public onVizelPropertiesChanged(props: IVizelProperties, vzl?: IVizel): void {
    if (this._listener) {
      this._listener.onVizelPropertiesChanged(props, vzl);
    }
  }

  public setAxes(subspace: ISubspace): Promise<any> {
    throw new Error('Vizel::setAxes deprecated');
  }

  public getProperties(): IVizelProperties {
    if (this._vizelInstance && this._vizelInstance.getProperties) {
      return this._vizelInstance.getProperties();
    }
    return this._properties;
  }

  public setProperties(o: { [id: string]: any }): boolean {
    this._properties = {...this._properties, ...o};
    if (this._vizelInstance && this._vizelInstance.setProperties) {
      return this._vizelInstance.setProperties(o);
    }
    return false;
  }

  public addListener(listener: IVizelListener): void {
    this._listener = listener;
  }

  public removeListener(listener: IVizelListener): void {
    if (this._listener === listener) {
      this._listener = null;
    }
  }

  public async componentDidMount() {
    const { dp, cfg, subspace} = this.props;
    if (!(dp && cfg && subspace)) return;

    const chartStyle: string = cfg.getRaw().chartStyle;
    const view_class = fixViewClass(cfg.getVizelType(), chartStyle);

    try {
      const VizelClass: any = await loadVizel(view_class, cfg.dataset.schema_name);
      this.setState({error: null, loading: false, VizelClass});

    } catch (err) {
      // this.setState({error: extractErrorMessage(err), loading: false, VizelClass: null});
      this.setState({error: lang('error.noSuchVizel') + ': ' + String(view_class), loading: false, VizelClass: null});
    }
  }

  private _setupVizelRef = (ref: any) => {
    this._vizelInstance = ref;

    if (this._vizelInstance) {
      if (this._vizelInstance.addListener) {
        this._vizelInstance.addListener(this);
      }
      if (this._vizelInstance.setProperties) {
        this._vizelInstance.setProperties(this._properties);
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IVizelProps>, nextContext: any) {
    if (this.props.cfg !== nextProps.cfg) {
      // пока визели не понимают изменение cfg требуется их пересоздать
      // если поменять значение key то React пересоздаст инстанс
      // такое происходит при редактировании конфига, но не должно случаться часто
      // поэтому оставим warn для отслеживания таких случаев
      console.warn('vizel.cfg changed');
      this.setState({autoincrement: this.state.autoincrement + 1});
    }

    if (this.props.cfg.getVizelType() !== nextProps.cfg.getVizelType()) {
      this.setState({error: null, loading: true, VizelClass: null}, () => {
        const view_class = nextProps.cfg.getVizelType();
        loadVizel(view_class, this.props.cfg.dataset.schema_name)
          .then((VizelClass: any) => {
            this.setState({error: null, loading: false, VizelClass});
          }).catch(err => {
            // this.setState({error: extractErrorMessage(err), loading: false, VizelClass: null});
            this.setState({error: lang('error.noSuchVizel') + ': ' + String(view_class), loading: false, VizelClass: null});
          });
      });
    }
    if (!isEqual(this.props.properties, nextProps.properties)) {
      // отменил перерисовку дэша в полноэкранном режиме
      // чтобы selectedPlotType не сбрасывался
      if (nextProps.properties.hasOwnProperty('fullScreen')) return;
      // для ресайза графиков на трендах
      this.setState({autoincrement: this.state.autoincrement + 1});
    }
  }

  public render() {
    const { error, loading, VizelClass, autoincrement } = this.state;

    // if (error) {
    //   return null;
    //   return <div className="Vizel error">{error}</div>;
    // }
    //
    // if (loading) {
    //   return null;
    //   return <div className="Vizel loading loadingFirstTime"/>;
    // }
    if (error) return this.props.renderError ?  this.props.renderError(error) : <div className="Vizel error">{error}</div>;

    if (loading) return this.props.renderLoading ?  this.props.renderLoading(loading) : <div className="Vizel loading loadingFirstTime"/>;

    if (VizelClass) {
      return (
        <VizelClass ref={this._setupVizelRef}
                    key={autoincrement}
                    {...this.props} />);
    } else {
      return null;                                                                                  // TODO: pass renderLoading
    }
  }
}

export default Vizel;
