/**
 *
 *
 *
 */

import { BaseService, createSingleton, AppConfig, extractErrorMessage, AuthenticationService, IAuthentication } from '@luxms/bi-core';
import { httpGet } from '../repositories/http/data-storage';


export interface IRawSummary {
  chats: {
    count: number;
  };
  'datasets': {
    count: number;
  };
  'tasks-to': {
    count: number;
  };
  'tasks-from': {
    count: number;
    active_count: number;
    expired_count: number;
  };
  'tasks-self': {
    count: number;
  };
  presentations: {
    count: number;
  };
  // additional
  ds: {
    count: number;
  };
}


export interface ISummaryModel {
  loading: boolean;
  error: string;
  data: IRawSummary;
}


export class SummaryService extends BaseService<ISummaryModel> {

  public constructor() {
    super({
      loading: true,
      error: null,
      data: null,
    });
    AuthenticationService.subscribeUpdatesAndNotify(this._reload);
  }

  private _reload = async () => {
    const authModel: IAuthentication = AuthenticationService.getModel();
    if (authModel.error) return this._updateWithError(authModel.error);
    if (authModel.loading) return this._updateWithLoading();
    if (!authModel.authenticated) return this._updateWithError(AuthenticationService.NOT_AUTHENTICATED);

    try {
      const url = AppConfig.fixRequestUrl(`/api/summary`);
      const rawData: IRawSummary = await httpGet<IRawSummary>(url);
      rawData.ds = rawData.datasets;                                            // it is called 'ds'
      this._setModel({
        loading: false,
        error: null,
        data: rawData,
      });
    } catch (err) {
      this._updateWithError(extractErrorMessage(err));
    }
  };

  public static getInstance = createSingleton<SummaryService>(() => new SummaryService(), '__summaryService');

  public static getModel(): ISummaryModel {
    return this.getInstance().getModel();
  }

  public static subscribe(event: string, listener: any): IDisposable {
    return this.getInstance().subscribe(event, listener);
  }

  public static subscribeUpdatesAndNotify(listener: (model: ISummaryModel) => void): IDisposable {
    return this.getInstance().subscribeUpdatesAndNotify(listener);
  }

  public static subscribeUpdates(listener: (model: ISummaryModel) => void): IDisposable {
    return this.getInstance().subscribeUpdates(listener);
  }

  public static unsubscribe(listener: (...args: any[]) => any): boolean {
    return this.getInstance().unsubscribe(listener);
  }
}

