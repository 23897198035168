import { httpGet, httpPost } from '../repositories/http/data-storage';
import { AppConfig } from '@luxms/bi-core';
import { target } from '../../srx/controllers/dataset/dashboard-builder/selectors';

const BASE_URL = '/api/qrpc';

export interface IServiceInfo {
  service: string;
  method: string;
  paramNames: string[];
  endpoint?: string;
  discoveryName: string;
}

export interface IServiceRequest {
  service: string;
  method: string;
  params: any[];
}

export interface IQueuedJob {
  jobRequest: IServiceRequest;
  id: string;
  state: string;
  queuedAt: number;
  startedAt: number;
  doneAt: number;
  message?: string;
  done: boolean;
}

export class QRPCService {

  public async getServices(): Promise<IServiceInfo[]> {
    await AppConfig.getInstance().whenReady();
    const res: IServiceInfo[] = await httpGet<IServiceInfo[]>(AppConfig.fixRequestUrl(BASE_URL + '/services'));
    return res.sort((a, b) => {
      if (a.discoveryName > b.discoveryName) return  1;
      if (a.discoveryName < b.discoveryName) return -1;
      return 0;
    });
  }

  public async execute(request: IServiceRequest): Promise<any> {
    await AppConfig.getInstance().whenReady();
    const result: any = await httpPost<any>(AppConfig.fixRequestUrl(BASE_URL + '/executeSync'), request);
    return result;
  }

  public async executeAsync(request: IServiceRequest): Promise<any> {
    const timing = [0, 1, 2, 2, 4, 4, 8];
    await AppConfig.getInstance().whenReady();
    let queuedJob: IQueuedJob = await httpPost<IQueuedJob>(AppConfig.fixRequestUrl(BASE_URL + '/execute'), request);
    console.log('Execute job', queuedJob);

    const checkStatus = async (): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        if (queuedJob.done) {
          resolve(true);
        } else {
          const timeout: number = (timing.length == 1 ? timing[0] : timing.shift());
          setTimeout(async () => {
            const url: string = AppConfig.fixRequestUrl(BASE_URL + '/jobs?id=' + queuedJob.id);
            const res: IQueuedJob[] = await httpGet<IQueuedJob[]>(url);
            if (res.length != 1) {
              reject('Job Error');
            } else {
              queuedJob = res[0];
              resolve(await checkStatus());
            }
          }, 1000 * timeout);
        }
      });
    };

    await checkStatus();
    console.log('Job completed', queuedJob);
    const url: string = AppConfig.fixRequestUrl(BASE_URL + '/result?id=' + queuedJob.id);
    const result: any = await httpGet(url);
    return result;
  }
}

const qrpc = (name: string) => {
  return (cls: any) => qrpcmethod(cls, name);
};

const qrpcmethod = (cls: any, classname: string) => {
  Object.keys(cls).forEach(methodname => {
    cls[methodname] = async function (...args) {
      const req = {
        service: classname,
        method: methodname,
        params: args,
      };
      const qrpc = new QRPCService();
      const result = await qrpc.execute(req);
      return result;
    };
  });
};


interface ISchema {
  '@class': 'com.luxms.bi.mdm.jdbc.elements.Schema';
  facets: {sourceId: string; elementType: 'schema'; elementName: string; };
  name: string;
  sourceId: string;
  uri: string;
}


@qrpc('DataSourceInspectorService')
export class DataSourceInspectorService {
  public static sampleFirstRows(id: string, sql: string, limit: number, offset: number): Promise<any> {
    throw new Error('Error');
  }

  public static getSchemas(ident: string): Promise<ISchema[]> {
    throw new Error('Error');
  }

  public static getTables(ident: string, tableName: string): Promise<any[]> {
    throw new Error('Error');
  }

  public static getDocuments(ident: string): Promise<any> {
    throw new Error('Error');
  }

  public static dropDocument(sourceId: string, documentId: string): Promise<any> {
    throw new Error('Error');
  }

  public static createDataSource(sourceId: string, query: string, newSourceId: string, newSourceTitle: string): Promise<any> {
    throw new Error('Error');
  }

  public static getTableDetails(sourceId: string, schema: string, table: string): Promise<any> {
    throw new Error('Error');
  }
}

