/**
 *
 *
 *
 */

import $ from 'jquery';
import { fixColorPairEx } from '../../config/ColorPair';
import { coloring, formatNum } from '../../utils/utils';


let SIZE: number = 100;


export class Chart {

  public static TYPE_COLUMN: string = 'columns';
  public static TYPE_BAR: string = 'bars';
  public static TYPE_PIE: string = 'pies';

  private _cnv: CanvasRenderingContext2D = null;
  private _metrics: IMetric[] = null;
  private _vector: IValue[] = null;
  private _domain: number[] = null;
  private _currentType: string;
  private readonly _canvas: HTMLCanvasElement;

  public constructor(private $container = null) {
    const c: HTMLCanvasElement = document.createElement('canvas');
    c.width = SIZE;
    c.height = SIZE;
    if (this.$container) this.$container.append(c);
    this._cnv = c.getContext('2d');
    this._canvas = c;
  }

  public getCanvasElement(): HTMLCanvasElement {
    return this._canvas;
  }

  public setData(metrics: IMetric[], vector: IValue[], domain: number[]): void {
    this._metrics = metrics;
    this._vector = vector;
    this._domain = domain;
    this.redraw();
  }

  public clear(): void {
    this._cnv.clearRect(0, 0, SIZE, SIZE);
  }

  public setType(value: string): void {
    this._currentType = value;
    if (this._vector == null) return;
    this.redraw();
  }

  public redraw(): void {
    this.clear();
    if (this._currentType == Chart.TYPE_COLUMN) {
      this.redrawAsColumn();
    } else if (this._currentType == Chart.TYPE_PIE) {
      this.redrawAsPie();
    } else if (this._currentType == Chart.TYPE_BAR) {
      this.redrawAsBar();
    } else {
      throw new Error('Unknown chart type - ' + String(this._currentType));
    }
  }

  public redrawAsPie(): void {
    const vector: number[] = this._vector.map(v => typeof v === 'number' ? Math.abs(v) : v) as number[];
    let sum: number = 0;
    vector.forEach((e: number) => sum += e);
    let start: number = 3 / 2 * Math.PI;
    const p: number = SIZE >> 1;

    let drawSinglePercent = false;

    if (vector.length == 1
      && this._metrics[0].unit
      && this._metrics[0].unit['unit'] == 'PERCENT') {
      drawSinglePercent = true;
      this._cnv.fillStyle = 'rgba(0, 0, 0, 0.2)';
      this._cnv.beginPath();
      this._cnv.moveTo(p, p);
      this._cnv.arc(p, p, p, 0, Math.PI * 2);
      this._cnv.lineTo(p, p);
      this._cnv.fill();
    }

    this._metrics.forEach((e, i) => {
      let arc = 2 * Math.PI;
      if (drawSinglePercent) {
        arc *= (vector[i] / 100);
      } else {
        arc *= (vector[i] / sum);
      }
      this._cnv.fillStyle = e.color;
      this._cnv.beginPath();
      this._cnv.moveTo(p, p);
      this._cnv.arc(p, p, p, start, start + arc);
      this._cnv.lineTo(p, p);
      this._cnv.fill();
      start += arc;
    });
    this.$container.find('div').remove();
  }

  public redrawAsColumn(): void {
    const colW: number = SIZE / this._vector.length;
    let colH: number = 0;
    let x: number = 0;

    this._metrics.forEach((e, i) => {
      this._cnv.fillStyle = e.color;
      colH = SIZE * ((this._vector[i] as number - this._domain[0]) / (this._domain[1] - this._domain[0]));
      this._cnv.fillRect(x, SIZE - colH, colW, colH);
      x += colW;
    });

    this.$container.find('div').remove();
  }

  public redrawAsBar(): void {
    const colH: number = SIZE / this._vector.length;
    let colW: number = 0;
    let y: number = 0;

    this.$container.find('div').remove();

    this._metrics.forEach((e: IMetric, i: number) => {
      this._cnv.fillStyle = e.color;
      colW = SIZE * ((this._vector[i] as number - this._domain[0]) / (this._domain[1] - this._domain[0]));
      this._cnv.fillRect(0, y, colW, colH);

      this.$container.append(
        $('<div></div>')
          .text(formatNum(this._vector[i]))
          .css({
            'position': 'absolute',
            'right': '100%',
            'top': (y) + 'px',
            'width': 'auto',
            'color': 'black',
            'background-color': 'rgba(255, 255, 255, 0.5)',
            'padding': '0 5px',
            'height': colH + 'px',
            'white-space': 'nowrap',
            'text-align': 'right',
            'vertical-align': 'right',
            'line-height': colH + 'px',
            'font-size': Math.min(14, colH * 0.8) + 'px',
          }));

      y += colH;
    });
  }

  public setContainer($container: JQuery): void {
    this.$container = $container;
  }
}

export class MapFillColorizer implements IColorResolver {
  private readonly _min: number;
  private readonly _max: number;

  public constructor(min: number, max: number, private rgbMin: [number, number, number], private rgbMax: [number, number, number]) {
    this._min = min;
    this._max = max;
  }

  public getColorPair(e: IEntity, v: number): IColorPair {
    if (v == null) {
      return fixColorPairEx('#ffffff;#ffffff');
    }
    const ratio: number = (v - this._min) / (this._max - this._min);
    const hsvMin: coloring.HSVColor = new coloring.RGBColor(this.rgbMin[0], this.rgbMin[1], this.rgbMin[2]).toHSV();
    const hsvMax: coloring.HSVColor = new coloring.RGBColor(this.rgbMax[0], this.rgbMax[1], this.rgbMax[2]).toHSV();
    const hsv: coloring.HSVColor = coloring.HSVColor.interpolateHSV(hsvMin, hsvMax, ratio);
    const rgb: coloring.RGBColor = hsv.toRGB();
    return fixColorPairEx(rgb.toString());
  }

  public getColor(e: IEntity, v: number): string {
    return this.getColorPair(e, v).color;
  }

  public getBgColor(e: IEntity, v: number): string {
    return this.getColorPair(e, v).bgColor;
  }
}

export function setSize(size: number) {
  SIZE = size;
}
