/// <reference path="../../defs/bi.d.ts" />

import axios from 'axios';


function readErrorMessage(err: any): string {
  let errorMessage: string = 'Unknown error';
  try {
    if (err.isAxiosError) {
      const response = err.response;
      errorMessage = response.statusText;
      const {key, type, message} = response.data;
      // TODO: check DATASET_ACCESS_DENIED
      if (key === 'INVALID_SESSION') {
        errorMessage = 'Not authenticated';                                                         // TODO: lang
      } else {
        errorMessage = message;
      }
    }
  } catch (err) {
    // ???
  }

  return errorMessage;
}


export async function httpGet<T>(url: string): Promise<T> {
  try {
    return (await axios.get(url)).data;
  } catch (err) {
    throw new Error(readErrorMessage(err));
  }
}


export async function httpPost<T>(url: string, body: any): Promise<T> {
  try {
    return (await axios.post(url, body)).data;
  } catch (err) {
    throw new Error(readErrorMessage(err));
  }
}


export async function httpPut<T>(url: string, body: any): Promise<T> {
  try {
    return (await axios.put(url, body)).data;
  } catch (err) {
    throw new Error(readErrorMessage(err));
  }
}


export async function httpDelete<T>(url: string): Promise<T> {
  try {
    return (await axios.delete(url)).data;
  } catch (err) {
    throw new Error(readErrorMessage(err));
  }
}
