const durationRegexp = /^([-+])?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?(?:T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?$/;

interface IUnpackedDuration {
  sign: number;
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

function parseISO8601Duration(iso8601Duration: string): IUnpackedDuration {
  const matches = iso8601Duration.match(durationRegexp);
  const sign = matches[1] === '-' ? -1 : +1;

  return {
    sign,
    years: matches[2] === undefined ? 0 : matches[2] * sign,
    months: matches[3] === undefined ? 0 : matches[3] * sign,
    weeks: matches[4] === undefined ? 0 : matches[4] * sign,
    days: matches[5] === undefined ? 0 : matches[5] * sign,
    hours: matches[6] === undefined ? 0 : matches[6] * sign,
    minutes: matches[7] === undefined ? 0 : matches[7] * sign,
    seconds: matches[8] === undefined ? 0 : matches[8] * sign,
  };
}


const iso8601Regexp = /^(\d\d\d\d)(?:-(\d\d)(?:-(\d\d)(?:[T ](\d\d)(?::(\d\d)(?::(\d\d))?)?)?)?)?/;

interface IUnpacked {
  Y: number;
  M: number;
  D: number;
  h: number;
  m: number;
  s: number;
}

function unpack(iso8601): IUnpacked {
  let matches = iso8601.match(iso8601Regexp);
  return {
    // Y: +matches[1],
    // M: matches[2] === undefined ? null : +matches[2],
    // D: matches[3] === undefined ? null : +matches[3],
    // h: matches[4] === undefined ? null : +matches[4],
    // m: matches[5] === undefined ? null : +matches[5],
    // s: matches[6] === undefined ? null : +matches[6],
    Y: +matches[1],
    M: matches[2] === undefined ? 1 : +matches[2],
    D: matches[3] === undefined ? 1 : +matches[3],
    h: matches[4] === undefined ? 0 : +matches[4],
    m: matches[5] === undefined ? 0 : +matches[5],
    s: matches[6] === undefined ? 0 : +matches[6],
  };
}

const l2 = (n) => 9 < n ? String(n) : '0' + n;

function pack(d) {
  return `${d.Y}-${l2(d.M)}-${l2(d.D)} ${l2(d.h)}:${l2(d.m)}:${l2(d.s)}`;
}

const isLeap = (Y) => (Y % 400 === 0 || Y % 100 !== 0) && Y % 4 === 0;
const DAYS_PER_MONTH = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
function getDaysInMonth(year, month) {
  while (month < 1) { year -= 1; month += 12; }
  while (month > 12) { year += 1; month -= 12; }
  return DAYS_PER_MONTH[month] + (month === 2 && isLeap(year));
}

function fixToRange<T>(obj: T, key1: keyof T, key2: keyof T, from: number, to: number) {
  if (obj[key2] === null) return;
  while (obj[key2] < from) { obj[key1]--; obj[key2] += to - from; }
  while (obj[key2] >= to) { obj[key1]++; obj[key2] -= to - from; }
  return obj;
}

function fix(d: IUnpacked): IUnpacked {
  fixToRange(d, 'm', 's', 0, 60);
  fixToRange(d, 'h', 'm', 0, 60);
  fixToRange(d, 'D', 'h', 0, 25);
  if (d.D !== null) {
    while (d.D < 1) { d.M--; d.D += getDaysInMonth(d.Y, d.M); }
    while (d.D > getDaysInMonth(d.Y, d.M)) { d.D -= getDaysInMonth(d.Y, d.M); d.M++; }
  }
  fixToRange(d, 'Y', 'M', 1, 13);                                                                   // will be no problem with D as it is fixed before
  return d;
}

function fixMonthDays(d: IUnpacked): IUnpacked {
  if (d.D !== null) d.D = Math.min(d.D, getDaysInMonth(d.Y, d.M));
  return d;
}


export function dplus(date, duration) {
  let d = fix(unpack(date));
  let dd = parseISO8601Duration(duration);
  if (dd.years) d.Y += dd.years;
  fixMonthDays(d);                                                                                  // may become invalid if year was leap
  if (dd.months) d.M += dd.months;
  fixMonthDays(d);
  if (dd.weeks) d.D += 7 * dd.weeks;
  if (dd.days) d.D += dd.days;
  if (dd.hours) d.h += dd.hours;
  if (dd.minutes) d.m += dd.minutes;
  if (dd.seconds) d.h += dd.seconds;
  fix(d);
  return pack(d);
}
