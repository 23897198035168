import React, { createRef, useCallback, useState } from 'react';
import './CreateKoobStep1.scss';
import cn from 'classnames';
import { BaseService, extractErrorMessage, repo, srv } from '@luxms/bi-core';
import {DataSourceInspectorService, QRPCService} from '../../../services/QRPCService';
import VirtualList from '../VirtualList/VirtualList';
import {
  IconArrow,
  IconCH1,
  IconExcel1,
  IconHelp,
  IconMSSQL1,
  IconMySQL1,
  IconNone1,
  IconOracle1,
  IconPlus,
  IconPostgresql,
  IconSearch1, IconWarning,
} from '../data_sources/icons';
import db_relations from '../DbRelations/services';
import {$eid, $eidx} from '../../../libs/imdas/list';
import DbRelations, {IDbLink} from '../DbRelations/DbRelations';
import {formSqlExpression, isDbRelationsConnected} from '../DbRelations/DbRelationsUtils';
import TextEditor from '../TextEditor';
import {Button} from '@luxms/bi-face';
import {lang, search} from '../../../utils/utils';
import useService from '../../useService';
import createService from '../../../services/createService';
import CanIService from '../../../services/CanIService';
import IfICan from '../../helpers/IfICan';
import ContextMenu  from '../ContextMenu/ContextMenu';
import AlertsVC from '../../../view-controllers/AlertsVC';


// [esix] Надо как-то по-умному вынести эти сервисы, потому что все захотят ими пользоваться
// вместо того, чтобы грузить пару adm.DataSourcesService и ds.adm.DataSourcesService
const DataSourcesService = createService(null, ({useService, useServiceItselfWithCustomSubscription}, schema_name: string | null) => {
  const globalClaim = `L adm.data_sources`;
  const localClaim = `L ${schema_name}.data_sources`;
  const claims = schema_name ? [globalClaim, localClaim] : [globalClaim];                           // нет атласа - смотрим только глобальне

  const canI: CanIService = useServiceItselfWithCustomSubscription(CanIService, claims);

  if (claims.some(claim => canI.can(claim) === undefined)) {                                        // какой-то клэйм из нужных не загружен => дожидаемся
    canI.ensure(claims);
    return Object.assign([], {error: null, loading: true});
  }

  const globalDataSources = canI.can(globalClaim) ? useService(srv.adm.DataSourcesService) : [];    // м.б. если нет обоих, то error-ить?
  const localDataSources = canI.can(localClaim) && schema_name ? useService(srv.ds.DataSourcesService, schema_name) : [];

  if (globalDataSources.error || localDataSources.error) return Object.assign([], {error: globalDataSources.error || localDataSources.error, loading: false});
  if (globalDataSources.loading || localDataSources.loading) return Object.assign([], {error: null, loading: true});

  return Object.assign(
    [...localDataSources.filter(ds => ds.is_global === 0), ...globalDataSources],
    {error: null, loading: false});
});


interface IDataSourcesSelectorProps {
  readonly selectedSourceId: string | null;
  readonly schema_name: string | null;
  readonly onChange: (ident: string) => any;
  readonly onClickCreate: () => any;
}

const DataSourcesSelector = ({selectedSourceId, schema_name, onChange, onClickCreate}: IDataSourcesSelectorProps) => {
  const [searchSource, setSearchSource] = useState('');
  // const allDataSources = useService<srv.adm.DataSourcesService>(srv.adm.DataSourcesService);
  const allDataSources = useService<srv.adm.DataSourcesService>(DataSourcesService, schema_name);     // совмещенный сервис (локальные + глобальные)

  const renderDataSource = useCallback((item: repo.adm.IRawDataSource): JSX.Element => {
    const isGlobal = (item as any).is_global !== 0;                                                // TODO: типизировать is_global
    const ident = isGlobal ? item.ident : `source://connector/${item.ident}?atlas=${schema_name}`;
    // для глобальных data_source продолжаем использовать старые id-шки для обратной совместимости
    // для локальных - используем URI
    // В будущем для всех data_source будет uri

    const selected = selectedSourceId === ident;
    const dataSourceSchemaName = isGlobal ? 'adm' : schema_name;

    const onRemoveDataSource = async () => {
      const svc = isGlobal ? srv.adm.DataSourcesService.getInstance() : srv.ds.DataSourcesService.createInstance(schema_name);
      try {
        await svc.whenReady();
        await svc.remove(item.id);
        AlertsVC.pushInfoAlert('Источник данных удален');
      } catch (err) {
        AlertsVC.pushDangerAlert(extractErrorMessage(err));
      } finally {
        if (!isGlobal) svc.release();
      }
    };

    return (
      <div className={cn('CreateKoobStep1__Left__DataSource-Item', {selected})}
           onClick={() => onChange(ident)}>
        <IfICan oneOf={[`D ${dataSourceSchemaName}.data_sources/${item.id}`]}>     {/* TODO: поместить IfICan вокруг Item! Сейчас не сработает, потому что он не рисуется и не проверяется */}
          <ContextMenu.Here>
            <ContextMenu.Item title="Удалить" action={onRemoveDataSource}/>
          </ContextMenu.Here>
        </IfICan>
        <span>{getIcon(item.url)}</span>
        {item.title}
      </div>);
  }, [selectedSourceId, onChange, schema_name]);

  if (allDataSources.error) return <div className="error">{allDataSources.error}</div>;
  if (allDataSources.loading) return null;

  const dataSources = allDataSources.filter(d => d?.id);                                            // ??
  const renderSources = dataSources.filter((d) => search(d.title, searchSource));

  return (
    <>
      <header>
        <div className="CreateKoobStep1__Title">
          <p>Источники данных</p>

          <IfICan oneOf={schema_name ? [`C adm.data_sources`, `C ${schema_name}.data_sources`] : [`C adm.data_sources`]}>
            <span onClick={onClickCreate}>
              <Button size="sm" width="fit-content" variant="primary" icon={<IconPlus/>}>{''}</Button>
            </span>
          </IfICan>
        </div>

        <div className="CreateKoobStep1__Search">
          <IconSearch1/>
          <input type="text"
                 placeholder="Поиск"
                 value={searchSource}
                 onChange={e => setSearchSource(e.target.value)}/>
        </div>
      </header>
      <main>
        <div className="CreateKoobStep1__Wrapper">
          <VirtualList items={renderSources}
                       renderItem={renderDataSource}/>
        </div>
      </main>
    </>);
};


interface ICreateKoobStep1Props {
  readonly schema_name: string;
  readonly onChange: (sourceId: string, sqlExpression: string, id: string) => void;
  readonly openCreate: () => void;
  readonly newSourceId: string;
}

interface ICreateKoobStep1State {
  readonly schemas: ISchema[];
  readonly openTables: { id: string, table: ITable[] }[];
  readonly useTables: { sourceId: string, schema: string, name: string }[];
  // select
  readonly sourceId: string;
  readonly sqlExpression: string;
  readonly id: string;
  readonly error: string;
  // search
  readonly searchSchema: string;
  readonly searchSource: string;
  //
  readonly separatorMove: boolean;
  readonly deltaMove: string;
}

// todo обработка ошибок
export class CreateKoobStep1 extends React.Component<ICreateKoobStep1Props, ICreateKoobStep1State> {
  private _schemasService: SchemasService;
  public readonly state: ICreateKoobStep1State;

  public constructor(props) {
    super(props);
    this.state = {
      schemas: [],
      openTables: [],
      useTables: [],
      sourceId: 'luxmsbi',
      sqlExpression: '',
      error: null,
      id: null,
      searchSchema: '',
      searchSource: '',
      // двигай телом
      separatorMove: false,
      deltaMove: localStorage.getItem('preferences.CreateKoobStep1') ?? '50%',
    };
  }

  public componentDidMount() {
    this._schemasService = new SchemasService(this.state.sourceId);
    this._schemasService.subscribeUpdatesAndNotify(this._onServiceShemaUpdate);
  }

  public componentDidUpdate(prevProps: Readonly<ICreateKoobStep1Props>, prevState: Readonly<ICreateKoobStep1State>) {
    if (this.state.sourceId !== prevState.sourceId) {
      this._schemasService.unsubscribe(this._onServiceShemaUpdate);
      this._schemasService = new SchemasService(this.state.sourceId);
      this._schemasService.subscribeUpdatesAndNotify(this._onServiceShemaUpdate);
    }
    if (this.props.newSourceId !== prevProps.newSourceId) this.setState({sourceId: this.props.newSourceId});
  }

  public componentWillUnmount() {
    this._schemasService.unsubscribe(this._onServiceShemaUpdate);
    this._schemasService.release();
  }

  private _onServiceShemaUpdate = (): void => {
    const shemaModel = this._schemasService.getModel();
    if (shemaModel.loading || shemaModel.error) return;
    const schemas = shemaModel.schemas.filter(s => s.name);
    this.setState({schemas});
  }

  // changed fn
  private _onClickShema = async (shema: ISchema): Promise<void> => {
    const {name, sourceId} = shema;
    const {openTables} = this.state;
    const idx = $eidx(openTables, name);
    if (idx < 0) {
      const tablesSrv = new TablesService(sourceId, name);
      await tablesSrv.whenReady();
      const table = tablesSrv.getModel().tables;
      this.setState({openTables: [...openTables, {id: name, table}]});
    } else {
      const copyTables = [...openTables];
      copyTables.splice(idx, 1);
      this.setState({openTables: copyTables});
    }
  }
  private _onDragStart = (event: any, table: ITable): void => {
    const {sourceId} = this.state;
    event.dataTransfer.setData('application/vnd.luxmsbi.table+json', JSON.stringify({...table, sourceId}));
    event.dataTransfer.effectAllowed = 'move';
  }
  private _onDoubleClick = (event: any, table: ITable): void => {
    event.stopPropagation();
    event.preventDefault();
    const {useTables, sourceId} = this.state;
    const schema = table?.facets?.schema ?? table.schema;
    const name = table?.facets?.elementName ?? table.name;
    this.setState({useTables: [...useTables, {sourceId, schema, name }]});
  }
  private _changeDbRelations = (tables: any, links: IDbLink[]): void => {
    const ids: string[] = tables.map(t => t.id);
    const [id] = cubeName(ids);
    if (!isDbRelationsConnected(ids, links)) {
      return this.setState({
        error: !tables?.length ? null : lang('graph_not_connected'),
        sqlExpression: formSqlExpression(tables, links),
        useTables: tables,
      });
    }
    const sqlExpression = formSqlExpression(tables, links);
    this.setState({sqlExpression, error: null, id, useTables: tables});
  }
  private _onChange = (): void => {
    const {sourceId, sqlExpression, error, id} = this.state;
    if (error || !sqlExpression) return;
    this.props.onChange(sourceId, sqlExpression, id);
  }

  // resize fn
  private _onDragStartResize = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  }
  private _onPointerDown = (e: React.PointerEvent<HTMLDivElement>): void => {
    const el = e.currentTarget;
    el.setPointerCapture(e.pointerId);
    e.stopPropagation();
    this.setState({separatorMove: true});
  }
  private _onPointerUp = (e: React.PointerEvent<HTMLDivElement>): void => {
    const el = e.currentTarget;
    el.releasePointerCapture(e.pointerId);
    e.stopPropagation();
    this.setState({separatorMove: false});
    localStorage.setItem('preferences.CreateKoobStep1', this.state.deltaMove);
  }
  private _onPointerMove = (e: React.PointerEvent<HTMLDivElement>): void => {
    if (!this.state.separatorMove) return;
    e.stopPropagation();
    e.preventDefault();
    const deltaMove = (e.clientY - 73) + 'px';
    this.setState({deltaMove});
  }

  public render() {
    const {schema_name} = this.props;
    const {schemas, openTables, useTables, sourceId, sqlExpression, error, deltaMove} = this.state;
    const {searchSchema} = this.state;

    const renderShemas = schemas.filter((s) => search(s.title, searchSchema));

    return (
      <React.Fragment>

        {/*ЛЕВАЯ ПАНЕЛЬ*/}
        <div className="CreateKoobStep1__Left">

          {/* Выбранная schema*/}
          <div className="CreateKoobStep1__Left__Schema" style={{height: deltaMove}}>

            <header>
              <div className="CreateKoobStep1__Title">
                <p>Схемы в источнике данных</p>
              </div>

              <div className="CreateKoobStep1__Search">
                <IconSearch1/>
                <input type="text" placeholder="Поиск" value={searchSchema}
                       onChange={e => this.setState({searchSchema: e.target.value})}/>
              </div>
            </header>

            <main>
              <div className="CreateKoobStep1__Wrapper">
                {renderShemas.map(((schema) => {
                  const openTable = $eid(openTables, schema.name);
                  const renderTable = openTable?.table || [];
                  const useSchemaTable = useTables.filter(t => t.schema === schema.name);
                  return (
                    <React.Fragment key={schema.name}>
                      <div className={cn('CreateKoobStep1__Left__Schema-Item', {
                        select: !!openTable,
                        use: !!useSchemaTable.length
                      })}
                           onClick={() => this._onClickShema(schema)}
                      >
                        <span><IconArrow/></span>
                        {schema.title}
                      </div>
                      {
                        !!renderTable.length &&
                        <ul className="CreateKoobStep1__Left__Schema-List">
                          {renderTable.map((t) => {
                            const useTable = useSchemaTable.some(tt => tt.name === t.name);
                            return (
                              <li draggable className={cn({use: useTable})}
                                  onDoubleClick={e => this._onDoubleClick(e, t)}
                                  onDragStart={e => this._onDragStart(e, t)} key={t.name}>
                                {t.title}
                              </li>);
                          })}
                        </ul>
                      }
                    </React.Fragment>
                  );
                }))}
              </div>
            </main>
          </div>

          {/*Выбранный DataSource*/}
          <div className="CreateKoobStep1__Left__DataSource">
            {/*resize*/}
            <div className="CreateKoobStep1__Resizer"
                 onPointerDown={this._onPointerDown}
                 onPointerUp={this._onPointerUp}
                 onPointerMove={this._onPointerMove}
                 onDragStart={this._onDragStartResize}
            />
            <DataSourcesSelector schema_name={schema_name}
                                 selectedSourceId={sourceId}
                                 onChange={(sourceId) => this.setState({sourceId, openTables: [], useTables: [], searchSchema: ''})}
                                 onClickCreate={this.props.openCreate}/>
          </div>

        </div>

        {/*ЦЕНТРАЛЬНАЯ*/}

        <div className="CreateKoobStep1__Center">

          <div className="CreateKoobStep1__Center__Top">
            <DbRelations onChange={this._changeDbRelations} tables={useTables} setRenderTables={() => null}
                         ident={sourceId}/>
          </div>

          <div className="CreateKoobStep1__Center__Bottom">
            <div className="CreateKoobStep1__TextEditor">
              <TextEditor contentType="text/sql"
                          className="CustomScrollbar"
                          readOnly={true}
                          content={sqlExpression}
                          onChange={() => null}/>
            </div>
            <div className="CreateKoobStep1__Help">
              <div className={cn('CreateKoobStep1__Help__Title', {error: !!error})}>
                <span>
                  {!!!error && <IconHelp/>}
                  {!!error && <IconWarning/>}
                </span>
                {!!!error && <p>Перетащите таблицы на экран и настройте связи</p>}
                {!!error && <p>{error}</p>}
              </div>
              <div className="CreateKoobStep1__Help__Btn">
                <Button className="Back" disabled={true}>Назад</Button>
                <Button className="Next" disabled={!!!sqlExpression || !!error} onClick={this._onChange}>
                  Вперед
                </Button>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default CreateKoobStep1;

// todo switch case
const getIcon = (url: string): JSX.Element => {
  if (url && url.includes('postgresql')) return <IconPostgresql/>;
  else if (url && url.includes('clickhouse')) return <IconCH1/>;
  else if (url && url.includes('oracle')) return <IconOracle1/>;
  else if (url && url.includes('sqlserver')) return <IconMSSQL1/>;
  else if (url && url.includes('mysql')) return <IconMySQL1/>;
  else if (url && url.includes('xdds')) return <IconExcel1/>;
  return <IconNone1/>;
};


interface ISchema {
  facets: {
    elementType: 'schema';
    elementName: string;
    sourceId: string;
  };
  name: string;
  uri: string;
  title?: string;
  sourceId?: string;
  spreadsheetVersion?: 'EXCEL2007';
}

interface ISchemas {
  readonly error: string;
  readonly loading: boolean;
  readonly schemas: ISchema[];
}


class SchemasService extends BaseService<ISchemas> {
  private readonly _sourceId: string;

  public constructor(sourceId: string) {
    super({loading: true, error: null, schemas: []});
    this._sourceId = sourceId;
    this._reload();
  }

  private async _reload() {
    try {
      const schemas = await DataSourceInspectorService.getSchemas(this._sourceId);
      this._updateModel({schemas, loading: false, error: null});
    } catch (err) {
      this._updateModel({schemas: [], loading: false, error: err.message});
    }
  }
}

interface ITable {
  facets: {
    elementType: 'schema';
    elementName: string;
    sourceId: string
    schema: string;
  };
  name: string;
  title: string;
  schema: string;
  uri: string;
}

interface IRenderTable {
  x: number;
  y: number;
  id: string;
  tableInfo: db_relations.ITableInfo;
}

interface ITables {
  error: string;
  loading: boolean;
  tables: ITable[];
}

export class TablesService extends BaseService<ITables> {
  private readonly _ident: string;
  private readonly _schema: string;
  public static MODEL: ITables;
  public state: ITables;

  public constructor(ident: string, schema: string) {
    super({
      loading: true,
      error: null,
      tables: [],
    });
    this._ident = ident;
    this._schema = schema;
    this._reload();
  }

  private async _reload() {
    const qrpc = new QRPCService();
    const request = {
      service: 'DataSourceInspectorService',
      method: 'getTables',
      params: [this._ident, this._schema],
    };
    try {
      const tables = await qrpc.execute(request);
      this._updateModel({tables, loading: false, error: null});
    } catch (err) {
      this._updateModel({tables: [], loading: false, error: null});
    }
  }
}

function cubeName(tables: string[]): [string, string] {
  const nameCube = [];
  const title = [];
  tables.map((t) => {
    let [topic, id, table] = t.split('.');
    nameCube.push([id, table].join('_'));
    title.push([id, table].join('_'));
  });
  return [nameCube.join('_'), title.join('_')];
}