import {graphic} from 'echarts';

export module coloring {
  export interface IColor {
    toRGB(): RGBColor;

    toHSV(): HSVColor;

    add(d1: number, d2: number, d3: number): IColor;

    mul(x1: number, x2: number, x3: number): IColor;

    toString(): string;
  }

  export type IGradientColorStop = [number, string];
  export type IGradientEchartsColorStop = {offset: number, color: string};

  interface IGradientColor {
    linearGradient: { x1: number; y1: number; x2: number; y2: number; };
    stops: IGradientColorStop[];
  }
  interface IGradientEchartsColor {
    type: string;
    x: number;
    y: number;
    x2?: number;
    y2?: number;
    r?: number;
    colorStops: IGradientEchartsColorStop[];
    global: boolean;
  }

  const formatHex2 = (v: number) => ('0' + Math.floor(v).toString(16)).substr(-2);

  export class RGBColor implements IColor {
    public constructor(public r, public g, public b) {
      this.r = Math.max(0, Math.min(this.r, 255));
      this.g = Math.max(0, Math.min(this.g, 255));
      this.b = Math.max(0, Math.min(this.b, 255));
    }

    public toRGB(): RGBColor {
      return this;
    }

    public toHSV(): HSVColor {
      const r = this.r / 255;
      const g = this.g / 255;
      const b = this.b / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      var h, v = max;

      var d = max - min;
      var s = (max == 0) ? 0 : d / max;

      if (max === min) {
        h = 0;
      } else {
        switch (max) {
          case r:
            h = (g - b) / d + ((g < b) ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h = h / 6;
      }

      return new HSVColor(Math.floor(h * 360), Math.floor(s * 100), Math.floor(v * 100));
    }

    public add(d1: number, d2: number, d3: number): IColor {
      return new RGBColor(this.r + d1 * 255, this.g + d2 * 255, this.b + d3 * 255);
    }

    public mul(x1: number, x2: number, x3: number): IColor {
      return new RGBColor(this.r * x1, this.g * x2, this.b * x3);
    }

    public static interpolateRGB(c1: RGBColor, c2: RGBColor, k: number): RGBColor {       // k [0..1]
      return new RGBColor(
          (1 - k) * c1.r + k * c2.r,
          (1 - k) * c1.g + k * c2.g,
          (1 - k) * c1.b + k * c2.b);
    }

    public toString(): string {
      // return 'rgb(' + Math.floor(this.r) + ',' + Math.floor(this.g) + ',' + Math.floor(this.b) + ')';
      return '#' + formatHex2(this.r) + formatHex2(this.g) + formatHex2(this.b);
    }
  }

  export class HSVColor implements IColor {
    public constructor(public h: number, public s: number, public v: number) {
      this.h = Math.max(0, Math.min(this.h, 360));
      this.s = Math.max(0, Math.min(this.s, 100));
      this.v = Math.max(0, Math.min(this.v, 100));
    }

    public clone(): HSVColor {
      return new HSVColor(this.h, this.s, this.v);
    }

    /**
     * HSV to RGB color conversion
     *
     * H runs from 0 to 360 degrees
     * S and V run from 0 to 100
     *
     * Ported from the excellent java algorithm by Eugene Vishnevsky at:
     * http://www.cs.rit.edu/~ncs/color/t_convert.html
     */
    public toRGB(): RGBColor {
      var r, g, b;
      var i;
      var f, p, q, t;

      // Make sure our arguments stay in-range
      var h = Math.max(0, Math.min(360, this.h));
      var s = Math.max(0, Math.min(100, this.s));
      var v = Math.max(0, Math.min(100, this.v));

      // We accept saturation and value arguments from 0 to 100 because that's
      // how Photoshop represents those values. Internally, however, the
      // saturation and value are calculated from a range of 0 to 1. We make
      // That conversion here.
      s /= 100;
      v /= 100;

      if (s == 0) {
        // Achromatic (grey)
        r = g = b = v;
        return new RGBColor(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255));
      }

      h /= 60; // sector 0 to 5
      i = Math.floor(h);
      f = h - i; // factorial part of h
      p = v * (1 - s);
      q = v * (1 - s * f);
      t = v * (1 - s * (1 - f));

      switch (i) {
        case 0:
          r = v;
          g = t;
          b = p;
          break;
        case 1:
          r = q;
          g = v;
          b = p;
          break;
        case 2:
          r = p;
          g = v;
          b = t;
          break;
        case 3:
          r = p;
          g = q;
          b = v;
          break;
        case 4:
          r = t;
          g = p;
          b = v;
          break;
        default: // case 5:
          r = v;
          g = p;
          b = q;
      }

      return new RGBColor(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255));
    }

    public add(d1: number, d2: number, d3: number): IColor {
      return new HSVColor(this.h + d1 * 360, this.s + d2 * 100, this.v + d3 * 100);
    }

    public mul(x1: number, x2: number, x3: number): IColor {
      return new HSVColor(this.h * x1, this.s * x2, this.v * x3);
    }

    public mulEx(x1: number, x2: number, x3: number): IColor {
      if (this.s == 0 || this.s == 100) x2 = 1;
      if (this.v == 0 || this.v == 100) x3 = 1;
      return new HSVColor(this.h * x1, this.s * x2, this.v * x3);
    }

    public mulEx2(mh: number, ms: number, mv: number): IColor {
      if (this.s == 100 && this.v == 100) {
        return new HSVColor(this.h * mh, this.s * ms, this.v * mv);
      } else {
        if (this.s == 0 || this.s == 100) ms = 1;
        if (this.v == 0 || this.v == 100) mv = 1;
      }
      return new HSVColor(this.h * mh, this.s * ms, this.v * mv);
    }

    public toHSV(): HSVColor {
      return this;
    }

    public toString(): string {
      return this.toRGB().toString();
      // let hsl = hsv2hsl(this.h, this.s, this.v);
      // return 'hsl(' + hsl[0] + ',' + hsl[1] + '%,' + hsl[2] + '%)';
    }

    public static interpolateHSV(c1: HSVColor, c2: HSVColor, k: number): HSVColor {       // k [0..1]
      let h, h1 = c1.h, h2 = c2.h;
      if (Math.abs(h2 - h1) <= 180) {
        h = (1 - k) * h1 + k * h2;
      } else if (h2 < h1) {               //  [0]--(h2)----------(h1)---[360]
        h = ((1 - k) * h1 + k * (360 + h2)) % 360;
      } else {                            //  [0]--(h1)----------(h2)---[360]
        h = ((1 - k) * (h1 + 360) + k * h2) % 360;
      }
      return new HSVColor(
          h,
          (1 - k) * c1.s + k * c2.s,
          (1 - k) * c1.v + k * c2.v);
    }
  }

  const CSS_COLORS_BY_NAME: { [id: string]: string } = {
    black: '#000000',
    blue: '#0000ff',
    green: '#008000',
    red: '#ff0000',
    white: '#ffffff',
    yellow: '#ffff00',
  };

  export function make(s: string): IColor {
    try {
      if (s.match(/^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/)) {
        return new RGBColor(parseInt(RegExp.$1, 16), parseInt(RegExp.$2, 16), parseInt(RegExp.$3, 16));
      }
      if (s.match(/^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/)) {
        return new RGBColor(parseInt(RegExp.$1 + RegExp.$1, 16), parseInt(RegExp.$2 + RegExp.$2, 16), parseInt(RegExp.$3 + RegExp.$3, 16));
      }
      if (s.match(/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/)) {
        return new RGBColor(parseInt(RegExp.$1, 10), parseInt(RegExp.$2, 10), parseInt(RegExp.$3, 10));
      }
      if (s in CSS_COLORS_BY_NAME) {
        return make(CSS_COLORS_BY_NAME[s]);
      }
    } catch (err) {
      debugger;
    }
    throw new Error('Unknown color: ' + String(s));
  }
  export function makeEchartsGradient(gradientType: string, baseColor: string, chartType?: string): IGradientEchartsColor {
    gradientType = String(gradientType).toLowerCase();
    const base: HSVColor = make(baseColor).toHSV();
    let stops: IGradientEchartsColorStop[];
    if (gradientType === '3d') {
      if (base.s === 100 && base.v === 100) {
        stops = [
          {offset: 0.00, color: base.add(0.0, 0.0, -0.1).toString()},
          {offset: 0.30, color: base.add(0.0, -0.3, -0.1).toString()},
          {offset: 0.70, color: base.toString()},
          {offset: 1.00, color: base.add(0.0, 0.0, -0.2).toString()}
        ];
      } else if (base.v === 100 || base.v === 0) {
        stops = [
          {offset: 0.00, color: base.toString()},
          {offset: 0.20, color: base.add(0.0, -0.2, 0.0).toString()},
          {offset: 0.40, color: base.toString()},
          {offset: 0.65, color: base.add(0.0, +0.2, -0.2).toString()},
          {offset: 0.90, color: base.toString()},
          {offset: 1.00, color: base.add(0.0, +0.1, 0.0).toString()}
        ];
      } else if (base.s === 100 || base.s === 0) {
        stops = [
          {offset: 0.00, color: base.toString()},
          {offset: 0.20, color: base.add(0.0, 0.0, +0.2).toString()},
          {offset: 0.40, color: base.toString()},
          {offset: 0.65, color: base.add(0, 0.0, -0.2).toString()},
          {offset: 0.90, color: base.toString()},
          {offset: 1.00, color: base.add(0, 0.0, -0.1).toString()},
        ];
      } else {
        stops = [
          {offset: 0.00, color: base.toString()},
          {offset: 0.20, color: base.add(0.0, -0.2, +0.2).toString()},
          {offset: 0.40, color: base.toString()},
          {offset: 0.65, color: base.add(0, +0.2, -0.2).toString()},
          {offset: 0.90, color: base.toString()},
          {offset: 1.00, color: base.add(0, +0.1, -0.1).toString()},
        ];
      }
      let gradient = new graphic.LinearGradient(0, 0, 0, 1, stops);
      if (chartType && chartType == 'column') {
        gradient = new graphic.LinearGradient(0, 0, 1, 0, stops);
      }
      return gradient;

    } else if (gradientType === 'opacity') {
      const {r, g, b} = make(baseColor).toRGB();
      stops = [
        {offset: 0, color: `rgba(${r}, ${g}, ${b}, 1)`},
        {offset: 0.2, color: `rgba(${r}, ${g}, ${b}, 0.3)`},
        {offset: 0.75, color: `rgba(${r}, ${g}, ${b}, 0.1)`},
        {offset: 1, color: `rgba(${r}, ${g}, ${b}, 0.1)`}
      ];

      return new graphic.LinearGradient(0, 0, 0, 1, stops);

    } else if (gradientType === 'transparentize') {
      const {r, g, b} = make(baseColor).toRGB();
      return `rgba(${r}, ${g}, ${b}, 0.7)` as any;

    } else {
      // default gradient
      const upper: IColor = base.mul(1, 0.7, 1.3);
      stops = [
        {offset: 0, color: base.toString()},
        {offset: 1, color: upper.toString()},
      ];

      return new graphic.LinearGradient(0, 0, 0, 1, stops);
    }
  }
}