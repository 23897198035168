import { BaseService, IDisposable, UrlState, IUrl, createSingleton, AuthenticationService, IAuthentication } from '@luxms/bi-core';
import { IDsStateService, IDsState } from './types';
import { DsStateService1 } from './DsStateService1';

/**
 * @description Подписан на url, показывает в моделе какой датасет/дешборд/дешлет/метрика... на экране.
 */
export class CurrentDsStateService extends BaseService<IDsState> {
  @BaseService.dependency
  private _authenticationService: AuthenticationService = AuthenticationService.getInstance();

  @BaseService.dependency
  private _dsStateService: IDsStateService = null;

  @BaseService.dependency
  private _urlState: UrlState = UrlState.getInstance();

  private _schemaName: string = null;

  protected _onDepsReadyAndUpdated({authentication, dsState, urlState}: {authentication: IAuthentication, dsState: IDsState, urlState: IUrl}) {
    if (!authentication.authenticated) {
      this._dsStateService = null;
      this._schemaName = null;
      this._updateWithError(AuthenticationService.NOT_AUTHENTICATED);
      return;
    }
    if (urlState.segment !== 'ds' || !urlState.segmentId) {
      this._dsStateService = null;
      this._schemaName = null;
      this._updateWithError('No dataset');
      return;
    }

    if (dsState === null || urlState.segmentId !== this._schemaName) {
      this._schemaName = urlState.segmentId;
      this._dsStateService = DsStateService1.createInstance(this._schemaName);
      return;
    }
    this._setModel(dsState);
  }


  public static getInstance = createSingleton<CurrentDsStateService>(() => new CurrentDsStateService(), '__currentDsStateService');

  public static getModel(): IDsState {
    return this.getInstance().getModel();
  }

  public static subscribeUpdatesAndNotify(listener: (model: IDsState) => void): IDisposable {
    return this.getInstance().subscribeUpdatesAndNotify(listener);
  }

  public static unsubscribe(listener: (...args: any[]) => any): boolean {
    return this.getInstance().unsubscribe(listener);
  }

}
