/**
 *
 *
 *
 */
import uniq from 'lodash/uniq';
import { BaseService, disposeAll, IDisposable, urlState } from '@luxms/bi-core';
import { Period } from './entities';
import { $eid, $esid } from '../../libs/imdas/list';
import { bi, IS_P, markContinuousPeriodType, nEntities, oneEntity, } from '../../utils/utils';
import { IDatasetModel, IDsState, IDsStateService } from './types';
import { lpeRun } from '../../utils/lpeRun';
import { urlExtractParams } from '../../utils/url-utils';
import {
  IEntity,
  ILocation,
  IMetric,
  IMLPPtrCube,
  IPeriod,
  ISubspace,
  ISubspacePtr,
  ITag,
  IUnit,
  IValue,
} from '../../defs/bi';
import { VizelPivotMLP } from '../../view-controllers/vizels/VizelPivotMLP';
import { LookupSubspaceService } from '../lookup/LookupSubspaceService';
import { KoobSubspaceAsyncService } from '../koob/KoobSubspaceAsyncService';
import { DsStateService1 } from './DsStateService1';


/** help fn */
const isArray = (arg) => Object.prototype.toString.call(arg) === '[object Array]';
const isHash = (arg) => (typeof arg === 'object') && (arg !== null) && !isArray(arg);

export function makeMetrics(metricDescription: any, units: IUnit[], metrics: IMetric[]): IMetric {
  if (typeof metricDescription === 'object') {
    return {
      axisId: 'metrics',
      id: metricDescription.id || ('generated_' + Math.random()),                                 // TODO: guid?
      parent_id: null,
      unit_id: metricDescription.unit_id || -1,
      srt: 0,
      tree_level: -1,
      title: metricDescription.title || String(metricDescription.id) || '',
      is_text_val: 0,
      config: {},
      parent: null,
      unit: $eid(units, metricDescription.unit_id),
      children: [],
      is_hidden: 0,
      rawTags: [],
      addTag: (tag: ITag) => null,
      getTags: (): ITag[] => [],
      getTag: (id: string | number): ITag => null,
      getTagByGroupId: (tagGroupId: string): ITag => null,
      is_formula: true,
    };
  }

  const [_, id, strParams] = metricDescription.match(/^(=.*?)(?:\?(.*))?$/);
  const params: any = urlExtractParams(metricDescription);
  let unit_id: number = 'unit_id' in params ? parseInt(params.unit_id) : -1;
  let unit = $eid(units, unit_id);

  const children = id.match(/#\d+/g).map(metricIdWithSharp => $eid(metrics, metricIdWithSharp.slice(1))).filter(m => !!m);
  if (!unit) unit = children[0].unit;
  unit_id = unit ? unit.id : null;

  let title: string;
  if ('title' in params) {
    title = params.title;
  } else if (String(id).startsWith('=')) {
    title = id.slice(1).replace(/#\d+/g, metricIdWithSharp => $eid(metrics, metricIdWithSharp.slice(1))?.title ?? '');
  } else {
    title = id;
  }

  const metric: IMetric = {
    axisId: 'metrics',
    id,
    parent_id: null,
    unit_id,
    srt: 0,
    tree_level: -1,
    title,
    is_text_val: 0,
    config: {},
    parent: null,
    unit,
    children,
    is_hidden: 0,
    rawTags: [],
    addTag: (tag: ITag) => null,
    getTags: (): ITag[] => [],
    getTag: (id: string | number): ITag => null,
    getTagByGroupId: (tagGroupId: string): ITag => null,
    is_formula: true,
  };
  return metric;
}

export function makeLocation(locationDescription: any): ILocation {
  if (typeof locationDescription === 'object') {
    const id: string = locationDescription.id || ('generated_' + Math.random());                                 // TODO: guid?
    return {
      axisId: 'locations',
      loc_id: -1,
      id,
      parent_id: null,
      tree_level: -1,
      title: locationDescription.title || String(locationDescription.id) || '',
      config: {},
      parent: null,
      children: [],
      spatials: [],
      card: null,
      is_point: 0,
      latitude: 0,
      longitude: 0,
      is_hidden: 0,
      srt: 0,
      rawTags: [],
      src_id: '',
      addTag: (tag: ITag) => null,
      getTags: (): ITag[] => [],
      getTag: (id: string | number): ITag => null,
      getTagByGroupId: (tagGroupId: string): ITag => null,
      is_formula: true,
      getAltTitle: (titleType: string) => locationDescription.title || String(locationDescription.id) || '',
    };
  }
  throw new Error('Invalid location');
}

export function makeNullAxis(): IEntity {
  return {id: '', title: '', ids: [], axisIds: [], formula: [], titles: [], config: {}, unit: null};
}

export function esApplyDrilldown<T extends IEntity>(es: T[], drilldownLevel: number): T[] {
  for (; drilldownLevel > 0; drilldownLevel--) {                                  // go down
    es = Array.prototype.concat.apply([], es.map((e: T) => e.children));
  }
  for (; drilldownLevel < 0; drilldownLevel++) {                                  // go up
    es = uniq(Array.prototype.concat.apply([], es.map((e: T) => e.parent)).filter(e => e != null));
  }
  return es;
}

export interface ISubspaceModel {
  error: string;
  loading: boolean;
  subspace: ISubspace;
}

/** create subspace  */

function createSubspace(dsStateService: IDsStateService, subspacePtr: ISubspacePtr, isExternal: boolean, onUpdate: () => any = null): [ISubspace, IDisposable[]] {
  const dsModel: IDatasetModel = dsStateService.getDataset();
  const state: IDsState = dsStateService.getModel();
  const subscriptions: IDisposable[] = [];
  let ms: IMetric[], ls: ILocation[], ps: IPeriod[];

  function _initMs(): void {
    const mIdsExpr: any = subspacePtr.getMIds();
    let wantSubscribeForAllMetrics: boolean = false;
    let wantSubscribeForUrl: boolean = false;

    if (Array.isArray(mIdsExpr) && mIdsExpr.length) {
      ms = mIdsExpr.map((id: string): IMetric => {
        if (typeof id === 'object') {
          return makeMetrics(id, dsModel.units, dsModel.metrics);
        } else if (typeof id === 'string' && id[0] === '=') {
          return makeMetrics(id, dsModel.units, dsModel.metrics);
        }
        const m = $eid(dsModel.metrics, id);
        return m;
      }).filter(m => !!m);

    } else if ((typeof mIdsExpr === 'string') && (mIdsExpr === '*' || mIdsExpr === '(..)')) {
      wantSubscribeForAllMetrics = true;
      ms = dsModel.metrics;

    } else if (typeof mIdsExpr === 'string' && mIdsExpr.startsWith('lpe:')) {
      wantSubscribeForAllMetrics = true;
      ms = lpeRun(mIdsExpr, {
        ms: dsModel.metrics,
        es: dsModel.metrics,
        state: state.metrics,
        byId: (id) => $eid(dsModel.metrics, id),
        m: (id) => $eid(dsModel.metrics, id),
        e: (id) => $eid(dsModel.metrics, id),
        e$: (...ids) => $esid(dsModel.metrics, ids.length === 1 && Array.isArray(ids[0]) ? ids[0] : ids),
        url: (key) => {
          wantSubscribeForUrl = true;                                                               // TODO subscribe only for key
          const model: any = urlState.getModel();
          return model[key] || '';
        },
        dataSource: subspacePtr.dataSource,
      });

    } else if (!isExternal) {
      wantSubscribeForAllMetrics = true;
      ms = state.metrics;

    } else {
      ms = dsModel.defaultMetrics;
    }
    ms = esApplyDrilldown(ms, subspacePtr.metricsDrilldown);
    if (wantSubscribeForAllMetrics && onUpdate) {
      subscriptions.push(dsStateService.subscribe('metrics', onUpdate));
    }
    if (wantSubscribeForUrl && onUpdate) {
      subscriptions.push(urlState.subscribeUpdates(onUpdate));
    }
  }

  function _initLs(): void {
    const lIdsExpr: any = subspacePtr.getLIds();
    let wantSubscribeForAllLocations: boolean = false;
    let wantSubscribeForUrl: boolean = false;

    if (Array.isArray(lIdsExpr) && lIdsExpr.length) {
      ls = lIdsExpr.map((id: string): ILocation => {
        if (typeof id === 'object') {
          return makeLocation(id);
        } else if (typeof id === 'string' && id[0] === '=') {
          return makeLocation(id);
        }
        const l = $eid(dsModel.locations, id);
        return l;
      }).filter(l => !!l);

    } else if (lIdsExpr === '*' || lIdsExpr === '(..)') {
      wantSubscribeForAllLocations = true;
      ls = dsModel.locations;

    } else if (typeof lIdsExpr === 'string' && lIdsExpr.startsWith('lpe:')) {
      wantSubscribeForAllLocations = true;
      ls = lpeRun(lIdsExpr, {
        ls: dsModel.locations,
        es: dsModel.locations,
        state: state.locations,
        STATE: (x: any) => {
          if (!x) return state;
          return state[x];
        },
        lastState: oneEntity(state.locations),
        byId: (id) => $eid(dsModel.locations, id),
        l: (id) => $eid(dsModel.locations, id),
        e: (id) => $eid(dsModel.locations, id),
        e$: (...ids) => $esid(dsModel.locations, ids.length === 1 && Array.isArray(ids[0]) ? ids[0] : ids),
        url: (key) => {
          wantSubscribeForUrl = true;                                                               // TODO subscribe only for key
          const model: any = urlState.getModel();
          return model[key] || '';
        },
        dataSource: subspacePtr.dataSource,
      });

    } else if (!isExternal) {
      wantSubscribeForAllLocations = true;
      ls = state.locations;

    } else {
      ls = dsModel.defaultLocations;
    }

    ls = esApplyDrilldown(ls, subspacePtr.locationsDrilldown);

    if (wantSubscribeForAllLocations && onUpdate) {
      subscriptions.push(dsStateService.subscribe('locations', onUpdate));
    }
    if (wantSubscribeForUrl && onUpdate) {
      subscriptions.push(urlState.subscribeUpdates(onUpdate));
    }
  }

  function _initPs(): void {
    const pIdsExpr: any = subspacePtr.getPIds();
    const periodType: string = subspacePtr.getPType();
    let wantSubscribeForAllPeriods: boolean = false;                            // may ba always true?
    let wantSubscribeForUrl: boolean = false;

    if (Array.isArray(pIdsExpr) && pIdsExpr.length) {                           // array (ids)
      ps = $esid(dsModel.periods, pIdsExpr);

    } else if (isArray(pIdsExpr) && pIdsExpr.length === 0) {              // []
      ps = [];

    } else if (isHash(pIdsExpr)) {                                                          // {start, end}
      ps = dsModel.getPeriodInfoByRange(pIdsExpr.start, pIdsExpr.end).periods as Period[];

    } else if (periodType != null && String(periodType).match(/^(\d+)(?:\.(\d+))?$/)) {       // period-type
      subscriptions.push(dsModel.subscribe('periodsUpdated', onUpdate));

      const pt: number = parseInt(RegExp.$1);
      const periods: IPeriod[] = dsModel.periodsHelper.getPeriodsByTypeId(pt);
      markContinuousPeriodType(periods, [pt, 1]);

      if (typeof pIdsExpr === 'string') {                                                     // period_type + biPath
        // Тут надо запускать lpe
      } else {
        ps = periods;
      }

    } else if (typeof pIdsExpr === 'string' && pIdsExpr.startsWith('lpe:')) {
      wantSubscribeForAllPeriods = true;
      ps = lpeRun(pIdsExpr, {
        ps: dsModel.periods,
        es: dsModel.periods,
        state: state.periods,
        lastState: oneEntity(state.periods),                                                        // TODO: implement getters in lpe and subscribe on state
        // disabled due to conflict with expression "[url(period).end.e()]"
        // start: state.periods[0] || null,
        // end: oneEntity(state.periods),
        byId: (id) => $eid(dsModel.periods, id),
        p: (id) => $eid(dsModel.periods, id),
        e: (id) => $eid(dsModel.periods, id),
        idDecYear: (id) => String(id).replace(/^..../, (v) => String(+v - 1)),
        last: (ps, expr) => {
          const P = ps[ps.length - 1];
          if (!P) return [];
          if (expr == null) return P;                                                               // WARN: last() returns one entity (not array) when no expression
          let {year, quarter, month, day} = P;
          day = P.date.getDate();
          switch (expr) {
            case 'year':
              return ps.filter(p => p.year == year);
            case 'quarter':
              return ps.filter(p => p.quarter === quarter && p.year == year);
            case 'month':
              return ps.filter(p => p.month === month && p.year == year);
            case 'day':
              return ps.filter(p => p.day === day && p.month === month && p.year == year);
          }
          return [];
        },
        prev: (ps: IPeriod[], expr, count = 1) => {
          const P = ps[ps.length - 1];
          if (!P) return [];
          if (expr == null) return ps[ps.length - 2];                                               // WARN: last() returns one entity (not array) when no expression
          let {year, quarter, month, day} = P;
          day = P.date.getDate();
          switch (expr) {
            case 'year': {
              year -= count;
              return ps.filter(p => p.year === year);
            }
            case 'quarter': {
              year -= Math.floor(count / 4);
              const currentCount = count != 0 ? count - 4 * Math.floor(count / 4) : 0;
              if (quarter - currentCount < 0) {
                year--;
                quarter = 4 + (quarter - currentCount);
              } else if (quarter == currentCount) {
                year--;
                quarter = 4;
              } else {
                quarter -= currentCount;
              }
              return ps.filter(p => p.quarter === quarter && p.year == year);
            }
            case 'month': {
              let curDate = new Date(year, month - 1, 1);
              curDate.setMonth(curDate.getMonth() - count);
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              return ps.filter(p => p.year == year && p.month == month);
            }
            case 'day': {
              console.log('before:', day, month, year, quarter);
              let curDate = new Date(year, month - 1, day);
              curDate.setDate(curDate.getDate() - count);
              day = curDate.getDate();
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              console.log('after:', day, month, year, quarter);
              return ps.filter(p => p.day === day && p.month === month && p.year == year);
            }
          }
          return [];
        },
        next: (ps: IPeriod[], expr, count = 1) => {
          const P = ps[ps.length - 1];
          if (!P) return [];
          let {year, quarter, month, day} = P;
          const PNext = dsModel.periods[dsModel.periods.indexOf(P) + 1];
          if (expr == null) return PNext;
          switch (expr) {
            case 'year': {
              year += count;
              return dsModel.periods.filter(p => p.year === year);
            }
            case 'quarter': {
              year += Math.floor(count / 4);
              const currentCount = count != 0 ? count - 4 * Math.floor(count / 4) : 0;
              if (quarter - currentCount < 0) {
                year++;
                quarter = 4 + (quarter + currentCount);
              } else if (quarter == currentCount) {
                year++;
                quarter = 4;
              } else {
                quarter += currentCount;
              }
              return dsModel.periods.filter(p => p.quarter === quarter && p.year == year);
            }
            case 'month': {
              let curDate = new Date(year, month - 1, 1);
              curDate.setMonth(curDate.getMonth() + count);
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              return dsModel.periods.filter(p => p.year == year && p.month == month);
            }
            case 'day': {
              let curDate = new Date(year, month - 1, day);
              curDate.setDate(curDate.getDate() + count);
              day = curDate.getDate();
              month = curDate.getMonth() + 1;
              year = curDate.getFullYear();
              return dsModel.periods.filter(p => p.day === day && p.month === month && p.year == year);
            }
          }
          return [];
        },
        pt: state.periods[state.periods.length - 1].period_type,
        e$: (...ids) => $esid(dsModel.periods, ids.length === 1 && Array.isArray(ids[0]) ? ids[0] : ids),
        url: (key) => {
          wantSubscribeForUrl = true;                                                               // TODO subscribe only for key
          const model: any = urlState.getModel();
          return model[key] || '';
        },
        dataSource: subspacePtr.dataSource,
      });
      ps = ps.filter(IS_P);

    } else if (!isExternal) {                                                                 // nothing (state)
      wantSubscribeForAllPeriods = true;
      ps = dsStateService.getModel().periods;

    } else {                                                                                  // nothing (defaults)
      ps = dsModel.defaultPeriods;
    }

    if (wantSubscribeForAllPeriods && onUpdate) {
      subscriptions.push(dsStateService.subscribe('periods', onUpdate));
    }
    if (wantSubscribeForUrl && onUpdate) {
      subscriptions.push(urlState.subscribeUpdates(onUpdate));
    }
  }

  _initMs();
  _initLs();
  _initPs();

  const axesOrder: string[] = subspacePtr.getAxesOrder();

  const mlp = {
    metrics: ms,
    locations: ls,
    periods: ps,
  };

  if (!subspacePtr.isCombine()) {
    const xs = mlp[axesOrder[2]];
    const ys = mlp[axesOrder[1]];
    const zs = mlp[axesOrder[0]];
    console.assert(xs && ys && zs);
    return [bi.createSimpleSubspace(xs, ys, zs), subscriptions];
  }

  // DARK MATTER #2

  const combineAxes = subspacePtr.getCombineAxes();
  const fake = {xAxis: ['periods'], yAxis: ['metrics'], zAxis: ['locations'], tags: []};  // todo delete for test
  const combineMLP = {
    metrics: ms,
    locations: ls,
    periods: ps,
  };

  // фильтрую млп, под теги
  if (combineAxes.tags.length > 0) {
    combineMLP.metrics = [];
    combineMLP.locations = [];
    combineMLP.periods = [];
    combineAxes.tags.forEach((tag) => {
      const axisId = tag[0];
      const tagName = tag[1];
      const axis: any = axisId === 'locations' ? mlp.locations : axisId === 'metrics' ? mlp.metrics : mlp.periods;
      const result = axis.filter((ax) => ax.getTagIdByGroupId(tagName));
      if (axisId === 'locations') combineMLP.locations = combineMLP.locations.concat(result);
      if (axisId === 'metrics') combineMLP.metrics = combineMLP.metrics.concat(result);
      if (axisId === 'periods') combineMLP.periods = combineMLP.periods.concat(result);
    });

    if (combineMLP.metrics.length === 0) combineMLP.metrics = mlp.metrics;
    if (combineMLP.locations.length === 0) combineMLP.locations = mlp.locations;
    if (combineMLP.periods.length === 0) combineMLP.periods = mlp.periods;
  }

  const pivotData = new VizelPivotMLP(combineMLP, combineAxes, subspacePtr);


  const xs: IEntity[] = pivotData.xAxisKeys;
  const ys: IEntity[] = pivotData.yAxisKeys;
  const zs: IEntity[] = pivotData.zAxisKeys;

  if (xs.length === 0) xs.push(makeNullAxis());
  if (ys.length === 0) ys.push(makeNullAxis());
  if (zs.length === 0) zs.push(makeNullAxis());

  const subspace: ISubspace = {
    xAxis: combineAxes.xAxis.join(';'),
    yAxis: combineAxes.yAxis.join(';'),
    zAxis: combineAxes.zAxis.join(';'),
    ms: combineMLP.metrics,
    ls: combineMLP.locations,
    ps: combineMLP.periods,
    xs,
    ys,
    zs,
    reduce: function (nx, ny, nz): ISubspace {
      this.xs = nEntities(subspace.xs, nx);
      this.ys = nEntities(subspace.ys, ny);
      this.zs = nEntities(subspace.zs, nz);
      const {m, l, p} = pivotData.getMLP(xs, ys, zs);
      subspace.ms = m;
      subspace.ls = l;
      subspace.ps = p;
      return this;
    },
    getZ: (id: number) => zs[id],
    getY: (id: number) => ys[id],
    getX: (id: number) => xs[id],
    isEmpty: () => !(zs.length || xs.length || ys.length),
    projectData: function (mlpCube: IMLPPtrCube): IValue[][][] {
      mlpCube.forEach((mid: string, lid: string, pid: string, v: IValue): void => {
        try {
          pivotData.putMLPValue(mid, lid, pid, v);
        } catch (err) {
          console.log('projectData', err);
          debugger;
        }
      });
      let cube: IValue[][][] = pivotData.createMLPCube(this.zs, this.ys, this.xs);
      return cube;
    },
    getMLP: (zs: any, ys: any, xs: any) => {
      const {m, l, p} = pivotData.getMLP(xs, ys, zs);
      return {m: m[0], l: l[0], p: p[0]};
    },
    getXs: (mid: string, lid: string, pid: string): string => pivotData.getXs(mid, lid, pid),
    getXsLength: async () => pivotData.xAxisKeys.length,
    getYsLength: async () => pivotData.yAxisKeys.length,
    createSubspaceAxes: (x, y) => _createSubspaceAxes(x, y),
    pivotData,
  };

  return [subspace, subscriptions];

  function _createSubspaceAxes(x: [number, number], y: [number, number]): any {
    const xss = pivotData.xAxisKeys.slice(x[0], x[1]);
    const yss = pivotData.yAxisKeys.slice(y[0], y[1]);
    return {
      xs: xss,
      ys: yss,
      createSubspaceAxes: (x, y) => _createSubspaceAxes(x, y),
    };
  }

  //
  // DARK MATTER
  //


  // const axes: IEntity[][] = axesOrder.map((taggedAxisId: string): IEntity[] => {
  //   if (!SubspacePtr.isTaggedAxisId(taggedAxisId)) {
  //     // simple axis
  //     const axis: IEntity[] = mlp[taggedAxisId];
  //     axis['getIndexByMLP'] = function (mid: string, lid: string, pid: string) {                  // TODO: fix this HACK
  //       switch (taggedAxisId) {
  //         case 'metrics':
  //           return $eidx(ms, mid);
  //         case 'locations':
  //           return $eidx(ls, lid);
  //         case 'periods':
  //           return $eidx(ps, pid);
  //         default:
  //           return -1;
  //       }
  //     };
  //     return axis;
  //   }
  //
  //   // tagged axis
  //   const [axisId, tagsGroupId] = SubspacePtr.extractTaggedAxisId(taggedAxisId);
  //   let es: TaggedEntity[] = mlp[axisId];
  //   console.assert(!!es);
  //
  //   const usedTags: { [id: string]: boolean } = {};
  //   for (let i = 0; i < es.length;) {
  //     if (tagsGroupId === '=parent') {       // special case
  //       usedTags[(es[i] as any).parent.id] = true;
  //     } else if (tagsGroupId === '=title') {
  //       usedTags[(es[i] as any).title] = true;
  //     } else {
  //       const tagInE: IEntity = es[i].getTagByGroupId(tagsGroupId);
  //       if (!tagInE) {
  //         es = es.slice(0);
  //         es.splice(i, 1);
  //         continue;           // skip increment of i
  //       }
  //       usedTags[tagInE.id] = true;
  //     }
  //     i++;
  //   }
  //
  //   const helper: MetricsHelper | LocationsHelper | PeriodsHelper = {
  //     metrics: dsModel.metricsHelper,
  //     locations: dsModel.locationsHelper,
  //     periods: dsModel.periodsHelper,
  //   }[axisId];
  //   console.assert(!!helper);
  //
  //   let tagsGroup: TagGroup;
  //   tagsGroup = helper.getTagGroup(tagsGroupId);
  //   if (tagsGroupId === '=parent') {       // special case
  //     tagsGroup = new TagGroup('=parent', 'parent');
  //     (es as any[]).forEach((e: IEntity) => {
  //       if (!tagsGroup.children.find((t => t.id === e.parent.id))) {
  //         tagsGroup.addChild(new Tag(e.parent.id, e.parent.title));
  //       }
  //     });
  //   } else if (tagsGroupId === '=title') {
  //     tagsGroup = new TagGroup('=title', 'title');
  //     (es as any[]).forEach((e: IEntity) => {
  //       if (!tagsGroup.children.find((t => t.id === e.title))) {
  //         tagsGroup.addChild(new Tag(e.title));
  //       }
  //     });
  //   }
  //   console.assert(!!tagsGroup);
  //
  //   const tagsExpression: string | string[] = subspacePtr.getAxisEntityIds(tagsGroupId);
  //
  //   let tags: ITag[];
  //   if (Array.isArray(tagsExpression)) {
  //     const idsArray: string[] = tagsExpression as string[];
  //     tags = idsArray.map((tagId: string) => tagsGroup.getChildById(tagId)).filter(tag => tag != null);
  //
  //   } else if (typeof tagsExpression === 'string') {
  //     const descendants: ITag[] = tagsGroup.getDescendants();
  //     tags = evalBiPathExpression<ITag>(tagsExpression as string, descendants, []);
  //
  //   } else {
  //     tags = tagsGroup.getDescendants();                // take all tags in some order
  //     tags = tags.filter((t: ITag) => usedTags[t.id]);  // take only those tags, that are in use
  //   }
  //
  //   tags['getIndexByMLP'] = function (mid: string, lid: string, pid: string): number {         // HACK
  //     const midx: number = $eidx(ms, mid);
  //     const lidx: number = $eidx(ls, lid);
  //     const pidx: number = $eidx(ps, pid);
  //     if (midx === -1 || lidx === -1 || pidx === -1) return -1;
  //     let e: ITaggedEntity;
  //     switch (axisId) {
  //       case 'metrics':
  //         e = ms[midx];
  //         break;
  //       case 'locations':
  //         e = ls[lidx];
  //         break;
  //       case 'periods':
  //         e = ps[pidx];
  //         break;
  //       default:
  //         return -1;
  //     }
  //
  //     let tag: ITag = e.getTagByGroupId(tagsGroupId);
  //     if (tagsGroupId === '=parent') {
  //       tag = this.find(t => t.id === (e as any).parent.id);
  //     } else if (tagsGroupId === '=title') {
  //       tag = this.find(t => t.id === (e as any).title);
  //     }
  //     return this.indexOf(tag);
  //   };
  //   return tags;
  // });
  //
  // // all axis beyond `z` (eg `aa`, `ab`, `ac`) should have length === 1
  // for (let i = 0; i < axes.length - 3; i++) {
  //   if (axes[i].length > 1) {
  //     axes[i] = oneEntities(axes[i]);
  //   }
  // }
  //
  // const subspace: ISubspace = {
  //   ms,
  //   ls,
  //   ps,
  //   xs: axes[axes.length - 1],
  //   ys: axes[axes.length - 2],
  //   zs: axes[axes.length - 3],
  //   aas: axes[axes.length - 4],
  //   abs: axes[axes.length - 5],
  //   getZ: (idx) => axes[axes.length - 3][idx],
  //   getY: (idx) => axes[axes.length - 2][idx],
  //   getX: (idx) => axes[axes.length - 1][idx],
  //   axesOrder,
  //   reduce: function (nx, ny, nz) {
  //     console.warn('tagged reduce not implemented');
  //     return this;
  //   },
  //   isEmpty: function () {
  //     return !(ms.length && ls.length && ps.length);
  //   },
  //   getZYXIndexesByMLPIds: function (mid, lid, pid): [number, number, number] {
  //     return [
  //       axes[axes.length - 3]['getIndexByMLP'](mid, lid, pid),      // TODO: fix getIndexByMLP
  //       axes[axes.length - 2]['getIndexByMLP'](mid, lid, pid),
  //       axes[axes.length - 1]['getIndexByMLP'](mid, lid, pid),
  //     ];
  //   },
  //   getMLP: function (z: IEntity, y: IEntity, x: IEntity): IMLP {
  //     console.assert(axes[axes.length - 3].indexOf(z) !== -1);
  //     console.assert(axes[axes.length - 2].indexOf(y) !== -1);
  //     console.assert(axes[axes.length - 1].indexOf(x) !== -1);
  //
  //     const mlp = {
  //       metrics: ms,
  //       locations: ls,
  //       periods: ps,
  //     };
  //     let filters: any = axes.slice(0);
  //     filters[filters.length - 3] = [z];
  //     filters[filters.length - 2] = [y];
  //     filters[filters.length - 1] = [x];
  //     filters = filters.map((f) => f[0]);
  //
  //     axesOrder.forEach((taggedAxiName: string, idx: number) => {
  //       if (!SubspacePtr.isTaggedAxisId(taggedAxiName)) {
  //         // simple axi
  //         const e: IEntity = filters[idx];
  //         if (mlp[taggedAxiName].indexOf(e) !== -1) mlp[taggedAxiName] = [e];
  //       } else {
  //         const [axisId, tagsGroupName] = SubspacePtr.extractTaggedAxisId(taggedAxiName);
  //         const t: ITag = filters[idx];   // its a tag
  //         mlp[axisId] = mlp[axisId].filter((e) => e.tags.indexOf(t) !== -1);
  //       }
  //     });
  //     return {m: mlp.metrics[0], l: mlp.locations[0], p: mlp.periods[0]};
  //   },
  //   projectData: function (mlpCube) {
  //     const cube = createNullCube<IValue>(this.zs.length, this.ys.length, this.xs.length);
  //
  //     const self: any = this;
  //     mlpCube.forEach((mid, lid, pid, v) => {
  //       const tuple = self.getZYXIndexesByMLPIds(mid, lid, pid);
  //       const zi = tuple[0], yi = tuple[1], xi = tuple[2];
  //       if (zi !== -1 && yi !== -1 && xi !== -1) {
  //         cube[zi][yi][xi] = v;
  //       }
  //     });
  //
  //     return cube;
  //   },
  //   getArity: () => axes.length,
  //   getRawConfig: () => {
  //     throw new Error('getRawConfig not implemented for tagged dataSource');
  //   },
  // };
  // return [subspace, subscriptions];
}

export class MLPSubspaceService extends BaseService<ISubspaceModel> {
  private readonly _schemaName: string;
  private readonly _subspacePtr: ISubspacePtr;
  private readonly _isExternal: boolean;
  private  _dsStateService: DsStateService1;
  protected _subscriptions: IDisposable[] = [];

  public constructor(schemaName: string, subspacePtr: ISubspacePtr, isExternal: boolean) {
    super({
      error: null,
      loading: true,
      subspace: null,
    });
    this._schemaName = schemaName;
    this._subspacePtr = subspacePtr;
    this._isExternal = isExternal;
    this._dsStateService = DsStateService1.createInstance(schemaName);
    this._dsStateService.subscribeUpdates(this._onDsStateUpdated);
    this._onDsStateUpdated(this._dsStateService.getModel());
  }

  protected _dispose() {
    disposeAll(this._subscriptions);
    this._dsStateService.unsubscribe(this._onDsStateUpdated);                                       // ничего страшного не случится, если два раза отписаться
    this._dsStateService.release();
    this._dsStateService = null;
    super._dispose();
  }

  private _onDsStateUpdated = (dsState: IDsState) => {
    if (dsState.loading) return this._updateModel({error: null, loading: true});
    if (dsState.error) return this._updateModel({error: dsState.error, loading: false});
    this._dsStateService.unsubscribe(this._onDsStateUpdated);                                       // подписка на dsState больше не нужна

    this._makeSubspaceAndNotify();
  }

  private _makeSubspaceAndNotify = () => {
    disposeAll(this._subscriptions);
    const [subspace, newSubscriptions] = createSubspace(this._dsStateService, this._subspacePtr, this._isExternal, this._makeSubspaceAndNotify);
    this._subscriptions = newSubscriptions;
    this._updateModel({error: null, loading: false, subspace});
  }
}

/**
 * @param {string} schemaName  - имя датасета
 * @param {ISubspacePtr} subspacePtr - вспомогательная функция с работой datasource
 * @param {boolean} isExternal - external vizel?
 * @param {function} onChange -  функция callback при изменении модели
 * @description Асинхронная функция создания subspace, сама решает какой subspace создать для МЛП,КУБОВ,ЛУКАП
 * SubspacePtr можно взять из конфига методом getSubspacePtr()
 */
export function createSubspaceGenerator(schemaName: string, subspacePtr: ISubspacePtr, isExternal: boolean, onChange: any): IDisposable {
  let subspaceService: KoobSubspaceAsyncService | LookupSubspaceService | MLPSubspaceService;
  // KoobSubspaceAsyncService
  if (subspacePtr.koob) subspaceService = new KoobSubspaceAsyncService(schemaName, subspacePtr);
  else if (subspacePtr.lookupId) subspaceService = new LookupSubspaceService(schemaName, subspacePtr);
  else subspaceService = new MLPSubspaceService(schemaName, subspacePtr, isExternal);


  const onSubspaceServiceUpdated = (subspaceModel: ISubspaceModel) => {
    if (!subspaceModel.loading && !subspaceModel.error) {
      onChange(subspaceModel.subspace);
    }
  };

  subspaceService.subscribeUpdatesAndNotify(onSubspaceServiceUpdated);

  return {
    dispose: () => {
      subspaceService.unsubscribe(onSubspaceServiceUpdated);
      subspaceService.release();
      subspaceService = null;
    },
  };
}
