/**
 *
 *
 *
 *
 */

import {
  BaseService,
  AppConfig,
  AuthenticationService,
  IAuthentication,
  createObjectsCache,
  srv,
  IBaseModel,
  repo
} from '@luxms/bi-core';
import { DatasetModel } from './DatasetModel';
import axios from 'axios';
import { IDatasetModel, IDatasetServiceModel } from './types';
import { responses } from '../../defs/bi';
import { LocationAreasService } from './LocationAreasService';
import { LocationCardsService } from './LocationCardsService';
import { LocationCardFieldsService } from './LocationCardFieldsService';

export class DatasetService extends BaseService<IDatasetServiceModel> {
  private readonly _disabledMLP: boolean;
  private readonly _datasetId: string | number;
  //
  private readonly _authenticationService: AuthenticationService;
  private readonly _dashletsService: srv.ds.DashletsService;
  private readonly _dashboardsService: srv.ds.DashboardsService;
  private readonly _unitsService: srv.ds.UnitsService;
  private readonly _dashboardTopicsService: srv.ds.DashboardTopicsService;

  // mlp srv
  private readonly _locationsMLPService: LocationsMLPService;
  private readonly _metricsMLPService: MetricMLPService;
  private readonly _periodsService: srv.ds.PeriodsService;

  public constructor(datasetId: string | number) {
    super({
      loading: true,
      error: null,
      dataset: null,
    });
    this._datasetId = datasetId;
    this._disabledMLP = AppConfig.hasFeature('DisableMLP');

    this._authenticationService = AuthenticationService.getInstance();
    this._dashletsService = srv.ds.DashletsService.createInstance(this._datasetId);
    this._dashboardsService = srv.ds.DashboardsService.createInstance(this._datasetId);
    this._unitsService = srv.ds.UnitsService.createInstance(this._datasetId);
    this._dashboardTopicsService = srv.ds.DashboardTopicsService.createInstance(this._datasetId);

    if (!this._disabledMLP) {
      this._locationsMLPService = new LocationsMLPService(String(this._datasetId));
      this._metricsMLPService = new MetricMLPService(this._datasetId);
      this._periodsService = srv.ds.PeriodsService.createInstance(this._datasetId);

      this._locationsMLPService.subscribeUpdatesAndNotify(this._onServiceUpdated);
      this._metricsMLPService.subscribeUpdatesAndNotify(this._onServiceUpdated);
      this._periodsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    }
    this._authenticationService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._dashletsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._dashboardsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._unitsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._dashboardTopicsService.subscribeUpdatesAndNotify(this._onServiceUpdated);

    // todo
    this._subscriptions.push(AuthenticationService.subscribeUpdatesAndNotify(this._onServiceUpdated));
  }


  private _onServiceUpdated = async () => {
    const modelAuth: IAuthentication = this._authenticationService.getModel();
    const modelDashlets = this._dashletsService.getModel();
    const modelDashboard = this._dashboardsService.getModel();
    const modelUnit = this._unitsService.getModel();
    const modelTopic = this._dashboardTopicsService.getModel();
    // mlp
    const modelLocation = this._locationsMLPService?.getModel(); // может не быть
    const modelMetric = this._metricsMLPService?.getModel();     // может не быть
    const modelPeriod = this._periodsService?.getModel();        // может не быть

    if (!modelAuth.authenticated) return this._updateWithError(AuthenticationService.NOT_AUTHENTICATED);

    if (modelAuth.loading || modelDashlets.loading || modelDashboard.loading || modelUnit.loading || modelTopic.loading || modelLocation?.loading || modelMetric?.loading || modelPeriod?.loading) {
      return this._updateWithLoading();
    }

    if (modelAuth.error || modelDashlets.error || modelDashboard.error || modelUnit.error || modelTopic.error || modelLocation?.error || modelMetric?.error || modelPeriod?.error) {
      return this._updateWithError(modelDashlets.error || modelTopic.error || modelDashboard.error || modelUnit.error || modelLocation?.error || modelMetric?.error || modelPeriod?.error);
    }

    try {
      const url = AppConfig.fixRequestUrl(`/api/datasets/${this._datasetId}`);
      const result: responses.IDatasetResponse = (await axios.get(url)).data;
      const rawDataset = (result).dataset;

      //
      const dashboards = modelDashboard.filter(e => e?.id);
      const dashboard_topics = modelTopic.filter(e => e?.id);
      const dashlets = modelDashlets.filter(e => e?.id);
      const {locations = [], location_cards = [], location_card_fields = [], location_areas = []} = modelLocation || {};
      const {metrics = [], metric_sets = []} = modelMetric || {};
      const periods = (modelPeriod || []).filter(e => e?.id);
      const units = modelUnit.filter(e => e?.id);

      const tables: any = {
        dashboard_topics,
        dashboards,
        dashlets,
        location_areas,
        location_card_fields,
        location_cards,
        locations,
        metric_sets,
        metrics,
        periods,
        units
      };

      const dataset: IDatasetModel = new DatasetModel(rawDataset, tables);


      this._updateWithData({dataset});

    } catch (err) {
      console.error('Failed to load dataset model', err.message);
      console.error(err);
      this._updateWithError(err);
    }
  }

  protected _dispose() {
    this._authenticationService.unsubscribe(this._onServiceUpdated);
    this._dashletsService.unsubscribe(this._onServiceUpdated);
    this._unitsService.unsubscribe(this._onServiceUpdated);
    this._dashboardsService.unsubscribe(this._onServiceUpdated);
    this._dashboardTopicsService.unsubscribe(this._onServiceUpdated);

    if (!this._disabledMLP) {
      this._locationsMLPService.unsubscribe(this._onServiceUpdated);
      this._metricsMLPService.unsubscribe(this._onServiceUpdated);
      this._periodsService.unsubscribe(this._onServiceUpdated);
    }
    super._dispose();
  }

  private static _cache = createObjectsCache(shemaName => new DatasetService(shemaName), '__dsStateDatasetService');
  public static createInstance: (schema_name: number | string) => DatasetService = DatasetService._cache.get;
}

interface ILocationMLPModel extends IBaseModel {
  readonly locations: repo.ds.IRawLocation[];
  readonly location_areas: any[];
  readonly location_card_fields: any[];
  readonly location_cards: any[];
}

/**
 * @description Загружаю все таблицы у Датасета location, location_areas, location_card_fields, location_cards
 */
class LocationsMLPService extends BaseService<ILocationMLPModel> {
  private _locationsService: srv.ds.LocationsService;
  private _locationAreasService: LocationAreasService;
  private _locationCardsService: LocationCardsService;
  private _locationCardFieldsService: LocationCardFieldsService;

  public constructor(schemaName: string) {
    super({
      loading: true,
      error: null,
      locations: [],
      location_areas: [],
      location_card_fields: [],
      location_cards: [],
    });

    this._locationAreasService = new LocationAreasService(schemaName);
    this._locationCardsService = new LocationCardsService(schemaName);
    this._locationCardFieldsService = new LocationCardFieldsService(schemaName);
    this._locationsService = srv.ds.LocationsService.createInstance(schemaName);

    this._locationAreasService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._locationCardsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._locationCardFieldsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._locationsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
  }

  private _onServiceUpdated = (): void => {
    const areaModel = this._locationAreasService.getModel();
    const cardModel = this._locationCardsService.getModel();
    const filedModel = this._locationCardFieldsService.getModel();
    const locationsModel = this._locationsService.getModel();
    if (areaModel.loading || cardModel.loading || filedModel.loading || locationsModel.loading) return this._updateWithLoading();
    if (areaModel.error || cardModel.error || filedModel.error || locationsModel.error) {
      return this._updateWithError(areaModel.error || cardModel.error || filedModel.error || locationsModel.error);
    }

    const location_areas = areaModel.filter(e => e?.id);
    const location_cards = cardModel.filter(e => e?.id);
    const location_card_fields = filedModel.filter(e => e?.id);
    const locations = locationsModel.filter(e => e?.id);
    this._updateWithData({location_areas, location_cards, location_card_fields, locations});
  }

  protected _dispose() {
    this._locationAreasService.unsubscribe(this._onServiceUpdated);
    this._locationCardsService.unsubscribe(this._onServiceUpdated);
    this._locationCardFieldsService.unsubscribe(this._onServiceUpdated);
    this._locationsService.unsubscribe(this._onServiceUpdated);
    this._locationAreasService = null;
    this._locationCardsService = null;
    this._locationCardFieldsService = null;
    this._locationsService = null;
    super._dispose();
  }
}

interface IMetricMLPModel extends IBaseModel {
  readonly metric_sets: repo.ds.IRawMetricSet[];
  readonly metrics: repo.ds.IRawMetric[];
}

/**
 * @description Загружаю таблицы у Датасета metrics, metric_sets
 */
class MetricMLPService extends BaseService<IMetricMLPModel> {
  private _metricSetsService: srv.ds.MetricSetsService;
  private _metricsService: srv.ds.MetricsService;

  public constructor(schemaName: string | number) {
    super({
      loading: true,
      error: null,
      metric_sets: [],
      metrics: []
    });
    this._metricSetsService = srv.ds.MetricSetsService.createInstance(schemaName);
    this._metricsService = srv.ds.MetricsService.createInstance(schemaName);
    this._metricSetsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
    this._metricsService.subscribeUpdatesAndNotify(this._onServiceUpdated);
  }

  private _onServiceUpdated = (): void => {
    const setsModel = this._metricSetsService.getModel();
    const metricModel = this._metricsService.getModel();
    if (setsModel.loading || metricModel.loading) return this._updateWithLoading();
    if (setsModel.error || metricModel.error) return this._updateWithError(setsModel.error || metricModel.error);

    const metric_sets = setsModel.filter(e => e?.id);
    const metrics = metricModel.filter(e => e?.id);
    this._updateWithData({metric_sets, metrics});
  }

  protected _dispose() {
    this._metricSetsService.unsubscribe(this._onServiceUpdated);
    this._metricsService.unsubscribe(this._onServiceUpdated);
    this._metricSetsService = null;
    this._metricsService = null;
    super._dispose();
  }
}