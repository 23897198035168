import * as React from 'react';
import {ILocation, IMetric} from "../../../defs/bi";
import {_buildLegendSubtitle} from "./MapFillLayerDialogR";

interface IDiscreteLegendRProps {
  pairs: [string, string][];
  title: string;
  metric: IMetric;
}

class DiscreteLegendR  extends React.Component<IDiscreteLegendRProps> implements IVMLegend {
  public state: IVMLegendState = {
    width: 250,
    title: '',
    subtitle: '',
    items: [],
  };

  constructor(props) {
    super(props);
    this.draw(props);
  }

  public hiliteLocation(l: ILocation): void {
    //
  }

  public dispose() {
    //
  }

  private draw({pairs, title, metric}: IDiscreteLegendRProps): void {
    this.state.title = title;
    this.state.width = 250;
    this.state.subtitle = _buildLegendSubtitle(metric);

    this.state.items = pairs.map(pair => ({
      title: pair[0],
      color: pair[1],
    }));
  }

  public render() {
    const {title, subtitle, width, items} = this.state;

    return(<article className="MapfillDiscreteLegend legend "
                    style={{padding: '11px 11px 16px 12px', minHeight: '50px'}}
    >
      <table>
        {title && (
          <thead>
          <tr  key={title} >
            <td colSpan={2}>{title}</td>
          </tr>
          <tr key={subtitle}>
            <td  colSpan={2}
                 style={{
                   paddingBottom: '5px',
                   paddingLeft: '5px',
                   paddingRight: '5px',
                   fontSize: Math.max(13, 18 - subtitle.length / 25) + 'px',
                 }}
            >{subtitle}</td>
          </tr>
          </thead>) || null}
        <tbody>
        {items.map((item, z) => {
          return(<tr key={item.title + z} className="MapfillDiscreteLegend__Item">
            <td className={'colored-item'}>
              <span style={{backgroundColor: item.color}}></span>
            </td>
            <td style={{textAlign: 'left'}}>
              {(item.title) ? <span>{item.title}</span> : null}
            </td>
          </tr>);
        })}
        </tbody>
      </table>
    </article>);
  }
}

export default DiscreteLegendR;