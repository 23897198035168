import { AppConfig, BaseService } from '@luxms/bi-core';
import { IMLPSubspace } from '../../defs/bi';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { Simulate } from 'react-dom/test-utils';
import abort = Simulate.abort;

export interface ILookupDataModel extends ILookupResponse {
  loading: boolean;
  error: string;
  noData: boolean;
}

export interface ILookupResponse {
  rows: string[][] | number[][];
  columns: { name: string, title: string, search: string, config?: any }[];
  config?: any;
}

interface ILookupReq {
  dataset?: string;
  lookupId: string;
  metrics: string[] | number[];
  locations: string[] | number[];
  periods: string[] | number[];
  limit?: number;
  offset?: number;
  filter?: string;
  sort?: string;
  row?: any;
  cell?: any;
  fileName?: string;
}

interface ILookupDataRequest {
  offset?: number;
  limit?: number;
  sort?: string;
  cancelToken?: CancelToken;
}

export async function lookupRequestData(schemaName: string, lookupId: string, mlp: IMLPSubspace, request: ILookupDataRequest): Promise<ILookupResponse> {
  const url: string = AppConfig.fixRequestUrl(`/api/lookup/${schemaName}/${lookupId}`);
  const _ms: string[] = (mlp.ms || []).map((m) => m.id);
  const _ls: string[] = (mlp.ls || []).map((l) => l.id);
  const _ps: string[] = (mlp.ps || []).map((p) => p.id);

  const body: ILookupReq = {
    lookupId: lookupId,
    metrics: _ms,
    locations: _ls,
    periods: _ps,
  };

  if (request.offset) body.offset = request.offset;
  if (request.limit) body.limit = request.limit;
  if (request.sort) body.sort = request.sort;

  const response = await axios({
    url,
    method: 'POST',
    data: body,
    cancelToken: request.cancelToken,
  });

  return response.data;
}

export class LookupDataService extends BaseService<ILookupDataModel> {
  private readonly _lookupId: string = null;
  private readonly _schemaName: string = null;

  private _mlp: IMLPSubspace = null;
  private _request: ILookupDataRequest = {};

  private _loadCancel: CancelTokenSource | null = null;

  constructor(lookupId: string | number, schemaName: string, mlp: IMLPSubspace, request: ILookupDataRequest) {
    super({loading: true, error: null, noData: false, columns: [], rows: []});

    this._lookupId = String(lookupId);
    this._schemaName = schemaName;
    this._mlp = mlp;
    this._request = request;

    this._load();
  }

  private async _load(): Promise<void> {
    this._updateWithLoading();
    this._loadCancel = axios.CancelToken.source();

    const data: ILookupResponse = await lookupRequestData(this._schemaName, this._lookupId, this._mlp, {
      ...this._request,
      cancelToken: this._loadCancel.token,
    });

    const noData: boolean = data.rows.length === 0;
    this._loadCancel = null;

    this._updateWithData({
      loading: false,
      error: null,
      noData,
      ...data,
    });
  }

  public async loadData(request: ILookupDataRequest, mlp?: IMLPSubspace): Promise<void> {
    this.abort();
    if (mlp) this._mlp = mlp;
    this._request = request;
    this._load();
  }

  public abort() {
    if (this._loadCancel) {
      this._loadCancel.cancel('canceled');
      this._loadCancel = null;
    }
  }

}
