exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.DsShellError{display:flex;flex-direction:column;text-align:center;width:100%;height:100%}.DsShellError a{margin-top:1rem;color:inherit;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};