exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.MitoDropdown{width:11rem;border-radius:.5rem;box-shadow:var(--shadow_dash);padding:.5rem;background:var(--color4)}.MitoDropdown__List,.MitoDropdown__SubList{list-style:none;padding:0;margin:0}.MitoDropdown__List{font-size:.75rem;color:var(--color1)}.MitoDropdown__Item{padding:.5rem;cursor:pointer;border-radius:.5rem}.MitoDropdown__Item:hover{box-shadow:var(--panel_push-up)}.MitoDropdown__SubListWrapper{list-style:none}.MitoDropdown__SubListWrapper.open{box-shadow:var(--panel_push-down)}.MitoDropdown__SubListWrapper.open .MitoDropdown__SubListHeading{margin-bottom:.5rem}.MitoDropdown__SubListWrapper.open:hover{box-shadow:var(--panel_push-down)}.MitoDropdown__SubItem{padding:.25rem .5rem}.MitoDropdown__SubItem:not(:last-of-type){margin-bottom:.5rem}.MitoDropdown__SubListHeading{display:flex;justify-content:space-between}.MitoDropdown__SubListHeading svg{stroke:var(--color5)}.MitoDropdown__ItemWithInput{position:relative}.MitoDropdown__ItemWithInput input{border:none;outline:none;background:var(--color_conditions);font-size:.75rem;padding:.25rem;color:var(--color1);margin-bottom:.5rem}.MitoDropdown__ItemWithInput input:hover{box-shadow:var(--panel_push-up)}.MitoDropdown__ItemWithInput input:focus{box-shadow:var(--panel_push-up)}.MitoDropdown__ItemWithInput input::placeholder{color:var(--color16)}.MitoDropdown__ItemInputError{position:absolute;font-size:.65rem;white-space:nowrap;color:var(--color8);left:0;bottom:-0.25rem}", ""]);

// exports
exports.locals = {
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};