import React from 'react';
import cn from 'classnames';
import { IThemeVM, ThemeVC } from '../../view-controllers/ThemeVC';

interface ISetTheme {
  theme: string;
  className?: string;
  children?: any;
}

class SetTheme extends React.Component<ISetTheme> {
  public state: {
    error: string;
    loading: boolean;
    themeId: string;
  } = {
    error: null,
    loading: true,
    themeId: '',
  };

  public constructor(props: ISetTheme) {
    super(props);
  }

  public componentDidMount() {
    ThemeVC.getInstance().subscribeUpdatesAndNotify(this._onThemeVCUpdated);
  }

  public componentWillUnmount() {
    ThemeVC.getInstance().unsubscribe(this._onThemeVCUpdated);
  }

  public componentDidUpdate(prevProps: Readonly<ISetTheme>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.theme !== prevProps.theme) {
      // this._onThemeVCUpdated(ThemeVC.getInstance().getModel());
      this.setState(() => ({
        themeId: this.props.theme,
      }));
    }
  }

  private _onThemeVCUpdated = (themeVM: IThemeVM) => {
    if (themeVM.error) return this.setState({error: themeVM.error, loading: false, theme: null});
    if (themeVM.loading) return this.setState({error: null, loading: true, theme: null});

    const themeId = this.props.theme in themeVM.themes ? this.props.theme : themeVM.currentThemeId;
    this.setState({error: null, loading: false, themeId});
  }


  public render() {
    const {className, children} = this.props;
    const {error, loading, themeId} = this.state;

    if (error) return <div className="error">{error}</div>;
    if (loading) return null;

    return (
      <div className={cn(`theme-${themeId}`, className)} data-theme={themeId}>
        {children}
      </div>);
  }
}

export default SetTheme;
