export function getMouseCoordsFromEvent(evt) {
  let x = 0, y = 0;

  if (evt.pageX) {
    x = evt.pageX;
  } else if (evt.clientX) {
    x = evt.clientX + (document.documentElement.scrollLeft || document.body.scrollLeft);
  }

  if (evt.pageY) {
    y = evt.pageY;
  } else if (evt.clientY) {
    y = evt.clientY + (document.documentElement.scrollTop || document.body.scrollTop);
  }

  return {x, y};
}


export function getElementCoords(el: HTMLElement, verticalDirection: string = 'top', horizontalDirection: string = 'left') {
  const rect: ClientRect = el.getBoundingClientRect();
  let x = rect.left, y = rect.top;
  if (verticalDirection === 'bottom') y += rect.height;
  if (horizontalDirection === 'right') x += rect.width;
  return {x, y};
}


export function hasClass(el: HTMLElement, className: string): boolean {
  let elClassName = el.className || '';
  if (typeof elClassName !== 'string') {
    elClassName = el.getAttribute('class') || '';
  }
  const classNames: string[] = elClassName.split(' ');
  return (classNames.indexOf(className) !== -1);
}


export function getParentsList(el: HTMLElement, includeSelf: boolean = false): HTMLElement[] {
  let result: HTMLElement[] = [];
  if (includeSelf) {
    result.push(el);
  }
  while ((el = el.parentElement)) {
    result.push(el);
  }
  return result;
}


export function isParentHasClass(el: HTMLElement, className: string, includeSelf: boolean = false): boolean {
  const parents: HTMLElement[] = getParentsList(el, includeSelf);
  return !!parents.find(p => hasClass(p, className));
}


export function getParentWithClass(el: HTMLElement, className: string, includeSelf: boolean = false): HTMLElement {
  const parents: HTMLElement[] = getParentsList(el, includeSelf);
  return parents.find(p => hasClass(p, className));
}

export function toggleActiveClass(clickTarget, classOfActiveElement) {
  const target = (clickTarget as HTMLElement).closest('.leaflet-marker-icon');
  const activeElement =  target?.querySelector(`${classOfActiveElement}`);
  if (target) activeElement?.classList.add('_active');
  if ((clickTarget as HTMLElement).closest('span')?.classList.contains('mark-popup__close-button')) {
    document.querySelectorAll('.mark-popup').forEach(item => item.classList.remove('_active'));
  }
}
