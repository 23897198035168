import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {BIIcon} from '../../../src/views/components/BIIcon/BIIcon';
import {Button} from '@luxms/bi-face';
import {lang} from '../../../src/utils/utils';
import './DatasetSnapshotManager.scss';
import {QRPCService} from "../../../src/services/QRPCService";

interface ISnapshotList {
  items: { id: number, title: string }[];
  onChangeSelect: (id: number) => void;
}

interface IDatasetSnapshotManagerProp {
  schemaName: string;
  onClose: () => void;
  visible: boolean;
}

interface IDatasetSnapshotManagerState {
  loading: boolean;
  error: string;
  success: string;
  list: { id: number, title: string }[]
  selectId: number;
}

export class DatasetSnapshotManager extends React.Component<IDatasetSnapshotManagerProp, IDatasetSnapshotManagerState> {

  public state: IDatasetSnapshotManagerState;

  public constructor(props) {
    super(props);
    this.state = {loading: false, error: null, success: null, list: [], selectId: null}
  }

  public componentDidUpdate(prevProps: Readonly<IDatasetSnapshotManagerProp>) {
    if (this.props.visible !== prevProps.visible && this.props.visible) {
      this._loadSnapshotList();
    }
  }

  // load fn
  private _loadSnapshotList = async (): Promise<void> => {
    this.setState({loading: true, error: null, list: []});
    const {schemaName} = this.props;
    const qrpc = new QRPCService();

    try {
      const result = await qrpc.execute({
        service: 'DatasetSnapshotService',
        method: 'getSnapshots',
        params: [schemaName],
      });
      const list = (result || []).map(item => ({id: item.id, title: item.title}));
      this.setState({list, loading: false, error: null});
    } catch (error) {
      this.setState({loading: false, success: null, error: error, list: []});
    }
  }
  private _restoreSnapshot = async (): Promise<void> => {
    this.setState({loading: true, error: null});
    const {schemaName} = this.props;
    const {selectId} = this.state;
    const qrpc = new QRPCService();
    try {
      await qrpc.execute({
        service: 'DatasetSnapshotService',
        method: 'restore',
        params: [schemaName, selectId],
      });
      const success = lang('snapshotRestored');
      this.setState({loading: false, error: null, success})
    } catch (error) {
      this.setState({loading: false, error, success: null})
    }
  }
  private _makeSnapshot = async (): Promise<void> => {
    this.setState({loading: true, error: null});
    const {schemaName} = this.props;
    const qrpc = new QRPCService();
    try {
      await qrpc.execute({
        service: 'DatasetSnapshotService',
        method: 'dump',
        params: [schemaName],
      });


      const result = await qrpc.execute({
        service: 'DatasetSnapshotService',
        method: 'getSnapshots',
        params: [schemaName],
      });

      const list = (result || []).map(item => ({id: item.id, title: item.title}));
      const success = lang('snapshotSaved');
      this.setState({loading: false, error: null, success, list})
    } catch (error) {
      this.setState({loading: false, error, success: null})
    }
  }

  public render() {
    const {loading, error, success, list, selectId} = this.state;
    const {onClose} = this.props;
    return (
      <div className='DatasetSnapshotManager'>
        <div className={cn('DatasetSnapshotManager__Display', {loading})}>
          {success && <span className="DatasetSnapshotManager__Message success">{success}</span>}
          {error && <span className="DatasetSnapshotManager__Message failure">{error}</span>}
          {!success && !error && <SnapshotList items={list} onChangeSelect={(id) => this.setState({selectId: id})}/>}
        </div>

        <div className="DatasetSnapshotManager__Controls">


          {!success &&
          <Button variant="primary" className={cn('DatasetSnapshotManager__Button', {disabled: loading})}
                  onClick={this._makeSnapshot}>
            {lang('snapshot') + ' +'}
          </Button>
          }

          {!success &&
          <Button variant="primary" className={cn('DatasetSnapshotManager__Button', {disabled: selectId === null})}
                  onClick={this._restoreSnapshot}>
            {lang('restore')}
          </Button>
          }


          {success &&
          <Button variant="primary" className={cn('DatasetSnapshotManager__Button', {disabled: loading})}
                  onClick={() => onClose()}>
            {lang('close')}
          </Button>
          }

          {success &&
          <Button variant="primary"
                  className={cn('DatasetSnapshotManager__Button', {disabled: loading})}
                  onClick={() => this.setState({success: null})}>
            {lang('backToHome')}
          </Button>
          }

        </div>

        <BIIcon icon="x" className="DatasetSnapshotManager__Close" onPress={() => onClose()}/>
      </div>
    );
  }
}

export default DatasetSnapshotManager;

const SnapshotList: React.FC<ISnapshotList> = ({items, onChangeSelect}) => {
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    onChangeSelect(activeId);
  }, [activeId]);

  return (
    <ul className="DatasetSnapshotManager__List">
      {
        !items.length
          ? <li className={cn('DatasetSnapshotManager__ListItem empty')}>{lang('no_data')}</li>
          : (items.map((item) => {
            return (<li key={item.id} className={cn('DatasetSnapshotManager__ListItem', {active: activeId === item.id})}
                        onClick={() => setActiveId(item.id)}>
              {item.id + ': ' + item.title}
            </li>);
          }))
      }
    </ul>
  );
};




