import React, {Suspense} from 'react';
import {ITextEditorProps} from '@luxms/bi-face/TextEditor/TextEditor.types';

const AceEditor = React.lazy(() => import ('@luxms/bi-face/TextEditor'));
const TextEditor: React.FC<ITextEditorProps> = (props): JSX.Element => {
  return (
    <Suspense fallback={null}>
      <AceEditor {...props}/>
    </Suspense>
  );
};

export default TextEditor;
