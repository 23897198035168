import React from 'react';
import './Error.scss';
import Icon from '../../components/Icon/Icon';

interface IErrorProp {
  icon?: string;
  message: string;
}

export class Error extends React.Component<IErrorProp, any> {
  public constructor(props) {
    super(props);
  }

  public render() {
    const {icon = 'frownO', message} = this.props;
    return (
      <div className="simple-container">
        <section className="ErrorView error">
          <Icon type={icon}/>
          <h1>{message}</h1>
        </section>
      </div>);
  }
}