import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import SVGIcon from '../SVGIcon';
import {Error} from '../../../../srx/views/error/Error';
import {LoadingIcon} from '../../vizels/VizelMap';
import './SpriteComponent.scss';

const SpriteComponent: React.FC<{visible: boolean, onHide: () => void}> = ({visible, onHide}) => {
  const [ids, setIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean | string>(false);
  const [tooltip, setTooltip] = useState<boolean | string>(false);

  useEffect(() => {
    const quotes = /[`'"]/gi;
    const regexp = /id=["'`].+["'`]/gmi;
    setLoading(true);
    fetch('../../assets/icons/sprite.svg').then(response => {
      response.text().then(text => {
        const ids = text.match(regexp).join('').split(quotes).filter(it => (it !== 'id=' && !!it));
        setLoading(false);
        setIds(ids);
      });
    }).catch( err => {
      setLoading(false);
      setError(err.message);
    });
  }, []);

  const clickHandler = (e) => {
    const content = e.currentTarget.getAttribute('data-info');
    navigator?.clipboard?.writeText(content).then(() => {
      setTooltip(content);
      const timeout = setTimeout( () => {
        setTooltip('');
        clearTimeout(timeout);
      }, 1000);
    }) ;
  };

  return <div className={cn('SpriteComponent', {visible: !!visible})}>
    <span className="SpriteComponent__close" onClick={onHide}><SVGIcon path="x"/></span>
    <div className="SpriteComponent__container">
      {error && <Error message={'error'} />}
      {loading && <LoadingIcon />}
      {
        ids.map(item => (
          <div key={item} className="SpriteComponent__box" data-info={item} onClick={clickHandler}>
            <span className={cn('SpriteComponent__tooltip', {visible: tooltip === item})}>copied</span>
            <div className="SpriteComponent__iconBox"><SVGIcon path={item}/></div>
            <div className="SpriteComponent__iconText">{item}</div>
          </div>
        ))
      }
    </div>
  </div>;
};
export default SpriteComponent;