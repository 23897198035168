
//
// global styles
//
import { IPkFontMetrics } from '../../../libs/plotnik/PkSprite';
import { useEffect, useRef } from 'react';
import { YAxisBreaksOptions } from 'highcharts-main';

const CS: CSSStyleDeclaration = getComputedStyle(document.body);

const YAXIS_TEXT_STYLE = {
  color: "#7F8B9C",
  fontSize: 18,
  fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
  fontWeight: "normal"
};
const AXIS_LABEL_TEXT_STYLE = {
  fontSize: 12,
  fontFamily: 'sans-serif'
};
const DISTANCE_BETWEEN_LABEL_AND_YAXIS = 8;


// Canvas might be rendered before custom font is loaded
// so, this is workaround:
let mainFontReady: boolean = false;

export const mainFontLoader: Promise<any> = new Promise(async (resolve, reject) => {
  try {
    if (!CS.fontFamily.split(',')[0].match(/^["']?(.+?)["']?$/)) throw new Error('Unknown font format');
    let mainFontName: string = RegExp.$1;

    // https://github.com/bramstein/fontfaceobserver
    let FontFaceObserver: any = await import('../../../../libs/fontfaceobserver/fontfaceobserver.standalone');
    FontFaceObserver = FontFaceObserver.default || FontFaceObserver;
    if (!FontFaceObserver) {
      // the library only registers itself in global
      FontFaceObserver = (window as any).FontFaceObserver;
    }
    let font = new FontFaceObserver(mainFontName);
    await font.load();
    // await new Promise((resolve) => window.setTimeout(() => resolve, 0));
    console.log('Load font:', mainFontName);
    mainFontReady = true;
    resolve();

    if (__getTextWidthContext) {
      setFont(__getTextWidthContext, {});
    }

  } catch (err) {
    // something failed
    // there is no reason to reload, or try something else
    console.error('Font loader:', err);
    mainFontReady = true;
    resolve();
  }
});

/**
 * @param ctx - canvas
 * @param {IPkFontMetrics} fontMetrics - набор ключей для шрифта
 * @description устанавливает шрифт в canvas э-т
 */
export function setFont(ctx: CanvasRenderingContext2D, fontMetrics: IPkFontMetrics = {}) {
  const {fontStyle, fontVariant, fontWeight, fontStretch, fontSize, lineHeight, fontFamily} = fontMetrics;
  const strFont = [
    fontStyle || CS.fontStyle || 'normal',
    fontVariant || CS.fontVariant || 'normal',
    fontWeight || CS.fontWeight || 'normal',
    // fontStretch || this.CS.fontStretch || 'normal',
    (fontSize ? (parseInt(fontSize as string) + 'px') : (CS.fontSize || 'medium')) + '/' + (lineHeight ? (parseInt(lineHeight as string) + 'px') : (CS.lineHeight || 'normal')),
    fontFamily || CS.fontFamily + ', \'CURSIVE\'' || 'sans-serif'].join(' ');
  ctx.font = strFont;
}


let __getTextWidthContext: CanvasRenderingContext2D = null;

/**
 * @param {string} text - строка текста
 * @param {IPkFontMetrics} fontMetrics - набор ключей для шрифта
 * @description создает эл-т canvas, и считает его длину в px
 */
export function getTextWidth(text: string, fontMetrics?: IPkFontMetrics): number {
  if (!__getTextWidthContext) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    __getTextWidthContext = canvas.getContext('2d');
    setFont(__getTextWidthContext, {});
  }

  if (fontMetrics) {
    setFont(__getTextWidthContext, fontMetrics);
  }

  const metrics = __getTextWidthContext.measureText(text);

  if (fontMetrics) { // reset if it was set
    setFont(__getTextWidthContext, {});
  }

  return metrics.width;
}

interface TextMetricsExtend extends TextMetrics {
  height: number;
}

/**
 * @param {string} text - строка текста
 * @param {IPkFontMetrics} fontMetrics - набор ключей для шрифта
 * @description создает эл-т canvas, и считает его длину и ширину в px
 */
export function getTextSize(text: string, fontMetrics?: IPkFontMetrics): TextMetricsExtend {
  if (!__getTextWidthContext) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    __getTextWidthContext = canvas.getContext('2d');
    setFont(__getTextWidthContext, {});
  }

  if (fontMetrics) {
    setFont(__getTextWidthContext, fontMetrics);
  }

  const metrics: any = __getTextWidthContext.measureText(text);

  if (fontMetrics) { // reset if it was set
    setFont(__getTextWidthContext, {});
  }

  metrics['height'] = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
  return metrics;
}

let __svg: SVGElement = null;

function setSvgFont(svg: SVGElement, fontMetrics: IPkFontMetrics = {}) {
  const {fontStyle, fontVariant, fontWeight, fontSize, lineHeight, fontFamily} = fontMetrics;
  const strFont = {
    fontStyle: fontStyle || CS.fontStyle || 'normal',
    fontVariant: fontVariant || CS.fontVariant || 'normal',
    fontWeight: fontWeight || CS.fontWeight || 'normal',
    fontSize: fontSize ? (fontSize + 'px') : (CS.fontSize || 'medium'),
    lineHeight: lineHeight ? (lineHeight + 'px') : (CS.lineHeight || 'normal'),
    fontFamily: fontFamily || CS.fontFamily + ', \'CURSIVE\'' || 'sans-serif'
  };
  Object.keys(strFont).forEach((key) => svg.style[key] = strFont[key]);
}

function hideSvg(svg: SVGElement) {
  const hidden = {
    visibility: 'hidden',
    position: 'absolute',
    left: '-1px',
    overflow: 'hidden !important'
  };
  Object.keys(hidden).forEach((key) => svg.style[key] = hidden[key]);
}

/**
 * @param {string} text - строка текста
 * @param {IPkFontMetrics} fontMetrics - набор ключей для шрифта
 * @param  viewBox
 * @description создает эл-т svg, и считает его длину и ширину x,y в px
 */
export function getSvgTextSize(text: string, fontMetrics?: IPkFontMetrics, viewBox?: {x: number, y: number, width: number, height: number}): DOMRect {
  if (!__svg) {
    __svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    hideSvg(__svg);
  }

  if (viewBox) {
    __svg.setAttribute('viewBox', Object.values(viewBox).join(' '));
  }

  const textElementNS = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  const textNode = document.createTextNode(text);
  textElementNS.setAttribute('x', '0');
  textElementNS.setAttribute('y', viewBox && viewBox.hasOwnProperty('y') ? (viewBox.y / 2).toString() : '50');
  textElementNS.setAttribute('fill', 'black');
  setSvgFont(textElementNS, fontMetrics);
  textElementNS.appendChild(textNode);
  __svg.appendChild(textElementNS);
  document.body.appendChild(__svg);
  const metrics: DOMRect = textElementNS.getBBox();
  __svg.removeChild(textElementNS);
  document.body.removeChild(__svg);

  return metrics;
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * @param {any} val
 * @description React hook для сравнения state
 */
export const useCompare = val => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

/**
 * @param axisTitle
 * @param axisLongestLabel
 * @param whatMeasure
 * @param styleAxisTitle
 * @param styleAxisLabel
 * @description Вспомогательная функция для echarts.Рассчитываю расстояние отступа слева,чтобы влезли и название оси и лейблы значений
 */
export function getAxisGap(axisTitle: string, axisLongestLabel: string, whatMeasure: string = 'width', styleAxisTitle?: IPkFontMetrics, styleAxisLabel?: IPkFontMetrics) {
  if (!axisTitle) return {gridGap: 50, nameGap: 50};
  if (!styleAxisTitle) styleAxisTitle = {...YAXIS_TEXT_STYLE};
  if (!styleAxisLabel) styleAxisLabel = {...AXIS_LABEL_TEXT_STYLE}
  const widthNameYAxis = getTextSize(axisTitle, styleAxisTitle).height;
  const metricsLongestValue = getTextSize(axisLongestLabel, styleAxisLabel);
  let widthLongestValue = whatMeasure === 'width' ? metricsLongestValue.width : metricsLongestValue.height;
  const gap = widthNameYAxis + DISTANCE_BETWEEN_LABEL_AND_YAXIS;
  // dominant-baseline="central" - + widthNameYAxis / 2;
  const nameGap = widthLongestValue + DISTANCE_BETWEEN_LABEL_AND_YAXIS + Math.round(widthNameYAxis / 2);
  return {gridGap: gap, nameGap, };
}